<template>
  <div v-if="reportList.length > 0">
    <el-tabs v-model="currentReport" @tab-click="selectReport">
      <el-tab-pane
        v-for="(report, index) in reportList"
        :lazy="true"
        :key="report.title"
        :label="report.title"
      >
        <DatePicker :index="index" :report="report" />

        <br />

        <iframe :src="report.url" style="height: 85vh; width: 100%" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import DatePicker from "./DatePicker";

export default {
  props: ["userProfileDepartmentIds"],
  data() {
    return {
      currentReport: "",
      reportURL: "",
      isLoaded: false,
    };
  },
  components: {
    DatePicker,
  },
  methods: {
    selectReport(tab) {
      this.reportURL = _.get(this.reportList[tab.index], "url", "");
    },
  },
  computed: {
    isHandoverByDepartmentEnabled() {
      return _.get(this.$store, "state.modules.handover.handoverRouting.byDepartment", false);
    },
    reportList() {
      const embedChartsNotEnabled = !this.$store.state.modules.analytics.urlEmbedCharts;
      if (embedChartsNotEnabled) {
        return [];
      }

      const {
        reportURL1,
        reportURL2,
        reportURL3,
        reportURL4,
        reportURL5,
      } = this.$store.state.modules.analytics.urlEmbedCharts;
      const {
        reportTitle1,
        reportTitle2,
        reportTitle3,
        reportTitle4,
        reportTitle5,
      } = this.$store.state.modules.analytics.urlEmbedCharts;
      const {
        reportDepartmentId1,
        reportDepartmentId2,
        reportDepartmentId3,
        reportDepartmentId4,
        reportDepartmentId5,
      } = this.$store.state.modules.analytics.urlEmbedCharts;

      const reports = _.zipWith(
        [reportTitle1, reportTitle2, reportTitle3, reportTitle4, reportTitle5],
        [reportURL1, reportURL2, reportURL3, reportURL4, reportURL5],
        [
          reportDepartmentId1,
          reportDepartmentId2,
          reportDepartmentId3,
          reportDepartmentId4,
          reportDepartmentId5,
        ],
        (title, url, departmentId) => ({ title, url, departmentId })
      )
        .filter((report) => report.url)
        .filter((report) => {
          if (!this.isHandoverByDepartmentEnabled) {
            return true;
          }

          const userNoDept = _.isEmpty(this.userProfileDepartmentIds);
          const departmentIdNotSet = !report.departmentId;
          const userBelongToDept = _.includes(this.userProfileDepartmentIds, report.departmentId);
          return userNoDept || departmentIdNotSet || userBelongToDept;
        });

      return reports;
    },
  },
};
</script>
