<template>
  <div>
    <span
      class="has-text-grey"
      style="margin-left: 1.5em; margin-right: 3.5em; fontsize: 14px; margin-bottom: 1em"
    >
      {{ setting.label || name }}
    </span>

    <el-button
      :disabled="setting.disabled ? true : false"
      :type="setting.buttonType ? setting.buttonType : ''"
      :size="setting.size ? setting.size : ''"
      @click="setting.onClick"
    >
      {{ setting.text }}
    </el-button>

    <Tooltip :label="setting.label || name" :content="setting.detail" />
  </div>
</template>

<script>
import Tooltip from "../Tooltip.vue";
export default {
  components: { Tooltip },
  props: ["value", "setting", "name"],
  mounted() {
    if (this.value[this.name] == null) {
      if (this.setting.default != null) {
        this.$set(this.value, this.name, this.setting.default);
      } else {
        this.$set(this.value, this.name, null);
      }
    }
  },
};
</script>
