import Router, { RawLocation, Route } from "vue-router";
import { ErrorHandler } from "vue-router/types/router";

//referenced: https://github.com/vuejs/vue-router/issues/2881
const originalPush = Router.prototype.push;

// @ts-ignore
Router.prototype.push = function push(
  location: RawLocation,
  onResolve: Function | undefined,
  onReject: ErrorHandler | undefined
): Promise<Route> | void {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  // @ts-ignore
  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      // resolve err
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};

export default Router;
