<template>
  <div id="form_color_settings">
    <div class="color_settings_header">
      <span>Color Settings</span>
    </div>
    <div class="color_settings_body">
      <el-form class="color_settings_form">
        <div
          style="display: flex; margin-bottom: 1em"
          v-for="(item, colorId) in colorSchema"
          :key="colorId"
        >
          <el-input v-model="form.settings.options.colors[colorId]" style="flex: 1">
            <div slot="prepend" style="width: 140px">
              {{ item.label }}
              <Tooltip :label="item.label" :content="item.detail" />
            </div>
          </el-input>
          <el-color-picker v-model="form.settings.options.colors[colorId]" />
        </div>
        <div>
          <div>
            <div class="select-prepend">
              <span>Font Family</span>
              <Tooltip label="Font Family" content="The font family that the form will use" />
            </div>
            <el-select
              v-model="form.settings.options.colors.fontFamily"
              placeholder="Select"
              style="width: 50%"
            >
              <el-option
                v-for="(item, index) in fontFamily"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div
          style="
            border: 1px solid #ebeef5;
            margin-top: 20px;
            box-sizing: border-box;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          "
        >
          <p style="font-size: 18px; font-weight: bold; padding: 10px; margin: 0">Custom CSS</p>
          <codemirror v-model="form.settings.options.colors.customCSS" :options="editorOptions" />
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import Tooltip from "@/components/ModuleEditor/Tooltip.vue";

export default {
  components: { Tooltip },
  name: "FormColorSetting",
  data() {
    return {
      colorSchema: {
        // borderColor: {
        //   label: "Border color",
        //   detail: "The color of the border of the form",
        // },
        labelColor: {
          label: "Label color",
          detail: "Label color of form",
        },
        activeColor: {
          label: "Active color",
          detail: "Color of the input when it is activated",
        },
        errorColor: {
          label: "Error color",
          detail: "The color of error in the form",
        },
        readOnlyColor: {
          label: "Read-only color",
          detail: "Color of the field when it is read-only",
        },
        primaryButtonColor: {
          label: "Primary button",
          detail: "Primary button color of the form",
        },
        primaryButtonHoverColor: {
          label: "Primary button hover",
          detail: "Primary button color when hover",
        },
        infoButtonColor: {
          label: "Info button",
          detail: "Info button color of the form",
        },
        infoButtonColorHover: {
          label: "Info button hover",
          detail: "Info button color when hover",
        },
      },
      fontFamily: [
        "Helvetica Neue,Helvetica,Arial,sans-serif",
        "Montserrat,sans-serif",
        "Arial, Helvetica,sans-serif",
        `"Trebuchet MS",Helvetica,sans-serif`,
        "Tahoma, Geneva,sans-serif",
        "Calibri",
      ],
      editorOptions: {
        lineNumbers: true,
        line: false,
        styleActiveLine: true,
        keyMap: "sublime",
        mode: "css",
        theme: "monokai",
        tags: {
          style: [
            ["type", /^text\/(x-)?scss$/, "text/x-scss"],
            [null, null, "css"],
          ],
        },
      },
    };
  },
  props: {
    form: Object,
  },
  methods: {},
  created() {
    if (!_.has(this.form.settings.options, "colors")) {
      this.$set(this.form.settings.options, "colors", {
        // borderColor: "#d31145",
        labelColor: "#000",
        activeColor: "#66afe9",
        errorColor: "#FF5500",
        readOnlyColor: "#eee",
        primaryButtonColor: "#d31145",
        primaryButtonHoverColor: "#E86487",
        infoButtonColor: "#485B70",
        infoButtonColorHover: "#769FAB",
        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
        customCSS: "",
      });
    }
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/colors.scss";

$main-color: $color-primary;

@mixin header-style {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $main-color;
  color: #fff;
  min-height: 35px;
  font-size: 0.9em;
  font-weight: bold;
  padding: 5px 10px;
}

#form_color_settings {
  border: 2px solid $main-color;
  border-radius: 10px 20px;
  overflow: hidden;

  .color_settings_header {
    @include header-style();
  }
}

.color_settings_form {
  padding: 10px;
}

.select-prepend {
  background-color: $color-light;
  color: $color-dark;
  vertical-align: middle;
  display: inline-block;
  border: 1px solid $color-light;
  border-right: 0;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0 20px;
  width: 140px;
  height: 38px;
  line-height: 40px;
  font-size: 14px;
}
</style>
