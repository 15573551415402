<template>
  <advanced-json-editor
    :json.sync="this.form.settings.fields"
    :height="640"
    @error="onError"
    @updated="onUpdated"
  ></advanced-json-editor>
</template>

<script>
import AdvancedJsonEditor from "./AdvancedJsonEditor";
import _ from "lodash";

export default {
  name: "FormDetailFields",
  components: {
    AdvancedJsonEditor,
  },
  props: {
    form: Object,
  },
  methods: {
    onError() {
      //TODO: show error
    },
    onUpdated(val) {
      this.form.settings.fields = _.cloneDeep(val);
    },
  },
};
</script>

<style scoped></style>
