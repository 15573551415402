import flat from "flat";

function flatten(value) {
  if (!value) {
    return "";
  }
  try {
    const output = JSON.parse(value);
    if (typeof output === "number") {
      throw new Error();
    }
    return flat(output);
  } catch (e) {
    return value;
  }
}

/**
 * Check if valid json in string format
 * @returns {true}
 */
function isFlatAble(data) {
  return data.startsWith("{") && data.endsWith("}");
}

export { flatten, isFlatAble };
