import _ from "lodash";
import gql from "graphql-tag";

import { pushNotification } from "@/helperMethods/pushNotification";
import { simplifyMessage } from "@/helperMethods/livechat/simplifyMessage";
import { doesChatBelongsToCurrentAgent } from "@/helperMethods/livechat/isAgentInChat";
import { getDisplayId } from "@/helperMethods/livechat/util";
import { isSelf } from "@/helperMethods/editor/nodeList";

export default {
  methods: {
    subscribeLivechatInviteAgent() {
      const component = this as any;
      component.$apollo.addSmartSubscription("livechatInviteAgent", {
        query: gql`
          subscription {
            livechatInviteAgent
          }
        `,
        result({ data }) {
          const component = this as any;
          const inviteFrom = data.livechatInviteAgent.inviter;
          const memo = data.livechatInviteAgent.memoComment || "";
          const chat = data.livechatInviteAgent.chat;
          const transferRedacted = data.livechatInviteAgent.transferRedacted || false;
          const header = `${inviteFrom} has invited you to participate a livechat session. `;
          let confirmationMessage = header;
          if (memo) {
            confirmationMessage += `<br/><br/><strong>Notes:</strong><br/>"${memo}"<br/><br/>`;
          }
          confirmationMessage += `Do you want to accept this invitation?`;

          const pushNotifMessage = {
            title: `Livechat Invitation from ${inviteFrom}`,
            body: `Notes: ${memo || "-"}`,
            icon: "./img/icons/mstile-150x150.png",
          };
          pushNotification(pushNotifMessage, 5000, () => {
            window.focus();
            if (component.$route.path !== "/livechat") {
              component.$router.push("/livechat").catch(() => {});
            }
          });

          component
            .$confirm(confirmationMessage, "Invitation", {
              confirmButtonText: "Join chat",
              cancelButtonText: "Cancel",
              dangerouslyUseHTMLString: true,
              closeOnClickModal: false,
            })
            .then(() => {
              component.$router.push("/livechat");
              const email = _.get(component, "$store.state.profile.email", "");
              const name = _.get(component, "$store.state.profile.name", "");
              if (email) {
                component.$store
                  .dispatch("LIVECHAT_ACCEPT_INVITATION", {
                    chat,
                    transferRedacted,
                  })
                  .then((acceptedInvitation) => {
                    if (acceptedInvitation) {
                      component.$notify.success({
                        title: "Success",
                        message: "Successfully accepted chat invitation",
                        position: "bottom-right",
                      });
                    }
                  })
                  .catch((err) => {
                    component.notifyError("Encountered error accepting live chat invitation");
                  });
              }
            })
            .catch((action) => {
              component.$notify.info({
                title: "Info",
                message: "You dismissed the invitation.",
                position: "bottom-right",
              });
            });
        },
      });
    },
    subscribeLivechatAgentConnectionStatus() {
      const component = this as any;
      component.$apollo.addSmartSubscription("livechatAgentConnectionStatus", {
        query: gql`
          subscription {
            livechatAgentConnectionStatus
          }
        `,
        fetchPolicy: "network-only",
        result(data) {
          const connectedAgents = this.storeConnectedAgents(data);
          this.storeLatestAgentStatus(connectedAgents);
        },
      });
    },
    subscribeNewLivechatMessageFromUser() {
      const component = this as any;
      component.$apollo.addSmartSubscription("newLivechatMessage", {
        query: gql`
          subscription {
            newLivechatMessage
          }
        `,
        result({ data }) {
          const newMessage = _.get(data, "newLivechatMessage");

          this.$store
            .dispatch("HANDLE_NEW_MESSAGE", {
              newMessage,
            })
            .then((chat) => {
              if (_.isEmpty(chat)) {
                return;
              }

              const simplifiedMessage = simplifyMessage(newMessage.message) || {
                text: "",
              };

              // Browser notification
              const agentIsOnline = _.get(this, "$store.getters.isAgentOnline", false);
              const isUserMessage =
                newMessage.message.type !== "reply" && newMessage.message.type !== "agent";

              const agentEmail = _.get(this.$store, "state.profile.email");
              const chatUserId = newMessage.user_id;
              const chatBelongsToCurrentAgent = doesChatBelongsToCurrentAgent(
                agentEmail,
                chatUserId
              );

              if (isUserMessage && agentIsOnline && chatBelongsToCurrentAgent) {
                const displayIdPayload = {
                  ...chat.stateVariables,
                  chatId: chat.RowKey,
                  user_id: chat.user_id,
                };
                const displayId = getDisplayId(displayIdPayload);
                if (simplifiedMessage.text) {
                  const pushNotifMessage = {
                    title: `New message from user: ${displayId}`,
                    body: simplifiedMessage.text,
                    icon: "./img/icons/mstile-150x150.png",
                  };
                  pushNotification(pushNotifMessage, 2000, () => {
                    window.focus();
                    if (this.$route.path !== "/livechat") {
                      this.$router.push("/livechat").catch(() => {});
                    }
                  });
                }

                this.$nextTick(() => {
                  const selectedChatRowKey = _.get(this.selectedChat, "RowKey", "");
                  const isCurrentSelectedChatMessage = chat.RowKey === selectedChatRowKey;
                  if (this.$refs["interactionsPanel"] && isCurrentSelectedChatMessage) {
                    _.delay(() => {
                      this.$refs["interactionsPanel"] &&
                        this.$refs["interactionsPanel"].scrollToBottom();
                    }, 100);
                  }
                });
              }
            });
        },
      });
    },
    subscribeToModuleUpdate() {
      const component = this as any;
      component.$apollo.addSmartSubscription("moduleUpdated", {
        query: gql`
          subscription {
            moduleUpdated
          }
        `,
        fetchPolicy: "network-only",
        result(data) {
          const { author, modules = {} } = data?.data?.moduleUpdated || {};
          const isInvalidData = !_.isString(author) || _.isEmpty(modules);
          if (isInvalidData) return;

          component.$store.commit("SET_MODULES", { Bot: { modules } });

          if (!isSelf(author))
            component.$notify.info({
              title: "Configuration Change",
              message: `Settings have been updated by ${author}`,
              position: "bottom-right",
            });
        },
        error(error) {
          console.error("Subscription error on moduleUpdated:", error);
          component.$notify.error({
            title: "Subscription Error",
            message: "Failed to subscribe to module updates.",
            position: "bottom-right",
          });
        },
      });
    },
  },
};
