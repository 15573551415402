<template>
  <el-card class="box-card">
    <transition-group name="section-list">
      <div class="interactive-message-section" v-for="(item, idx) in list" :key="idx + 1">
        <WhatsAppContactInfoCard :title="title" :obj="item" :fields="fields" />
        <button class="section-delete-button" @click.prevent="deleteGroupField(list, idx)">
          <i class="el-icon-close"></i>
        </button>
      </div>
    </transition-group>
  </el-card>
</template>

<script>
import WhatsAppContactInfoCard from "@/components/WhatsAppContactInfoCard";

export default {
  name: "WhatsAppContactMessage",
  components: {
    WhatsAppContactInfoCard,
  },
  props: ["title", "list", "fields"],
  methods: {
    deleteGroupField(list, index) {
      list.splice(index, 1);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/colors.scss";

.interactive-message-section {
  border: 1px solid $color-light;
  padding: 0px 25px;
  padding-top: 25px;
  padding-bottom: 15px;
  margin: 10px 0;
  border-radius: 5px;
  position: relative;
}

.section-delete-button {
  position: absolute;
  right: 6px;
  top: 6px;
  border: 0;
  padding: 0;
  background: #fff;
  font-size: 16px;
  color: #333;
}
.section-delete-button:hover {
  color: $color-danger;
}

.section-list-enter-active,
.section-list-leave-active {
  transition: all 0.5s ease;
}
.section-list-enter-from,
.section-list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
