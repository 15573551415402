<template>
  <div style="text-align: center">
    <el-input
      size="mini"
      v-model="sideEffect.data.key"
      placeholder="key"
      style="margin-bottom: 10px; width: 60%"
    >
      <template slot="prepend">Key</template>
    </el-input>

    <div style="margin-bottom: 10px">
      <el-radio-group size="mini" v-model="sideEffect.data.type" @change="updateDataType">
        <el-radio-button label="string" border>Text</el-radio-button>
        <el-radio-button label="number" border>Number</el-radio-button>
        <el-radio-button label="boolean" border>True/False</el-radio-button>
        <el-radio-button label="null" border>Value from user</el-radio-button>
      </el-radio-group>
    </div>

    <div
      v-if="sideEffect.data.type === 'string' && typeof sideEffect.data.value === 'string'"
      class="el-input el-input--mini el-input-group el-input-group--prepend"
      style="width: 60%; margin-bottom: 10px"
    >
      <div class="el-input-group__prepend">Value</div>
      <input
        v-model="sideEffect.data.value"
        autocomplete="off"
        placeholder="Input value"
        class="el-input__inner"
      />
    </div>

    <div
      v-if="sideEffect.data.type === 'number' && typeof sideEffect.data.value === 'number'"
      class="el-input el-input--mini el-input-group el-input-group--prepend"
      style="width: 60%; margin-bottom: 10px"
    >
      <div class="el-input-group__prepend">Value</div>
      <input
        v-model.number="sideEffect.data.value"
        type="number"
        autocomplete="off"
        placeholder="Input value"
        class="el-input__inner"
      />
    </div>

    <div
      style="margin-bottom: 10px"
      v-if="sideEffect.data.type === 'boolean' && typeof sideEffect.data.value === 'boolean'"
    >
      <input v-model="sideEffect.data.value" type="radio" name="type-boolean" :value="true" />
      <label style="margin-right: 20px">True</label>
      <input v-model="sideEffect.data.value" type="radio" name="type-boolean" :value="false" />
      <label>False</label>
      <br />
    </div>

    <el-autocomplete
      v-model="sideEffect.data.next"
      :fetch-suggestions="getContentNodes"
      placeholder="Select target node"
      size="mini"
      style="width: 60%"
    >
      <div slot="prepend">Next</div>
      <el-button
        slot="append"
        icon="el-icon-d-arrow-right"
        @click="selectNode({ type: 'content', id: valueData.next })"
      />
    </el-autocomplete>
  </div>
</template>
<script>
export default {
  props: ["sideEffect"],
  data() {
    return {
      dataValue: null,
    };
  },
  beforeMount() {
    if (!this.sideEffect.data || !_.isPlainObject(this.sideEffect.data)) {
      this.sideEffect.data = {};
    }
  },
  methods: {
    getContentNodes(value, cb) {
      if (value) {
        cb(this.$store.state.search.content.search(value));
      }
    },
    selectNode({ type, id }) {
      this.$store.dispatch("SELECT_NODE", { type, id });
      this.$router.push("/editor");
    },
    updateDataType(value) {
      switch (this.sideEffect.data.type) {
        case "null":
          this.sideEffect.data.value = null;
          break;
        case "boolean":
          this.sideEffect.data.value = true;
          break;
        case "number":
          this.sideEffect.data.value = 0;
          break;
        default:
          this.sideEffect.data.value = "";
      }

      this.$forceUpdate();
    },
  },
};
</script>
