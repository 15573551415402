export const downloadArrayAsCSVFile = (arr: string[][], fileName: string) => {
  let csvContent = "data:text/csv;charset=utf-8,";
  arr.forEach((rowArray) => {
    const row = rowArray.join(",");
    csvContent += row + "\r\n";
  });
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};
