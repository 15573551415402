<template>
  <span>
    <div class="card-container">
      <el-card
        :body-style="{ padding: 0, whitespace: 'normal', 'text-align': 'left' }"
        :style="{
          overflow: 'visible',
          width: '300px',
          'border-color': editable && !valid ? 'red' : 'lightgrey',
        }"
        @click="$emit('selectCard')"
      >
        <div
          name="image"
          style="
            height: 160px;
            overflow: hidden;
            background-position: 50% 50%;
            background-size: cover;
          "
          :style="[validationStyle('image'), imageDiv, { padding: '5px 10px' }]"
          @click="edit"
        >
          <el-input
            v-if="editable"
            v-show="edit_image"
            ref="image_input"
            v-model.trim="card.image"
            name="image"
            placeholder="image"
            required
            type="url"
            style="zIndex=2"
            @keyup.enter="save"
            @blur="save"
          >
            <el-button slot="prepend" icon="el-icon-picture" />
            <el-button slot="append" icon="el-icon-close" @click="card.image = null" />
          </el-input>
        </div>
        <div style="padding: 5px 10px" :style="validationStyle('text')">
          <div v-show="!edit_text" style="height: 42px; color: #323a4e" name="text" @click="edit">
            <strong>{{ card.text || "Title" | trim }}</strong>
          </div>
          <el-input
            v-show="edit_text"
            ref="text_input"
            v-model="card.text"
            name="text"
            required
            type="text"
            @keyup.enter="save"
            @blur="save"
          >
            <template slot="prepend">title</template>
            <template slot="append">{{ text_chars }}/80</template>
          </el-input>
        </div>
        <div style="padding: 5px 10px" :style="validationStyle('subtext')">
          <div
            v-show="!edit_subtext"
            name="subtext"
            style="color: grey; height: 80px; overflow-x: hidden; overflow-y: auto"
            @click="edit"
          >
            <p>{{ card.subtext || "Short description" | trim }}</p>
          </div>

          <el-input
            v-show="edit_subtext"
            ref="subtext_input"
            v-model="card.subtext"
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 5 }"
            size="large"
            name="subtext"
            placeholder="Short description"
            @blur="save"
          />

          <span
            v-if="editable"
            class="length-counter"
            :style="{ color: exceed_subtext_chars ? 'red' : 'lightgray' }"
            >{{ subtext_chars }}/80</span
          >
        </div>
        <div style="padding: 5px 10px" :style="validationStyle('url')">
          <div v-show="!edit_url" style="min-height: 42px" name="url" @click="edit">
            <el-tag type="primary" style="max-width: 100%; overflow: hidden">
              {{ card.url || "https://..." }}
            </el-tag>
          </div>
          <el-input
            v-show="edit_url"
            ref="url_input"
            v-model.trim="card.url"
            name="url"
            required
            type="url"
            placeholder="https://example.com/"
            @keyup.enter="save"
            @blur="save"
          >
            <el-button slot="prepend" icon="el-icon-share" />
          </el-input>
        </div>

        <Buttons
          v-if="showButtons"
          v-model="card.buttons"
          :editable="editable"
          @addButton="addButton('addButton')"
          @save-side-effect="$emit('save-side-effect')"
        />
        <div style="padding: 5px 10px; display: flex; justify-content: center; align-items: center">
          Padded:
          <el-switch
            style="margin-left: 5px"
            :value="card.options ? card.options.padded : false"
            v-on:change="changePaddedOption"
          />
        </div>
      </el-card>
    </div>
  </span>
</template>
<script>
import { getDefaultPlaceholderImage } from "@/helperMethods/util";

export default {
  name: "Card",
  components: {
    Buttons: () => import("@/components/Buttons/Index"),
  },
  props: {
    editable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showButtons: {
      type: Boolean,
      default() {
        return true;
      },
    },
    date: {
      type: String,
    },
    index: {
      type: Number,
      default: 0,
    },
    card: {
      default() {
        return {
          text: "",
          subtext: "",
          image: "",
          url: "",
          buttons: [],
          options: {
            padded: false,
          },
        };
      },
    },
  },
  data() {
    return {
      edit_text: !this.card.text,
      edit_subtext: !this.card.subtext,
      edit_image: !this.card.image,
      edit_url: !this.card.url,
    };
  },
  computed: {
    valid_text() {
      return this.card.text && this.card.text.length > 0;
    },
    valid_subtext() {
      return this.card.subtext && this.card.subtext.length > 0;
    },
    valid_url() {
      let pattern = /^https?:\/\/.+/i;
      return !this.card.url || pattern.test(this.card.url);
    },
    valid_image() {
      let url = /^https?:\/\/.+/i; // Do not check file type
      const valid = url.test(this.card.image);
      return this.card.image === "" || valid;
    },
    valid() {
      this.$emit("imageSafeToSave", this.valid_image);
      return !!(this.valid_text && this.valid_subtext && this.valid_image && this.valid_url);
    },
    imageDiv() {
      return {
        backgroundImage: `url(${this.card.image || getDefaultPlaceholderImage()})`,
      };
    },
    text_chars() {
      return this.card.text ? this.card.text.length : 0;
    },
    subtext_chars() {
      return this.card.subtext ? this.card.subtext.length : 0;
    },
    exceed_text_chars() {
      return this.text_chars > 80;
    },
    exceed_subtext_chars() {
      return this.subtext_chars > 80;
    },
  },
  methods: {
    addButton() {
      const newButton = {
        text: "Enter button text",
        type: "event",
        event: null,
        data: null,
        url: null,
        phone: null,
        webview_height_ratio: null,
      };
      if (Array.isArray(this.card.buttons)) {
        this.card.buttons.push(newButton);
      } else {
        // undefined ?
        this.card.buttons = [newButton];
      }
    },
    validationStyle(field) {
      return this["valid_" + field] ? null : { color: "red" };
    },
    edit({
      currentTarget: {
        attributes: {
          name: { value },
        },
      },
    }) {
      if (this.editable) {
        this["edit_" + value] = true;
        this.$emit("selectCard");
        this.$nextTick(() => {
          window.test = this.$refs[value + "_input"].$el;

          if (value === "subtext") {
            this.$refs[value + "_input"].$el.querySelector("textarea").focus();
          } else {
            this.$refs[value + "_input"].$el.querySelector("input").focus();
          }
        });
      }
    },
    save({
      currentTarget: {
        attributes: {
          name: { value },
        },
      },
    }) {
      this["edit_" + value] = false;
      if (this.$parent.editCard) {
        this.$parent.editCard({
          slot: this.index,
          card: this.card,
        });
      }
    },
    changePaddedOption(isPadded) {
      this.card.options = {
        padded: isPadded,
      };
      this.$forceUpdate();
    },
  },
};
</script>
<style scoped>
.card-container {
  vertical-align: top;
  width: 310px;
  display: inline-block;
  margin: auto 8px;
  white-space: normal;
  text-align: center;
}

.length-counter {
  float: right;
}
</style>
