<template>
  <div>
    <PowerBI v-if="isLoaded" :user-profile-department-ids="userProfileDepartmentIds" />

    <!-- For Kibana/Grafana -->
    <URLEmbedCharts :user-profile-department-ids="userProfileDepartmentIds" />
  </div>
</template>
<script>
import PowerBI from "./PowerBI";
import URLEmbedCharts from "./URLEmbedCharts";

export default {
  data() {
    return {
      isLoaded: false,
    };
  },
  components: {
    PowerBI,
    URLEmbedCharts,
  },
  mounted() {
    if (_.isEmpty(this.departments)) {
      this.isLoaded = false;
      this.$store.dispatch("FETCH_DEPARTMENTS").finally(() => {
        this.isLoaded = true;
      });
    } else {
      this.isLoaded = true;
    }
  },
  computed: {
    departments() {
      const depts = _.get(this.$store, "state.department.departments", []);
      return depts;
    },
    userProfileDepartmentIds() {
      const userProfileDepartments = _.get(
        this.$store,
        "state.profile.app_metadata.departments",
        []
      );
      const depts = _.chain(this.departments)
        .intersectionWith(userProfileDepartments, (department, userProfileDepartment) => {
          return department.name === userProfileDepartment;
        })
        .map("id")
        .value();

      return depts;
    },
  },
};
</script>
