import * as _ from "lodash";

let flowInvalidComponents: string[] = [];

const flowTitleValid = (flowObject: any) => {
  return flowObject.title !== "";
};

const questionValidator = (
  questionVariable: string | undefined | object,
  variable: { name: string; checkFor: string[] }
) => {
  const typeofValue: string = typeof questionVariable;
  const { name: variableName, checkFor } = variable;
  if (["undefined", "null"].includes(typeofValue)) {
    throw new Error("Variable is invalid");
  }

  if (!questionVariable) {
    flowInvalidComponents.push(`${variableName}`);
    return false;
  }

  if (typeofValue === "string" && typeof questionVariable === "string") {
    _.map(checkFor, (check) => {
      if (check === "empty") {
        // Check for empty variable
        if (questionVariable.length === 0) {
          flowInvalidComponents.push(`${variableName}`);
          return false;
        }
      }
      if (check === "space") {
        // Check for spaces
        const trimmedQuestionVariable = questionVariable.trim();
        if (/\s/.test(trimmedQuestionVariable)) {
          flowInvalidComponents.push(`${variableName}`);
          return false;
        }
      }
    });
    return true;
  }
};

const questionValid = (question: any) => {
  const variablesToCheck = [
    { name: "name", checkFor: ["empty"] },
    { name: "question", checkFor: ["empty"] },
    { name: "variableName", checkFor: ["empty", "space"] },
  ];
  return variablesToCheck.map((variable) => questionValidator(question[variable.name], variable));
};

export function checkFlowValidity(flowObject: any) {
  let flowInvalid = false;
  flowInvalidComponents = [];

  const titleValid = flowTitleValid(flowObject);
  if (!titleValid) {
    flowInvalid = true;
    flowInvalidComponents.push("title");
  }

  const sections = flowObject.sections;
  const sectionValidationArray: (true | false)[] = _.chain(sections)
    .map((section) => section.questions)
    .flattenDeep()
    .map((question: any) => questionValid(question))
    .flatMap()
    .value();

  const sectionsValid = sectionValidationArray.every((value: any) => value === true);

  if (!sectionsValid) {
    flowInvalid = true;
    // flowInvalidComponents.push("questions");
  }

  if (flowInvalid) {
    return {
      status: false,
      message: `${flowInvalidComponents.join(", ")} is/are invalid. Please rectify!`,
    };
  } else {
    return { status: true };
  }
}
