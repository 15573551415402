import { render, staticRenderFns } from "./SystemVariables.vue?vue&type=template&id=613ece04&scoped=true&"
import script from "./SystemVariables.vue?vue&type=script&lang=js&"
export * from "./SystemVariables.vue?vue&type=script&lang=js&"
import style0 from "./SystemVariables.vue?vue&type=style&index=0&id=613ece04&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "613ece04",
  null
  
)

export default component.exports