<script>
import { Bar } from "vue-chartjs";
import _ from "lodash";

export default {
  extends: Bar,
  props: ["groupedData"],
  mounted() {
    this.renderRatingChart(this.groupedData);
  },
  methods: {
    renderRatingChart(val) {
      const stepSize = _.ceil(_.max(Object.values(val)) / 5) || 1;
      this.renderChart(
        {
          labels: ["1", "2", "3", "4", "5"],
          datasets: [
            {
              backgroundColor: ["#FF4401", "#FF8D01", "#FFD80F", "#99FD20", "#04FF14"],
              data: Object.values(
                _.assign(
                  {
                    1: 0,
                    2: 0,
                    3: 0,
                    4: 0,
                    5: 0,
                  },
                  val
                )
              ),
            },
          ],
        },
        {
          tooltop: false,
          responsive: true,
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "# of votes",
                },
                ticks: { beginAtZero: true, stepSize },
              },
            ],
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Rating",
                },
                gridlines: false,
              },
            ],
          },
        }
      );
    },
  },
  watch: {
    groupedData(val) {
      this.renderRatingChart(val);
    },
  },
};
</script>
