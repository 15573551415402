<template>
  <el-main>
    <el-dialog
      title="Delete Topic"
      :visible.sync="deleteTopicDialogVisible"
      :before-close="handleClose"
      :close-on-press-escape="false"
      width="50%"
      center
    >
      <span>
        This will permanently delete
        <b>{{ selectedTopic }}</b> topic. This will mean losing all trained models, intents and
        examples of <b>{{ selectedTopic }} </b>. Are you sure you want to continue?
      </span>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">No</el-button>
        <el-button type="primary" @click="handleDelete">Yes</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>
<script>
export default {
  props: ["deleteTopicDialogVisible", "selectedTopic"],
  methods: {
    handleClose() {
      this.$emit("toggleTopicDeleteDialogVisible", false);
    },
    handleDelete() {
      this.$emit("deleteTopic");
    },
  },
};
</script>

<style scoped></style>
