<template>
  <el-container class="faq-container">
    <el-header style="z-index: 10">
      <h3 style="line-height: 36px">FAQ builder</h3>
      <small>Teach your bot how to answer more questions!</small>
      <div style="position: fixed; top: 10px; right: 10px; z-index: 10">
        <el-card body-style="padding-bottom: 0;">
          <el-button
            :disabled="!initialCheckedProgress"
            :loading="saving"
            type="primary"
            @click="save"
          >
            Save
          </el-button>

          <el-dropdown
            type="primary"
            style="margin: 10px"
            split-button
            @click="train"
            @command="trainCommand"
          >
            Train
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="rasa">KeyReply V2</el-dropdown-item>
              <el-dropdown-item command="luis">LUIS</el-dropdown-item>
              <el-dropdown-item command="ibm">IBM Watson</el-dropdown-item>
              <el-dropdown-item command="google">Google Dialog</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-button
            :disabled="!initialCheckedProgress || training"
            :loading="publishing"
            type="primary"
            @click="publish"
          >
            Publish
          </el-button>
          <el-progress
            v-if="training && initialCheckedProgress"
            :percentage="parseFloat(trainingProgress.toFixed(0))"
            color="#32CD32"
          />
          <br />
          <br />
          <span style="position: absolute">
            <small>
              <strong>Current AI was trained</strong>
              {{ currentModelTime }}
            </small>
          </span>
          <br />
          <el-collapse>
            <el-collapse-item title="Show previous AI versions" name="1">
              <el-table :data="models" max-height="320" highlight-current-row>
                <el-table-column prop="formattedModelId" label="ID">
                  <template slot-scope="props">
                    <strong v-if="props.row.modelId === publishedModel">
                      {{ props.row.formattedModelId }}
                    </strong>
                    <span v-else>{{ props.row.formattedModelId }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="status" min-width="70" label="Status">
                  <template slot-scope="props">
                    <i
                      v-if="props.row.status === 'Completed'"
                      style="vertical-align: middle; margin-left: 20px"
                      class="el-icon-check has-text-success"
                    />
                    <i
                      v-else-if="props.row.status === 'Failed'"
                      style="vertical-align: middle; margin-left: 25px"
                      class="el-icon-close has-text-danger"
                    />
                    <i
                      v-else-if="props.row.status === 'In Progress'"
                      style="vertical-align: middle; margin-left: 25px"
                      class="el-icon-loading"
                    />
                  </template>
                </el-table-column>
                <el-table-column label="Publish" min-width="90">
                  <template slot-scope="props">
                    <i
                      v-if="props.row.modelId === publishedModel"
                      style="vertical-align: middle; margin-left: 20px"
                      class="el-icon-check has-text-success"
                    />
                    <el-button
                      v-else
                      :disabled="props.row.status === 'In Progress'"
                      size="mini"
                      @click="publish(props.row.modelId)"
                    >
                      Publish
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-collapse-item>
          </el-collapse>
        </el-card>
      </div>
    </el-header>

    <el-main>
      <el-tabs :value="activeTab">
        <!-- Training Dataset Tab -->
        <el-tab-pane name="dataset" label="Training Dataset">
          <el-row>
            <div>
              <el-button slot="reference" type="primary" @click="dialogUploadVisible = true">
                Import
              </el-button>
              <el-button type="primary" @click="exportQnA">Export</el-button>

              <el-button
                type="primary"
                icon="el-icon-plus"
                @click="createNewLabel({ question: '', answer: '' })"
              >
                Add new question
              </el-button>

              <el-button-group v-if="$store.state.showAdvanced">
                <el-button
                  v-for="lang in supportedLanguages"
                  :key="lang.code"
                  :plain="selectedLanguage.code !== lang.code"
                  size="mini"
                  type="primary"
                  @click="filterLanguage(lang)"
                >
                  {{ lang.name }}
                </el-button>
              </el-button-group>

              <el-tooltip content="Advanced mode for fixing common AI issues" placement="top">
                <el-button style="float: right" @click="debug = !debug">Advanced Mode</el-button>
              </el-tooltip>

              <el-dialog
                title="Import FAQ questions"
                :visible.sync="dialogUploadVisible"
                :before-close="handleClose"
                :close-on-press-escape="false"
                width="75%"
              >
                <el-row :gutter="10">
                  <el-col :md="10">
                    <el-input v-model="crawlTarget" placeholder="https://..." />
                  </el-col>
                  <el-col :md="4">
                    <el-button
                      :loading="crawling"
                      type="primary"
                      icon="el-icon-download"
                      @click="crawl"
                    >
                      Load FAQ from web page
                    </el-button>
                  </el-col>
                </el-row>

                <hr />

                <el-row :gutter="10">
                  <el-col>
                    <el-upload
                      drag
                      class="upload-demo"
                      action="https://cors-anywhere.herokuapp.com/https://my-json-server.typicode.com/typicode/demo/posts"
                      :before-upload="beforeUpload"
                      :on-error="handleError"
                      :on-success="handleSuccess"
                      :on-remove="handleRemove"
                      :on-change="handleChange"
                      :file-list="fileList"
                    >
                      <i class="el-icon-upload upload-icon" />
                      <div class="el-upload__text">Drag file / click to upload</div>
                      <div slot="tip" class="el-upload__tip">csv file only</div>
                    </el-upload>
                    <el-button @click="handleClose(false)">Cancel</el-button>
                    <el-button
                      type="primary"
                      :disabled="this.uploadedContentList.length === 0"
                      @click="upload"
                    >
                      Upload
                    </el-button>
                    <el-tooltip
                      class="item"
                      style="float: right"
                      effect="dark"
                      content
                      placement="left"
                    >
                      <div slot="content">
                        Q: What happens if I import more than one answer for one question?
                        <br />A: Only the first one will be taken and it will OVERWRITE the existing
                        answer for that FAQ.
                        <br />
                        <br />Q: What if the same ID already exist in FAQ? <br />A: Your questions
                        will be added on as examples without replacing the current FAQ entirely.
                        <br />
                        <br />Q: What if there are other columns, do I need to remove them before
                        uploading? <br />A: No, they will be automatically ignored, only text under
                        the Id, Question, and Answer (case-sensitive) will be processed.
                        <br />
                        <br />Q: What if I import question(s) and/or example(s) that already exist
                        for that FAQ? <br />A: Currently, they will just be added into the example
                        list (duplicate checking is still WIP).
                        <br />
                        <br />Q: I am already uploading a .csv file, but it doesn't recognize it as
                        so. Why? <br />A: Check whether the first column of the file is ID instead
                        of Id, this is an issue that will detect the file as SYLK instead of .csv.
                        <br />
                        <br />Q: Must the column headers be on the first row? <br />A: Yes.
                      </div>
                      <el-button>
                        <i class="el-icon-question" />
                      </el-button>
                    </el-tooltip>
                    <el-button style="float: right" @click="downloadTemplate">
                      Download Template
                    </el-button>
                  </el-col>
                </el-row>
              </el-dialog>
            </div>

            <!-- <div style="float:left;margin-top: 15px;" v-if="score && showAdvanced">
              <span style="font-size: 1.2em;font-weight:bold;">Model Score: {{ score }}</span>
            </div>-->
            <div style="margin: 8px 0">
              <el-select
                v-model="selectedTags"
                style="width: 300px"
                :collapse-tags="true"
                multiple
                placeholder="Filter questions by tags"
                clearable
                filterable
              >
                <el-option v-for="tag in listTags" :key="tag" :label="tag" :value="tag" />
              </el-select>
              <el-input
                v-model="search"
                placeholder="search"
                class="search-bar"
                prefix-icon="el-icon-search"
              />
            </div>
          </el-row>
          <!-- Advance Mode -->
          <el-row>
            <el-card v-if="debug" style="float: right; margin-bottom: 8px">
              <el-tooltip content="Enable all questions" placement="top">
                <el-button type="primary" @click="enableAll">Enable All</el-button>
              </el-tooltip>

              <el-tooltip content="Disable all questions">
                <el-button type="primary" @click="disableAll">Disable All</el-button>
              </el-tooltip>

              <el-button type="primary" @click="autoTest">Run tests</el-button>

              <h4>The following questions contain examples that are not correctly classified.</h4>
              <!-- 
              <el-tooltip content="Disable all questions">
                <el-button @click="editAll" type="primary">Edit</el-button>
              </el-tooltip>
              -->
              <el-tooltip content="Test historical messages with the latest model" placement="top">
                <el-button type="primary" @click="backtest">Run Backtests</el-button>
              </el-tooltip>

              <br />

              <span v-for="(pair, index) in hasWrongEnabledPredictions" :key="index">
                <el-tooltip
                  placement="top"
                  :content="`${pair.variations.filter((v) => v.test).length}/${
                    pair.variations.length
                  }`"
                >
                  <el-tag
                    :key="index"
                    style="margin: 4px"
                    type="warning"
                    @click.native="search = pair.id"
                    >{{ pair.id }}</el-tag
                  >
                </el-tooltip>
              </span>

              <el-alert
                v-if="hasWrongPredictions.length === 0"
                style="display: inline"
                :closable="false"
                type="success"
                title="All FAQ issues are fixed! Bot is good to go!"
              />
            </el-card>
          </el-row>
          <el-table
            ref="dataset"
            v-loading="loadingDataset"
            element-loading-text="Loading..."
            border
            :data="visibleDataset"
            :row-key="(row, index) => row.id"
            :expand-row-keys="expandedRows"
            style="width: 100%"
          >
            <el-table-column label width="36">
              <template slot-scope="props">
                <el-tooltip
                  v-if="
                    props.row.question.trim().length === 0 || props.row.answer.trim().length === 0
                  "
                  content="Either question or answer cannot be blank"
                  placement="top"
                >
                  <el-checkbox
                    v-model="props.row.enabled"
                    :disabled="
                      props.row.question.trim().length === 0 || props.row.answer.trim().length === 0
                    "
                  />
                </el-tooltip>

                <el-checkbox v-else v-model="props.row.enabled" />
              </template>
            </el-table-column>

            <el-table-column label="Question" width="400" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-input
                  v-if="scope.row.edit"
                  v-model="scope.row.question"
                  @blur="handleBlur(scope.row)"
                />
                <div
                  v-else
                  style="width: 100%; height: 24px"
                  class="table-cell"
                  @click="scope.row.edit = true"
                >
                  {{ scope.row.question }}
                </div>
              </template>
            </el-table-column>

            <el-table-column label="Answer" min-width="400" prop="answer" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-input
                  v-if="scope.row.edit"
                  v-model="scope.row.answer"
                  type="textarea"
                  autosize
                  @blur="handleBlur(scope.row)"
                />
                <el-button-group v-else>
                  <div v-if="/^@/.test(scope.row.answer)">
                    <el-popover
                      placement="top-start"
                      title="Answer"
                      width="300"
                      trigger="hover"
                      :content="findAnswer(scope.row.id)"
                    >
                      <div slot="reference">
                        <el-button size="small" @click="selectNode(scope.row.answer)">
                          Go to {{ scope.row.answer }}
                        </el-button>
                        <el-button
                          icon="el-icon-edit"
                          size="small"
                          @click="scope.row.edit = true"
                        />
                      </div>
                    </el-popover>
                  </div>
                  <div
                    v-else
                    style="min-width: 300px; height: 24px"
                    class="table-cell"
                    @click="scope.row.edit = true"
                  >
                    {{ scope.row.answer }}
                  </div>
                </el-button-group>
              </template>
            </el-table-column>

            <el-table-column
              v-if="debug"
              label="Examples"
              width="90"
              sortable
              sort-by="variations.length"
            >
              <template slot-scope="scope">
                {{ scope.row.variations.length }}
              </template>
            </el-table-column>

            <el-table-column label="Tags" width="200">
              <template slot-scope="scope">
                <el-input
                  v-if="scope.row.tagInputVisible"
                  ref="saveTagInput"
                  v-model="scope.row.tagInputValue"
                  size="mini"
                  v-focus
                  @keyup.enter.native="newTagConfrim(scope.row)"
                  @blur="newTagConfrim(scope.row)"
                />
                <el-button
                  v-else
                  circle
                  icon="el-icon-plus"
                  class="button-new-tag"
                  size="mini"
                  @click="showTagInput(scope.row)"
                />
                <!-- Tags  -->
                <el-tooltip
                  v-for="tag in scope.row.tags"
                  :key="tag"
                  class="item"
                  effect="dark"
                  :content="tag"
                  :disabled="tag.length < 22"
                  placement="top"
                >
                  <el-tag
                    type="primary"
                    size="small"
                    :closable="true"
                    :disable-transitions="true"
                    @close="deleteTag(scope.row, tag)"
                    >{{ tag | trim(22) }}</el-tag
                  >
                </el-tooltip>
              </template>
            </el-table-column>

            <el-table-column type="expand">
              <template slot-scope="props">
                <el-row :gutter="16">
                  <el-col :span="18">
                    <el-input v-model="props.row.id" @blur="handleBlur(props.row)">
                      <span slot="prepend">Question ID:</span>
                    </el-input>
                  </el-col>
                  <el-col :span="3">
                    <el-button
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      @click="deleteQuestion(props.$index, props.row)"
                    >
                      Delete question
                    </el-button>
                  </el-col>
                  <el-col :span="3" style="text-align: right">
                    <el-checkbox v-model="props.row.enabled">Enabled</el-checkbox>
                  </el-col>
                </el-row>

                <el-row :gutter="8" style="margin: 8px 0">
                  <el-col :span="4">{{ props.row.variations.length }} examples</el-col>

                  <el-col v-if="debug" :span="4">
                    <el-rate
                      :value="props.row.variations.length / 2"
                      disabled
                      :allow-half="true"
                      :low-threshold="1"
                      :colors="['#FF4949', '#F7BA2A', '#13CE66']"
                      style="display: inline-block"
                    />
                  </el-col>

                  <el-col v-if="debug" :span="4">
                    <el-button size="small" type="primary" @click="testAll(props.row.id, false)">
                      Run Test
                    </el-button>
                  </el-col>
                  <el-col v-if="debug" :span="4">
                    Result:
                    {{ props.row.variations.filter((v) => v.test).length }}/{{
                      props.row.variations.length
                    }}
                  </el-col>
                </el-row>

                <el-row :gutter="8" style="margin: 8px 0">
                  <el-col :span="18">
                    <el-input
                      v-model="props.row.text"
                      type="textarea"
                      :autosize="true"
                      size="large"
                      placeholder="Type an example here..."
                    />
                  </el-col>
                  <el-col :span="3">
                    <el-button
                      style="width: 100%"
                      size="small"
                      type="primary"
                      icon="el-icon-plus"
                      @click="newVariation(props.row)"
                    >
                      Add examples
                    </el-button>
                  </el-col>
                  <el-col :span="3">
                    <el-dropdown>
                      <el-button
                        style="width: 100%"
                        :loading="generating"
                        size="small"
                        type="primary"
                        @click="paraphrase(props.row.id, 'en')"
                      >
                        Generate examples
                      </el-button>
                      <!-- FIXME (easy): use for loop -->
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="paraphrase(props.row.id, 'en')">
                          English
                        </el-dropdown-item>
                        <el-dropdown-item @click.native="paraphrase(props.row.id, 'ar')">
                          Arabic
                        </el-dropdown-item>
                        <el-dropdown-item @click.native="paraphrase(props.row.id, 'bn')">
                          Bengali
                        </el-dropdown-item>
                        <el-dropdown-item @click.native="paraphrase(props.row.id, 'zh-CN')">
                          Chinese
                        </el-dropdown-item>
                        <el-dropdown-item @click.native="paraphrase(props.row.id, 'fr')">
                          French
                        </el-dropdown-item>
                        <el-dropdown-item @click.native="paraphrase(props.row.id, 'de')">
                          German
                        </el-dropdown-item>
                        <el-dropdown-item @click.native="paraphrase(props.row.id, 'hi')">
                          Hindi
                        </el-dropdown-item>
                        <el-dropdown-item @click.native="paraphrase(props.row.id, 'id')">
                          Indonesian
                        </el-dropdown-item>
                        <el-dropdown-item @click.native="paraphrase(props.row.id, 'ja')">
                          Japanese
                        </el-dropdown-item>
                        <el-dropdown-item @click.native="paraphrase(props.row.id, 'ms')">
                          Malay
                        </el-dropdown-item>
                        <el-dropdown-item @click.native="paraphrase(props.row.id, 'pt')">
                          Portuguese
                        </el-dropdown-item>
                        <el-dropdown-item @click.native="paraphrase(props.row.id, 'ru')">
                          Russian
                        </el-dropdown-item>
                        <el-dropdown-item @click.native="paraphrase(props.row.id, 'es')">
                          Spanish
                        </el-dropdown-item>
                        <el-dropdown-item @click.native="paraphrase(props.row.id, 'tl')">
                          Tagalog
                        </el-dropdown-item>
                        <el-dropdown-item @click.native="paraphrase(props.row.id, 'th')">
                          Thai
                        </el-dropdown-item>
                        <el-dropdown-item @click.native="paraphrase(props.row.id, 'vi')">
                          Vietnamese
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>

                <el-table
                  :data="props.row.variations"
                  style="width: 100%"
                  :row-class-name="reservedStyle"
                >
                  <el-table-column label="Examples" prop="text" />
                  <el-table-column
                    v-if="debug"
                    width="50"
                    prop="test"
                    :filtered-value="debug ? [false, undefined] : [true, false, undefined]"
                    :filters="[
                      { text: 'Correct', value: true },
                      { text: 'Wrong', value: false },
                    ]"
                    :filter-method="filterVariations"
                  >
                    <template slot-scope="scope">
                      <el-button
                        v-if="scope.row.test === true"
                        size="mini"
                        circle
                        class="has-text-success"
                        icon="el-icon-check"
                      />
                      <el-button else size="mini" circle icon="el-icon-close" />
                    </template>
                  </el-table-column>
                  <el-table-column v-if="debug" width="160" label="Error">
                    <template slot-scope="scope">
                      <el-tag
                        v-if="scope.row.test !== true"
                        type="danger"
                        @click.native="search = scope.row.id"
                        >{{ scope.row.test || "No Result" }}</el-tag
                      >

                      <div
                        v-if="
                          dialogVisible &&
                          scope.row.id === candidate.id &&
                          scope.row.text === candidate.text
                        "
                      >
                        <el-autocomplete
                          v-model="chosenQuestion"
                          size="small"
                          style="width: 80%"
                          class="inline-input"
                          :fetch-suggestions="querySearch"
                          placeholder="Search"
                          @input="clearChosenLabel()"
                          @select="chooseLabel"
                        />
                        <el-button
                          v-if="chosenLabel"
                          type="text"
                          icon="el-icon-check"
                          @click="confirmReclassify(props.row)"
                        />
                        <el-button
                          v-else
                          type="text"
                          icon="el-icon-close"
                          @click="dialogVisible = false"
                        />
                      </div>
                      <el-button v-else size="mini" :plain="true" @click="openDialog(scope.row)">
                        Classify
                      </el-button>
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-if="debug"
                    width="100"
                    label="Confidence"
                    prop="score"
                    :formatter="formatScore"
                    sortable
                    :sort-method="sortVariations"
                  />
                  <el-table-column v-if="debug" width="90" label="Reserved" prop="reserved">
                    <template slot-scope="scope">
                      <el-checkbox v-model="scope.row.reserved" />
                    </template>
                  </el-table-column>
                  <el-table-column width="50">
                    <template slot-scope="scope">
                      <el-button
                        size="mini"
                        circle
                        icon="el-icon-delete"
                        @click="deleteVariation(props.row, scope.row.text)"
                      />
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>

        <!-- Converstational Data Tab -->
        <el-tab-pane name="data" label="Conversational Data">
          <div style="margin-top: -16px">Data &nbsp;&nbsp;</div>
          <div>
            <el-button type="primary" @click="exportConversationalData"> Export </el-button>
          </div>

          <div style="text-align: center">
            <el-card style="max-height: 240px; overflow: auto">
              <el-tag
                v-for="question in questions"
                :key="question[0]"
                style="cursor: pointer; margin: 4px"
                type="info"
                @click.native="
                  filterQuestion = question[0];
                  previewAutoClassify = [];
                "
              >
                {{ findQuestion(question[0]) || "No Answer" }}
                <el-badge :value="question[1]" :max="99" />
              </el-tag>
            </el-card>

            <br />
            <br />

            <el-button type="primary" @click="autoClassify">
              Evaluate with latest AI Model
            </el-button>

            <div>
              <el-slider
                v-model="autoClassifyThreshold"
                :step="0.05"
                :max="1"
                style="width: 200px; margin: auto"
              />
            </div>
            <el-button v-if="previewAutoClassify" type="success" @click="executeAutoClassify()">
              Add
              {{ previewAutoClassify.filter((p) => p.score > autoClassifyThreshold).length }}
              questions to training dataset
            </el-button>
            <br />
            <br />
            <el-input
              v-model="searchRawData"
              prefix-icon="el-icon-search"
              placeholder="search"
              class="search-bar"
            />
            <el-pagination
              :current-page.sync="currentPage"
              :page-size="50"
              layout="prev, pager, next"
              :total="inbox.length"
            />
          </div>

          <el-table
            :data="inbox.slice((currentPage - 1) * 50, currentPage * 50)"
            :default-sort="{ prop: 'RowKey', order: 'descending' }"
          >
            <el-table-column type="selection" width="55" />
            <el-table-column min-width="200" label="Message" sortable prop="question">
              <template slot-scope="scope">
                <el-tooltip>
                  <template slot="content">
                    {{ scope.row.RowKey }} |
                    {{ formatDate(scope.row.createdDate) }}
                  </template>
                  <span style="word-break: normal; white-space: normal; height: auto">{{
                    scope.row.question
                  }}</span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column min-width="400" label="Prediction" prop="prediction">
              <template slot-scope="scope">
                <el-popover
                  v-if="previewAutoClassify[scope.$index]"
                  placement="top-start"
                  title="Answer"
                  width="300"
                  trigger="hover"
                  :content="findAnswer(previewAutoClassify[scope.$index].label)"
                >
                  <el-tag
                    slot="reference"
                    style="word-break: normal; white-space: normal; height: auto"
                    >{{ findQuestion(previewAutoClassify[scope.$index].label) }}</el-tag
                  >
                </el-popover>

                <el-popover
                  v-else
                  placement="top-start"
                  title="Answer"
                  width="300"
                  trigger="hover"
                  :content="findAnswer(scope.row.prediction)"
                >
                  <el-tag
                    slot="reference"
                    style="word-break: normal; white-space: normal; height: auto"
                    >{{ findQuestion(scope.row.prediction) }}</el-tag
                  >
                </el-popover>
              </template>
            </el-table-column>

            <el-table-column min-width="100" label="Confident">
              <template slot-scope="scope">
                <el-tag
                  v-if="previewAutoClassify[scope.$index]"
                  :type="
                    previewAutoClassify[scope.$index].score > autoClassifyThreshold
                      ? 'success'
                      : 'danger'
                  "
                >
                  {{
                    previewAutoClassify[scope.$index].score > autoClassifyThreshold ? "Yes" : "No"
                  }}
                  ({{ Math.min(previewAutoClassify[scope.$index].score.toFixed(2), 1) }})
                </el-tag>
              </template>
            </el-table-column>

            <el-table-column label="Actions" width="350">
              <template slot-scope="scope">
                <div
                  v-if="dialogVisible && scope.row.RowKey === candidate.RowKey"
                  style="margin-top: 4px"
                >
                  <el-autocomplete
                    v-model="chosenQuestion"
                    style="width: 80%"
                    class="inline-input"
                    :fetch-suggestions="querySearch"
                    placeholder="Search"
                    @input="clearChosenLabel()"
                    @select="chooseLabel"
                  />
                  <el-button
                    v-if="chosenLabel"
                    type="success"
                    icon="el-icon-check"
                    @click="confirmLabel"
                  />
                  <el-button
                    v-else
                    :plain="true"
                    icon="el-icon-close"
                    @click="dialogVisible = false"
                  />
                </div>
                <div v-else>
                  <el-button
                    size="small"
                    :disabled="!scope.row.prediction"
                    type="success"
                    icon="el-icon-check"
                    @click="markAsCorrect(scope.row)"
                  />
                  <el-button
                    size="small"
                    type="danger"
                    icon="el-icon-delete"
                    @click="markAsDeleted(scope.row)"
                  />
                  <el-button size="small" @click="openDialog(scope.row)"> Classify </el-button>
                  <el-button size="small" @click="createNewLabel(scope.row)"> New FAQ </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>

      <el-card
        v-if="showTestWidget"
        :body-style="{ padding: 0, width: '400px', height: '600px' }"
        style="position: fixed; bottom: 10px; right: 10px; z-index: 1"
      >
        <el-container style="height: 100%; padding: 8px">
          <el-main :loading="testing">
            <i style="float: right" class="el-icon-close" @click="showTestWidget = false" />
            <div v-if="widgetTestResult">
              <h3>{{ findQuestion(widgetTestResult.label) }}</h3>
              <small style="float: right"
                >score: {{ widgetTestResult.probability.toFixed(2) }}</small
              >
              <br />
              <Bubble :value="{ text: findAnswer(widgetTestResult.label) }" />
            </div>
            <div class="muted">Type a message below to test the bot.</div>
          </el-main>
          <el-footer height="60" style="padding: 0">
            <el-input
              v-model="testQuery"
              v-focus
              type="text"
              placeholder="Type a test message..."
              @keyup.enter.native="test"
            />
          </el-footer>
        </el-container>
      </el-card>
      <el-button
        v-else
        type="primary"
        style="position: fixed; bottom: 10px; right: 40px; z-index: 1"
        size="mini"
        @click="showTestWidget = true"
      >
        Test Bot
      </el-button>
    </el-main>

    <!-- Back to top -->
  </el-container>
</template>
<script>
import Vue from "vue";
import XLSX from "xlsx";
import _ from "lodash";
import Bubble from "@/components/Bubble";
import moment from "moment";

export default Vue.extend({
  components: {
    Bubble,
  },
  data() {
    return {
      filterQuestion: true,
      previewAutoClassify: [],
      autoClassifyThreshold: 0.75,
      debug: false,
      currentPage: 1,
      crawlTarget: "",
      showTestWidget: false,
      testQuery: "",
      widgetTestResult: null,
      editAllToggle: false,
      expandAllToggle: false,
      saving: false,
      testing: false,
      generating: false,
      crawling: false,
      refreshing: false,
      publishing: false,
      loadingDataset: false,
      showTags: false,
      selectedTags: [],
      search: "",
      searchRawData: "",
      activeTab: "dataset",
      expandedRows: [],
      selection: [],
      chosenLabel: "",
      chosenQuestion: "",
      candidate: {},
      dialogVisible: false,
      dialogUploadVisible: false,
      showSuggestions: true,
      fileList: [],
      uploadedContentList: [],
      selectedLanguage: {},
      previousProgress: 0,
      destroyed: false,
      initialCheckedProgress: false,
      notified: false,
    };
  },
  computed: {
    trainingProgress: {
      get() {
        return this.$store.state.training.trainingProgress || 0;
      },
      set(value) {
        this.$store.state.training.trainingProgress = value;
      },
    },
    training: {
      get() {
        return !this.initialCheckedProgress || this.$store.state.training.training;
      },
      set(value) {
        this.$store.state.training.training = value;
      },
    },
    totalVariations() {
      return this.dataset.length + _.sumBy(this.dataset, (o) => o.variations.length);
    },
    listTags() {
      return _.chain(this.dataset).map("tags").flatten().uniq().sort().value();
    },
    currentModelTime() {
      const modelId = _.get(this, "$store.state.modules.faq.Alexandria.modelId");
      if (modelId && typeof modelId === "string") {
        return moment().to(
          moment(
            modelId.substring(modelId.lastIndexOf("_") + 1, modelId.lastIndexOf("-")) +
              ` ${modelId.substring(
                modelId.lastIndexOf("-") + 1,
                modelId.lastIndexOf("-") + 3
              )}:${modelId.substring(
                modelId.lastIndexOf("-") + 3,
                modelId.lastIndexOf("-") + 5
              )}:${modelId.substring(modelId.lastIndexOf("-") + 5)}`
          ).add(8, "hours")
        );
      } else {
        return "Never trained";
      }
    },
    score() {
      const score = this.$store.state.training.score.score;
      return score;
    },
    models() {
      if (this.$store.state.training.models) {
        const models = this.$store.state.training.models;
        const result = models.map((o) => {
          if (o.modelId) {
            const model = {
              modelId: o.modelId,
              formattedModelId: `${moment().to(
                moment(
                  o.modelId.substring(o.modelId.lastIndexOf("_") + 1, o.modelId.lastIndexOf("-")) +
                    ` ${o.modelId.substring(
                      o.modelId.lastIndexOf("-") + 1,
                      o.modelId.lastIndexOf("-") + 3
                    )}:${o.modelId.substring(
                      o.modelId.lastIndexOf("-") + 3,
                      o.modelId.lastIndexOf("-") + 5
                    )}:${o.modelId.substring(o.modelId.lastIndexOf("-") + 5)}`
                ).add(8, "hours")
              )}`,
              status: !o.status || o.status === "Completed" ? "Completed" : o.status,
            };

            return model;
          }
        });
        return result;
      }
      return this.$store.state.training.models;
    },
    latestModel() {
      return this.$store.state.training.latestModel;
    },
    publishedModel() {
      return _.get(this, "$store.state.modules.faq.Alexandria.modelId");
    },
    questions() {
      const questions = _.chain(this.$store.state.training.all)
        .filter((q) => q.archived !== true)
        .countBy((q) => q.prediction)
        .toPairs()
        .orderBy([(q) => q[1]], ["desc"])
        .value();
      return questions;
    },
    inbox() {
      if (this.$store.state.training.all) {
        return this.$store.state.training.all
          .filter((q) => q.archived !== true)
          .filter((q) => {
            if (q.question) {
              // if question if defined
              if (this.filterQuestion === q.prediction) {
                // if filter is the same as prediction
                if (q.question.toLowerCase().includes(this.searchRawData)) {
                  // if it includes search query
                  return true;
                } else {
                  return undefined;
                }
              } else {
                return undefined;
              }
            }

            return undefined;
          });
      } else {
        return [];
      }
    },
    archived() {
      if (this.$store.state.training.all) {
        return this.$store.state.training.all.filter((q) => q.archived === true);
      } else {
        return [];
      }
    },
    supportedLanguages() {
      return (
        this.$store.state.training.supportedLanguages || [
          {
            name: "English",
            code: "eng",
          },
          {
            name: "Mandarin Chinese",
            code: "cmn",
          },
        ]
      );
    },
    dataset() {
      return (
        _.map(this.$store.state.training.dataset, (pair) => {
          pair.tagInputVisible = false;
          pair.tagInputValue = "";
          pair.enabled = !!pair.enabled; // Cast to boolean type
          pair.tags = pair.tags || [];

          return pair;
        }) || []
      );
    },
    visibleDataset() {
      let search = this.search.toLowerCase();

      if (this.debug && !search) {
        return _.filter(
          this.dataset,
          (pair) => pair.enabled && pair.variations.find((v) => !v.test)
        );
      }

      return _.filter(this.dataset, (pair) => {
        // Filter by tags first
        if (this.selectedTags.length > 0) {
          if (_.intersection(this.selectedTags, pair.tags).length === 0) {
            return false;
          }
        }

        // Use search term
        if (search) {
          let idMatch = pair.id.toLowerCase() === search;
          let answerMatch = pair.answer.toLowerCase().includes(search);
          let questionMatch = pair.question.toLowerCase().includes(search);
          let variationMatch = pair.variations.find((variation) =>
            variation.text.toLowerCase().includes(search)
          );

          return _.some([idMatch, answerMatch, questionMatch, variationMatch]);
        } else {
          return true;
        }
      });
    },
    hasWrongPredictions() {
      return this.dataset.filter((pair) => pair.variations.find((v) => !v.test));
    },
    hasWrongEnabledPredictions() {
      return this.dataset.filter((pair) => pair.variations.find((v) => !v.test) && pair.enabled);
    },
  },
  mounted() {
    this.refresh();
  },
  beforeDestroy() {
    this.destroyed = true;
  },
  methods: {
    matchIdtoDataset(questionId) {
      return _.find(this.dataset, (data) => {
        const questionIdLowerCase = questionId.toLowerCase();
        const dataIdLowerCase = data.id.toLowerCase();
        if (dataIdLowerCase.includes(questionIdLowerCase)) {
          return true;
        }
        return false;
      });
    },
    formatDate(timestamp) {
      return moment(timestamp).format("DD-MM-YYYY HH:mm:ss");
    },
    poll(interval, modelId, startTime) {
      // Parameter 1: setInterval object to stop if there's any passed in
      // Parameter 2: modelId to check for the currently active brain
      // Parameter 3: initial starting time of training, to notify user if > 100 seconds
      this.$rest("get", `faq_check_progress?modelId=${modelId}`, {})
        .then((data) => {
          if (data.progress === "error") {
            this.training = false;
            this.trainingProgress = 0;
            this.$message({
              type: "error",
              message: "Training Failed.",
            });
          } else {
            if (data.progress < 100) {
              var temp = startTime.clone();
              if (!this.notified && moment().isAfter(temp.add(100, "seconds"))) {
                this.$notify({
                  title: "Come back later",
                  message:
                    "Due to training size, training will take a longer time. Refresh periodically to see if your model is updated.",
                  duration: 0,
                });
                this.notified = true;
                this.$store.dispatch("FETCH_MODELS");
              }

              if (interval) {
                clearInterval(interval);
              }
              var newInterval = this.updateProgressBar(data.progress);
              setTimeout(() => {
                if (this.training && !this.destroyed) {
                  // If training is still going on, and user haven't navigated away from page
                  this.poll(newInterval, modelId, startTime);
                }
              }, 5000);
            } else {
              if (interval) {
                clearInterval(interval);
              }
              this.trainingProgress = 100;
              this.$store
                .dispatch("FETCH_MODELS")
                .then(() => {
                  this.$message({
                    type: "success",
                    message: "Training Complete",
                  });
                })
                .catch(() => {
                  this.$message({
                    type: "error",
                    message: "An error occurred while fetching the latest models",
                  });
                })
                .finally(() => {
                  this.trainingProgress = 0;
                  this.training = false;
                });
            }
          }
        })
        .catch(() => {
          this.training = false;
          this.trainingProgress = 0;
          this.$message({
            type: "error",
            message: "An error occurred while updating progress",
          });
        });
    },
    downloadTemplate() {
      var link = document.createElement("a");
      link.download = "FAQ_template.csv";
      link.href = "/template/FAQ_template.csv";
      link.click();
    },
    filterLanguage(lang) {
      this.selectedLanguage = lang;
    },
    // Dataset Tab
    reservedStyle({ row }) {
      return row.reserved ? "warning-row" : "";
    },
    enableAll() {
      this.dataset.forEach((pair) => {
        pair.enabled = true;
      });
    },
    disableAll() {
      this.dataset.forEach((pair) => {
        pair.enabled = false;
      });
    },
    backtest() {
      this.$message({
        type: "success",
        message: "Running AI model backtests.",
      });

      this.$rest("get", "faq_backtest");
    },

    autoTest() {
      // Old implementation
      // this.hasWrongPredictions.forEach(pair => {
      //   this.testAll(pair.id);
      // });

      // setTimeout(this.save, 10000);
      this.hasWrongPredictions
        .map((pair) => pair.id)
        .reduce((promise, item) => {
          return promise.then(() => {
            return this.testAll(item, true);
          });
        }, Promise.resolve())
        .then(() => {
          this.save();
        });
    },

    // Conversation Data Tab
    autoClassify() {
      let rows = this.inbox;

      // console.log(rows.length); -> Unexpected console statement
      // console.log("Starting Auto Learning Procedure"); -> Unexpected console statement

      let questions = rows.map((q) => q.question);
      // console.table(questions); -> Unexpected console statement
      this.$store.dispatch("TEST_FAQ", { questions }).then((response) => {
        this.previewAutoClassify = response.map((row) => {
          if (row.predictions.length > 0) {
            row.label = row.predictions[0].label;
            row.score = row.predictions[0].confidence;
          }

          return row;
        });
      });
    },
    executeAutoClassify() {
      if (this.previewAutoClassify) {
        let confident = 0;
        let notConfident = 0;

        let toDelete = [];
        this.previewAutoClassify.forEach((row, index) => {
          if (row.score > this.autoClassifyThreshold) {
            confident++;

            toDelete.push(this.inbox[index]);
            this.newVariation({ id: row.label, text: row.text }, false);
          } else {
            notConfident++;
          }
        });

        toDelete.forEach(this.markAsDeleted);

        // console.log("Confidently classified " + confident + " questions"); -> Unexpected console statement
        // console.log("Ignored " + notConfident + " questions"); -> Unexpected console statement
        this.save();
      }
    },
    formatScore(row, column, value) {
      if (row.test === true) {
        return `${(100 * Math.min(value, 1)).toFixed(2)}%`;
      } else {
        return "";
      }
    },
    selectNode(answer) {
      let id = answer.substring(1);
      this.$store.dispatch("SELECT_NODE", { type: "content", id });
      this.$router.push("/editor");
    },
    showTagInput(row) {
      row.tagInputVisible = true;
      this.$forceUpdate();
    },
    newTagConfrim(row) {
      if (row.tagInputValue) {
        row.tags.push(row.tagInputValue);
      }

      row.tagInputValue = "";
      row.tagInputVisible = false;
      this.$forceUpdate();
    },
    deleteTag(row, tag) {
      row.tags.splice(row.tags.indexOf(tag), 1);
      this.$forceUpdate();
    },
    expandRow(row, isExpanded) {
      if (isExpanded) {
        this.expandedRows = [row.id];
      } else {
        this.expandedRows = [];
      }
    },
    approveSuggestion(action) {
      if (action.type === "ADD_VARIATION") {
        this.expandedRows = [action.data.id];
      }

      if (action.type === "LABEL") {
        this.activeTab = action.data;
      }

      this.$refs.suggestionsCarousel.next();
    },

    rejectSuggestion(action) {
      this.$refs.suggestionsCarousel.next();
    },

    handleBlur(row) {
      row.edit = false;
    },

    openDialog(row) {
      this.candidate = row;
      this.dialogVisible = true;
    },
    clearChosenLabel() {
      this.chosenLabel = "";
    },
    handleSelectionChange(val) {
      this.selection = val;
    },
    undoDeleted(row) {
      this.$store.dispatch("UNARCHIVE_TRAINING_RECORD", row);
    },
    markAsDeleted(row) {
      this.$store.dispatch("ARCHIVE_TRAINING_RECORD", row);
    },
    markAsCorrect(row) {
      let id = row.prediction;

      this.candidate = row;
      this.chosenLabel = id;

      this.confirmLabel();
    },
    querySearch(queryString, cb) {
      var results = queryString
        ? this.dataset.filter((data) =>
            _.contains(data.question.toLowerCase(), queryString.toLowerCase())
          )
        : this.dataset;

      cb(
        results.map((data) => {
          return {
            value: data.question,
            id: data.id,
          };
        })
      );
    },
    chooseLabel(item) {
      this.chosenLabel = item.id;
      this.chosenQuestion = item.value;
    },
    confirmLabel() {
      this.newVariation({
        id: this.chosenLabel,
        text: this.candidate.question,
      });
      this.$store.dispatch("ARCHIVE_TRAINING_RECORD", this.candidate);

      this.dialogVisible = false;
      this.activeTab = "dataset";
      this.expandedRows = [this.chosenLabel];
      this.chosenLabel = "";
      this.chosenQuestion = "";
    },
    confirmReclassify(question) {
      this.newVariation({
        id: this.chosenLabel,
        text: this.candidate.text,
      });
      this.deleteVariation({ id: question.id }, this.candidate.text);

      this.dialogVisible = false;
      this.activeTab = "dataset";
      this.expandedRows = [this.chosenLabel];
      this.chosenLabel = "";
      this.chosenQuestion = "";
    },
    findAnswer(id) {
      let pair = this.matchIdtoDataset(id);
      if (pair && /^@/.test(pair.answer)) {
        const contentId = pair.answer.substring(1).trim();
        let contentNode = this.$store.state.nodes.content[contentId];
        // const contentNodeText = _.get(contentNode, "content.text", "");
        return this.$extractText(contentNode);
      } else {
        if (pair) {
          if (pair.answer) {
            return pair.answer;
          } else {
            return "No Answer for this Prediction";
          }
        } else {
          return "";
        }
      }
    },
    findQuestion(questionId) {
      // TODO: change ids to be consistent. some FAQ_uuid and some Question_uuid
      let pair = this.matchIdtoDataset(questionId);
      if (pair) {
        return pair.question;
      } else {
        return questionId;
      }
    },
    deleteQuestion(index, row) {
      this.$store.commit("DELETE_QUESTION", row.id);
      this.save();
    },
    deleteVariation({ id }, text) {
      let pair = this.dataset.find((data) => data.id === id);
      let index = pair.variations.findIndex((variation) => variation.text === text);
      pair.variations.splice(index, 1);

      this.save();
      this.$set(this.expandedRows, 0, id);
    },
    test() {
      let questions = [this.testQuery];
      this.testing = true;

      return this.$store.dispatch("TEST_FAQ", { questions }).then((predictions) => {
        this.testing = false;
        if (predictions[0] && predictions[0].predictions.length > 0) {
          this.widgetTestResult = predictions[0].predictions[0];
        }
      });
    },
    testEverything() {
      this.testing = true;
      let labels = this.dataset.map((data) => data.id);
      let promises = labels.map(this.testAll, false);
      Promise.all(promises)
        .then(() => {
          // console.log("completed testing all variations");  -> Unexpected console statement
          this.save();
          this.testing = false;
        })
        .catch(() => {
          // console.log("Error while testing variations", error);  -> Unexpected console statement
          this.testing = false;
        });
    },

    paraphrase(label, target) {
      let pair = this.dataset.find((data) => data.id === label);

      if (pair) {
        let questions = _.sampleSize(
          pair.variations.map((a) => a.text),
          5
        );
        // console.log(
        //   "Using " + questions.length + " seed questions to generate paraphrase"
        // );  -> Unexpected console statement

        this.generating = true;
        this.$rest("post", "faq_paraphrase", {
          questions,
          target,
        })
          .then((result) => {
            this.generating = false;
            pair.text = result.generatedVariations.join("\n");
            this.$forceUpdate();

            this.$message({
              type: "success",
              message: `Generated ${result.total} variations.`,
            });
          })
          .catch(() => {
            this.generating = false;
          });
      }
    },
    testAll(label, wrongOnly) {
      let pair = this.dataset.find((data) => data.id === label);
      if (pair) {
        let questions = wrongOnly
          ? _.filter(pair.variations, (variation) => !variation.test).map((a) => a.text)
          : pair.variations.map((a) => a.text);
        return this.$store.dispatch("TEST_FAQ", { questions }).then((predictions) => {
          pair.variations.forEach((variation) => {
            // let prediction = predictions[index];
            let prediction = predictions.find((prediction) => prediction.text === variation.text);

            if (!prediction) {
              return;
            }

            if (prediction.predictions.length > 0) {
              let predictedLabel = prediction.predictions[0].label;
              let predictedProbability = prediction.predictions[0].probability;
              this.$set(variation, "test", predictedLabel === label ? true : predictedLabel);
              this.$set(variation, "score", predictedProbability);
            } else {
              this.$set(variation, "test", false);
              this.$set(variation, "score", 0);
            }
          });
        });
      } else {
        // console.warn("ID is not defined. Terminate test");  -> Unexpected console statement
      }
    },
    editAll() {
      this.editAllToggle = !this.editAllToggle;

      _.forEach(this.dataset, (value) => {
        value.edit = this.editAllToggle;
      });
    },
    expandAll() {
      this.expandAllToggle = !this.expandAllToggle;

      if (this.expandAllToggle === true) {
        let labels = this.dataset.map((data) => data.id);
        this.expandedRows = labels;
      } else {
        this.expandedRows = [];
      }
    },
    newVariation({ id, text, variations }, save = true) {
      let pair = this.dataset.find((data) => {
        return data.id === id;
      });

      if (!variations) {
        if (pair) {
          variations = pair.variations;
        } else {
          // console.log("Label not found " + id);  -> Unexpected console statement
          return;
        }
      }

      if (text && text.trim().length > 0) {
        let lines = text.split(/\n/);
        _.forEach(lines, (line) => {
          line = line.trim();
          if (line) {
            variations.unshift({ text: line });
          }
        });

        if (save) {
          this.save();
        }

        this.$set(this.expandedRows, 0, id);
        text = "";
      }
    },
    createNewLabel(row) {
      let question = row.question || "";
      let newLabel = {
        id: "QUESTION_" + Date.now(),
        edit: true,
        question,
        answer: "",
        variations: [],
      };

      this.dataset.unshift(newLabel);
      this.saving = true;
      this.$store.dispatch("ADD_FAQ_LABEL", newLabel).then(() => {
        this.saving = false;
        this.$set(this.expandedRows, 0, newLabel.id);
      });

      if (row) {
        this.$store.dispatch("ARCHIVE_TRAINING_RECORD", row);
        this.activeTab = "dataset";
      }
    },
    createImportLabel(id, edit, question, answer, variations) {
      let newLabel = {
        id: id,
        edit: edit,
        question: question,
        answer: answer,
        variations: variations,
      };

      this.dataset.unshift(newLabel);
      this.saving = true;
      this.$store.dispatch("ADD_FAQ_LABEL", newLabel).then(() => {
        this.saving = false;
        this.$set(this.expandedRows, 0, newLabel.id);
      });

      this.$store.dispatch("ARCHIVE_TRAINING_RECORD", { question, answer });
      this.activeTab = "dataset";
    },
    crawl() {
      if (this.crawlTarget) {
        this.crawling = true;
        this.$rest("post", "faq_crawl", {
          url: this.crawlTarget,
        })
          .then((data) => {
            this.crawling = false;
            if (data.question_answer_pairs) {
              // console.log(data.question_answer_pairs.length, "Questions");  -> Unexpected console statement

              let list = data.question_answer_pairs.map((pair) => {
                let cleanQuestion = pair.question.replace(/^\d+\\?\.? ?/, "");
                return {
                  id: pair.label,
                  question: cleanQuestion,
                  answer: pair.answer,
                  variations: [],
                  enabled: true,
                };
              });

              this.$store.state.training.dataset = this.dataset.concat(list);
            }
          })
          .catch(() => {
            // console.error(e);  -> Unexpected console statement
          });
      }
    },
    save() {
      this.saving = true;
      this.dataset.forEach((row) => {
        let distinctList = [];

        row.text = "";
        row.variations = row.variations.reduce((memo, variation) => {
          if (distinctList.includes(variation.text.toLowerCase())) {
            // console.log("Delete duplicate variation: " + variation.text);  -> Unexpected console statement
          } else {
            distinctList.push(variation.text.toLowerCase());
            memo.push(variation);
          }

          return memo;
        }, []);
      });

      return this.$store.dispatch("SAVE_FAQ_DATASET", { dataset: this.dataset }).then(() => {
        this.saving = false;

        this.$message({
          type: "success",
          message: "Saved",
        });

        this.progress = 1;
      });
    },
    updateProgressBar(currentProgress) {
      var increaseToCurrentInterval = null;
      if (this.trainingProgress < currentProgress) {
        const perInterval = (currentProgress - this.trainingProgress) / 5.0;
        increaseToCurrentInterval = setInterval(() => {
          this.trainingProgress += perInterval;
        }, 800);
      }
      setTimeout(() => {
        if (increaseToCurrentInterval) {
          clearInterval(increaseToCurrentInterval);
        }
        const perIntervalIncrease = (100 - this.trainingProgress) * (4 / this.totalVariations);
        var interval = setInterval(() => {
          if (!this.training || this.destroyed) {
            // Stops the progress bar when training is unexpectedly stopped or if user navigates away from page
            clearInterval(interval);
          }
          if (this.trainingProgress + perIntervalIncrease < currentProgress + 20) {
            this.trainingProgress += perIntervalIncrease;
          } else if (this.trainingProgress > currentProgress + 20) {
            // Stops the progress bar when the progress on the front end already matches what Alexandria is returning
            clearInterval(interval);
          } else {
            this.trainingProgress = currentProgress + 20;
            // Stops the progress bar when the progress on the front end already matches what Alexandria is returning
            clearInterval(interval);
          }
        }, 500);
        return interval;
      }, 4000);
    },
    trainCommand(command) {
      if (command === "rasa") {
        this.training = true;
        this.notified = false;
        const startTime = moment();
        this.save().then(() => {
          return this.$store
            .dispatch("TRAIN_FAQ", { dataset: this.dataset })
            .then((obj) => {
              this.trainingProgress = 0;
              this.previousProgress = 0;
              this.poll(null, obj.modelId, startTime);
              this.training = false;
            })
            .catch(() => {
              if (moment().isAfter(startTime.add(100, "seconds"))) {
                this.$notify({
                  title: "Come back later",
                  message:
                    "Due to training size, training will take a longer time. Refresh periodically to see if your model is updated.",
                  duration: 0,
                });
              } else {
                this.$message({
                  type: "error",
                  message: "Training Failed.",
                });
              }
              // clearInterval(interval);
              this.trainingProgress = 0;
              // console.log("error", error);  -> Unexpected console statement
              this.training = false;
            });
        });
      }
    },
    train() {
      this.training = true;
      this.notified = false;
      const startTime = moment();
      this.save().then(() => {
        return this.$store
          .dispatch("TRAIN_FAQ", { dataset: this.dataset })
          .then((obj) => {
            this.trainingProgress = 0;
            this.previousProgress = 0;
            this.poll(null, obj.modelId, startTime);
          })
          .catch(() => {
            if (moment().isAfter(startTime.add(100, "seconds"))) {
              this.$notify({
                title: "Come back later",
                message:
                  "Due to training size, training will take a longer time. Refresh periodically to see if your model is updated.",
                duration: 0,
              });
            } else {
              this.$message({
                type: "error",
                message: "Training Failed.",
              });
            }
            // clearInterval(interval);
            this.trainingProgress = 0;
            // console.log("error", error);  -> Unexpected console statement
            this.training = false;
          });
      });
    },
    publish(modelId) {
      this.publishing = true;
      // console.log("Publishing " + modelId);  -> Unexpected console statement
      this.$store.dispatch("PUBLISH_FAQ", modelId).then(() => {
        this.$store.dispatch("FETCH_MODELS").then(() => {
          this.publishing = false;

          this.$message({
            type: "success",
            message: "Content Saved",
          });
          this.$set(this.$store.state.modules.faq.Alexandria, "modelId", modelId);
          this.progress = 3;
          return this.$store.dispatch("FETCH_MODELS");
        });
      });
    },
    sortVariations(a, b) {
      if (a.test !== true && b.test === true) return -2;
      if (b.test !== true && a.test === true) return 2;

      return a.score > b.score ? 1 : -1;
    },
    filterVariations(value, row) {
      return row.test === value;
    },
    exportQnA() {
      let toExport = [];
      toExport.push(["Id", "Question", "Answer", "Expanded Answer", "Test", "Score"]);

      this.dataset.forEach((row) => {
        let expandedAnswer = "";

        if (/^@/.test(row.answer)) {
          let contentNode = this.$store.state.nodes.content[row.answer.substring(1)];
          if (contentNode) {
            expandedAnswer = this.$extractText(contentNode);
          }
        }

        toExport.push([row.id, row.question, row.answer]);

        row.variations.forEach((variation) => {
          toExport.push(["", variation.text, "", "", variation.test, variation.score]);
        });
      });

      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.aoa_to_sheet(toExport);
      XLSX.utils.book_append_sheet(wb, ws, "faq");

      const filename = this.$exportFilename("faq", "csv");
      XLSX.writeFile(wb, filename, {});
    },

    beforeUpload(file) {
      const isCSV = file.type === "text/csv" || file.name.endsWith(".csv");
      if (!isCSV) {
        this.$message.error("Uploaded file must be in CSV format!");
      }
      return isCSV;
    },
    handleClose(done) {
      this.dialogUploadVisible = false;
      this.handleRemove();

      if (done) done();
    },
    handleRemove() {
      this.fileList = [];
      this.uploadedContentList = [];
    },
    handleError(err) {
      // console.log("An error occurred while importing file!"); -> Unexpected console statement
      // console.log(err);  -> Unexpected console statement
    },
    handleSuccess(res, file) {
      var reader = new FileReader();
      reader.onload = ((vuecomponent) => {
        return function (e) {
          var data = e.target.result;
          var wb = XLSX.read(data, { type: "binary" });
          var uploadedData = XLSX.utils.sheet_to_json(
            wb.Sheets[wb.SheetNames[0]] // Only get the first sheet
          );
          var questionId = "";
          var toImport = {};
          uploadedData.forEach((obj, index) => {
            // console.log(obj);  -> Unexpected console statement
            // Can either be a new question or a variation
            if (obj.Question != null || obj.Id != null || obj.Tags) {
              // Check if the question is the first one encountered
              if (questionId == "" || obj.Id != null) {
                // Checks if the next row is a new question
                if (questionId != "" && obj.Id != questionId) {
                  vuecomponent.uploadedContentList.push(toImport);
                  toImport = {};
                }
                questionId = obj.Id;
              }
              if (questionId != "") {
                if (Object.keys(toImport).length === 0) {
                  toImport = {
                    id: questionId,
                    edit: true,
                    question: obj.Question == null ? "" : obj.Question,
                    answer: obj.Answer == null ? "" : obj.Answer,
                    variations: [],
                    tags: obj.Tags ? [obj.Tags] : [],
                  };
                } else {
                  if (
                    obj.Question &&
                    !_.find(toImport.variations, (o) => o.text === obj.Question)
                  ) {
                    toImport.variations.push({ text: obj.Question });
                  }
                  if (obj.Tags && toImport.tags.indexOf(obj.Tags) === -1) {
                    // console.log(
                    //   vuecomponent.$store.state.training.dataset.tags
                    // );  -> Unexpected console statement
                    toImport.tags.push(obj.Tags);
                  }
                }

                // Check if the row is the last in the dataset
                // console.log(index);  -> Unexpected console statement
                // console.log(uploadedData.length);  -> Unexpected console statement
                if (index == uploadedData.length - 1) {
                  // console.log("pushing");  -> Unexpected console statement
                  // console.log(toImport); -> Unexpected console statement
                  vuecomponent.uploadedContentList.push(toImport);
                }
              }
            }
          });
        };
      })(this);
      reader.readAsText(file.raw);

      this.fileList = [...this.fileList, file];
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-1);
      this.uploadedContentList = [];
    },

    upload() {
      this.$message({
        type: "success",
        message: `Uploaded content`,
      });
      this.uploadedContentList.forEach((obj) => {
        var existing = false;
        this.$store.state.training.dataset.forEach((faqObj) => {
          if (faqObj.id == obj.id) {
            if (obj.question && !_.find(faqObj.variations, (o) => o.text === obj.question)) {
              faqObj.variations.push({ text: obj.question });
            }

            obj.variations.forEach((variation) => {
              if (variation && !_.find(faqObj.variations, (o) => o.text === variation)) {
                faqObj.variations.push(variation);
              }
            });
            faqObj.tags = faqObj.tags.concat(
              _.filter(obj.tags, (o) => faqObj.tags.indexOf(o) === -1)
            );
            existing = true;
          }
        });
        if (!existing) {
          this.$store.state.training.dataset.push(obj);
        }
      });
      this.handleClose(false);
    },

    exportConversationalData() {
      var data = this.$store.state.training.all;
      var toExport = [];
      toExport.push(["prediction", "question"]);
      data.sort((a, b) => {
        if (a.prediction < b.prediction) return -1;
        if (a.prediction > b.prediction) return 1;
        return 0;
      });
      data.forEach((v) => {
        var prediction = this.findQuestion(v.prediction);
        if (prediction === "") {
          prediction = "No Answer";
        }
        toExport.push([prediction, v.question]);
      });
      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.aoa_to_sheet(toExport);
      XLSX.utils.book_append_sheet(wb, ws, "conversation");

      const filename = this.$exportFilename("faq_conversation_data", "csv");
      XLSX.writeFile(wb, filename, {});
    },
    refresh() {
      this.refreshing = true;
      this.loadingDataset = true;
      this.$store.dispatch("SCORE_FAQ");
      const self = this;
      this.$store.dispatch("FETCH_MODELS").then(() => {
        self.initialCheckedProgress = true;

        if (self.models && Array.isArray(self.models)) {
          self.models.forEach((model) => {
            if (model && model.status === "In Progress") {
              return this.poll(null, model.modelId, moment());
            }
          });
        }
      });

      return this.$store
        .dispatch("FETCH_FAQ_DATASET")
        .then(() => {
          this.$store.dispatch("FETCH_FAQ_UNLABELED").then(() => {
            this.refreshing = false;
            this.loadingDataset = false;
          });
        })
        .catch((error) => {
          this.refreshing = false;
          this.loadingDataset = false;
          this.$notify.error({
            title: "Error",
            message: "Encountered error fetching FAQ dataset",
            position: "bottom-right",
          });
        });
    },
  },
});
</script>

<style>
.faq-container {
  min-height: 700px;
  height: 100%;
}
.el-table .warning-row {
  background: oldlace;
}
</style>
<style scoped>
.table-cell {
  word-break: normal;
  cursor: pointer;
}

.search-bar {
  width: 400px;
  transition-property: width;
  transition-duration: 0.5s;
}

.el-autocomplete-suggestion {
  width: 350px;
}
</style>
