<template>
  <div v-if="selectedIntent && selectedIntent.edit" class="tag-answer-section">
    <div>
      <!-- Add example row -->
      <el-row :gutter="10">
        <el-col :span="14">
          <el-input
            v-model="exampleSearch"
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 10 }"
            placeholder="Search or add an example here, or copy multiple examples from Excel"
          />
        </el-col>

        <el-col :span="10">
          <el-input
            v-if="openAIGenerateExamplesEnabled"
            class="example-context"
            v-model="examplesContext"
            type="text"
            placeholder="Briefly describe what this intent is about"
            maxlength="80"
            show-word-limit
          />
          <el-dropdown size="mini" trigger="click" style="width: 100%">
            <el-button
              :disabled="!isAuthorisedForChange(selectedIntent.department)"
              :loading="generating"
              type="plain"
              size="mini"
              style="width: 100%"
            >
              <i v-if="!generating" class="el-icon-caret-bottom" style="padding-right: 0.5em"></i>
              {{
                openAIGenerateExamplesEnabled
                  ? "Generate Examples (OpenAI API)"
                  : "Generate Examples"
              }}
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="paraphrase(selectedIntent.id, 'en')">
                English
              </el-dropdown-item>
              <el-dropdown-item @click.native="paraphrase(selectedIntent.id, 'ar')">
                Arabic
              </el-dropdown-item>
              <el-dropdown-item @click.native="paraphrase(selectedIntent.id, 'bn')">
                Bengali
              </el-dropdown-item>
              <el-dropdown-item @click.native="paraphrase(selectedIntent.id, 'zh-CN')">
                中文
              </el-dropdown-item>
              <el-dropdown-item @click.native="paraphrase(selectedIntent.id, 'fr')">
                French
              </el-dropdown-item>
              <el-dropdown-item @click.native="paraphrase(selectedIntent.id, 'de')">
                German
              </el-dropdown-item>
              <el-dropdown-item @click.native="paraphrase(selectedIntent.id, 'hi')">
                Hindi
              </el-dropdown-item>
              <el-dropdown-item @click.native="paraphrase(selectedIntent.id, 'id')">
                Indonesian
              </el-dropdown-item>
              <el-dropdown-item @click.native="paraphrase(selectedIntent.id, 'ja')">
                Japanese
              </el-dropdown-item>
              <el-dropdown-item @click.native="paraphrase(selectedIntent.id, 'ms')">
                Malay
              </el-dropdown-item>
              <el-dropdown-item @click.native="paraphrase(selectedIntent.id, 'pt')">
                Portuguese
              </el-dropdown-item>
              <el-dropdown-item @click.native="paraphrase(selectedIntent.id, 'ru')">
                Russian
              </el-dropdown-item>
              <el-dropdown-item @click.native="paraphrase(selectedIntent.id, 'es')">
                Spanish
              </el-dropdown-item>
              <el-dropdown-item @click.native="paraphrase(selectedIntent.id, 'tl')">
                Tagalog
              </el-dropdown-item>
              <el-dropdown-item @click.native="paraphrase(selectedIntent.id, 'th')">
                Thai
              </el-dropdown-item>
              <el-dropdown-item @click.native="paraphrase(selectedIntent.id, 'vi')">
                Vietnamese
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-button
            :disabled="!isAuthorisedForChange(selectedIntent.department)"
            style="width: 100%; margin-top: 8px"
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="addNewVariation"
            >Add Examples</el-button
          >
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-pagination
          background
          small
          layout="prev, pager, next"
          :pageSize="pageSize"
          :current-page.sync="currentPage"
          :total="noOfItems"
          @prev-click="togglePage"
          @next-click="togglePage"
          @current-change="togglePage"
          @size-change="handleSizeChange"
          style="display: inline-block"
        ></el-pagination>
        <div style="display: inline-block">
          <span class="has-text-grey" style="font-size: 10px">
            {{ noOfItems }}
            {{ noOfItems === 1 ? "example" : "examples" }}
          </span>
        </div>
      </el-row>

      <div style="display: flex; justify-content: end; margin-bottom: 10px">
        <el-radio-group v-model="conflictedFilter" size="small">
          <el-radio-button label="All"></el-radio-button>
          <el-radio-button label="Non Conflicted"></el-radio-button>
          <el-radio-button label="Conflicted"></el-radio-button>
        </el-radio-group>
      </div>

      <!-- Listing of examples -->
      <el-table
        size="mini"
        class="variations-table"
        :data="pagedExamples"
        style="width: 100%"
        :row-class-name="reservedStyle"
        empty-text="No examples for this question."
      >
        <el-table-column label="Examples" prop="text" min-width="300">
          <template slot-scope="scope">
            <div
              v-if="scope.row !== editExampleData"
              class="table-cell"
              @click="handleExampleEdit(scope.row)"
            >
              <el-badge v-if="badgeForQustions[scope.row.id]" value="new">{{
                scope.row.text
              }}</el-badge>
              <div v-else style="margin-left: 1em; line-height: 1.2">
                {{ scope.row.text }}
              </div>
            </div>

            <el-input
              v-else
              v-model="scope.row.text"
              :disabled="!isAuthorisedForChange(selectedIntent.department)"
              type="text"
              :autosize="true"
              size="medium"
              @change="dirty = true"
              ref="exampleEditor"
              placeholder="Type an example here..."
              @blur="handleExampleBlur(scope.row)"
              @keyup.enter.native="handleExampleBlur(scope.row)"
            ></el-input>
          </template>
        </el-table-column>

        <!-- Conflicted Column -->
        <el-table-column width="150" label="Conflict?" header-align="center" align="center">
          <!-- Custom column header -->
          <template v-if="!groupByTopicIsEnabled" slot="header">
            <el-tooltip content="Evaluate examples with latest published model" placement="top">
              <el-button
                :disabled="!isAuthorisedForChange(selectedIntent.department)"
                :loading="testLoading"
                icon="el-icon-refresh"
                size="mini"
                @click="testIntent"
              >
                Conflict?
              </el-button>
            </el-tooltip>
          </template>

          <template slot-scope="scope">
            <FaqConflictedColumn
              :scope="scope"
              :test-loading="testLoading"
              :move-example="moveExample"
              :delete-variation="deleteVariation"
              @moveExample="moveExample"
              @deleteVariation="deleteVariation"
            />
          </template>
        </el-table-column>

        <!-- Language -->
        <el-table-column width="124" header-align="center" align="center" label="🌐">
          <template slot-scope="scope">
            <el-select
              :disabled="!isAuthorisedForChange(selectedIntent.department)"
              v-model="scope.row.language"
              clearable
              filterable
              placeholder="Select"
              size="mini"
              @change="handleLanguageChange(scope.row)"
            >
              <el-option
                v-for="{ name, code } in availableLanguages"
                :key="code"
                :value="code"
                :label="name"
              />
            </el-select>
          </template>
        </el-table-column>

        <!-- Suggestions -->
        <el-table-column width="100" header-align="center" align="center" label="Suggest">
          <template slot="header">
            <span>Suggest</span>
            <el-tooltip
              content="Check to show example as suggested text (for Suggestions / Multiple Options)"
              effect="dark"
              placement="top"
            >
              <i class="has-text-grey el-icon-question" style="margin-left: 0.5em" />
            </el-tooltip>
          </template>

          <template slot-scope="scope">
            <el-checkbox
              :disabled="!isAuthorisedForChange(selectedIntent.department)"
              v-model="scope.row.is_suggestion"
            ></el-checkbox>
          </template>
        </el-table-column>

        <!-- Test -->
        <el-table-column
          v-if="$store.state.showAdvanced"
          width="120"
          header-align="center"
          align="center"
          label="For testing"
          prop="reserved"
        >
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.reserved" />
          </template>
        </el-table-column>

        <!-- delete icon -->
        <el-table-column width="48" label align="center">
          <template slot-scope="variation_scope">
            <el-button
              :disabled="!isAuthorisedForChange(selectedIntent.department)"
              size="mini"
              type="danger"
              icon="el-icon-delete"
              circle
              @click="$emit('deleteVariation', selectedIntent, variation_scope.row.text)"
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-row>
      <el-col>
        <JSONEditor v-if="$store.state.showAdvanced" :value="examples" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import { mapGetters } from "vuex";
import JSONEditor from "@/components/JSONEditor";
import FaqConflictedColumn from "@/components/Faq/FaqConflictedColumn";
import languagesMixin from "@/mixins/languages";
import { faqDatasetMixin, faqConflictedMixin } from "@/mixins/faq";
import { VARIANTS_LIMIT } from "@/components/Faq/constant";

export default Vue.extend({
  components: { JSONEditor, FaqConflictedColumn },
  props: ["badgeForQustions", "selectedLanguages", "selectedIntent", "deleteVariation"],
  mixins: [languagesMixin, faqDatasetMixin, faqConflictedMixin],
  data() {
    return {
      testLoading: false,
      exampleSearch: "",
      examplesContext: "",
      generating: false,
      dirty: false,
      editExampleData: "",
      pageSize: 10,
      currentPage: 1,
      conflictedFilter: "All",
    };
  },
  watch: {
    intentTestingCompleted: {
      deep: true,
      handler(updatedValue, previousValue) {
        if (updatedValue) {
          this.$notify.success({
            title: "Evaulation Success",
            position: "bottom-right",
            message: `Successfully evaluated examples`,
          });

          this.testLoading = false;
          this.setTrainingInProgress(false);
        }
      },
    },
  },
  mounted() {
    this.currentPage = 1;
    this.pageSize = _.get(this.$store, "state.modules['faq'].page_size", 10);
  },
  computed: {
    ...mapGetters(["openAIGenerateExamplesEnabled", "intentTestingCompleted"]),
    dataset() {
      return _.get(this.$store.state, "training.dataset", []);
    },
    availableLanguages() {
      const supportedLanguage = _.get(
        this,
        "$store.state.modules.translate.supported_languages",
        []
      );
      if (_.isEmpty(supportedLanguage) || !supportedLanguage) {
        return this.languages;
      }
      const mappedSupportedLanguage = _.filter(this.languages, (lang) => {
        return supportedLanguage.includes(lang.code);
      });
      return mappedSupportedLanguage;
    },
    noOfItems() {
      return this.examples.length;
    },
    examples() {
      if (this.selectedIntent) {
        const filteredExamples = this.selectedIntent.variations
          .filter((variation) => {
            if (this.exampleSearch) {
              return (
                variation &&
                variation.text &&
                variation.text.toLowerCase().includes(this.exampleSearch.toLowerCase())
              );
            } else {
              return true;
            }
          })
          .filter((variation) => {
            const selectedLanguages = this.selectedLanguages;
            if (selectedLanguages.length > 0) {
              return selectedLanguages.includes(variation.language);
            } else {
              return true;
            }
          });
        if (this.conflictedFilter !== "All") {
          return _.filter(filteredExamples, (variation) => {
            if (!Array.isArray(variation.intent_ranking)) {
              return false;
            }
            const isConflicted = this.isConflictedByFirstIntent(variation.intent_ranking);
            const isPotentialConflicted = this.isPotentiallyConflicted(variation.intent_ranking);
            return this.conflictedFilter === "Conflicted"
              ? isConflicted || isPotentialConflicted
              : !isConflicted && !isPotentialConflicted;
          });
        }
        return filteredExamples;
      } else {
        return [];
      }
    },
    pagedExamples() {
      const startingIndex = (this.currentPage - 1) * this.pageSize;
      const currentIndex = this.currentPage * this.pageSize;
      const totalExamples = this.examples.length;
      if (totalExamples === 0) return [];
      return this.examples.slice(
        startingIndex,
        totalExamples >= currentIndex ? currentIndex : totalExamples
      );
    },
    groupByTopicIsEnabled() {
      return this.$store.getters.groupByTopicIsEnabled;
    },
  },
  methods: {
    setTrainingInProgress(inProgress) {
      this.$store.commit("SET_TRAINING_IN_PROGRESS", inProgress);
    },
    handleExampleEdit(row) {
      if (row) {
        row.edit = true;
        this.editExampleData = row;
        this.$nextTick(() => {
          this.$refs.exampleEditor.focus();
        });
      }
    },
    togglePage(pageNo) {
      this.currentPage = pageNo;
    },
    handleSizeChange(size) {
      this.pageSize = size;
    },

    handleExampleBlur(row) {
      if (this.dirty) {
        this.dirty = false;
      }
      this.editExampleData = "";
    },
    isAuthorisedForChange(intentDepartment) {
      const userDepartment = this.$store.getters.userDepartment;

      const userIsAuthorised = _.intersection(userDepartment, intentDepartment).length > 0;
      const intentIsGeneral = intentDepartment.includes("general");

      const isAuthorised = userIsAuthorised || intentIsGeneral;
      return isAuthorised;
    },
    moveExample(example, intentSourceName = "") {
      let { intentName } = this.selectedIntent;

      const intent_ranking = example.intent_ranking;
      if (intent_ranking) {
        const firstIntent = intent_ranking[0].name;
        const secondIntent = intent_ranking[1].name;
        let targetIntent;

        if (intentSourceName) {
          targetIntent = intentName;
          intentName = intentSourceName;
        }
        // check whether first intent is already same as selectedIntent
        else if (firstIntent !== intentName) {
          // move to the first intent
          targetIntent = firstIntent;
        } // check whether second Intent is same as selectedIntent
        else if (secondIntent !== intentName) {
          // move to the second intent
          targetIntent = secondIntent;
        }

        const { pair: intentObj } = intentSourceName
          ? this.getIntentByIdentity(targetIntent)
          : this.getIntentByIdentity(intentName);
        if (!_.isEmpty(targetIntent)) {
          // delete from old intent
          this.$emit("deleteVariation", { id: intentName }, example.text, intentObj?.id);
          // save to the intent
          this.$emit("saveVariation", { id: targetIntent }, example);
          return;
        }
      }
    },
    testIntent() {
      // Will test one intent at a time. No save is required. it is done backend
      if (this.selectedIntent) {
        this.$store.state.training.intentTestingCompleted = false;
        this.testLoading = true;
        this.setTrainingInProgress(true);
        // latestModelId is only for train and deconflicting
        this.$store
          .dispatch("FAQ_TEST_TRAINING_DATA", {
            intents: [this.selectedIntent],
            latestModelId: "",
            isIgnoreFallbackOthersIntent: false,
          })
          .then((updatedDataset) => {
            if (updatedDataset && !this.intentTestingCompleted) {
              this.$notify.success({
                title: "Evaluation Success",
                position: "bottom-right",
                message: `Successfully evaluated examples`,
              });
            }
          })
          .catch((err) => {
            this.$notify.error({
              title: "Evaluation Failed",
              position: "bottom-right",
              message: `Encountered error testing faq`,
            });
          })
          .finally(() => {
            this.testLoading = false;
            this.setTrainingInProgress(false);
          });
      }
    },
    sortExamples(a, b) {
      return a.text > b.text ? 1 : -1;
    },
    sortLanguages(a, b) {
      return a.language > b.language ? 1 : -1;
    },
    sortSuggestions(a, b) {
      return a.is_suggestion > b.is_suggestion ? 1 : -1;
    },
    handleLanguageChange(variation) {
      this.$emit("saveVariation", this.selectedIntent, variation);
    },
    confidenceFormatter(row, column, value, index) {
      if (row.test === true) {
        return `${(100 * Math.min(value, 1)).toFixed(2)}%`;
      } else {
        return "";
      }
    },

    paraphrase(label, target) {
      let pair = this.dataset.find((data) => data.id === label);
      if (!pair) {
        return;
      }

      this.updateSelectedIntendTextFromSearchBox();
      let questions =
        _.sampleSize(
          pair.variations.map((variation) => variation.text),
          5
        ) || [];

      if (this.selectedIntent.text) {
        questions.push(this.selectedIntent.text);
      }

      if (questions.length > 0 || this.openAIGenerateExamplesEnabled) {
        this.generating = true;
        this.$rest("post", "faq_paraphrase", {
          questions,
          target,
          examplesContext: this.examplesContext,
        })
          .then((result) => {
            this.generating = false;
            pair.text = this.exampleSearch = result.generatedVariations.join("\n");
            this.$forceUpdate();

            this.$message({
              type: "success",
              message: `Generated ${result.total} variations.`,
            });
          })
          .catch(() => {
            this.generating = false;
          });
      } else {
        this.$message({
          type: "warning",
          message: "No variations are found.",
        });
        this.generating = false;
      }
    },
    sortVariations(a, b) {
      if (a.test !== true && b.test === true) return -2;
      if (b.test !== true && a.test === true) return 2;

      return a.score > b.score ? 1 : -1;
    },

    filterVariations(value, row) {
      return row.test === value;
    },
    reservedStyle({ row }, rowIndex) {
      return row.reserved ? "warning-row" : "";
    },
    addNewVariation() {
      const totalNewVariationToAdd = this.exampleSearch.split(/\n/).length;
      const totalVariations = this.selectedIntent.variations.length + totalNewVariationToAdd;

      if (totalVariations > VARIANTS_LIMIT) {
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: `You can't add more than ${VARIANTS_LIMIT} variants for an intent`,
        });
        return;
      }

      this.updateSelectedIntendTextFromSearchBox();
      this.resetSearchBox();
      this.$emit("newVariation", this.selectedIntent);
    },
    updateSelectedIntendTextFromSearchBox() {
      this.selectedIntent.text = this.exampleSearch;
    },
    resetSearchBox() {
      this.exampleSearch = "";
      this.currentPage = 1;
    },
  },
  // apollo: {
  //   dataset() {
  //     return {
  //       query: gql`
  //         query {
  //           faqAPI {
  //             dataset: getDataset
  //           }
  //         }
  //       `,
  //       update: data => {
  //         const dataset = get(data, "faqAPI.dataset");
  //         this.$set(this.$store.state.training, "dataset", dataset);
  //         const trainingDataset = this.$store.state.training.dataset;

  //         const result = _.map(trainingDataset, pair => {
  //           pair.tagInputVisible = false;
  //           pair.tagInputValue = "";
  //           pair.enabled = !!pair.enabled; // Cast to boolean type
  //           pair.tags = pair.tags || [];

  //           return pair;
  //         });

  //         return result;
  //       }
  //     };
  //   }
  // }
});
</script>

<style scoped lang="scss">
@import "../../../../../assets/scss/colors.scss";

.test-btn {
  width: 100%;
  color: $color-dark;
  background-color: #fff;
  border-color: $color-dark;
}
.test-btn:hover {
  width: 100%;

  color: $color-dark;
  background-color: #fff;
  border-color: $color-dark;
}
.example-context {
  width: 100%;
  margin-bottom: 8px;
}
</style>
