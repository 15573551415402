<template>
  <div>
    <APIEditor ref="api-editor" />
  </div>
</template>
<script>
import APIEditor from "@/components/APIEditor/Index.vue";

export default {
  components: { APIEditor },
  mounted() {
    if (_.isEmpty(this.$store.state.apieditor.mappings)) {
      this.$store.dispatch("FETCH_ALL_MAPPINGS", {
        brain: this.$store.state.brain,
      });
    }
    this.$refs["api-editor"].apiLoading = true;
    this.$store
      .dispatch("FETCH_ALL_APIS", {
        brain: this.$store.state.brain,
      })
      .finally(() => {
        this.$refs["api-editor"].apiLoading = false;
      });
  },
};
</script>
