<template>
  <div class="agent-quick-reply-preview">
    <template v-if="node.content">
      <div style="display: flex; overflow-x: auto">
        <template v-if="Array.isArray(node.content)">
          <div style="flex: 0 0 auto" :key="i" v-for="(c, i) in node.content">
            <div>
              <img class="agent-quick-reply-img" v-if="c.image" :src="c.image" />
              <p v-if="c.text" class="agent-quick-reply-preview-text" v-html="c.text"></p>
              <p v-if="c.subtext" class="agent-quick-reply-preview-subtext" v-html="c.subtext"></p>
              <el-button
                class="agent-quick-reply-button"
                size="mini"
                v-for="(b, bi) in c.buttons"
                :key="`arr-button-${bi}`"
                >{{ b.text }}</el-button
              >
            </div>
          </div>
        </template>
        <template v-else>
          <div style="flex: 0 0 auto">
            <div>
              <img
                class="agent-quick-reply-img"
                v-if="node.content.image"
                :src="node.content.image"
              />
              <p
                v-if="node.content.text"
                class="agent-quick-reply-preview-text"
                v-html="node.content.text"
              ></p>
              <p
                v-if="node.content.subtext"
                class="agent-quick-reply-preview-subtext"
                v-html="node.content.subtext"
              ></p>
              <el-button
                size="mini"
                type="primary"
                class="agent-quick-reply-button"
                plain
                :key="`arr-button-${bi}`"
                v-for="(b, bi) in node.content.buttons"
                >{{ b.text }}</el-button
              >
              <el-button
                size="mini"
                type="primary"
                class="agent-quick-reply-button"
                plain
                v-for="(b, bi) in node.content.buttons"
                :key="`arr-button-${bi}`"
                >{{ b.text }}</el-button
              >
            </div>
          </div>
        </template>
      </div>
    </template>

    <div
      v-if="Array.isArray(quickReplies) && quickReplies.length > 0"
      class="agent-quick-reply-preview-tags"
    >
      <el-tag
        v-for="(quickReply, index) in quickReplies"
        :key="`qr-${index}`"
        class="agent-quick-reply-preview-tag"
        type="info"
        size="mini"
        effect="plain"
      >
        {{ quickReply.text }}
      </el-tag>
    </div>
  </div>
</template>

<script>
export default {
  props: ["node", "quickReplies"],
};
</script>

<style scoped>
.agent-quick-reply-preview-text,
.agent-quick-reply-preview-subtext {
  max-height: 60px;
  overflow: auto;
  word-break: break-word;
  max-width: 250px;
}
.agent-quick-reply-preview-text {
  font-size: 11.5px;
  margin-bottom: 5px;
}
.agent-quick-reply-preview-subtext {
  font-size: 10px;
}
.agent-quick-reply-preview-tags {
  padding: 5px;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
}
.agent-quick-reply-preview-tag {
  margin-right: 3px;
  margin-bottom: 3px;
  border-radius: 12px;
  background-color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.agent-quick-reply-img {
  max-height: 200px;
  max-width: 100%;
}
.agent-quick-reply-button {
  cursor: default;
  padding: 5px 15px;
  width: 100%;
  margin-left: 0;
  margin-top: 5px;
}
p {
  margin-top: 0;
}
</style>
