import { GetterTree } from "vuex/types";
import { RootState } from "@/store/types";
import { FileUploadState } from "./types";

const getters: GetterTree<FileUploadState, RootState> = {
  storageType(state, getters, rootState) {
    return state.storageType;
  },
  isAzureStorage(state, getters, rootState) {
    return state.storageType === "azure";
  },
  isCephStorage(state, getters, rootState) {
    return state.storageType === "ceph";
  },
};

export default getters;
