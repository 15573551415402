<template>
  <el-dialog
    title="Attributes"
    :visible.sync="dialogVisible"
    width="20%"
    :close-on-click-modal="false"
  >
    <div>
      <div
        v-for="(value, key, i) in attributes"
        :key="i"
        :class="value.type === ATTRIBUTE_TYPE.DROPDOWN && 'dropdown'"
      >
        <el-row :gutter="5" style="padding: 10px; margin-bottom: 0px">
          <el-col :span="20" style="font-size: medium">{{ value.name }}</el-col>
          <el-col :span="4"
            ><el-switch v-model="value.isShowOnClient" @change="handleChange"
          /></el-col>
        </el-row>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">Cancel</el-button>
      <el-button type="primary" @click="handleConfirmAttributes">Confirm</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
const CONTACT_ATTRIBUTES = require("@/graphql/OutReachContactAttributes.graphql");
import {
  attributeTypeOption,
  ATTRIBUTE_TYPE,
  convertNameToLabelAttribute,
  optionCheckbox,
  OPTION_CHECKBOX,
} from "@/helperMethods/outreach/attribute";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      attributes: {},
      options: attributeTypeOption(),
      valueSelect: "",
      ATTRIBUTE_TYPE,
      optionCheckbox: optionCheckbox(true),
      errors: {},
    };
  },
  methods: {
    enableConfirmButton() {
      const countObject = this.countAttributeName();
      const existDuplicate = Object.values(countObject).some((item) => item.count > 1);
      const existNoneNameAttribute = Object.values(this.attributes).some(
        (attribute) => !attribute.name
      );
      return existNoneNameAttribute || existDuplicate;
    },
    handleConfirmAttributes() {
      const attributeArray = Object.values(this.attributes).map((attribute) => {
        const { name, type, value, defaultValue = "", id = undefined, isShowOnClient } = attribute;
        let defaultValueForAttribute = defaultValue;
        if (
          (type === ATTRIBUTE_TYPE.CHECKBOX && defaultValue === OPTION_CHECKBOX.NONE) ||
          (type === ATTRIBUTE_TYPE.CHECKBOX && !defaultValue)
        ) {
          defaultValueForAttribute = OPTION_CHECKBOX.FALSE;
        }
        if (
          (type === ATTRIBUTE_TYPE.DROPDOWN && defaultValue === OPTION_CHECKBOX.NONE) ||
          (type === ATTRIBUTE_TYPE.DROPDOWN && !defaultValue)
        ) {
          defaultValueForAttribute = "";
        }
        const formattedName = JSON.stringify({
          label: convertNameToLabelAttribute(name.trim()),
          type,
          value:
            type === ATTRIBUTE_TYPE.DROPDOWN ? value.split(",").map((item) => item.trim()) : value,
        });
        return {
          ...attribute,
          name,
          label: formattedName,
          value: defaultValueForAttribute,
          isShowOnClient,
          id,
        };
      });
      this.$store.commit("SET_OUTREACH_LOADING", true);
      const self = this;
      this.$apollo
        .mutate({
          mutation: CONTACT_ATTRIBUTES,
          variables: {
            attributes: attributeArray,
          },
          update(store, { data: { outReachContactAPI } }) {
            self.$notify.success({
              title: "Success",
              position: "bottom-right",
              message: `Successfully add attributes.`,
            });
            self.$emit("refetch-data", true);
          },
        })
        .catch((err) => {
          self.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `Failed to add attributes!`,
          });
          self.$store.commit("SET_OUTREACH_LOADING", false);
        });
    },
    handleChange() {
      this.$emit("change", this.item);
    },
  },
  computed: {
    ...mapGetters(["contactAttributes"]),
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(data) {
        this.$emit("update:visible", data);
      },
    },
  },
  watch: {
    visible: {
      handler() {
        this.attributes = this.contactAttributes.length
          ? this.contactAttributes.reduce(
              (res, { name, isDeleted, id, type, value = [], isShowOnClient }, index) => {
                return {
                  ...res,
                  [index + 1]: {
                    name,
                    isDeleted,
                    id,
                    type,
                    isShowOnClient,
                    value: Array.isArray(value) ? value.join(", ") : value,
                  },
                };
              },
              {}
            )
          : {
              1: {
                name: "",
                value: "",
                defaultValue: "",
                isShowOnClient: false,
                type: ATTRIBUTE_TYPE.TEXT_BOX,
              },
            };
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.outreach-attribute {
  max-height: 40vh;
  overflow-y: scroll;
  .attribute-item {
    .attribute-dropdown-type {
      margin: 0 5px;
    }
    display: flex;
    &:not(:first-child) {
      margin-top: 10px;
    }
    > div:not(:last-child) {
      width: 33%;
    }
    // > div:first-child {
    //   width: 43%;
    // }
    // > div:nth-child(2) {
    //   width: 43%;
    //   margin-left: 10px;
    // }
    .action-attributes {
      button {
        border: none;
        background: none !important;
        &:hover {
          background: none !important;
        }
      }
      .btn-delete {
        &:hover {
          path {
            stroke: #1d57d8;
          }
        }
      }
    }
    &.deleted {
      background: blue;
    }
  }
}
</style>
