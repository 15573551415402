import { WatsonState } from "./types";

const state: WatsonState = {
  intents: [],
  entities: [],
  dialogNodes: [],
  workspace: {},
  isEditedObject: {},
};

export default state;
