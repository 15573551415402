<template>
  <div v-if="shouldShowTimeline" class="timeline-container">
    <el-popover
      style="max-height: 400px"
      placement="bottom"
      width="400"
      trigger="click"
      popper-class="timeline-popup"
    >
      <el-timeline reverse>
        <el-timeline-item
          v-for="(timeline, index) in timelineData"
          :key="chat.RowKey + '__' + index"
          :timestamp="timeline.timestamp"
          :color="timelineItemColor(timeline, index)"
        >
          {{ timeline.content }}
        </el-timeline-item>
      </el-timeline>
      <label slot="reference" class="el-tag el-tag--small el-tag--light">
        Escalation History
        <i class="el-icon-date el-icon--right"></i>
      </label>
    </el-popover>
  </div>
</template>

<script>
import { jsonParse } from "@/helpers/parser";
import _ from "lodash";
import moment from "moment";

export default {
  props: {
    chat: Object,
  },
  data() {
    return {
      showTimeline: true,
    };
  },
  methods: {
    timelineItemColor(timeline, index) {
      const defaultColor = "#5a5e66f";
      const activeColor = "#fa5555";
      if (index === this.timelineData.length - 1) {
        return activeColor;
      } else {
        return defaultColor;
      }
    },
  },
  computed: {
    shouldShowTimeline() {
      return !_.isEmpty(this.timelineData);
    },
    timelineData() {
      const logs = _.get(this.chat.meta, "escalate_logs");
      if (!logs) {
        return [];
      }
      return jsonParse(logs).map((it) => ({
        timestamp: `${moment(it.date_created).format(
          "DD/MM/yyyy, HH:mm"
        )} (${moment(it.date_created).fromNow()})`,
        content: it.department,
      }));
    },
  },
};
</script>

<style lang="scss">
.timeline-popup {
  max-height: 400px;
  overflow: auto;
  padding: 40px 0 10px 0 !important;
}
</style>
