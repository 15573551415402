<template>
  <!-- IF IMAGE URL -->
  <el-image
    v-if="displayType === 'image'"
    style="width: 100px; height: 100px"
    :src="value"
    :fit="`cover`"
    :preview-src-list="[value]"
    lazy
  />
  <!-- IF NON-IMAGE URL -->
  <el-link v-else-if="displayType === 'link'" :href="value" target="_blank" class="font-size-small">
    <i class="el-icon-files"></i> View {{ fileName }}
  </el-link>
  <!-- ELSE (TEXT) -->
  <span v-else>{{ cleanedValue }}</span>
</template>

<script>
import { trim } from "@/filters";

const urlRegex =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
const imageRegex = /\.(jpg|gif|png|jpeg)$/;

export default {
  name: "FormResultValueDisplay",
  props: {
    value: String,
    trim: { type: Boolean, default: false },
  },
  computed: {
    displayType() {
      const isUrl = urlRegex.test(this.value);
      if (!isUrl) return "text";

      try {
        const { protocol, pathname } = new URL(this.value);
        const isSecureUrl = protocol === "https:";
        if (!isSecureUrl) return "text";

        return imageRegex.test(pathname) ? "image" : "link";
      } catch (_) {
        return "text";
      }
    },
    cleanedValue() {
      return this.trim ? trim(this.value, 100) : this.value;
    },
    fileName() {
      try {
        const fileNameWithSas = _.last(this.value?.split("/"));
        const fileName = _.first(fileNameWithSas?.split("?"));
        const extension = _.last(fileName?.split("."));
        return `.${extension}`;
      } catch {
        return "file";
      }
    },
  },
};
</script>

<style scoped>
.font-size-small {
  font-size: 12px;
}
</style>
