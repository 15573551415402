<template>
  <div>
    <!-- Loading icon -->
    <el-button v-if="testLoading" class="test-loading-btn">
      <i class="el-icon-loading" />
    </el-button>

    <!-- Not testing icon -->
    <el-tooltip
      v-else-if="
        !scope.row.intent_ranking ||
        (scope.row.intent_ranking && scope.row.intent_ranking.length === 0)
      "
      content="This example is not evaluated yet 🚼"
      placement="top"
    >
      <i class="list-icon el-icon-minus" style="color: #707070" />
    </el-tooltip>

    <!-- Conflicted icon -->
    <el-popover
      v-else-if="isConflictedByFirstIntent(scope.row.intent_ranking)"
      placement="top-start"
      width="300"
      trigger="click"
    >
      <!-- :title="checkStatus(scope.row.intent_ranking)" -->
      <el-row>
        <el-col>
          <span>
            <table class="conflictingIntentTable">
              <!-- Current intent row -->
              <tr>
                <td style="font-weight: bold">Current Intent:</td>
              </tr>
              <tr>
                <td>
                  <span
                    class="example-intent-listing"
                    @click="setSearch(selectedIntent.intentName)"
                    >{{ selectedIntent.intentName }}</span
                  >
                </td>
                <td style="text-align: center">
                  <!-- Check whether the current intent is part of the top 3 predictions -->
                  <span v-if="fetchCurrentIntentConfidence(scope.row.intent_ranking)">{{
                    fetchCurrentIntentConfidence(scope.row.intent_ranking)
                  }}</span>

                  <el-tooltip v-else content="Not the top 3 predictions" placement="top">
                    <i class="list-icon el-icon-minus" style="color: #707070" />
                  </el-tooltip>
                </td>
              </tr>
              <br />

              <!-- Top Prediction row -->
              <tr>
                <td style="font-weight: bold">Top Prediction:</td>
              </tr>
              <tr>
                <td>
                  <el-tooltip
                    :content="`Example: ${scope.row.intent_ranking[0].example || ''}`"
                    placement="left"
                  >
                    <i class="has-text-grey el-icon-question" style="margin-right: 0.5em" />
                  </el-tooltip>
                  <span
                    class="example-intent-listing"
                    :style="
                      formatIntentListStyle(
                        scope.row.intent_ranking,
                        scope.row.intent_ranking[0].name,
                        0
                      )
                    "
                    @click="setSearch(scope.row.intent_ranking[0].name)"
                    >{{ scope.row.intent_ranking[0].name }}</span
                  >
                </td>
                <td style="text-align: center">
                  <span>{{ formatConfidence(scope.row.intent_ranking[0].confidence) }}%</span>
                </td>
              </tr>
            </table>
          </span>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <FaqConflictedButtonAction
            :scope="scope"
            :selected-intent="selectedIntent"
            @moveExample="moveExample"
            @deleteVariation="deleteVariation"
          />
        </el-col>
      </el-row>

      <el-button slot="reference" style="border: 0px">
        <!-- keyreply Red -->
        <span class="has-text-danger">Conflicted</span>
      </el-button>
    </el-popover>

    <!-- Potentially conflicted icon -->
    <el-popover
      v-else-if="isPotentiallyConflicted(scope.row.intent_ranking)"
      placement="top-start"
      width="300"
      trigger="click"
    >
      <!-- :title="checkStatus(scope.row.intent_ranking)" -->
      <el-row>
        <el-col>
          <table class="conflictingIntentTable">
            <tr>
              <td style="font-weight: bold">Current Intent:</td>
            </tr>
            <tr>
              <td>
                <span
                  class="example-intent-listing"
                  :style="
                    formatIntentListStyle(
                      scope.row.intent_ranking,
                      scope.row.intent_ranking[0].name,
                      0
                    )
                  "
                  @click="setSearch(scope.row.intent_ranking[0].name)"
                  >{{ scope.row.intent_ranking[0].name }}</span
                >
              </td>
              <td>{{ formatConfidence(scope.row.intent_ranking[0].confidence) }}%</td>
            </tr>
            <br />
            <tr>
              <td style="font-weight: bold">Confused Prediction:</td>
            </tr>
            <tr>
              <td>
                <el-tooltip
                  :content="`Example: ${scope.row.intent_ranking[1].example || ''}`"
                  placement="left"
                >
                  <i class="has-text-grey el-icon-question" style="margin-right: 0.5em" />
                </el-tooltip>
                <span
                  class="example-intent-listing"
                  :style="
                    formatIntentListStyle(
                      scope.row.intent_ranking,
                      scope.row.intent_ranking[1].name,
                      1
                    )
                  "
                  @click="setSearch(scope.row.intent_ranking[1].name)"
                  >{{ scope.row.intent_ranking[1].name }}</span
                >
              </td>
              <td>{{ formatConfidence(scope.row.intent_ranking[1].confidence) }}%</td>
            </tr>
          </table>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <FaqConflictedButtonAction
            :scope="scope"
            :selected-intent="selectedIntent"
            @moveExample="moveExample"
            @deleteVariation="deleteVariation"
          />
        </el-col>
      </el-row>

      <el-button slot="reference" style="border: 0px">
        <!-- Orange -->
        <span class="has-text-warning">Potential</span>
        <br />
        <span class="has-text-warning">{{ " " }}Conflict</span>
      </el-button>
    </el-popover>

    <!-- OK icon -->
    <el-tooltip
      v-else-if="!isConflictedByFirstIntent(scope.row.intent_ranking)"
      :content="`High confidence (${formatConfidence(scope.row.intent_ranking[0].confidence)}%) 👍🏻`"
      placement="top"
    >
      {{ scope.row.intent_ranking[0] }}
      <!-- Green -->
      <i class="list-icon el-icon-success has-text-success" />
    </el-tooltip>
  </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import FaqConflictedButtonAction from "@/components/Faq/FaqConflictedButtonAction";
import { faqConflictedMixin } from "@/mixins/faq";

export default Vue.extend({
  props: ["scope", "testLoading", "moveExample", "deleteVariation"],
  mixins: [faqConflictedMixin],
  components: { FaqConflictedButtonAction },
  computed: {
    selectedIntent() {
      const selectedIntent = _.find(
        this.dataset,
        (data) => data.id === this.$store.state.pages.faq.selectedIntentId
      );
      return selectedIntent;
    },
    dataset() {
      return _.get(this.$store.state, "training.dataset", []);
    },
  },
  methods: {
    fetchCurrentIntentConfidence(intent_ranking) {
      const { intentName } = this.selectedIntent;

      if (intent_ranking && intent_ranking.length > 0) {
        const { name: firstIntent, confidence: firstConfidence } = intent_ranking[0];
        const { name: secondIntent, confidence: secondConfidence } = intent_ranking[1] || {
          name: "",
          confidence: 0,
        };
        const { name: thirdIntent, confidence: thirdConfidence } = intent_ranking[2] || {
          name: "",
          confidence: 0,
        };

        if (firstIntent === intentName) {
          return this.formatConfidence(firstConfidence) + " %";
        }

        if (secondIntent === intentName) {
          return this.formatConfidence(secondConfidence) + " %";
        }

        if (thirdIntent === intentName) {
          return this.formatConfidence(thirdConfidence) + " %";
        }

        return false;
      }
    },
    setSearch(intentName) {
      // set the search and narrow down the list
      this.$set(this.$store.state.faq, "intentSearch", intentName);
    },
    checkStatus(intent_ranking) {
      const { intentName } = this.selectedIntent;

      if (intent_ranking) {
        const firstIntent = intent_ranking[0].name;
        const secondIntent = intent_ranking[1].name;
        if (firstIntent !== intentName) {
          return "❌ Conflicted Example";
        }

        if (secondIntent !== intentName) {
          return "⚠️ Potential Conflict";
        }
      }
    },

    formatIntentListStyle(intent_ranking, intent_ranking_name, index) {
      const { intentName } = this.selectedIntent;
      if (index === 0 && intent_ranking_name !== intentName) {
        return "color:red";
      } else if (index === 1 && this.isPotentiallyConflicted(intent_ranking)) {
        return "color:red";
      } else {
        // to remove focus from the user
        return "color:grey";
      }
    },

    formatConfidence(confidence) {
      if (typeof confidence === "string") {
        return parseInt(confidence).toFixed(2);
      } else if (typeof confidence === "number") {
        // Make it a percentage with one decimal
        return (confidence * 100).toFixed(1);
      }
    },
  },
  // apollo: {
  //   dataset() {
  //     return {
  //       query: gql`
  //         query {
  //           faqAPI {
  //             dataset: getDataset
  //           }
  //         }
  //       `,
  //       update: data => {
  //         const dataset = _.get(data, "faqAPI.dataset");
  //         this.$set(this.$store.state.training, "dataset", dataset);
  //         const trainingDataset = this.$store.state.training.dataset;

  //         const result = _.map(trainingDataset, pair => {
  //           pair.tagInputVisible = false;
  //           pair.tagInputValue = "";
  //           pair.enabled = !!pair.enabled; // Cast to boolean type
  //           pair.tags = pair.tags || [];

  //           return pair;
  //         });

  //         return result;
  //       }
  //     };
  //   }
  // }
});
</script>

<style scoped>
.example-intent-listing {
  font-size: 15px;
  cursor: pointer;
}
.test-loading-btn {
  border: 0;
  background-color: #fff;
  height: 44px;
}

.test-loading-btn:hover {
  border: 0;
  background-color: #fff;
  height: 44px;
}
.list-icon {
  font-size: 18px;
  vertical-align: middle;
}

.conflictingIntentTable {
  width: 100%;
  font-size: 14px;
}

.conflictingIntentTable tr th {
  width: 50%;
  text-align: center;
}
.conflictingIntentTable tr td {
  /* width: 50%; */
  max-width: 180px;
  text-align: left;
  vertical-align: top;
}
</style>
