import { render, staticRenderFns } from "./TrendingList.vue?vue&type=template&id=214aa6f9&scoped=true&"
import script from "./TrendingList.vue?vue&type=script&lang=js&"
export * from "./TrendingList.vue?vue&type=script&lang=js&"
import style0 from "./TrendingList.vue?vue&type=style&index=0&id=214aa6f9&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "214aa6f9",
  null
  
)

export default component.exports