import router from "@/router";

export default function catchRedirect(error: any) {
  if (error.networkError && error.networkError.statusCode === 401) {
    router.push({ path: "/error/session-timeout" }).catch(() => {});
  } else if (
    (error.networkError && error.networkError.statusCode === 403) ||
    (error.response && error.response.status === 403) ||
    (error.response && error.response.status === 401)
  ) {
    router.push({ path: "/error/no-permission" }).catch(() => {});
  } else if (error.networkError) {
    router.push({ path: "/error/connection-error" }).catch(() => {});
  }
}
