<template>
  <div>
    <el-row>
      <el-card style="margin-bottom: 0">
        <el-form label-width="150px" size="mini">
          <el-row style="margin-bottom: 0">
            <el-col :span="12">
              <el-form-item label="Threshold">
                <el-slider v-model="confidenceThreshold"></el-slider>
              </el-form-item>
              <el-form-item label="Filter">
                <el-slider v-model="confidenceFilter" range></el-slider>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label-width="90px" label="Show Scale">
                <el-checkbox v-model="showScale"></el-checkbox>
              </el-form-item>
              <el-form-item label-width="90px" label="Filter Type">
                <el-radio v-model="filterType" label="count">Count</el-radio>
                <br />
                <el-radio v-model="filterType" label="hac">Highest Average Confidence</el-radio>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
    </el-row>
    <el-row>
      <el-col>
        <el-card>
          <div>
            <div id="dataviz-datasetquality"></div>
            <div
              v-if="showTooltip"
              :style="{
                position: 'fixed',
                left: tooltipX + 'px',
                top: tooltipY + 'px',
              }"
            >
              <Tooltip :tooltipData="tooltipData" />
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { SVG } from "@svgdotjs/svg.js";
import Tooltip from "./Tooltip.vue";
import _ from "lodash";
/*
    pseudocode
    1) filter entire dataset for selected Expected intent
    2) filter for all rank 1 unique intents
    3) filter for threshold
    4) filter rank 1 question data where expected intent != actual intent (predicted wrongly)
    5) categorise  {Key: QuestionSet[]}  -> will be used for red dots
    6) sort intents by average / sort intents by count
    7) filter intents to top5 by slicing
    8) filter correctly predicted questions -> for green dots
      get questions where expected == Actual intent at rank 1, and rank2 intent is included in intentsToDisplay
    */

export default {
  name: "DatasetQuality",
  components: {
    Tooltip,
  },
  props: {
    rawSelectedIntent: {
      type: Object,
      required: true,
    },
    dataSet: {
      type: Array,
      required: true,
    },
    noOfIntentsToDisplay: {
      type: Number,
      default: 5,
    },
    height: {
      type: Number,
      default: 600,
    },
    graphWidth: {
      type: Number,
      default: 1000,
    },
    graphHeight: {
      type: Number,
      default: 700,
    },
  },
  data() {
    return {
      canvas: null,
      centerX: 0,
      centerY: 0,
      circleRadius: 0,
      circleSize: 270,
      awayFromCenterLength: 270,
      tooltipX: 0,
      tooltipY: 0,
      tooltipData: null,
      showScale: true,
      showTooltip: false,
      filterType: "count",
      //svg elements stored in objects
      svgGreenScaleArray: [],
      svgRedScaleArray: [],
      confidenceFilter: [0, 100],
      confidenceThreshold: 70,
    };
  },
  computed: {
    selectedIntent: function () {
      return this.rawSelectedIntent.intentName.toLowerCase();
    },
  },
  methods: {
    handleRefresh: function (event) {
      this.initDraw();
    },
    getIntentsToDisplay: function (mainIntentData) {
      //get all rank 1 unique intents
      const allRank1QuestionsData = mainIntentData.filter((item) => item.Rank === 1);

      //filter rank 1 question data where expected intent != actual intent (predicted wrongly)
      const rank1QuestionsNoSelectedData = allRank1QuestionsData.filter(
        (item) => item["Expected intent"] != item["Actual intent"]
      );
      //categorise items into array for each actual intent header
      //will also be used to display red dots
      const redDotDataMap = {};
      rank1QuestionsNoSelectedData.forEach((item) => {
        if (redDotDataMap[item["Actual intent"]]) {
          redDotDataMap[item["Actual intent"]].push(item);
        } else {
          redDotDataMap[item["Actual intent"]] = [item];
        }
      });

      //helper function to get average
      const getAverage = (data) => {
        const temp = data.map((item) => item.Confidence);
        return temp.reduce((a, b) => a + b) / data.length;
      };

      //*for wrong data - choose either to sort by average or count
      //sort intents by average
      const sortedIntentsByAverage = Object.keys(redDotDataMap).sort((a, b) =>
        getAverage(redDotDataMap[a]) < getAverage(redDotDataMap[b]) ? 1 : -1
      );
      // sort intents by count
      const sortedIntentsByCount = Object.keys(redDotDataMap).sort((a, b) =>
        redDotDataMap[a].length < redDotDataMap[b].length ? 1 : -1
      );

      let intentsToDisplay;
      //filter intents to top5
      if (this.filterType === "count") {
        intentsToDisplay = sortedIntentsByCount.slice(0, this.noOfIntentsToDisplay);
      } else if (this.filterType === "hac") {
        intentsToDisplay = sortedIntentsByAverage.slice(0, this.noOfIntentsToDisplay);
      }

      return [intentsToDisplay, redDotDataMap];
    },
    getGreenDotsData: function (mainIntentData, intentsToDisplay) {
      //create tempMap to analyse all questions if criterion met, and store secondIntent (for categorising in greenQuestionData)
      const tempMap = {};
      const greenQuestionData = {};
      mainIntentData.forEach((item) => {
        if (!tempMap[item["Question"]]) {
          tempMap[item["Question"]] = {
            selectedIntentIsFirst: false,
            hasRankTwo: false,
            secondIntent: "",
          };
        }
        if (item["Actual intent"] == this.selectedIntent && item.Rank == 1) {
          tempMap[item["Question"]].selectedIntentIsFirst = true;
        }
        if (intentsToDisplay.includes(item["Actual intent"]) && item.Rank == 2) {
          tempMap[item["Question"]].hasRankTwo = true;
          tempMap[item["Question"]].secondIntent = item["Actual intent"];
        }
      });

      //check tempMap if question met criterion
      mainIntentData.forEach((item) => {
        if (
          item.Rank == 1 &&
          item["Expected intent"] === this.selectedIntent &&
          item["Actual intent"] === this.selectedIntent &&
          tempMap[item["Question"]].selectedIntentIsFirst &&
          tempMap[item["Question"]].hasRankTwo
        ) {
          greenQuestionData[tempMap[item["Question"]].secondIntent]
            ? greenQuestionData[tempMap[item["Question"]].secondIntent].push(item)
            : (greenQuestionData[tempMap[item["Question"]].secondIntent] = [item]);
        }
      });
      return greenQuestionData;
    },
    drawMainIntentCircle: function () {
      //center circle
      const svgCenterCircle = this.canvas
        .circle(this.circleSize * (1 - this.confidenceThreshold / 100))
        .attr({ cx: this.centerX, cy: this.centerY })
        .fill({ opacity: 0.05, color: "green" })
        .stroke({ width: 2, color: "green", dasharray: "8,8" });
      //text background
      const svgCenterCircleTextBackground = this.canvas
        .rect(this.selectedIntent.length * 8 + 5, 30)
        .center(this.centerX, this.centerY - 30)
        .fill({ opacity: 0.4, color: "green" })
        .radius(7);
      //text
      const svgCenterCircleText = this.canvas
        .text(this.selectedIntent)
        .center(this.centerX, this.centerY - 30)
        .fill({ opacity: 1, color: "black" });
    },
    drawGreenLineScale: function () {
      //GREEN LINE SCALE
      const circleX = this.centerX + Math.cos(270 * (Math.PI / 180)) * this.awayFromCenterLength;
      const circleY = this.centerY + Math.sin(270 * (Math.PI / 180)) * this.awayFromCenterLength;
      const redCircleBottomX =
        this.centerX +
        Math.cos(270 * (Math.PI / 180)) * (this.awayFromCenterLength - this.circleRadius);
      const redCircleBottomY =
        this.centerY +
        Math.sin(270 * (Math.PI / 180)) * (this.awayFromCenterLength - this.circleRadius);
      // green scale line
      const greenLine = this.canvas
        .line(redCircleBottomX + 75, redCircleBottomY, this.centerX + 75, this.centerY)
        .stroke({ width: 2, color: "green", opacity: 0.5 });
      // top green dotted lines
      const topGreenDottedLine = this.canvas
        .line(redCircleBottomX + 75, redCircleBottomY, circleX, circleY + this.circleRadius)
        .stroke({
          width: 2,
          color: "green",
          opacity: 0.5,
          dasharray: "5,5",
        });
      // bottom green dotted lines
      const bottomGreenDottedLine = this.canvas
        .line(this.centerX, this.centerY, this.centerX + 75, this.centerY)
        .stroke({
          width: 2,
          color: "green",
          opacity: 0.5,
          dasharray: "5,5",
        });
      // green line's 0% label
      const text0 = this.canvas
        .text("0%")
        .center(redCircleBottomX + 110, redCircleBottomY)
        .fill({ opacity: 1, color: "green" })
        .font({ size: 20 });
      //green line's 100% label
      const text100 = this.canvas
        .text("100%")
        .center(this.centerX + 110, this.centerY - 5)
        .fill({ opacity: 1, color: "green" })
        .font({ size: 20 });

      this.svgGreenScaleArray = [
        greenLine,
        topGreenDottedLine,
        bottomGreenDottedLine,
        text0,
        text100,
      ];
    },
    drawRedLineScale: function () {
      //RED LINE
      const circleX = this.centerX + Math.cos(270 * (Math.PI / 180)) * this.awayFromCenterLength;
      const circleY = this.centerY + Math.sin(270 * (Math.PI / 180)) * this.awayFromCenterLength;
      const greenCircleTopX = this.centerX + Math.cos(270 * (Math.PI / 180)) * this.circleRadius;
      const greenCircleTopY = this.centerY + Math.sin(270 * (Math.PI / 180)) * this.circleRadius;
      const redLine = this.canvas
        .line(greenCircleTopX - 75, greenCircleTopY, circleX - 75, circleY)
        .stroke({ width: 2, color: "red", opacity: 0.5 });
      const redTopDottedLine = this.canvas.line(circleX, circleY, circleX - 75, circleY).stroke({
        width: 2,
        color: "red",
        opacity: 0.5,
        dasharray: "5,5",
      });
      const redBottomDottedLine = this.canvas
        .line(greenCircleTopX - 75, greenCircleTopY, this.centerX, this.centerY - this.circleRadius)
        .stroke({
          width: 2,
          color: "red",
          opacity: 0.5,
          dasharray: "5,5",
        });

      const text0 = this.canvas
        .text("0%")
        .center(greenCircleTopX - 110, greenCircleTopY - 5)
        .fill({ opacity: 1, color: "red" })
        .font({ size: 20 });
      const text100 = this.canvas
        .text("100%")
        .center(circleX - 110, circleY)
        .fill({ opacity: 1, color: "red" })
        .font({ size: 20 });

      this.svgRedScaleArray = [redLine, redTopDottedLine, redBottomDottedLine, text0, text100];
    },
    drawSurroundingIntentCircle: function (circleX, circleY, cumulativeAngle, intent) {
      //intent circles
      const svgIntentCircle = this.canvas
        .circle(this.circleSize * (1 - this.confidenceThreshold / 100))
        .attr({ cx: circleX, cy: circleY })
        .fill({ opacity: 0.05, color: "red" })
        .stroke({ width: 2, color: "red", dasharray: "8,8" });
      //line to circles
      const svgLineToCircle = this.canvas
        .line(circleX, circleY, this.centerX, this.centerY)
        .stroke({ width: 1, color: "#000", opacity: 0.2 });

      const textBoxX =
        this.centerX +
        Math.cos(cumulativeAngle * (Math.PI / 180)) * (this.awayFromCenterLength + 80);
      const textBoxY =
        this.centerY +
        Math.sin(cumulativeAngle * (Math.PI / 180)) * (this.awayFromCenterLength + 80);
      //rectangle background
      const svgIntentTextBackground = this.canvas
        .rect(intent.length * 8 + 5, 30)
        .center(textBoxX, textBoxY)
        .fill({ opacity: 0.4, color: "red" })
        .radius(7);
      //intent text
      const svgIntentText = this.canvas
        .text(intent)
        .center(textBoxX, textBoxY)
        .fill({ opacity: 1, color: "black" });
    },
    drawRedDots: function (
      intentRedDots,
      segments,
      MaxlengthAwayFromLine,
      cumulativeAngle,
      circleX,
      circleY
    ) {
      const sortedRedDots = intentRedDots.sort((a, b) => (a.Confidence < b.Confidence ? -1 : 1));

      //group dots into segments
      const segmentedRedDots = [];
      let segmentUpperLimit = 1 / segments;
      const cumulativeLimit = 1 / segments;
      let startingIndex = 0;

      for (let i = 0; i < sortedRedDots.length; i++) {
        if (sortedRedDots[i].Confidence < segmentUpperLimit) {
          continue;
        } else {
          if (sortedRedDots[i].Confidence + cumulativeLimit > segmentUpperLimit) {
            if (i > 0) segmentedRedDots.push(sortedRedDots.slice(startingIndex, i));
            startingIndex = i;
            segmentUpperLimit += cumulativeLimit;
          }
        }
      }
      if (startingIndex < sortedRedDots.length) {
        segmentedRedDots.push(sortedRedDots.slice(startingIndex, sortedRedDots.length));
      }

      //draw dots
      segmentedRedDots.forEach((groupArray) => {
        let incrementalSpace = (MaxlengthAwayFromLine * 2) / groupArray.length / 1.5;
        let cumulativeRedDotSpace = -MaxlengthAwayFromLine / 2;

        groupArray.forEach((item) => {
          const redDist = (this.awayFromCenterLength - this.circleRadius) * item.Confidence;

          const lineX =
            circleX -
            Math.cos(cumulativeAngle * (Math.PI / 180)) *
              (this.awayFromCenterLength - this.circleRadius - redDist);
          const lineY =
            circleY -
            Math.sin(cumulativeAngle * (Math.PI / 180)) *
              (this.awayFromCenterLength - this.circleRadius - redDist);
          const arcX =
            circleX -
            Math.cos((cumulativeAngle - cumulativeRedDotSpace / 2) * (Math.PI / 180)) *
              (this.awayFromCenterLength -
                this.circleRadius -
                redDist +
                Math.abs(cumulativeRedDotSpace / 5));
          const arcY =
            circleY -
            Math.sin((cumulativeAngle - cumulativeRedDotSpace / 2) * (Math.PI / 180)) *
              (this.awayFromCenterLength -
                this.circleRadius -
                redDist +
                Math.abs(cumulativeRedDotSpace / 5));
          const qnX =
            circleX -
            Math.cos((cumulativeAngle - cumulativeRedDotSpace) * (Math.PI / 180)) *
              (this.awayFromCenterLength - this.circleRadius - redDist);
          const qnY =
            circleY -
            Math.sin((cumulativeAngle - cumulativeRedDotSpace) * (Math.PI / 180)) *
              (this.awayFromCenterLength - this.circleRadius - redDist);

          this.canvas
            .path(`M ${lineX} ${lineY} Q ${arcX} ${arcY}, ${qnX} ${qnY}`)
            .stroke({ width: 2, color: "red", opacity: 0.1 })
            .fill({ opacity: 0 });
          const dot = this.canvas
            .circle(10)
            .attr({ cx: qnX, cy: qnY })
            .fill({ opacity: 0.5, color: "red" })
            .on("mouseover", (event) => {
              this.tooltipX = event.clientX + 20;
              this.tooltipY = event.clientY - 130;
              this.tooltipData = item;
            });
          dot.on("mouseover", () => {
            this.showTooltip = true;
          });
          this.canvas.on("click", () => {
            this.showTooltip = false;
          });
          cumulativeRedDotSpace += incrementalSpace;
        });
      });
    },
    attachEvents(dot, item) {
      dot;
    },
    drawGreenDots: function (intentGreenDots, segments, MaxlengthAwayFromLine, cumulativeAngle) {
      const sortedGreenDots = intentGreenDots.sort((a, b) =>
        a.Confidence < b.Confidence ? -1 : 1
      );

      //group dots into segments
      const segmentedGreenDots = [];
      let segmentUpperLimit = 1 / segments;
      const cumulativeLimit = 1 / segments;
      let startingIndex = 0;

      for (let i = 0; i < sortedGreenDots.length; i++) {
        if (sortedGreenDots[i].Confidence < segmentUpperLimit) {
          continue;
        } else {
          if (sortedGreenDots[i].Confidence + cumulativeLimit > segmentUpperLimit) {
            if (i > 0) segmentedGreenDots.push(sortedGreenDots.slice(startingIndex, i));
            startingIndex = i;
            segmentUpperLimit += cumulativeLimit;
          }
        }
      }
      if (startingIndex < sortedGreenDots.length) {
        segmentedGreenDots.push(sortedGreenDots.slice(startingIndex, sortedGreenDots.length));
      }

      //draw dots
      segmentedGreenDots.forEach((groupArray) => {
        let incrementalSpace = (MaxlengthAwayFromLine * 2) / groupArray.length / 1.5;
        let cumulativeGreenDotSpace = -MaxlengthAwayFromLine / 2;

        groupArray.forEach((item) => {
          const greenDist = (this.awayFromCenterLength - this.circleRadius) * item.Confidence;
          const rand = _.random(49) - 25;

          const lineX =
            this.centerX +
            Math.cos(cumulativeAngle * (Math.PI / 180)) *
              (this.awayFromCenterLength - this.circleRadius - greenDist);
          const lineY =
            this.centerY +
            Math.sin(cumulativeAngle * (Math.PI / 180)) *
              (this.awayFromCenterLength - this.circleRadius - greenDist);
          const arcX =
            this.centerX +
            Math.cos((cumulativeAngle - cumulativeGreenDotSpace / 2) * (Math.PI / 180)) *
              (this.awayFromCenterLength -
                this.circleRadius -
                greenDist +
                Math.abs(cumulativeGreenDotSpace / 5));
          const arcY =
            this.centerY +
            Math.sin((cumulativeAngle - cumulativeGreenDotSpace / 2) * (Math.PI / 180)) *
              (this.awayFromCenterLength -
                this.circleRadius -
                greenDist +
                Math.abs(cumulativeGreenDotSpace / 5));
          const qnX =
            this.centerX +
            Math.cos((cumulativeAngle - cumulativeGreenDotSpace) * (Math.PI / 180)) *
              (this.awayFromCenterLength - this.circleRadius - greenDist);
          const qnY =
            this.centerY +
            Math.sin((cumulativeAngle - cumulativeGreenDotSpace) * (Math.PI / 180)) *
              (this.awayFromCenterLength - this.circleRadius - greenDist);
          this.canvas
            .path(`M ${lineX} ${lineY} Q ${arcX} ${arcY}, ${qnX} ${qnY}`)
            .stroke({ width: 2, color: "green", opacity: 0.1 })
            .fill({ opacity: 0 });
          const dot = this.canvas
            .circle(10)
            .attr({ cx: qnX, cy: qnY })
            .fill({ opacity: 0.5, color: "green" })
            .on("mouseover", (event) => {
              this.tooltipX = event.clientX + 20;
              this.tooltipY = event.clientY - 130;
              this.tooltipData = item;
            });
          dot.on("mouseover", () => {
            this.showTooltip = true;
          });
          this.canvas.on("click", () => {
            this.showTooltip = false;
          });
          cumulativeGreenDotSpace += incrementalSpace;
        });
      });
    },
    initDraw: function () {
      this.centerX = this.graphWidth / 2;
      this.centerY = this.graphHeight / 2;
      this.circleRadius = (this.circleSize * (1 - this.confidenceThreshold / 100)) / 2;

      if (this.canvas) this.canvas.remove();

      this.showScale = true;

      //filter entire dataset for selected Expected intent
      const mainIntentData = this.dataSet.filter(
        (item) => item["Expected intent"] === this.selectedIntent
      );

      //filter for threshold
      const filteredThresholdMainIntentData = mainIntentData.filter(
        (item) =>
          item.Confidence * 100 >= this.confidenceFilter[0] &&
          item.Confidence * 100 <= this.confidenceFilter[1]
      );

      //get intents to display and red dot data map
      const [intentsToDisplay, redDotDataMap] = this.getIntentsToDisplay(
        filteredThresholdMainIntentData
      );

      //calculate angle for each intent
      const outerCircleAngle = 360 / intentsToDisplay.length;

      //Filter correctly predicted questions for green dots
      //get questions where expected == Actual intent at rank 1, and rank2 intent is included in intentsToDisplay
      const greenQuestionData = this.getGreenDotsData(
        filteredThresholdMainIntentData,
        intentsToDisplay
      );

      //##########################################################
      //Draw diagram

      //svg viewbox
      this.canvas = SVG()
        .addTo("#dataviz-datasetquality")
        .size("100%", this.height)
        .viewbox(0, 0, this.graphWidth, this.graphHeight);

      if (intentsToDisplay.length > 0) {
        this.drawRedLineScale();
        this.drawGreenLineScale();
      }

      this.drawMainIntentCircle();
      //formula to get coordinates of outter circle
      //target X = current x + cos(angle deg) * (length)
      //target Y = current y + sin(angle deg) * (length)

      //for each circle at specific angle, start from the north/top with angle 270
      let cumulativeAngle = 270;
      //Loop each surrounding circle
      for (let i = 0; i < intentsToDisplay.length; i++) {
        //surrounding circle's X
        const circleX =
          this.centerX + Math.cos(cumulativeAngle * (Math.PI / 180)) * this.awayFromCenterLength;
        //surrounding circle's Y
        const circleY =
          this.centerY + Math.sin(cumulativeAngle * (Math.PI / 180)) * this.awayFromCenterLength;

        //draw surrounding circles
        this.drawSurroundingIntentCircle(circleX, circleY, cumulativeAngle, intentsToDisplay[i]);

        const segments = 10;
        const MaxlengthAwayFromLine = 30;

        //draw red dots
        if (redDotDataMap[intentsToDisplay[i]]) {
          this.drawRedDots(
            redDotDataMap[intentsToDisplay[i]],
            segments,
            MaxlengthAwayFromLine,
            cumulativeAngle,
            circleX,
            circleY
          );
        }

        //draw green dots
        if (greenQuestionData[intentsToDisplay[i]]) {
          this.drawGreenDots(
            greenQuestionData[intentsToDisplay[i]],
            segments,
            MaxlengthAwayFromLine,
            cumulativeAngle
          );
        }
        cumulativeAngle += outerCircleAngle;
      }
    },
  },
  watch: {
    showScale: function (val) {
      if (this.showScale) {
        this.drawRedLineScale();
        this.drawGreenLineScale();
      } else {
        this.svgRedScaleArray.forEach((item) => item.remove());
        this.svgGreenScaleArray.forEach((item) => item.remove());
      }
    },
    selectedIntent: function () {
      this.handleRefresh();
    },
    confidenceFilter: function () {
      this.handleRefresh();
    },
    confidenceThreshold: function () {
      this.handleRefresh();
    },
    filterType: function () {
      this.handleRefresh();
    },
  },
  mounted() {
    this.confidenceThreshold = _.get(this, "$store.state.modules.faq.Elsa.threshold", 0.7) * 100;
    this.initDraw();
  },
};
</script>
