<template>
  <el-popover
    placement="bottom"
    title="Create New Page"
    width="300"
    trigger="hover"
    v-model="isCreatePopoverOpen"
  >
    <div>
      <el-input
        v-model="temporaryPageName"
        placeholder="Please input page name..."
        style="margin-bottom: 20px"
      >
        <template slot="append">
          <el-button id="webpage-add-button" type="primary" size="small" @click.prevent="onAdd">
            <i class="el-icon-check"></i>
          </el-button>
        </template>
      </el-input>

      <el-radio-group v-model="temporaryPageType" size="mini">
        <el-radio-button :label="null">Web Page</el-radio-button>
        <el-radio-button label="pdf">PDF</el-radio-button>
      </el-radio-group>
    </div>
    <el-button circle size="mini" icon="el-icon-plus" slot="reference"></el-button>
  </el-popover>
</template>

<script>
export default {
  name: "WebPageEditorAddButton",
  props: ["webPages"],
  data() {
    return {
      isCreatePopoverOpen: false,
      temporaryPageName: null,
      temporaryPageType: null,
    };
  },
  methods: {
    /**
     * @description On add new web page
     * @return {void}
     */
    onAdd() {
      this.webPages.unshift({
        name: this.temporaryPageName,
        type: this.temporaryPageType,
        status: "editing",
        content: "",
      });
      this.$nextTick(() => {
        this.temporaryPageName = null;
        this.temporaryPageType = null;
        this.isCreatePopoverOpen = false;
        this.$emit("onSelect", {
          $index: 0,
          row: this.webPages[0],
        });
      });
    },
  },
};
</script>
