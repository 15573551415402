import { EditorState } from "./types";

const state: EditorState = {
  updateContentNode: false,
  uploadSuccess: false,
  isUploadedContentNodes: false,
  oldNodes: {
    content: {},
    trigger: {},
  },
  refetchContentNodes: false,
  triggerDiff: {},
  connectedNodes: {
    busy: false,
    data: {},
  },
};

export default state;
