import actions from "./actions";
import mutations from "./mutations";
import state from "./state";
import { Module } from "vuex/types";
import { PrismState } from "./types";
import { RootState } from "@/store/types";

const prism: Module<PrismState, RootState> = {
  actions,
  mutations,
  state,
};

export default prism;
