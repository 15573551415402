<template>
  <el-row :gutter="20">
    <el-col :xs="24" :md="10" :lg="8">
      <WebPageEditorList
        :selected-web-page="items[selectedWebPageIndex]"
        :web-pages="items"
        v-loading="isFetching || isFetching"
        @onSelect="onSelectWebPage($event)"
        @onSearch="search = $event"
      />
    </el-col>
    <el-col :xs="24" :md="14" :lg="16">
      <WebPageEditorEditor
        ref="webpage-editor"
        :is-busy="isBusy"
        :is-saving="isSaving"
        :is-deleting="isDeleting"
        :set-status="setStatus"
        :selected-web-page="items[selectedWebPageIndex]"
        @onUpdate="$set(items, selectedWebPageIndex, $event)"
        @onDelete="items.splice(selectedWebPageIndex, 1)"
      />
    </el-col>
  </el-row>
</template>

<script>
import { graph } from "@/store/api";
import gql from "graphql-tag";
import _ from "lodash";

import WebPageEditorList from "./List/Index";
import WebPageEditorEditor from "./Editor/Index";

export default {
  name: "WebPageEditor",
  components: {
    WebPageEditorList,
    WebPageEditorEditor,
  },
  data() {
    return {
      webPages: [],
      search: null,
      selectedWebPageIndex: null,
      isFetching: false,
      isSaving: false,
      isDeleting: false,
      isFetchingStorageType: false,
    };
  },
  mounted() {
    this.fetch();
    this.fetchStorageType();
  },
  methods: {
    /**
     * On webpage selected
     *
     * @return {void}
     */
    onSelectWebPage(id) {
      this.$refs["webpage-editor"].reRendering = true;
      this.selectedWebPageIndex = id;
      _.delay(() => {
        this.$refs["webpage-editor"].reRendering = false;
      }, 200);
    },

    /**
     * @description Fetch storage type for editor
     * @return {void}
     */
    fetchStorageType() {
      this.isFetchingStorageType = true;
      this.$store.dispatch("GET_STORAGE_TYPE").then((storageType) => {
        if (storageType) {
          this.isFetchingStorageType = false;
        }
      });
    },

    /**
     * @description
     * @return {void}
     */
    setStatus(name, status) {
      this[name] = status;
    },

    /**
     * @description Fetch all webpages
     * @return {void}
     */
    fetch() {
      if (!this.isFetching) {
        this.isFetching = true;
        graph
          .query({
            query: gql`
              query {
                webPageEditorAPI {
                  fetch
                }
              }
            `,
            fetchPolicy: "network-only",
          })
          .then((response) => {
            this.webPages = _.get(response, "data.webPageEditorAPI.fetch", []);
          })
          .catch((error) =>
            this.$notify.error({
              title: "Error",
              position: "bottom-right",
              message: `Failed to fetch web pages.`,
            })
          )
          .finally(() => (this.isFetching = false));
      }
    },
  },
  computed: {
    /**
     * List of webpages
     */
    items: {
      get() {
        if (!this.search) {
          return this.webPages;
        }

        return this.webPages.filter((page) =>
          _.includes(page.name.toLowerCase(), this.search.toLowerCase())
        );
      },
      set(updated) {
        this.webPages = updated;
      },
    },
    /**
     * @description Check whether any async process running
     * @return {boolean}
     */
    isBusy() {
      return this.isFetching || this.isSaving || this.isDeleting || this.isFetchingStorageType;
    },
  },
  apollo: {
    $subscribe: {
      webPageUpdated: {
        query: gql`
          subscription {
            webPageUpdated
          }
        `,
        result({ request_reload }) {
          this.$confirm(
            "There is new updates to the webpages. Do you wish to reload the web pages?",
            "New updates",
            {
              confirmButtonText: "Yes",
              cancelButtonText: "Cancel",
              type: "warning",
            }
          )
            .then((_) => {
              this.selectedWebPageIndex = null;
              this.fetch();
              this.$notify.success({
                title: "Success",
                position: "bottom-right",
                message: `Web pages reloaded.`,
              });
            })
            .catch((_) => {});
        },
      },
    },
  },
};
</script>
