import { render, staticRenderFns } from "./SearchMessagesBar.vue?vue&type=template&id=52bba7e8&scoped=true&"
import script from "./SearchMessagesBar.vue?vue&type=script&lang=js&"
export * from "./SearchMessagesBar.vue?vue&type=script&lang=js&"
import style0 from "./SearchMessagesBar.vue?vue&type=style&index=0&id=52bba7e8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52bba7e8",
  null
  
)

export default component.exports