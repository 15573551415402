import store from "@/store";
import moment from "moment";
import { IBroadcast } from "@/store/modules/broadcast/types";

export function isPast(timestamp: number | string): boolean {
  // FIXME: remove me once this new implementation is correct
  // const selectedBroadcastId = store.state.broadcast.selectedBroadcastId;
  // if (timestamp && selectedBroadcastId) {
  //   return moment.unix(Number(selectedBroadcastId) / 1000).isBefore(moment());
  // } else {
  //   return false;
  // }

  if (timestamp) {
    return moment.unix(Number(timestamp) / 1000).isBefore(moment());
  } else {
    return false;
  }
}

export function isValidNode(nodeId: string): boolean {
  const node = store.state.nodes.content[nodeId];
  const isValidNode = !!node;
  return isValidNode;
}

export function highlightPendingBroadcasts({ row }: { row: IBroadcast }): string {
  let className = "past-broadcast-row";
  const isFuture = !isPast(row.timestamp);

  if (row && isFuture) {
    className = "warning-broadcast-row";
  }

  return className;
}

export const pickerOptions = {
  disabledDate(time: Date) {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return time.getTime() < yesterday.getTime();
  },
  shortcuts: [
    {
      text: "Today",
      onClick(picker: any) {
        picker.$emit("pick", new Date());
      },
    },
    {
      text: "Tomorrow",
      onClick(picker: any) {
        const date = new Date();
        date.setTime(date.getTime() + 3600 * 1000 * 24);
        picker.$emit("pick", date);
      },
    },
    {
      text: "A week later",
      onClick(picker: any) {
        const date = new Date();
        date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
        picker.$emit("pick", date);
      },
    },
  ],
};
