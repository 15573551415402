<template>
  <el-tabs type="border-card">
    <el-tab-pane label="Examples" style="padding: 25px"
      ><IntentExamples :handleEditModel="handleEditModel" :intentEdit="intentEdit"
    /></el-tab-pane>
    <el-tab-pane :disabled="isDisabledAnswer(intentEdit.examples)" label="Answer"
      ><IntentAnswer :intentEdit="intentEdit" :handleEditModel="handleEditModel"
    /></el-tab-pane>
  </el-tabs>
</template>

<script>
import IntentExamples from "./IntentExamples.vue";
import IntentAnswer from "./IntentAnswer.vue";
export default {
  components: { IntentExamples, IntentAnswer },
  props: ["intentEdit", "handleEditModel"],
  data() {
    return {};
  },
  methods: {
    isDisabledAnswer(example) {
      return !example;
    },
  },
};
</script>

<style scoped>
.el-tabs {
  height: 100%;
}

.el-tabs .el-tabs__content {
  height: calc(100% - 70px);
  overflow-y: scroll;
  padding: 0px 15px;
}
</style>
