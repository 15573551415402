<template>
  <div class="box">
    <h3>{{ label }}: {{ data }}</h3>
  </div>
</template>
<script>
export default {
  props: ["data", "label"],
};
</script>

<style scoped lang="scss">
@import "../assets/scss/colors.scss";

.box {
  font-size: 20px;
  text-align: center;
  padding-top: 50px;
  border: 2px solid $color-primary;
  background: white;
  color: $color-primary;
  height: 100px;
  width: 100%;

  transition: border 0.5s, background 0.5s;
}

.box:hover {
  background: $color-primary;
  color: white;
}
</style>
