<template>
  <div style="text-align: center">
    <el-input
      v-model="sideEffect.data.url"
      placeholder="Enter URL here"
      size="mini"
      style="width: 60%; margin-bottom: 25px"
    >
      <template slot="prepend">
        URL
        <el-tooltip effect="dark" content="what is the url to call" placement="top">
          <i class="has-text-grey el-icon-question" style="margin-left: 0.5em" />
        </el-tooltip>
      </template>
    </el-input>

    <div style="margin-bottom: 10px">
      <el-radio-group v-model="sideEffect.data.methodType" size="mini">
        <el-radio-button label="GET">GET</el-radio-button>
        <el-radio-button label="POST">POST</el-radio-button>
      </el-radio-group>
    </div>

    <ul>
      <li style="list-style-type: none">
        <el-button icon="el-icon-plus" size="mini" type="text" @click="addRequestHeader">
          Add Request Header
        </el-button>
      </li>
      <li
        v-for="(kv, index) in sideEffect.data.requestHeaders"
        :key="index"
        style="list-style-type: none"
      >
        <input
          style="max-width: 40%; margin-right: 5px; margin-bottom: 5px"
          v-model="kv.key"
          placeholder="Input key"
        />
        <input
          style="max-width: 40%; margin-right: 5px; margin-bottom: 5px"
          v-model="kv.value"
          type="text"
          placeholder="Input value"
        />
        <el-button
          class="el-icon-delete"
          size="mini"
          type="text"
          @click="removeRequestHeader(index)"
        />
      </li>
    </ul>
    <ul>
      <li style="list-style-type: none">
        <el-button size="mini" icon="el-icon-plus" type="text" @click="addParameter">
          Add Parameter
        </el-button>
      </li>
      <li
        v-for="(param, parameterIndex) in sideEffect.data.parameters"
        :key="parameterIndex"
        style="list-style-type: none"
      >
        <input
          style="max-width: 40%; margin-right: 5px; margin-bottom: 5px"
          type="text"
          placeholder="Input key"
          :value="param.key"
        />
        <input
          style="max-width: 40%; margin-right: 5px; margin-bottom: 5px"
          type="text"
          placeholder="Input value"
        />
        <el-button size="mini" class="el-icon-delete" type="text" @click="removeParameter(index)" />
      </li>
    </ul>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: ["sideEffect"],
  beforeMount() {
    if (!_.isPlainObject(this.sideEffect.data)) {
      this.sideEffect.data = {};
    }

    if (!_.has(this.sideEffect.data, "parameters")) {
      this.sideEffect.data.parameters = [];
    }

    if (!_.has(this.sideEffect.data, "requestHeaders")) {
      this.sideEffect.data.requestHeaders = [];
    }
  },
  methods: {
    addRequestHeader() {
      this.sideEffect.data.requestHeaders.push({
        key: "",
        value: "",
      });
      this.$forceUpdate();
    },
    removeRequestHeader(index) {
      this.sideEffect.data.requestHeaders.splice(index, 1);
      this.$forceUpdate();
    },
    addParameter() {
      if (!this.sideEffect.data.parameters) {
        this.sideEffect.data.parameters = [];
      }
      this.sideEffect.data.parameters.push({
        key: "",
        value: "",
      });
      this.$forceUpdate();
    },
    removeParameter(index) {
      this.sideEffect.data.parameters.splice(index, 1);
      this.$forceUpdate();
    },
  },
};
</script>
