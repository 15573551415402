<template>
  <el-row v-if="message.type === 'message'">
    <el-table ref="faqTable" :data="dataset" size="mini" style="max-height: 600px; overflow: auto">
      <el-table-column
        header-align="center"
        property="question"
        label="Add this message to a question?"
        style="cursor: pointer"
      >
        <template slot-scope="scope">
          <el-row type="flex" justify="space-between" style="margin-bottom: 0" align="middle">
            <div style="max-width: 88%">{{ scope.row.question }}</div>
            <i
              @click.prevent="addAndSave(scope.row, $event, scope.column)"
              style="font-size: 16px"
              class="el-icon-circle-plus cursor-pointer"
            ></i>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
  </el-row>
</template>
<script>
import _ from "lodash";
import gql from "graphql-tag";
import { prepareDatasetToSave } from "@/helperMethods/faq/util";

export default {
  name: "AddToFaq",
  props: ["message", "isOpened"],
  computed: {
    messageText() {
      return _.get(this.message, "data.content[0].text", "");
    },
    dataset() {
      let res =
        _.map(this.$store.state.training.dataset, (faq) => {
          faq.tagInputVisible = false;
          faq.tagInputValue = "";
          faq.enabled = !!faq.enabled; // Cast to boolean type
          faq.tags = faq.tags || [];

          return faq;
        }) || [];
      return res;
    },
  },
  watch: {
    isOpened: {
      handler(opened) {
        if (opened) {
          this.refresh();
        }
      },
    },
  },
  methods: {
    refresh() {
      return this.$store.dispatch("FETCH_FAQ_DATASET");
    },
    addAndSave(row, event, column) {
      let text = this.message.data.text;
      if (!text && this.message.data.content && this.message.data.content.length) {
        text = this.message.data.content[0].text;
      }
      this.newVariation({
        id: row.id,
        text: text,
        variations: row.variations,
      });
    },
    newVariation({ id, text, variations }, save = true) {
      let pair = this.dataset.find((data) => {
        return data.id === id;
      });

      if (!variations) {
        if (pair) {
          variations = pair.variations;
        } else {
          return;
        }
      }

      if (text && text.trim().length > 0) {
        let lines = text.split(/\n/);
        _.forEach(lines, (line) => {
          line = line.trim();
          if (line) {
            variations.unshift({ text: line, is_suggestion: false });
          }
        });
        if (save) {
          this.save();
        }
      }
    },
    async save() {
      this.saving = true;
      const datasetToSave = prepareDatasetToSave(this.$store.state.training.dataset);
      const isSaved = await this.$apollo
        .mutate({
          mutation: gql`
            mutation($dataset: JSON!) {
              faqAPI {
                saveDataset(dataset: $dataset)
              }
            }
          `,
          variables: {
            dataset: datasetToSave,
          },
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: "Failed to save FAQ dataset.",
          });
          return false;
        });
      if (isSaved) {
        this.dirty = false;
        this.saving = false;

        this.$message({
          type: "success",
          message: "Saved",
        });
      }
    },
  },
};
</script>
