<template>
  <div class="intent-answer">
    <div class="intent-answer-save" v-if="!isDisableAnswer">
      <el-button
        type="danger"
        icon="el-icon-check"
        @click="saveAnswer"
        :loading="isLoading"
        v-if="!isSaved"
        >Save</el-button
      >
      <el-button type="success" icon="el-icon-check" @click="saveAnswer" :loading="isLoading" v-else
        >Saved</el-button
      >
    </div>
    <el-row style="margin-top: 10px; margin-bottom: 0px">
      <el-col style="padding-left: 30px">
        <label
          style="
            vertical-align: middle;
            margin-left: 0.4em;
            margin-bottom: 2em;
            margin-right: 0.5em;
            fontsize: 14px;
          "
          class="has-text-grey"
        >
          Department:
          <el-tooltip
            effect="dark"
            content="The department this intent belongs to. General by default."
            placement="top"
          >
            <i class="has-text-grey el-icon-question" style="margin-left: 0.2em"></i>
          </el-tooltip>
        </label>
        <el-select
          placeholder="Select a department"
          disabled
          v-model="departments"
          multiple
          filterable
        >
          <el-option :value="departments[0]" :label="departments[0]">
            <span style="float: left">{{ departments[0] }}</span>
          </el-option>
        </el-select>
      </el-col>
      <el-col
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 10px 10px 0 10px;
        "
      >
        <el-button round @click="handleJumpToButton">Jump to Content</el-button></el-col
      >
    </el-row>
    <div class="intent-answer-action" v-if="!checkIntentExist() && isOpenContent">
      <div class="intent-answer-dropdown">
        <el-select
          v-model="selectedCountry"
          slot="prepend"
          placeholder="Select"
          v-if="getExamples()"
        >
          <el-option
            v-for="(value, name, index) in getExamples()"
            :key="`option${index}`"
            :label="getNameCountry(name)"
            :value="name"
          ></el-option>
        </el-select>
      </div>
      <p><b>Actions</b> <i class="el-icon-arrow-right"></i> {{ getActionIntent() }}</p>
      <el-input :placeholder="getActionIntent()" disabled style="padding-top: 10px">
        <template slot="prepend">
          <div style="width: 50px">Action</div>
        </template>
      </el-input>

      <el-autocomplete
        v-model="answer"
        class="autocomplete-list autocomplete-list-target"
        style="margin-top: 0; padding: 20px 0"
        :disabled="isDisableAnswer"
        placeholder="Target"
        :fetch-suggestions="getContentNodes"
      >
        <div slot="prepend" style="width: 50px">Data</div>
        <el-button
          slot="append"
          icon="el-icon-d-arrow-right"
          :disabled="isDisableAnswer"
          @click="selectNode({ type: 'content', id: answer })"
        />
      </el-autocomplete>
    </div>
    <div class="intent-answer-container" v-if="isOpenContent">
      <b>Preview</b>
      <div v-if="!checkIntentExist()" class="intent-answer-container-content">
        <div class="intent-answer-container-content-title">
          <p>Trending topic</p>
          <p style="font-weight: bold">Answer</p>
        </div>
        <div class="intent-answer-container-content-main" v-if="publishedExamples()">
          {{ publishedExamples() }}
        </div>
        <div class="intent-answer-container-content-main">
          <div
            class="intent-answer-container-content-main-text"
            v-if="selectedNodeData(answer) && !selectedNodeData(answer).image"
          >
            <div v-if="checkIsArray(selectedNodeData(answer).text)">
              <p v-for="(text, i) in selectedNodeData(answer).text" :key="'text' + i">
                {{ text }}
              </p>
            </div>
            <div v-else>
              {{ selectedNodeData(answer).text }}
            </div>
            <div v-if="selectedNodeData(answer).buttons">
              <p v-for="(btn, i) in selectedNodeData(answer).buttons" :key="btn.text + i">
                {{ btn.text }}
              </p>
            </div>
          </div>
          <div v-else-if="!isDisableAnswer">
            <p>This intent has no action.</p>
            <p>Please select an action above.</p>
          </div>
          <div v-else>
            <p>This intent redirect to url</p>
            <a :href="answer" target="_blank">
              <p style="font-style: italic; text-decoration: underline" class="has-text-danger">
                {{ answer }}
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getExampleByCountry,
  getNameCountryFromExample,
  prepareAnswerToSave,
} from "@/helperMethods/trendingTopics/methods";
import { mixin } from "@/helperMethods/trendingTopics/mixins";
import gql from "graphql-tag";
import _, { isArray } from "lodash";
export default {
  mixins: [mixin],
  props: ["intentEdit", "handleEditModel"],
  data() {
    return {
      answer: "",
      selectedCountry: "",
      isLoading: false,
      isSaved: false,
      departments: ["general"],
      isOpenContent: false,
      isDisableAnswer: false,
    };
  },
  methods: {
    checkExistUrl() {
      const { intentId } = this.intentEdit;
      const { url } = JSON.parse(intentId);
      if (url) {
        this.answer = url;
        this.isDisableAnswer = true;
      }
    },
    getActionIntent() {
      const { type, url, event } = this.actionIntent();
      if (url) return type;
      return event;
    },
    handleJumpToButton() {
      this.isOpenContent = true;
      const examples = this.getExamples();
      this.selectedCountry = Object.keys(examples)[0] || "";
    },
    getNameCountry(countryCode) {
      return getNameCountryFromExample(countryCode);
    },
    getExamples() {
      const { examples } = this.intentEdit;
      if (!examples) return "";
      return JSON.parse(examples);
    },
    checkIsArray(param) {
      return isArray(param);
    },
    checkIntentExist() {
      return this.intentEdit && Object.keys(this.intentEdit).length === 0;
    },
    publishedExamples() {
      if (!this.intentEdit.examples) return "";
      return getExampleByCountry(this.intentEdit.examples, this.selectedCountry);
    },
    actionIntent() {
      return JSON.parse(this.intentEdit.intentId);
    },
    selectedNodeData(answer) {
      const actionEvent = this.actionIntent().event;
      let selectedContentNode;
      switch (actionEvent) {
        case "goto":
          {
            const contentNodeID = answer;
            selectedContentNode = this.$store.state.nodes.content[contentNodeID];
          }
          break;
        case "capture":
          {
            const { next } = answer;
            if (next && next.constructor === Object) {
              const { event, data } = next;
              if (event === "goto" && data) {
                selectedContentNode = this.$store.state.nodes.content[data];
              }
            } else if (next) {
              selectedContentNode = this.$store.state.nodes.content[next];
            }
          }
          break;
        // The other events do not need to show the content node.
      }

      return selectedContentNode ? selectedContentNode.content : "";
    },
    getContentNodes(value, cb) {
      if (value && this.$store.state.search.content) {
        cb(this.$store.state.search.content.search(value));
      } else {
        cb([]);
      }
    },
    async saveAnswer() {
      let isSaved;
      const { id, intentId, examples: examplesById } = this.intentEdit;
      // const examplesById = await this.getExamplesById(id);
      this.isLoading = true;
      if (this.selectedCountry) {
        const examplesToSave = prepareAnswerToSave(this.answer, examplesById, this.selectedCountry);
        isSaved = await this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: Int, $examples: JSON) {
                saveExamplesForTopic(id: $id, examples: $examples)
              }
            `,
            variables: {
              id,
              examples: examplesToSave,
            },
          })
          .catch(() => {
            this.$message({
              showClose: true,
              type: "error",
              message: "Failed to add answer.",
            });
            return false;
          });
        this.intentEdit.examples = JSON.stringify(examplesToSave);
      } else {
        const oldIntentId = intentId;
        const button = JSON.parse(intentId);
        button.data = this.answer;
        const newIntentId = JSON.stringify(button);
        isSaved = await this.$apollo
          .mutate({
            mutation: gql`
              mutation ($oldIntentId: String!, $newIntentId: String!) {
                updateIntentId(oldIntentId: $oldIntentId, newIntentId: $newIntentId)
              }
            `,
            variables: {
              oldIntentId,
              newIntentId,
            },
          })
          .catch(() => {
            this.$message({
              showClose: true,
              type: "error",
              message: "Failed to add answer.",
            });
            return false;
          });
        this.intentEdit.intentId = newIntentId;
      }
      if (isSaved) {
        this.$message({
          showClose: true,
          message: "Answer add successfully",
          type: "success",
        });
      }
      this.isLoading = false;
      this.isSaved = true;
      await this.reCallApi();
    },
    selectNode({ type, id }) {
      const currentRoute = _.get(this, "$router.currentRoute.fullPath");
      if (currentRoute !== "/editor") {
        this.$router.push("/editor");
      }
      this.$store.dispatch("SELECT_NODE", { type, id });
    },
  },
  watch: {
    intentEdit: function () {
      this.isSaved = false;
      this.selectedCountry = "";
      this.isOpenContent = false;
      if (!_.isEmpty(this.intentEdit)) {
        this.answer = JSON.parse(this.intentEdit.intentId).data;
        this.checkExistUrl();
      }
    },
    selectedCountry: {
      handler() {
        this.isSaved = false;
        const exampleRecord = JSON.parse(this.intentEdit.examples);
        const answerByCountry = exampleRecord[this.selectedCountry].answer;
        if (answerByCountry) this.answer = answerByCountry;
      },
    },
  },
  created() {
    this.selectedCountry = "";
    if (!_.isEmpty(this.intentEdit)) {
      this.answer = JSON.parse(this.intentEdit.intentId).data;
      this.checkExistUrl();
    }
  },
};
</script>

<style lang="scss" scoped>
.intent-answer {
  max-height: 435px;
  overflow-y: scroll;
  &-save {
    position: fixed;
    bottom: 5px;
    right: 10px;
  }
  &-dropdown {
    position: absolute;
    right: 15px;
    top: 10px;
    width: 150px;
  }
  &-container {
    padding: 15px;
    height: 67%;
    box-shadow: 0px 0px 3px 5px rgba(0, 0, 0, 0.17);
    overflow-y: scroll;
    &-content {
      &-title {
        margin: 0;
      }
      &-main {
        &-text {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }
}
</style>
