import { ContactAttribute, OutReachContact } from "@/store/modules/outreach/types";
import { ATTRIBUTE_TYPE } from "./attribute";

export enum OUT_REACH_FILTER_COMPARISON {
  IS = "is",
  CONTAINS = "contains",
}

export interface IOption<T> {
  label: string;
  value: T;
  attributeType?: ATTRIBUTE_TYPE;
  options?: string[];
}

export interface FilterOutReachItem {
  comparison: OUT_REACH_FILTER_COMPARISON;
  variable: keyof OutReachContact;
  value: string | string[];
  valueArray: string[];
}

export const defaultExcludeFields: Array<keyof OutReachContact> = [
  "ID",
  "id",
  "isDuplicate",
  "lastSeen",
  "contactattributes",
];

export const outReachFilterChannel = {
  Whatsapp: "whatsapp",
  Email: ["email", "gmail", "hotmail", "outlook"],
  Phone: ["phone", "phone number"],
  "Web chat": ["webchat", "livechat", "live chat", "chat", "chat widget"],
};

export const haveContainComparisonKey = ["name", "primaryContact"];

export const formatCamelCaseToStringNormal = (key: string): string => {
  const result = key.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const formatUpperFirstLetter = (key: string): string => {
  return key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
};

export const getDefaultFilterOutReach = (
  data: FilterOutReachItem
): { [key: number]: Partial<FilterOutReachItem> } => {
  const maxKey = Math.max(...Object.keys(data).map((key) => +key));
  return {
    [maxKey + 1]: {
      variable: undefined,
      comparison: OUT_REACH_FILTER_COMPARISON.IS,
      value: "",
      valueArray: [],
    },
  };
};

export const getOutReachVariableExcludeField = (
  excludeFields: Array<keyof OutReachContact> = defaultExcludeFields,
  attributes: ContactAttribute[] = []
): IOption<string>[] => {
  const outReachContact = new OutReachContact();
  const attributeFilter: IOption<string>[] = attributes.map(
    ({ label, name, value, type: attributeType = ATTRIBUTE_TYPE.TEXT_BOX }) => ({
      label,
      value: name,
      attributeType,
      options: value,
    })
  );
  const defaultFilter = Object.getOwnPropertyNames(outReachContact)
    .sort((a, b) => a.localeCompare(b))
    .reduce((result: IOption<keyof OutReachContact>[], key) => {
      if (!excludeFields.includes(key as keyof OutReachContact)) {
        result.push({
          label: formatCamelCaseToStringNormal(key),
          value: key as keyof OutReachContact,
        });
      }
      return result;
    }, []);
  return [...defaultFilter, ...attributeFilter];
};

export const getOutReachSortableVariableExcludeField = (
  excludeFields: Array<keyof OutReachContact> = defaultExcludeFields,
  attributes: ContactAttribute[] = []
): IOption<string>[] => {
  const outReachContact = new OutReachContact();
  const attributeFilter: IOption<string>[] = attributes.map(
    ({ label, name, value, type: attributeType = ATTRIBUTE_TYPE.TEXT_BOX }) => ({
      label,
      value: name,
      attributeType,
      options: value,
    })
  );

  const additionalNameFields = [
    { label: "First Name", value: "firstName" as keyof OutReachContact },
    { label: "Last Name", value: "lastName" as keyof OutReachContact },
  ];

  const defaultFilter = Object.getOwnPropertyNames(outReachContact)
    .sort((a, b) => a.localeCompare(b))
    .reduce((result: IOption<keyof OutReachContact>[], key) => {
      if (
        !excludeFields.includes(key as keyof OutReachContact) &&
        !["stage", "tags"].includes(key)
      ) {
        if (key !== "name") {
          result.push({
            label: formatCamelCaseToStringNormal(key),
            value: key as keyof OutReachContact,
          });
        } else {
          additionalNameFields.forEach((additionalNamefield) => result.push(additionalNamefield));
        }
      }
      return result;
    }, []);
  const rs = [...defaultFilter, ...attributeFilter];
  return rs;
};

export const getComparisonOptions = (haveContain = true): IOption<OUT_REACH_FILTER_COMPARISON>[] =>
  Object.keys(OUT_REACH_FILTER_COMPARISON).reduce(
    (result: IOption<OUT_REACH_FILTER_COMPARISON>[], key) => {
      if (haveContain) {
        return [
          ...result,
          {
            label: formatUpperFirstLetter(key),
            value: OUT_REACH_FILTER_COMPARISON[key],
          },
        ];
      } else if (key === OUT_REACH_FILTER_COMPARISON.CONTAINS) {
        return result;
      }
      return result;
    },
    []
  );

export const optionChannelFilter = () =>
  Object.keys(outReachFilterChannel).map((key) => ({
    id: outReachFilterChannel[key],
    name: key,
  }));

export const checkIsPhoneNumber = (phone: string): boolean => {
  const regex = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{3})/;
  // const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,5}$/im;
  return regex.test(phone);
};

// comment for future features

// export const getFilteredListFromList = (data: OutReachContact[], filters: FilterOutReachItem[]) => {
//   return data.reduce((result: OutReachContact[], item) => {
//     let isValid = true;
//     filters.forEach((filter) => {
//       const { comparison, value, variable } = filter;
//       const itemValue = item[variable];
//       const itemFormatted = Array.isArray(itemValue)
//         ? itemValue
//             .map(({ name }) => name)
//             .join(" ")
//             .toLowerCase()
//         : typeof itemValue === "object"
//         ? itemValue.name.toLowerCase() || ""
//         : itemValue.toString().toLowerCase();

//       if (comparison === OUT_REACH_FILTER_COMPARISON.IS) {
//         isValid = itemFormatted === value.toLowerCase();
//       } else {
//         isValid = itemFormatted.includes(value.toLowerCase());
//       }
//     });
//     if (isValid) result.push(item);
//     return result;
//   }, []);
// };

export const OUT_REACH_IMPORT_NOTES = [
  "Tags should be max 20 character including space.",
  "Separate two tags with a comma.",
  `Tags & Stage on CSV file and KeyReply dashboard should match exactly. Else, the field will
  show blank.`,
  `Don't make any changes to the column titles.`,
  `Delete the example contact once
  the purpose is served`,
  `Custom attribute column name must follow template "attribute_name"`,
];

export const OUT_REACH_MODULE = "outreach";
