import _ from "lodash";

const getFilteredList = (data: {}, modules: any, query: string) => {
  return _.pickBy(data, (value, key) => {
    const module = modules[key];
    const moduleExist = !!module;
    key = key.toUpperCase();
    let containsSearchTerm = key.includes(query.toUpperCase());
    // if the key doesn't match, then search within the object
    if (!containsSearchTerm && module.enabled) {
      const withinObjectContainsTerm = anyObjectContainsTerm(value, query);
      if (withinObjectContainsTerm) {
        containsSearchTerm = true;
      }
    }
    return moduleExist && containsSearchTerm;
  });
};

const anyObjectContainsTerm = (value: any, query: string) => {
  let containsSearchTerm = false;
  // loop through the keys inside the object
  const objectKeys = Object.keys(value);
  objectKeys.every((objectKey) => {
    const element = value[objectKey];
    const termFound = objectContainsTerm(element, query);
    if (termFound) {
      containsSearchTerm = true;
      // break the loop immediately
      return false;
    }
    return true;
  });
  return containsSearchTerm;
};

const objectContainsTerm = (
  obj: {
    label: string;
    detail: string;
    children: {};
  },
  query: string
) => {
  // read label and detail if contains query
  const queryLower = query.toLowerCase();
  const labelExists = !!obj.label;
  const detailExists = !!obj.detail;
  const childrenExist = !!obj.children;
  let containsTerm = false;

  if (labelExists) {
    const nameContainsTerm = obj.label.toLowerCase().includes(queryLower);
    containsTerm = containsTerm || nameContainsTerm;
  }
  if (detailExists) {
    const detailContainsTerm = obj.detail.toLowerCase().includes(queryLower);
    containsTerm = containsTerm || detailContainsTerm;
  }
  if (childrenExist) {
    containsTerm = anyObjectContainsTerm(obj.children, query);
  }
  return containsTerm;
};

export default {
  anyObjectContainsTerm,
  getFilteredList,
};
