import { RootState } from "@/store/types";
import { GetterTree } from "vuex";
import { OutreachState } from "./types";

const getters: GetterTree<OutreachState, RootState> = {
  contactsArray(state) {
    return state.contacts;
  },
  contactsTags(state) {
    return state.tags;
  },
  contactsStages(state) {
    return state.stages;
  },
  contactFilterBoard(state) {
    return state.filterBoard;
  },
  contactAttributes(state) {
    return state.contactAttributes;
  },
};

export default getters;
