<template>
  <div class="body-container">
    <label class="body-text">{{ previewText }}</label>
  </div>
</template>

<script>
export default {
  name: "WhatsAppMessageTemplateBody",
  components: {},
  props: {
    previewText: {
      type: String,
      default: "No Preview Available",
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.body-container {
  padding: 10px 10px 10px 10px;
}

.body-text {
  white-space: pre-wrap;
}
</style>
