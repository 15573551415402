import Vue from "vue";
import { MutationTree } from "vuex/types";

import { ZoomState } from "./types";

const mutations: MutationTree<ZoomState> = {
  SET_ZOOM_MEETING: (state, payload) => {
    Vue.set(state.meetings, payload.user_id, payload)
  }
};

export default mutations;
