<template>
  <div>
    <el-row>
      <el-col :span="5" style="max-width: 160px">
        <label
          v-if="mode !== 'form'"
          class="has-text-grey"
          style="vertical-align: middle; margin-left: 1.6em; margin-bottom: 2em; fontsize: 14px"
        >
          {{ setting.label || name }}
          <Tooltip :label="setting.label || name" :content="setting.detail" />
        </label>
      </el-col>
      <el-col :span="23" style="max-width: 540px; margin-left: 0.5em">
        <el-input
          v-model="value[name]"
          type="textarea"
          :placeholder="setting.placeholder"
          :autosize="{ minRows: 4 }"
          :required="setting.required"
          style="margin-bottom: 1em"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Tooltip from "../Tooltip.vue";
export default {
  components: { Tooltip },
  props: ["value", "setting", "name", "mode"],
  mounted() {
    if (this.value[this.name] == null) {
      if (this.setting.default != null) {
        this.$set(this.value, this.name, this.setting.default);
      } else {
        this.$set(this.value, this.name, null);
      }
    }
  },
};
</script>
