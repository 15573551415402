<template>
  <div>
    <div slot="header" class="clear-fix">
      <h3 style="line-height: 36px">
        {{ activeFlowTitle ? `${activeFlowTitle} Preview` : "Flow preview" }}
      </h3>

      <el-button
        circle
        plain
        type="danger"
        size="small"
        icon="el-icon-close"
        @click="$emit('closePreview')"
        style="float: right"
      />

      <el-tooltip content="Restart workflow" placement="right">
        <el-button
          circle
          size="small"
          icon="el-icon-refresh"
          style="float: right; margin-right: 5px"
          @click="$emit('startFlow')"
        />
      </el-tooltip>
    </div>
  </div>
</template>
<script>
export default {
  props: ["activeFlowTitle"],
};
</script>

<style lang="css"></style>
