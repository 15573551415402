<template>
  <div>
    <div v-if="renderChart">
      <el-row v-if="isAccuracyAvailable">
        <el-col :span="24">
          <chart-container
            :chart="{
              desc: 'FAQ Accuracy',
              type: 'doughnut',
              data: predictionAccuracy,
            }"
          />
        </el-col>
      </el-row>
      <el-row v-else>
        <el-col :span="24">
          <p>FAQ Accuracy is not available</p>
          <small style="margin-bottom: 20px; display: inline-block">
            This is probably caused by examples not trained yet or empty examples.
          </small>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import ChartContainer from "@/components/FAQChartContainer";

export default {
  components: { ChartContainer },
  props: ["selectedIntent", "threshold"],
  data() {
    return {
      isAccuracyAvailable: true,
      renderChart: true,
    };
  },
  computed: {
    predictionAccuracy() {
      this.renderChart = false;
      this.$nextTick(() => {
        this.renderChart = true;
      });
      const variations = this.selectedIntent.variations;
      let correctPredictions = 0;
      let unclearPredictions = 0;
      variations.forEach((variation) => {
        if (Array.isArray(variation.intent_ranking)) {
          const intentRankingNotExist = variation.intent_ranking.length === 0;
          if (intentRankingNotExist) {
            return;
          }
          const intentRanking = variation.intent_ranking.filter(
            (ranking) => ranking.name === this.selectedIntent.question
          );
          if (intentRanking.length === 0) {
            return;
          }
          const confidence = intentRanking[0].confidence;
          // check confidence if above or equal threshold
          if (confidence >= this.threshold) {
            // add to correct prediction count
            correctPredictions++;
          } else {
            // add to correct unclear count
            unclearPredictions++;
          }
        }
      });

      const totalPredictions = correctPredictions + unclearPredictions;
      this.isAccuracyAvailable = totalPredictions > 0;

      // calculate percentage to nearest 2 digit decimals
      const percentageCorrect =
        Math.round((correctPredictions / totalPredictions) * (100 * 100)) / 100;
      const percentageUnclear =
        Math.round((unclearPredictions / totalPredictions) * (100 * 100)) / 100;

      if (this.isAccuracyAvailable) {
        return [
          {
            value: correctPredictions,
            label: `Correctly Predicted (${percentageCorrect || 0}%)`,
          },
          {
            value: unclearPredictions,
            label: `Unclear (${percentageUnclear || 0}%)`,
          },
        ];
      }
      return [];
    },
  },
  watch: {
    selectedIntent: {
      handler: function () {
        this.isAccuracyAvailable = true;
        this.renderChart = true;
      },
      deep: true,
    },
  },
};
</script>

<style></style>
