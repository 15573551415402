<template>
  <div class="folder-item">
    <div
      style="height: 80px; width: 100px"
      :style="{ backgroundImage: `url(${folderSrc})` }"
      class="folder-item-icon"
    ></div>
    <el-tooltip class="item" effect="dark" :content="name" placement="bottom-start">
      <p class="folder-item-name">{{ name }}</p>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  props: ["name", "isSelected"],
  computed: {
    folderSrc() {
      return require("../../assets/folder.png");
    },
  },
};
</script>

<style scoped>
.folder-item-name {
  margin: 0;
  text-align: center;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.folder-item {
  width: 100px;
  height: fit-content;
  margin: 10px;
  cursor: pointer;
}
.folder-item-icon {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
</style>
