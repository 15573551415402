<template>
  <div>
    <el-form-item prop="data">
      <el-input v-model="value.data.type" placeholder="iframe" disabled>
        <template slot="prepend">Type</template>
        <el-select v-model="value.data.type" placeholder="iframe" slot="append">
          <el-option
            v-for="item in ['inline', 'iframe', 'tab']"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-input>
    </el-form-item>

    <el-form-item prop="data">
      <el-input v-model="value.data.url" placeholder="data">
        <template slot="prepend">URL</template>
      </el-input>
    </el-form-item>

    <el-checkbox v-show="value.data.type === 'inline'" v-model="value.data.hideBackButton">
      Hide Back Button
    </el-checkbox>
  </div>
</template>

<script>
export default {
  name: "EventNodeTypeDisplay",
  props: ["value"],
  beforeMount() {
    if (
      this.value.data === undefined ||
      this.value.data === null ||
      typeof this.value.data === "string"
    ) {
      this.value.data = {
        type: "iframe",
        url: "",
        hideBackButton: false,
      };
    }
  },
};
</script>
