<template>
  <div>
    <el-radio-group v-model="auth.type" size="small" style="margin-bottom: 15px">
      <el-radio-button v-for="method in authMethods" :key="method" :label="method">
        {{ method }}
      </el-radio-button>
    </el-radio-group>

    <!-- Basic -->
    <template v-if="auth.type === 'Basic'">
      <el-form-item label="Username" style="margin-bottom: 5px">
        <el-input
          size="mini"
          placeholder="Enter username"
          style="width: 50%"
          v-model="auth.username"
        ></el-input>
      </el-form-item>
      <el-form-item label="Password" style="margin-bottom: 5px">
        <el-input
          size="mini"
          style="width: 50%"
          type="password"
          placeholder="Enter password"
          v-model="auth.password"
        ></el-input>
      </el-form-item>
    </template>

    <!-- Header -->
    <template v-else-if="auth.type === 'Api'">
      <p class="input-label">API to execute</p>
      <el-select
        size="mini"
        v-model="auth.api_name"
        filterable
        class="api-select"
        style="margin-bottom: 10px"
      >
        <el-option
          v-for="(option, key) in $store.state.apieditor.mappings"
          :key="key"
          :label="key"
          :value="key"
        >
        </el-option>
      </el-select>

      <p class="input-label">Retry authentication when API status code matches</p>
      <el-input class="api-input" size="mini" v-model="auth.api_recall_if_error_code"></el-input>
    </template>

    <template v-else-if="auth.type === 'Bearer Token'">
      <el-form-item label="Token" style="margin-bottom: 5px">
        <el-input
          size="mini"
          style="width: 50%"
          placeholder="Enter token"
          v-model="auth.token"
        ></el-input>
      </el-form-item>
    </template>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: ["auth"],
  data() {
    return {};
  },
  computed: {
    authMethods() {
      return ["Basic", "Api", "Bearer Token"];
    },
  },
  beforeMount() {
    this.auth.type = _.capitalize(this.auth.type);
  },
  methods: {
    addHeader() {
      this.$emit("addHeader");
    },
    removeHeader(index) {
      this.$emit("removeHeader", index);
    },
  },
};
</script>

<style scoped>
.input-label {
  font-style: italic;
  font-size: 12px;
  margin: 5px 0;
  color: gray;
}
.api-input {
  max-width: 12.5%;
}
</style>
