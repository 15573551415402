<template>
  <div class="interactive-message-section">
    <h3>Contact #{{ index + 1 }}</h3>
    <WhatsAppContactInfoCard
      title="Personal Info"
      :obj="contact.name"
      :fields="formFields.personal"
    >
      <el-form-item>
        <el-date-picker type="date" v-model="contact.birthday" placeholder="Birthday">
        </el-date-picker>
      </el-form-item>
    </WhatsAppContactInfoCard>
    <div v-if="showOptionalGroup">
      <WhatsAppContactInfoCard title="Organization" :obj="contact.org" :fields="formFields.org" />
      <div style="margin: 12px 0">
        <el-button
          type="primary"
          style="margin-bottom: 12px"
          @click="addGroupField(contact, 'phones')"
          plain
          >Add Phone</el-button
        >
        <WhatsAppContactCards
          v-if="contact.phones.length > 0"
          title="Phone"
          :fields="formFields.phones"
          :list="contact.phones"
        />
      </div>
      <div style="margin: 12px 0">
        <el-button
          type="primary"
          style="margin-bottom: 12px"
          @click="addGroupField(contact, 'addresses')"
          plain
          >Add Address</el-button
        >
        <WhatsAppContactCards
          v-if="contact.addresses.length > 0"
          title="Address"
          :fields="formFields.addresses"
          :list="contact.addresses"
        />
      </div>
      <div style="margin: 12px 0">
        <el-button
          type="primary"
          style="margin-bottom: 12px"
          @click="addGroupField(contact, 'emails')"
          plain
          >Add Email</el-button
        >
        <WhatsAppContactCards
          v-if="contact.emails.length > 0"
          title="Email"
          :fields="formFields.emails"
          :list="contact.emails"
        />
      </div>
      <div style="margin: 12px 0">
        <el-button
          type="primary"
          style="margin-bottom: 12px"
          @click="addGroupField(contact, 'urls')"
          plain
          >Add URL</el-button
        >
        <WhatsAppContactCards
          v-if="contact.urls.length > 0"
          title="URL"
          :fields="formFields.urls"
          :list="contact.urls"
        />
      </div>
    </div>
    <el-button @click="showOptionalGroup = !showOptionalGroup" style="padding: 3px 0" type="text"
      >{{ showOptionalGroup ? "Hide" : "Show" }} optional group fields</el-button
    >
    <button
      v-if="index > 0"
      class="section-delete-button"
      @click.prevent="$emit('deleteContact', index)"
    >
      <i class="el-icon-close"></i>
    </button>
  </div>
</template>

<script>
import WhatsAppContactInfoCard from "@/components/WhatsAppContactInfoCard";
import WhatsAppContactCards from "@/components/WhatsAppContactCards";

export default {
  name: "WhatsAppContactItem",
  components: {
    WhatsAppContactInfoCard,
    WhatsAppContactCards,
  },
  props: ["contact", "index"],
  data() {
    return {
      showOptionalGroup: false,
      formFields: {
        personal: [
          {
            key: "formatted_name",
            required: true,
            label: "Full name, as it normally appears",
          },
          {
            key: "first_name",
            required: true,
            label: "First name",
          },
          {
            key: "last_name",
            required: false,
            label: "Last name",
          },
          {
            key: "middle_name",
            required: false,
            label: "Middle name",
          },
          {
            key: "suffix",
            required: false,
            label: "Name suffix",
          },
          {
            key: "prefix",
            required: false,
            label: "Name prefix",
          },
        ],
        org: [
          {
            key: "company",
            required: false,
            label: "Name of the contact's company",
          },
          {
            key: "department",
            required: false,
            label: "Name of the contact's department",
          },
          {
            key: "title",
            required: false,
            label: "Contact's business title",
          },
        ],
        phones: [
          {
            key: "phone",
            required: false,
            label: "Phone number",
          },
          {
            key: "type",
            required: false,
            label: "Phone type",
            type: "select",
            options: ["CELL", "MAIN", "IPHONE", "HOME", "WORK"],
          },
          {
            key: "wa_id",
            required: false,
            label: "WhatsApp ID",
          },
        ],
        addresses: [
          {
            key: "type",
            required: false,
            label: "Address type",
            type: "select",
            options: ["HOME", "WORK"],
          },
          {
            key: "street",
            required: false,
            label: "Street number and name",
          },
          {
            key: "city",
            required: false,
            label: "City name",
          },
          {
            key: "state",
            required: false,
            label: "State abbreviation",
          },
          {
            key: "zip",
            required: false,
            label: "ZIP code",
          },
          {
            key: "country",
            required: false,
            label: "Full country name",
          },
          {
            key: "country_code",
            required: false,
            label: "Two-letter country abbreviation",
          },
        ],
        emails: [
          {
            key: "type",
            required: false,
            label: "Email type",
            type: "select",
            options: ["HOME", "WORK"],
          },
          {
            key: "email",
            required: false,
            label: "Email address",
          },
        ],
        urls: [
          {
            key: "type",
            required: false,
            label: "URL type",
            type: "select",
            options: ["HOME", "WORK"],
          },
          {
            key: "url",
            required: false,
            label: "Enter URL",
          },
        ],
      },
    };
  },
  methods: {
    addGroupField(contact, fieldName) {
      contact[fieldName].push({});
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/colors.scss";

.interactive-message-section {
  border: 1px solid $color-light;
  padding: 0px 25px;
  padding-top: 25px;
  padding-bottom: 15px;
  margin: 10px 0;
  border-radius: 5px;
  position: relative;
}

.section-delete-button {
  position: absolute;
  right: 6px;
  top: 6px;
  border: 0;
  padding: 0;
  background: #fff;
  font-size: 16px;
  color: #333;
}
.section-delete-button:hover {
  color: $color-danger;
}

.section-list-enter-active,
.section-list-leave-active {
  transition: all 0.5s ease;
}
.section-list-enter-from,
.section-list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.section-delete-btn-cancel {
  background: $color-primary;
  color: #fff;
}
.section-delete-btn-cancel:hover {
  background: $color-primary;
  color: #fff;
}

.section-delete-btn-confirm {
  background: #fff !important;
  color: $color-dark !important;
  border: 1px solid $color-light !important;
}

.section-delete-btn-confirm:hover {
  color: $color-primary !important;
  border-color: lighten($color-primary, 45) !important;
  background-color: lighten($color-primary, 45) !important;
}
</style>
