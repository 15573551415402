<template>
  <div>
    <div style="margin: 10px 0">
      <el-button type="primary" @click="addLanguage">Add language</el-button>
    </div>
    <el-form :model="ruleForm" ref="stickyForm">
      <div v-for="(item, index) in ruleForm.input" :key="index" style="display: flex">
        <el-form-item
          :prop="'input.' + index + '.lang'"
          :rules="[
            { required: true, message: 'Please input language code', trigger: 'blur' },
            { validator: checkLanguageExist, triger: 'blur' },
          ]"
        >
          <el-input
            @blur="onChange(index)"
            v-model="ruleForm.input[index].lang"
            ref="input"
            autocomplete="off"
            style="max-width: 350px; margin-right: 20px; margin-top: 1em"
            maxlength="11"
            show-word-limit
          >
            <div v-if="mode !== 'form'" slot="prepend" style="min-width: 140px">
              {{ `Language Code` }}
              <Tooltip :label="languageLabel.langCode || name" :content="languageDetail.langCode" />
            </div>
          </el-input>
        </el-form-item>
        <el-form-item
          :prop="'input.' + index + '.value'"
          :rules="[{ required: true, message: 'Please input button text', trigger: 'blur' }]"
        >
          <el-input
            @blur="onChange(index)"
            v-model="ruleForm.input[index].value"
            ref="input"
            autocomplete="off"
            style="max-width: 500px; margin-top: 1em"
            maxlength="11"
            show-word-limit
          >
            <div v-if="mode !== 'form'" slot="prepend" style="min-width: 140px">
              {{ `Button Text` }}
              <Tooltip :label="languageLabel.text || name" :content="languageDetail.text" />
            </div>
            <el-button
              @click="onDelete(index)"
              size="mini"
              class="has-text-danger"
              slot="append"
              icon="el-icon-delete"
            ></el-button>
          </el-input>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import _ from "lodash";
import Tooltip from "../../Tooltip.vue";
export default {
  components: { Tooltip },
  props: ["value", "setting", "name", "mode", "modules"],
  data() {
    return {
      ruleForm: {
        input: [],
      },
      languageLabel: {
        langCode: "Language Code",
        text: "Button Text",
      },
      languageDetail: {
        langCode:
          "Copy the exact language code that you have used in the content node for the same language. ",
        text: "Add the text you want to show for the sticky menu button in this language.",
      },
    };
  },
  methods: {
    onChange(index) {
      if (!_.isEqual(this.ruleForm.input[index].value, this.value[this.name][index].value)) {
        this.$set(this.value[this.name][index], "value", this.ruleForm.input[index].value);
      }
    },
    addLanguage() {
      this.value[this.name].push({
        lang: "",
        value: "",
      });
    },
    onDelete(index) {
      this.$confirm("Are you sure to delete this language?")
        .then((_) => {
          this.value[this.name].splice(index, 1);
          this.$nextTick(() => {
            this.$forceUpdate();
          });
        })
        .catch((_) => {});
    },
    checkLanguageExist(rule, value, callback) {
      if (this.hasDuplicates(this.listLanguage)) {
        callback(new Error("This language already exists"));
      } else {
        callback();
      }
    },
    hasDuplicates(a) {
      return _.uniq(a).length !== a.length && a.length > 1;
    },
  },
  computed: {
    listLanguage() {
      const result = this.ruleForm.input.map((item) => item.lang);
      return result;
    },
  },
  created() {
    if (!this.value[this.name]) {
      this.value[this.name] = _.cloneDeep(this.setting.default);
    }
    this.ruleForm.input = this.value[this.name];
  },
  watch: {
    "value.stickyMenuLanguages": {
      handler(value) {
        this.ruleForm.input = value;
      },
    },
  },
};
</script>
