<template>
  <div>
    <el-divider content-position="left">Column</el-divider>
    <el-input-number
      style="margin-left: 8px"
      v-model="nodeContent.options.column"
      size="small"
      :min="1"
    ></el-input-number>
    <el-divider content-position="left">Images</el-divider>
    <div class="card-container">
      <el-popover
        placement="top"
        trigger="hover"
        class="image-item"
        v-for="(image, index) in nodeContent.images"
        :key="index"
        :style="flexItemWidthStyle"
      >
        <el-card
          :body-style="{ padding: 0, whitespace: 'normal', 'text-align': 'left' }"
          :style="{
            overflow: 'visible',
            'border-color': editable && !valid(index) ? 'red' : 'lightgrey',
          }"
          slot="reference"
        >
          <div
            name="image"
            style="
              height: 160px;
              overflow: hidden;
              background-size: cover;
              background-position: 50% 50%;
            "
            @click="edit(index)"
            :style="imageDiv(index)"
          >
            <el-input
              v-if="editable"
              v-show="index === edit_image"
              ref="image_input"
              v-model.trim="nodeContent.images[index]"
              name="image"
              placeholder="image"
              required
              type="url"
              style="zIndex=2"
              :class="`image-${index}`"
            >
              <el-button slot="prepend" icon="el-icon-picture" />
              <el-button slot="append" icon="el-icon-close" @click.stop="closeEdit" />
            </el-input>
          </div>
        </el-card>
        <el-button-group class="action-buttons">
          <el-button :disabled="index === 0" icon="el-icon-arrow-left" @click="moveLeft(index)" />
          <el-button
            style="width: 100px"
            type="danger"
            icon="el-icon-delete"
            @click="removeImage(index)"
          />
          <el-button
            :disabled="index >= nodeContent.images.length - 1"
            icon="el-icon-arrow-right"
            @click="moveRight(index)"
          />
        </el-button-group>
      </el-popover>
      <div class="push-image-container" :style="flexItemWidthStyle">
        <div class="push-image" @click="pushImage">
          <i class="el-icon-circle-plus-outline"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getDefaultPlaceholderImage } from "@/helperMethods/util";

export default {
  name: "Grid",
  props: {
    editable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showButtons: {
      type: Boolean,
      default() {
        return true;
      },
    },
    date: {
      type: String,
    },
    nodeOptions: {
      default() {
        return {
          mode: "grid",
        };
      },
    },
    nodeContent: {
      default() {
        return {
          images: [],
          options: {
            column: 2,
          },
        };
      },
    },
  },
  data() {
    return {
      edit_image: -1,
    };
  },
  computed: {
    flexItemWidthStyle() {
      const width = 100 / this.nodeContent.options.column + "%";
      return {
        flexBasis: width,
      };
    },
  },
  methods: {
    valid_image(index) {
      let url = /^https?:\/\/.+/i; // Do not check file type
      return this.nodeContent.images[index] === "" || url.test(this.nodeContent.images[index]);
    },
    validationStyle(field) {
      return this["valid_" + field] ? null : { color: "red" };
    },
    valid(index) {
      this.$emit("imageSafeToSave", this.valid_image(index));
      return this.valid_image(index);
    },
    imageDiv(index) {
      return {
        backgroundImage: `url(${this.nodeContent.images[index] || getDefaultPlaceholderImage()})`,
      };
    },
    edit(index) {
      if (this.editable) {
        this.edit_image = index;
      }
    },
    closeEdit() {
      this.edit_image = -1;
    },
    pushImage() {
      this.nodeContent.images.push(getDefaultPlaceholderImage());
    },
    moveLeft(index) {
      let placeholder = this.nodeContent.images[index - 1];
      this.nodeContent.images[index - 1] = this.nodeContent.images[index];
      this.nodeContent.images[index] = placeholder;
      this.$forceUpdate();
    },
    moveRight(index) {
      let placeholder = this.nodeContent.images[index + 1];
      this.nodeContent.images[index + 1] = this.nodeContent.images[index];
      this.nodeContent.images[index] = placeholder;
      this.$forceUpdate();
    },
    removeImage(index) {
      this.nodeContent.images.splice(index, 1);
    },
  },
};
</script>
<style scoped>
.card-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.image-item {
  position: relative;
  box-sizing: border-box;
  padding: 8px;
  min-height: 160px;
}
.push-image-container {
  box-sizing: border-box;
  padding: 8px;
  height: 100%;
}

.push-image {
  border: 1px dashed #333;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  min-height: 160px;
}

.push-image:hover {
  border: 1px dashed orangered;
  color: orangered;
}
</style>
