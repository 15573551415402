<template>
  <el-input v-if="dataType === 'string'" v-model="value.data" placeholder="value">
    <template slot="prepend">Data</template>
  </el-input>
</template>

<script>
export default {
  name: "EventNodeTypeTab",
  props: ["value"],
  computed: {
    dataType: {
      get() {
        return typeof this.value.data;
      },
      set(type) {
        if (type !== this.dataType) {
          switch (type) {
            case "string":
              this.value.data = "";
              break;
            case "number":
              this.value.data = 1;
              break;
            case "boolean":
              this.value.data = true;
              break;
            case "object":
              this.value.data = {};
              break;
          }
        }
      },
    },
  },
};
</script>
