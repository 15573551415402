<template>
  <el-card style="box-shadow: none; margin-right: 15px; width: 40%">
    <div slot="header" class="widget-header">
      <div style="display: flex; align-items: center; justify-content: space-between; width: 100%">
        <div>
          <h2>Persistent Menu</h2>
          <br />
          <small style="font-style: italic" class="has-text-lightgrey"
            >Press save changes to save persistent menu...</small
          >
        </div>
        <div>
          <el-button
            :disabled="!valid"
            :loading="saving"
            @click="saveChanges"
            type="primary"
            icon="el-icon-check"
            size="mini"
          >
            Save changes
          </el-button>
        </div>
      </div>
    </div>

    <div>
      <Bubble v-model="menu" editable hideText @addButton="addButton" />
    </div>

    <JSONEditor
      v-if="$store.state.showAdvanced"
      v-model="menu.buttons"
      @valid="valid = true"
      @invalid="valid = false"
    />
  </el-card>
</template>
<script>
import Bubble from "@/components/Bubble";
import JSONEditor from "@/components/JSONEditor";

export default {
  name: "MenuEditor",
  components: {
    Bubble,
    JSONEditor,
  },
  props: {
    editorOption: Object,
  },
  data() {
    return {
      saving: false,
      valid: true,
    };
  },
  computed: {
    menu() {
      return {
        buttons: this.$store.state.persistentMenu || [],
      };
    },
  },
  methods: {
    saveChanges() {
      if (this.valid) {
        this.saving = true;
        this.$store.state.persistentMenu = this.menu.buttons;
        this.$store.dispatch("SAVE_MENU").then(
          () => {
            this.saving = false;
            this.$message({
              type: "success",
              message: "Menu Saved",
            });

            setTimeout(() => {
              this.$rest("get", "facebook_config");
            }, 1000);
          },
          (e) => {
            this.saving = false;
            this.$message({
              type: "error",
              message: "Error when saving",
            });
          }
        );
      }
    },
    addButton() {
      const newButton = {
        text: "",
        type: "event",
        event: null,
        data: null,
        url: null,
        phone: null,
        webview_height_ratio: null,
      };

      this.menu.buttons.push(newButton);
    },
  },
};
</script>
