<template>
  <el-row
    type="flex"
    justify="flex-start"
    style="margin-bottom: 0; flex-wrap: wrap; align-items: flex-start"
  >
    <el-input
      v-model="searchTerm"
      placeholder="Input search term..."
      style="margin-right: 10px; margin-bottom: 10px; max-width: 385px"
      size="mini"
    >
      <template slot="append">
        <el-popover placement="bottom" title="Filter" trigger="click" width="300">
          <div>
            <el-select
              v-model="searchChatSources"
              placeholder="Filter by chat source"
              multiple
              filterable
              style="width: 100%; margin-bottom: 10px"
              size="mini"
            >
              <el-option
                v-for="source in chatSources"
                :key="source.value"
                :label="source.displayName"
                :value="source.value"
              ></el-option>
            </el-select>

            <el-select
              filterable
              v-model="searchTags"
              multiple
              size="mini"
              placeholder="Filter by tag name"
              style="width: 100%; margin-bottom: 10px"
            >
              <el-option v-for="(tag, index) in chatTags" :key="index" :label="tag" :value="tag" />
            </el-select>

            <el-select
              filterable
              clearable
              v-model="searchContextHistory"
              size="mini"
              placeholder="Filter by content node"
              style="width: 100%"
            >
              <el-option
                v-for="(contentNode, index) in contentNodeNames"
                :key="index"
                :label="contentNode"
                :value="contentNode"
              />
            </el-select>
          </div>
          <el-button
            :disabled="isLoading"
            slot="reference"
            size="mini"
            icon="el-icon-circle-plus-outline"
          >
            Filter
          </el-button>
        </el-popover>
      </template>
    </el-input>

    <el-checkbox v-model="shouldShowOnlyMyChats" size="mini" border style="margin-right: 10px">
      My chats
    </el-checkbox>

    <PredefinedFilter
      ref="predefinedFilter"
      type="resolvedChat"
      style="margin-right: 10px"
      @selectedFilter="onPredefinedFilterSelected"
      @resetFilter="onResetHandler"
    />

    <el-date-picker
      v-if="!isDateDisabled"
      v-model="searchChatDateRange"
      type="datetimerange"
      format="dd/MM/yyyy HH:mm"
      style="margin-right: 10px; width: 300px; margin-bottom: 10px"
      align="right"
      unlink-panels
      range-separator=" ~ "
      start-placeholder="Start date"
      size="mini"
      end-placeholder="End date"
      :clearable="false"
    ></el-date-picker>

    <el-button
      plain
      type="primary"
      size="mini"
      icon="el-icon-search"
      :disabled="isLoading"
      @click="updateFilterAndSearch"
      >Search</el-button
    >

    <el-button
      style="margin-right: 10px"
      :disabled="isLoading"
      size="mini"
      icon="el-icon-close"
      @click="resetFilter"
      >Clear</el-button
    >

    <el-dropdown size="mini" :disabled="isLoading || sendEmailLoading">
      <el-button type="info" plain size="mini">
        More
        <i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-if="selectedChatId && !dataCollectionFormEnabled"
          @click.native="emailChat"
        >
          <i class="el-icon-s-promotion"></i>&nbsp;Email Chat Transcript
        </el-dropdown-item>
        <el-dropdown-item @click.native="exportDialogVisible = true">
          <i class="el-icon-download"></i>&nbsp;Download All Chats
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <el-dialog
      id="livechat_downloadAllChats"
      title="Download All Chats"
      :visible.sync="exportDialogVisible"
      width="50%"
    >
      <div width="100%">
        <span>Please select how you would like to export the chats?</span>
      </div>
      <br />
      <div v-if="exportChoice === 'zip'">
        <img src="/template/zipsample1.gif" width="100%" />
        <span>
          This export type will seperate each conversation into one .csv file, combining all of them
          into one compressed .zip file
        </span>
      </div>
      <div v-if="exportChoice === 'single'">
        <img src="/template/singlesample1.gif" width="100%" />
        <span>
          This export type will separate each conversation into different sheets, combining all of
          them into one .xlsx file
        </span>
      </div>
      <div width="100%">
        <el-radio v-model="exportChoice" label="zip"> A .zip file (multiple .csv) </el-radio>
        <el-radio v-model="exportChoice" label="single"> An .xlsx file (multiple sheets) </el-radio>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="exportAllChats(exportChoice)">Confirm</el-button>
        <el-button @click="exportDialogVisible = false">Cancel</el-button>
      </span>
    </el-dialog>
  </el-row>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import { getMessageByCode } from "@/helperMethods/util";
import { mapGetters } from "vuex";
import {
  getDefaultDateRange,
  isDateRangeValid,
  showDateRangeError,
} from "@/components/LiveChat/helpers/filters";
import { livechatExportMixin } from "@/mixins/liveChat";
import PredefinedFilter from "@/components/LiveChat/PredefinedFilter";

export default {
  name: "SearchChatFilters",
  props: {
    tab: String,
  },
  components: { PredefinedFilter },
  mixins: [livechatExportMixin],
  data() {
    return {
      isSearching: false,
      searchTerm: "",
      searchChatSources: [],
      searchTags: [],
      searchContextHistory: "",
      searchChatLimit: 20,
      searchChatDate: new Date(),
      searchChatDateRange: [...this.getDefaultDateRange()],
      exportDialogVisible: false,
      exportChoice: "zip",
      shouldShowOnlyMyChats: false,
    };
  },
  computed: {
    ...mapGetters([
      "dataCollectionFormEnabled",
      "fetchQueueChatLoading",
      "fetchResolvedChatLoading",
      "queueChatTags",
      "resolvedChatTags",
      "resolvedChatsArray",
      "selectedChat",
      "selectedChatId",
      "sendEmailLoading",
      "isQueueTab",
      "contentNodeNames",
    ]),
    isLoading() {
      const isQueue = this.isQueueTab;
      if (isQueue) {
        return this.fetchQueueChatLoading;
      }

      return this.fetchResolvedChatLoading;
    },
    chatTags() {
      const isQueue = this.isQueueTab;
      if (isQueue) {
        return this.queueChatTags;
      }

      return this.resolvedChatTags;
    },
    isDateDisabled() {
      const isQueue = this.isQueueTab;
      return isQueue;
    },
  },

  watch: {
    searchChatDateRange(newValue, prevValue) {
      const noChanges =
        moment(newValue?.[0]).isSame(moment(prevValue?.[0])) &&
        moment(newValue?.[1]).isSame(moment(prevValue?.[1]));
      if (noChanges) {
        return;
      }

      // for range validation
      const [startDate, endDate] = newValue || [];
      const dateRangeValid = this.isDateRangeValid({ startDate, endDate }, 14);
      if (!dateRangeValid) {
        // revert date range
        this.searchChatDateRange = prevValue;

        this.$notify.warning({
          title: "Info",
          position: "bottom-right",
          message: `Date range should not be greater than 14 days`,
        });
      }
    },
  },
  methods: {
    getDefaultDateRange,
    isDateRangeValid,
    emailChat() {
      this.$set(this.$store.state.livechat, "sendEmailLoading", true);

      const chat = this.selectedChat;
      const livechatData = this.selectedChat;
      const agent_email = this.$store.state.profile.email;

      const noSelectedChatData = !livechatData;
      if (noSelectedChatData) {
        this.$set(this.$store.state.livechat, "sendEmailLoading", false);
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: "Encountered error sending email of selected chat",
        });
      }

      this.$store
        .dispatch("EMAIL_CHAT_TRANSCRIPT", {
          userId: chat.user_id,
          partitionKey: livechatData.PartitionKey,
          agentEmail: agent_email,
        })
        .then((isForwarded) => {
          if (isForwarded) {
            this.$notify.success({
              title: "Success",
              message: "Chat transcript has been emailed successfully.",
              position: "bottom-right",
            });
          } else {
            this.$notify.warning({
              title: "Warning",
              message: "No email settings found. Please configure email settings.",
              position: "bottom-right",
            });
          }
          this.$set(this.$store.state.livechat, "sendEmailLoading", false);
        })
        .catch((error) => {
          const errorCode = _.get(error, "graphQLErrors[0].extensions.code");

          const message = getMessageByCode(errorCode, "Error sending email");
          this.$set(this.$store.state.livechat, "sendEmailLoading", false);
          this.$notify.error({
            title: "Error",
            message,
            position: "bottom-right",
          });
        });
    },

    updateFilterAndSearch() {
      this.$emit("resetResolvedChatFilters");
      const resolvedChatFilter = this.getSearchFilters();

      if (!this.isDateRangeValid(resolvedChatFilter, 14)) {
        showDateRangeError();
        return;
      }

      resolvedChatFilter.offset = 0;
      const updatedResolvedChatFilter = Object.assign({}, resolvedChatFilter, {
        date: moment().toDate(),
      });
      this.$store.commit("SET_SELECTED_CHAT_ID", null);
      this.$store.commit("SET_FETCH_RESOLVED_CHAT_LOADING", true);
      this.$store.commit("UPDATE_RESOLVED_LIVECHAT_FILTERS", updatedResolvedChatFilter);
    },
    resetFilter() {
      this.resetFilters(); // local component variables
      this.$refs.predefinedFilter.reset();
      this.$store.commit("RESET_ALL_FILTERS");
    },
    getSearchFilters() {
      let offset = this.resolvedChatsArray.length - 1;
      if (offset < 0) {
        offset = 0;
      }
      const commonFilters = this.searchFiltersCommon;
      return {
        ...commonFilters,
        limit: parseInt(this.searchChatLimit),
        offset,
        dates: [this.searchChatDateRange?.[0], this.searchChatDateRange?.[1]],
        date: this.searchChatDate,
        involvedMe: this.shouldShowOnlyMyChats,
      };
    },
    resetFilters() {
      const isNotQueue = !this.isQueueTab;
      if (isNotQueue) {
        this.searchChatDateRange = [...this.getDefaultDateRange()];
      }
      this.searchChatDate = new Date();
      this.searchChatLimit = 20;
      this.searchTerm = "";
      this.searchChatSources = [];
      this.searchTags = [];
      this.searchContextHistory = "";
      this.shouldShowOnlyMyChats = false;
    },
    exportAllChatsFetch(limit, offset) {
      const commonFilters = this.searchFiltersCommon;
      return this.$store.dispatch("FETCH_ALL_RESOLVED_CHATS", {
        ...commonFilters,
        limit,
        offset,
        messageLimit: 200,
        agentIsNot: "[]",
        includeInteractions: true,
      });
    },
    onResetHandler(e) {
      this.$store.commit("SET_FETCH_RESOLVED_CHAT_LOADING", true); // apollo.then will set to false when fetch is complete
      this.$store.commit("FLUSH_RESOLVED_CHATS_ARRAY"); // remove array of chats from state
      this.resetFilters();
      const resolvedChatsFilter = this.getSearchFilters();
      this.$store.commit("UPDATE_RESOLVED_LIVECHAT_FILTERS", resolvedChatsFilter);
    },
    onPredefinedFilterSelected(filter) {
      if (filter.startDate && filter.endDate) {
        this.searchChatDateRange = [filter.startDate, filter.endDate];
      }
      this.searchTerm = filter?.searchTerm || "";
      this.searchTags = _.compact(filter?.tags?.split(",") || []);
      this.searchContextHistory = filter?.contextHistory;
      this.searchChatSources = _.compact(filter?.chatSources?.split(",") || []);
      this.updateFilterAndSearch();
    },
  },
};
</script>

<style scoped>
.filterGroup .el-select {
  width: 100%;
}
.downloadChats {
  text-align: right;
  padding: 5px 5px;
}
</style>
