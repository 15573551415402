<template>
  <div style="padding: 0px">
    <el-row>
      <el-col>
        <div class="add-tag-section">
          <span slot="label" class="has-text-grey" style="font-size: 14px; padding-right: 10px">
            Tags:
          </span>
          <!-- Tags  -->
          <el-tooltip
            v-for="tag in selectedIntent.tags"
            :key="tag"
            class="item"
            effect="dark"
            :content="tag"
            :disabled="tag.length < 22"
            placement="top"
          >
            <el-tag
              :disabled="!isAuthorisedForChange(selectedIntent.department)"
              type="primary"
              style="background-color: #409eff; color: #fff; margin-right: 10px !important"
              size="small"
              :closable="true"
              :disable-transitions="false"
              @close="deleteTag(selectedIntent, tag)"
            >
              {{ tag | trim(22) }}
            </el-tag>
          </el-tooltip>

          <el-input
            v-if="selectedIntent.tagInputVisible"
            ref="saveTagInput"
            v-model="selectedIntent.tagInputValue"
            type="text"
            class="input-new-tag"
            size="mini"
            v-focus
            @keyup.enter.native="newTagConfrim(selectedIntent)"
            @blur="newTagConfrim(selectedIntent)"
          />
          <el-button
            v-else
            :disabled="!isAuthorisedForChange(selectedIntent.department)"
            class="button-new-tag"
            size="mini"
            @click="showTagInput(selectedIntent)"
          >
            + New Tag
          </el-button>
        </div>
      </el-col>
    </el-row>

    <FaqAnswerDepartment :intent="selectedIntent" />

    <el-row>
      <el-row>
        <el-col :span="24">
          <EventNodeSelector
            :value="selectedIntentAnswer"
            @save-side-effect="$emit('save', { rowId: selectedIntent.id, action: 'MODIFY' })"
          />
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-tooltip
            v-if="!isAuthorisedForChange(selectedIntent.department)"
            content="Not authorised!"
            placement="right"
          >
            <div>
              <el-button
                :disabled="!isAuthorisedForChange(selectedIntent.department)"
                type="primary"
                size="mini"
                icon="el-icon-check"
              >
                Save
              </el-button>
            </div>
          </el-tooltip>
          <el-button
            v-else
            type="primary"
            icon="el-icon-check"
            size="mini"
            @click="$emit('save', { rowId: selectedIntent.id, action: 'MODIFY' })"
          >
            Save
          </el-button>
        </el-col>
      </el-row>
    </el-row>
    <el-row v-if="this.$store.state.showAdvanced">
      <el-col>
        <JSONEditor v-model="clonedComputedSingleRowData" />
      </el-col>
    </el-row>

    <!-- Node Preview Section -->
    <el-row>
      <el-row>
        <el-col style="text-align: center">
          <el-card
            class="nodePreviewSection"
            width="100%"
            shadow="never"
            body-style="border: 1px 0px"
          >
            <span class="has-text-dark">Node Preview</span>
          </el-card>
        </el-col>
      </el-row>
      <el-col class="nodePreviewSection">
        <div v-if="selectedNodeData">
          <Bubble
            v-model="selectedNodeData"
            @save-side-effect="$emit('save', { rowId: selectedIntent.id, action: 'MODIFY' })"
          />
          <div class="edit-node-btn-section">
            <el-button class="edit-node-btn" @click="selectNode(selectedIntent.action)">
              Edit node In Editor
            </el-button>
          </div>
        </div>
        <div v-else class="no-preview-section">
          <span>
            This intent has no action.
            <br />Please select an action above.
          </span>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import Bubble from "@/components/Bubble.vue";
import JSONEditor from "@/components/JSONEditor";
import EventNodeSelector from "@/components/EventNodeSelector/Index";
import FaqAnswerDepartment from "./FaqAnswerDepartment.vue";

export default Vue.extend({
  props: ["selectedIntent"],
  components: {
    FaqAnswerDepartment,
    Bubble,
    EventNodeSelector,
    JSONEditor,
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  computed: {
    dataset() {
      return _.get(this.$store.state, "training.dataset", []);
    },
    selectedIntentIsEmpty() {
      return _.isEmpty(this.selectedIntent);
    },
    clonedComputedSingleRowData() {
      return _.cloneDeep(this.selectedIntent);
    },

    activeNodeId() {
      return this.$store.state.activeNodeId;
    },
    selectedNodeData() {
      const selectedIntent = _.find(
        this.dataset,
        (data) => data.id === this.$store.state.pages.faq.selectedIntentId
      );
      let selectedContentNode;
      const actionEvent = _.get(selectedIntent, "action.event");
      switch (actionEvent) {
        case "goto":
          {
            const contentNodeID = _.get(selectedIntent, "action.data");
            selectedContentNode = this.$store.state.nodes.content[contentNodeID];
          }
          break;
        case "capture":
          {
            const { next } = _.get(selectedIntent, "action.data", {});
            if (next && next.constructor === Object) {
              const { event, data } = next;
              if (event === "goto" && data) {
                selectedContentNode = this.$store.state.nodes.content[data];
              }
            } else if (next) {
              selectedContentNode = this.$store.state.nodes.content[next];
            }
          }
          break;
        // The other events do not need to show the content node.
      }
      if (selectedContentNode) {
        // Indicate that valid answer has been provided
        this.$emit("toggleRequiredAnswerFlag", true);
        return selectedContentNode.content;
      } else {
        return "";
      }
    },
    selectedIntentAnswer: {
      get() {
        const event = _.get(this, "selectedIntent.action.event");
        if (!event) {
          this.selectedIntent.action = { event: "", data: {} };
        }
        const action = this.selectedIntent.action;
        return action;
      },
      set(value) {
        this.$emit("isDirty");
        this.handleAnswerChange(value);
      },
    },
  },
  methods: {
    isAuthorisedForChange(intentDepartment) {
      const userDepartment = this.$store.getters.userDepartment;

      const userIsAuthorised = _.intersection(userDepartment, intentDepartment).length > 0;
      const intentIsGeneral = intentDepartment.includes("general");

      const isAuthorised = userIsAuthorised || intentIsGeneral;
      return isAuthorised;
    },
    handleAnswerChange(action) {
      const pair = _.find(
        this.dataset,
        (data) => data.id === this.$store.state.pages.faq.selectedIntentId
      );

      let selectedIntentIndex = _.findIndex(this.dataset, (row) => {
        return row.id === this.$store.state.pages.faq.selectedIntentId;
      });

      pair.action = action;
      if (typeof action === "string") {
        // FAQ V1.0
        pair.answer = action;
      } else if (typeof action === "object") {
        switch (action.event) {
          case "goto":
            pair.answer = action.data;
            break;
          case "capture":
            {
              let selectedContentNode;
              const { next } = _.get(action, "data", {});
              if (next && next.constructor === Object) {
                const { event, data } = next;
                if (event === "goto" && data) {
                  selectedContentNode = this.$store.state.nodes.content[data];
                }
              } else if (next) {
                selectedContentNode = this.$store.state.nodes.content[next];
              }
              pair.answer = selectedContentNode;
            }
            break;
        }
        // Update activeNodeId
        this.$set(this.$store.state, "activeNodeId", pair.answer);
      }

      // Updating the dataset from apollo
      this.dataset[selectedIntentIndex] = pair;
    },
    toggleDialogVisible(isVisible = true, selectedIntent = {}) {
      this.selectedIntent = selectedIntent;
      this.dialogVisible = isVisible;
    },
    handleSelect(item) {
      this.$emit("isDirty");
      this.$emit("handleBlur", this.selectedIntent);
    },

    getContentNodes(value, cb) {
      cb(this.$store.state.search.content.search(value || ""));
    },

    selectNode(action) {
      let id = action.data;
      this.$store.dispatch("SELECT_NODE", { type: "content", id });
      this.$router.push("/editor");
    },

    deleteQuestion(row) {
      this.$store.commit("DELETE_QUESTION", row.id);
      // clearIntentView
      this.$set(this.$store.state.pages.faq, "selectedIntent", null);

      // Reset the delete dialog variables
      this.selectedIntent = {};
      this.dialogVisible = false;
    },

    newTagConfrim(row) {
      if (row.tagInputValue) {
        if (row.tags && row.tags.includes(row.tagInputValue)) {
          this.$message({
            type: "error",
            message: "Please type unique tag only.",
          });
        } else {
          row.tags.push(row.tagInputValue);
        }
      }

      row.tagInputValue = "";
      row.tagInputVisible = false;
      this.$forceUpdate();
    },
    deleteTag(row, tag) {
      row.tags.splice(row.tags.indexOf(tag), 1);
      this.$forceUpdate();
    },
    showTagInput(row) {
      row.tagInputVisible = true;
      this.$forceUpdate();
    },
  },
});
</script>

<style scoped lang="scss">
@import "../../../../../assets/scss/colors.scss";

.el-card.nodePreviewSection .el-card__body {
  padding: 5px !important;
}

.el-card.nodePreviewSection {
  border-radius: 0px;
  background-color: $color-light;
  margin: 0px -20px 20px -20px;
  font-size: 12px;
  font-weight: bold;
}

.edit-node-btn-section {
  text-align: center;
  margin-top: 1em;
  /* max-width: 310px; */
}

.button-new-tag {
  margin-left: 10px;
  /* height: 32px; */
  /* line-height: 30px; */
  padding: 5px 10px;
}

.add-tag-section {
  margin: 0px 5px 10px 5px;
}

.input-new-tag {
  width: 100px;
  height: 24px;
  margin-left: 10px;
  vertical-align: top;
}

.delete-question-section {
  text-align: center;
  margin-top: 1em;
  /* max-width: 310px; */
}

.delete-node-btn {
  color: $color-dark;
  width: 310px;
  background-color: #fff;
  border-color: $color-dark;
}

.no-preview-section {
  text-align: center;
  color: $color-dark;
  font-size: 18px;
  vertical-align: middle;
  line-height: 50px;
  /* margin-left: 60px; */
}
.edit-node-btn {
  background-color: $color-primary;
  color: white;
  border-radius: 5px;
  width: 310px;
  /* width: 50%; */
  /* margin-left: 5px;  */
}
</style>
