import { FaqState } from "./types";

const state: FaqState = {
  activeTab: "trainingData",
  intentSearch: "",
  backendSearch: "",
  backendSearchLoading: false,
  previewAutoClassify: [],
  arrayOfSelectedMessages: [],
  selectedConversationalDataIntent: "",
  selectedTopics: [],
  topics: [],
  trainingInProgress: false,
  publishingInProgress: false,
  currentViewingTopic: "",
  selectTrainTopicsDialogVisible: false,
};

export default state;
