export default {
  data() {
    return {
      // ISO 639-1 codes
      // https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
      languages: [
        { code: "en", name: "English" },
        { code: "zh", name: "Chinese" },
        { code: "ms", name: "Malay" },
        { code: "id", name: "Indonesian" },
        { code: "fil", name: "Filipino" },
        { code: "th", name: "Thai" },
        { code: "tg", name: "Tagalog" },
        { code: "vi", name: "Vietnamese" },
        { code: "ja", name: "Japanese" },
        { code: "ko", name: "Korean" },
        { code: "ar", name: "Arabic" },
        { code: "cs", name: "Czech" },
        { code: "da", name: "Danish" },
        { code: "de", name: "German" },
        { code: "el", name: "Greek" },
        { code: "es", name: "Spanish" },
        { code: "fi", name: "Finnish" },
        { code: "fr", name: "French" },
        { code: "hi", name: "Hindi" },
        { code: "hy", name: "Armenian" },
        { code: "it", name: "Italian" },
        { code: "ka", name: "Georgian" },
        { code: "nl", name: "Dutch" },
        { code: "pl", name: "Polish" },
        { code: "pt", name: "Portuguese" },
        { code: "ro", name: "Romanian" },
        { code: "ru", name: "Russian" },
        { code: "sv", name: "Swedish" },
        { code: "km", name: "Khmer" },
        { code: "tr", name: "Turkish" },
      ],
    };
  },
};
