import { render, staticRenderFns } from "./FaqTrainingDataset.vue?vue&type=template&id=61b4a2ae&scoped=true&"
import script from "./FaqTrainingDataset.vue?vue&type=script&lang=js&"
export * from "./FaqTrainingDataset.vue?vue&type=script&lang=js&"
import style0 from "./FaqTrainingDataset.vue?vue&type=style&index=0&id=61b4a2ae&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61b4a2ae",
  null
  
)

export default component.exports