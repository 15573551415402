import { MutationTree } from "vuex/types";
import Vue from "vue";
import { EditorState } from "./types";
import _ from "lodash";

const mutations: MutationTree<EditorState> = {
  SET_CHANGE_CONTENT_NODE(state, payload) {
    Vue.set(state, "updateContentNode", payload);
  },
  SET_REFETCH_CONTENT_NODE(state, isRefetch) {
    Vue.set(state, "refetchContentNodes", isRefetch);
  },
  SET_UPLOAD_CONTENT_SUCCESS(state, isSuccess) {
    Vue.set(state, "uploadSuccess", isSuccess);
  },
  /**
   * @description Used to create trace of old nodes before these content nodes are updated. These old versions are used for makerchecker
   * @param state
   * @param param1
   */
  APPEND_OLD_NODE_BY_TYPE(state, { type, oldNode }: { type: "content" | "trigger"; oldNode: any }) {
    const invalidType = !["content", "trigger"].includes(type);
    if (invalidType) {
      console.error(`Cannot append oldNode. Type: ${type} not valid`);
      return;
    }
    const oldNodeId = _.get(oldNode, "id");
    if (!oldNodeId) {
      return;
    }

    Vue.set(state.oldNodes[type], `[${oldNodeId}]`, oldNode);
  },

  SET_CONNECTED_NODES_BUSY(state, busy) {
    Vue.set(state.connectedNodes, "busy", busy);
  },

  SET_CONNECTED_NODES_DATA(state, data) {
    Vue.set(state.connectedNodes, "data", data);
  },
};

export default mutations;
