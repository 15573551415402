<template>
  <el-row>
    <el-col :span="14">
      <el-form :model="node.content.options">
        <el-form-item>
          <el-input
            type="textarea"
            placeholder="Add body text"
            maxlength="1024"
            show-word-limit
            v-model="node.content.text"
            rows="5"
          />
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "WhatsAppLocationRequest",
  props: ["node"],
};
</script>

<style></style>
