import { render, staticRenderFns } from "./Folder.vue?vue&type=template&id=0453d5a6&scoped=true&"
import script from "./Folder.vue?vue&type=script&lang=js&"
export * from "./Folder.vue?vue&type=script&lang=js&"
import style0 from "./Folder.vue?vue&type=style&index=0&id=0453d5a6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0453d5a6",
  null
  
)

export default component.exports