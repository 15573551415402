<template>
  <div>
    <el-row>
      <el-col :span="6" style="max-width: 160px">
        <label
          style="vertical-align: middle; margin-left: 0.5em; margin-bottom: 2em; font-size: 14px"
          class="has-text-grey"
        >
          {{ setting.label || name }}
          <Tooltip :label="setting.label || name" :content="setting.detail" />
        </label>
      </el-col>
      <el-col :span="22" style="max-width: 540px; margin-left: 0.5em">
        <YAMLEditor ref="yamlEditor" v-model="value[name]" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import YAMLEditor from "@/components/YAMLEditor";
import Tooltip from "../Tooltip.vue";
export default {
  props: ["value", "setting", "name"],
  components: {
    YAMLEditor,
    Tooltip,
  },
  mounted() {
    if (this.value[this.name] == null) {
      if (this.setting.default != null) {
        this.$set(this.value, this.name, this.setting.default);
      } else {
        this.$set(this.value, this.name, null);
      }
    }
  },
};
</script>
