import _ from "lodash";

const cache = {};

const createParser = (template: string): Function => {
  const sanitized = template
    .replace(/\$\{([\s]*[^;\s\{]+[\s]*)\}/g, (_ignore, match) => `\$\{map.${match.trim()}\}`)
    .replace(/(\$\{(?!map\.)[^}]+\})/g, "");

  return Function("map", `return \`${sanitized}\``);
};

const string2template = (template: string, map: object): string => {
  if (typeof template !== "string") {
    return template;
  }
  const convertedMap: object = _.reduce(
    map,
    (obj, value, key) => {
      if (value !== null && typeof value === "string") {
        try {
          // @ts-ignore
          obj[key] = JSON.parse(value);
          return obj;
          // @ts-ignore
          // eslint-disable-next-line no-empty
        } catch (err) {}
      }
      // @ts-ignore
      obj[key] = value;
      return obj;
    },
    {}
  );
  // @ts-ignore
  const parser = (cache[template] = cache[template] || createParser(template));
  return convertedMap ? parser(convertedMap) : parser;
};

export default string2template;
