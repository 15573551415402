import { render, staticRenderFns } from "./FormResultItem.vue?vue&type=template&id=11450c08&scoped=true&"
import script from "./FormResultItem.vue?vue&type=script&lang=js&"
export * from "./FormResultItem.vue?vue&type=script&lang=js&"
import style0 from "./FormResultItem.vue?vue&type=style&index=0&id=11450c08&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11450c08",
  null
  
)

export default component.exports