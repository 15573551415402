<template>
  <el-tooltip
    v-if="$store.getters.isShowAdvanced || uploadSuccess"
    slot="reference"
    class="item"
    effect="dark"
    content="Save all uploaded content"
    placement="bottom"
    size="small"
    style="margin-right: 10px"
  >
    <el-button
      circle
      type="success"
      :loading="saving"
      :plain="true"
      icon="el-icon-check"
      @click="saveAllContent"
    />
  </el-tooltip>
</template>

<script>
import _ from "lodash";

export default {
  name: "EditorSaveAll",
  props: ["setStatus", "saving"],
  data() {
    return { allNewContents: {} };
  },
  computed: {
    uploadSuccess() {
      return this.$store.state.editor.uploadSuccess;
    },
    nodes() {
      return this.$store.state.nodes;
    },
  },
  methods: {
    async saveAllContent() {
      this.setStatus("saving", true);
      const content = _.pickBy(this.nodes.content, (content) => content.new);
      const trigger = _.pickBy(this.nodes.trigger, (trigger) => trigger.new);
      this.allNewContents = {
        content,
        trigger,
      };
      const oldNodes = _.get(this, "$store.state.editor.oldNodes", {});
      if (Object.keys(this.allNewContents).length === 0) {
        this.$notify.warning({
          title: "Empty content node list",
          position: "bottom-right",
          message: "Nothing to save",
        });
        this.setStatus("saving", false);
        return;
      }

      this.$store
        .dispatch("SAVE_ALL_NEW_NODES", {
          nodesObject: this.allNewContents,
          oldNodesObject: oldNodes,
        })
        .then(() => {
          this.$notify.success({
            type: "success",
            title: "Successful save",
            message: "All uploaded contents saved",
            position: "bottom-right",
          });
          this.allNewContents = {};
          this.$store.commit("SET_REFETCH_CONTENT_NODE", true);
        })
        .catch(({ message }) => {
          this.allNewContents = {};
          this.$notify.error({
            title: "Error Saving",
            message,
            position: "bottom-right",
          });
        })
        .finally(() => {
          this.setStatus("saving", false);
        });
    },
  },
};
</script>
