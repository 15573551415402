import _ from "lodash";
import moment from "moment-timezone";

interface Chat {
  interactions?: Array<any>;
  RowKey?: string;
  user_id: string;
  status: string;
}

export function sortMessagesAscending(chat: Chat) {
  if (!_.isEmpty(chat)) {
    chat.interactions = _.orderBy(chat.interactions, (message: any) => {
      if (message.Timestamp) {
        return moment(message.Timestamp).valueOf();
      } else {
        return moment(message.date_created).valueOf();
      }
    });
    return chat;
  }
  return null;
}
