<template>
  <div style="font-size: 14px">
    <el-tooltip
      v-if="graphQLWebsocketStatus === 'connected'"
      class="item"
      effect="dark"
      placement="bottom-end"
    >
      <div slot="content">
        <i class="el-icon-success has-text-success" style="position: relative; right: 5px"></i>
        <label>You are connected to the server</label>
      </div>
      <div style="display: inline-block">
        <i class="el-icon-success has-text-success" style="position: relative; right: 5px"></i>
        <label class="has-text-success" style="position: relative">Connected</label>
      </div>
    </el-tooltip>
    <el-tooltip
      v-else-if="graphQLWebsocketStatus === 'connecting'"
      class="item"
      effect="dark"
      placement="bottom-end"
    >
      <div slot="content">
        <i class="el-icon-warning has-text-warning" style="position: relative; right: 5px"></i>
        <label>We are attempting to connect you to the server.</label>
      </div>
      <div style="display: inline-block">
        <i class="el-icon-warning has-text-warning" style="position: relative; right: 5px"></i>
        <label class="has-text-warning" style="position: relative">Connecting...</label>
      </div>
    </el-tooltip>
    <el-tooltip v-else class="item" effect="dark" placement="bottom-end">
      <div slot="content">
        <i class="el-icon-error has-text-danger" style="position: relative; right: 5px"></i>
        <label> Unable to connect to the server, please refresh this page and try again. </label>
      </div>
      <div style="display: inline-block">
        <i class="el-icon-error has-text-danger" style="position: relative; right: 5px"></i>
        <label class="has-text-danger" style="position: relative">Disconnected</label>
      </div>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  props: ["graphQLWebsocketStatus"],
};
</script>
