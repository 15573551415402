<template>
  <div>
    <el-alert
      title="Please note that Facebook Messenger no longer supports vertical list template."
      type="warning"
      center
      show-icon
    ></el-alert>
    <br />

    <div
      v-for="(list, index) in node.content"
      v-dragging="{ item: list, list: node.content, group: 'list' }"
      style="flex-direction: row; display: flex; align-items: flex-end"
      :key="index"
    >
      <ListEntry
        style="margin-bottom: 3px; border: 1px solid #e6ebf5; flex-grow: 1"
        :list="list"
        editable
        @save-side-effect="$emit('save-side-effect')"
      />
      <div style="display: flex; flex-direction: column">
        <el-tooltip content="Attach image to list item" placement="top">
          <el-popover placement="top" width="400" trigger="click">
            <el-input
              placeholder="Paste image url here"
              v-model="list.image"
              class="input-with-select"
            >
              <el-button
                slot="append"
                v-copy="{ text: list.image }"
                icon="el-icon-document-copy"
              ></el-button>
            </el-input>
            <el-button
              type="text"
              slot="reference"
              style="margin: 5px; padding: 0"
              icon="el-icon-picture"
            />
          </el-popover>
        </el-tooltip>
        <el-tooltip content="Delete list item" placement="top">
          <el-button
            type="text"
            style="margin: 5px; padding: 0"
            icon="el-icon-delete"
            @click="removeList(index)"
          />
        </el-tooltip>
        <el-tooltip content="Add button to list item" placement="top">
          <el-button
            type="text"
            style="margin: 5px; padding: 0"
            :disabled="Array.isArray(list.buttons) && list.buttons.length >= 1"
            icon="el-icon-plus"
            @click="addListButton(index)"
          />
        </el-tooltip>
      </div>
    </div>
  </div>
</template>
<script>
import ListEntry from "@/components/ListEntry";

export default {
  name: "List",
  components: {
    ListEntry,
  },
  props: {
    editable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    node: {
      default() {
        return {
          content: [{ text: "", subtext: "", image: "", url: "", buttons: [] }],
          buttons: [],
        };
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    addListButton(index) {
      this.node.content[index].buttons.push({
        text: "Enter button text",
        type: "event",
        event: null,
        data: null,
        url: null,
        phone: null,
        webview_height_ratio: null,
      });
      this.$forceUpdate();
    },
    addButton() {
      this.node.buttons.push({
        text: "Enter button text",
        type: "event",
        event: null,
        data: null,
        url: null,
        phone: null,
        webview_height_ratio: null,
      });
    },
    removeList(index) {
      const self = this;
      // storing first card in the card list to a temporary variable to retain the full details
      if (!self.currentNode) {
        if (self.node.content.length > 0) {
          self.currentNode = self.node.content[0];
        }
      }
      this.$confirm("This will delete the list entry. Continue?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "info",
      })
        .then(() => {
          if (self.node.content.length === 1) {
            self.node.content = self.currentNode; // assign the current node
            delete self.node.content.image; // deleting image
            delete self.currentNode; // deleting temporary instance
          } else {
            self.node.content.splice(index, 1);
          }
        })
        .catch((err) => {
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `Error occured while deleting the list entry.`,
          });
        });
    },
  },
};
</script>
