<template>
  <el-input
    v-model="keywords"
    :placeholder="placeholder"
    class="search-bar"
    prefix-icon="el-icon-search"
    clearable
  />
</template>

<script>
export default {
  props: {
    placeholder: String,
  },
  data() {
    return {
      keywords: "",
    };
  },
  watch: {
    keywords(val) {
      this.$parent.$emit("search:keyword:changed", val);
    },
  },
};
</script>
