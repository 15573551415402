import * as Sentry from "@sentry/browser";

export function jsonParse(object: object | string): object | string {
  try {
    let parsedObject = object;
    if (typeof object === "string") {
      parsedObject = JSON.parse(object);
    }

    return parsedObject;
  } catch (err) {
    Sentry.captureException(err);
    return object;
  }
}
