import _ from "lodash";
import store from "@/store/index";
import Vue from "vue";
import { IButton } from "../type";
import { IExample, IFaq } from "../faqType";
import { isLinkFound } from "../task_management/util";
import { ButtonType } from "@/store/types";

export function setSelectedIntent(): void {
  const intents = store.getters.intents;
  if (intents.length > 0) {
    const firstIntentName = _.get(intents, "[0][0]", "");
    Vue.set(store.state.faq, "selectedConversationalDataIntent", firstIntentName);
  }
}

export function setActionData(action: IButton): string {
  let actionData = "";
  if (action) {
    switch (typeof action.data) {
      case "object":
        actionData = JSON.stringify(action.data);
        break;
      case "string":
        actionData = action.data;
        break;
      default:
        actionData = "";
    }
  }
  return actionData;
}

export function faqExportedWorksheetData(dataset: IFaq[]): string[][] {
  if (!Array.isArray(dataset)) {
    throw new Error("Encountered error exporting FAQ");
  }
  const ws_data: any[] = [];

  const headers: string[] = [
    "Intent",
    "Example",
    "Suggest",
    "Language",
    "Event",
    "Data",
    "Enabled",
    "Entities",
    "Topics",
    "Error",
    "For language reference: https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes",
  ];
  ws_data.push(headers);

  dataset.forEach((row) => {
    const actionData = setActionData(row.action);

    const variationsExists = Array.isArray(row.variations) && row.variations.length > 0;
    const actionEvent = _.get(row, "action.event", "");
    const intentEnabled = _.get(row, "enabled", false);
    const entities = _.get(row, "entities", []);
    const topics = _.get(row, "topics", []);
    const stringEntities = entities.join(";");
    const stringTopics = topics.join(";");

    if (!variationsExists) {
      // single row for the intent only
      ws_data.push([
        row.intentName,
        "",
        "",
        row.language,
        actionEvent,
        actionData,
        intentEnabled,
        stringEntities,
        stringTopics,
      ]);
    }

    // Variations exists
    const variations = row.variations as IExample[];

    variations.forEach((variation) => {
      const language = variation.language || row.language;

      let problem = "";
      const topPrediction = _.first(variation.intent_ranking);
      if (topPrediction) {
        if (topPrediction.name !== row.intentName) {
          problem = `CONFLICT:${topPrediction.name}`;
        }

        if (topPrediction.confidence < 0.5) {
          problem = `LOW_CONFIDENCE:${topPrediction.confidence}`;
        }
      }

      ws_data.push([
        row.intentName,
        variation.text,
        variation.is_suggestion,
        language,
        actionEvent,
        actionData,
        intentEnabled,
        stringEntities,
        stringTopics,
        problem,
      ]);
    });
  });

  return ws_data;
}

export function formatEntities(stringEntities: string): string[] {
  if (Array.isArray(stringEntities)) {
    return stringEntities;
  }
  if (typeof stringEntities !== "string") {
    return [];
  }
  return stringEntities.split(";"); // delimiter ';'
}

export function findIntentIndex(id: string, intentName: string): number {
  const existingDataset = store.state.training.dataset;
  const intentIndex = _.findIndex(existingDataset, (row) => {
    return row.id === id || row.intentName === intentName;
  });

  return intentIndex;
}

export function validateIntentVariations(variations: IExample[]): boolean {
  let isInvalid = false;
  const isPredictionOptionsEnabled = _.get(
    store,
    "state.modules.faq.predictionOptions.enablePredictionOptions",
    false
  );

  const hasAtLeastOneSuggestion = _.find(
    variations,
    (variation: IExample) => variation.is_suggestion
  );
  if (isPredictionOptionsEnabled && !hasAtLeastOneSuggestion) {
    isInvalid = true;
  }
  return isInvalid;
}

export function validateIntentAction(action: ButtonType): boolean {
  let isInvalid = false;
  const isInvalidBasicDetails = validateBasicIntentDetails(action);
  const isInvalidEvent = validateIntentEvent(action);
  const isInvalidNext = validateIntentNextIfAny(action);
  if (!isInvalid) {
    // only update if false
    isInvalid = isInvalidBasicDetails || isInvalidEvent || isInvalidNext;
  }
  return isInvalid;
}

function validateBasicIntentDetails(action: ButtonType): boolean {
  const { url, phone, event, data } = action;
  if (!url && !phone && !event) {
    return true;
  }
  if (event && !data) {
    return !isLinkFound(event);
  }
  return false;
}

// TODO: parmeshwar
function validateIntentEvent(action: ButtonType): boolean {
  const { event, data } = action;
  if (event === "capture") {
    if (!data) {
      return true;
    } else {
      const { key, value, next } = data;
      if (!key || !value) {
        return true;
      } else {
        return !isLinkFound(action);
      }
    }
  } else if (event === "startflow") {
    if (!data) {
      return true;
    } else {
      const { id } = data;
      if (!id) {
        return true;
      }
    }
  }
  return false;
}

// TODO: parmeshwar
function validateIntentNextIfAny(action: ButtonType): boolean {
  const { event, data } = action;
  if (event === "goto") {
    return !isLinkFound(data);
  }
  return false;
}
