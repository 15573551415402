<template>
  <div class="icon-bell-notification">
    <img
      v-if="isHover || isOpened"
      src="../../assets/bell-notification-opened.svg"
      width="18px"
      height="18px"
    />
    <img v-else src="../../assets/bell-notification.svg" width="18px" height="18px" />
  </div>
</template>

<script>
export default {
  props: ["isHover", "isOpened"],
};
</script>

<style lang="scss">
.icon-bell-notification {
  height: 22px;
}
</style>
