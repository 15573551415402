// @ts-nocheck

export default {
  methods: {
    fallbackFormMixin(apis) {
      return {
        pipeline: {
          data: [
            { key: "polyaimodel", value: "polyaimodel" },
            { key: "openai", value: "openai" },
            { key: "faq", value: "faq" },
            { key: "zendesk", value: "zendesk" },
            { key: "luis", value: "luis" },
            { key: "bookworm", value: "bookworm" },
            { key: "search", value: "search" },
            { key: "wikipedia", value: "wikipedia" },
            { key: "emotion", value: "emotion" },
            { key: "handover", value: "handover" },
            ...apis,
          ],
          default: ["faq", "handover"],
          label: "Fallback Order",
          type: "transfer",
          titles: ["Disabled", "Enabled"],
        },
        minLength: {
          default: 0,
          label: "Minimum Text Length",
          type: "number",
        },
        maxLength: {
          default: 200,
          label: "Maximum Text Length",
          type: "number",
        },
        retryLimit: {
          default: 2,
          label: "Retry Limit",
          type: "number",
          detail: "Number of retries before it hits fallback node",
        },
        retryFallbackNode: {
          default: "handover_talk_to_agent",
          label: "Go to node",
          type: "string",
          detail: "The node_id chatbot will fall back on after it hits Retry Limit",
        },
        resetFallbackAttemptsOnResultFound: {
          default: true,
          label: "Reset fallback attempts on result found",
          type: "boolean",
          detail:
            "The fallback attempt counter will be reset when a result is found through any of the fallback mechanism",
        },
        resetFallbackAttemptsOnTooManyAttempts: {
          default: true,
          label: "Reset fallback attempts on too many attempts",
          type: "boolean",
          detail:
            "The fallback attempt counter will be reset when the number of attempts exceeds the limit",
        },
      };
    },
  },
};
