import _ from "lodash";
import { isLocal } from "@/helperMethods/util";

const params = new URLSearchParams(location.search);

const getRoot = (): string => {
  const server = params.get("server");
  const baseURL = _.get(window, "appConfig.BASE_URL");

  if (server) {
    if (/^http/.test(server)) {
      if (/\/server/.test(server)) {
        return server;
      } else {
        return `${server}/server/`;
      }
    } else if (/\.(app|staging)/.test(server)) {
      if (/.keyreply.com$/.test(server)) {
        return `https://${server}/server/`;
      } else {
        return `https://${server}.keyreply.com/server/`;
      }
    } else {
      return `https://${server}.staging.keyreply.com/server/`;
    }
  } else {
    if (baseURL) {
      return baseURL;
    }

    if (isLocal()) {
      return `http://localhost:3000`;
    }
  }

  return prompt("Server URL") || "";
};

const config = {
  root: getRoot(),
};

export { config, getRoot };
