import { SupervisorState } from "./types";

const state: SupervisorState = {
  currentMode: "",
  selectedChatSession: null,
  currentExpanded: null,
  selectedAgent: null,
  agentsWithChatCount: [],

  inQueueChatsArray: [],
  onGoingChatsArray: [],
  abandonedChatsArray: [],
  resolvedChatsArray: [],

  inQueueChatCount: 0,
  onGoingChatCount: 0,
  abandonedChatCount: 0,
  resolvedChatCount: 0,

  inQueueChatLoading: true,
  onGoingChatLoading: true,
  abandonedChatLoading: true,
  resolvedChatLoading: true,
  agentListLoading: true,
};

export default state;
