<template>
  <el-row>
    <el-col>
      <label
        style="
          vertical-align: middle;
          margin-left: 0.4em;
          margin-bottom: 2em;
          margin-right: 0.5em;
          fontsize: 14px;
        "
        class="has-text-grey"
      >
        Department:
        <el-tooltip
          effect="dark"
          content="The department this intent belongs to. General by default."
          placement="top"
        >
          <i class="has-text-grey el-icon-question" style="margin-left: 0.2em"></i>
        </el-tooltip>
      </label>
      <el-select
        :class="selectDepartmentClass"
        placeholder="Select a department"
        :disabled="!isAuthorisedForChange(intentDepartment)"
        v-model="intent.department"
        multiple
        filterable
      >
        <el-option
          v-for="(department, index) in capitalizedDepartments($store.getters.userDepartment)"
          :key="index"
          :label="department"
          :value="department.toLowerCase()"
        >
          <span style="float: left">{{ department }}</span>
        </el-option>
      </el-select>
    </el-col>
  </el-row>
</template>

<script>
import _ from "lodash";

export default {
  props: ["intent"],
  computed: {
    intentDepartment: {
      get() {
        let intentDepartment = this.intent.department || [];
        if (intentDepartment.length === 0) {
          intentDepartment = ["general"];
          this.$set(this.intent.department, ["general"]);
        }

        const lowerCaseIntentDepartment = intentDepartment.map((word) => word.toLowerCase());

        return lowerCaseIntentDepartment;
      },
    },
    selectDepartmentClass() {
      if (this.intentDepartment.length > 1) {
        return "department-selection";
      } else {
        return "department-selection-small";
      }
    },
  },
  methods: {
    isAuthorisedForChange(intentDepartment) {
      const userDepartment = this.$store.getters.userDepartment;

      const userIsAuthorised = _.intersection(userDepartment, intentDepartment).length > 0;
      const intentIsGeneral = intentDepartment.includes("general");

      const isAuthorised = userIsAuthorised || intentIsGeneral;
      return isAuthorised;
    },
    capitalizedDepartments(departments) {
      let capitalizedDepartments = [];
      if (departments) {
        capitalizedDepartments = _.map(departments, (dept) => {
          return dept
            .split(" ")
            .map((word) => _.capitalize(word))
            .join(" ")
            .trim();
        });
      }
      return capitalizedDepartments;
    },
  },
  watch: {
    "intent.department"() {
      if (
        this.intent.department &&
        this.intent.department.length > 1 &&
        this.intent.department[0] === "general"
      ) {
        this.intent.department.splice(0, 1);
      } else if (this.intent.department && this.intent.department.length === 0) {
        this.intent.department = ["general"];
      }
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 1500px) {
  /* 1500px based on manual estimation */
  .department-selection {
    margin-left: 0.6em;
    width: 100%;
    max-width: 300px;
  }
}
@media screen and (min-width: 1500px) {
  /* 1500px based on manual estimation */
  .department-selection {
    margin-left: 0.6em;
    width: 100%;
    max-width: 450px;
  }
}
.department-selection-small {
  margin-left: 0.6em;
  width: 100%;
  max-width: 300px;
}
</style>
