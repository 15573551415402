import { LivechatSessionType } from "@/store/modules/livechat/types";

/**
 * Split ended chat sessions into two types: abandoned and resolved.
 *
 * Abandoned chats are chat ended but do not have any agent assigned.
 * Resolved chats are chat ended but do have at least one agent assigned.
 *
 * @param LivechatSessionType[] endedChatSessions
 * @returns {
 *   abandoned: LivechatSessionType[];
 *   resolved: LivechatSessionType[];
 * }
 */
export function splitAbandonedResolvedChats(endedChatSessions: LivechatSessionType[]): {
  abandonedChats: LivechatSessionType[];
  resolvedChats: LivechatSessionType[];
} {
  const resolvedChats: LivechatSessionType[] = [];
  const abandonedChats: LivechatSessionType[] = [];
  endedChatSessions.forEach((chat) => {
    if (chat.agents && chat.agents.length > 0) {
      resolvedChats.push(chat);
    } else {
      abandonedChats.push(chat);
    }
  });

  return {
    abandonedChats,
    resolvedChats,
  };
}
