<template>
  <div>
    <span class="input-label"> Quick Reply Display Mode </span>
    <el-select
      filterable
      @change="handleChange"
      :value="quickReplyDisplayMode"
      placeholder="Select"
      name="QuickReplyDisplayMode"
      class="input-select"
    >
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        <span class="select-label">{{ item.label }}</span>
        <span class="select-value">{{ item.value }}</span>
      </el-option>
    </el-select>
    <el-tooltip effect="dark" :content="detail" placement="top-end">
      <i class="el-icon-question detail-icon" />
    </el-tooltip>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "QuickReplyDisplayMode",
  props: ["node"],
  data() {
    return {
      detail: "Default will use global configuration",
      options: [
        {
          label: "Default",
          value: "default",
        },
        {
          label: "Always",
          value: "always",
        },
        {
          label: "None",
          value: "none",
        },
      ],
    };
  },
  computed: {
    quickReplyDisplayMode() {
      return _.get(this.node, "options.quickReplyDisplayMode", "default");
    },
  },
  methods: {
    handleChange(value) {
      const newNode = _.cloneDeep(this.node);
      if (!newNode.options) {
        newNode.options = {
          quickReplyDisplayMode: value,
        };
      } else {
        newNode.options.quickReplyDisplayMode = value;
      }
      this.$emit("updateNode", newNode);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/colors.scss";
.select-value {
  float: right;
  color: $color-grey;
  font-size: 13px;
  margin-left: 1.5em;
}

.select-label {
  float: left;
}

.input-select {
  margin-bottom: 1em;
  width: 160px;
}

.input-label {
  margin-left: 0px;
  margin-right: 1.5em;
  color: $color-grey;
  font-size: 14px;
}

.detail-icon {
  margin-left: 0.5em;
  color: $color-grey;
}
</style>
