import _ from "lodash";

interface MappedObject {
  flows: any[];
  links: any[];
  nodes: any[];
  NotFound: any[];
  ActionNotClear: any[];
  intents: any[];
}

interface SceneObject {
  flows: any[];
  links: any[];
  nodes: any[];
}

function filterSceneByIntentId(sceneObject: SceneObject, intentId: string): SceneObject {
  const filterred = _.cloneDeep(sceneObject);

  filterred.links = _.filter(filterred.links, (item) => _.includes(item.intentId, intentId));
  filterred.nodes = _.filter(filterred.nodes, (item) => _.includes(item.intentId, intentId));
  filterred.flows = _.filter(filterred.flows, (item) => _.includes(item.intentId, intentId));

  return filterred;
}

function nodesPositionSetup(sceneObject: SceneObject): SceneObject {
  const data = _.cloneDeep(sceneObject);

  let indexOfPreviousNodeFromCurrentNode: number;
  let buttonIndex: number;
  let buttonNotFound: boolean = false;
  const [initPosX, initPosY] = [50, 50];
  const [distanceX, distanceY] = [400, 400];

  data.nodes = _.map(data.nodes, (item) => {
    const newItem = {
      ...item,
    };

    const linkToCurrentNode = _.find(data.links, (link) => link.to === item.id);

    if (!linkToCurrentNode) {
      [newItem.x, newItem.y] = [initPosX, initPosY];
    } else {
      indexOfPreviousNodeFromCurrentNode = _.findIndex(
        data.nodes,
        (node) => linkToCurrentNode.from === node.id
      );
      newItem.x = (indexOfPreviousNodeFromCurrentNode + 1) * distanceX + initPosX;

      buttonIndex = _.get(
        data,
        `nodes[${indexOfPreviousNodeFromCurrentNode}].buttons`,
        []
      ).findIndex((button: any) => {
        const buttonIdFromLink =
          linkToCurrentNode.button != null
            ? linkToCurrentNode.button
            : linkToCurrentNode.quickReply;
        const expectedButtonStyleType = linkToCurrentNode.button != null ? "button" : "quickReply";

        const buttonIdIsMatched = button.id === buttonIdFromLink;
        const buttonStyleTypeIsMatched = button.styleType === expectedButtonStyleType;

        const conditionSatisfied = buttonIdIsMatched && buttonStyleTypeIsMatched;

        return conditionSatisfied;
      });

      buttonNotFound = buttonIndex === -1;
      if (buttonNotFound) {
        newItem.y = 0;
      } else {
        newItem.y = buttonIndex * distanceY + initPosY;
      }
    }

    newItem.isLocked = false;

    return newItem;
  });

  return data;
}

function flowchartSetup(mappedObject: MappedObject, intentId: string): MappedObject {
  const data = _.cloneDeep(mappedObject);

  let tempScene: any = null;

  /**
   * description: filter function here is used for filtering nodes, flows, and links that represent with the selected intent ID
   */
  tempScene = filterSceneByIntentId(
    { flows: data.flows, links: data.links, nodes: data.nodes },
    intentId
  );

  data.links = tempScene.links;
  data.nodes = tempScene.nodes;
  data.flows = tempScene.flows;

  /**
   * description: this is to merge nodes and flows, and merge every nodes quickReplies and buttons
   */
  data.nodes = [...data.nodes, ...data.flows];

  data.nodes = _.map(data.nodes, (item) => ({
    ...item,
    buttons: [...item.buttons, ...item.quickReplies],
  }));

  /**
   * description: mapping every nodes positions by default
   */
  tempScene = nodesPositionSetup({
    flows: data.flows,
    links: data.links,
    nodes: data.nodes,
  });

  data.links = tempScene.links;
  data.nodes = tempScene.nodes;
  data.flows = tempScene.flows;

  return data;
}

function changeVersion(
  version: string,
  id: string,
  intentId: string,
  flowsData: any,
  currentMappedFlow: any
) {
  let data = _.cloneDeep(flowsData);
  data = flowchartSetup(data, intentId);

  // initialize new nodes array and reduce current nodes and flows data
  const nodes: any = _.reduce(
    data.nodes,
    (prev, current) => ({
      ...prev,
      [current.id]: current,
    }),
    {}
  );
  const flows = _.reduce(
    data.flows,
    (prev, current) => ({
      ...prev,
      [current.id]: current,
    }),
    {}
  );

  // remove nodes and flows from hidden node (unversioned node)
  const nodeId = `${id}:${version}`;

  const iteratingNodes: any = {};
  iteratingNodes[nodeId] = nodes[nodeId];

  let nodesLength = Object.keys(iteratingNodes).length;
  let index = 0;

  while (index < nodesLength) {
    const key = Object.keys(iteratingNodes)[0];
    const node = iteratingNodes[key];

    _.forEach(node.buttons, (button) => {
      if (button.event === "goto") {
        const nextNodeId = button.data;
        const nextNode = nodes[nextNodeId];
        if (nextNode) {
          iteratingNodes[nextNodeId] = nextNode;
        }
      }
    });

    _.forEach(node.quickReplies, (quickReply) => {
      if (quickReply.event === "goto") {
        const nextNodeId = quickReply.data;
        const nextNode = nodes[nextNodeId];
        if (nextNode) {
          iteratingNodes[nextNodeId] = nextNode;
        }
      }
    });

    index++;
    nodesLength = Object.keys(iteratingNodes).length;
  }

  const mappedNodes: any = _.reduce(
    currentMappedFlow,
    (prev, current) => ({
      ...prev,
      [current.id]: current,
    }),
    {}
  );

  const iteratingKeys = Object.keys(iteratingNodes);

  _.forEach(iteratingKeys, (key) => {
    const iteratingNode = iteratingNodes[key];

    mappedNodes[nodeId] = mappedNodes[id];
    delete mappedNodes[id];

    mappedNodes[nodeId] = {
      ...mappedNodes[nodeId],
      id: nodeId,
      label: iteratingNode.label,
      buttons: iteratingNode.buttons,
      quickReplies: iteratingNode.quickReplies,
    };
  });

  data.nodes = Object.values(mappedNodes);

  return data;
}

export { filterSceneByIntentId, nodesPositionSetup, flowchartSetup, changeVersion };
export { MappedObject, SceneObject };
