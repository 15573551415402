<template>
  <section>
    <el-button
      plain
      size="mini"
      type="warning"
      icon="el-icon-d-arrow-right"
      style="margin-right: 10px"
      :disabled="isBusy || isEscalating || !isInChat"
      :loading="isEscalating"
      @click.prevent="dialogIsOpened = true"
      >Escalate {{ $device.Mobile ? "" : "Chat" }}</el-button
    >

    <el-dialog
      id="transferChatDialog"
      title="Transfer to Department"
      width="30%"
      :visible="dialogIsOpened"
      :show-close="false"
      :append-to-body="true"
    >
      <div width="20%">
        <el-select filterable v-model="toTransferDepartmentID" placeholder="Select department">
          <el-option
            v-for="item in departments"
            :key="item.id"
            :label="item.name"
            :value="item.id"
            :disabled="item.name.toLowerCase() === userQueryTopic.toLowerCase()"
          />
        </el-select>
      </div>

      <el-alert
        title="Transfer to Department"
        type="info"
        effect="dark"
        :disabled="!isMultipleAgents"
        :closable="false"
        style="margin-top: 10px; word-break: break-word"
        show-icon
      >
        The agent will be removed from the chat when the chat is transfered to a department
      </el-alert>
      <el-alert
        title=""
        type="info"
        effect="dark"
        v-if="isMultipleAgents"
        :closable="false"
        style="margin-top: 10px; word-break: break-word"
        show-icon
      >
        Only 1 agent can continue and other agents may "Leave Chat" in order to transfer the ongoing
        chat to a department successfully.
      </el-alert>

      <span slot="footer" class="dialog-footer">
        <el-button
          :disabled="isMultipleAgents || isEscalating || !toTransferDepartmentID"
          :loading="isEscalating"
          type="primary"
          @click="transferChat"
        >
          Confirm
        </el-button>
        <el-button :disabled="isEscalating" @click.prevent="dialogIsOpened = false">
          Cancel
        </el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import { isTransferRedactedText } from "@/helperMethods/livechat/util";

export default {
  props: ["selectedChat", "isBusy", "isInChat"],
  data() {
    return {
      dialogIsOpened: false,
      toTransferDepartmentID: null,
      isEscalating: false,
    };
  },
  mounted() {
    // Fetch all departments on mounted
    this.fetchDepartments();
  },
  methods: {
    /**
     * @description Check if department is current
     * @return {boolean}
     */
    isCurrentDepartment(department) {
      return department === this.userQueryTopic;
    },

    /**
     * @description Fetch all departments
     * @return {void}
     */
    fetchDepartments() {
      this.$store.dispatch("FETCH_DEPARTMENTS");
    },

    /**
     * @description For department routing only
     * @description Should only available if isHandoverRoutingByDepartmentEnabled
     * @return {void}
     */
    async transferChat() {
      try {
        if (this.selectedChat.agents.length > 1) {
          // FIXME: Use a more friendly message.
          this.$notify.error({
            title: "Error",
            message: "Chat with more than 1 agent not allow to escalated.",
            position: "bottom-right",
          });
          return;
        }

        this.isEscalating = true;
        const response = await this.$store.dispatch("SET_CHAT_DEPARTMENT", {
          chat: this.selectedChat,
          toDepartment: this.toTransferDepartmentID,
          transferRedacted: this.isTransferRedactedText,
        });

        const state = _.get(response, "data.livechatAPI.setLivechatDepartment", null);

        if (!state) {
          this.$notify.error({
            title: "Error",
            message: "There is no agent online in the selected department.",
            position: "bottom-right",
          });
        } else {
          this.$notify.success({
            title: "Success",
            message: "Chat escalated.",
            position: "bottom-right",
          });
        }

        this.isEscalating = false;
        this.dialogIsOpened = false;
      } catch (error) {
        this.$notify.error({
          title: "Error",
          message: "There is no agent online in the selected department.",
          position: "bottom-right",
        });
        this.isEscalating = false;
      }
    },
  },
  computed: {
    /**
     * @description Departments list that able to escalate chat to
     * @return {Array<{ id: string; name: string; description: string; meta: any }>}
     */
    departments() {
      const departments = _.get(this.$store.state, "department.departments", []);

      const connectedAgents = _.get(this.$store.state, "livechat.connectedAgents", []);

      let onlineDepartments = [];

      _.forEach(connectedAgents, (agent) => {
        if (agent.status === "ONLINE") {
          onlineDepartments = _.union(onlineDepartments, agent.departments);
        }
      });

      return _.filter(departments, (department) => {
        return onlineDepartments.indexOf(department.name) !== -1;
      });
    },

    /**
     * @description User query topic
     * @return {string}
     */
    userQueryTopic() {
      const userQueryTopic = _.get(this.selectedChat, "stateVariables.userQueryTopic");
      return userQueryTopic || "";
    },

    /**
     * @description If more than 1 agent connected in a chat
     * @return {boolean}
     */
    isMultipleAgents() {
      return this.selectedChat.agents.length > 1;
    },

    isTransferRedactedText,
  },
};
</script>

<style lang="scss">
@media (max-width: 1024px) {
  #transferChatDialog {
    .el-dialog {
      width: 70% !important;
    }
  }
}
</style>
