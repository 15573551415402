<template>
  <el-card class="box-card" style="margin: 12px 0">
    <div slot="header" class="clearfix">
      <span>{{ title }}</span>
      <el-button
        v-if="!allOptional"
        @click="showOptional = !showOptional"
        style="float: right; padding: 3px 0"
        type="text"
        >{{ showOptional ? "Hide" : "Show" }} optional fields</el-button
      >
      <el-button v-else disabled style="float: right; padding: 3px 0" type="text"
        >This is optional</el-button
      >
    </div>
    <el-form-item
      v-for="(field, index) in requiredFields"
      :key="`rf-${index}`"
      prop="text"
      :rules="[{ required: true, trigger: 'blur', message: 'Please provide input' }]"
    >
      <el-input type="text" v-model="obj[field.key]" :placeholder="field.label"> </el-input>
    </el-form-item>
    <div v-if="showOptional || allOptional">
      <el-form-item v-for="(field, index) in optionalFields" :key="`of-${index}`">
        <el-input
          v-if="!field.type"
          type="text"
          v-model="obj[field.key]"
          :placeholder="field.label"
        >
        </el-input>
        <el-select
          v-if="field.type === 'select'"
          v-model="obj[field.key]"
          :placeholder="field.label"
        >
          <el-option
            v-for="(item, index) in field.options"
            :key="index"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <slot />
    </div>
  </el-card>
</template>

<script>
export default {
  name: "WhatsAppContactInfoCard",
  props: ["title", "obj", "fields"],
  data() {
    return {
      showOptional: false,
    };
  },
  computed: {
    requiredFields() {
      return this.fields.filter((field) => field.required);
    },
    optionalFields() {
      return this.fields.filter((field) => !field.required);
    },
    allOptional() {
      return this.requiredFields.length === 0;
    },
  },
};
</script>

<style></style>
