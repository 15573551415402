<template>
  <div class="chat-list-wrapper">
    <el-row v-loading="fetchAbandonedChatLoading">
      <!-- Empty chat message -->
      <el-row
        v-if="!abandonedChatsArray || abandonedChatsArray.length === 0"
        style="text-align: center; position: relative"
      >
        <h1>There are currently no abandoned chats.</h1>
      </el-row>

      <el-row
        class="chat-list-item"
        @click.native="selectChat(chat.RowKey, chat.interactions?.length || 0)"
        v-for="(chat, index) in abandonedChatsArray"
        :key="index"
      >
        <el-tooltip class="list-item" effect="dark" placement="right">
          <div slot="content" style="max-width: 400px">
            <AbandonedTooltip :chat="chat" />
          </div>

          <el-row
            class="list-item"
            type="flex"
            :class="getChatClass(index, chat, selectedChatId)"
            style="margin-bottom: 0"
          >
            <!-- Avatar -->
            <el-col style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
              <el-row type="flex" style="margin-bottom: 0; align-items: center">
                <ChatAvatar :chat="chat" style="display: inline-block" />
                <el-col
                  style="
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                  "
                >
                  <div class="chat-name">
                    <span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                      {{ getChatName(chat) }}
                    </span>
                  </div>
                  <el-row style="margin-top: 4px" v-if="getTagName(chat.stateVariables)">
                    <el-tag :type="tagType(getTagName(chat.stateVariables))" size="mini">{{
                      getTagName(chat.stateVariables)
                    }}</el-tag>
                  </el-row>
                </el-col>
              </el-row>
              <el-row style="font-size: 16px">
                <div class="chat-item-meta" v-if="chat.agents.length > 0">
                  <span>Attended by {{ chat.agents.join(", ") }}</span>
                </div>
              </el-row>
            </el-col>
          </el-row>
        </el-tooltip>
      </el-row>
      <el-row>
        <div
          v-loading="showMoreIsLoading"
          element-loading-spinner="el-icon-loading"
          v-if="abandonedChatsArray && abandonedChatsArray.length > 0 && hasMoreChats"
          class="prev-conversation"
          @click="$emit('fetchMoreAbandonedChats')"
        >
          - show more -
        </div>
      </el-row>
    </el-row>
  </div>
</template>

<script>
import AbandonedTooltip from "./Tooltip";
import ChatAvatar from "@/components/LiveChat/ChatAvatar";
import { mapGetters } from "vuex";
import moment from "moment";
import map from "lodash/map";
import get from "lodash/get";
import { getChatName, getTagName, tagType } from "@/filters/index";

import {
  getAvatar,
  getChatClass,
  getDisplayId,
  getPreview,
  printDuration,
} from "@/helperMethods/livechat/util";

export default {
  props: {
    selectedIndex: Number,
    showMoreIsLoading: {},
    hasMoreChats: {},
  },
  components: {
    AbandonedTooltip,
    ChatAvatar,
  },
  data() {
    return {
      waitedTime: [],
      pageNo: 0,
      tab: "abandoned",
    };
  },
  computed: {
    ...mapGetters(["fetchAbandonedChatLoading", "abandonedChatsArray", "selectedChatId"]),
  },
  methods: {
    getAvatar,
    getDisplayId,
    getChatClass,
    getPreview,
    get,
    getChatName,
    getTagName,
    tagType,

    selectChat(chatId, interactionsLength) {
      this.$set(this.$store.state.livechat, "selectedChatId", chatId);
      this.$store.commit("SELECT_LIVE_CHAT", { chatId, type: "abandoned" });
      if (interactionsLength < 10) {
        this.$emit("on-init-fetch-interaction");
      }
    },

    calculateWaitedTime() {
      this.waitedTime = map(this.abandonedChatsArray, (chat) => {
        return printDuration(chat.startQueueTime, moment().format());
      });
    },
  },
  mounted() {
    this.calculateWaitedTimeInterval = setInterval(() => {
      this.calculateWaitedTime();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.calculateWaitedTimeInterval);
  },
  filters: {
    humanize(value) {
      return moment(value).fromNow();
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/chat-list.scss";
</style>
