import NotSecureContext from "@/components/NotSecureContext.vue";

export default {
  components: {
    NotSecureContext,
  },
  computed: {
    isSecureContext() {
      const component = this as any;
      return component.$store.state.isSecureContext;
    },
  },
};
