<template>
  <el-table size="mini" :data="departments">
    <el-table-column prop="id" label="ID" width="200" />
    <el-table-column prop="name" label="Name" width="200" />
    <el-table-column prop="description" label="Description" />
    <el-table-column label="Operations" width="200">
      <template slot-scope="scope">
        <el-button
          type="primary"
          icon="el-icon-edit"
          size="mini"
          plain
          circle
          @click="handleEdit(scope.$index, scope.row)"
        ></el-button>
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          circle
          :loading="deleteLoading"
          @click="deleteDepartment(scope.$index, scope.row)"
        ></el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";

const DEPARTMENTS_DESTROY = require("@/graphql/DepartmentsDestroy.graphql");
const DEPARTMENTS_GET = require("@/graphql/DepartmentsGet.graphql");

export default Vue.extend({
  props: {
    departments: Array,
  },
  data() {
    return {
      deleteLoading: false,
    };
  },
  computed: {
    ...mapGetters(["createDepartmentLoading"]),
  },
  methods: {
    handleEdit(index, row) {
      this.$emit("edit:department", row);
    },

    deleteDepartment(index, department) {
      this.deleteLoading = true;
      this.$confirm("This will permanently delete the department.", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.destroyDepartment(department);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete department canceled",
          });
          this.deleteLoading = false;
        });
    },
    destroyDepartment(department) {
      const self = this;
      this.$apollo
        .mutate({
          mutation: DEPARTMENTS_DESTROY,
          variables: {
            id: department.id,
          },
          update(store, { data: { agentAPI } }) {
            // const data = store.readQuery({ query: DEPARTMENTS_GET });
            // const { departments } = _.get(data, "agentAPI");
            // const index = departments.findIndex(o => o.id === department.id);
            // if (index !== -1) {
            //   departments.splice(index, 1);
            // }
            // store.writeQuery({ query: DEPARTMENTS_GET, data });

            // set loading to be false
            self.$store.commit("DEPARTMENT_DELETE", department.id);
            self.deleteLoading = false;
            self.$notify.success({
              title: "Success",
              position: "bottom-right",
              message: `Successfully deleted the ${department.name} department!`,
            });
          },
        })
        .catch((err) => {
          self.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `Failed to delete the ${department.name} department!`,
          });
        });
    },
  },
});
</script>
