<template>
  <div>
    <span style="margin-left: 1.5em; margin-right: 3.5em; fontsize: 14px" class="has-text-grey">
      {{ setting.label || name }}
      <Tooltip :label="setting.label || name" :content="setting.detail" />
    </span>
    <el-date-picker
      v-model="value[name]"
      type="date"
      format="dd-MM-yyyy"
      placeholder="Pick a day"
      style="margin-bottom: 20px"
    >
    </el-date-picker>
  </div>
</template>

<script>
import Tooltip from "../Tooltip.vue";
export default {
  components: { Tooltip },
  props: ["value", "setting", "name"],
  mounted() {
    if (this.value[this.name] == null) {
      if (this.setting.default != null) {
        this.$set(this.value, this.name, this.setting.default);
      } else {
        this.$set(this.value, this.name, null);
      }
    }
  },
};
</script>
