<template>
  <div>
    <el-button
      style="line-height: 36px; padding: 0 20px"
      size="mini"
      @click="dialogTableVisible = true"
      type="text"
    >
      History
    </el-button>

    <el-dialog
      @opened="opened"
      @closed="closed"
      title="Interaction History"
      width="80%"
      :visible.sync="dialogTableVisible"
      :append-to-body="true"
    >
      <section>
        <el-table size="mini" :data="interactions">
          <el-table-column property="date" label="Time" width="150">
            <template slot-scope="scope">
              <small>
                {{ scope.row.Timestamp | moment }}
              </small>
            </template>
          </el-table-column>
          <el-table-column property="event" label="Action" width="200"></el-table-column>
          <el-table-column property="data" label="Content">
            <template slot-scope="scope">
              <div v-if="typeof flatten(scope.row.data) === 'string'">
                {{ scope.row.data }}
              </div>
              <div v-else>
                <el-tooltip placement="bottom" effect="light">
                  <table slot="content">
                    <tr
                      :key="`${scope.row.id}-${key}`"
                      v-for="(item, key) in flatten(scope.row.data)"
                    >
                      <th>{{ key }}</th>
                      <td>{{ item }}</td>
                    </tr>
                  </table>
                  <el-button type="text" size="mini">View Data</el-button>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column property="prediction" label="Model Prediction" width="250">
            <template slot-scope="scope">
              {{ scope.row.prediction || "-" }}
            </template>
          </el-table-column>
          <el-table-column property="probability" label="Score" width="100">
            <template slot-scope="scope">
              {{ formatScore(scope.row.probability) }}
            </template>
          </el-table-column>
        </el-table>
        <div style="text-align: center; margin-top: 10px">
          <el-button
            type="text"
            size="mini"
            :loading="loading"
            :disabled="loading || !canShowMore"
            @click="showMore"
            >show more</el-button
          >
        </div>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import { flatten } from "@/helpers/flatten";
import moment from "moment";
import { defaultPickerOptions } from "@/helperMethods/util";

export default {
  data() {
    return {
      pickerOptions: defaultPickerOptions,
      loading: false,
      pageSize: _.get(this.$store, "state.modules['visitors'].interactions_page_size", 25),
      currentPage: 1,
      dialogTableVisible: false,
      interactionsCount: 0,
      interactions: [],
      trainingData: [],
    };
  },
  filters: {
    moment: function (date) {
      return moment(date).local().format("D MMM YY h:mm A");
    },
  },
  computed: {
    canShowMore() {
      return this.currentPage < this.totalPages;
    },
    totalPages() {
      return Math.ceil(this.interactionsCount / this.pageSize);
    },
  },
  props: ["visitor", "searchDateRange"],
  methods: {
    formatScore(number) {
      if (!number) {
        return "-";
      }
      return number.toFixed(2);
    },
    flatten,
    async opened() {
      await this.getTrainingData();
      await this.getInteractions();
      this.updateInteractionsWithTrainingPredictions();
    },
    closed() {
      this.interactions = [];
      this.currentPage = 1;
      this.interactionsCount = 0;
      this.trainingData = [];
      this.dialogTableVisible = false;
    },
    async showMore() {
      this.currentPage += 1;
      await this.getTrainingData();
      await this.getInteractions();
      this.updateInteractionsWithTrainingPredictions();
    },
    async getTrainingData() {
      this.loading = true;

      try {
        let startDate = "";
        let endDate = "";
        if (this.searchDateRange && this.searchDateRange.length > 0) {
          startDate = this.searchDateRange[0];
          endDate = this.searchDateRange[1];
        }
        const response = await this.$store.dispatch("GET_TRAINING_DATA_BY_USER", {
          startDate,
          endDate,
          userId: this.visitor.RowKey,
        });
        this.loading = false;
        this.trainingData.push(...response);
      } catch (err) {
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: `Error fetching training data.`,
        });
        this.loading = false;
      }
    },
    async getInteractions() {
      this.loading = true;

      try {
        let startDate = "";
        let endDate = "";
        if (this.searchDateRange && this.searchDateRange.length > 0) {
          startDate = this.searchDateRange[0];
          endDate = this.searchDateRange[1];
        }
        const response = await this.$store.dispatch("FETCH_USER_INTERACTIONS_BY_PAGE", {
          userId: this.visitor.RowKey,
          page: this.currentPage,
          pageSize: this.pageSize,
          searchString: this.search,
          startDate: startDate,
          endDate: endDate,
        });

        const data = _.get(response, "data.getInteractionsByPage.rows", []);
        this.loading = false;

        this.interactions.push(...data);
        this.interactionsCount = _.get(response, "data.getInteractionsByPage.count", 0);
      } catch (err) {
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: `Error fetching users.`,
        });
        this.loading = false;
      }
    },
    updateInteractionsWithTrainingPredictions() {
      const clonedInteractions = _.cloneDeep(this.interactions);

      const updatedInteractions = _.map(clonedInteractions, (interaction) => {
        if (interaction.event === "FAQ_SEARCH" || interaction.event === "WATSON_SEARCH") {
          const matchedTraining = _.find(this.trainingData, (data) => {
            const matchingDate = this.isNearDatetime(data.last_modified, interaction.last_modified);
            const matchingUserId = data.user_id === interaction.user_id;
            const matchingData = data.question === interaction.data;
            return matchingDate && matchingUserId && matchingData;
          });

          const probability = _.get(matchedTraining, "probability");
          const prediction = _.get(matchedTraining, "prediction", "");
          if (probability) {
            Object.assign(interaction, { probability, prediction });
          }
        }
        return interaction;
      });

      this.interactions = updatedInteractions;
    },
    /**
     * @description Return true if datetime are within 15 min of each other
     * @param {string} firstDatetime
     * @param {string} secondDatetime
     * @return {boolean}
     */
    isNearDatetime(firstDatetime, secondDatetime) {
      return (
        this.roundMomentToNearest(
          moment(firstDatetime),
          moment.duration(15, "minutes"),
          "ceil"
        ).toString() ===
          this.roundMomentToNearest(
            moment(secondDatetime),
            moment.duration(15, "minutes"),
            "ceil"
          ).toString() ||
        this.roundMomentToNearest(
          moment(firstDatetime),
          moment.duration(15, "minutes"),
          "floor"
        ).toString() ===
          this.roundMomentToNearest(
            moment(secondDatetime),
            moment.duration(15, "minutes"),
            "floor"
          ).toString()
      );
    },
    /**
     * @description round moment to nearest, by floor or ceiling
     * @param {moment} date
     * @param {moment} duration
     * @param {string} method Use "floor" or "ceil"
     * @return {moment}
     */
    roundMomentToNearest(date, duration, method) {
      return moment(Math[method](+date / +duration) * +duration);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/colors.scss";

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid $color-dark;
  text-align: left;
  padding: 4px 10px;
}

tr:nth-child(even) {
  background-color: $color-light;
}
</style>
