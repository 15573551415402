<template>
  <div class="edit-intent">
    <div class="edit-intent-back"></div>
    <div class="edit-intent-container">
      <p class="edit-intent-container-close">
        <i class="el-icon-close" style="font-size: 25px" @click="handleEditModel"></i>
      </p>

      <div class="edit-intent-container-content">
        <div class="edit-intent-container-content-title"><b>Edit Trending Items</b></div>
        <div class="edit-intent-container-content-data">
          <el-table :data="listTrending" :row-class-name="tableRowClassName">
            <el-table-column width="80">
              <template slot-scope="scope" @click="setIntentEdit(scope.row)">
                <div
                  style="height: 30px; display: flex; justify-content: center; align-items: center"
                >
                  <i
                    class="el-icon-success green"
                    style="font-size: 25px"
                    v-if="!checkIconExistExample(scope.row.examples, scope.row.intentId)"
                  ></i>
                  <i class="el-icon-warning orange" style="font-size: 25px" v-else></i>
                </div>
              </template>
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope" @click="setIntentEdit(scope.row)">
                <div
                  style="text-align: start; margin: 0; cursor: pointer"
                  @click="setIntentEdit(scope.row)"
                >
                  <b>
                    {{ getTextFromIntentId(scope.row.intentId) }}
                  </b>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="edit-intent-container-qa">
        <QuestionAnswerDetail :intentEdit="editingEdit" :handleEditModel="handleEditModel" />
      </div>
    </div>
  </div>
</template>

<script>
import QuestionAnswerDetail from "./QuestionAnswerDetail/Index.vue";
import {
  getPublishedExample,
  getTextButton,
  checkCanPublishTopic,
} from "@/helperMethods/trendingTopics/methods";
export default {
  components: { QuestionAnswerDetail },
  props: ["handleEditModel", "listTrending", "intentEdit"],
  data() {
    return {
      editingEdit: {},
    };
  },
  mounted() {
    this.editingEdit = this.intentEdit;
  },
  updated() {
    const offSetTopCurrent = document.querySelectorAll(".selected-intent")[0];
    document.querySelector(".edit-intent-container-content-data div").scrollTop =
      offSetTopCurrent.offsetTop;
  },
  methods: {
    tableRowClassName({ row }) {
      if (row.intentId === this.editingEdit.intentId) {
        return "selected-intent";
      }
      return "";
    },
    checkIconExistExample(examples, intentId) {
      return checkCanPublishTopic(intentId, examples);
    },
    existPublishedExample(examples) {
      if (!examples) return "";
      return getPublishedExample(examples);
    },
    setIntentEdit(data) {
      this.editingEdit = data;
    },
    getTextFromIntentId(data) {
      return getTextButton(data);
    },
  },
  watch: {
    $props: {
      handler() {
        this.isOpenContent = false;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.el-table {
  .selected-intent {
    background: #e3e3e3;
    &:hover {
      background: #e3e3e3 !important;
    }
  }
}

.edit-intent {
  &-back {
    background: rgba(128, 128, 128, 0.418);
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
  }
  &-container {
    width: calc(100vw - 10%);
    height: 70%;
    background: white;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    display: flex;

    &-close {
      position: absolute;
      right: 10px;
      top: -5px;
      text-align: end;
      z-index: 9999;
      width: fit-content;
      > i {
        cursor: pointer;
      }
    }
    &-content {
      width: 40%;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &-title {
        position: absolute;
        z-index: 999;
        top: 20px;
        left: 20px;
      }
      &-data {
        padding: 10px 0;
        display: flex;
        justify-content: center;
        height: 65%;
        > div {
          overflow-y: scroll;
        }
        .el-table__body-wrapper {
          overflow: hidden;
        }
      }
    }
    &-qa {
      width: 60%;
      overflow: hidden;
      padding: 5px 5px 0 0;
    }
  }
  .el-icon-warning {
    &.orange {
      color: orange;
    }
  }
  .el-icon-success {
    &.green {
      color: rgb(22, 180, 1);
    }
  }
}
</style>
