<template>
  <section>
    <masonry :cols="{ default: 9, 1280: 8, 1024: 6, 800: 5, 600: 3 }" :gutter="20">
      <Item
        :key="index"
        :file="item"
        v-for="(item, index) in openedFolder"
        @after-delete="openedFolder.splice(index, 1)"
      />
    </masonry>
  </section>
</template>

<script>
import Item from "./Item";

export default {
  props: ["openedFolder"],
  components: {
    Item,
  },
};
</script>
