<template>
  <div>
    <el-row v-if="value.data.next">
      <el-steps direction="vertical" :active="2" style="width: 24px; height: 100px; margin: auto">
        <el-step />
        <el-step />
        <el-button v-if="value.data.next" type="text" icon="el-icon-close" @click="removeNext">
          Link
        </el-button>
      </el-steps>
      <EventNodeSelector v-model="value.data.next" :previous-node="value" />

      <!-- @save-side-effect="$emit('save-side-effect')"  -->
    </el-row>

    <div v-else style="width: 100%; text-align: center">
      <!-- Toggle User Input for Webchat -->
      <el-button type="text" icon="el-icon-plus" @click="addNext">Next step</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "EventNodeSelectorNext",
  components: {
    EventNodeSelector: () => import("@/components/EventNodeSelector/Index"),
  },
  props: { value: { event: "", data: {} } },
  methods: {
    addNext() {
      this.$set(this.value.data, "next", {
        type: "event",
        event: null,
        data: null,
      });
    },
    removeNext() {
      this.$delete(this.value.data, "next");
    },
  },
};
</script>
