import modules from "@/store/moduleList";

type Post = {
  text: string;
  subtext: string;
  image: string;
  url: string;
};

const params = new URLSearchParams(location.search);
const server =
  params.get("server") || (location.host === "localhost:8080" ? "localhost" : location.host);

export default {
  profile: {},
  server,
  serverStatus: {},
  bots: [] as string[],
  greeting: {
    text: "",
  },
  isPreview: true,
  persistentMenu: [],
  filler: [],
  users: {},
  loaded: 0,
  audit: [],
  activeNodeType: "content",
  activeNodeId: "conversation_start",
  activeNodeSelected: false,
  conditions: false,
  userInteractions: [],
  brain: "",
  account: "",
  posts: {} as { [date: string]: Post[] },
  showEditor: false,
  showAdvanced: false,
  showDeveloperConsole: false,
  showIntentSelector: false,
  selectedIntent: "",
  modules,
  training: {
    unlabeled: [],
    labeled: [],
    dataset: [],
    score: {
      score: 0,
      true: 0,
    },
    trainingProgress: 0,
    all: [],
    searched: [],
    intentTestingCompleted: true,
  },
  broadcasts: {},
  nodeNavigationStack: [],
  nodes: {
    content: {},
    trigger: {},
    condition: {},
    keywords: {},
    event: [],
    tasks: [],
  },
  search: {
    content: null,
    event: null,
    api_mappings: null,
  },
  analytics: null,
  socket: null,
  otherTasks: [],
  contentMissingDetails: [],
  duplicateTriggers: [],
  wildcardTriggers: [],
  intentMissingDetails: [],
  missingContentExpiry: "",
  identicalTriggerExpiry: "",
  missingIntentExpiry: "",
  pages: {
    faq: {
      selectedIntent: null,
    },
  },
  mappedFlow: {
    flows: [],
    links: [],
    nodes: [],
    NotFound: [],
    ActionNotClear: [],
    intents: [],
  },
  visualizationData: {},
  processingVisualization: false,
  releaseId: {
    simple: process.env.VERSION,
    server: window.SERVER_RELEASE_ID,
    dashboard: window.DASHBOARD_RELEASE_ID,
  },
  currentTabLoggedOut: null,
  isSecureContext: window.isSecureContext,

  currentlyTopics: [],
  previousTopics: [],
  trendingTopics: [],
  fliterDate: ["", ""],
  orderCurrently: [],
};
