<template>
  <div id="form_color_settings">
    <div class="color_settings_header">
      <span>Custom Validation</span>
      <el-button @click.prevent="addCondition" size="mini" plain>
        <i class="el-icon-plus"></i>
        Add Rule
      </el-button>
    </div>
    <div class="color_settings_body">
      <div style="padding: 5px" v-if="conditions && conditions.length > 0">
        <el-divider style="font-size: 0.7em">Rules</el-divider>
        <div
          v-for="(condition, index) in conditions"
          :key="index"
          style="
            border: 1px solid #e0e6ed;
            padding: 10px;
            margin: 20px 0;
            box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div style="flex: 1">
            <div style="align-items: center; flex-direction: row; display: flex">
              <el-select
                v-model="condition.property"
                filterable
                allow-create
                default-first-option
                size="small"
                placeholder="Choose a property"
                style="width: 35%; margin-right: 5px"
              >
                <!-- this options list from contentNode.ts // contentNodeOptionsMixin -->

                <el-option
                  v-for="(property, propertyIndex) in form.settings?.uiSchema['ui:order']"
                  :key="`condition-property-option-${propertyIndex}`"
                  :label="property"
                  :value="property"
                ></el-option>
              </el-select>
              <el-select
                v-if="condition.valueType !== 'REGEX'"
                placeholder="Operator"
                filterable
                :value="condition.operator"
                @change="changeOperator(condition, $event)"
                size="small"
                style="width: 15%; margin-right: 5px"
              >
                <el-option
                  :key="`condition-operator-option-${index}`"
                  v-for="(conditionOperatorOption, index) in filterValidOperator(
                    condition.valueType
                  )"
                  :value="conditionOperatorOption.value"
                  :label="conditionOperatorOption.name"
                />
              </el-select>

              <el-select
                :key="
                  ['INCLUDED IN', 'NOT INCLUDED IN'].includes(condition.operator)
                    ? 'select-multiple'
                    : 'select-single'
                "
                v-if="showEntitySelector(condition)"
                size="small"
                filterable
                :multiple="['INCLUDED IN', 'NOT INCLUDED IN'].includes(condition.operator)"
                v-model="condition.value"
                placeholder="Select Entity"
              >
                <el-option
                  v-for="item in entityValues(condition)"
                  :key="item.value"
                  :label="item.value"
                  :value="item.value"
                ></el-option>
              </el-select>
              <div
                style="display: flex; align-items: center; flex: 1"
                v-else-if="!['EXISTS', 'NOT EXISTS'].includes(condition.operator)"
              >
                <el-select
                  class="value-type"
                  placeholder="Type"
                  :value="condition.valueType || 'JSON'"
                  @change="changeValueType(condition, $event)"
                  filterable
                  size="small"
                  style="width: 50%; margin-right: -1px"
                >
                  <el-option
                    :key="`condition-operator-option-${index}`"
                    v-for="(conditionOperatorOption, index) in conditionValueTypeOptions"
                    :value="conditionOperatorOption.value"
                    :label="conditionOperatorOption.name"
                  />
                </el-select>
                <el-input-number
                  class="value-field"
                  v-if="condition.valueType === 'NUMBER'"
                  style="width: 65%"
                  v-model="condition.value"
                  size="small"
                  controls-position="right"
                />
                <el-select
                  class="value-field"
                  v-else-if="condition.valueType === 'BOOLEAN'"
                  placeholder="Value Type"
                  v-model="condition.value"
                  filterable
                  size="small"
                  style="width: 65%"
                >
                  <el-option :value="false" label="FALSE" />
                  <el-option :value="true" label="TRUE" />
                </el-select>
                <el-autocomplete
                  class="value-field"
                  v-else-if="condition.valueType === 'JSON'"
                  style="width: 65%"
                  v-model="condition.value"
                  type="textarea"
                  rows="1"
                  size="small"
                  :fetch-suggestions="querySearch(condition)"
                />
                <el-select
                  v-else-if="condition.valueType === 'FORM'"
                  v-model="condition.value"
                  filterable
                  allow-create
                  default-first-option
                  size="small"
                  placeholder="Choose a property"
                  style="width: 65%; margin: 0 10px"
                >
                  <!-- this options list from contentNode.ts // contentNodeOptionsMixin -->

                  <el-option
                    v-for="(property, propertyIndex) in form.settings?.uiSchema['ui:order']"
                    :key="`condition-property-option-${propertyIndex}`"
                    :label="property"
                    :value="property"
                  ></el-option>
                </el-select>
                <el-input
                  v-else-if="condition.valueType === 'REGEX'"
                  v-model.trim="condition.value"
                  class="value-field"
                  spellcheck="false"
                  style="width: 65%"
                  size="small"
                />
                <el-autocomplete
                  v-else
                  class="value-field"
                  style="width: 65%"
                  v-model="condition.value"
                  size="small"
                />
              </div>
            </div>
            <div style="width: 100%; margin: 10px 0">
              <el-input placeholder="Please input validation message" v-model="condition.message">
                <template slot="prepend">Error Message</template>
              </el-input>
            </div>
          </div>

          <div>
            <el-button
              style="color: red; font-size: 16px"
              type="text"
              icon="el-icon-delete"
              size="mini"
              circle
              @click="removeCondition(index)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  contentNodeConditionMethodMixin,
  contentNodeConditionOptionsDataMixin,
  contentNodeConditionOptionsComputedMixin,
} from "@/mixins/contentNode";
export default {
  name: "FormCustomValidation",
  mixins: [
    contentNodeConditionOptionsDataMixin,
    contentNodeConditionMethodMixin,
    contentNodeConditionOptionsComputedMixin,
  ],
  data() {
    return {
      conditions: [],
    };
  },
  props: {
    form: Object,
  },
  methods: {
    addCondition() {
      this.conditions.push({
        property: "",
        value: "",
        operator: "",
        valueType: "JSON",
        message: "",
      });
    },
    filterValidOperator(valueType) {
      return this.conditionOperatorOptions.filter(
        (item) => !item.name.includes("exists") && (!valueType || item.valid.includes(valueType))
      );
    },
    changeOperator(condition, value) {
      condition.operator = value;
      if (value.includes("EXISTS")) {
        condition.valueType = "JSON";
        condition.value = "";
      }
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    changeValueType(condition, value) {
      condition.valueType = value;
      const operatorStillValid = this.conditionOperatorOptions.find(
        (item) => item.value === condition.operator && item.valid.includes(value)
      );
      if (!operatorStillValid) {
        condition.operator = "";
      }
      if (value === "STRING" || value === "JSON") {
        condition.value = "";
      }
      if (value === "NUMBER") {
        condition.value = 0;
      }
      if (value === "BOOLEAN") {
        condition.value = false;
      }
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    removeCondition(index) {
      this.conditions.splice(index, 1);
    },
    fixRegex(condition) {
      if (!/^\//.test(condition.value)) {
        condition.value = "/" + condition.value;
      }

      if (/^\/(:?\w|\W)+[^/|i]$/gim.test(condition.value)) {
        condition.value = condition.value + "/i";
      }
    },
  },
  created() {
    this.conditions = _.cloneDeep(this.form?.settings?.options?.conditions) || [];
    const formFieldIndex = this.conditionValueTypeOptions.findIndex((a) => a.value === "FORM");
    if (formFieldIndex === -1) {
      this.conditionValueTypeOptions.push({
        name: "FORM FIELD",
        value: "FORM",
      });
      this.conditionValueTypeOptions.push({
        name: "REGEX",
        value: "REGEX",
      });
    }
  },
  beforeDestroy() {
    this.$set(this.form.settings.options, "conditions", this.conditions);
  },
};
</script>

<style lang="scss">
.color_settings_body {
  overflow-y: auto;
  padding: 5px;
  max-height: 750px;
}
</style>
