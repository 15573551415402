import _ from "lodash";

const valueTypes = [
  {
    name: "AUTO",
    value: "JSON",
  },
  {
    name: "ABC",
    value: "STRING",
  },
  {
    name: "123",
    value: "NUMBER",
  },
  {
    name: "TRUE/FALSE",
    value: "BOOLEAN",
  },
  {
    name: "DATE",
    value: "DATE",
  },
];

const operators = [
  {
    name: "<",
    value: "<",
    valid: ["JSON", "NUMBER", "FORM", "DATE"],
  },
  {
    name: "=",
    value: "=",
    valid: ["JSON", "STRING", "NUMBER", "BOOLEAN", "FORM", "DATE"],
  },
  {
    name: ">",
    value: ">",
    valid: ["JSON", "NUMBER", "FORM", "DATE"],
  },
  {
    name: "not",
    value: "NOT",
    valid: ["JSON", "STRING", "NUMBER", "BOOLEAN", "FORM", "DATE"],
  },
  {
    name: "includes",
    value: "INCLUDES",
    valid: ["JSON", "STRING", "NUMBER", "FORM"],
  },
  {
    name: "not includes",
    value: "NOT INCLUDES",
    valid: ["JSON", "STRING", "NUMBER", "FORM"],
  },
  {
    name: "included in",
    value: "INCLUDED IN",
    valid: ["JSON", "STRING", "FORM"],
  },
  {
    name: "not included in",
    value: "NOT INCLUDED IN",
    valid: ["JSON", "STRING", "FORM"],
  },
  {
    name: "exists",
    value: "EXISTS",
    valid: ["JSON", "STRING", "NUMBER", "BOOLEAN"],
  },
  {
    name: "not exists",
    value: "NOT EXISTS",
    valid: ["JSON", "STRING", "NUMBER", "BOOLEAN"],
  },
];

const properties = [
  {
    name: "General",
    options: [
      {
        name: "Sentiment",
        value: "entities.SENTIMENT",
      },
      {
        name: "Message Channel",
        value: "source",
      },
      {
        name: "App Source",
        value: "appSource",
      },
      {
        name: "Role",
        value: "role",
      },
    ],
  },
  {
    name: "Geography",
    options: [
      {
        name: "Language",
        value: "entities.LANGUAGE",
      },
      {
        name: "Country",
        value: "entities.COUNTRY",
      },
      {
        name: "Country Code",
        value: "entities.COUNTRY_CODE",
      },
    ],
  },
  {
    name: "Date Time",
    entities: ["date"],
    options: [
      // Start
      {
        name: "Date (D [1-31])",
        value: "entities.DATE_START",
      },
      {
        name: "Day (dddd [Sunday-Saturday])",
        value: "entities.DATE_DAY_START",
      },
      {
        name: "Month (M [1-12])",
        value: "entities.DATE_MONTH_START",
      },
      {
        name: "Year (YYYY [2020])",
        value: "entities.DATE_YEAR_START",
      },
      {
        name: "Hour (H [0-23])",
        value: "entities.DATE_HOUR_START",
      },
      {
        name: "Minute (m [0-59])",
        value: "entities.DATE_MINUTE_START",
      },
      {
        name: "Timestamp (ms)",
        value: "entities.DATE_TIMESTAMP_START",
      },
    ],
  },
  {
    name: "Duration - Date Time",
    entities: ["date"],
    options: [
      {
        // Monday, Tuesday
        name: "Date End (D [1-31])",
        value: "entities.DATE_END",
      },
      {
        name: "Day End (dddd [Sunday-Saturday])",
        value: "entities.DATE_DAY_END",
      },
      {
        name: "Month End (M [1-12])",
        value: "entities.DATE_MONTH_END",
      },
      {
        name: "Year End (YYYY [2020])",
        value: "entities.DATE_YEAR_END",
      },
      {
        name: "Hour End (H [0-23])",
        value: "entities.DATE_HOUR_END",
      },
      {
        name: "Minute End (m [0-59])",
        value: "entities.DATE_MINUTE_END",
      },
      {
        name: "Timestamp End (ms)",
        value: "entities.DATE_TIMESTAMP_END",
      },
      // Duration
      {
        name: "Duration in Years",
        value: "entities.DURATION_YEARS",
      },
      {
        name: "Duration in Months",
        value: "entities.DURATION_MONTHS",
      },
      {
        name: "Duration in Weeks",
        value: "entities.DURATION_WEEKS",
      },
      {
        name: "Duration in Days",
        value: "entities.DURATION_DAYS",
      },
      {
        name: "Duration in Hours",
        value: "entities.DURATION_HOURS",
      },
      {
        name: "Duration in Minutes",
        value: "entities.DURATION_MINUTES",
      },
    ],
  },
  {
    name: "Zendesk",
    options: [
      {
        name: "Department Name",
        value: "zendesk_channel.department.name",
      },
      {
        name: "User Agent",
        value: "zendesk_channel.user_agent",
      },
      {
        name: "Device Type",
        value: "zendesk_channel.device_type",
      },
      {
        name: "Platform",
        value: "zendesk_channel.platform",
      },
      {
        name: "Visitor Phone Number",
        value: "zendesk_channel.visitor.phone",
      },
      {
        name: "Visitor Email",
        value: "zendesk_channel.visitor.email",
      },
      {
        name: "Visitor Display Name",
        value: "zendesk_channel.visitor.display_name",
      },
    ],
  },
];

export const contentNodeConditionOptionsPropsMixin = {
  props: {
    conditionOperatorOptions: {
      type: Array,
      default() {
        return operators;
      },
    },
    conditionPropertyOptions: {
      type: Array,
      default() {
        return properties;
      },
    },
    conditionValueTypeOptions: {
      type: Array,
      default() {
        return valueTypes;
      },
    },
  },
};

export const contentNodeConditionOptionsDataMixin = {
  data() {
    return {
      conditionOperatorOptions: operators,
      conditionPropertyOptions: properties,
      conditionValueTypeOptions: valueTypes,
    };
  },
};

export const contentNodeConditionOptionsComputedMixin = {
  computed: {
    entity() {
      return (this as any).$store.state.nodes.entity;
    },
    conditionPropertyOptionsDynamic() {
      const propOpts = (this as any).conditionPropertyOptions || [];

      const { entity } = this as any;

      const entityKeys = Object.keys(entity);
      const filtered = propOpts.filter(
        (o) => o.entities === undefined || _.intersection(entityKeys, o.entities).length
      );

      return [
        ...filtered,
        ...(entityKeys.filter((entityKey) => entity[entityKey].type === "list").length
          ? [
              {
                name: "Entities",
                options: Object.keys(entity)
                  .filter((entityKey) => entity[entityKey].type === "list")
                  .map((mapKey) => ({
                    name: mapKey,
                    value: `entities_last.${mapKey}`,
                  })),
              },
            ]
          : []),
      ];
    },
  },
};

export const contentNodeConditionMethodMixin = {
  methods: {
    createFilter(queryString) {
      return (keyword) => new RegExp(queryString).test(keyword.value);
    },
    showEntitySelector(condition) {
      const result =
        condition.property &&
        (condition.property.includes("entity") || condition.property.includes("entities")) &&
        ["INCLUDED IN", "NOT INCLUDED IN", "="].includes(condition.operator) &&
        !!this.getEntityList(condition);
      return result;
    },
    getEntityList(condition) {
      const { property } = condition;
      if (
        property &&
        (condition.property.includes("entity") || condition.property.includes("entities"))
      ) {
        const entityProperty = _.last(property.split("."));
        const entities = _.get(this, `entity.${entityProperty}.list`, null);
        return entities;
      }
      return null;
    },
    entityValues(condition) {
      const entityList = this.getEntityList(condition) || [];
      const result = _.map(entityList, (item, key) => ({ value: key, link: key }));
      return result;
    },
    querySearch(condition) {
      const keywords = this.entityValues(condition) || [];
      return (queryString, cb) => {
        if (queryString) {
          const results = keywords.filter(this.createFilter(queryString));
          cb(results);
        } else {
          cb([]);
        }
      };
    },
  },
};
