<template>
  <div>
    <el-button
      size="mini"
      @click="visible = true"
      type="text"
      style="line-height: 36px; padding: 0 20px"
    >
      Run Test
    </el-button>
    <el-dialog
      @opened="opened"
      @closed="closed"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :visible.sync="visible"
      title="Run Regression Test"
      width="50%"
      custom-class="test-panel-dialog"
      :append-to-body="true"
    >
      <section>
        <template v-if="!completed">
          <el-form status-icon ref="form" label-width="120px">
            <el-form-item label="Role">
              <el-input :disabled="busy" v-model="form.role" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="App Source">
              <el-input :disabled="busy" v-model="form.appSource" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                @click="runTest"
                :loading="busy"
                :disabled="busy"
                size="mini"
                type="primary"
                >Start</el-button
              >
              <el-button size="mini" :disabled="busy" @click="visible = false">Cancel</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-else>
          <table class="styled-table">
            <thead>
              <tr>
                <th>Action</th>
                <th>Result</th>
              </tr>
            </thead>
            <tbody>
              <tr :key="index" v-for="(result, index) in results">
                <td style="width: 80%">
                  <el-tag class="action-tag">
                    {{ result.action.event ? "postback: " + result.action.event : "message" }}
                  </el-tag>
                  {{
                    result.action.text ||
                    (typeof result.action.data === "string" ? result.action.data : "")
                  }}
                </td>
                <td>
                  <el-popover placement="top" title=" " trigger="hover" :disabled="result.passed">
                    <div>
                      <code-diff
                        :old-string="JSON.stringify(result.previous_answers, null, 2)"
                        :new-string="JSON.stringify(result.answers, null, 2)"
                        :context="10"
                      />
                    </div>
                    <el-button
                      size="mini"
                      type="success"
                      slot="reference"
                      :class="{ 'success-button': result.passed, 'failed-button': !result.passed }"
                      >{{ result.passed ? "PASSED" : "FAILED" }}
                      <i
                        style="margin-left: 10px"
                        :class="{ 'el-icon-check': result.passed, 'el-icon-close': !result.passed }"
                        class="el-icon-right"
                      ></i
                    ></el-button>
                  </el-popover>
                </td>
              </tr>
            </tbody>
          </table>

          <div style="text-align: center">
            <el-button
              @click="visible = false"
              :loading="busy"
              :disabled="busy"
              size="mini"
              type="primary"
              plain
              >Close</el-button
            >
          </div>
        </template>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import gql from "graphql-tag";
import { v4 as uuid } from "uuid";
import cuid from "cuid";
import axios from "axios";
import CodeDiff from "@/components/WebPageEditor/Editor/CodeDiff.vue";
export default {
  props: ["visitor"],
  components: { CodeDiff },
  data() {
    return {
      form: { appSource: null, role: null },
      results: null,
      visible: false,
      busy: false,
      completed: false,
      session_id: uuid() + "_smoketest",
      state_id: uuid() + "_smoketest",
    };
  },
  methods: {
    getWebchatEndpoint() {
      const baseUrl = window.location.hostname;
      return baseUrl.indexOf("localhost") > -1
        ? `http://${window.location.hostname}:3000/api/webchat`
        : `https://${window.location.hostname}/server/api/webchat`;
    },
    getWebchatPayload(interaction) {
      let payload = {};
      if (interaction.type === "message") {
        payload = {
          message: {
            id: cuid(),
            text: _.get(interaction, "data.content[0].text"),
            isPreview: false,
          },
        };
      } else {
        payload = {
          postback: {
            id: cuid(),
            data: _.get(interaction, "data.content[0].data"),
            event: _.get(interaction, "data.content[0].event"),
            isPreview: false,
            options: {},
            text: _.get(interaction, "data.content[0].postbackText"),
          },
        };
      }
      return {
        sender: {
          id: this.state_id,
          session_id: this.session_id,
          appSource: this.form.appSource,
          role: this.form.role,
        },
        recipient: {
          id: window.location.hostname,
        },
        session_length: 30,
        ...payload,
      };
    },
    async interact(interaction) {
      try {
        const endpoint = this.getWebchatEndpoint();
        const payload = this.getWebchatPayload(interaction);
        const response = await axios.post(endpoint, payload);
        return _.get(response, "data") || [];
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    opened() {
      this.session_id = uuid() + "_smoketest";
      this.state_id = uuid() + "_smoketest";
    },
    removeUserInformationFromUrl(url) {
      const urlsearchparams = new URLSearchParams(url || "");
      urlsearchparams.delete("sender_id");
      urlsearchparams.delete("recipient_id");
      return urlsearchparams.toString();
    },
    parseInteractionButtons(buttons) {
      if (!buttons && !Array.isArray(buttons)) {
        return [];
      }
      return buttons.map((b) => {
        return {
          ...b,
          data:
            typeof b.data === "object"
              ? { ...b.data, url: this.removeUserInformationFromUrl(b.data.url) }
              : b.data,
          url: this.removeUserInformationFromUrl(b.url),
        };
      });
    },
    parseInteraction(interaction) {
      return _.get(interaction, "data.content", _.get(interaction, "content", [])).map((c) => {
        const buttons = this.parseInteractionButtons(c.buttons);
        return _.pickBy(
          {
            text: c.text,
            subtext: c.subtext,
            url: c.url,
            buttons,
            images: c.images ? c.images : c.image ? [c.image] : [],
            event: c.event,
            data: c.data,
          },
          (i) => {
            if (Array.isArray(i)) return i.length > 0;
            return _.identity(i);
          }
        );
      });
    },
    async removeTestData() {
      return await this.$apollo.query({
        query: gql`
          query ($stateId: String) {
            removeSmokeTestData(stateId: $stateId)
          }
        `,
        fetchPolicy: "network-only",
        variables: {
          stateId: this.state_id,
        },
      });
    },
    async runTest() {
      if (!this.busy) {
        try {
          this.busy = true;
          const response = await this.$apollo.query({
            query: gql`
              query ($sessionId: String) {
                fetchInteractionsBySession(sessionId: $sessionId)
              }
            `,
            fetchPolicy: "network-only",
            variables: {
              sessionId: this.visitor.session_id,
            },
          });
          const interactions = _.get(response, "data.fetchInteractionsBySession") || [];
          const results = [];
          for (let i = 0; i < interactions.length; i++) {
            const interaction = interactions[i];
            if (interaction.type === "postback" || interaction.type === "message") {
              const result = {};
              const interactRes = await this.interact(interaction);
              const leftoverInteractions = interactions.slice(i + 1);
              const nextQuestionIndex = leftoverInteractions.findIndex(
                (j) => j.type === "postback" || j.type === "message"
              );
              result.action = _.first(this.parseInteraction(interaction));
              result.previous_answers = leftoverInteractions
                .slice(0, nextQuestionIndex > -1 ? nextQuestionIndex : leftoverInteractions.length)
                .map((j) => this.parseInteraction(j));
              result.answers = interactRes.map((j) => this.parseInteraction(j));
              result.passed =
                JSON.stringify(result.previous_answers) === JSON.stringify(result.answers);
              results.push(result);
            }
          }
          this.results = results;
          console.log(results);
          this.removeTestData();
        } catch (error) {
          console.log(error);
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `Something went wrong, please try again later.`,
          });
          this.visible = false;
        } finally {
          this.completed = true;
          this.busy = false;
        }
      }
    },
    closed() {
      this.results = [];
      this.interactions = [];
      this.form = { appSource: null, role: null };
      this.completed = false;
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/colors.scss";

.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.styled-table thead tr {
  background-color: $color-success;
  color: #ffffff;
  text-align: left;
}
.styled-table th {
  color: $color-dark;
}

.styled-table th,
.styled-table td {
  padding: 8px 10px;
}

.styled-table tbody tr {
  border-bottom: 1px solid $color-light;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: $color-light;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid $color-success;
}
.styled-table tbody tr.active-row {
  font-weight: bold;
  color: $color-success;
}
.success-button {
  background-color: $color-success;
  border-color: $color-success;
  font-weight: bold;
}
.failed-button {
  font-weight: bold;
  background-color: $color-danger;
  border-color: $color-danger;
}
.action-tag {
  font-weight: bold;
  margin-right: 10px;
  background-color: $color-dark;
  border-color: $color-dark;
  color: white;
}
.el-icon-right {
  font-weight: bold;
}
>>> .test-panel-dialog .el-dialog__body {
  padding-top: 0;
}
</style>
