<template>
  <div>
    <span
      style="margin-left: 1.5em; margin-right: 3em; fontsize: 14px; margin-bottom: 1em"
      class="has-text-grey"
    >
      {{ setting.label || name }}
    </span>
    <Tooltip :label="setting.label || name" :content="setting.detail" />
    <el-transfer
      style="display: inline-block; margin-left: 1.5em"
      v-model="value[name]"
      :titles="setting.titles"
      :data="setting.data"
      target-order="push"
      :filterable="true"
    ></el-transfer>
  </div>
</template>

<script>
import Tooltip from "../Tooltip.vue";
export default {
  components: { Tooltip },
  props: ["value", "setting", "name"],
  mounted() {
    if (this.value[this.name] == null) {
      if (this.setting.default != null) {
        this.$set(this.value, this.name, this.setting.default);
      } else {
        this.$set(this.value, this.name, null);
      }
    }
  },
};
</script>
