<template>
  <span>
    <div class="card-container">
      <el-divider content-position="left">Node Content</el-divider>
      <el-card
        :body-style="{ padding: 0, whitespace: 'normal', 'text-align': 'left' }"
        :style="{
          overflow: 'visible',
          width: '100%',
          'border-color': editable && !valid ? 'red' : 'lightgrey',
        }"
      >
        <div style="padding: 5px 10px" :style="validationStyle('text')">
          <div
            v-show="!edit_text"
            style="color: #323a4e"
            class="preview-text"
            name="text"
            @click="edit"
          >
            <p>{{ nodeContent.text || "Node Text" }}</p>
          </div>
          <el-input
            v-show="edit_text"
            ref="text_input"
            v-model="nodeContent.text"
            type="textarea"
            name="text"
            autosize
            required
            @keyup.enter="save"
            @blur="save"
          >
          </el-input>
        </div>
      </el-card>
      <el-divider content-position="left">Slider Config</el-divider>
      <div style="margin: 10px 0; display: none; text-align: left">
        <el-popover placement="top" trigger="click">
          <EventNodeSelector v-model="nodeContent.sliderConfig.event" />
          <el-button slot="reference">Event Setting</el-button>
        </el-popover>
      </div>
      <div style="margin: 10px 0">
        <div style="margin: 10px 0">
          <el-input
            placeholder="User text when confirm the slider"
            v-model="nodeContent.sliderConfig.nodeEvent.text"
          >
            <template slot="prepend"
              >Text
              <el-tooltip
                effect="dark"
                content="User text when confirm the slider "
                placement="top"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-input>
        </div>
        <el-input placeholder="Capture key" v-model="nodeContent.sliderConfig.nodeEvent.key">
          <template slot="prepend"
            >Key
            <el-tooltip
              effect="dark"
              content="Name of variable that the node capture"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </template>
        </el-input>
      </div>
      <div style="margin: 10px 0; text-align: left">
        <el-form>
          <TypeEventGoto :prependName="'Next'" v-model="nodeContent.sliderConfig.nodeEvent.next" />
        </el-form>
      </div>
      <div style="margin: 10px 0">
        <el-input placeholder="Slider Min Text" v-model="nodeContent.sliderConfig.minText">
          <template slot="prepend">Min</template>
        </el-input>
      </div>
      <div style="margin: 10px 0">
        <el-input placeholder="Slider Max Text" v-model="nodeContent.sliderConfig.maxText">
          <template slot="prepend">Max</template>
        </el-input>
      </div>
      <div style="margin: 10px 0; text-align: left">
        <span style="font-size: 14px; display: block">Main Color</span>
        <el-color-picker v-model="nodeContent.sliderConfig.mainColor"></el-color-picker>
      </div>
      <div style="margin: 10px 0; text-align: left">
        <span style="font-size: 14px; display: block">Total Units</span>
        <el-input-number
          size="small"
          style="margin: 10px 0"
          v-model="nodeContent.sliderConfig.units"
          :min="3"
        ></el-input-number>
      </div>
      <div v-if="nodeContent.sliderConfig.units > 0" style="margin: 10px 0; text-align: left">
        <span style="font-size: 14px; display: block">Slider Preview</span>
        <div class="slider-text">
          <span>{{ nodeContent.sliderConfig.minText || "MIN" }}</span>
          <span :style="sliderTextStyle">{{ nodeContent.sliderConfig.maxText || "MAX" }}</span>
        </div>
        <div class="slider-container">
          <span
            class="slider-item"
            v-for="(unit, index) in nodeContent.sliderConfig.units"
            :key="index"
            :style="sliderStyle(index)"
          ></span>
        </div>
      </div>
    </div>
  </span>
</template>
<script>
export default {
  name: "Slider",
  components: {
    TypeEventGoto: () => import("@/components/EventNodeSelector/Types/Goto"),
    EventNodeSelector: () => import("@/components/EventNodeSelector/Index"),
  },
  props: {
    editable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showButtons: {
      type: Boolean,
      default() {
        return true;
      },
    },
    date: {
      type: String,
    },
    index: {
      type: Number,
      default: 0,
    },
    nodeOptions: {
      default() {
        return {
          mode: "slider",
        };
      },
    },
    nodeContent: {
      default() {
        return {
          text: "",
          buttons: [],
          sliderConfig: {
            minText: "",
            maxText: "",
            nodeEvent: {
              event: "capture",
              next: {
                data: "",
                type: "event",
                event: "goto",
              },
              key: "",
              text: "",
            },
            event: {},
            units: 0,
            mainColor: "#4e6cce",
          },
        };
      },
    },
  },
  data() {
    return {
      edit_text: !this.nodeContent.text,
      edit_subtext: !this.nodeContent.subtext,
      edit_image: !this.nodeContent.image,
      edit_url: !this.nodeContent.url,
    };
  },
  computed: {
    valid_text() {
      return this.nodeContent.text && this.nodeContent.text.length > 0;
    },
    valid() {
      return this.valid_text;
    },
    text_chars() {
      return this.nodeContent.text ? this.nodeContent.text.length : 0;
    },
    exceed_text_chars() {
      return this.text_chars > 640;
    },
    sliderTextStyle() {
      return {
        color: this.nodeContent.sliderConfig.mainColor,
      };
    },
  },
  methods: {
    validationStyle(field) {
      return this["valid_" + field] ? null : { color: "red" };
    },
    edit({
      currentTarget: {
        attributes: {
          name: { value },
        },
      },
    }) {
      if (this.editable) {
        this["edit_" + value] = true;
        this.$nextTick(() => {
          window.test = this.$refs[value + "_input"].$el;

          if (value === "subtext") {
            this.$refs[value + "_input"].$el.querySelector("textarea").focus();
          } else {
            this.$refs[value + "_input"].$el.querySelector("input").focus();
          }
        });
      }
    },
    save({
      currentTarget: {
        attributes: {
          name: { value },
        },
      },
    }) {
      this["edit_" + value] = false;
      if (this.$parent.editCard) {
        this.$parent.editCard({
          slot: this.index,
          card: this.card,
        });
      }
    },
    sliderStyle(index) {
      return {
        opacity: (100 / this.nodeContent.sliderConfig.units) * (index + 1) + "%",
        background: this.nodeContent.sliderConfig.mainColor,
      };
    },
  },
};
</script>
<style scoped>
.card-container {
  vertical-align: top;
  width: 50%;
  display: inline-block;
  margin: auto 8px;
  white-space: normal;
  text-align: center;
}

.length-counter {
  float: right;
}

.preview-text {
  word-break: break-all;
  padding: 5px;
  border-radius: 8px;
  font-size: 14px;
}

.preview-text p {
  margin: 0;
}

.slider-container {
  display: flex;
  border-radius: 50px;
  overflow: hidden;
  width: 500px;
}

.slider-item {
  flex: 1;
  height: 8px;
}

.slider-text {
  width: 500px;
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  font-size: 12px;
  font-weight: bold;
}
</style>
