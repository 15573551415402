<template>
  <el-button
    icon="el-icon-camera"
    type="info"
    plain
    style="margin-left: 10px;"
    size="mini"
    :disabled="loading"
    :loading="loading"
    v-if="settings.enabled"
    v-on:click="handleClick"
  >Zoom meeting</el-button>
</template>

<script>
import _get from 'lodash.get';

export default {
  name: "ZoomMeetingButton",
  props: ["selectedChat"],
  data: function() {
    return {
      loading: false,
      meeting_id: null,
    };
  },
  computed: {
    settings () {
      return this.$store.state.modules.zoom;
    },
    meeting () {
      return this.$store.state.zoom.meetings[this.selectedChat.user_id];
    },
  },
  methods: {
    _get,
    async handleClick() {
      this.loading = true;
      try {
        const result = await this.$store.dispatch("ZOOM_MEETING_START", {
          chat: this.selectedChat,
          meeting_id: this.meeting_id,
        });
        this.meeting_id = result.id;
      } catch (err) {
        this.$notify.error({
          title: "Zoom meeting error",
          message: err.response?.data?.message || err.message,
          position: "bottom-right",
        });
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>
