import _ from "lodash";
import { COUNTRY_LIST } from "./countryList";
import { ExampleRecord, ExampleTrending, OrderUpdate } from "./type";

export const getPublishedExample = (examples: string): string | undefined => {
  const exampleRecord: ExampleRecord = JSON.parse(examples);
  if (_.isEmpty(exampleRecord)) return "";
  const publishedExample = exampleRecord[Object.keys(exampleRecord)[0]].examples.find(
    (example) => example.published
  );
  return publishedExample?.example;
};

export const getExampleByCountry = (examples: string, countryCode?: string): string | undefined => {
  if (!countryCode) return getPublishedExample(examples);
  const exampleRecord: ExampleRecord = JSON.parse(examples);
  if (!exampleRecord[countryCode]) return "";
  const publishedExample = exampleRecord[countryCode].examples.find((example) => example.published);
  return publishedExample?.example;
};

export const getTextButton = (data: string): string => {
  const button = JSON.parse(data);
  if (button.url) return button.text;
  return button.data;
};

export const getExamplesFromString = (exampleString?: string): ExampleTrending[] | [] => {
  return exampleString ? JSON.parse(exampleString) : [];
};

export const getExampleFromString = (example?: string): ExampleRecord | {} => {
  return example ? JSON.parse(example) : {};
};

export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const prepareExampleToSave = (
  countryKey: string,
  example: string,
  oldExample: string
): ExampleRecord => {
  const publishedExample: ExampleTrending = { example, published: true };
  const oldExamples: ExampleRecord = getExampleFromString(oldExample);
  if (_.isEmpty(oldExample)) {
    return {
      [countryKey]: {
        examples: [publishedExample],
        answer: "",
      },
    };
  }
  if (_.isEmpty(oldExamples[countryKey])) {
    oldExamples[countryKey] = {
      examples: [publishedExample],
      answer: "",
    };
    return oldExamples;
  }
  const checkExistExample = [...oldExamples[countryKey].examples].find(
    (eg) => eg.example.trim() === example.trim()
  );
  if (checkExistExample) {
    oldExamples[countryKey].examples = [
      ...[...oldExamples[countryKey].examples].map((eg) => ({
        ...eg,
        published: eg.example === example,
      })),
    ];
    return oldExamples;
  }
  oldExamples[countryKey].examples = [
    ...[...oldExamples[countryKey].examples].map((example) => ({ ...example, published: false })),
    publishedExample,
  ];
  return oldExamples;
};

export const prepareAnswerToSave = (
  answer: string,
  oldExample: string,
  countryKey: string
): ExampleRecord => {
  const oldExamples: ExampleRecord = getExampleFromString(oldExample);
  oldExamples[countryKey].answer = answer;
  return oldExamples;
};

export const getNameCountryFromExample = (countryCode: string): string => {
  const country = COUNTRY_LIST.find((country) => country.link === countryCode);
  return country ? country.value : countryCode;
};

export const getHeightElement = (
  coverClassName: string,
  extraClassName: string,
  offSet = 20
): number => {
  const extra = document.getElementsByClassName(extraClassName) as HTMLCollectionOf<HTMLElement>;
  const cover = document.getElementsByClassName(coverClassName) as HTMLCollectionOf<HTMLElement>;
  return Math.abs(cover[0].offsetHeight - extra[0].offsetHeight - offSet);
};

export const returnPublishSuccessMessage = (
  dataPublishTopic: OrderUpdate[],
  dataUpdateOrder: OrderUpdate[],
  dataRemove: number[]
): string => {
  let messageSuccess = "Trending topics";
  if (dataPublishTopic.length > 0) {
    messageSuccess += " published ";
  }
  if (dataUpdateOrder.length > 0) {
    messageSuccess += " sorted ";
  }
  if (dataRemove.length > 0) {
    messageSuccess += " removed ";
  }
  messageSuccess += " successfull";
  return messageSuccess;
};


export const checkCanPublishTopic=(intentId:string,examples=''):boolean=>{
  if (!examples) return true;
  const exampleRecord : ExampleRecord = JSON.parse(examples);
  const { url } = JSON.parse(intentId);
  if (url) return false;
  const existAnyAnswer = Object.values(exampleRecord).find(({ answer }) => answer);
  return _.isEmpty(exampleRecord) || !existAnyAnswer;
}

export const getIntentName = (intentId:string):{data:string,intentName:string}=>{
  const {data,intentName=''}= JSON.parse(intentId)
  return {
    data,
    intentName
  }
}
