<template>
  <el-table
    v-loading="apiLoading"
    :border="true"
    :show-header="false"
    :data="arrayApis"
    height="75vh"
    highlight-current-row
    ref="api-settings-table"
    size="mini"
    @row-click="showActiveApi"
  >
    <el-table-column prop="name" label="Name">
      <template slot-scope="scope">
        <div style="display: flex">
          <div style="width: 65px; padding: 5px 8px 5px 0px">
            <el-tag
              style="width: 56px; text-align: center"
              size="mini"
              effect="plain"
              :type="
                {
                  GET: 'success',
                  POST: 'warning',
                  DELETE: 'danger',
                  PUT: 'info',
                }[scope.row.method]
              "
            >
              {{ scope.row.method }}
            </el-tag>
          </div>
          <span>
            {{ scope.row.name }}
          </span>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  components: {},
  props: ["apiLoading", "arrayApis"],
  data() {
    return {
      dialogVisible: false,
      selectedDeleteApiName: "",
    };
  },
  methods: {
    showDeleteApiDialog(props) {
      this.$emit("show-delete-api-dialog", props);
    },
    showActiveApi(api) {
      this.$emit("show-api-editor", api.name);
    },
  },
};
</script>
