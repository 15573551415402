<template>
  <el-popover
    placement="top"
    title="Side Effect"
    width="500"
    trigger="click"
    v-model="isSideEffectPopoverOpened"
  >
    <div style="text-align: center">
      <el-autocomplete
        v-model="answer.sideEffect.event"
        class="autocomplete-list"
        size="mini"
        placeholder="Enter an event here..."
        style="margin-top: 0; width: 60%"
        :fetch-suggestions="getEventNodes"
      >
        <div slot="prepend">Event</div>
      </el-autocomplete>
    </div>

    <template v-if="sideEffectIsNotEmpty">
      <el-divider>
        <small class="has-text-dark">
          <i>Configurations</i>
        </small>
      </el-divider>

      <component :side-effect="answer.sideEffect" :is="getComponent"></component>
    </template>

    <el-button plain type="primary" icon="el-icon-setting" size="mini" slot="reference">
      Configure
    </el-button>
  </el-popover>
</template>

<script>
import _ from "lodash";
import EventDataEditor from "./EventDataEditor";
import Api from "./Api";
import Capture from "./Capture";

export default {
  props: ["activeFlow", "answer"],
  components: {
    EventDataEditor,
    Api,
    Capture,
  },
  data() {
    return {
      isSideEffectPopoverOpened: false,
    };
  },
  computed: {
    sideEffectIsNotEmpty() {
      return !_.isEmpty(this.answer.sideEffect.event);
    },
    getComponent() {
      let component = null;
      switch (this.answer.sideEffect.event) {
        case "callApi":
          component = "Api";
          break;
        case "capture":
          component = "Capture";
          break;
        default:
          component = "EventDataEditor";
      }

      return component;
    },
  },
  methods: {
    getEventNodes(value, cb) {
      // important! events should only be side effects related, should not have any goTo or capture functions
      const events = this.$store.state.search.event;
      const fileteredEvents = this.$store.state.search.event;
      if (value) {
        cb(events.search(value));
      }
    },
  },
};
</script>
