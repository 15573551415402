import _ from "lodash";

const ctx: Worker = self as any;

export const WorkerEvent = {
  GET_CONNECTED_NODES: "GET_CONNECTED_NODES",
};

ctx.onmessage = ({ data }) => {
  const { type, payload } = data;
  if (type === WorkerEvent.GET_CONNECTED_NODES) {
    ctx.postMessage({
      type: "SET_CONNECTED_NODES_BUSY",
      payload: true,
    });

    // Send result back to the main-thread
    ctx.postMessage({
      type: "SET_CONNECTED_NODES_DATA",
      payload: {
        apis: payload.apiMappings
          .filter((i) => JSON.stringify(i).indexOf(payload.nodeId) > -1)
          .map((t) => ({ value: t.api, label: t.api })),
        intents: payload.intents
          .filter((i) => JSON.stringify(i).indexOf(payload.nodeId) > -1)
          .map((i) => ({ value: i.id, label: i.intentName })),
        contents: Object.values(payload.nodes.content)
          .filter(
            (c: any) => c.id !== payload.nodeId && JSON.stringify(c).indexOf(payload.nodeId) > -1
          )
          .map((c: any) => ({ value: c.id, label: c.id })),
        triggers: _.map(payload.nodes.trigger, (i, k) => ({ id: k, ...i }))
          .filter((t) => JSON.stringify(t).indexOf(payload.nodeId) > -1)
          .map((t) => ({ value: t.id, label: t.id })),
      },
    });

    ctx.postMessage({
      type: "SET_CONNECTED_NODES_BUSY",
      payload: false,
    });
  }
};
