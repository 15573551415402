import { GetterTree } from "vuex/types";
import { RootState } from "@/store/types";
import { FlowEditorState } from "./types";

const getters: GetterTree<FlowEditorState, RootState> = {
  isFetchingFlows(state) {
    return state.fetchingFlows;
  },
  sections(state, getters, RootState) {
    const flowsObject = state.flows;
    const allFlows = Object.values(flowsObject);
    const sections = allFlows.map((flow) => {
      return flow.sections;
    });
    return sections;
  },
  flowContent(state, getters, RootState) {
    const content = state.contents;
    return content;
  },
};

export default getters;
