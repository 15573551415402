import { GetterTree } from "vuex/types";
import { RootState } from "@/store/types";
import { WatsonState } from "./types";

const getters: GetterTree<WatsonState, RootState> = {
  watsonIntents: (state, getters, rootState) => {
    return state.intents;
  },
  watsonEntities: (state, getters, rootState) => {
    return state.entities;
  },
  watsonDialogNodes: (state, getters, rootState) => {
    return state.dialogNodes;
  },
  watsonisEditedObject: (state, getters, rootState) => {
    return state.isEditedObject;
  },
};

export default getters;
