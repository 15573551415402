<template>
  <App id="keyreply" v-if="initialized" @closeInit="closeInit" />
  <div class="logo" v-else>
    <img :src="require('./assets/logo.png')" width="64" height="64" />
  </div>
</template>
<script>
import App from "./App.vue";
import { loadGraphQL } from "@/store/api";
import { Vue } from "@/bootstrap";
import VueApollo from "vue-apollo";
import { checkAutoSessionLocalStorage, onInactiveSession } from "@/helperMethods/autoSession";

export default {
  components: {
    App,
  },
  data() {
    return {
      initialized: false,
    };
  },
  methods: {
    onInactiveSession,
    refreshPage() {
      window.location.reload();
    },
    closeInit(isFocus) {
      this.initialized = false;
      if (!isFocus) {
        this.$nextTick(() => {
          this.refreshPage();
        });
      }
    },
    loadApp() {
      const graph = loadGraphQL();
      const apolloProvider = new VueApollo({
        defaultClient: graph,
      });
      Vue.use(VueApollo);
      Vue.prototype.$apolloProvider = apolloProvider;
      // checking auto session config expired
      const loggedOut = checkAutoSessionLocalStorage();
      if (loggedOut) {
        this.onInactiveSession();
      } else {
        this.initialized = true;
      }
    },
  },
  computed: {
    isAuthenticated() {
      return this.$auth.isAuthenticated;
    },
  },
  watch: {
    isAuthenticated: async function (authenticated) {
      if (authenticated) {
        this.loadApp();
      }
    },
  },
  created() {
    if (this.$auth.isAuthenticated) {
      this.loadApp();
    }
  },
};
</script>

<style scoped>
.logo {
  display: flex;
  place-content: center;
  place-items: center;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.logo img {
  transition: all ease-in-out 0.25s;
}
</style>
