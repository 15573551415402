<template>
  <div>
    <i v-if="!isRedirectBotEnabled"
      >Bot redirect not enabled in settings. Press
      <a @click="goToSettings" class="has-text-primary">here</a> to enable it now!</i
    >

    <i v-else
      >Press <a @click="goToSettings" class="has-text-primary">here</a> to add more bot options!</i
    >

    <br />
    <br />

    <p>Select bot to redirect:</p>

    <el-select
      style="width: 300px"
      v-model="value.data.value"
      filterable
      placeholder="Select an option"
    >
      <el-option v-for="item in options" :key="item.name" :label="item.name" :value="item.name">
      </el-option>
    </el-select>

    <br />
    <br />

    <EventNodeSelectorNext v-model="value" />
  </div>
</template>

<script>
import _ from "lodash";
import EventNodeSelectorNext from "../Next.vue";

export default {
  name: "EventNodeTypeRedirectBot",
  components: {
    EventNodeSelectorNext,
  },
  props: ["value"],
  methods: {
    goToSettings() {
      this.$router.push("/settings?active=webchat&scroll_to=module-editor-webchat-botRedirect");
    },
  },
  computed: {
    options() {
      return Object.values(_.get(this.$store.state, "modules.webchat.botRedirect.configs") || {});
    },
    isRedirectBotEnabled() {
      return _.get(this.$store.state, "modules.webchat.botRedirect.enabled") || false;
    },
  },
};
</script>

<style scoped lang="scss"></style>
