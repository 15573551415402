<template>
  <div
    style="display: flex; flex-flow: row wrap; justify-content: flex-end; margin: 0 16px 10px 16px"
  >
    <el-button
      plain
      v-for="(reply, index) in content"
      v-text="getText(reply)"
      :key="index"
      :type="buttonOutline"
      :disabled="!isPreview"
      rel="noopener"
      size="mini"
      style="margin: 0 5px 5px 0; border-radius: 15px"
      target="_blank"
      @click.stop.prevent.native="sendPostback(reply)"
    />
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: ["content", "buttonOutline", "isPreview"],
  methods: {
    getText(button) {
      const buttonText = _.get(button, "text", button.title); // Default is 'text', else facebook is 'title'
      return buttonText;
    },
    sendPostback(reply) {
      this.$emit("sendPostback", reply);
      this.$forceUpdate();
    },
  },
};
</script>
