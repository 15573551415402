<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: {
    datasets: {
      default() {
        return [];
      },
    },
    labels: {
      default() {
        return [];
      },
    },
    options: {
      default() {
        return {};
      },
    },
  },
  mounted() {
    this.renderChart(
      {
        labels: this.labels,
        datasets: this.datasets,
      },
      this.options
    );
  },
};
</script>
