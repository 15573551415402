<!-- This component refers to @/src/components/ModuleEditor/index.vue -->
<template>
  <div>
    <!-- SAVE BUTTON -->
    <el-row>
      <el-col :offset="20" :span="4">
        <el-button
          style="width: 100%"
          type="primary"
          :loading="saving"
          :disabled="!valid"
          @click="reviewChanges"
        >
          Save
        </el-button>
      </el-col>
    </el-row>

    <!-- CONFIG FIELDS -->
    <smart-field-handoveragentchatlimitperuser
      v-model="modules['handover']"
      :name="'chatLimitPerAgent'"
      :setting="setting"
      :modules="modules"
    ></smart-field-handoveragentchatlimitperuser>

    <!-- CONFIRMATION POPUP -->
    <el-dialog title="Confirmation" :visible.sync="confirmDialog">
      <div v-if="havingChanges" v-html="getChanges()" class="changes-wrapper"></div>
      <div
        v-else
        class="changes-wrapper"
        style="place-content: center; place-items: center; display: grid"
      >
        No change has been made to the Settings
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="confirmDialog = false">Cancel</el-button>
        <el-button size="mini" type="primary" v-if="havingChanges" @click="saveChanges"
          >Save</el-button
        >
      </span>
    </el-dialog>

    <!-- JSON ON KONAMI MODE -->
    <JSONEditor
      v-if="$store.state.showAdvanced"
      v-model="modules"
      @valid="valid = true"
      @invalid="valid = false"
    />
  </div>
</template>

<script>
import * as jsondiffpatch from "jsondiffpatch/dist/jsondiffpatch.umd";
import _ from "lodash";
import JSONEditor from "@/components/JSONEditor";
import SmartFieldHandoveragentchatlimitperuser from "@/components/ModuleEditor/SmartFields/Custom/HandoverAgentChatLimitPerUser.vue";

export default {
  components: {
    JSONEditor,
    SmartFieldHandoveragentchatlimitperuser,
  },
  data() {
    return {
      saving: false,
      valid: true,
      confirmDialog: false,
      originalModules: {},
      setting: {
        default: [],
        label: "Chat Limit per Agent",
        type: "HandoverAgentChatLimitPerUser",
      },
      modules: this.$store.state.modules,
    };
  },
  computed: {
    havingChanges() {
      return !_.isEqual(this.originalModules, this.modules);
    },
  },
  methods: {
    saveOriginalModules() {
      this.originalModules = _.cloneDeep(this.modules);
    },
    toComponent(setting) {
      const type = _.capitalize(setting.type);
      const componentName = `SmartField${type}`;
      const isComponentExist = _.has(this.$options.components, componentName);
      return isComponentExist ? componentName : null;
    },
    getChanges() {
      jsondiffpatch.formatters.html.hideUnchanged();

      const delta = jsondiffpatch.diff(this.originalModules, this.modules);
      const changes = jsondiffpatch.formatters.html.format(delta, this.originalModules);
      return changes;
    },
    reviewChanges(event) {
      this.confirmDialog = true;
    },
    saveChanges(event) {
      this.confirmDialog = false;
      this.saving = true;

      this.$store
        .dispatch("SAVE_MODULES", {
          modules: this.modules,
        })
        .then(
          () => {
            this.saving = false;
            this.$message({
              type: "success",
              message: "Config Saved",
            });
            // sync up two variables after saving
            this.saveOriginalModules();
          },
          () => {
            this.saving = false;
            this.$message({
              type: "error",
              message: "Error saving config",
            });
          }
        );
    },
  },
  mounted() {
    this.saveOriginalModules();
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";
@import "~jsondiffpatch/dist/formatters-styles/html.css";

.is-highlighted,
.is-highlighted .el-card,
.is-highlighted .el-card .el-table {
  background-color: lighten($color-primary, 45);
}

.changes-wrapper {
  margin: 20px;
  padding: 10px;
  border: 2px solid rgba(0, 0, 0, 0.05);
  background: #fdfdfd;
  border-radius: 4px;
  overflow: auto;
}
</style>
