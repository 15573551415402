import { doesLinkExist } from "@/helperMethods/editor";

export function validateButtonOrQuickReply(button: any) {
  if (!button) {
    return false;
  }
  const { url, phone, event, text, data, type = "event" } = button;
  let isInvalid = false;

  if (!text) return true;

  // Remove button length limit
  // if (text.length > 20) return true;

  if (type) {
    switch (type) {
      case "event":
        if (!event) isInvalid = true;
        if (event && !data) {
          isInvalid = !doesLinkExist(event);
        }
        if (event === "capture") {
          if (!data) {
            isInvalid = true;
          } else {
            const { key, value, next } = data;
            if (!key || value === undefined || value === "") {
              isInvalid = true;
            } else {
              if (typeof next === "string") {
                isInvalid = !doesLinkExist(next);
              } else {
                if (!next) {
                  isInvalid = true;
                } else {
                  const { event: nextEvent, data: nextData } = next;
                  isInvalid = nextEvent === undefined || nextData === undefined;
                }
              }
            }
          }
        } else if (event === "startflow") {
          if (!data) {
            isInvalid = true;
          } else {
            const { id } = data;
            if (!id) {
              isInvalid = true;
            }
          }
        } else if (event === "goto") {
          isInvalid = !doesLinkExist(data);
        }
        break;

      case "microsite":
        if (!data) isInvalid = true;
        break;
      case "website":
        if (!url) isInvalid = true;
        break;
      case "phone":
        if (!phone) isInvalid = true;
        break;
      case "action":
        isInvalid = true;
        break;
    }
  } else {
    isInvalid = true;
  }
  return isInvalid;
}
export function isInvalid(button: any, index: any) {
  // @ts-ignore
  const form = this.$refs[`form${index}`];
  if (form && form.length) {
    form[0]
      .validate()
      .then((valid: boolean) => valid)
      .catch((e: any) => false);
  }
  return validateButtonOrQuickReply(button);
}
export default {
  methods: {
    isInvalid,
  },
  data() {
    return {
      rules: {
        text: [
          {
            required: true,
            message: "Please add a Button Text",
            trigger: "blur",
          },
        ],
        url: [
          {
            required: true,
            validator: validateURL,
            trigger: "change",
          },
        ],
        phone: [
          {
            required: true,
            validator: validatePhone,
            trigger: "change",
          },
        ],
      },
    };
  },
};

export const validateURL = (rule: string, value: string, callback: Function) => {
  const isEmpty = value === "";
  const isURL =
    /^((https?:\/\/)|(mailto:))?([a-zA-Z0-9]+[.])+[a-zA-Z]{2,4}(:\d+)?(\/[~_.\-a-zA-Z0-9=&%@:]+)*\??[~_.\-a-zA-Z0-9=&%@:]*$/.test(
      value
    );
  if (isEmpty) {
    callback(new Error("URL is required"));
  } else if (!isURL) {
    callback(new Error("URL is invalid"));
  } else {
    callback();
  }
};

export const validatePhone = (rule: string, value: string, callback: Function) => {
  const isEmpty = !value;
  if (isEmpty) {
    callback(new Error("Phone number is required"));
  } else {
    const hasPlusPrefix = value.charAt(0) === "+";
    const containsOnlyDigits = /^\+\d+$/.test(value);
    if (!hasPlusPrefix) {
      callback(new Error('Phone number must have "+" prefix'));
    } else if (!containsOnlyDigits) {
      callback(new Error("Phone number must contain digits"));
    } else {
      callback();
    }
  }
};
