<template>
  <el-dropdown ref="dropdown" trigger="click">
    <el-tooltip class="item" effect="dark" content="Move Question" placement="top">
      <el-button style="margin: 0 10px" type="text" size="mini" icon="el-icon-top-right" />
    </el-tooltip>
    <el-dropdown-menu slot="dropdown">
      <el-cascader-panel @change="onMove" :options="flowOptions"></el-cascader-panel>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  props: ["flowOptions", "question"],
  methods: {
    onMove(event) {
      this.$confirm("Move question from current section?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          const _question = _.cloneDeep(this.question);
          _question.sectionId = event[1];
          _question.flowId = event[0];
          this.$emit("deleteQuestion");
          this.$eventBus.$emit("moveQuestion", _question);
        })
        .finally(() => {
          if (this.$refs.dropdown) {
            this.$refs.dropdown.hide();
          }
        });
    },
  },
};
</script>
