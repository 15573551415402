import _ from "lodash";
import qs from "query-string";
import { GraphNode } from "./type";
import moment from "moment";

const shortcuts = [
  {
    text: "Today",
    onClick(picker: any) {
      const end = new Date();
      const start = new Date();
      picker.$emit("pick", [start, end]);
    },
  },
  {
    text: "Yesterday",
    onClick(picker: any) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24);
      picker.$emit("pick", [start, end]);
    },
  },
  {
    text: "Last week",
    onClick(picker: any) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      picker.$emit("pick", [start, end]);
    },
  },
  {
    text: "Last month",
    onClick(picker: any) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      picker.$emit("pick", [start, end]);
    },
  },
  {
    text: "Last 3 months",
    onClick(picker: any) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      picker.$emit("pick", [start, end]);
    },
  },
  {
    text: "Last 6 months",
    onClick(picker: any) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
      picker.$emit("pick", [start, end]);
    },
  },
  {
    text: "Last year",
    onClick(picker: any) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
      picker.$emit("pick", [start, end]);
    },
  },
];
const firstSetOfShorcuts = [
  {
    text: "Tomorrow",
    onClick(picker: any) {
      const date = new Date();
      date.setTime(date.getTime() + 3600 * 1000 * 24);
      picker.$emit("pick", date);
    },
  },
  {
    text: "A week later",
    onClick(picker: any) {
      const date = new Date();
      date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
      picker.$emit("pick", date);
    },
  },
  {
    text: "A month later",
    onClick(picker: any) {
      const date = new Date();
      date.setTime(date.getTime() + 3600 * 1000 * 24 * 30);
      picker.$emit("pick", date);
    },
  },
];
const secondSetOfShortcuts = [
  {
    text: "Tomorrow",
    onClick(picker: any) {
      const today = moment();
      const date = today.add(1, "day").toDate();
      picker.$emit("pick", date);
    },
  },
  {
    text: "A week later",
    onClick(picker: any) {
      const today = moment();
      const date = today.add(1, "week").toDate();
      picker.$emit("pick", date);
    },
  },
  {
    text: "A month later",
    onClick(picker: any) {
      const today = moment();
      const date = today.add(1, "month").toDate();
      picker.$emit("pick", date);
    },
  },
];

export const pickerOptionsForFutureDatesOnly = {
  disabledDate(time: Date) {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return time.getTime() < yesterday.getTime();
  },
  shortcuts: firstSetOfShorcuts,
};

export const pickerOptionsForFutureDatesVersionII = {
  disabledDate(time: Date) {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return time.getTime() < yesterday.getTime();
  },
  shortcuts: secondSetOfShortcuts,
};

export const defaultPickerOptions = {
  shortcuts,
};

type ApolloErrorCode = "FORBIDDEN" | "UNAUTHENTICATED" | "GRAPHQL_VALIDATION_FAILED";

export function getMessageByCode(errorCode: ApolloErrorCode, message: string): string {
  let finalMessage = message;
  switch (errorCode) {
    case "FORBIDDEN":
      finalMessage = "401: User does not have permissions";
      break;
    case "UNAUTHENTICATED":
      finalMessage = "401: User is not authenticated";
      break;
    case "GRAPHQL_VALIDATION_FAILED":
      finalMessage = "Encountered GRAPHQL_VALIDATION_FAILED error";
      break;
  }
  return finalMessage;
}

export function isWorkPermitValid(workPermit: string): boolean {
  if (!workPermit || workPermit.length != 10) return false;
  const digits = "1234567890".split("");

  for (let i = 0; i < 10; i++) {
    const char = workPermit.charAt(i);

    // second character must be whitespace, if not return false
    if (i === 1) {
      if (char !== " ") return false;
    } else if (i === 9) {
      const lastCharCheck = digits.includes(char) || char === "-";
      if (!lastCharCheck) return false;
    } else if (!digits.includes(char)) {
      return false;
    }
  }
  return true;
}

export function isNricValid(nric: string): boolean {
  const isValidLength = nric?.length === 9;
  const firstLetter = nric ? nric[0]?.toUpperCase() : "";
  const isValidFirstLetter = ["S", "T", "G", "F", "M"].includes(firstLetter);

  if (!isValidLength || !isValidFirstLetter) {
    return false;
  }

  const lastLetter = nric[8].toUpperCase();
  const nricCheckDigits = "JZIHGFEDCBA";
  const finCheckDigits = "XWUTRQPNMLK";
  const mFinCheckDigits = "KLJNPQRTUWX";
  const digits = nric.slice(1, 8);
  const weights = [2, 7, 6, 5, 4, 3, 2];
  let sum = 0;

  if (isNaN(+digits)) {
    return false;
  }
  if (["T", "G"].includes(firstLetter)) {
    sum += 4;
  }
  for (let i = 0; i < digits.length; i++) {
    sum += parseInt(digits.charAt(i), 10) * weights[i];
  }
  if (["S", "T"].includes(firstLetter)) {
    return nricCheckDigits.charAt(sum % 11) === lastLetter;
  }
  // m-series FIN
  if (firstLetter === "M") {
    // 1. increase sum by the weightage (3)
    sum += 3;
    // 2. calculate remainder, R1 = remainder of sum / 11
    const remainder = sum % 11;
    // 3. calculate check digit number, P = 11 - R1
    const checkDigit = 11 - remainder;
    // 4. minus P by 1, because M-series digit mapping formula is started by index 1
    const checkDigitIndex = checkDigit - 1;

    return mFinCheckDigits.charAt(checkDigitIndex) === lastLetter;
  }
  return finCheckDigits.charAt(sum % 11) === lastLetter;
}

export function convertNodesToG6(nodes: GraphNode) {
  if (!_.isObject(nodes)) {
    return [];
  }

  const result: any = { nodes: [], edges: [] };
  const sortedKeys = _.keys(nodes);
  const slicedKeys = _.slice(sortedKeys, 0, 10);

  let x = 200,
    y = 100,
    i = 0;

  _.forEach(slicedKeys, (key: string) => {
    const node: any = nodes[key];
    const edges = getEdges(node);
    const next = edges[0];

    const nodeObject = {
      id: key,
      x,
      y,
      label: key !== "conversation_start" && key !== "conversation_end" ? key : "",
      clazz:
        key === "conversation_start"
          ? "startKr"
          : key === "conversation_end"
          ? "endKr"
          : "basicStateKr",
    };

    result.nodes.push(nodeObject);

    if (node.next) {
      const edge = {
        source: key,
        target: node.next,
        sourceAnchor: 1,
        targetAnchor: 0,
        clazz: "flow",
      };
      result.edges.push(edge);
    }

    // check every 10th row, it will create increment x and reset y
    if (i % 10 === 0 && i !== 0) {
      x = x + 300;
      y = 0;
    }

    // increment y
    y = y + 150;

    i++;
  });

  return result;
}

function getEdges(node: object) {
  const edges: any[] = [];

  edges.push(_.get(node, "next"));

  edges.push(_.get(node, "content.buttons.event"));
  edges.push(_.get(node, "content.buttons.data"));
  edges.push(_.get(node, "quickReplies.event"));
  edges.push(_.get(node, "quickReplies.data"));

  return edges;
}

export function isFunction(fn: any) {
  return typeof fn === "function";
}

export function getPlaceholderImage(width: number, height: number, text: string) {
  const imageText = text.replace(/\s/g, "+");
  return `https://via.placeholder.com/${width}x${height}?text=${imageText}`;
}

export function getDefaultPlaceholderImage() {
  return getPlaceholderImage(310, 160, "Add Image");
}

const isLocal = () => /^(0.0.0.0|127.0.0.*|localhost)$/.test(location.hostname);
const isLocalNetwork = () => /^(192.168.\d+.\d+)$/.test(location.hostname);
const isLocalhost = () => {
  return isLocal() || isLocalNetwork();
};

export { isLocal, isLocalhost, isLocalNetwork };

export const formatToken = (token = "") => {
  if (token) {
    return `Bearer ${token}`;
  } else {
    return "";
  }
};

export const updateUrlQuery = (query) => {
  if (window.history.pushState) {
    const validQuery = _.pickBy(query, _.identity);
    const newurl = window.location.href.split("?")[0] + "?" + qs.stringify(validQuery);
    window.history.pushState({ path: newurl }, "", newurl);
  }
};
