import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import state from "./state";
import { Module } from "vuex/types";
import { MakerCheckerState } from "./types";
import { RootState } from "@/store/types";

const makerChecker: Module<MakerCheckerState, RootState> = {
  // namespaced: true,
  getters,
  state,
  actions,
  mutations,
};

export default makerChecker;
