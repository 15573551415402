import { MutationTree } from "vuex/types";
import Vue from "vue";
import { ApiContent, ApiEditorState, Apis, Mappings } from "./types";

const mutations: MutationTree<ApiEditorState> = {
  SET_APIS(state, payload: Apis) {
    Vue.set(state, "apis", payload);
  },
  SET_API_MAPPINGS(state, payload: Mappings) {
    Vue.set(state, "mappings", payload);
  },
  SET_API_CONTENT(state, payload: ApiContent) {
    Vue.set(state, "contents", payload);
  },
  DELETE_API(state, payload) {
    Vue.delete(state.apis, payload);
  },
  CREATE_API_MAPPING(state, payload) {
    Vue.set(state.mappings, payload.name, payload);
  },
  DELETE_API_MAPPING(state, payload) {
    Vue.delete(state.mappings, payload);
  },
};

export default mutations;
