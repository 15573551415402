<template>
  <el-row :gutter="16">
    <el-col :md="10">
      <el-card class="preview-card">
        <iframe ref="frame" frameborder="0" style="height: 90vh; width: 100%" />
      </el-card>
    </el-col>
    <el-col :md="12">
      <el-card class="widget-color-setting">
        <div slot="header" style="display: flex; justify-content: space-between">
          <h3 style="margin: 0">Colors</h3>
          <div>
            <el-select size="mini" v-model="modules.webchat.ui_beta" placeholder="Select">
              <el-option label="Version 2" :value="false" />
              <el-option label="Version 3" :value="true" />
            </el-select>
            <el-button
              type="success"
              plain
              style="margin-left: 10px"
              size="mini"
              :loading="saving"
              :disabled="!valid"
              @click="saveChanges"
            >
              Save
            </el-button>
          </div>
        </div>
        <div class="form-field-group">
          <div v-for="(setting, key) in webchatThemeConfig" :key="key" :required="setting.required">
            <component
              v-model="modules.webchat"
              :name="key"
              :setting="setting"
              :is="toComponent(setting)"
              :modules="modules"
            ></component>
          </div>
        </div>
      </el-card>
    </el-col>
    <el-col :md="23">
      <el-card class="embed-card">
        <div slot="header" class="widget-header">
          <div>
            <h3>Embed Code</h3>
          </div>
          <div style="float: right">
            <el-select
              v-model="loadSettings"
              size="mini"
              style="margin-right: 8px"
              placeholder="Select"
              @change="changeLoadSettings"
              filterable
            >
              <el-option
                v-for="embedSetting in embedCodeList"
                :key="embedSetting.name"
                :label="embedSetting.name"
                :value="embedSetting.name"
              />
            </el-select>

            <a rel="noopener" target="_blank" :href="previewURL" style="margin-right: 8px">
              <el-button size="mini" icon="el-icon-view" type="primary" plain
                >Open in new Tab</el-button
              >
            </a>

            <el-popover
              placement="bottom"
              title="Tip: Drag this onto the bookmark bar"
              trigger="hover"
              content="Visit a webpage and click the new bookmark"
            >
              <a slot="reference" :href="bookmarklet" style="margin-right: 8px">
                <el-button
                  size="mini"
                  icon="el-icon-bookmark"
                  type="primary"
                  disabled
                  plain
                  @click.stop.prevent
                  >Bookmark</el-button
                >
              </a>
            </el-popover>

            <el-tooltip
              effect="dark"
              content="Launch compact & persistent web widget that stay open even navigate to different pages."
              placement="top"
            >
              <el-button
                size="mini"
                icon="el-icon-open"
                type="info"
                plain
                @click="launchStickyWidget"
                >Launch Mini Widget</el-button
              >
            </el-tooltip>

            <el-tooltip effect="dark" content="Run code as authrorized user" placement="top">
              <el-button
                v-if="authorizeWidgetPermitted"
                size="mini"
                style="float: right"
                :loading="saving"
                icon="el-icon-check"
                :plain="true"
                type="success"
                @click="onCodeSave(true)"
                :disabled="!authorize.token"
              >
                Run as authorized user
              </el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="Run code" placement="top">
              <el-button
                size="mini"
                style="float: right"
                :loading="saving"
                icon="el-icon-check"
                :plain="true"
                type="success"
                @click="onCodeSave(false)"
              >
                Run
              </el-button>
            </el-tooltip>
          </div>
        </div>
        <codemirror v-model="customEmbedCode" :options="editorOptions" @input="onCodeChange" />
      </el-card>
      <el-card v-if="authorizeWidgetPermitted" class="embed-card">
        <div slot="header" class="widget-header">
          <div>
            <h3>Authorize Widget (Post-Login)</h3>
          </div>
          <div style="float: right">
            <el-row style="display: flex; justify-content: end; align-items: center">
              <el-select
                v-model="payloadSetting"
                size="mini"
                style="margin-right: 8px"
                placeholder="Select"
                @change="changeAuthorizedPayloadSettings"
                filterable
              >
                <el-option
                  v-for="embedSetting in embedAuthorizedWidgetList"
                  :key="embedSetting.name"
                  :label="embedSetting.name"
                  :value="embedSetting.name"
                />
              </el-select>

              <el-input
                style="width: 15%; margin-right: 8px"
                size="mini"
                :value="authorize.token.slice(0, 6) + '...'"
                disabled
              />
              <el-button
                size="mini"
                :plain="true"
                :type="
                  authorize.loading
                    ? 'info'
                    : authorize.error
                    ? 'danger'
                    : authorize.token
                    ? 'success'
                    : 'warning'
                "
                disabled
              >
                {{
                  authorize.loading
                    ? "Generating..."
                    : authorize.error
                    ? "Error in generating."
                    : authorize.token
                    ? "Token generated"
                    : "Token resetted"
                }}
              </el-button>
              <el-button
                size="mini"
                icon="el-icon-close"
                :plain="true"
                type="danger"
                @click="resetToken"
              >
                Reset Token
              </el-button>
              <el-button
                size="mini"
                style="margin-right: 8px"
                :loading="authorize.loading"
                icon="el-icon-check"
                :plain="true"
                type="success"
                @click="generateToken"
                :disabled="!authorize.valid"
              >
                Generate Token
              </el-button>
              <el-tooltip effect="dark" content="Disabled, if body invalid format" placement="top">
                <i class="el-icon-info"></i>
              </el-tooltip>
            </el-row>
          </div>
        </div>
        <div style="padding: 12px; color: #909399" v-if="webchatSettings.useDefaultSub">
          <i style="margin-right: 0.5rem" class="el-icon-info"></i>
          <span
            >"sub" value will be overwritten by dashboard user identity automatically, as example
            "AuthWidget_user@mail.com"</span
          >
        </div>
        <JSONEditor
          v-model="authorize.body"
          @valid="authorize.valid = true"
          @invalid="authorize.valid = false"
        />
      </el-card>
      <el-card v-if="hasFacebookCustomerChat" class="embed-card">
        <div slot="header" class="widget-header">
          <div>
            <h3>
              Facebook Customer Chat SDK
              <a
                target="_blank"
                href="https://developers.facebook.com/docs/messenger-platform/discovery/customer-chat-plugin"
                ><i class="el-icon-info"></i
              ></a>
            </h3>
          </div>
          <el-color-picker v-model="color" size="small" style="float: right"></el-color-picker>
        </div>
        <codemirror v-model="facebookEmbedCode" :options="editorOptions" />
      </el-card>
    </el-col>
  </el-row>
</template>
<script>
/* eslint-disable no-useless-escape */
import _ from "lodash";
import Vue from "vue";
import LocalStorageManager from "../localStorageManager";
import { tabSettingValidation } from "@/helperMethods/modules";
import SmartFieldColor from "@/components/ModuleEditor/SmartFields/Color";
import SmartFieldSelect from "@/components/ModuleEditor/SmartFields/Select";
import webchatConfig from "@/components/ModuleEditor/BaseObjects/Webchat.json";
import JSONEditor from "@/components/JSONEditor";
import { checkRoles, getPermittedRoles } from "@/helperMethods/auth";

export default {
  components: { SmartFieldColor, SmartFieldSelect, JSONEditor },
  data() {
    return {
      AUTHORIZE_WIDGET: "authorize_widget",
      loadSettings: "Use last setting",
      payloadSetting: "",
      color: "#1D57D8",
      element: null,
      isPreview: true,
      editorOptions: {
        lineNumbers: true,
        line: true,
        styleActiveLine: true,
        keyMap: "sublime",
        mode: "htmlmixed",
        theme: "monokai",
        tags: {
          style: [
            ["type", /^text\/(x-)?scss$/, "text/x-scss"],
            [null, null, "css"],
          ],
        },
      },
      customEmbedCodeValue:
        LocalStorageManager.getItem("widgetCustomEmbedCode-" + this.$store.state.brain) || "",
      saving: false,
      valid: true,
      test: false,
      authorize: {
        body: {
          sub: "<USER_UID>",
        },
        token: "",
        valid: true,
        loading: false,
        error: false,
      },
    };
  },
  computed: {
    authorizeWidgetPermitted() {
      return checkRoles(getPermittedRoles(this.AUTHORIZE_WIDGET), false);
    },
    webchatSettings() {
      return this.$store.state.modules.webchat;
    },
    userProfile() {
      return this.$store.state.profile;
    },
    previewURL() {
      const appConfig = window.appConfig;
      const currentUrl = window.location.href;

      if (currentUrl.includes("localhost")) {
        return "http://localhost:8081/?isPreview=true";
      } else if (appConfig.WEBCHAT_URL) {
        return `${appConfig.WEBCHAT_URL}?isPreview=true`;
      } else {
        return `${appConfig.WEBCHAT_URL}?isPreview=true`;
      }
    },
    embedCodeList() {
      const defaults = [
        {
          name: "Default (Development)",
          value: this.developmentEmbedCode,
        },
        {
          name: "Default (Production)",
          value: this.productionEmbedCode,
        },
        {
          name: "Last saved (Local)",
          value: LocalStorageManager.getItem("widgetCustomEmbedCode-" + this.$store.state.brain),
        },
      ];

      const profileRoles = _.get(
        this.$store.state,
        "profile.roles",
        _.get(this.$store.state, "profile.app_metadata.roles", [])
      );

      const settingsDefined = this.$store.state.modules.webchat.embedPresets || [];
      const showAllWidgetPreset = this.$store.state.modules.webchat.showAllWidgetPreset;
      const filteredByRoles =
        showAllWidgetPreset || profileRoles.includes("admin")
          ? [...settingsDefined]
          : settingsDefined.filter(
              (setting) =>
                profileRoles.includes("admin") || // admin roles
                setting.roles === undefined || // roles not defined in the setting
                (Array.isArray(setting?.roles) && checkRoles(setting?.roles)) // only roles stated in the setting
            );
      return _.concat(defaults, filteredByRoles);
    },
    embedAuthorizedWidgetList() {
      const defaults = [];

      const profileRoles = _.get(
        this.$store.state,
        "profile.roles",
        _.get(this.$store.state, "profile.app_metadata.roles", [])
      );

      const settingsDefined = this.$store.state.modules.webchat.embedAuthorizedWidget || [];
      const showAllAuthorizedWidgetPayload =
        this.$store.state.modules.webchat.showAllAuthorizedWidgetPayload;
      const filteredBySelectedWidgetAndRoles = showAllAuthorizedWidgetPayload
        ? [...settingsDefined]
        : settingsDefined.filter((setting) => {
            return (
              (setting?.list === undefined ||
                (Array.isArray(setting?.list) && setting?.list?.includes(this.loadSettings))) &&
              (profileRoles.includes("admin") || // admin roles
                setting.roles === undefined || // roles not defined in the setting
                (Array.isArray(setting?.roles) && checkRoles(setting?.roles))) // only roles stated in the setting
            );
          });
      return _.concat(defaults, filteredBySelectedWidgetAndRoles);
    },
    makerCheckerObject() {
      // this.$store.state.modules.makerChecker
      return _.assign(this.$store.state.modules.makerChecker || {}, {});
    },
    fileURL() {
      const appConfig = window.appConfig;
      if (appConfig.WEBCHAT_URL) {
        //webchat url
        return appConfig.WEBCHAT_URL;
      } else if (/(0.0.0.0|192.168.\d+.\d+|localhost)/i.test(Vue.config.root)) {
        // Is locahost
        return `http://${location.hostname}:8081/`;
      } else if (/azurewebsites/i.test(Vue.config.root)) {
        return "https://webchat.keyreply.com/";
      } else if (/app.keyreply.com/i.test(Vue.config.root)) {
        return Vue.config.root.replace("server", "webchat");
      } else {
        return "https://webchat.keyreply.com/";
      }
    },
    customEmbedCode: {
      get() {
        return this.customEmbedCodeValue !== ""
          ? this.customEmbedCodeValue
          : this.developmentEmbedCode +
              `<style>
/*Panel Header*/
#keyreply-panel-header {
  background-color: <insert here> !important
}
/*Panel Body*/
#keyreply-panel-body {
  background-color: <insert here> !important
}
/*Text bubble*/
.keyreply-bubble {
 background-color: <insert here> !important
}
/*Launcher logo*/
.keyreply-launcher {
  background-image: url(<insert here>) !important
}
</style>`;
      },
      set(newValue) {
        this.customEmbedCodeValue = newValue;
      },
    },
    customStyleValue() {
      const styleValue = this.customEmbedCodeValue
        ? this.customEmbedCodeValue.match(/<style(?:\W*scoped\W*)?>{1}((.|\n)*)<\/style>{1}/i)
        : ["", ""];
      return styleValue && styleValue.length > 0 ? styleValue[1].trim() : null;
    },
    bookmarklet() {
      return `javascript:(function(){window.kr_settings={server:%22${Vue.config.root}%22,isPreview:true};var s=document.createElement(%22script%22);s.type=%22text/javascript%22,s.src=%22${this.fileURL}js/app.js%22,document.getElementsByTagName(%22head%22)[0].appendChild(s);})();`;
    },
    developmentEmbedCode() {
      return this.getEmbedCode({
        server: Vue.config.root,
        isPreview: this.isPreview,
      });
    },

    productionEmbedCode() {
      return this.getEmbedCode({
        server: Vue.config.root,
      });
    },

    hasFacebookCustomerChat() {
      const { FACEBOOK_APP_ID, FACEBOOK_PAGE_ID } = this.$store.state.modules.facebook;
      return FACEBOOK_APP_ID && FACEBOOK_PAGE_ID;
    },
    facebookEmbedCode() {
      const { FACEBOOK_APP_ID, FACEBOOK_PAGE_ID } = this.$store.state.modules.facebook;

      if (FACEBOOK_APP_ID && FACEBOOK_PAGE_ID) {
        return `<script>
var chatDiv = document.createElement('div');
chatDiv.className = 'fb-customerchat';
chatDiv.setAttribute('page_id', '${FACEBOOK_PAGE_ID}');
chatDiv.setAttribute('greeting_dialog_delay', '10');
chatDiv.setAttribute('greeting_dialog_display', 'fade');
chatDiv.setAttribute('data-href', 'https://keyreply.com');
chatDiv.setAttribute('messenger_app_id', '${FACEBOOK_APP_ID}');
chatDiv.setAttribute('theme_color', '${this.color}');
chatDiv.setAttribute('ref', 'website');
document.body.appendChild(chatDiv);

window.fbAsyncInit = function () {
  FB.init({
      appId: '${FACEBOOK_APP_ID}',
      xfbml: true,
      version: 'v4.0'
  });
};

(function(d, s, id) {
  var js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s); js.id = id;
  js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js";
  fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));
<\/script>`;
      } else {
        return "// This chatbot is not connected to Facebook Messenger Platform. Please contact KeyReply for assistance in setup.";
      }
    },
    modules: {
      get() {
        return this.$store.state.modules;
      },
      set(value) {
        this.$store.state.modules = value;
      },
    },
    webchatThemeConfig() {
      let filteredConfig = {};
      for (const [key, value] of Object.entries(webchatConfig)) {
        if (value.type === "color") {
          filteredConfig[key] = value;
        }
      }
      const { buttonOutlineColor } = webchatConfig;
      return { buttonOutlineColor, ...filteredConfig };
    },
  },
  mounted() {
    const JWT = LocalStorageManager.getItem("JWT");
    if (JWT) {
      this.authorize.token = JWT;
    }
    if (this.$store.state.brain) {
      this.drawFrame();
    }
  },
  methods: {
    changeAuthorizedPayloadSettings(defaultActive) {
      this.authorize.body = _.chain(this.embedAuthorizedWidgetList)
        .find({ name: defaultActive })
        .get("value")
        .value();
    },
    async generateToken() {
      this.authorize.error = false;
      this.authorize.loading = true;
      delete this.authorize.body?.iat;
      delete this.authorize.body?.exp;
      const payload = {
        ...this.authorize.body,
        sub: this.webchatSettings.useDefaultSub
          ? `AuthWidget_${this.userProfile.email}`
          : this.authorize.body.sub,
      };
      try {
        const result = await this.$rest("post", "authorize_widget", payload);
        this.authorize.token = result.access_token;
      } catch (err) {
        this.authorize.error = true;
      } finally {
        this.authorize.loading = false;
      }
    },
    resetToken() {
      LocalStorageManager.removeItem("_kruid");
      LocalStorageManager.removeItem("JWT");
      this.authorize.token = "";
      this.authorize.error = false;
    },
    launchStickyWidget() {
      this.$eventBus.$emit("TOGGLE_SHOW_WEB_WIDGET", {
        enabled: true,
        style: this.customStyleValue,
        code: this.customEmbedCode,
      });
    },
    drawFrame(authorizedUser) {
      if (!this.$refs.frame) return;

      this.$refs.frame.contentWindow.document.head.innerHTML = `<style>${this.customStyleValue}</style>`;
      this.$refs.frame.contentWindow.document.open();
      const authorizedScript = !authorizedUser
        ? ""
        : `
<script>
if (typeof window.kr_settings.user === "object") {
  window.kr_settings.user.JWT = "${this.authorize.token}";
} else {
  window.kr_settings.user = {
    JWT: "${this.authorize.token}"
  }
}
<\/script>
        `;

      this.$refs.frame.contentWindow.document.write(`
<!DOCTYPE html>
<html dir='ltr'>
  <body>
      ${this.customEmbedCode}
      ${authorizedScript}
  </body>
</html>`);
    },
    onCodeChange(value) {
      this.customEmbedCodeValue = value;
    },
    onCodeSave(value) {
      LocalStorageManager.setItem(
        "widgetCustomEmbedCode-" + this.$store.state.brain,
        this.customEmbedCode
      );
      const tokenPayload = {
        ...this.authorize.body,
        sub: this.webchatSettings.useDefaultSub
          ? `AuthWidget_${this.userProfile.email}`
          : this.authorize.body.sub,
      };
      if (value) {
        this.$store.dispatch("AUDIT_AUTHORIZED_WIDGET", {
          widgetInfo: this.customEmbedCode,
          tokenPayload,
        });
      }

      this.drawFrame(value);
    },
    changeLoadSettings(defaultActive) {
      this.customEmbedCode = _.chain(this.embedCodeList)
        .find({ name: defaultActive })
        .get("value")
        .value();
      this.resetToken();
    },
    getEmbedCode(settings) {
      const settingsString = JSON.stringify(settings);
      const webchatJsURL = new URL("js/app.js", this.fileURL);
      return `<script>
window.kr_settings = ${settingsString};
<\/script>
<script async src="${webchatJsURL.href}"><\/script>`;
    },
    toComponent(setting) {
      const type = _.capitalize(setting.type);
      const componentName = `SmartField${type}`;
      const isComponentExist = _.has(this.$options.components, componentName);
      return isComponentExist ? componentName : null;
    },
    saveChanges(event) {
      const tabSetting = _.get(this.modules, "webchat.tabs", []);
      if (!tabSettingValidation(tabSetting)) {
        this.$message({
          type: "error",
          message: "Error saving tab config",
        });
        return;
      }

      this.saving = true;
      this.$store
        .dispatch("SAVE_MODULES", {
          modules: this.modules,
        })
        .then(
          () => {
            this.saving = false;
            this.$message({
              type: "success",
              message: "Config Saved",
            });
            this.drawFrame();
          },
          () => {
            this.saving = false;
            this.$message({
              type: "error",
              message: "Error saving config",
            });
          }
        );
    },
  },
};
</script>
<style scoped>
th a {
  cursor: pointer;
}
.widget-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.widget-color-setting {
  width: 100%;
  padding: 14px;
  background: #fff;
  box-shadow: 0 0 5px #eee;
  box-sizing: border-box;
  margin: 8px 0;
  border-radius: 4px;
}
.form-field-group {
  margin-top: 10px;
  text-align: center;
}
</style>

<style>
.preview-card .el-card__body,
.embed-card .el-card__body {
  padding: 0 !important;
}
</style>
