<template>
  <div>
    <span
      style="margin-left: 1.5em; margin-right: 3em; fontsize: 14px; margin-bottom: 1em"
      class="has-text-grey"
    >
      {{ setting.label || name }}
    </span>

    <el-cascader
      v-model="value[name]"
      :options="setting.options"
      :props="setting.props"
      :collapse-tags="setting['collapse-tags']"
      :clearable="setting.clearable"
    >
      <template slot-scope="{ node, data }">
        <span
          v-update-header="{ title: getHeader(node) }"
          :class="`cascader-test`"
          class="cascader-item test"
        >
          {{ data.label }}
        </span>
        <span
          :style="{
            float: 'right',
            fontSize: 13 + 'px',
            marginLeft: '1.5em',
          }"
          class="has-text-grey"
        >
          {{ data.value ? data.value : data.label }}
        </span>
      </template>
    </el-cascader>

    <Tooltip :label="setting.label || name" :content="setting.detail" />
  </div>
</template>

<script>
import _ from "lodash";
import Tooltip from "../Tooltip.vue";
export default {
  components: { Tooltip },
  data() {
    return {
      optionsWithHeaders: [],
    };
  },
  methods: {
    getHeader(node) {
      return this.setting.headers[node.level - 1];
    },
    applyHeaders(optionsToModify, headerArr, level) {
      if (!Array.isArray(optionsToModify)) return;
      for (let i = 0; i < optionsToModify.length; i++) {
        const obj = optionsToModify[i];

        if (i === 0) {
          const t = optionsToModify.unshift({
            label: headerArr[level],
            value: headerArr[level],
            children: [],
          });
        }
        this.applyHeaders(obj.children, headerArr, level + 1);
      }
    },
  },
  directives: {
    updateHeader: {
      // directive definition
      inserted: function (el, binding) {
        let cascaderWrapper = el.parentNode.parentNode.parentNode.parentNode;
        const firstNodeName = cascaderWrapper.children[0].nodeName;
        if (firstNodeName === "UL") {
          let headerElement = document.createElement("p");
          headerElement.style.paddingLeft = "20px";
          headerElement.style.paddingTop = "10px";
          headerElement.style.margin = 0;
          headerElement.innerHTML = binding.value.title;
          cascaderWrapper.prepend(headerElement);
        }
      },
    },
  },
  props: ["value", "setting", "name"],
  mounted() {
    if (this.value[this.name] == null) {
      if (this.setting.default != null) {
        this.$set(this.value, this.name, this.setting.default);
      } else {
        this.$set(this.value, this.name, null);
      }
    }
    const optionsToModify = _.cloneDeep(this.setting.options);
    this.applyHeaders(optionsToModify, this.setting.headers, 0);
    this.optionsWithHeaders = optionsToModify;
    // console.log(this.optionsWithHeaders);
  },
};
</script>

<style scoped>
>>> ul.el-scrollbar__view.el-cascader-menu__list li:nth-of-type(1) {
  background-color: transparent;
  cursor: default;
}

>>> ul.el-scrollbar__view.el-cascader-menu__list li:nth-of-type(1) label {
  display: none;
}

>>> ul.el-scrollbar__view.el-cascader-menu__list li:nth-of-type(1) i {
  display: none;
}
</style>
