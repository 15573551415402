<template>
  <div class="tag-component-list-tag">
    <table class="tag-component-list-tag-table" aria-hidden="true">
      <thead>
        <tr class="tag-component-list-tag-table-head">
          <th class="tag-component-list-tag-table-head-title">
            <p>{{ label }}s</p>
          </th>
          <th
            class="tag-component-list-tag-table-head-action"
            v-if="checkRoles(getPermittedRoles(moduleName))"
          >
            <el-button size="mini" icon="el-icon-plus" type="text" @click="createNewTag">{{
              buttonText
            }}</el-button>
          </th>
        </tr>
      </thead>
      <tbody v-if="tags.length > 0">
        <tr
          v-for="tag in tags"
          :key="tag.id"
          class="tag-component-list-tag-table-row"
          @mouseover="showActionIcon = true"
          @mouseleave="showActionIcon = false"
        >
          <td class="tag-component-list-tag-table-row-title">
            <p>{{ tag.name }}</p>
          </td>
          <td
            v-if="checkRoles(getPermittedRoles(moduleName))"
            class="tag-component-list-tag-table-row-action action-button show"
            :class="formModel.id === tag.id && 'show'"
          >
            <div>
              <el-button
                class="list-tag-button-edit"
                icon="el-icon-edit-outline"
                size="mini"
                @click="editTag(tag)"
              >
              </el-button>
              <el-button
                size="mini"
                @click="deleteTag(tag)"
                style="margin: 0px; padding-left: 10px"
                icon="el-icon-delete"
                type="danger"
                class="list-tag-button-delete"
              >
              </el-button>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr class="tag-component-list-tag-table-row-title" style="padding-bottom: 0">
          {{
            notFoundText
          }}
        </tr>
      </tbody>
    </table>
    <form-tag-vue
      :visible.sync="tagFormDialogVisible"
      :props-model="formModel"
      :tags="tags"
      @submit:tag="submitTag"
      @refresh-props-model="refreshPropsModel"
      :type="type"
      :label="label"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { checkRoles, getPermittedRoles } from "@/helperMethods/auth";
import { OUT_REACH_TAG_TYPE } from "@/store/modules/outreach/types";
import { OUT_REACH_MODULE } from "@/helperMethods/outreach";
import FormTagVue from "./FormTag.vue";
import _ from "lodash";

import TAG_DESTROY from "@/graphql/OutReachTagDestroy.graphql";
import TAG_CREATE from "@/graphql/OutReachTagCreate.graphql";
import TAG_UPDATE from "@/graphql/OutReachTagUpdate.graphql";
import DeleteIcon from "@/components/SvgIcon/DeleteIcon.vue";
import EditIcon from "@/components/SvgIcon/EditIcon.vue";

export default Vue.extend({
  components: { FormTagVue, DeleteIcon, EditIcon },
  props: {
    tags: Array,
    type: OUT_REACH_TAG_TYPE,
  },
  data() {
    return {
      tagFormDialogVisible: false,
      moduleName: OUT_REACH_MODULE,
      showActionIcon: false,
      formModel: {},
    };
  },

  computed: {
    buttonText() {
      return `Add ${this.type.toLowerCase()}s`;
    },
    label() {
      return _.upperFirst(this.type.toLowerCase());
    },
    notFoundText() {
      return `No ${this.type.toLowerCase()}s found`;
    },
  },
  methods: {
    checkRoles,
    getPermittedRoles,
    createNewTag() {
      this.refreshPropsModel();
      this.tagFormDialogVisible = true;
    },
    editTag(tag) {
      this.formModel = tag;
      this.tagFormDialogVisible = true;
    },
    refreshPropsModel() {
      this.formModel = {};
    },
    submitTag(tag) {
      this.$store.commit("SET_OUTREACH_LOADING", true);
      if (tag.id) {
        const tagForm = _.pick(tag, ["id", "name", "description", "type"]);
        this.updateTag(tagForm);
      } else {
        const tagForm = _.pick(tag, ["name", "description", "type"]);
        const exist = this.tags.find(
          (data) => data.name.trim().toLowerCase() === tag.name.trim().toLowerCase()
        );
        if (exist) {
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `${this.label} name already exist`,
          });
        } else {
          this.createTag(tagForm);
        }
      }
      this.$store.commit("SET_OUTREACH_LOADING", false);
    },
    createTag(tag) {
      const self = this;
      tag.name = tag.name.trim();
      this.$apollo
        .mutate({
          mutation: TAG_CREATE,
          variables: {
            tag,
          },
          update(store, { data: { outReachTagStageAPI } }) {
            const created = _.get(outReachTagStageAPI, "createOutReachTag", null);
            if (self.type === OUT_REACH_TAG_TYPE.TAG) {
              self.$store.commit("ADD_OUTREACH_TAG", created);
            } else {
              self.$store.commit("ADD_OUTREACH_STAGE", created);
            }
            self.$notify.success({
              title: "Success",
              position: "bottom-right",
              message: `Successfully created the ${tag.name} ${self.label}!`,
            });
          },
        })
        .catch((error) => {
          self.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: error.message,
          });
        });
    },
    updateTag(tag) {
      const self = this;
      tag.name = tag.name.trim();
      this.$apollo
        .mutate({
          mutation: TAG_UPDATE,
          variables: {
            tag,
          },
          update(store, { data: { updateOutReachTag } }) {
            if (self.type === OUT_REACH_TAG_TYPE.TAG) {
              self.$store.commit("UPDATE_OUTREACH_TAG", tag);
            } else {
              self.$store.commit("UPDATE_OUTREACH_STAGE", tag);
            }
            self.$notify.success({
              title: "Success",
              position: "bottom-right",
              message: `Successfully updated the ${tag.name} ${self.label}!`,
            });
          },
        })
        .catch((error) => {
          self.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: error.message,
          });
        });
    },
    deleteTag(tag) {
      this.$confirm(`This will permanently delete the ${this.label}.`, "Warning", {
        confirmButtonClass: "btn-kr-style",
        cancelButtonClass: "btn-cancel",
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.destroyTag(tag);
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: `Delete ${this.label} canceled`,
          // });
        });
    },
    destroyTag(tag) {
      this.$store.commit("SET_OUTREACH_LOADING", true);
      const self = this;
      this.$apollo
        .mutate({
          mutation: TAG_DESTROY,
          variables: {
            id: tag.id,
            type: this.type,
          },
          update(store, { data: { outReachTagStageAPI } }) {
            const destroyed = _.get(outReachTagStageAPI, "destroyOutReachTag");
            if (destroyed) {
              if (self.type === OUT_REACH_TAG_TYPE.TAG) {
                self.$store.commit("REMOVE_OUTREACH_TAG", tag.id);
              } else {
                self.$store.commit("REMOVE_OUTREACH_STAGE", tag.id);
              }
            }
            // set loading to be false
            // self.$store.commit("DEPARTMENT_DELETE", stage.id);
            self.$notify.success({
              title: "Success",
              position: "bottom-right",
              message: `Successfully deleted the ${tag.name} ${self.label}!`,
            });
          },
        })
        .catch((err) => {
          self.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `Failed to delete the ${tag.name} ${self.label}!`,
          });
        });
      this.$store.commit("SET_OUTREACH_LOADING", false);
    },
  },
});
</script>
<style lang="scss">
$primary-KR-color: #1d57d8;
$row-bg-hover: #f4f7fa;
$base-date-picker-color: #1d57d8;
$button-bg-hover: #e8eefb;
$button-border-hover: #bbcdf3;
@mixin text-style {
  font-family: "Avenir";
  font-style: normal;
  line-height: 16px;
  margin: 0;
}
.btn-cancel {
  color: $primary-KR-color !important;
  border-color: $primary-KR-color !important;
  &:hover {
    border-color: $primary-KR-color !important;
  }
}

.btn-kr-style {
  background: $primary-KR-color !important;
  border-color: $primary-KR-color !important;
  color: white !important;
  &:hover {
    background: #4573d7 !important;
    border-color: $primary-KR-color !important;
    color: white !important;
  }
}
.tag-component-list-tag {
  p,
  button {
    @include text-style;
  }
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  &-table {
    width: 100%;
    padding-bottom: 17px;
    border-spacing: 0;
    &-head {
      border-bottom: 1px solid #cecece;
      padding: 5px;
      box-sizing: border-box;
      font-size: 16px;

      &-title {
        padding: 10px 17px;
        line-height: 16px;
        font-weight: 500;
        color: #000000;
      }
      &-action {
        button {
          font-weight: 500;
          font-size: 14px;
          color: $primary-KR-color;
        }
      }
    }
    &-row {
      border-bottom: 1px solid #ebeef5;
      &:hover {
        background: $row-bg-hover;
        .action-button {
          display: block;
        }
      }
      &-action {
        .list-tag-button-delete {
          color: #f56c6c;
          i {
            font-size: 16px;
          }
          &:hover {
            color: #f78989;
          }
        }
        .list-tag-button-edit {
          color: #409eff;
          i {
            font-size: 16px;
          }
          &:hover {
            color: #66b1ff;
          }
        }
        button {
          border: none;
          background: none !important;
          &:hover {
            // background: none !important;
          }
          &:first-child {
            &:hover {
              path {
                fill: #1d57d8;
              }
            }
          }
          &:last-child {
            &:hover {
              path {
                stroke: #1d57d8;
              }
            }
          }
        }
        display: none;
        &.show {
          display: block;
        }
      }
      &-title {
        padding: 10px 19px;
        font-size: 14px;
        color: #000000;
      }
    }
    tr {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    td:last-child,
    th:last-child {
      width: 100px;
      text-align: start;
    }
  }
}
</style>
