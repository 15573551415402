<template>
  <div>
    <el-row style="margin-bottom: 0">
      <el-col>
        <el-card>
          <el-form label-width="130px" size="mini">
            <el-form-item label="Confidence Target">
              <el-slider v-model="sliderValue"></el-slider>
            </el-form-item>
            <el-form-item label="Selected Question">
              <el-select
                filterable
                v-model="selectedQuestion"
                placeholder="Select"
                style="width: 100%"
              >
                <el-option
                  v-for="variation in rawSelectedIntent.variations"
                  :key="variation.text"
                  :label="variation.text"
                  :value="variation.text"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <el-row style="margin-bottom: 0">
      <el-col>
        <el-card>
          <div>
            <div id="dataviz-intentconflict"></div>
            <p>{{ selectedQuestion }}</p>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row>
      <el-collapse>
        <el-collapse-item title="Show details" name="1">
          <el-row :gutter="15">
            <div v-if="Object.keys(columnData).length != 0">
              <el-col :span="8" v-for="intentName in Object.keys(columnData)" :key="intentName">
                <el-card>
                  <h3>
                    Rank
                    {{
                      questionData.filter((item) => item["Actual intent"] == intentName)[0].Rank
                    }})
                    {{ intentName }}
                  </h3>
                  <p v-for="(question, index) in columnData[intentName]" :key="index">
                    {{ question }}
                  </p>
                </el-card>
              </el-col>
            </div>
          </el-row>
        </el-collapse-item>
      </el-collapse>
    </el-row>
  </div>
</template>

<script>
import { SVG } from "@svgdotjs/svg.js";
import _ from "lodash";

export default {
  name: "IntentConflict",
  props: {
    rawSelectedIntent: {
      type: Object,
      required: true,
    },
    dataSet: {
      type: Array,
      required: true,
    },
    //must be >=1, automatically saves 1 space for selected intent
    noOfIntentsToDisplay: {
      type: Number,
      default: 3,
    },
    height: {
      type: Number,
      default: 250,
    },
    graphWidth: {
      //zoom width
      type: Number,
      default: 700,
    },
    graphHeight: {
      //zoom height
      type: Number,
      default: 200,
    },
  },
  data() {
    return {
      canvas: null,
      centerX: 0, //position line on graph
      centerY: 0, //position line on graph
      leftX: 0,
      rightX: 0,
      mainIntentData: null,
      columnIntents: [],
      columnData: {},
      questionData: null,
      sliderValue: 75,
      idealConfidence: 0.75,
      selectedQuestion: "",
    };
  },
  computed: {
    selectedIntentToDisplay: function () {
      return this.rawSelectedIntent.intentName.toLowerCase();
    },
  },
  watch: {
    sliderValue: function () {
      this.handleRefresh();
    },
    selectedQuestion: function () {
      this.handleRefresh();
    },
  },
  methods: {
    handleRefresh: function (event) {
      this.initDraw();
    },
    drawQuestionLine: function (questionSet, height) {
      const calcX = this.leftX + (this.rightX - this.leftX) * questionSet.Confidence;
      let isSelectedIntent = false;
      if (questionSet["Actual intent"] === this.selectedIntentToDisplay) isSelectedIntent = true;
      const colour = isSelectedIntent ? "orange" : "black";
      //Top vertical line
      this.canvas.line(calcX, this.centerY, calcX, this.centerY - height).stroke({
        width: 3,
        color: colour,
        opacity: 1,
      });
      //Top horizontal line
      this.canvas.line(calcX - 6, this.centerY - height, calcX + 6, this.centerY - height).stroke({
        width: 3,
        color: colour,
        opacity: 1,
      });
      //Intent text
      this.canvas
        .text(questionSet["Actual intent"])
        .x(calcX + 37)
        .y(this.centerY - height - 30)
        .fill({ opacity: 1, color: colour })
        .font({ size: 15 });
      //Confidence number
      this.canvas
        .text((questionSet.Confidence * 100).toFixed(2).toString() + "%")
        .x(calcX + 15)
        .y(this.centerY - height - 10)
        .fill({ opacity: 1, color: colour })
        .font({ size: 15 });
      //Rank Digit
      this.canvas
        .text(questionSet.Rank.toString())
        // .text("10")
        .x(calcX + 15)
        .y(this.centerY - height - 32)
        .fill({ opacity: 1, color: colour })
        .font({ size: 15 });
      //Rank Box
      this.canvas
        .rect(23, 17)
        .x(calcX + questionSet.Rank.toString().length + 6)
        // .x(calcX + "10".length * 5)
        .y(this.centerY - height - 30)
        .fill({ opacity: 0 })
        .stroke({ color: colour, opacity: 1, width: 2 })
        .radius(3);

      if (isSelectedIntent && questionSet.Confidence < this.idealConfidence) {
        this.drawIdealConfidenceLine(calcX, colour);
      }
    },
    drawIdealConfidenceLine: function (calcX, colour) {
      const idealX = this.leftX + (this.rightX - this.leftX) * this.idealConfidence;
      const dottedLineHeight = 35;
      //Bottom left vertical line
      this.canvas.line(calcX, this.centerY, calcX, this.centerY + dottedLineHeight).stroke({
        width: 3,
        color: colour,
        opacity: 1,
        dasharray: "3,3",
      });
      //Bottom horizontal line
      this.canvas
        .line(calcX, this.centerY + dottedLineHeight, idealX, this.centerY + dottedLineHeight)
        .stroke({
          width: 3,
          color: colour,
          opacity: 1,
          dasharray: "3,3",
        });
      //Bottom right vertical line
      this.canvas.line(idealX, this.centerY, idealX, this.centerY + dottedLineHeight).stroke({
        width: 3,
        color: colour,
        opacity: 1,
        dasharray: "3,3",
      });
      //calculate left arrow line coordinates
      const arrowLeftX = idealX + Math.cos(60 * (Math.PI / 180)) * 25;
      const arrowLeftY = this.centerY + 5 + Math.sin(60 * (Math.PI / 180)) * 25;
      //draw left arrow line
      this.canvas.line(idealX, this.centerY, arrowLeftX, arrowLeftY).stroke({
        width: 3,
        color: colour,
        opacity: 1,
        dasharray: "3,3",
      });
      //calculate right arrow line coordinates
      const arrowRightX = idealX + Math.cos(120 * (Math.PI / 180)) * 25;
      const arrowRightY = this.centerY + 5 + Math.sin(120 * (Math.PI / 180)) * 25;
      //draw right arrow line
      this.canvas.line(idealX, this.centerY, arrowRightX, arrowRightY).stroke({
        width: 3,
        color: colour,
        opacity: 1,
        dasharray: "3,3",
      });
      //Ideal Confidence Text
      this.canvas
        .text((this.idealConfidence * 100).toFixed(0).toString() + "%")
        .center(idealX, this.centerY + 55)
        .fill({ opacity: 1, color: "orange" })
        .font({ size: 17 });
    },
    initDraw: function () {
      if (this.canvas) this.canvas.remove();
      this.columnIntents = [];
      this.idealConfidence = this.sliderValue / 100;

      //filter entire dataset for selected Expected intent
      this.mainIntentData = this.dataSet.filter(
        (item) => item["Expected intent"] === this.selectedIntentToDisplay
      );

      //data with only selected question (1-10 rows)
      this.questionData = _.filter(this.mainIntentData, (item) => {
        return item["Question"] === this.selectedQuestion;
      });

      //sort question data in decending order by confidence
      const sortedData = this.questionData.sort((a, b) => (a.Confidence > b.Confidence ? -1 : 1));
      //get ranking (between 1 to 10) of the Actual intent
      const filterArrayResult = this.questionData.filter(
        (item) => item["Expected intent"] == item["Actual intent"]
      );

      //set up canvas
      this.canvas = SVG()
        .addTo("#dataviz-intentconflict")
        .size("100%", this.height)
        .viewbox(0, 0, this.graphWidth, this.graphHeight);

      const minHeight = 20;
      const incrementalHeight = 50;

      //check if selected question is in top 10
      const isSelectedQuestionInTop10 = filterArrayResult.length != 0;
      if (isSelectedQuestionInTop10) {
        const rankOfActualIntent = filterArrayResult[0].Rank;

        //check if selected intent is in the top # of intents to display
        if (rankOfActualIntent <= this.noOfIntentsToDisplay) {
          for (let i = 0; i < Math.min(this.noOfIntentsToDisplay, this.questionData.length); i++) {
            if (i === 0) {
              this.drawQuestionLine(sortedData[i], minHeight);
            } else {
              this.drawQuestionLine(sortedData[i], minHeight + incrementalHeight * i);
            }
            this.columnIntents.push(sortedData[i]["Actual intent"]);
          }
        } else {
          //if not in top # of intent, leave 1 space for selected intent
          for (let i = 0; i < this.noOfIntentsToDisplay - 1; i++) {
            if (i === 0) this.drawQuestionLine(sortedData[i], minHeight);
            else this.drawQuestionLine(sortedData[i], minHeight + incrementalHeight * i);
            this.columnIntents.push(sortedData[i]["Actual intent"]);
          }
          //manually draw selected intent at position
          this.drawQuestionLine(
            sortedData[rankOfActualIntent - 1],
            minHeight + incrementalHeight * (this.noOfIntentsToDisplay - 1)
          );
          this.columnIntents.push(sortedData[rankOfActualIntent - 1]["Actual intent"]);
        }

        //selected question not in top 10
      } else {
        for (let i = 0; i < Math.min(this.noOfIntentsToDisplay, this.questionData.length); i++) {
          if (i === 0) {
            this.drawQuestionLine(sortedData[i], minHeight);
          } else {
            this.drawQuestionLine(sortedData[i], minHeight + incrementalHeight * i);
          }
          this.columnIntents.push(sortedData[i]["Actual intent"]);
        }
      }

      //draw main horizontal black line
      this.canvas.line(this.leftX, this.centerY, this.rightX, this.centerY).stroke({
        width: 5,
        color: "black",
        opacity: 1,
      });
      //0% label
      this.canvas
        .text("0%")
        .center(this.leftX - 30, this.centerY)
        .fill({ opacity: 1, color: "black" })
        .font({ size: 20 });
      //100% label
      this.canvas
        .text("100%")
        .center(this.rightX + 30, this.centerY)
        .fill({ opacity: 1, color: "black" })
        .font({ size: 20 });
      //Confidence label
      this.canvas
        .text("Confidence")
        .center(this.rightX + 30, this.centerY + 30)
        .fill({ opacity: 1, color: "black" })
        .font({ size: 14 });

      this.processColumnData();
    },
    processColumnData: function () {
      this.columnData = {};
      this.columnIntents.forEach((intentName) => {
        const filteredActualIntentsData = this.mainIntentData.filter(
          (item) => item["Actual intent"] === intentName
        );
        const questionsData = filteredActualIntentsData.map((item) => item.Question);
        const uniqueQuestionsData = _.uniq(questionsData);
        Object.assign(this.columnData, {
          [intentName]: uniqueQuestionsData,
        });
      });
    },
  },
  mounted: function () {
    this.centerX = this.graphWidth / 2;
    this.centerY = this.graphHeight - 50;
    this.leftX = this.centerX - this.graphWidth / 3;
    this.rightX = this.centerX + this.graphWidth / 3;

    this.initDraw();
  },
};
</script>

<style scoped></style>
