<template>
  <section
    style="height: 100%"
    :style="{
      overflow: selectedWebPage.status === 'editing' ? 'hidden' : 'auto',
    }"
  >
    <el-tabs
      v-if="selectedWebPage.status === 'editing' || selectedWebPage.status === 'published'"
      type="border-card"
      class="webpage-editor"
      style="height: 100%"
      v-model="currentTab"
      @tab-click="onTabChange"
    >
      <el-tab-pane
        name="editor"
        label="Content"
        :class="{ 'is-webpage-disabled': isDisabled }"
        lazy
      >
        <vue-html5-editor @change="onChange" :content="content"></vue-html5-editor>
      </el-tab-pane>
      <el-tab-pane
        name="html-head"
        label="HTML Head </>"
        :class="{ 'is-webpage-disabled': isDisabled }"
      >
        <codemirror
          ref="head-editor"
          v-model="selectedWebPage.head"
          :options="htmlEditorOptions"
          :disabled="isDisabled"
        />
      </el-tab-pane>
      <el-tab-pane
        name="html-body"
        label="HTML Body </>"
        :class="{ 'is-webpage-disabled': isDisabled }"
        ><codemirror
          ref="head-editor"
          v-model="selectedWebPage.content"
          :options="htmlEditorOptions"
      /></el-tab-pane>
    </el-tabs>

    <iframe v-else :src="previewContent" style="width: 100%; height: 100%" frameborder="0"></iframe>
  </section>
</template>

<script>
import { HtmlEditorOptions } from "../Index";

export default {
  props: ["selectedWebPage", "isDisabled"],
  data() {
    return {
      htmlEditorOptions: HtmlEditorOptions,
      content: "",
      currentTab: "editor",
    };
  },
  computed: {
    /**
     * HTML content for iframe preview
     *
     * @return {string}
     */
    previewContent() {
      const html = `<head>${this.selectedWebPage.head}</><body>${this.selectedWebPage.content}</body>`;
      return "data:text/html," + encodeURIComponent(html);
    },
  },
  mounted() {
    this.updateContentToLocal();
  },
  methods: {
    /**
     * On wysiwyg editor changed
     *
     * @return {void}
     */
    // onChange: _.debounce(function ($event) {
    //   this.content = $event;
    // }, 300),
    onChange($event) {
      this.content = $event;
    },

    /**
     * Update from local content
     */
    updateContentFromLocal() {
      if (this.content) {
        this.selectedWebPage.content = this.content;
        this.$nextTick(() => {
          this.content = "";
        });
      }
    },

    /**
     * Update to local content
     */
    updateContentToLocal() {
      this.content = this.selectedWebPage.content;
    },

    onTabChange(tab) {
      switch (tab.name) {
        case "html-body":
          this.updateContentFromLocal();
          break;

        case "editor":
          this.updateContentToLocal();
          break;
      }
    },
  },
};
</script>

<style scoped>
.webpage-editor >>> .CodeMirror,
.webpage-editor >>> .vue-html5-editor .content {
  height: 580px;
}

.webpage-editor >>> .vue-html5-editor table td,
.webpage-editor >>> .vue-html5-editor table th {
  border: 1px solid #ddd;
}
</style>
