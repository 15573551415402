<template>
  <section style="padding: 10px; overflow: hidden">
    <div>
      <el-button
        plain
        type="primary"
        icon="el-icon-upload"
        size="mini"
        :disabled="isDisabled"
        @click.prevent="onUpload"
        >Upload PDF</el-button
      >

      <input
        id="webpage-uploader"
        ref="webpage-uploader"
        style="display: none"
        type="file"
        accept="application/pdf"
        @change="onPdfSelected"
      />
    </div>

    <iframe
      v-if="selectedWebPage.content && selectedWebPage.content.url"
      :src="selectedWebPage.content.url"
      style="width: 100%; height: 600px; margin-top: 20px"
      frameborder="0"
    ></iframe>
  </section>
</template>

<script>
import { validateFileUpload } from "@/helperMethods/fileUpload";

export default {
  props: ["selectedWebPage", "isDisabled"],
  data() {
    return {
      isBusy: false,
    };
  },
  computed: {
    /**
     * @description Is azure storage getter
     * @return {boolean}
     */
    isAzureStorage() {
      return this.$store.getters.isAzureStorage;
    },

    /**
     * @description Is ceph storage getter
     * @return {boolean}
     */
    isCephStorage() {
      return this.$store.getters.isCephStorage;
    },

    /**
     * @description Check if any storage plugin enabled
     * @return {boolean}
     */
    hasAnyStorageEnabled() {
      return this.isAzureStorage || this.isCephStorage;
    },
  },
  methods: {
    /**
     * @description On upload button pressed
     * @return {void}
     */
    onUpload() {
      let elem = document.getElementById("webpage-uploader");
      if (elem && document.createEvent) {
        let evt = document.createEvent("MouseEvents");
        evt.initEvent("click", true, false);
        elem.dispatchEvent(evt);
      }
    },

    /**
     * @description On pdf selected
     * @return {void}
     */
    async onPdfSelected($event) {
      try {
        const file = _.first($event.target.files);
        if (file) {
          const { validated, errorMessage } = validateFileUpload(file);
          if (!validated) {
            this.$notify.error({
              title: `File ${file.name} didn't pass validation.`,
              position: "bottom-right",
              message: errorMessage,
            });
            return;
          }

          const canBeUploaded = await this.scanForVirus(file);
          if (!canBeUploaded) return;

          if (this.isAzureStorage) {
            this.uploadToAzure(file);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    async scanForVirus(file) {
      try {
        const formData = new FormData();
        formData.append("file", file);
        this.isBusy = true;
        const response = await this.$rest("post", "miniapp_virus_scan", formData);
        const result = _.get(response, "data", {});
        const isSafe = !result.is_infected;
        const virusDetected = !_.isEmpty(result.viruses);
        const canBeUploaded = isSafe && !virusDetected;
        if (!canBeUploaded) {
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `File ${file.name} contain suspicious malware, please try with other file.`,
          });
        }
        return canBeUploaded;
      } catch (error) {
        this.$notify({
          type: "warning",
          title: "Warning",
          position: "bottom-right",
          message: "Antivirus failed to run!",
        });
      } finally {
        this.isBusy = false;
      }
    },

    /**
     * @description Upload to azure storage
     * @return {void}
     */
    async uploadToAzure(file) {
      try {
        this.isBusy = true;

        let form = new FormData();
        form.append("brain", this.$store.state.brain);
        form.append("name", "microsite");
        form.append("documents", file);
        const response = await this.$rest("post", "miniapp_documents_upload", form);

        const uploadedPDF = _.first(response);
        if (!uploadedPDF) {
          throw "No pdf uploaded";
        }

        this.selectedWebPage.content = uploadedPDF;
        this.$refs["webpage-uploader"].value = null;
        this.$emit("onSave");
        this.isBusy = false;
      } catch (error) {
        console.log(error);
        this.$refs["webpage-uploader"].value = null;
        this.isBusy = false;
      }
    },
  },
};
</script>
