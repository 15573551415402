import store from "@/store/index";
import { ContentNode } from "@/helperMethods/type";
import { IFlowContentNode } from "@/store/modules/floweditor/types";
import _ from "lodash";

export const TEXT_FAIL_OPTIONS = [
  { value: "try_again", label: "Try Again" },
  { value: "skip_user_input", label: "Skip user input" },
];

export const TEXT_SUCCESS_OPTIONS = [
  { value: "skip_user_input", label: "Skip user input" },
  { value: "call_api", label: "Call API" },
  { value: "proceed", label: "Proceed to next question" },
  { value: "escalate_email", label: "Send an email escalation" },
  {
    value: "escalate_email_after_work_hours",
    label: "Send an email escalation (After office hours only)",
  },
  { value: "auto_resolve", label: "Auto resolve chat" },
];

export function previewSuccessOption(successId: string): string {
  const flowContentNode: IFlowContentNode = store.state.floweditor.contents[successId];
  const globalContentNode: ContentNode = _.get(store, `state.nodes.content[${successId}]`);

  const flowContentExists = !!flowContentNode;
  const globalContentExists = !!globalContentNode;

  if (flowContentExists) {
    return _.get(flowContentNode, "question", "");
  } else if (globalContentExists) {
    const nodeText =
      _.get(globalContentNode, "content[0].text") || _.get(globalContentNode, "content.text");

    const finalNodeTest = formatNodeText(nodeText);
    return finalNodeTest;
  } else {
    return "";
  }
}

export function getSuccessOption(successId: string): any {
  const flowContentNode: IFlowContentNode = store.state.floweditor.contents[successId];
  const globalContentNode: ContentNode = _.get(store, `state.nodes.content[${successId}]`);

  const flowContentExists = !!flowContentNode;
  const globalContentExists = !!globalContentNode;

  if (flowContentExists) {
    return flowContentNode;
  } else if (globalContentExists) {
    return globalContentNode;
  } else {
    return null;
  }
}

function formatNodeText(nodeText: string[] | string): string {
  let finalNodeText: string = "";
  if (Array.isArray(nodeText)) {
    finalNodeText = nodeText.join(",");
  } else if (typeof nodeText === "string") {
    finalNodeText = nodeText;
  }

  return finalNodeText;
}

export function isFlowLinkingToOtherFlows(flowId: string): boolean {
  const flows = _.get(store, "state.floweditor.flows", {});
  const flowExists = !!flows[flowId];
  const selectedFlow = flows[flowId];

  if (flowExists) {
    const isLinked = _.get(selectedFlow, "isLinkingToOtherFlows", false);
    return isLinked;
  }
  return false;
}
