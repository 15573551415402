import { RootState } from "@/store/types";
import { ActionTree } from "vuex/types";
import _ from "lodash";
import { worker } from "@/bootstrap";
import { WorkerEvent } from "@/workers";

const actions: ActionTree<any, RootState> = {
  GET_CONNECTED_NODES: async ({ rootState, commit }, nodeId) => {
    worker.postMessage({
      type: WorkerEvent.GET_CONNECTED_NODES,
      payload: {
        nodeId,
        nodes: _.get(rootState, "nodes") || {},
        intents: _.get(rootState, "training.dataset") || [],
        apiMappings: Object.values(_.get(rootState, "apieditor.mappings") || {}),
      },
    });
  },
};

export default actions;
