<template>
  <el-card style="margin-bottom: 20px" v-loading="loading">
    <!-- HEADER -->
    <h3 slot="header">
      {{ setting.label || name }}
      <Tooltip :label="setting.label || name" :content="setting.detail" />
    </h3>

    <!-- NOT AUTHORISED -->
    <p class="info-text" v-if="!isAuthorized">
      Only users with "supervisor" module access can configure this setting.
    </p>

    <!-- AUTHORISED -->
    <div v-if="isAuthorized">
      <!-- INFO -->
      <p class="info-text">
        This limit only applies to auto assignments. You can configure agents if they belong to at
        least one department that you are a part of. The list of agents and departments is retrieved
        from the system authentication data and not manually configured here.

        <br /><br />
        Your departments are:
        <el-tag
          v-for="(department, index) in this.getUserAuthentication?.departments"
          :key="index"
          type="primary"
          style="margin: 3px 3px 3px 0"
          size="small"
        >
          {{ department }}
        </el-tag>
      </p>

      <!-- SEARCH BAR -->
      <el-input
        v-model="searchQuery"
        placeholder="Search email"
        prefix-icon="el-icon-search"
        clearable
        class="search-bar"
      />

      <!-- PAGINATION & NO OF RECORDS -->
      <div class="block">
        <el-pagination
          :disabled="loading"
          background
          small
          layout="prev, pager, next"
          :pageSize="pageSize"
          :current-page.sync="currentPage"
          :total="noOfItems"
          @prev-click="togglePage"
          @next-click="togglePage"
          @current-change="togglePage"
          @size-change="handleSizeChange"
          style="display: inline-block"
        ></el-pagination>

        <div style="display: inline-block">
          <span>{{ noOfItems }} records</span>
        </div>
      </div>

      <!-- TABLE -->
      <el-table :data="tableData" style="width: 90%" max-height="750" stripe border>
        <!-- EMAIL / AGENT ID -->
        <el-table-column prop="agentId" label="Email" width="250" />

        <!-- DEPARTMENTS -->
        <el-table-column property="department" label="Departments">
          <template slot-scope="scope">
            <i
              v-if="scope.row.departments.length === 0"
              style="font-size: 9px"
              class="has-text-dark"
            >
              No department assigned.
            </i>

            <el-checkbox-group v-model="scope.row.queues" size="small">
              <el-checkbox
                v-for="(department, index) in scope.row.departments.filter((item) => item)"
                :key="index"
                :label="department"
                border
                style="width: 45%; margin: 0px 3px 0px 0; white-space: nowrap; overflow: hidden"
              >
              </el-checkbox>
            </el-checkbox-group>
          </template>
        </el-table-column>

        <!-- CHAT LIMIT -->
        <el-table-column prop="limit" label="Chat limit" width="200">
          <template slot-scope="scope">
            <div v-if="scope.row.limit">
              <!-- USE DEFAULT LIMIT -->
              <el-checkbox
                v-model="scope.row.limit.useDefaultLimit"
                @change="handleDefaultLimitChange(scope.row.limit)"
                label="Use default"
              />
              <!-- INPUT TEXT -->
              <el-input-number
                v-model="scope.row.limit.count"
                :min="0"
                :max="99"
                size="mini"
                :disabled="scope.row.limit.useDefaultLimit"
              />
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-card>
</template>

<script>
import Tooltip from "../../Tooltip.vue";
import gql from "graphql-tag";
import { checkRoles, getPermittedRoles } from "@/helperMethods/auth";
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";

export default {
  name: "HandoverAgentChatLimitPerUser",
  props: {
    setting: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    modules: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  components: {
    Tooltip,
  },
  data() {
    return {
      loading: true,
      defaultChatLimit: this.value["chat_limit"] || 1,
      tableData: [],
      totalRecordCount: 0,
      pageSize: 10,
      currentPage: 1,
      filter: {},
      error: false,
      searchQuery: "",
    };
  },
  computed: {
    ...mapGetters(["getUserAuthentication"]),
    noOfItems() {
      return this.totalRecordCount || 0;
    },
    isAuthorized() {
      const roles = this.getUserAuthentication?.roles || [];
      const allowedRoles = getPermittedRoles("supervisor");
      const isAuthorized = checkRoles(allowedRoles);
      const adminRole = roles && roles.includes("admin");

      return isAuthorized || adminRole;
    },
    existingSetting() {
      return _.cloneDeep(this.value?.[this.name] || []);
    },
  },
  mounted() {
    if (!_.isArray(this.value?.[this.name]))
      this.$set(this.value, this.name, this.setting.default || []);
  },
  methods: {
    handleDefaultLimitChange(limit) {
      if (limit.useDefaultLimit) {
        this.$set(limit, "count", this.defaultChatLimit);
      }
    },
    debouncedSearch: debounce(function () {
      // Only proceed if the search query has actually changed to reduce unnecessary fetches
      if (this.filter?.searchQuery !== this.searchQuery) {
        this.filter = { ...this.filter, searchQuery: this.searchQuery };
        this.currentPage = 1;

        this.fetchData();
      }
    }, 500),
    fetchData() {
      this.loading = true;

      this.$apollo.queries.getDashboardUsers.refetch({
        limit: this.pageSize,
        offset: (this.currentPage - 1) * this.pageSize,
        filter: this.filter,
      });
    },
    togglePage(pageNo) {
      this.currentPage = pageNo;
      this.fetchData();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.fetchData();
    },
  },
  watch: {
    searchQuery() {
      this.debouncedSearch();
    },
    tableData: {
      handler(newVal) {
        try {
          const settingToBeUpdated = _.cloneDeep(this.existingSetting);

          _.each(newVal, ({ agentId, limit = {}, queues, authType, departments }) => {
            const { count, useDefaultLimit } = limit;
            const intersectedQueues = _.intersection(queues, departments);

            const settingForThisAgent = {
              agentId,
              authType,
              queues: intersectedQueues,
              limit: useDefaultLimit ? this.defaultChatLimit : count,
            };

            // need the index to maintain the array order and prevent unintended changes to the JSON preview for other department users
            const foundIndex = _.findIndex(
              settingToBeUpdated,
              (setting) =>
                typeof agentId === "string" &&
                typeof agentId === "string" &&
                agentId.toLowerCase() === setting.agentId.toLowerCase()
            );
            const previousData = foundIndex > -1 ? settingToBeUpdated[foundIndex] : null;

            // Exclude agents using default configuration from being stored in the setting JSON
            const isQueueIncludingAllDepartments = queues?.length === departments?.length;
            const excludeFromSetting = useDefaultLimit && isQueueIncludingAllDepartments;

            if (!previousData && excludeFromSetting) return; // continue to next iteration
            else if (previousData && excludeFromSetting) {
              settingToBeUpdated.splice(foundIndex, 1); // remove from setting
            } else if (previousData) {
              settingToBeUpdated[foundIndex] = settingForThisAgent;
            } else if (!previousData) {
              settingToBeUpdated.push(settingForThisAgent);
            }
          });

          this.value[this.name] = settingToBeUpdated;
        } catch (error) {
          console.error(error);
          this.error = true;
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: "Failed to update Chat Limit per Agent table data. Please reload the page..",
          });
        }
      },
      deep: true,
    },
  },
  apollo: {
    getDashboardUsers() {
      const { pageSize, currentPage, filter } = this;

      return {
        query: gql`
          query paginateDashboardUsers(
            $limit: Int = 100
            $offset: Int = 0
            $sort: String = "desc"
            $filter: JSON
          ) {
            agentSupervisorAPI {
              paginateDashboardUsers(limit: $limit, offset: $offset, sort: $sort, filter: $filter) {
                count
                results {
                  email
                  authType
                  departments
                }
              }
            }
          }
        `,
        variables: {
          limit: pageSize,
          offset: (currentPage - 1) * pageSize,
          filter: filter,
        },
        fetchPolicy: "network-only",
        update(data) {
          try {
            const { count, results: parsedDashboardUsers } = _.get(
              data,
              "agentSupervisorAPI.paginateDashboardUsers",
              []
            );
            this.totalRecordCount = count;

            const myDepartments = Array.isArray(this.getUserAuthentication.departments)
              ? this.getUserAuthentication.departments
              : [];
            const newSetting = _.map(parsedDashboardUsers, (user) => {
              const userDepartments = Array.isArray(user.departments) ? user.departments : [];

              if (_.intersection(userDepartments, myDepartments).length > 0) {
                const currentAgentSetting = _.find(
                  this.existingSetting,
                  (setting) => setting.agentId === user.email
                );

                return {
                  agentId: user.email,
                  authType: user.authType,
                  departments: user.departments,
                  queues: currentAgentSetting?.queues || user.departments,
                  limit: {
                    useDefaultLimit: _.isEmpty(currentAgentSetting),
                    count: _.isInteger(currentAgentSetting?.limit)
                      ? currentAgentSetting.limit
                      : this.defaultChatLimit,
                  },
                };
              }
            }).filter(Boolean);

            this.tableData = newSetting;
          } catch (error) {
            console.error(error);
            this.$notify.error({
              title: "Error",
              position: "bottom-right",
              message: "Failed to process Chat Limit per Agent data. Please reload the page.",
            });
            this.error = true;
          } finally {
            this.loading = false;
            this.error = false;
          }
        },
        error(error) {
          console.error(error);
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: "Failed to load Chat Limit per Agent data. Please reload the page.",
          });
          this.loading = false;
          this.error = true;
        },
      };
    },
  },
};
</script>

<style>
.box-card {
  padding: 20px;
}
.box-container {
  margin-bottom: 20px;
}
.hint {
  font-size: 12px;
  color: #888;
  margin-top: 5px;
  line-height: 20px;
}
.info-text {
  font-size: 14px;
  color: grey;
  margin-bottom: 20px;
}

.search-bar {
  width: 200px !important;
  margin-bottom: 10px !important;
}
.block {
  margin-bottom: 10px;
}
</style>
