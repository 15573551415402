<template>
  <el-card style="margin-bottom: 30px">
    <h3 slot="header">Bot Redirect</h3>
    <el-row>
      <el-col :span="15">
        <el-checkbox
          v-model="enabled"
          label="Enabled"
          border
          style="margin-bottom: 15px; margin-right: 15px"
        ></el-checkbox>

        <el-button v-if="enabled" type="primary" @click="isModalOpen = true"> Add </el-button>

        <template v-if="enabled">
          <el-divider></el-divider>
          <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="name" label="API name" width="180"> </el-table-column>
            <el-table-column prop="endpoint" label="API endpoint" width="180"> </el-table-column>
            <el-table-column width="200" label="Action">
              <template slot-scope="scope">
                <el-button
                  icon="el-icon-delete"
                  type="text"
                  circle
                  round
                  size="mini"
                  @click="() => onDelete(scope.row)"
                />
              </template>
            </el-table-column>
          </el-table>

          <el-dialog title="Tips" :visible.sync="isModalOpen" width="30%" :before-close="() => {}">
            <div>
              <p style="margin-bottom: 0">API Name</p>
              <el-input placeholder="Please input" v-model="form.name"></el-input>

              <p style="margin-bottom: 0">API Endpoint</p>
              <el-input
                type="textarea"
                placeholder="Please input"
                v-model="form.endpoint"
              ></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="isModalOpen = false">Cancel</el-button>
              <el-button type="primary" @click="onConfirm">Confirm</el-button>
            </span>
          </el-dialog>
        </template>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import _ from "lodash";
import { toSlug } from "@/filters";

export default {
  name: "BotRedirect",
  props: ["name", "setting", "value", "modules"],
  computed: {
    tableData() {
      return Object.values(_.get(this.value, "botRedirect.configs", {}));
    },
  },
  watch: {
    enabled(val) {
      this.value[this.name].enabled = val;
    },
  },
  methods: {
    validateForm() {
      const slug = toSlug(this.form.name);
      if (this.value.botRedirect.configs[slug]) {
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: `This API name has been taken.`,
        });
        return false;
      }
      this.form.name = slug;
      return !this.value.botRedirect.configs[slug];
    },
    onDelete(row) {
      this.$delete(this.value.botRedirect.configs, row.name);
    },
    onConfirm() {
      if (this.validateForm()) {
        this.$set(this.value.botRedirect.configs, this.form.name, this.form);
        this.form = {};
        this.isModalOpen = false;
      }
    },
  },
  beforeMount() {
    if (!this.value[this.name]) {
      this.value[this.name] = _.cloneDeep(this.setting.default);
    } else {
      this.enabled = this.value[this.name].enabled;
    }
    if (!_.get(this.value, "botRedirect.configs")) {
      this.value.botRedirect.configs = {};
      this.$forceUpdate();
    }
  },
  data() {
    return {
      isModalOpen: false,
      enabled: false,
      form: {},
    };
  },
};
</script>
