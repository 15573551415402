<template>
  <div>
    <el-row :key="index" v-for="(transformation, index) in transformations" :gutter="10">
      <el-col :span="8" style="text-align: left">
        <el-checkbox v-model="transformation.enabled">
          {{ transformation.displayName }}
        </el-checkbox>
      </el-col>
      <el-col :span="8">
        <el-input
          placeholder="Find"
          :disabled="!transformation.enabled"
          v-model="transformation.expectedPattern"
        ></el-input>
      </el-col>
      <el-col :span="8">
        <el-input
          placeholder="Replace"
          :disabled="!transformation.enabled"
          v-model="transformation.replacement"
        ></el-input>
      </el-col>
      <div style="display: flex; align-items: center" v-if="transformation.enabled">
        <div
          v-if="!evaluateRegex(transformation.expectedPattern)"
          class="has-text-danger"
          style="line-height: 1"
        >
          Invalid regular expression for search pattern.
          <u style="cursor: pointer" @click="fixRegex(transformation)"> Auto fix? </u>
        </div>
        <div v-else-if="!transformation.replacement" style="line-height: 1" class="has-text-danger">
          Replacement cannot be blank.
        </div>
      </div>
    </el-row>
  </div>
</template>

<script>
import _ from "lodash";
import { convertRegexStringToRegex } from "@/helperMethods/import_export/transformations";

export default {
  name: "Transformations",
  props: ["transformations"],
  data() {
    return {};
  },
  methods: {
    validate() {
      return _.every(this.transformations, (transformation) => {
        if (!transformation.enabled) return true;

        const expectedPatternIsValid = this.evaluateRegex(transformation.expectedPattern);
        if (expectedPatternIsValid && transformation.replacement) return true;

        return false;
      });
    },
    evaluateRegex(expectedPattern) {
      if (!expectedPattern || typeof expectedPattern !== "string") return false;

      const isValidRegex = expectedPattern.match(/([^\/]*)(?:\/(.+)\/)(?![gimsuy]*[^gimsuy]+)/);
      if (isValidRegex === null) return false;

      const leadingCharactersBeforeRegexExist = !!isValidRegex[1];
      if (leadingCharactersBeforeRegexExist) return false;

      return true;
    },
    fixRegex(transformation) {
      const expectedPattern = transformation.expectedPattern;
      const regex = convertRegexStringToRegex(expectedPattern);
      if (regex) {
        transformation.expectedPattern = `/${regex.source}/${regex.flags}`;
      } else {
        transformation.expectedPattern = `/./i`;
      }
    },
  },
};
</script>
