import { graph } from "@/store/api";
import { RootState } from "@/store/types";
import { ActionTree } from "vuex/types";
import { PrismState } from "./types";

import gql from "graphql-tag";
import _ from "lodash";

const actions: ActionTree<PrismState, RootState> = {
  EMBED_SENTENCES: (store, { sentences }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation($sentences: [String]) {
            prismAPI {
              getEmbeddings(sentences: $sentences)
            }
          }
        `,
        variables: { sentences },
      })
      .then((response) => {
        const data = _.get(response, "data.prismAPI.getEmbeddings");
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },

  RUN_TSNE: (store, { embeddings, perplexity }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation($embeddings: JSON, $perplexity: Int) {
            prismAPI {
              getTSNEOutput(embeddings: $embeddings, perplexity: $perplexity)
            }
          }
        `,
        variables: { embeddings, perplexity },
      })
      .then((response) => {
        const data = _.get(response, "data.prismAPI.getTSNEOutput");
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
};

export default actions;
