<template>
  <section>
    <el-row :gutter="10">
      <el-col :xs="24" :md="8" :lg="6">
        <Header
          @onAdd="
            ($event) => {
              activeApi = $event;
              apis = Object.assign({ [$event.name]: $event }, apis);
            }
          "
        />

        <div style="margin: 8px 0">
          <el-input
            placeholder="Search API name or mapping name..."
            v-model="searchTerm"
            size="mini"
            class="input-with-select"
            @change="onChange"
            clearable
            prefix-icon="el-icon-search"
          >
            <!-- <el-button slot="append" ></el-button> -->
          </el-input>
        </div>
        <List :api-loading="apiLoading" :array-apis="arrayApis" @show-api-editor="showApiDetail" />
      </el-col>
      <el-col :xs="24" :md="16" :lg="18">
        <Edit :api="activeApi" @unselect="activeApi = null" />
      </el-col>
    </el-row>
  </section>
</template>

<script>
import _ from "lodash";
import List from "./List";
import Header from "./Header";
import Edit from "./Edit";
import { updateUrlQuery } from "@/helperMethods/util";

export default {
  components: { List, Edit, Header },
  props: [],
  data() {
    const searchTerm = _.get(this.$route, "query.search") || null;
    return {
      apiLoading: false,
      deletingApi: false,
      showApiNodePopover: false,
      activeApi: null,
      dialogVisible: false,
      selectedDeleteApiName: "",
      searchTerm,
    };
  },
  computed: {
    mappings() {
      return _.chain(this.$store.state).get("apieditor.mappings", {}).values().value();
    },
    apis: {
      get() {
        this.apiLoading = false;
        const apis = this.$store.state.apieditor.apis;
        return apis;
      },
      set(values) {
        this.$store.state.apieditor.apis = values;
      },
    },
    arrayApis: {
      get() {
        if (this.apis) {
          const apis = _.chain(this.apis).values().orderBy(["last_modified"], ["desc"]).value();
          if (this.searchTerm) {
            return apis.filter((api) => {
              const searchTerm = this.searchTerm.toLowerCase();
              const apiName = api.name.toLowerCase();
              const matchedApiName = apiName.includes(searchTerm);
              const mappings = this.mappings.filter((mapping) => mapping.api === api.name);
              const matchedMappingName = mappings.some((mapping) =>
                mapping.name.toLowerCase().includes(searchTerm)
              );
              return matchedApiName || matchedMappingName;
            });
          }
          return apis;
        } else {
          return [];
        }
      },
    },
  },
  methods: {
    onChange() {
      updateUrlQuery({ search: this.searchTerm });
    },
    showApiDetail(apiName) {
      this.activeApi = this.apis[apiName];
    },
    deleteApi(apiName) {
      this.deletingApi = true;
      this.apis = _.omit(this.apis, apiName);
      this.$store
        .dispatch("DELETE_API", { apiName: apiName })
        .then((deleted) => {
          this.deletingApi = false;
          if (deleted) {
            this.$message({
              type: "success",
              message: "Successfully deleted API",
            });
            this.back();
            this.handleClose();
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: "Encountered error deleting API",
          });
        });
    },
  },
};
</script>
