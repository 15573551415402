export const timezoneList = [
  { label: "(GMT-12:00) International Date Line West", value: "-12" },
  { label: "(GMT-11:00) Midway Island, Samoa", value: "-11" },
  { label: "(GMT-10:00) Hawaii", value: "-10" },
  { label: "(GMT-09:00) Alaska", value: "-9" },
  { label: "(GMT-08:00) Pacific Time (US & Canada)", value: "-8" },
  { label: "(GMT-07:00) Chihuahua, La Paz, Mazatlan", value: "-7" },
  { label: "(GMT-06:00) Central Time (US & Canada)", value: "-6" },
  { label: "(GMT-05:00) Eastern Time (US & Canada)", value: "-5" },
  { label: "(GMT-04:00) Atlantic Time (Canada)", value: "-4" },
  { label: "(GMT-03:30) Newfoundland", value: "-3.5" },
  { label: "(GMT-03:00) Brasilia", value: "-3" },
  { label: "(GMT-02:00) Mid-Atlantic", value: "-2" },
  { label: "(GMT-01:00) Cape Verde Is.", value: "-1" },
  {
    label: "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
    value: "0",
  },
  {
    label: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    value: "1",
  },
  {
    label: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius, Jerusalem, Istanbul",
    value: "2",
  },
  { label: "(GMT+03:00) Kuwait, Riyadh, Baghdad", value: "3" },
  { label: "(GMT+03:30) Tehran", value: "3.5" },
  { label: "(GMT+04:00) Abu Dhabi, Muscat", value: "4" },
  { label: "(GMT+04:30) Kabul", value: "4.5" },
  { label: "(GMT+05:00) Islamabad, Karachi, Tashkent", value: "5" },
  { label: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi", value: "5.5" },
  { label: "(GMT+05:45) Kathmandu", value: "5.75" },
  { label: "(GMT+06:00) Almaty, Novosibirsk, Astana, Dhaka", value: "6" },
  { label: "(GMT+06:30) Yangon (Rangoon)", value: "6.5" },
  { label: "(GMT+07:00) Bangkok, Hanoi, Jakarta", value: "7" },
  { label: "(GMT+08:00) Kuala Lumpur, Singapore", value: "8" },
  { label: "(GMT+09:00) Osaka, Sapporo, Tokyo", value: "9" },
  { label: "(GMT+09:30) Adelaide", value: "9.5" },
  { label: "(GMT+10:00) Canberra, Melbourne, Sydney", value: "10" },
  { label: "(GMT+11:00) Magadan, Solomon Is., New Caledonia", value: "11" },
  { label: "(GMT+12:00) Fiji, Kamchatka, Marshall Is.", value: "12" },
  { label: "(GMT+13:00) Nuku'alofa", value: "13" },
];
