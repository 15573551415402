<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    style="background: none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.402347 11.2178C0.345913 11.2182 0.290046 11.2057 0.238417 11.1811C0.186789 11.1565 0.140569 11.1204 0.102795 11.0752C0.065021 11.0299 0.036549 10.9766 0.0192505 10.9186C0.00195207 10.8607 -0.00378115 10.7994 0.00242773 10.7389L0.310365 7.71868C0.321327 7.62214 0.362196 7.53244 0.426342 7.46411L6.96902 0.405368C7.08789 0.276868 7.22908 0.174921 7.38451 0.105362C7.53994 0.0358039 7.70656 0 7.87484 0C8.04311 0 8.20973 0.0358039 8.36516 0.105362C8.52059 0.174921 8.66179 0.276868 8.78065 0.405368L10.0244 1.74722C10.1435 1.87546 10.238 2.02779 10.3025 2.19548C10.3669 2.36318 10.4001 2.54294 10.4001 2.72448C10.4001 2.90603 10.3669 3.08579 10.3025 3.25349C10.238 3.42118 10.1435 3.57351 10.0244 3.70175L3.48572 10.7562C3.42239 10.8254 3.33925 10.8695 3.24977 10.8813L0.450337 11.2135L0.402347 11.2178ZM1.09421 7.9603L0.854256 10.2988L3.02182 10.04L9.46052 3.09339C9.50526 3.04529 9.54076 2.98813 9.56498 2.92519C9.5892 2.86226 9.60167 2.79479 9.60167 2.72664C9.60167 2.6585 9.5892 2.59102 9.56498 2.52809C9.54076 2.46516 9.50526 2.408 9.46052 2.3599L8.21277 1.01373C8.16818 0.965463 8.11521 0.927165 8.05687 0.901032C7.99854 0.8749 7.936 0.861448 7.87284 0.861448C7.80968 0.861448 7.74713 0.8749 7.6888 0.901032C7.63047 0.927165 7.57749 0.965463 7.53291 1.01373L1.09421 7.9603Z"
      fill="#101820"
    />
    <path
      d="M8.80067 4.8364C8.74804 4.83673 8.69587 4.82585 8.64714 4.80438C8.59842 4.7829 8.5541 4.75127 8.51673 4.71128L6.03323 2.02326C5.99594 1.98303 5.96637 1.93527 5.94619 1.88271C5.92601 1.83015 5.91562 1.77381 5.91562 1.71692C5.91562 1.66003 5.92601 1.60369 5.94619 1.55113C5.96637 1.49857 5.99594 1.45081 6.03323 1.41058C6.07052 1.37035 6.11479 1.33844 6.16351 1.31667C6.21222 1.2949 6.26444 1.28369 6.31717 1.28369C6.36991 1.28369 6.42212 1.2949 6.47084 1.31667C6.51956 1.33844 6.56383 1.37035 6.60112 1.41058L9.09261 4.0986C9.1301 4.13871 9.15985 4.18643 9.18015 4.23901C9.20046 4.29159 9.21091 4.34798 9.21091 4.40494C9.21091 4.4619 9.20046 4.51829 9.18015 4.57087C9.15985 4.62345 9.1301 4.67117 9.09261 4.71128C9.0543 4.75231 9.00868 4.78456 8.95851 4.80606C8.90833 4.82756 8.85464 4.83789 8.80067 4.8364Z"
      fill="#101820"
    />
    <path
      d="M6.03528 4.10018L2.92181 7.45923L3.48739 8.06941L6.60085 4.71036L6.03528 4.10018Z"
      fill="#101820"
    />
    <path
      d="M11.6001 11.2179H5.20137C5.09531 11.2179 4.99359 11.1725 4.91859 11.0915C4.84359 11.0106 4.80145 10.9009 4.80145 10.7864C4.80145 10.672 4.84359 10.5623 4.91859 10.4814C4.99359 10.4004 5.09531 10.355 5.20137 10.355H11.6001C11.7061 10.355 11.8079 10.4004 11.8829 10.4814C11.9579 10.5623 12 10.672 12 10.7864C12 10.9009 11.9579 11.0106 11.8829 11.0915C11.8079 11.1725 11.7061 11.2179 11.6001 11.2179Z"
      fill="#101820"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
