<template>
  <el-row type="flex" justify="end">
    <el-button
      class="emoji-preview"
      v-for="(qr, i) in options"
      :key="`emoji-${i}`"
      circle
      v-text="qr.text"
      style=""
    />
  </el-row>
</template>

<script>
export default {
  name: "FeedbackCollections",
  computed: {
    options() {
      const options = _.get(this, "$store.state.modules.faq.feedbackCollection.options", []);
      return options;
    },
  },
};
</script>

<style>
.emoji-preview {
  padding: 5px !important;
  padding-right: 7px !important;
  margin: 0 !important;
  margin-left: 5px !important;
  font-size: 18px;
}
</style>
