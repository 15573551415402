<template>
  <div style="position: relative; height: 100%">
    <EditorHeader />

    <el-tabs @tab-click="onTabClick" v-model="activeTab" class="tab-container">
      <el-tab-pane label="Nodes" name="nodes" :lazy="true">
        <NodesEditor />
      </el-tab-pane>

      <el-tab-pane v-if="isFlowEditorModuleEnabled" label="Flows" name="flows" :lazy="true">
        <FlowEditor :editor-option="editorOption" />
      </el-tab-pane>

      <el-tab-pane label="Integrations" name="apieditor" :lazy="true">
        <APIIntegration :editor-option="editorOption" />
      </el-tab-pane>

      <el-tab-pane
        label="Form"
        name="form"
        :lazy="true"
        v-if="isFormModuleEnabled"
        class="full-grid"
      >
        <Form :editor-option="editorOption" />
      </el-tab-pane>

      <el-tab-pane
        v-if="isWebpageEditorModuleEnabled"
        label="Web Pages"
        name="webpageeditor"
        :lazy="true"
      >
        <WebPageEditor />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";
import { mapState } from "vuex";

import EditorHeader from "./Header/Index";
import FlowEditor from "@/components/FlowEditor/Index.vue";
import NodesEditor from "./Nodes";
import APIIntegration from "@/components/APIIntegration.vue";
import WebPageEditor from "@/components/WebPageEditor/Index.vue";
import Form from "@/components/Form/Index.vue";
import _ from "lodash";

@Component({
  components: {
    FlowEditor,
    APIIntegration,
    EditorHeader,
    NodesEditor,
    WebPageEditor,
    Form,
  },
  methods: {
    onTabClick(event) {
      if (event.name === "flows") {
        this.$router.push("/editor/flow");
      } else if (event.name === "form") {
        this.$router.push("/editor/form");
      } else if (event.name === "apieditor") {
        this.$router.push("/editor/integrations");
      } else if (this.$route.name !== "editor") {
        this.$router.push("/editor");
      }
    },
    checkActiveTab() {
      switch (this.$route.name) {
        case "editor-form":
          this.activeTab = "form";
          break;
        case "editor-integrations":
          this.activeTab = "apieditor";
          break;
        case "editor-flow":
          this.activeTab = "flows";
          break;
        case "editor":
        default:
          this.activeTab = "nodes";
      }
    },
  },
  mounted() {
    this.checkActiveTab();
    this.$eventBus.$on("editorSwitchTab", (tab) => {
      this.activeTab = tab;
    });
  },
  computed: {
    ...mapState({
      modules: "modules",
    }),

    /**
     * @description Facebook module enabled
     * @return {boolean}
     */
    isFacebookModuleEnabled() {
      return this.modules.facebook && this.modules.facebook.enabled;
    },

    /**
     * @description Webpage module enabled
     * @return {boolean}
     */
    isWebpageEditorModuleEnabled() {
      return this.modules.webpage_editor && this.modules.webpage_editor.enabled;
    },

    /**
     * @description Floweditor module enabled
     * @return {boolean}
     */
    isFlowEditorModuleEnabled() {
      return this.modules.floweditor && this.modules.floweditor.enabled;
    },

    isFormModuleEnabled() {
      return this.modules.form && this.modules.form.enabled;
    },
  },
})
export default class Editor extends Vue {
  constructor() {
    super();
  }

  activeTab = "nodes";
  editorOption = {
    tabSize: 2,
    mode: {
      name: "javascript",
      json: true,
    },
    lineNumbers: true,
    theme: "monokai",
    scrollbarStyle: "null",
    keyMap: "sublime",
  };
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/colors.scss";

$border-color: $color-light;
$box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);

.row-fluid {
  white-space: nowrap;
  overflow: auto;
}

.widget-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.full-grid {
  display: grid;
  align-self: stretch;
  border: 1px solid $border-color;
  box-shadow: $box-shadow;
  border-radius: 5px;
}

.tab-container {
  height: 100%;
  position: relative;
  display: grid;
  grid-template-rows: min-content;
  ::v-deep .el-tabs__content {
    padding: 5px;
  }
}
</style>
