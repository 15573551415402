<template>
  <el-form-item prop="data">
    <el-autocomplete
      v-model="value.data.mappingName"
      class="autocomplete-list autocomplete-list-target"
      style="margin-top: 0"
      placeholder=""
      :fetch-suggestions="getApiMappingsSuggestions"
    >
      <div slot="prepend" style="width: 40px">API ID</div>
    </el-autocomplete>
  </el-form-item>
</template>

<script>
import _ from "lodash";

export default {
  name: "EventNodeTypeCallApi",
  props: ["value"],
  beforeMount() {
    const isMappingNameExist = _.has(this.value.data, "mappingName");
    if (!isMappingNameExist) {
      this.value.data = {
        mappingName: "",
      };
    }

    if (_.isEmpty(this.$store.state.apieditor.mappings)) {
      this.$store.dispatch("FETCH_ALL_MAPPINGS", {
        brain: this.$store.state.brain,
      });
    }
  },
  methods: {
    /**
     * @description Search available api mappings
     * @param {string} value
     * @return {[]}
     */
    searchApiMappings(value) {
      if (value.length === 0) {
        return this.$store.state.search.api_mappings.list;
      } else {
        return this.$store.state.search.api_mappings.search(value);
      }
    },

    /**
     * @description el-autocomplete on fetchSuggestions event handler
     * @param {string} value
     * @param {Function} cb
     * @return {void}
     */
    getApiMappingsSuggestions(value, cb) {
      cb(this.searchApiMappings(value));
    },
  },
};
</script>
