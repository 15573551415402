<template>
  <el-col :md="24" :lg="12">
    <el-card style="margin-top: 10px">
      <span style="font-size: 24px; font-weight: lighter">
        <b>Profile Settings</b>
      </span>
      <br />
      <br />
      <el-form
        ref="profileSettingsForm"
        :model="profileSettingsForm"
        :label-position="'left'"
        label-width="160px"
        :rules="profileSettingsFormRules"
      >
        <el-form-item label="WhatsApp User ID" prop="userid">
          <el-input v-model="profileSettingsForm.userid" disabled />
        </el-form-item>
        <el-form-item label="About" prop="about">
          <el-input v-model="profileSettingsForm.about" />
        </el-form-item>
        <el-form-item label="Profile Picture" prop="profile_picture">
          <img
            v-if="
              profileSettingsForm.profile_picture &&
              profileSettingsForm.profile_picture_type === 'url'
            "
            width="200"
            height="200"
            :src="profileSettingsForm.profile_picture"
          />
          <img
            v-else-if="
              profileSettingsForm.profile_picture && profileSettingsForm.profile_picture_type
            "
            width="200"
            height="200"
            :src="`data:${profileSettingsForm.profile_picture_type};base64,${profileSettingsForm.profile_picture}`"
          />

          <el-upload
            class="upload-demo upload-text-shrink"
            ref="upload"
            action="#"
            :before-upload="beforeUpload"
          >
            <el-button
              :loading="deletingProfilePhoto"
              slot="trigger"
              size="small"
              type="primary"
              style
            >
              Upload
            </el-button>
            <el-button
              :loading="deletingProfilePhoto"
              type="primary"
              plain
              size="small"
              style="margin-left: 10px"
              @click="reset_profile_picture"
            >
              Reset
            </el-button>
            <el-button
              :loading="deletingProfilePhoto"
              type="danger"
              size="small"
              style="margin-left: 10px"
              @click="deleteProfilePhoto"
              v-if="!cloudAPISettings?.enabled"
            >
              Delete
            </el-button>
            <el-tooltip
              effect="dark"
              content="Minimum dimensions: 192 x 192, Max size: 5mb"
              placement="bottom"
            >
              <i class="has-text-grey el-icon-question" style="margin-left: 0.5em"></i>
            </el-tooltip>
          </el-upload>
        </el-form-item>
        <el-form-item label="Address" prop="address">
          <el-input v-model="profileSettingsForm.address" />
        </el-form-item>
        <el-form-item label="Business Description" prop="description">
          <el-input v-model="profileSettingsForm.description" />
        </el-form-item>
        <el-form-item label="Business Category" prop="category">
          <el-select
            placeholder="Select Category"
            v-model="profileSettingsForm.category"
            clearable
            v-if="cloudAPISettings?.enabled"
            filterable
          >
            <el-option
              v-for="category in businessCloudAPICategories"
              :key="category.value"
              :label="category.label"
              :value="category.value"
            ></el-option>
          </el-select>
          <el-select
            placeholder="Select Category"
            v-model="profileSettingsForm.category"
            clearable
            v-else
            filterable
          >
            <el-option
              v-for="category in businessCategories"
              :key="category"
              :label="category"
              :value="category"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Contact Email" prop="email">
          <el-input v-model="profileSettingsForm.email" />
        </el-form-item>
        <el-form-item label="Website 1" prop="website1">
          <el-input v-model="profileSettingsForm.website1" />
        </el-form-item>
        <el-form-item label="Website 2" prop="website2">
          <el-input v-model="profileSettingsForm.website2" />
        </el-form-item>
        <el-button :loading="saving" type="primary" @click="save">Save</el-button>
      </el-form>
    </el-card>
  </el-col>
</template>

<script>
export default {
  name: "BusinessAccountSettingsTab",
  components: {},
  props: {
    profile_settings: Object,
    application_settings: Object,
    wa_endpoint: String,
    cloudAPISettings: Object,
  },
  data() {
    return {
      businessCategories: [
        "Automotive",
        "Beauty, Spa and Salon",
        "Clothing and Apparel",
        "Education",
        "Entertainment",
        "Event Planning and Service",
        "Finance and Banking",
        "Food and Grocery",
        "Public Service",
        "Hotel and Lodging",
        "Medical and Health",
        "Non-profit",
        "Professional Services",
        "Shopping and Retail",
        "Travel and Transportation",
        "Restaurant",
        "Other",
      ],
      businessCloudAPICategories: [
        { label: "Automotive", value: "AUTO" },
        { label: "Beauty, Spa and Salon", value: "BEAUTY" },
        { label: "Clothing and Apparel", value: "APPAREL" },
        { label: "Education", value: "EDU" },
        { label: "Entertainment", value: "ENTERTAIN" },
        { label: "Event Planning and Service", value: "EVENT_PLAN" },
        { label: "Finance and Banking", value: "FINANCE" },
        { label: "Food and Grocery", value: "GROCERY" },
        { label: "Public Service", value: "GOVT" },
        { label: "Hotel and Lodging", value: "HOTEL" },
        { label: "Medical and Health", value: "HEALTH" },
        { label: "Non-profit", value: "NONPROFIT" },
        { label: "Professional Services", value: "PROF_SERVICES" },
        { label: "Shopping and Retail", value: "RETAIL" },
        { label: "Travel and Transportation", value: "TRAVEL" },
        { label: "Restaurant", value: "RESTAURANT" },
        { label: "Other", value: "OTHER" },
      ],
      saving: false,
      original_profile_picture: "",
      original_profile_picture_type: "",
      profileSettingsFormRules: {},
      profileSettingsForm: {
        userid: "",
        about: "",
        profile_picture: "",
        profile_picture_type: "",
        address: "",
        description: "",
        category: "",
        email: "",
        website1: "",
        website2: "",
        profile_picture_handle: "",
      },
      deletingProfilePhoto: false,
    };
  },
  mounted() {
    this.updateApplicationSettingDetails(this.application_settings);
    this.updateProfileSettingDetails(this.profile_settings);
  },
  methods: {
    updateProfileSettingDetails(val) {
      const form = this.$data.profileSettingsForm;
      form.about = val?.profile?.about;
      form.profile_picture = val?.profile?.picture;
      form.profile_picture_type = val?.profile?.picture_type;
      this.$data.original_profile_picture = val?.profile?.picture;
      this.$data.original_profile_picture_type = val?.profile?.picture_type;
      form.address = val?.business_profile?.address;
      form.description = val?.business_profile?.description;
      form.category = val?.business_profile?.vertical;
      form.email = val?.business_profile?.email;
      form.website1 = _.get(val, "business_profile.websites[0]", "");
      form.website2 = _.get(val, "business_profile.websites[1]", "");
    },
    updateApplicationSettingDetails(val) {
      const form = this.$data.profileSettingsForm;
      form.userid = val.wa_id;
    },
    beforeUpload(file) {
      if (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg") {
        if (file.size <= 5242880) {
          // Check if file size is below 5MB
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            var img = new Image();
            img.onload = () => {
              if (img.naturalWidth < 192 || img.naturalHeight < 192) {
                this.$notify.error({
                  title: "Upload failed",
                  showClose: true,
                  message:
                    "Failed to upload! Dimensions did not meet the minimum requirement of 192 x 192!",
                  position: "bottom-right",
                });
              } else {
                if (this.cloudAPISettings?.enabled) {
                  this.upload_profile_picture_cloud_api(file);
                }
                const splitBase64String = reader.result.split(",");
                this.profileSettingsForm.profile_picture = splitBase64String[1];
                this.profileSettingsForm.profile_picture_type = file.type;
              }
            };
            img.src = reader.result;
          };
        } else {
          this.$notify.error({
            title: "Upload failed",
            showClose: true,
            message: "Failed to upload! File size exceeded 5MB!",
            position: "bottom-right",
          });
        }
      } else {
        this.$notify.error({
          title: "Upload failed",
          showClose: true,
          message: "Failed to upload! Only JPG, JPEG or PNG files are accepted!",
          position: "bottom-right",
        });
      }
    },
    upload_profile_picture() {},
    upload_profile_picture_cloud_api(file) {
      this.$store.dispatch("WHATSAPP_UPLOAD_PROFILE_PHOTO_CLOUD", file).then((data) => {
        this.profileSettingsForm.profile_picture_handle = data.h;
      });
    },
    reset_profile_picture() {
      this.profileSettingsForm.profile_picture = this.$data.original_profile_picture;
      this.profileSettingsForm.profile_picture_type = this.$data.original_profile_picture_type;
    },
    deleteProfilePhoto() {
      this.$confirm("Are you sure you want delete the existing profile photo?", "", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
      })
        .then((_) => {
          this.$data.deletingProfilePhoto = true;
          this.$store
            .dispatch("WHATSAPP_DELETE_PROFILE_PHOTO", {
              data: { wa_endpoint: this.wa_endpoint },
            })
            .then((data) => {
              this.$data.deletingProfilePhoto = false;
              this.$notify.success({
                title: "Delete success",
                position: "bottom-right",
                message: "Profile photo deleted!",
              });
            })
            .catch((err) => {
              this.$data.deletingProfilePhoto = false;
              this.$notify.error({
                title: "Delete failed",
                showClose: true,
                message: "Error occured while deleting profile photo!",
                position: "bottom-right",
              });
            });
        })
        .catch((_) => {});
    },
    save() {
      this.$refs.profileSettingsForm.validate((valid) => {
        if (valid) {
          this.$data.saving = true;
          const {
            about,
            profile_picture,
            profile_picture_type,
            address,
            description,
            category,
            email,
            website1,
            website2,
            profile_picture_handle,
          } = this.profileSettingsForm;

          const profile = { about };
          if (
            profile_picture !== this.original_profile_picture &&
            profile_picture_type !== this.original_profile_picture_type
          ) {
            profile.picture = profile_picture;
            profile.picture_type = profile_picture_type;
          }
          let business_profile;

          business_profile = {
            address,
            description,
            email,
            vertical: category,
            websites: [website1, website2],
          };

          if (this.cloudAPISettings?.enabled) {
            business_profile = {
              ...business_profile,
              about,
            };
            if (!_.isEmpty(profile_picture_handle)) {
              business_profile.profile_picture_handle = profile_picture_handle;
            }
          }
          this.$store
            .dispatch("UPDATE_WHATSAPP_PROFILE_SETTINGS", {
              data: {
                wa_endpoint: this.wa_endpoint,
                profile,
                business_profile,
              },
            })
            .then((data) => {
              this.$data.saving = false;
              this.$notify.success({
                title: "Update success",
                position: "bottom-right",
                message: "Profile settings updated!",
              });
            })
            .catch((err) => {
              const errorMessage = _.get(err, "response.data.error.message", err.message);
              this.$data.saving = false;
              this.$notify.error({
                title: "Update failed",
                showClose: true,
                message: errorMessage,
                position: "bottom-right",
              });
            });
        } else {
          return false;
        }
      });
    },
  },

  watch: {
    application_settings(val) {
      this.updateApplicationSettingDetails(val);
    },
    profile_settings(val) {
      this.updateProfileSettingDetails(val);
    },
  },
};
</script>
<style>
.upload-demo.upload-text-shrink .el-upload.el-upload--text {
  width: unset;
}
</style>
