<template>
  <div>
    <span style="font-size: 12px; margin-right: 0.5em">Allow user feedback</span>
    <el-select
      filterable
      size="mini"
      @change="handleChange"
      :value="feedbackCollectionMode"
      placeholder="Select"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
    <el-tooltip effect="dark" :content="detail" placement="top-end">
      <i class="has-text-dark el-icon-question" style="font-size: 14px; margin-left: 0.5em" />
    </el-tooltip>
  </div>
</template>

<script>
import _get from "lodash/get";
import _cloneDeep from "lodash/cloneDeep";

export default {
  name: "UserFeedbackMode",
  props: ["node"],
  data() {
    return {
      options: [
        {
          label: "Default",
          value: "default",
        },
        {
          label: "Allow",
          value: "allow",
        },
        {
          label: "Disallow",
          value: "disallow",
        },
      ],
    };
  },
  computed: {
    feedbackCollectionMode() {
      return _get(this.node, "options.feedbackCollectionMode", "default");
    },
    detail() {
      const defaultValue = _get(
        this,
        "$store.state.modules.faq.feedbackCollection.defaultContentNodeFeedback",
        false
      );
      return `Default option will use Settings > FAQ > End User Feedback Collection. Current Value: ${defaultValue}`;
    },
  },
  methods: {
    handleChange(value) {
      const newNode = _cloneDeep(this.node);
      if (!newNode.options) {
        newNode.options = {
          feedbackCollectionMode: value,
        };
      } else {
        newNode.options.feedbackCollectionMode = value;
      }
      this.$emit("updateNode", newNode);
    },
  },
};
</script>

<style scoped>
.el-select-dropdown__item {
  font-size: 12px;
}
</style>
