<template>
  <div class="tab-campaign">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span
          class="automation-tab"
          :class="{ active: activeTab === 'create-automation' }"
          @click="goToCreateAutomation"
        >
          Create Automation
        </span>
        <span
          class="automation-tab"
          :class="{ active: activeTab === 'automation-list' }"
          @click="goToAutomationList"
        >
          Automation List
        </span>
        <span
          class="automation-tab"
          :class="{ active: activeTab === 'automation-history' }"
          @click="goToAutomationHistory"
        >
          Execution History
        </span>

        <div class="automation-tab-btn" v-if="activeTab === 'create-automation'">
          <el-row :gutter="10" style="text-align: right">
            <el-col :span="12" style="margin-top: 8px">
              <el-switch
                v-model="automation.enabled"
                active-color="#13ce66"
                active-text="Enabled"
                inactive-text="Disabled"
                @change="changeStatus"
                v-if="canChangeStatus && !saving"
              >
              </el-switch>
              <el-switch
                v-model="automation.enabled"
                active-color="#13ce66"
                active-text="Enabled"
                inactive-text="Disabled"
                disabled
                v-else-if="canChangeStatus"
              >
              </el-switch>
            </el-col>
            <el-col :span="4" style="text-align: right; margin-top: 8px">
              <span v-if="saving"> Saving... </span>
              <span v-else-if="saved"> Saved </span>
              <span v-else> &nbsp; </span>
            </el-col>
            <el-col :span="8">
              <el-button class="automation-btn" size="small" plain @click="createNewAutomation">
                <i class="el-icon-plus"></i> Create New
              </el-button>
            </el-col>
          </el-row>
        </div>
        <div class="automation-tab-btn" v-else-if="activeTab === 'automation-list'" style="text-align: right">
          <el-button class="automation-btn" size="small" plain @click="refreshList">
            <i class="el-icon-refresh"></i> Refresh
          </el-button>
        </div>
        <div class="automation-tab-btn" v-else-if="activeTab === 'automation-history'" style="text-align: right">
          <el-button class="automation-btn" size="small" plain @click="refreshHistory">
            <i class="el-icon-refresh"></i> Refresh
          </el-button>
        </div>
      </div>
      <div v-if="activeTab === 'create-automation'">
        <CreateAutomation
          @saving="savingAutomation"
          @enable="enableChangeStatus"
          ref="newautomation"
        ></CreateAutomation>
      </div>
      <div v-else-if="activeTab === 'automation-list'">
        <AutomationList @edit="editAutomation" ref="automationlist"></AutomationList>
      </div>
      <div v-else-if="activeTab === 'automation-history'">
        <AutomationHistory ref="automationhistory"></AutomationHistory>
      </div>
    </el-card>
  </div>
</template>

<script>
import CreateAutomation from "./CreateAutomation.vue";
import AutomationList from "./AutomationList.vue";
import AutomationHistory from "./AutomationHistory.vue";

export default {
  components: { 
    CreateAutomation,
    AutomationList,
    AutomationHistory,
  },
  mounted() {},
  data() {
    return {
      activeTab: "create-automation",
      automation: {
        id: null,
        name: "",
        enabled: false,
      },
      saving: false,
      saved: false,
      canChangeStatus: false,
    };
  },
  methods: {
    goToCreateAutomation() {
      this.activeTab = "create-automation";
    },
    goToAutomationList() {
      this.saved = false;
      this.saving = false;
      this.activeTab = "automation-list";
    },
    goToAutomationHistory() {
      this.saved = false;
      this.saving = false;
      this.activeTab = "automation-history";
    },
    createNewAutomation() {
      this.automation.enabled = false;
      this.$refs.newautomation.createAutomation();
    },
    savingAutomation(value) {
      if (this.saving && this.saving != value) {
        this.saved = true;
        setTimeout(() => {
          this.saved = false;
        }, 5000);
      }
      this.saving = value;
    },
    savedAutomation(value) {
      this.saved = value;
    },
    editAutomation(id) {
      this.automation.enabled = false;
      this.activeTab = "create-automation";
      this.$nextTick(() => {
        this.$refs.newautomation.editAutomation(id);
      });
    },
    enableChangeStatus(value) {
      this.canChangeStatus = value;
    },
    changeStatus(status) {
      this.$refs.newautomation.changeStatus(status);
    },
    refreshList() {
      this.$refs.automationlist.findAll();
    },
    refreshHistory() {
      this.$refs.automationhistory.refreshList();
    },
  },
};
</script>

<style lang="scss">
.tab-campaign {
  padding: 10px;
  font-size: 12px;
}
.automation-tab {
  padding-right: 20px;
  font-size: 14px;
  cursor: pointer;
}
.automation-tab.active {
  font-weight: 600;
}
.automation-tab-btn {
  float: right;
  width: 400px;
}
.automation-btn.el-button.is-plain {
  background: #ffffff;
  border-color: #1d57d8;
  color: #1d57d8;
  font-size: 13px;
  font-weight: 600;
  padding: 9px 12px;
}
.automation-btn.el-button.is-plain span i {
  font-size: 10px;
  font-weight: 600;
  margin-right: 5px;
}
.automation-btn.el-button.is-plain.active,
.automation-btn.el-button.is-plain:hover,
.automation-btn.el-button.is-plain:focus {
  background: #1d57d8;
  border-color: #1d57d8;
  color: #ffffff;
}
</style>
