<template>
  <el-button
    style="margin-left: 10px"
    type="text"
    icon="el-icon-delete"
    :disabled="isBusy"
    :loading="isBusy"
    @click="onDelete"
  >
  </el-button>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["selected"],
  computed: {
    ...mapGetters(["storageType", "isAzureStorage", "isCephStorage"]),
  },
  data() {
    return {
      isBusy: false,
    };
  },
  methods: {
    async onDelete() {
      if (this.isBusy) {
        return;
      }
      try {
        const shouldDelete = await this.$confirm(
          "This will permanently delete all files in selected folders. Continue?",
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        );
        this.delete();
      } catch (error) {
        console.log("delete cancel");
      }
    },

    async delete() {
      if (this.isBusy) {
        return;
      }
      this.isBusy = true;
      try {
        for (const folder in this.selected) {
          for (let i = 0; i < this.selected[folder].length; i++) {
            const file = this.selected[folder][i];
            const form = new FormData();
            if (this.isAzureStorage) {
              form.append("filename", file.filepath);
              await this.$rest("post", "deleteFile", form);
            } else if (this.isCephStorage) {
              const fileName = file.name;
              const folderName = file.folder;
              form.append("fileName", fileName);
              if (folderName) {
                form.append("folderName", folderName);
              }
              await this.$rest("post", "miniapp_ceph_delete_file", form);
            }
          }
        }
        this.$emit("afterDelete");
      } catch (error) {
        console.log(error);
        this.$notify({
          message: "Error occurred while trying to delete!",
          type: "error",
        });
      } finally {
        this.isBusy = false;
      }
    },
  },
};
</script>
