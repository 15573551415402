<template>
  <codemirror :ref="ref" v-model="code" :options="settings" />
</template>
<script>
import codemirorEditorMixin from "@/mixins/codemirrorEditor";
export default {
  props: ["value"],
  mixins: [codemirorEditorMixin],
  data() {
    return {
      mode: "text/x-yaml",
      ref: "yamlEditor",
    };
  },
  computed: {
    code: {
      get() {
        return this.value;
      },
      set(yaml) {
        this.$emit("input", yaml);
      },
    },
  },
};
</script>

<style lang="scss">
@import "~codemirror/theme/monokai.css";
.vue-codemirror {
  border: 1px solid #e6ebf5 !important;
}
</style>
