<template>
  <el-row>
    <el-col :span="12">
      <el-form :model="node.content">
        <el-form-item>
          <el-input
            type="text"
            placeholder="Optional"
            maxlength="60"
            v-model="node.content.options.header"
            show-word-limit
          >
            <template slot="prepend">Header</template>
          </el-input>
        </el-form-item>
        <el-form-item
          prop="text"
          :rules="[{ required: true, trigger: 'blur', message: 'Please input content body' }]"
        >
          <el-input
            type="textarea"
            placeholder="Add body text"
            maxlength="1024"
            show-word-limit
            v-model="node.content.text"
            rows="5"
          />
        </el-form-item>
        <el-form-item>
          <el-input
            type="text"
            placeholder="Optional"
            maxlength="60"
            v-model="node.content.options.footer"
            show-word-limit
          >
            <template slot="prepend">Footer</template>
          </el-input>
        </el-form-item>
        <el-form-item
          :prop="'options' + '.' + 'listHeader'"
          :rules="[{ required: true, trigger: 'blur', message: 'Please input list header' }]"
        >
          <el-input
            type="text"
            placeholder="Required"
            maxlength="20"
            v-model="node.content.options.listHeader"
            show-word-limit
          >
            <template slot="prepend">List Header</template>
          </el-input>
        </el-form-item>
        <transition-group name="section-list">
          <div
            class="interactive-message-section"
            v-for="(section, index) in node.content.options.sections"
            :key="index + 1"
            :style="{
              borderColor: isSectionValid(section) ? '$color-light' : 'red',
            }"
          >
            <el-form-item>
              <el-input type="text" maxlength="24" v-model="section.title" show-word-limit>
                <template slot="prepend">Section Title</template>
              </el-input>
            </el-form-item>
            <Buttons
              v-model="section.rows"
              :editable="editable"
              @addButton="$emit('addButton', index)"
              @save-side-effect="$emit('save-side-effect')"
              :nodeType="node.type"
              :limit="10"
              :totalRows="totalRows"
            />
            <button
              v-if="index > 0"
              class="section-delete-button"
              @click.prevent="deleteSection(index)"
            >
              <i class="el-icon-close"></i>
            </button>
            <div
              v-if="!isSectionValid(section)"
              class="has-text-danger"
              style="margin-top: 10px; font-size: 14px"
            >
              <span>
                <i class="el-icon-warning"></i> Please add at least 1 button to the section
              </span>
            </div>
          </div>
        </transition-group>

        <div style="margin: 10px 0" v-if="totalRows < 10">
          <el-button type="primary" @click="addSection">Add Section</el-button>
        </div>
      </el-form>
    </el-col>
    <el-col :span="12">
      <div style="text-align: center">
        <p>For List Messages, this is how the parts fit together:</p>
        <img src="@/assets/preview-wa.png" alt="" width="500" />
      </div>
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: "WhatsAppList",
  components: {
    Buttons: () => import("@/components/Buttons/Index"),
  },
  props: ["node", "editable"],
  data() {
    return {};
  },
  computed: {
    totalRows() {
      return this.node.content.options.sections.reduce((total, item) => {
        return item.rows.length + total;
      }, 0);
    },
  },
  methods: {
    validationStyle(field) {
      return this["valid_" + field] ? null : { color: "red" };
    },
    addSection() {
      this.node.content.options.sections.push({
        title: "",
        rows: [],
      });
    },
    deleteSection(index) {
      this.$confirm("Are you sure you want to delete this section? ", "Confirmation", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        cancelButtonClass: "section-delete-btn-cancel",
        confirmButtonClass: "section-delete-btn-confirm",
        type: "warning",
      }).then(() => {
        this.node.content.options.sections.splice(index, 1);
      });
    },
    isSectionValid(section) {
      return section.rows.length > 0;
    },
  },
};
</script>
<style scoped lang="scss">
@import "../assets/scss/colors.scss";

.interactive-message-section {
  border: 1px solid $color-light;
  padding: 0px 25px;
  padding-top: 25px;
  padding-bottom: 15px;
  margin: 10px 0;
  border-radius: 5px;
  position: relative;
}

.section-delete-button {
  position: absolute;
  right: 6px;
  top: 6px;
  border: 0;
  padding: 0;
  background: #fff;
  font-size: 16px;
  color: #333;
}
.section-delete-button:hover {
  color: $color-danger;
}

.section-list-enter-active,
.section-list-leave-active {
  transition: all 0.5s ease;
}
.section-list-enter-from,
.section-list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.section-delete-btn-cancel {
  background: $color-primary;
  color: #fff;
}
.section-delete-btn-cancel:hover {
  background: $color-primary;
  color: #fff;
}

.section-delete-btn-confirm {
  background: #fff !important;
  color: $color-dark !important;
  border: 1px solid $color-light !important;
}

.section-delete-btn-confirm:hover {
  color: $color-primary !important;
  border-color: lighten($color-primary, 45) !important;
  background-color: lighten($color-primary, 45) !important;
}
</style>
