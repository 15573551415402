<template>
  <div>
    <el-form-item prop="queue_name">
      <el-input v-model="value.data.queue_name">
        <template slot="prepend">Queue Name</template>
      </el-input>
    </el-form-item>
    <el-form-item prop="keyvals" label>
      <el-input v-model="value.data.key_values" :placeholder="placeholder">
        <template slot="prepend">Key Values</template>
      </el-input>
    </el-form-item>

    <EventNodeSelectorNext v-model="value" />
  </div>
</template>

<script>
import EventNodeSelectorNext from "../Next.vue";

export default {
  name: "EventNodeTypeAzureQueue",
  components: {
    EventNodeSelectorNext,
  },
  props: ["value"],
  data() {
    return {
      placeholder: "key1=value2;key2={{var}};key3=value3",
    };
  },
};
</script>
