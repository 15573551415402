<template>
  <img
    v-if="!isSrcIconEmpty"
    :src="srcIcon"
    alt=""
    :style="styleEmail"
    style="margin-right: 15px"
  />
  <div v-else style="margin-right: 15px; width: 18px"></div>
</template>

<script>
import MailIcon from "../assets/message_icon.svg";
import WhatsAppIcon from "../assets/phone_circle_icon.svg";
import PhoneIcon from "../assets/phone_icon.svg";
import WebChatIcon from "../assets/web_chat_icon.svg";

import { outReachFilterChannel } from "@/helperMethods/outreach";
import * as _ from "lodash";

export default {
  props: ["channel"],
  data() {
    return {
      srcIcon: "",
      outReachFilterChannel,
      styleEmail: "",
    };
  },
  mounted() {
    this.srcIcon = this.getIconByChannel();
  },
  computed: {
    isSrcIconEmpty() {
      return this.srcIcon.length === 0;
    },
  },
  methods: {
    getIconByChannel() {
      if (!this.channel) return "";
      const channel = this.channel.replace(" ", "").toLowerCase();
      const { Email, Phone, Whatsapp } = outReachFilterChannel;
      // if (Email.includes(channel)) {
      //   this.styleEmail = "margin-left:-5px";
      //   return MailIcon;
      // }
      // if (outReachFilterChannel["Web chat"].includes(channel)) return WebChatIcon;
      if (Whatsapp.includes(channel)) return WhatsAppIcon;
      // if (Phone.includes(channel)) return PhoneIcon;
      return "";
    },
  },
  watch: {
    primaryContact: {
      handler() {
        this.srcIcon = this.getIconByChannel();
      },
    },
  },
};
</script>

<style></style>
