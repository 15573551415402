<template>
  <iframe :src="previewContent" style="width: 100%; height: 100%; border: 0"></iframe>
</template>

<script>
import _ from "lodash";

export default {
  props: ["selectedWebPage", "isDisabled"],
  computed: {
    previewContent() {
      if (this.selectedWebPage.type === "pdf") {
        const url = _.get(this.selectedWebPage, "content.url", "");
        return url;
      } else {
        const html = `<head>${this.selectedWebPage.head_published}</><body>${this.selectedWebPage.content_published}</body>`;
        return "data:text/html," + encodeURIComponent(html);
      }
    },
  },
};
</script>
