import { MutationTree } from "vuex/types";
import Vue from "vue";
import { AccessControlState } from "./types";
import _ from "lodash";
import moment from "moment";

const mutations: MutationTree<AccessControlState> = {
  SET_AUTH_USERS(state, payload) {
    // state.livechats = _.cloneDeep(payload);
    let data = [];
    data = payload;
    const users: any[] = _.cloneDeep(data);
    _.forEach(users, (user) => {
      if (!user.user_metadata) {
        user.user_metadata = {
          name: "",
        };
      }
      // convert data structure just for use in Element's table
      const newRole: { [index: string]: boolean } = {
        admin: false,
        editor: false,
        agent: false,
      };

      user.app_metadata.roles = _.reduce(
        user.app_metadata.roles,
        (memory, role: string) => {
          memory[role] = true;
          return memory;
        },
        newRole
      );
    });
    state.auth0.users = users;
    state.auth0.usersStatic = _.cloneDeep(users);
  },
  SET_AUTH_USER_TOTAL(state, payload) {
    state.auth0.total = payload;
  },
  ADD_AUTH_USER(state, payload) {
    const user: any = _.cloneDeep(payload);
    if (!user.user_metadata) {
      user.user_metadata = {
        name: "",
      };
    }
    // convert data structure just for use in Element's table
    const newRole: { [index: string]: boolean } = {
      admin: false,
      editor: false,
      agent: false,
    };

    user.app_metadata.roles = _.reduce(
      user.app_metadata.roles,
      (memory, role: string) => {
        memory[role] = true;
        return memory;
      },
      newRole
    );

    state.auth0.users.push(user);
  },
  DELETE_AUTH_USER(state, email) {
    const notUserWithEmail = _.remove(state.auth0.users, (user) => user.email !== email);
    state.auth0.users = notUserWithEmail;
  },
};

export default mutations;
