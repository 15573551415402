<template>
  <div v-if="setting.type === 'tag'">
    <span style="margin-left: 1.5em; margin-right: 3.5em; fontsize: 14px" class="has-text-grey">
      {{ setting.label || name }}
    </span>
    <el-tag
      v-for="tag in value[name]"
      :key="tag"
      closable
      :disable-transitions="false"
      @close="handleRemoveTag(tag)"
    >
      {{ tag }}
    </el-tag>
    <el-input
      v-if="tagInputVisible"
      ref="saveTagInput"
      v-model="tagInputValue"
      class="input-new-tag"
      size="mini"
      @keyup.enter.native="handleAddTag"
      @blur="handleAddTag"
    />
    <el-button v-else class="button-new-tag" size="small" @click="showTagInput">
      + New Tag
    </el-button>
  </div>
</template>

<script>
export default {
  props: ["value", "setting", "name"],
  data() {
    return {
      tagInputValue: "",
      tagInputVisible: false,
    };
  },
  mounted() {
    if (this.value[this.name] == null) {
      if (this.setting.default != null) {
        this.$set(this.value, this.name, this.setting.default);
      } else {
        this.$set(this.value, this.name, null);
      }
    }
  },
  methods: {
    showTagInput() {
      this.tagInputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleRemoveTag(tag) {
      this.setting.tags.splice(this.setting.tags.indexOf(tag), 1);
    },
    handleAddTag() {
      let inputValue = this.tagInputValue;
      if (inputValue) {
        this.value[this.name].push(inputValue);
      }
      this.tagInputVisible = false;
      this.tagInputValue = "";
    },
  },
};
</script>

<style scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
