<template>
  <el-button
    @click="copy"
    style="margin-left: 5px"
    :disabled="busy"
    :loading="busy"
    plain
    el-icon-right
    icon="el-icon-copy-document"
    type="info"
    size="mini"
    >Copy URL <input id="webpage-editor-copy-url-input" type="hidden"
  /></el-button>
</template>

<script>
import { graph } from "@/store/api";
import gql from "graphql-tag";
import _ from "lodash";

export default {
  props: ["webpage"],
  data() {
    return {
      busy: false,
    };
  },
  computed: {
    willExpired() {
      return !!_.get(this.$store.state, "modules.webpage_editor.WEBPAGE_EXPIRE_IN");
    },
  },
  methods: {
    getWebchatEndpoint() {
      const baseUrl = window.location.hostname;
      return baseUrl.indexOf("localhost") > -1
        ? `http://${window.location.hostname}:3000/microsite`
        : `https://${window.location.hostname}/server/microsite`;
    },
    async copy() {
      try {
        let url = this.getWebchatEndpoint() + `?web_page_id=${this.webpage.id}`;
        if (this.willExpired) {
          this.busy = true;
          const res = await graph.query({
            query: gql`
              query ($data: JSON) {
                webPageEditorAPI {
                  getUrl(data: $data)
                }
              }
            `,
            fetchPolicy: "network-only",
            variables: {
              data: this.webpage,
            },
          });
          url = _.get(res, "data.webPageEditorAPI.getUrl", "");
        }

        /* Copy the text inside the text field */
        navigator.clipboard.writeText(url);
      } catch (error) {
        console.log(error);
      } finally {
        this.busy = false;
      }
    },
  },
};
</script>
