<template>
  <el-row :gutter="0">
    <el-col :span="18">
      <div
        :style="validationStyle('text')"
        style="padding-left: 10px; padding-top: 10px; padding-right: 10px"
      >
        <div v-show="!edit_text" style="color: #323a4e" name="text" @click="edit">
          <strong style="white-space: initial; word-break: break-word">
            {{ list.text || "Title" | trim }}
          </strong>
        </div>
        <el-input
          v-show="edit_text"
          ref="text_input"
          v-model="list.text"
          name="text"
          required
          type="text"
          @keyup.enter="save"
          @blur="save"
        >
          <template slot="prepend">title</template>
          <template slot="append" :style="{ color: exceed_text_chars ? 'red' : 'lightgray' }">
            {{ text_chars }}/80
          </template>
        </el-input>
      </div>
      <div
        style="padding-left: 10px; padding-top: 10px; padding-right: 10px"
        :style="validationStyle('subtext')"
      >
        <div
          v-show="!edit_subtext"
          name="subtext"
          style="color: grey; overflow-x: hidden; overflow-y: auto"
          @click="edit"
        >
          <label style="white-space: initial; font-size: 11px; word-break: break-word">
            {{ list.subtext || "Short description" | trim }}
          </label>
        </div>

        <el-input
          v-show="edit_subtext"
          ref="subtext_input"
          v-model="list.subtext"
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 5 }"
          size="large"
          name="subtext"
          placeholder="Short description"
          show-word-limit
          @blur="save"
        />
      </div>

      <Buttons
        style="padding: 10px"
        v-if="Array.isArray(list.buttons) && list.buttons.length > 0"
        v-model="list.buttons"
        :editable="editable"
        :limit="1"
        @addButton="addButton('addButton')"
        @save-side-effect="$emit('save-side-effect')"
      />
    </el-col>
    <el-col :span="6">
      <!-- <img :src="list.image" height="100%" width="100%" style="padding-top: 50%;"/> -->
      <div
        name="image"
        style="
          height: 100px;
          overflow: hidden;
          background-position: 50% 50%;
          background-size: cover;
        "
        :style="[validationStyle('image'), imageDiv, { padding: '5px 10px' }]"
      ></div>
    </el-col>
  </el-row>
</template>
<script>
import { getDefaultPlaceholderImage } from "@/helperMethods/util";

export default {
  name: "ListEntry",
  components: {
    Buttons: () => import("@/components/Buttons/Index"),
  },
  props: {
    editable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    list: {
      default() {
        return {
          text: "",
          subtext: "",
          image: "",
          url: "",
          buttons: [],
        };
      },
    },
  },
  data() {
    return {
      edit_text: !this.list.text,
      edit_subtext: !this.list.subtext,
      edit_image: !this.list.image,
      edit_url: !this.list.url,
    };
  },
  computed: {
    valid_text() {
      return this.list.text && this.list.text.length > 0;
    },
    valid_subtext() {
      return this.list.subtext && this.list.subtext.length > 0;
    },
    valid_url() {
      let pattern = /^https?:\/\/.+/i;
      return !this.list.url || pattern.test(this.list.url);
    },
    valid_image() {
      let url = /^https?:\/\/.+/i; // Do not check file type
      const valid = url.test(this.list.image);
      console.log("valid https ", valid);
      return this.list.image === "" || url.test(this.list.image);
    },
    valid() {
      this.$emit("imageSafeToSave", this.valid_image);
      return !!(this.valid_text && this.valid_subtext && this.valid_image && this.valid_url);
    },
    imageDiv() {
      return {
        backgroundImage: `url(${this.list.image || getDefaultPlaceholderImage()})`,
      };
    },
    text_chars() {
      return this.list.text ? this.list.text.length : 0;
    },
    subtext_chars() {
      return this.list.subtext ? this.list.subtext.length : 0;
    },
    exceed_text_chars() {
      return this.text_chars > 80;
    },
    exceed_subtext_chars() {
      return this.subtext_chars > 80;
    },
  },
  methods: {
    addButton() {
      this.list.buttons.push({
        text: "Enter button text",
        type: "event",
        event: null,
        data: null,
        url: null,
        phone: null,
        webview_height_ratio: null,
      });
    },
    validationStyle(field) {
      return this["valid_" + field] ? null : { color: "red" };
    },
    edit({
      currentTarget: {
        attributes: {
          name: { value },
        },
      },
    }) {
      if (this.editable) {
        this["edit_" + value] = true;
        this.$emit("selectlist");
        this.$nextTick(() => {
          window.test = this.$refs[value + "_input"].$el;

          if (value === "subtext") {
            this.$refs[value + "_input"].$el.querySelector("textarea").focus();
          } else {
            this.$refs[value + "_input"].$el.querySelector("input").focus();
          }
        });
      }
    },
    save({
      currentTarget: {
        attributes: {
          name: { value },
        },
      },
    }) {
      this["edit_" + value] = false;
      if (this.$parent.editlist) {
        this.$parent.editlist({
          slot: this.index,
          list: this.list,
        });
      }
    },
  },
};
</script>
<style scoped>
.el-input__inner {
  width: 15em !important;
}
.list-container {
  vertical-align: top;
  width: 310px;
  display: inline-block;
  margin: auto 8px;
  white-space: normal;
  text-align: center;
}

.length-counter {
  float: right;
}
</style>
