<template>
  <el-card style="box-shadow: none" shadow="never">
    <div slot="header">
      <el-row
        type="flex"
        style="margin-bottom: 0; align-items: center; justify-content: space-between"
      >
        <span>Bot</span>
        <el-switch v-model="value.inactivityManager.bot.enabled"></el-switch>
      </el-row>
    </div>

    <div>
      <div>
        <small style="font-style: italic" class="has-text-lightgrey">
          Prompts user for a reply when user's inactive time exceeds inactivity threshold.
        </small>
      </div>
      <br />
      <div ref="bot-inactivity" v-if="value.inactivityManager.bot.enabled">
        <el-input
          placeholder="Please input"
          style="margin-bottom: 15px"
          v-model="value.inactivityManager.bot.threshold"
        >
          <template slot="prepend">
            Threshold
            <el-tooltip
              effect="dark"
              content="The number of seconds before the bot prompts for a reply."
              placement="right"
            >
              <i class="el-icon-question" style="margin-left: 0.5em" />
            </el-tooltip>
          </template>
        </el-input>

        <el-input style="margin-bottom: 15px" v-model="value.inactivityManager.bot.contentNode">
          <template slot="prepend">
            Content Node
            <el-tooltip
              effect="dark"
              content="Content node show to user when inactivity threshold met."
              placement="right"
            >
              <i class="el-icon-question" style="margin-left: 0.5em" />
            </el-tooltip>
          </template>
        </el-input>

        <el-divider>Second Prompt</el-divider>

        <div>
          <small style="font-style: italic" class="has-text-lightgrey">
            Prompts user for a reply again when user's inactive time exceeds inactivity threshold
            for the second time.
          </small>
        </div>
        <br />

        <el-checkbox
          v-model="value.inactivityManager.bot.secondPrompt"
          label="Enable"
          border
          style="margin-bottom: 15px"
        ></el-checkbox>

        <el-input
          v-if="value.inactivityManager.bot.secondPrompt"
          placeholder="Please input"
          style="margin-bottom: 15px"
          v-model="value.inactivityManager.bot.secondPromptThreshold"
        >
          <template slot="prepend">
            Threshold
            <el-tooltip
              effect="dark"
              content="The number of seconds before the bot prompts for a reply."
              placement="right"
            >
              <i class="el-icon-question" style="margin-left: 0.5em" />
            </el-tooltip>
          </template>
        </el-input>

        <div ref="bot-inactivity-second-prompt" v-if="value.inactivityManager.bot.secondPrompt">
          <el-input
            style="margin-bottom: 15px"
            v-model="value.inactivityManager.bot.secondPromptContentNode"
          >
            <template slot="prepend">
              Content Node
              <el-tooltip
                effect="dark"
                content="Content node show to user when second inactivity threshold met."
                placement="right"
              >
                <i class="el-icon-question" style="margin-left: 0.5em" />
              </el-tooltip>
            </template>
          </el-input>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "InactivityManagerBot",
  props: ["value"],
};
</script>
