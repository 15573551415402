<template>
  <span v-if="!groupByTopicIsEnabled" style="line-height: initial">
    <el-button size="small" icon="el-icon-share" type="plain" @click="dialogTableVisible = true">
      Show Previous Versions
      <el-tooltip effect="dark" placement="bottom">
        <div slot="content">
          <p style="color: white; margin-bottom: 0; font-size: 12px">
            Current bot was trained {{ currentModelTime }}
            <br />
            <span>{{ displayModelInfo }}</span>
          </p>
        </div>
        <i class="has-text-grey el-icon-question" style="margin-left: 0.5em" />
      </el-tooltip>
    </el-button>
    <el-dialog
      :visible.sync="dialogTableVisible"
      width="45%"
      top="20vh"
      :show-close="true"
      body-style="padding: 5px 20px 5px"
    >
      <FaqTestFileUploadCollapse v-if="$store.getters.evaluationModuleEnabled" />

      <el-table
        id="previous-bot-popup"
        :data="modelsToLoad()"
        max-height="340"
        size="mini"
        :row-class-name="highlightCurrentPublished"
        highlight-current-row
        style="text-align: left"
      >
        <el-table-column prop="modelId" header-align="left" label="ID" />

        <el-table-column prop="trainedFromNow" header-align="left" label="Trained" :width="120">
          <template slot-scope="props">
            <strong v-if="props.row.modelId === publishedModel">
              {{ props.row.trainedFromNow }}
            </strong>
            <span v-else>{{ props.row.trainedFromNow }}</span>
          </template>
        </el-table-column>

        <el-table-column
          v-if="$store.getters.evaluationModuleEnabled"
          header-align="center"
          :width="150"
          align="center"
        >
          <!-- Custom column header -->
          <template slot="header">
            <span>Performance</span>

            <el-tooltip
              content="Performance: % based on intent matched against test dataset. Test duration will differ with size of test file"
              placement="top"
            >
              <i class="el-icon-question" />
            </el-tooltip>
          </template>
          <template slot-scope="props">
            <el-button
              v-if="modelUnevaluated(props.row)"
              :loading="evaluateLoading"
              size="mini"
              @click="evaluate(props.row.modelId)"
            >
              Test
            </el-button>
            <div v-else>
              <span>{{ formatConfidence(props.row.confidence) }}</span>
              <el-tooltip
                :content="`Model performance is lower than current model`"
                placement="top"
                v-if="
                  $store.getters.evaluationModuleEnabled &&
                  modelConfidenceBelowPublished(props.row, models)
                "
              >
                <i style="margin-left: 4px" class="el-icon-warning has-text-danger"></i>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>

        <el-table-column :width="120">
          <template slot-scope="props">
            <!-- Published version -->
            <b
              v-if="checkPublished(props.row.modelId)"
              class="has-text-success"
              style="vertical-align: middle"
            >
              Published
            </b>

            <!-- Model not evaluated -->
            <el-tooltip
              content="Model not evaluated yet"
              placement="top"
              v-else-if="$store.getters.evaluationModuleEnabled && modelUnevaluated(props.row)"
            >
              <div>
                <el-button
                  :disabled="modelUnevaluated(props.row)"
                  :loading="$store.state.faq.publishingInProgress"
                  size="mini"
                >
                  Publish
                </el-button>
              </div>
            </el-tooltip>

            <!-- Model below threshold -->
            <el-tooltip
              :content="`Model performance is below threshold of ${classifierThreshold * 100}%`"
              placement="top"
              v-else-if="
                $store.getters.evaluationModuleEnabled &&
                modelConfidenceBelowThreshold(props.row, classifierThreshold)
              "
            >
              <div>
                <el-button
                  :disabled="modelConfidenceBelowThreshold(props.row, classifierThreshold)"
                  :loading="$store.state.faq.publishingInProgress"
                  size="mini"
                >
                  Publish
                </el-button>
              </div>
            </el-tooltip>

            <!-- All OK to publish -->
            <el-button
              v-else
              :loading="$store.state.faq.publishingInProgress"
              size="mini"
              @click="publish(props.row.modelId)"
            >
              Publish
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </span>
</template>
<script>
import Vue from "vue";
import gql from "graphql-tag";
import moment from "moment";
import { mapGetters } from "vuex";
import _ from "lodash";
import {
  formatConfidence,
  modelConfidenceBelowPublished,
  modelConfidenceBelowThreshold,
  modelUnevaluated,
  prepareDatasetToSave,
} from "@/helperMethods/faq/util";
import FaqTestFileUploadCollapse from "@/components/Faq/FaqTestFileUpload.vue";

export default Vue.extend({
  components: { FaqTestFileUploadCollapse },
  props: [],
  data() {
    return {
      models: [],
      dialogTableVisible: false,
      evaluateLoading: false,
    };
  },

  computed: {
    ...mapGetters([
      "rasaEnabled",
      "elsaEnabled",
      "groupByTopicIsEnabled",
      "getPublishingInProgress",
    ]),

    currentModelTime() {
      let modelId = "";
      if (this.elsaEnabled) {
        modelId = _.get(this.$store, "state.modules.faq.Elsa.publishedModelIds.general", "");
      } else {
        modelId = _.get(this.$store, "state.modules.faq.Rasa.modelId", "");
      }

      const timestamp = modelId.split("_")[1];
      if (!timestamp) {
        return null;
      }

      return moment(timestamp + " +00:00", "YYYYMMDD-HHmmss Z").fromNow();
    },

    displayModelInfo() {
      let modelId;
      if (this.elsaEnabled) {
        modelId = _.get(this.$store, "state.modules.faq.Elsa.publishedModelIds.general", "");
      } else {
        modelId = _.get(this.$store, "state.modules.faq.Rasa.modelId", "");
      }
      return modelId || "No bot loaded";
    },

    publishedModel() {
      if (this.rasaEnabled) {
        return _.get(this, "$store.state.modules.faq.Rasa.modelId", "");
      } else {
        return _.get(this, "$store.state.modules.faq.Elsa.publishedModelIds.general", "");
      }
    },

    classifierThreshold() {
      if (this.rasaEnabled) {
        return _.get(this, "$store.state.modules.faq.Rasa.threshold", 0.2);
      } else {
        return _.get(this, "$store.state.modules.faq.Elsa.threshold", 0.2);
      }
    },
  },

  methods: {
    setPublishingInProgress(inProgress) {
      this.$store.commit("SET_PUBLISHING_IN_PROGRESS", inProgress);
    },

    setTrainingInProgress(inProgress) {
      this.$store.commit("SET_TRAINING_IN_PROGRESS", inProgress);
    },

    modelsToLoad() {
      return this.models;
    },

    evaluate(modelId) {
      this.evaluateLoading = true;
      this.setTrainingInProgress(true);

      this.$store
        .dispatch("FAQ_TEST_MODEL_WITH_TEST_DATA", {
          modelId,
        })
        .then((updatedDataset) => {
          if (updatedDataset) {
            this.$notify.success({
              title: "Evaluation Success",
              position: "bottom-right",
              message: `Successfully tested ${modelId}`,
            });
          }
        })
        .catch((err) => {
          this.$notify.error({
            title: "Evaluation Error",
            position: "bottom-right",
            message: `Encountered error testing model`,
          });
        })
        .finally(() => {
          this.evaluateLoading = false;
          this.setTrainingInProgress(false);
        });
    },

    highlightCurrentPublished({ row, rowIndex }) {
      const rowModelId = row.modelId;
      if (this.checkPublished(rowModelId)) {
        return "published-model";
      }
      return "";
    },

    checkPublished(rowModelId) {
      let status = false;

      if (this.rasaEnabled) {
        status = rowModelId === this.publishedModel;
      } else if (this.elsaEnabled) {
        const { publishedModelIds } = _.get(this, "$store.state.modules.faq.Elsa", {});

        status = rowModelId === publishedModelIds["general"];
      }

      return status;
    },

    formatConfidence(confidence) {
      return formatConfidence(confidence);
    },

    modelUnevaluated(row) {
      return modelUnevaluated(row);
    },

    modelConfidenceBelowThreshold(row, threshold) {
      return modelConfidenceBelowThreshold(row, threshold);
    },

    modelConfidenceBelowPublished(row, models) {
      return modelConfidenceBelowPublished(row, models);
    },

    async publish(modelId) {
      this.setPublishingInProgress(true);
      await this.$apollo.mutate({
        mutation: gql`
          mutation ($modelId: String!) {
            faqAPI {
              publish(modelId: $modelId)
            }
          }
        `,
        variables: {
          modelId,
        },
        update: (
          store,
          {
            data: {
              faqAPI: { publish },
            },
          }
        ) => {
          if (publish && publish.success) {
            this.$notify.success({
              title: "Success",
              message: "Model published",
              position: "bottom-right",
            });

            if (publish.Elsa) {
              const copyPublishedModelIds = _.cloneDeep(
                _.get(this, "$store.state.modules.faq.Elsa.publishedModelIds", {})
              );
              copyPublishedModelIds["general"] = modelId;

              this.$set(
                this.$store.state.modules.faq.Elsa,
                "publishedModelIds",
                copyPublishedModelIds
              );
            } else if (publish.Rasa) {
              this.$set(this.$store.state.modules.faq.Rasa, "modelId", modelId);
            }
          } else {
            this.$notify.error({
              title: "Error",
              message: "Failed to publish model",
              position: "bottom-right",
            });
          }
        },
      });
      this.setPublishingInProgress(false);
    },

    async save() {
      this.saving = true;
      const datasetToSave = prepareDatasetToSave(this.$store.state.training.dataset);
      const isSaved = await this.$apollo
        .mutate({
          mutation: gql`
            mutation ($dataset: JSON!) {
              faqAPI {
                saveDataset(dataset: $dataset)
              }
            }
          `,
          variables: {
            dataset: datasetToSave,
          },
        })
        .catch(() => {
          this.$notify.error({
            title: "Error",
            message: "Failed to save FAQ dataset.",
            position: "bottom-right",
          });
          return false;
        });
      if (isSaved) {
        this.saving = false;
        this.$notify.success({
          title: "Success",
          message: "Saved FAQ Question",
          position: "bottom-right",
        });
      }
    },
  },

  apollo: {
    models() {
      return {
        query: gql`
          query ($modelName: String) {
            faqAPI {
              models: getModels(modelName: $modelName)
            }
          }
        `,
        variables() {
          return {
            modelName: _.get(this, "$store.state.faq.currentViewingTopic", ""),
          };
        },
        fetchPolicy: "cache-and-network",
        update: (data) => {
          if (!data.faqAPI) return [];

          let models = data.faqAPI.models;

          if (this.elsaEnabled) return models;

          models = _.map(models, (model) => {
            const splitByUnderscoreRegex = /[^_]*/;
            const topic = splitByUnderscoreRegex.exec(model.modelId)[0];
            return Object.assign({}, model, {
              trainedFromNow: moment(model.timestamp).fromNow(),
              topic,
            });
          });

          return models;
        },
        subscribeToMore: {
          document: gql`
            subscription {
              modelList: modelListChanged
            }
          `,
          updateQuery: (previousResult, { subscriptionData }) => {
            const modelList = _.get(subscriptionData, "data.modelList");
            return { faqAPI: { models: modelList } };
          },
        },
      };
    },
  },
});
</script>

<style>
#previous-bot-popup .published-model {
  background: oldlace;
}
.publishBtnGrp {
  text-align: right;
}
.previous-bot-dialog-popup {
  padding: 5px 20px 5px !important;
}
.previous-bot-dialog-popup > .el-dialog__header {
  height: 0;
}
.previous-bot-dialog-popup > .el-dialog__body {
  padding: 5px 20px 5px !important;
}
.previous-bot-dialog-popup > .el-dialog__body.el-footer {
  margin-top: 0;
}
</style>

<style scoped lang="scss">
@import "../../assets/scss/colors.scss";

.formatted-green {
  color: $color-success;
  vertical-align: middle;
  margin-left: 20px;
}
</style>
