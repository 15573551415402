<template>
  <div>
    <el-tabs type="border-card" v-model="currentTab">
      <el-tab-pane label="In-Memory" name="inmemory">
        <div style="margin-bottom: 10px">
          <el-button
            icon="el-icon-refresh"
            style="margin-right: 10px"
            type="primary"
            plain
            @click="fetchData"
            :loading="fetching"
            :disabled="updating || fetching"
            size="small"
            >Refresh</el-button
          >
          <el-popover
            @after-leave="tmpKey = null"
            v-model="currentOpened"
            placement="bottom"
            title="Add"
            width="200"
            trigger="click"
          >
            <el-input style="margin-bottom: 10px" placeholder="Please input" v-model="tmpKey">
            </el-input>
            <el-button
              slot="reference"
              :disabled="updating || fetching"
              style="margin-right: 10px"
              icon="el-icon-plus"
              type="info"
              plain
              size="small"
              >Add</el-button
            >
            <el-button
              icon="el-icon-check"
              type="info"
              plain
              @click="add"
              :loading="fetching"
              :disabled="updating || fetching"
              size="mini"
              >Confirm</el-button
            >
          </el-popover>
          <el-button
            @click="update"
            icon="el-icon-check"
            type="success"
            plain
            :loading="updating"
            :disabled="updating || fetching"
            size="small"
            >Save</el-button
          >
        </div>
        <p class="empty-text" v-if="Object.keys(apiStore).length === 0">No item added.</p>
        <p class="warning-text" v-if="Object.keys(apiStore).length > 0">
          In-Memory changes will be lost after application restart.
        </p>
        <el-card
          body-style="padding: 5px;"
          v-for="(k, i) in apiStore"
          class="box-card"
          :key="i"
          style="margin-bottom: 10px"
        >
          <div class="card-item-nav">
            <span style="font-weight: bold">{{ i }}</span>
            <el-button
              @click="remove(i)"
              size="mini"
              plain
              type="danger"
              icon="el-icon-delete"
              circle
            />
          </div>
          <JSONEditor v-if="currentTab === 'inmemory'" v-model="apiStore[i]" />
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="Constants" name="constants">
        <div style="margin-bottom: 10px">
          <el-popover
            @after-leave="tmpKey = null"
            v-model="defaultOpened"
            placement="bottom"
            title="Add"
            width="200"
            trigger="click"
          >
            <el-input style="margin-bottom: 10px" placeholder="Please input" v-model="tmpKey">
            </el-input>
            <el-button
              slot="reference"
              :disabled="updating || fetching"
              style="margin-right: 10px"
              icon="el-icon-plus"
              type="info"
              plain
              size="small"
              >Add</el-button
            >
            <el-button
              icon="el-icon-check"
              type="info"
              plain
              @click="add"
              :loading="fetching"
              :disabled="updating || fetching"
              size="mini"
              >Confirm</el-button
            >
          </el-popover>
          <el-button
            @click="saveDefault"
            icon="el-icon-check"
            type="success"
            plain
            :loading="updating"
            :disabled="updating || fetching"
            size="small"
            >Save</el-button
          >
        </div>
        <p class="empty-text" v-if="Object.keys(defaultStore).length === 0">No item added.</p>
        <el-card
          v-for="(k, i) in defaultStore"
          class="box-card"
          body-style="padding: 5px;"
          :key="i"
          style="margin-bottom: 10px"
        >
          <div class="card-item-nav">
            <span style="font-weight: bold">{{ i }}</span>
            <el-button
              @click="remove(i)"
              size="mini"
              plain
              type="danger"
              icon="el-icon-delete"
              circle
            />
          </div>
          <JSONEditor
            v-if="currentTab === 'constants'"
            :value="defaultStore[i]"
            @input="(v) => (defaultStore[i] = v)"
          />
        </el-card>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import _ from "lodash";
import gql from "graphql-tag";
import { graph } from "@/store/api";
import JSONEditor from "@/components/JSONEditor";

export default {
  components: { JSONEditor },
  data() {
    return {
      apiStore: {},
      defaultStore: _.get(this.$store, "state.modules.core.defaultSystemVariables") || {},
      fetching: false,
      updating: false,
      tmpKey: null,
      currentOpened: false,
      defaultOpened: false,
      currentTab: "inmemory",
    };
  },
  methods: {
    async saveDefault() {
      try {
        const confirmed = await this.$confirm(
          `Are you sure to save and apply this as initial state?`,
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
          }
        );
        if (confirmed) {
          this.updating = true;
          await graph.mutate({
            mutation: gql`
              mutation ($data: JSON) {
                apiEditorAPI {
                  saveApiStore(data: $data)
                }
              }
            `,
            variables: {
              data: { ...this.apiStore, ...this.defaultStore },
            },
            fetchPolicy: "no-cache",
          });
          this.$store.state.modules.core.defaultSystemVariables = this.defaultStore;
          await this.$store.dispatch("SAVE_MODULES", {
            modules: this.$store.state.modules,
          });
          this.$notify.success({
            title: "Success",
            message: "API store & initial state updated.",
            position: "bottom-right",
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.updating = false;
      }
    },
    onTabChange() {
      setTimeout(() => {
        this.$forceUpdate();
      }, 1000);
    },
    add() {
      const newKey = _.snakeCase(this.tmpKey);
      if (this.currentTab === "inmemory") {
        if (this.apiStore[newKey]) {
          this.$notify.error({
            title: "Error",
            message: "This key name has been taken.",
            position: "bottom-right",
          });
        } else {
          this.$set(this.apiStore, newKey, {});
          this.currentOpened = false;
        }
      } else {
        if (this.defaultStore[newKey]) {
          this.$notify.error({
            title: "Error",
            message: "This key name has been taken.",
            position: "bottom-right",
          });
        } else {
          this.$set(this.defaultStore, newKey, {});
          this.defaultOpened = false;
        }
      }
    },
    async update() {
      try {
        const confirmed = await this.$confirm(`Are you sure to save this?`, "Warning", {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
        });
        if (confirmed) {
          this.updating = true;
          await graph.mutate({
            mutation: gql`
              mutation ($data: JSON) {
                apiEditorAPI {
                  saveApiStore(data: $data)
                }
              }
            `,
            variables: {
              data: this.apiStore,
            },
            fetchPolicy: "no-cache",
          });
          this.$notify.success({
            title: "Success",
            message: "API store updated.",
            position: "bottom-right",
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.updating = false;
      }
    },
    remove(k) {
      this.$delete(this.apiStore, k);
    },
    async fetchData() {
      try {
        this.fetching = true;
        const result = await graph.query({
          query: gql`
            query {
              apiEditorAPI {
                fetchApiStore
              }
            }
          `,
          fetchPolicy: "network-only",
        });
        this.apiStore = _.get(result, "data.apiEditorAPI.fetchApiStore") || {};
      } catch (error) {
        console.log(error);
      } finally {
        this.fetching = false;
      }
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
>>> .CodeMirror {
  height: 60px !important;
  width: 100% !important;
}
.empty-text {
  text-align: center;
  margin: 20px 0;
}
.warning-text {
  font-size: 10px;
  font-style: italic;
  text-align: right;
}
.card-item-nav {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  align-items: center;
}
</style>
