import { MutationTree } from "vuex/types";
import Vue from "vue";
import { FlowContent, FlowEditorState, Flows, FlowTrigger } from "./types";
import _ from "lodash";

const mutations: MutationTree<FlowEditorState> = {
  SET_FETCHING_FLOWS(state, payload: boolean) {
    Vue.set(state, "fetchingFlows", payload);
  },
  SET_FLOWS(state, payload: Flows) {
    Vue.set(state, "flows", payload);
  },
  SET_FLOW_CONTENT(state, payload: FlowContent) {
    Vue.set(state, "contents", payload);
  },
  SET_FLOW_TRIGGER(state, payload: FlowTrigger) {
    Vue.set(state, "triggers", payload);
  },
  DELETE_FLOW(state, payload) {
    const _flows = _.cloneDeep(state.flows);
    delete _flows[payload];
    Vue.set(state, "flows", _flows);
  },
  ADD_FLOW(state, payload) {
    console.log(payload);
    const _flows = _.cloneDeep(state.flows);
    _flows[payload.flowId] = payload;
    Vue.set(state, "flows", _flows);
  },
  // ADD_FLOW_SECTION(state, payload) {
  //   console.log(payload)
  //   let _flows = _.cloneDeep(state.flows);
  //   _flows[payload.flow.flowId].sections.push(payload.section)
  //   Vue.set(state, "flows", _flows);
  // }
};

export default mutations;
