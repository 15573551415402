import { AccessControlState } from "./types";

const state: AccessControlState = {
  auth0: {
    users: [],
    usersStatic: [],
    user: {},
    total: 0,
  },
};

export default state;
