<template>
  <el-row>
    <el-col :span="14">
      <el-form :model="node.content.options">
        <el-form-item
          prop="latitude"
          :rules="[{ required: true, trigger: 'blur', message: 'Please input Latitude' }]"
        >
          <el-input
            type="text"
            v-model="node.content.options.latitude"
            show-word-limit
            placeholder="Require"
          >
            <template slot="prepend">Latitude</template>
          </el-input>
        </el-form-item>
        <el-form-item
          prop="longitude"
          :rules="[{ required: true, trigger: 'blur', message: 'Please input Longitude' }]"
        >
          <el-input
            type="text"
            v-model="node.content.options.longitude"
            show-word-limit
            placeholder="Require"
          >
            <template slot="prepend">Longitude</template>
          </el-input>
        </el-form-item>
        <el-form-item
          prop="name"
          :rules="[{ required: true, trigger: 'blur', message: 'Please input name of place' }]"
        >
          <el-input
            type="text"
            v-model="node.content.options.name"
            show-word-limit
            placeholder="Require"
          >
            <template slot="prepend">Name of Place</template>
          </el-input>
        </el-form-item>
        <el-form-item
          prop="address"
          :rules="[{ required: true, trigger: 'blur', message: 'Please input address' }]"
        >
          <el-input
            type="text"
            v-model="node.content.options.address"
            show-word-limit
            placeholder="Require"
          >
            <template slot="prepend">Address</template>
          </el-input>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "WhatsAppLocationShare",
  props: ["node"],
};
</script>

<style></style>
