import _ from "lodash";

export default {
  methods: {
    graphQLError(err, defaultMessage) {
      const graphQLErr = _.get(err, "graphQLErrors[0]");
      if (_.get(graphQLErr, "extensions.code") === "400_BAD_REQUEST") {
        const message = _.get(graphQLErr, "message");
        defaultMessage += `: ${message}`;
      }
      return defaultMessage;
    },
  },
};
