<template>
  <el-popover
    placement="bottom"
    popper-class="bg-lightgrey"
    width="260"
    trigger="click"
    :title="label"
  >
    <p style="font-size: 11px" v-if="content" v-html="$options.filters.wrapURL(content)"></p>
    <i
      v-if="content"
      class="has-text-dark el-icon-question"
      slot="reference"
      style="margin-left: 0.5em"
    />
  </el-popover>
</template>

<script>
export default {
  props: ["label", "content"],
};
</script>
