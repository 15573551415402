import actions from "./actions";
import mutations from "./mutations";
import state from "./state";
import { Module } from "vuex/types";
import { ZoomState } from "./types";
import { RootState } from "@/store/types";

const zoom: Module<ZoomState, RootState> = {
  // namespaced: true,
  actions,
  mutations,
  state,
};

export default zoom;
