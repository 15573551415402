import _ from "lodash";

const synth: SpeechSynthesis = window.speechSynthesis;

export default function (text: any): Promise<void> {
  text = text.replace(/&nbsp;/g, " ");
  text = text.replace(/[^\w ,.]/g, "");

  const utterThis = new SpeechSynthesisUtterance(text);

  let googleVoice: any;
  if (synth) {
    synth.onvoiceschanged = function () {
      googleVoice = _.find(synth.getVoices(), (voice: any) => voice.name === "Google US English");
    };
  }

  if (googleVoice) {
    utterThis.voice = googleVoice;
    utterThis.rate = 1;
  }

  synth.speak(utterThis);

  return new Promise<void>((resolve) => {
    utterThis.onend = () => {
      resolve();
    };
  });
}
