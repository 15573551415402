<template>
  <div class="sort-contacts">
    <div class="sort-contacts-container">
      <div class="sort-contacts-container-sorts">
        <div class="sort-contacts-container-sorts-container">
          <div
            class="sort-contacts-container-sorts-container-item"
            v-for="key in sortList"
            :key="key"
          >
            <el-select
              size="small"
              v-model="sorting.column"
              placeholder="SortBy"
              style="width: 500px"
            >
              <el-option
                v-for="(item, j) in variables"
                :key="j"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select size="small" v-model="sorting.value" placeholder="SortOrder">
              <el-option v-for="(item, j) in sortOrder" :key="j" :label="item" :value="item">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="sort-contacts-container-button">
        <el-button
          :key="key"
          @click="onResetSortList(true)"
          size="small"
          class="sort-contacts-container-button"
          :class="'reset'"
          >Reset</el-button
        >
        <el-button
          :key="key"
          @click="onApplySort()"
          size="small"
          class="sort-contacts-container-button"
          :class="'apply'"
          :disabled="!sorting.value || !sorting.column"
          >Apply Sorting</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getOutReachSortableVariableExcludeField } from "@/helperMethods/outreach";
export default {
  data() {
    return {
      sortOrder: ["ASC", "DESC"],
      clicked: 0,
      sortList: {
        1: {
          value: "",
        },
      },
      sorting: {
        column: "",
        value: "",
      },
    };
  },
  computed: {
    ...mapGetters(["contactsArray", "contactAttributes"]),
    variables() {
      return getOutReachSortableVariableExcludeField(undefined, this.contactAttributes);
    },
  },
  beforeCreate() {
    window.addEventListener("click", (e) => {
      this.clicked = this.clicked + 1;
      if (!this.$el.contains(e.target) && this.clicked > 1) {
        this.$emit("close-sort", false);
      }
    });
  },
  methods: {
    onResetSortList(isEmit) {
      this.sorting = {
        column: "",
        value: "",
      };
      this.$store.commit("SET_CONTACT_SORT_BOARD", {});
      isEmit && this.$emit("apply-sort", []);
    },
    onApplySort() {
      const values = this.sorting;
      this.$store.commit("SET_CONTACT_SORT_BOARD", values);
      if (values.column && values.value) {
        this.$emit("apply-sort", values);
      }
      this.$emit("close-sort", false);
    },
  },
};
</script>

<style lang="scss">
.sort-contacts {
  position: absolute;
  z-index: 999;
  width: 400px;
  height: 100px;
  background: white;
  top: 120%;
  left: 0%;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 25%);
  border-radius: 4px;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &-container {
    &-sorts {
      padding: 5px;
      display: flex;
      justify-content: space-between;
      &-container {
        padding: 5px;
        width: 100%;
        overflow-y: scroll;
        &-item {
          display: flex;
          > div {
            padding: 5px;
            width: 100%;

            .el-input__inner {
              border-color: black !important;
              padding-left: 10px;
              &::placeholder {
                color: black;
                opacity: 1; /* Firefox */
              }
            }
          }
        }
      }
    }
    &-button {
      text-align: end;
      padding-right: 20px;
      .reset {
        border-color: black !important;
        color: black !important;
        &:hover {
          background: rgba(50, 50, 50, 0.121) !important;
        }
      }
      .apply {
        border-color: #1d57d8 !important;
        color: #1d57d8 !important;
      }
      &:first-child {
        font-weight: 500;
      }
    }
  }
  .popper__arrow {
    display: none !important;
  }
}
</style>
