import { GetterTree } from "vuex/types";
import { RootState } from "@/store/types";
import { DepartmentState } from "./types";

const getters: GetterTree<DepartmentState, RootState> = {
  createDepartmentLoading(state, getters, rootState) {
    return state.createLoading;
  },
};

export default getters;
