<template>
  <div>
    <el-form-item prop="data">
      <!-- Dropdown to select department -->
      <el-select v-model="value.data.value" placeholder="Select Department" :filterable="true">
        <!-- Prepend Set Department -->
        <template slot="prepend">Set Department</template>
        <el-option
          v-for="department in departments"
          :key="department.id"
          :label="department.name"
          :value="department.name"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <EventNodeSelectorNext :value="value" />
  </div>
</template>

<script>
import EventNodeSelectorNext from "../Next.vue";
import _ from "lodash";

export default {
  name: "EventNodeTypeSetDepartment",
  props: ["value"],
  components: {
    EventNodeSelectorNext,
  },
  computed: {
    departments() {
      return _.get(this.$store, "state.department.departments", []);
    },
  },
  mounted() {
    // Fetch departments
    if (this.departments.length === 0) {
      this.$store.dispatch("FETCH_DEPARTMENTS");
    }
  },
};
</script>
