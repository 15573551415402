import { render, staticRenderFns } from "./WhatsAppMessageTemplateQuickReply.vue?vue&type=template&id=13b80dde&scoped=true&"
import script from "./WhatsAppMessageTemplateQuickReply.vue?vue&type=script&lang=js&"
export * from "./WhatsAppMessageTemplateQuickReply.vue?vue&type=script&lang=js&"
import style0 from "./WhatsAppMessageTemplateQuickReply.vue?vue&type=style&index=0&id=13b80dde&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13b80dde",
  null
  
)

export default component.exports