import _ from "lodash";

const mapTemplateVariablesIntoObj = (template: string) => {
  const matches = [...template.matchAll(/\{\{\s*(?<token>\w+)/g)].map((match) =>
    _.get(match, "groups.token")
  );
  const tokensMap = matches.reduce((accu, curr) => {
    if (curr) {
      if (_.includes(["pageNo", "PageNo"], curr)) {
        accu[curr] = 1;
      } else {
        accu[curr] = "";
      }
    }
    return accu;
  }, {});
  return tokensMap;
};

export { mapTemplateVariablesIntoObj };
