<template>
  <el-card class="selected-broadcast-card">
    <div slot="header">
      <el-row>
        <h3 v-if="isPast(selectedBroadcast.timestamp)">
          Broadcast was sent
          {{ moment(datetime, ["DD-MM-YYYY HH:mm"]).fromNow() }} -
          {{ datetime }}
        </h3>
        <h3 v-else>
          Broadcast will be sent
          {{ moment(datetime, ["DD-MM-YYYY HH:mm"]).fromNow() }} -
          {{ datetime }}
        </h3>
        <el-tooltip effect="dark" content="Delete broadcast" placement="bottom">
          <el-button
            :disabled="isPast(selectedBroadcastId)"
            :loading="loadingDelete"
            style="margin-left: 8px; float: right"
            icon="el-icon-delete"
            type="danger"
            circle
            @click="deleteBroadcast(selectedBroadcastId)"
          />
        </el-tooltip>
        <el-tooltip effect="dark" content="Save broadcast" placement="bottom">
          <el-button
            :disabled="isPast(selectedBroadcastId)"
            :loading="loadingSave"
            style="margin-left: 0px; float: right"
            icon="el-icon-check"
            :plain="true"
            type="success"
            circle
            @click="saveBroadcast"
          />
        </el-tooltip>
      </el-row>
      <el-row style="min-height: 300px">
        <el-collapse v-model="advanceBroadcastSettings">
          <el-collapse-item key="1" name="setting">
            <template slot="title">
              <h3 class="has-text-dark">Settings</h3>
              <el-tooltip
                placement="right"
                content="Set conditions for your broadcast or send to your test user"
              >
                <i class="el-icon-info" style="margin-left: 10px; color: grey" />
              </el-tooltip>
            </template>

            <span class="text-option-sub-title">
              Enable notification?
              <el-tooltip
                effect="dark"
                content="Enable if you wish to show push notification for broadcasted message"
                placement="right"
              >
                <i class="has-text-grey el-icon-question" style="margin-left: 0.5em" />
              </el-tooltip>
            </span>
            <el-row>
              <el-switch
                v-model="selectedBroadcast.broadcastType"
                active-text="Enable Notification"
                active-value="REGULAR"
                inactive-text="Silent push"
                inactive-value="NO_PUSH"
              ></el-switch>
            </el-row>

            <span class="text-option-sub-title">
              Broadcast Tag
              <el-tooltip
                effect="dark"
                content="The facebook tag it will be used to send broadcast message"
                placement="right"
              >
                <i class="has-text-grey el-icon-question" style="margin-left: 0.5em" />
              </el-tooltip>
            </span>
            <el-row>
              <el-col :span="12">
                <el-select
                  v-model="selectedBroadcast.broadcastTag"
                  filterable
                  allow-create
                  default-first-option
                  placeholder="Choose a broadcast tag"
                  style="width: 100%"
                >
                  <el-option
                    v-for="(tag, index) in broadcastTags"
                    :key="index"
                    :label="tag"
                    :value="tag"
                  />
                </el-select>
              </el-col>
            </el-row>

            <span class="text-option-sub-title">
              Broadcast Date Time
              <el-tooltip
                effect="dark"
                content="Select the date and time for your broadcast to be sent"
                placement="right"
              >
                <i class="el-icon-question has-text-grey" style="margin-left: 0.5em" />
              </el-tooltip>
            </span>
            <el-row>
              <el-date-picker
                class="new input"
                v-model="selectedBroadcast.date"
                type="datetime"
                placeholder="Select date and time"
                format="dd-MM-yyyy HH:mm"
                :picker-options="pickerOptions"
              />
            </el-row>

            <el-row>
              <el-col>
                <!-- <h3>Broadcast Segment Conditions</h3> -->
                <span class="text-option-sub-title">
                  Broadcast Segment Conditions
                  <el-tooltip
                    effect="dark"
                    content="Conditions for users. Save broadcast to get estimated users. Will filter users for facebook only"
                    placement="right"
                  >
                    <i class="has-text-grey el-icon-question" style="margin-left: 0.5em" />
                  </el-tooltip>
                </span>
                <el-row
                  v-for="(condition, index) in broadcast.conditions"
                  :key="index"
                  class="keyreply-conditions-row"
                  :gutter="10"
                >
                  <el-col :span="8">
                    <el-select
                      v-model="condition.property"
                      filterable
                      allow-create
                      default-first-option
                      placeholder="Choose a property"
                      style="width: 100%"
                    >
                      <el-option label="Language" value="entities.languageDetect"></el-option>
                      <el-option label="App Source" value="appSource"></el-option>
                      <el-option label="subscribe_latest" value="subscribe_latest"></el-option>
                      <el-option label="Context Last" value="context_last"></el-option>
                      <!-- <el-option label="Language" value="entities.languageDetect"></el-option> -->
                    </el-select>
                  </el-col>
                  <el-col :span="4">
                    <el-select filterable v-model="condition.operator">
                      <el-option value="<" label="<" />
                      <el-option value="=" label="=" />
                      <el-option value=">" label=">" />
                      <el-option value="NOT" label="not" />
                      <el-option value="INCLUDES" label="includes" />
                      <el-option value="INCLUDED IN" label="included in" />
                      <el-option value="EXISTS" label="exists" />
                      <el-option value="NOT EXISTS" label="not exists" />
                    </el-select>
                  </el-col>
                  <el-col :span="6">
                    <div v-if="!['EXISTS', 'NOT EXISTS'].includes(condition.operator)">
                      <el-input v-model="condition.value" />
                    </div>
                  </el-col>
                  <el-col :span="2">
                    <el-button
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      @click="removeCondition(index)"
                    />
                  </el-col>
                </el-row>
              </el-col>
              <el-col>
                <span class="has-text-success" :loading="loadingEstimatedReach"
                  >Estimated users reach: {{ estimatedReach[selectedBroadcastId] || 0 }}</span
                >
                <el-tooltip content="Add answer condition" placement="bottom">
                  <el-button
                    style="float: right; margin-right: 10px"
                    type="info"
                    icon="el-icon-plus"
                    @click="addCondition()"
                  >
                    Condition
                  </el-button>
                </el-tooltip>
              </el-col>
            </el-row>
            <span class="text-option-sub-title">
              Test user ID
              <el-tooltip
                effect="dark"
                content="Can be telegram or facebook user ID"
                placement="right"
              >
                <i class="has-text-grey el-icon-question" style="margin-left: 0.5em" />
              </el-tooltip>
            </span>
            <el-row>
              <el-select
                filterable
                allow-create
                default-first-option
                v-model="testUserId"
                placeholder="Enter the test user ID"
                style="max-width: 375px; width: 375px"
              >
                <el-option
                  v-for="(user, index) in facebookTestUserIds"
                  :key="index"
                  :value="user.value"
                >
                  <span style="float: left">{{ user.label }}</span>
                  <span
                    class="has-text-grey"
                    style="margin-left: 24px; float: right; font-size: 13px"
                  >
                    {{ user.value }}
                  </span>
                </el-option>
              </el-select>
              <el-tooltip
                content="Sends to test user based on notification and selected tag. Does not consider conditions"
                placement="top"
              >
                <el-button
                  style="margin-left: 10px"
                  type="primary"
                  icon="el-icon-s-promotion"
                  :loading="testBroadcastLoading"
                  :disabled="!testUserId"
                  @click="testBroadcast()"
                >
                  Send
                </el-button>
              </el-tooltip>
            </el-row>
          </el-collapse-item>
        </el-collapse>

        <el-card
          class="nodePreviewSection"
          width="100%"
          shadow="never"
          body-style="border: 1px 0px"
        >
          <span class="has-text-dark">Node Preview</span>
        </el-card>

        <!-- New broadcast format -->
        <div v-if="Array.isArray(broadcast.payload.content)">
          <el-row
            v-for="(content, index) in broadcast.payload.content"
            :key="index"
            class="broadcast-container"
          >
            <Card
              v-if="isCard(content)"
              :ref="`content-${index}`"
              :card="content"
              :editable="editable"
            />

            <Bubble
              v-else
              :ref="`content-${index}`"
              :value="content"
              :editable="editable"
              hide-buttons
              show-text-chars
              @addButton="addButton(index)"
            />

            <!-- <el-button-group style="margin-top:4px;">
              <el-button
                aria-label="Move Up"
                :disabled="index === 0"
                icon="el-icon-arrow-up"
                @click="moveUp(index)"
              />
              <el-button
                aria-label="Delete"
                type="danger"
                a
                icon="el-icon-delete"
                style="width:100px;"
                @click="removeContent(index)"
              />
              <el-button
                aria-label="Move Down"
                :disabled="index >= broadcast.payload.content.length - 1"
                icon="el-icon-arrow-down"
                @click="moveDown(index)"
              />
            </el-button-group>-->
          </el-row>
        </div>

        <div v-else-if="Array.isArray(broadcast.payload)">
          <div
            v-for="(content, index) in broadcast.payload"
            :key="index"
            class="broadcast-container"
          >
            <Card
              v-if="isCard(content)"
              :ref="`content-${index}`"
              :card="content"
              :index="index"
              :editable="editable"
            />

            <Bubble
              v-else
              :ref="`content-${index}`"
              :index="index"
              :value="content"
              :editable="editable"
              hide-buttons
              show-text-chars
              @addButton="addButton(index)"
            />
          </div>
        </div>
        <div v-else>
          <Card
            v-if="isCard(broadcast.payload.content)"
            :ref="`content-${0}`"
            :card="content"
            :editable="editable"
          />

          <Bubble
            v-else
            :ref="`content-${0}`"
            :value="broadcast.payload.content"
            :editable="editable"
            hide-buttons
            show-text-chars
            @addButton="addButton(0)"
          />
        </div>
      </el-row>
      <el-row v-if="showAdvanced">
        <JSONEditor ref="jsonEditor" v-model="broadcast" />
      </el-row>
    </div>
  </el-card>
</template>
<script>
import Card from "@/components/Card";
import Bubble from "@/components/Bubble";
import JSONEditor from "@/components/JSONEditor";

import { isPast, pickerOptions } from "@/helperMethods/broadcast/index";
import { mapGetters } from "vuex";

import moment from "moment-timezone";
import _ from "lodash";
import Vue from "vue";

export default {
  components: {
    Card,
    Bubble,
    JSONEditor,
  },
  data() {
    return {
      advanceBroadcastSettings: ["setting"],
      testUserId: "",
      testBroadcastLoading: false,
      loadingDelete: false,
      loadingSave: false,
      pickerOptions,
      estimatedReach: {},
      loadingEstimatedReach: false,
    };
  },
  computed: {
    ...mapGetters([
      "selectedBroadcast",
      "selectedBroadcastId",
      "showAdvanced",
      "facebookTestUserIds",
      "broadcastTags",
    ]),
    datetime() {
      return moment
        .unix(Number(this.selectedBroadcast.timestamp) / 1000)
        .format("DD-MM-YYYY HH:mm");
    },
    broadcast: {
      get() {
        const selectedBroadcast = this.selectedBroadcast;

        // let timestamp = this.$route.params.timestamp;
        // const selectedBroadcast =
        //   timestamp && this.$store.state.broadcasts[timestamp]
        //     ? this.$store.state.broadcasts[timestamp].payload
        //     : [];
        return selectedBroadcast;
      },
      set(value) {},
    },
    editable() {
      return this.selectedBroadcastId ? !isPast(this.selectedBroadcastId) : true;
    },
  },
  methods: {
    moment: moment,
    isPast: isPast,
    isCard(content) {
      return (
        content &&
        typeof content !== "string" &&
        typeof content.subtext === "string" &&
        typeof content.image === "string"
        // && typeof content.url === "string"
        // subtext, image & url are unique to cards
      );
    },

    addButton(contentIndex = 0) {
      const content = _.get(this.selectedBroadcast, "payload.content");
      let selectedContent = {};
      if (Array.isArray(content)) {
        selectedContent = content[contentIndex];
      } else {
        if (!content.buttons) {
          this.$set(this.selectedBroadcast.payload.content, "buttons", []);
        }
      }

      selectedContent.buttons.push({
        text: "Enter button text",
        event: null,
        data: null,
        url: null,
        phone: null,
        webview_height_ratio: null,
      });
    },
    moveDown(index) {
      let placeholder = this.broadcast[index + 1];
      this.$set(this.broadcast, index + 1, this.broadcast[index]);
      this.$set(this.broadcast, index, placeholder);
    },

    // TODO: WILL BE REMOVED SOON
    removeContent(index) {
      this.broadcast.splice(index, 1);
    },
    moveUp(index) {
      let placeholder = this.broadcast[index - 1];
      this.$set(this.broadcast, index - 1, this.broadcast[index]);
      this.$set(this.broadcast, index, placeholder);
    },
    addCondition() {
      const newCondition = { property: "", operator: "", value: "" };

      const conditions = this.selectedBroadcast.conditions || [];
      conditions.push(newCondition);
      Vue.set(this.selectedBroadcast, "conditions", conditions);
    },
    removeCondition(conditionIndex) {
      const conditions = this.selectedBroadcast.conditions || [];
      conditions.splice(conditionIndex, 1);
      Vue.set(this.selectedBroadcast, "conditions", conditions);
    },
    testBroadcast() {
      this.testBroadcastLoading = true;
      this.$store
        .dispatch("TEST_BROADCAST", {
          timestamp: this.selectedBroadcastId,
          broadcast: this.selectedBroadcast,
          testUserId: this.testUserId,
        })
        .then((isSuccessful) => {
          this.testBroadcastLoading = false;
          if (isSuccessful) {
            this.$message({
              type: "success",
              message: "Successfully sent message to test user",
            });
          } else {
            this.$message({
              type: "error",
              message: "Encountered error sending message to test user",
            });
          }
        })
        .catch((err) => {
          this.testBroadcastLoading = false;
          let message = "Encountered error sending message to test user.";
          const status = _.get(err, "response.status");
          switch (status) {
            case 400:
              message = "No test user is assigned. Please assign a test user first.";
              break;
            case 418:
              message = "No posts to send test users.";
              break;
            default:
          }

          this.$message({
            type: "error",
            message,
          });
        });
    },
    deleteBroadcast(broadcastTimestamp) {
      if (broadcastTimestamp) {
        this.$confirm("Delete broadcast. Continue?", "Are you sure?", {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning",
        }).then(() => {
          this.loadingDelete = true;
          this.$store
            .dispatch("DELETE_BROADCAST", { timestamp: broadcastTimestamp })
            .then((isDeleted) => {
              this.loadingDelete = false;
              if (isDeleted) {
                this.$message({
                  type: "success",
                  message: "Successfully deleted broadcast",
                });
                this.$router.push("/broadcast");
              } else {
                this.$message({
                  type: "error",
                  message: "Encountered error deleting broadcast",
                });
              }
            })
            .catch((err) => {
              this.loadingDelete = false;
              this.$message({
                type: "error",
                message: "Encountered error deleting broadcast",
              });
            });
        });
      }
    },
    saveBroadcast() {
      this.loadingSave = true;
      const selectedBroadcast = this.selectedBroadcast;

      let oldTimestamp = _.clone(selectedBroadcast.timestamp);
      const newTimestamp = moment(selectedBroadcast.date).format("x");

      if (oldTimestamp !== newTimestamp) {
        selectedBroadcast.timestamp = newTimestamp;
      }

      this.$store
        .dispatch("UPDATE_BROADCAST", {
          oldTimestamp,
          broadcast: selectedBroadcast,
        })
        .then((isUpdated) => {
          this.loadingSave = false;
          if (isUpdated) {
            this.$nextTick(() => {
              this.$store.commit("SET_SELECTED_BROADCAST", newTimestamp);
            });

            this.$message({
              type: "success",
              message: "Successfully updated broadcast",
            });
          } else {
            this.$message({
              type: "error",
              message: "Encountered error updating broadcast",
            });
          }
        })
        .catch((err) => {
          this.loadingSave = false;
          this.$message({
            type: "error",
            message: "Encountered error updating broadcast",
          });
        });

      this.loadingEstimatedReach = true;
      this.$store
        .dispatch("GET_ESTIMATED_REACH", { broadcast: this.selectedBroadcast })
        .then((reach) => {
          this.loadingEstimatedReach = false;
          if (reach) {
            this.estimatedReach[this.selectedBroadcastId] = reach;
          } else {
            this.estimatedReach[this.selectedBroadcastId] = 0;
          }
        })
        .catch((err) => {
          this.loadingEstimatedReach = false;
          this.$message({
            type: "error",
            message: "Encountered error getting broadcast estimated reach",
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/colors.scss";

.keyreply-conditions-row button {
  opacity: 0;
  margin-left: 5px;
}
.keyreply-conditions-row:hover button {
  opacity: 1;
}
.el-card.nodePreviewSection .el-card__body {
  padding: 5px !important;
}
.el-card.nodePreviewSection {
  border-radius: 0px;
  background-color: $color-light;
  margin: 0px -20px 20px -20px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.broadcast-container {
  text-align: center;
  width: 310px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 800px) {
  .selected-broadcast-card {
    min-height: 700px;
  }
}

@media only screen and (max-width: 800px) {
  .selected-broadcast-card {
    min-height: 500px;
  }
}

.text-option-sub-title {
  font-weight: bold;
  font-size: 1em;
}
</style>
