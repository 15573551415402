var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('el-dialog',{staticClass:"custom-dialog",attrs:{"visible":_vm.customDialog,"width":"40%"},on:{"update:visible":function($event){_vm.customDialog=$event},"closed":_vm.closeDialog}},[_c('span',{staticClass:"dialog-heading",attrs:{"slot":"title"},slot:"title"},[_vm._v(" Customize \""+_vm._s(_vm.nodeForm.title)+"\" ")]),_c('el-tabs',{model:{value:(_vm.customizeTabName),callback:function ($$v) {_vm.customizeTabName=$$v},expression:"customizeTabName"}},[_c('el-tab-pane',{attrs:{"label":"Customize node","name":"customize"}},[(_vm.nodeForm.type === 'standard' || _vm.nodeForm.type === 'frame')?_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{attrs:{"span":20}},[_c('p',{staticClass:"customize-dialog"},[_vm._v("Slots")]),_c('span',[_vm._v(" Enable this to gather the information your virtual assistant needs to respond to a user within a single node. ")])]),_c('el-col',{attrs:{"span":4}},[_c('el-switch',{on:{"change":function($event){_vm.setEdited('slotToggled', true);
                _vm.changeSlot();}},model:{value:(_vm.currentSlot),callback:function ($$v) {_vm.currentSlot=$$v},expression:"currentSlot"}})],1)],1):_vm._e(),(_vm.nodeForm.type === 'standard' || _vm.nodeForm.type === 'frame')?_c('el-row',{staticStyle:{"margin-top":"30px"},attrs:{"gutter":10}},[_c('el-col',{attrs:{"span":20}},[_c('p',{staticClass:"customize-dialog"},[_vm._v("Multiple Responses")]),_c('span',[_vm._v(" Enable multiple responses so that your virtual assistant can provide different responses to the same input, based on other conditions. ")])]),_c('el-col',{attrs:{"span":4}},[_c('el-switch',{on:{"change":function($event){_vm.setEdited('MCRToggled', true);
                _vm.changeMcr();}},model:{value:(_vm.currentMcr),callback:function ($$v) {_vm.currentMcr=$$v},expression:"currentMcr"}})],1)],1):_vm._e(),(_vm.nodeForm.type === 'folder')?_c('p',{staticClass:"customize-dialog"},[_vm._v(" There is no customization available for this node ")]):_vm._e()],1)],1)],1),_c('el-dialog',{attrs:{"visible":_vm.showMcrConfirm,"before-close":_vm.closeMcrConfirm},on:{"update:visible":function($event){_vm.showMcrConfirm=$event}}},[_c('span',{staticClass:"dialog-heading",attrs:{"slot":"title"},slot:"title"},[_vm._v(" Warning: \""+_vm._s(_vm.nodeForm.title)+"\" ")]),_c('div',{staticClass:"customize-dialog"},[_vm._v(" All existing Multiple Conditioned Responses will be "),_c('span',{staticClass:"has-text-danger"},[_vm._v("deleted")]),_vm._v(". Do you wish to proceed? ")]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.showMcrConfirm = false;
          _vm.currentMcr = true;}}},[_vm._v("Cancel")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.showMcrConfirm = false;
          _vm.enableMcr();}}},[_vm._v(" Confirm ")])],1)]),_c('el-dialog',{attrs:{"visible":_vm.showSlotNodeConfirm,"before-close":_vm.closeSlotNodeConfirm},on:{"update:visible":function($event){_vm.showSlotNodeConfirm=$event}}},[_c('span',{staticClass:"dialog-heading",attrs:{"slot":"title"},slot:"title"},[_vm._v(" Warning: \""+_vm._s(_vm.nodeForm.title)+"\" ")]),_c('div',{staticClass:"customize-dialog"},[_vm._v(" All existing Slots will be "),_c('span',{staticClass:"has-text-danger"},[_vm._v("deleted")]),_vm._v(". Do you wish to proceed? ")]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.showSlotNodeConfirm = false;
          _vm.currentSlot = true;}}},[_vm._v(" Cancel ")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.showSlotNodeConfirm = false;
          _vm.enableSlot();}}},[_vm._v(" Confirm ")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }