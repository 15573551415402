<template>
  <div class="container">
    <div class="header">
      <h1>Form Preview</h1>
      <a @click="reload" class="reload-btn" style="font-size: 16px">
        <i class="el-icon-refresh-right"></i
      ></a>
    </div>
    <div class="iframe-wrapper" v-loading="loading">
      <iframe :src="previewUrl" ref="previewIframe" @load="loading = false"></iframe>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "FormPreview",
  props: {
    form: Object,
  },
  data() {
    return {
      previewUrl: this.getPreviewUrl(),
      loading: true,
    };
  },
  methods: {
    getPreviewUrl() {
      this.loading = true;
      return `${_.get(this.form, "settings.options.API_URL", "")}/forms/embedded/${
        this.form.id
      }/preview?_t=${new Date()}`;
    },
    reload() {
      this.previewUrl = this.getPreviewUrl();
    },
  },
  mounted() {
    this.$eventBus.$on("reload-form-preview", () => {
      this.reload();
    });
  },
};
</script>

<style scoped lang="scss">
$primary-color: #1d57d8;
.container {
  border-radius: 5px;
  border: 2px solid $primary-color;
  display: grid;
  position: relative;
  height: 640px;

  .header {
    height: 35px;
    display: flex;
    background: $primary-color;
    color: white;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;

    h1 {
      float: left;
      font-size: 0.9em;
    }

    a {
      float: right;
      cursor: pointer;
      font-size: 0.8em;
      border: 1px solid $primary-color;
      border-radius: 4px;
      padding: 4px 8px;
      transition: all 0.25s ease-in-out;
      &:hover {
        border: 1px solid #fff;
      }
    }
  }

  .iframe-wrapper {
    position: absolute;
    top: 35px;
    left: 0;
    right: 0;
    bottom: 0;
  }

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.reload-btn {
  color: white;
  border: none !important;

  &:hover {
    border: none !important;
  }

  &:focus {
    border: none !important;
  }
}
</style>
