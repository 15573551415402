<template>
  <el-container class="faq-container">
    <el-header class="sticky-header" height="auto">
      <el-row type="flex" justify="space-between" style="flex-flow: row wrap">
        <el-menu
          :default-active="activeIndex"
          mode="horizontal"
          style="border: 0; background-color: transparent"
          @select="handleSelect"
        >
          <el-menu-item index="1">Training Dataset</el-menu-item>
          <el-menu-item index="2">Conversational Data</el-menu-item>
          <el-menu-item index="3">User Submitted Question</el-menu-item>
          <el-menu-item
            v-if="
              $store.state.modules.evaluation.enabled && checkRoles(getPermittedRoles('evaluation'))
            "
            index="4"
          >
            Evaluation
          </el-menu-item>
          <el-menu-item index="5">Trending Topics</el-menu-item>
        </el-menu>

        <faq-action-buttons v-if="activeIndex !== '3'" />
      </el-row>
    </el-header>

    <!-- Training Dataset Page Content -->
    <div v-if="activeIndex == '1'" class="content-section">
      <faq-training-dataset
        :is-dataset-loading="fetching"
        :expanded-rows="expandedRows"
        :dialog-visible="dialogVisible"
        :save="save"
        :new-variation="newVariation"
        :candidate="candidate"
        :open-dialog="openDialog"
        :dirty="dirty"
        :is-dirty="isDirty"
        :test-all="testAll"
        @save="save"
        @isDirty="isDirty"
      />
    </div>

    <!-- Conversational Data Page Content -->
    <div v-if="activeIndex == '2'" class="content-section">
      <faq-conversational-data
        :is-busy="isFetchingUnlabeledMessage"
        :new-variation="newVariation"
        @save="save"
      />
    </div>

    <!-- Conversational Data Page Content -->
    <div v-if="activeIndex == '3'" class="content-section">
      <faq-user-added-data></faq-user-added-data>
    </div>

    <div v-if="activeIndex == '4'" class="content-section">
      <Evaluation />
    </div>

    <div v-if="activeIndex == '5'" class="content-section trending-topic-content-section">
      <TrendingTopics :heightTrending="heightTrendingTopic" />
    </div>

    <el-row v-if="$store.state.showAdvanced">
      <JSONEditor ref="jsonEditor" v-model="dataset" />
    </el-row>

    <FaqSelectTrainTopicsDialog
      v-if="$store.state.faq.selectTrainTopicsDialogVisible === true"
      :select-train-topics-dialog-visible="$store.state.faq.selectTrainTopicsDialogVisible"
    />
  </el-container>
</template>
<script>
import Vue from "vue";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import JSONEditor from "@/components/JSONEditor";
import FaqTrainingDataset from "@/components/Faq/FaqTrainingDataset.vue";
import FaqActionButtons from "@/components/Faq/FaqActionButtons.vue";
import FaqConversationalData from "@/components/Faq/FaqConversationalData.vue";
import FaqSelectTrainTopicsDialog from "@/components/Faq/FaqSelectTrainTopicsDialog.vue";
import FaqUserAddedData from "@/components/Faq/FaqUserAddedData/Index.vue";
import Evaluation from "@/views/Evaluation/Index.vue";
import TrendingTopics from "@/views/TrendingTopics/Index.vue";
import { checkRoles, getPermittedRoles } from "@/helperMethods/auth";
import { getHeightElement } from "@/helperMethods/trendingTopics/methods";

import gql from "graphql-tag";
import { prepareDatasetToSave, updateApolloDataset } from "@/helperMethods/faq/util";

export default Vue.extend({
  components: {
    JSONEditor,
    FaqTrainingDataset,
    FaqConversationalData,
    FaqSelectTrainTopicsDialog,
    FaqActionButtons,
    FaqUserAddedData,
    Evaluation,
    TrendingTopics,
  },
  data() {
    return {
      testQuery: "",
      widgetTestResult: null,
      saving: false,
      fetching: false,
      testing: false,
      publishing: false,
      expandedRows: [],
      candidate: {},
      dialogVisible: false,
      dirty: false,
      fileList: [],
      activeIndex: "1",
      isFetchingUnlabeledMessage: false,
      heightTrendingTopic: 0,
    };
  },
  updated() {
    this.heightTrendingTopic = getHeightElement("faq-container", "el-header", 0);
  },
  computed: {
    dataset: {
      get() {
        return _.get(this.$store.state, "training.dataset", []);
      },
      set(value) {
        this.$store.commit("SET_FAQ_DATASET", {
          dataset: value,
        });
      },
    },
  },
  mounted() {
    this.fetchFaqUnlabeled();
    this.fetchFaqDataset();
  },
  methods: {
    checkRoles,
    getPermittedRoles,
    async fetchFaqDataset() {
      try {
        this.fetching = true;
        await this.$store.dispatch("FETCH_FAQ_DATASET");
      } catch (error) {
        this.$notify.error({
          title: "Error",
          message: "Failed to fetch faq dataset.",
          position: "bottom-right",
        });
      } finally {
        this.fetching = false;
      }
    },
    async fetchFaqUnlabeled() {
      this.isFetchingUnlabeledMessage = true;
      try {
        await this.$store.dispatch("FETCH_FAQ_UNLABELED");
      } catch (error) {
        this.$notify.error({
          title: "Error",
          message: "Failed to fetch faq unlabeled message.",
          position: "bottom-right",
        });
      } finally {
        this.isFetchingUnlabeledMessage = false;
      }
    },
    openDialog(row) {
      this.candidate = row;
      this.dialogVisible = true;
    },
    testAll(label, wrongOnly) {
      let pair = this.dataset.find((data) => data.id === label);
      if (pair) {
        let questions = wrongOnly
          ? _.filter(pair.variations, (variation) => !variation.test).map((a) => a.text)
          : pair.variations.map((a) => a.text);
        return this.$store.dispatch("TEST_FAQ", { questions }).then((predictions) => {
          pair.variations.forEach((variation, index) => {
            // let prediction = predictions[index];
            let prediction = predictions.find((prediction) => prediction.text === variation.text);

            if (!prediction) {
              return;
            }

            if (prediction.predictions.length > 0) {
              let predictedLabel = prediction.predictions[0].label;
              let predictedProbability = prediction.predictions[0].probability;
              this.$set(variation, "test", predictedLabel === label ? true : predictedLabel);
              this.$set(variation, "score", predictedProbability);
            } else {
              this.$set(variation, "test", false);
              this.$set(variation, "score", 0);
            }
          });
        });
      } else {
        // console.warn("ID is not defined. Terminate test");  -> Unexpected console statement
      }
    },

    /**
     * id = intent.intentName. id is unique identifier. It has no immediate usecase
     */
    newVariation(selectedIntent, save = true) {
      let { id, text, variations } = selectedIntent;
      // FIXME: to change to search by intentName only. intentName are always in upperCase
      let pair = this.dataset.find((data) => {
        return data.id === id || data.intentName === id.toUpperCase();
      });
      let pairIndex = _.findIndex(this.dataset, (row) => {
        return row.id === id || row.intentName === id.toUpperCase();
      });

      if (!variations) {
        if (pair) {
          variations = pair.variations;
        } else {
          return;
        }
      }

      if (text && text.trim().length > 0) {
        let lines = text.split(/\n/);
        _.forEach(lines, (line) => {
          line = line.trim();
          if (line) {
            variations.unshift({
              text: line,
              id: uuid(),
              language: "en",
              intent_ranking: [],
              is_suggestion: false,
            });
          }
        });
        if (pair) {
          pair.variations = variations;
          this.dataset[pairIndex] = pair;
        }
        if (save) {
          this.save({ rowId: pair.id, action: "CREATE" });
        }
        // this.$set(this.expandedRows, 0, id);
        selectedIntent.text = "";
      }
    },

    isDirty() {
      this.dirty = true;
    },

    async save(payload) {
      this.saving = true;
      const datasetToSave = prepareDatasetToSave(this.$store.state.training.dataset);

      // Payload doesn't do anything?
      // const { rowId, action, row } = payload

      // const currentIntent = _.find(datasetToSave, { id: rowId });
      // const isCreate = action === "CREATE";
      // const isDelete = action === "DELETE";
      // const isModify = action === "MODIFY";

      const isSaved = await this.$apollo
        .mutate({
          mutation: gql`
            mutation ($dataset: JSON!) {
              faqAPI {
                saveDataset(dataset: $dataset)
              }
            }
          `,
          variables: {
            dataset: datasetToSave,
          },
        })
        .catch(() => {
          this.$notify.error({
            title: "Error",
            message: "Failed to save FAQ dataset.",
            position: "bottom-right",
          });
          return false;
        });

      if (isSaved) {
        this.dirty = false;
        this.saving = false;
        this.$notify.success({
          type: "success",
          title: "FAQ Saved",
          // title: "Intent Saved",
          // message: "Saved FAQ Intent",
          message: "Saved FAQ Dataset",
          position: "bottom-right",
        });
        updateApolloDataset(datasetToSave);
      }
    },

    handleSelect(key, keyPath) {
      this.activeIndex = key;
    },
  },
  apollo: {
    $subscribe: {
      intentChanged: {
        query: gql`
          subscription {
            intentObject: intentChanged
          }
        `,
        result({ data }) {
          const intentObject = _.get(data, "intentObject");
          this.$store.commit("SET_UPDATED_INTENT", intentObject);
        },
      },
    },
  },
});
</script>

<style scoped lang="scss">
@import "../assets/scss/colors.scss";

.el-button {
  padding: 11px 20px;
}

.el-table .warning-row {
  background: oldlace;
}
.el-input__inner {
  background-color: $color-light;
  border: 1px solid $color-light;
}
.el-input__inner::placeholder {
  color: $color-dark;
}

#previous-bot-popup thead {
  color: black;
}

body {
  font-family: "Open Sans", sans-serif !important;
}
.el-table td div {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.el-table th {
  background-color: #fff;
}
.el-table tr {
  background-color: $color-light !important;
}

.el-badge__content.is-fixed {
  right: auto;
}

.el-tabs--border-card {
  border: none !important;
}
.el-tabs--border-card > .el-tabs__header {
  border-bottom: none !important;
}

.el-tabs--border-card > .el-tabs__header .el-tabs__item {
  border: none !important;
}

.el-tabs__item {
  font-size: 12px !important;
  font-weight: bold !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.el-tag + .el-tag {
  margin-left: 10px;
}

.el-textarea__inner {
  background-color: $color-light;
}
.el-textarea__inner::placeholder {
  color: $color-dark;
}

.table-cell {
  word-break: normal;
  cursor: pointer;
  width: 100%;
  height: 40px;
  padding-top: 0.5em;
}

input[type="text"] {
  background-color: $color-primary;
}

.add-tag-btn {
  padding: 5px 10px;
}
.tab-buttons {
  position: fixed;
  right: 47px;
  top: 0px;
  padding: 15px;
  float: left;
  justify-content: center;
  text-align: right;
  z-index: 9999;
  background-color: $color-light;
}
.bot-version-colapse {
  width: 40%;
  float: right;
  /* right: -27px; */
  /* position: absolute; */
  text-align: right;
}
html,
button,
th,
td {
  font-size: 12px;
}
.el-button.is-circle {
  border: 0px;
}

.previous-bot-popup-footer {
  text-align: center;
}
/* TODO: weird styling */
.sticky-header {
  line-height: 57px;
  padding: 0;
  height: auto;
}
.sticky-header .el-menu-item {
  height: auto;
  line-height: 1;
  padding: 15px 12px;
  font-size: 12px;
}
.el-footer {
  text-align: center;
  margin-top: 30px;
}
.el-main {
  padding-top: 0;
}
.content-section {
  padding: 15px;
}
.content-section .el-table {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.please_text {
  margin-top: 150px;
}
.faq-container {
  min-height: 700px;
  height: 100%;
}

.actions-var-btn {
  border: none;
  background-color: transparent;
}

.el-table {
  font-size: 12px;
}

.addExampleBtn {
  float: right;
}

.el-dropdown-menu.el-popper {
  max-height: 300px;
  overflow-y: scroll;
}

.el-dropdown-menu__item:not(.is-disabled):hover,
.el-dropdown-menu__item:focus {
  background-color: $color-light;
  color: $color-dark;
}

.el-progress__text {
  font-size: 10px !important;
}

.el-button {
  padding: 11px 20px !important;
}

/* .el-button.is-plain:hover, */
/* .el-button.is-plain:focus, */
.el-button.el-button--default:hover,
.el-button.el-button--default:focus,
.el-button.el-button--plain:hover,
.el-button.el-button--plain:focus {
  border: 1px solid $color-info !important;
  background-color: $color-info;
  color: #ffffff;
}
</style>
