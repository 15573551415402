import { MutationTree } from "vuex/types";
import { FileUploadState } from "./types";
import Vue from "vue";

const mutations: MutationTree<FileUploadState> = {
  SET_STORAGE_TYPE: (state, storageType: string) => {
    Vue.set(state, "storageType", storageType);
  },
};

export default mutations;
