<template>
  <section class="outreach-tag-segments">
    <list-tag :tags="tagsData" :type="OUT_REACH_TAG_TYPE.TAG" />
  </section>
</template>

<script>
import ListTag from "../TagComponents/ListTag.vue";
import { OUT_REACH_TAG_TYPE } from "@/store/modules/outreach/types";

export default {
  components: {
    ListTag,
  },
  data() {
    return {
      OUT_REACH_TAG_TYPE,
    };
  },
  computed: {
    tagsData() {
      return this.$store.state.outreach.tags.data || [];
    },
  },
};
</script>
