export const messageTemplateCategories = {
  "v16.0": [
    {
      label: "Utility",
      value: "UTILITY",
      details:
        "Send account updates, order updates, alerts, and more to share important information.",
    },
    {
      label: "Marketing",
      value: "MARKETING",
      details:
        "Send promotional offers, product announcements, and more to increase awareness and engagement.",
    },
    {
      label: "Authentication",
      value: "AUTHENTICATION",
      details: "Send codes that allow your customers to access their accounts.",
    },
  ],
  "v15.0": [
    {
      label: "Transactional",
      value: "TRANSACTIONAL",
      details:
        "Send account updates, order updates, alerts, and more to share important information.",
    },
    {
      label: "Marketing",
      value: "MARKETING",
      details:
        "Send promotional offers, product announcements, and more to increase awareness and engagement.",
    },
    {
      label: "One-time passwords",
      value: "OTP",
      details: "Send codes that allow your customers to access their accounts.",
    },
  ],
  "v14.0": [
    {
      label: "Transactional",
      value: "TRANSACTIONAL",
      details:
        "Send account updates, order updates, alerts, and more to share important information.",
    },
    {
      label: "Marketing",
      value: "MARKETING",
      details:
        "Send promotional offers, product announcements, and more to increase awareness and engagement.",
    },
    {
      label: "One-time passwords",
      value: "OTP",
      details: "Send codes that allow your customers to access their accounts.",
    },
  ],
};
