<template>
  <div style="display: flex; align-items: center; justify-content: center; margin-top: 100px">
    <div style="text-align: center">
      <img
        src="../../static/keyreply-bot-customer-service-dead.png"
        style="max-width: 500px; max-height: 100%"
      />
    </div>
    <div>
      You have successfully logged out from the system. Please click here to login.
      <br />
      <br />
      <el-button size="small" type="success" @click="redirectLogin">Login</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: "Logged out",
      notAccess: "You do not have access",
      type: "logout",
    };
  },
  methods: {
    redirectLogin() {
      this.$router.replace("/");
    },
  },
};
</script>
