<template>
  <el-card>
    <h3 slot="header">
      {{ setting.label || name }}
      <Tooltip :label="setting.label || name" :content="setting.detail" />
    </h3>

    <el-row :gutter="20">
      <el-col :span="12" v-for="(mapping, index) in value[name]" :key="index" class="box-container">
        <el-card class="box-card">
          <el-form :model="mapping">
            <el-form-item label="Key name">
              <el-input v-model="mapping.name" placeholder="ex: vendor A"></el-input>
            </el-form-item>
            <el-form-item label="Value for 'X-API-KEY' header">
              <el-input
                v-model="mapping.apiKey"
                :disabled="true"
                :type="showKeys[index] ? 'text' : 'password'"
              ></el-input>
              <p class="hint" v-if="showKeys[index]" size="small">
                Please make a note of the password in a secure location, as it will no longer be
                visible once this page is refreshed
              </p>
              <el-button @click="generateApiKey(index)" size="small" type="primary"
                >Generate</el-button
              >
            </el-form-item>

            <!-- DELETE BUTTON -->
            <el-form-item>
              <el-button type="danger" @click="onDeleteMapping(index)" size="small"
                ><i class="el-icon-delete"></i
              ></el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <el-button type="primary" @click="addMapping" size="small">Add Item</el-button>
  </el-card>
</template>

<script>
import { v4 as uuid } from "uuid";
import Tooltip from "../../Tooltip.vue";

export default {
  name: "DevCenterApiKey",
  props: ["setting", "value", "modules", "name"],
  components: {
    Tooltip,
  },
  data() {
    return {
      defaultValue: {
        name: "",
        apiKey: "",
      },
      showKeys: this.value[this.name] ? this.value[this.name].map(() => false) : [], // Array to keep track of which keys should be shown
    };
  },
  methods: {
    addMapping() {
      if (this.value[this.name].length < 5) {
        this.value[this.name].push({ ...this.defaultValue });
        this.showKeys.push(true); // Set the newly added key to be shown
      } else {
        this.$notify({
          title: "Warning",
          type: "warning",
          position: "bottom-right",
          message: "Maximum of 5 keys are allowed",
        });
      }
    },
    onDeleteMapping(index) {
      if (this.value[this.name].length) {
        this.value[this.name].splice(index, 1);
        this.showKeys.splice(index, 1); // Also remove the corresponding showKey
      }
    },
    generateApiKey(index) {
      this.value[this.name][index].apiKey = uuid();
      this.showKeys[index] = true;
    },
  },
  mounted() {
    if (!_.isArray(this.value[this.name]))
      this.$set(this.value, this.name, this.setting.default || []);
  },
};
</script>

<style>
.box-card {
  padding: 20px;
}
.box-container {
  margin-bottom: 20px;
}
.hint {
  font-size: 12px;
  color: #888;
  margin-top: 5px;
  line-height: 20px;
}
</style>
