<template>
  <el-tabs v-model="activeName">
    <el-tab-pane label="Monitor" name="monitor"><supervisor-monitor /></el-tab-pane>
    <el-tab-pane label="Settings" name="settings">
      <SupervisorSettings />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import SupervisorMonitor from "@/views/Supervisor/SupervisorMonitor";
import SupervisorSettings from "@/views/Supervisor/SupervisorSettings";

export default {
  components: {
    SupervisorMonitor,
    SupervisorSettings,
  },
  data() {
    return {
      // Set the default tab to "monitor"
      activeName: "monitor",
    };
  },
};
</script>
