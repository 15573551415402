<template>
  <el-table :data="data">
    <el-table-column label="Title" sortable>
      <template slot-scope="props">
        <a :href="url(props.row)">{{ props.row.text }}</a>
      </template>
    </el-table-column>
    <el-table-column prop="score" label="Frequency" sortable />
  </el-table>
</template>

<script>
export default {
  props: ["data", "label"],
  methods: {
    url(row) {
      if (row.url) {
        return row.url;
      } else if (/^http/i.test(row.text)) {
        return row.text;
      } else {
        return null;
      }
    },
  },
};
</script>
