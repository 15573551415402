<template>
  <div>
    <el-row>
      <el-col>
        <el-collapse>
          <el-collapse-item name="1">
            <template slot="title">
              <el-tooltip
                placement="left"
                content="The current test file used to test bot performance"
              >
                <i class="header-icon el-icon-question" style="margin-right: 8px"></i> </el-tooltip
              >Current file:
              <b :style="getFileNameStyle()">{{ currentTestFileName }}</b>
            </template>
            <el-row :gutter="10">
              <el-col>
                <el-upload
                  drag
                  ref="upload"
                  action="#"
                  :before-upload="beforeUpload"
                  :on-error="handleError"
                  :on-success="handleSuccess"
                  :on-remove="handleRemove"
                  :on-change="handleChange"
                  :file-list="fileList"
                >
                  <div class="el-upload__text file-upload-inner-text">
                    Drag file / click to upload
                  </div>
                </el-upload>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-button class="has-text-dark" style="width: 47%" @click="downloadTemplate">
                  Download CSV Template
                </el-button>

                <el-tooltip
                  v-if="noUploadedFileDetected"
                  content="Did not detect any content from uploaded file. Please check file again"
                  placement="top"
                >
                  <div style="display: inline; margin-left: 10px">
                    <el-button class="download button" :disabled="noUploadedFileDetected">
                      Download Current File
                    </el-button>
                  </div>
                </el-tooltip>
                <el-button v-else plain class="download button" @click="downloadFile">
                  Download Current File
                </el-button>
              </el-col>
            </el-row>
            <!-- </el-dialog> -->
          </el-collapse-item>
        </el-collapse>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as Papa from "papaparse";
import XLSX from "xlsx";

export default {
  data() {
    return {
      currentTestFile: "",
      fileList: [],
      uploadFileLoading: false,

      // crawlTarget: "" // TODO: remove if need to
    };
  },
  computed: {
    currentTestFileName() {
      if (this.currentTestFile && this.currentTestFile.length > 0) {
        return this.currentTestFile;
      } else {
        return "No file uploaded";
      }
    },
    noUploadedFileDetected() {
      return typeof this.currentTestFile === "string" && this.currentTestFile.length === 0;
    },
  },
  mounted() {
    this.listLatestFile();
  },
  methods: {
    getFileNameStyle() {
      let style = "margin-left: 10px;";
      if (!this.currentTestFile || this.currentTestFile.length === 0) {
        style += "color:red";
      }
      return style;
    },
    listLatestFile() {
      this.$rest("get", "list_latest_file")
        .then((result) => {
          this.currentTestFile = result.fileName;
        })
        .catch(() => {
          this.$message.error("Error uploading file!");
        });
    },
    upload() {
      this.$refs.upload.submit();
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-1);
    },

    handleClose(done) {
      this.handleRemove();
      if (done) done();
    },

    handleSuccess(file) {
      const reader = new FileReader();

      reader.readAsText(file);
      const fileName = file.name;
      reader.onload = (e) => {
        Papa.parse(e.target.result, {
          header: true,
          complete: ({ data }) => {
            this.uploadFile(fileName, data);
          },
        });
      };
    },
    uploadFile(fileName, data) {
      this.$rest("post", "upload_file", {
        fileName,
        data,
      })
        .then((result) => {
          this.$message({
            type: "success",
            message: "File uploaded success!",
          });
          this.uploadFileLoading = false;
          this.currentTestFile = fileName;
        })
        .catch(() => {
          this.uploadFileLoading = false;
          this.$message.error("Error uploading file!");
        });
    },
    downloadFile(data) {
      this.$rest("get", "download_file")
        .then((result) => {
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.json_to_sheet(result.data.testData);
          XLSX.utils.book_append_sheet(wb, ws, "test_set");
          XLSX.writeFile(wb, result.data.fileName, {});
          this.batchTestLoading = false;
        })
        .catch(() => {
          this.$message.error("Error downloading file!");
        });
    },
    downloadTemplate() {
      var link = document.createElement("a");
      link.download = "test_file_template.csv";
      link.href = "/template/evaluation_test_file_template.csv";
      link.click();
    },

    beforeUpload(file) {
      const isCSV = file.type === "text/csv" || file.name.endsWith(".csv");
      if (isCSV) {
        this.handleSuccess(file);
      } else {
        this.$message.error("Uploaded file must be in CSV format!");
      }
      return isCSV;
    },

    handleError(err) {
      // console.log("An error occurred while importing file!"); -> Unexpected console statement
      // console.log(err);  -> Unexpected console statement
    },

    handleRemove() {
      this.fileList = [];
      this.uploadedContentList = [];
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/colors.scss";

.file-upload-inner-text {
  font-size: 20px;
  display: block;
  height: 50px;
  text-align: center;
  line-height: 160px;
  color: $color-dark;
}

.download.button {
  color: white;
  width: 50%;
  font-size: 14px;
}
</style>
