<template>
  <el-row type="flex" :gutter="20" style="height: 100%">
    <el-col :span="8" style="display: flex; flex-direction: column; padding: 0">
      <el-card style="height: 100%" class="agent-session-list">
        <ChatSearchBar class="search-bar-container" placeholder="Search by name or email" />
        <AgentSessionList style="width: 100%" class="agent-session-list-container" />
      </el-card>
    </el-col>
    <el-col :span="16">
      <el-card ref="chatWindow" v-loading="loading" class="agent-session-chat-details-card">
        <div
          v-if="!selectedChatSession"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            color: rgba(0, 0, 0, 0.4);
            height: 100%;
            flex-direction: column;
          "
        >
          Please select a livechat session to view the details and conversations.
        </div>
        <el-scrollbar class="agent-session-chat-details">
          <el-row style="text-align: center">
            <el-button
              type="text"
              class="more-conversation-button"
              v-if="showMoreConversationButton && selectedChatSession"
              :loading="buttonLoading"
              @click="updateChatInteractions()"
            >
              - show more conversations -
            </el-button>
          </el-row>
          <el-row
            style="margin-bottom: 0"
            v-for="(message, index) in chatInteractions"
            :key="index"
          >
            <!-- CHAT MESSAGE -->
            <div
              :style="{
                width: '70%',
                float: isMessageByUser(message.type) ? 'left' : 'right',
              }"
            >
              <el-alert
                v-if="isDisconnectMessage(message)"
                :title="message.data.content[0].text"
                :description="$store.state.showAdvanced ? `Reason: ${message.error}` : ''"
                style="margin-left: 20px; margin-bottom: 10px"
                type="error"
                effect="dark"
                show-icon
                :closable="false"
              />
              <el-alert
                v-else-if="isReconnectMessage(message)"
                :title="message.data.content[0].text"
                style="margin-left: 20px; margin-bottom: 10px"
                type="success"
                effect="dark"
                show-icon
                :closable="false"
              />
              <span v-else>
                <message
                  :key="message.RowKey"
                  :message="message"
                  :has-more-than-one-agent-in-chat="hasMoreThanOneAgentInChat"
                  font="Tahoma"
                  agent-bubble-color="#F0F0F0"
                  agent-text-color="#4b4f56"
                  user-bubble-color="#0084FF"
                  user-text-color="#fff"
                  button-outline-color="black"
                />
              </span>
            </div>
          </el-row>
        </el-scrollbar>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>
import { mapGetters } from "vuex";
import { isMessageByUser } from "@/helperMethods/livechat/util";
import Message from "@/components/ChatInteractions/Message/Index";
import ChatSearchBar from "@/views/Supervisor/ChatSearchBar";
import AgentSessionList from "@/views/Supervisor/AgentSessionList";
import { SYSTEM_MESSAGE } from "@/lib/viewConstants";

export default {
  components: { Message, AgentSessionList, ChatSearchBar },
  data() {
    return {
      chatInteractions: [],
      loading: false,
      buttonLoading: false,
      showMoreConversationButton: true,
    };
  },
  props: {},
  computed: {
    ...mapGetters("supervisor", ["selectedChatSession"]),
  },
  methods: {
    isMessageByUser,
    isDisconnectMessage(message) {
      return (
        message.error &&
        message.data.content &&
        message.data.content.length === 1 &&
        message.data.content[0].text
      );
    },
    isReconnectMessage(message) {
      return (
        message.data.content &&
        message.data.content.length === 1 &&
        message.data.content[0].text &&
        message.data.content[0].text.toLowerCase() === SYSTEM_MESSAGE.connected
      );
    },
    scrollToBottom() {
      if (this.$refs.chatWindow) {
        const container = this.$refs.chatWindow.$el;
        container.scrollTop = container.scrollHeight;
      }
    },
    scrollToTop() {
      if (this.$refs.chatWindow) {
        const container = this.$refs.chatWindow.$el;
        container.scrollBottom = container.scrollHeight;
      }
    },
    updateChatInteractions(shouldScrollToBottom = false) {
      const fetchMorePayload = this.getFetchMorePayload({
        ...this.selectedChatSession,
        interactions: this.chatInteractions,
      });
      this.buttonLoading = true;
      return this.$store
        .dispatch("FETCH_MORE_LIVECHAT_INTERACTION", fetchMorePayload)
        .then((newInteractions) => {
          const fetchedAll = newInteractions.length === 0;
          if (fetchedAll) {
            this.showMoreConversationButton = false;
          }

          const updatedInteractions = _.concat(newInteractions, this.chatInteractions);

          this.chatInteractions = _.chain(updatedInteractions)
            .uniqBy((interaction) => interaction.RowKey)
            .orderBy(["date_created"], ["asc"])
            .value();

          if (!shouldScrollToBottom) {
            this.$nextTick(this.scrollToTop);
          } else {
            this.$nextTick(this.scrollToBottom);
          }
          if (this.loading) this.loading = false;
          this.buttonLoading = false;
        })
        .catch((err) => {
          if (this.loading) this.loading = false;
          this.buttonLoading = false;
          this.$notify.error({
            title: "Error",
            message: "Encountered error fetching chat history",
            position: "bottom-right",
          });
        });
    },
    /**
     * @description Has more than 1 agent in chat, analog to Chat Interaction component
     * @return {boolean}
     */
    hasMoreThanOneAgentInChat() {
      return _.get(this.selectedChatSession, "agents", []).length > 1;
    },

    getFetchMorePayload(chatObject) {
      // Get the current size of the interactions, send it for pagination size
      const offset = _.chain(chatObject).get("interactions", []).size().value();
      const userId = chatObject.RowKey;
      const partitionKey = chatObject.PartitionKey;

      // Fetch 20 at a time
      const fetchMorePayload = { userId, partitionKey, limit: 20, offset };
      return fetchMorePayload;
    },
  },
  watch: {
    selectedChatSession(newVal) {
      this.chatInteractions = [];
      this.showMoreConversationButton = true;
      if (newVal !== undefined && newVal !== null) {
        this.loading = true;
        this.updateChatInteractions(true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.agent-session-list {
  ::v-deep .el-card__body {
    padding: 0;
  }
}

.search-bar-container {
  ::v-deep .el-input__inner {
    border: 0;
  }

  border-bottom: 1px solid #e6ebf5;
}

.agent-session-list-container {
  padding: 0;
}

.agent-session-details {
  ::v-deep .el-card__body {
    padding: 0;
  }
}

.agent-session-chat-details-card {
  height: 100%;
  ::v-deep .el-card__body {
    height: 100%;
    box-sizing: border-box;
  }
}
.agent-session-chat-details {
  height: 100%;
  width: 100%;
}

::v-deep .el-scrollbar__bar {
  z-index: 2 !important;
}
::v-deep .el-scrollbar__wrap ul {
  width: 90%;
}
::v-deep .el-scrollbar__view {
  width: 98% !important;
}
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
  margin-bottom: 0 !important;
}
</style>
