<template>
  <el-dialog
    :title="`Entity ${entityForm.name}`"
    :visible.sync="showDialog"
    :before-close="handleClose"
  >
    <el-form :model="entityForm" ref="entityForm" :disabled="entityForm.type === 'function'">
      <el-form-item label="Entity name" prop="name">
        <el-input disabled v-model="entityForm.name"></el-input>
      </el-form-item>
      <el-form-item label="Replace text*" prop="replaceText">
        <el-switch v-model="entityForm.replaceText" disabled></el-switch>
        <span v-if="entityForm.type !== 'function'" style="font-style: italic">
          <br />
          * All detected entities will
          {{ entityForm.replaceText ? "" : "not" }} be replaced with
          <strong>{{ entityForm.name || `entity name` }}</strong
          >.
        </span>
      </el-form-item>
      <el-form-item label="Type" prop="type">
        <el-select v-model="entityForm.type" disabled>
          <el-option value="regex">Pattern/Regex</el-option>
          <el-option value="list">List/Synonyms</el-option>
        </el-select>
      </el-form-item>

      <!-- Form type === regex -->
      <el-form-item v-if="entityForm.type === 'regex'" label="Pattern/Regex" prop="pattern">
        <el-input disabled v-model="entityForm.pattern" placeholder="/test/i"></el-input>
      </el-form-item>

      <!-- Form type === list -->
      <el-form-item v-if="entityForm.type === 'list'" label="List of synonyms" prop="list">
        <table class="el-table keyreply-hover-table">
          <tbody>
            <tr v-for="(synonyms, key, index) in entityForm.list" :key="index">
              <td style="font-weight: bold; font-size: 1.2em; width: 125px">
                {{ key }}
              </td>
              <td>
                <el-tag :key="tag" v-for="tag in synonyms" :disable-transitions="false">
                  {{ tag }}
                </el-tag>
              </td>
            </tr>
          </tbody>
        </table>
      </el-form-item>

      <!-- Form type === function -->
      <el-form-item v-if="entityForm.type === 'function'" label="Description" prop="function">
        <div style="font-weight: bold">
          <br />
          {{ entityForm.description }}
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  props: ["entityForm", "showDialog"],
  methods: {
    handleClose() {
      this.$emit("toggleDialogVisible", false);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/colors.scss";

.el-tag + .el-tag {
  margin-left: 10px;
}
.keyreply-hover-table.keyreply-hover-color tbody tr:hover {
  background-color: lighten($color-primary, 45);
  cursor: pointer;
}
</style>
