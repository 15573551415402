<template>
  <div
    style="
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      padding-top: 10px;
      padding-left: 10px;
    "
  >
    <FaqModelList />

    <el-button
      v-if="!groupByTopicIsEnabled"
      type="warning"
      :loading="getPublishingInProgress"
      :disabled="$apollo.queries.models.loading"
      size="small"
      plain
      style="margin-left: 5px"
      icon="el-icon-finished"
      @click="publishLatest"
    >
      Publish Latest Version
    </el-button>

    <el-popover style="line-height: initial" placement="top" width="275" trigger="hover">
      <el-button
        slot="reference"
        type="primary"
        size="small"
        style="margin-left: 5px"
        :icon="trainingIcon"
        plain
      >
        Train Bot
      </el-button>
      <el-row>
        <el-col>
          <span>
            Currently a total of
            <b>{{ totalVariations }}</b> variations
            <span v-if="totalVariations > 0">
              <br />Training may take <i>{{ getEstimatedTrainingTime }}</i> minutes
            </span>
            <span v-else> <br />Enable intents to start training </span>
          </span>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <table>
            <tr v-if="!groupByTopicIsEnabled">
              <td>
                <el-tooltip content="Train and create a new FAQ model" placement="left">
                  <el-button
                    type="primary"
                    style="width: 100%"
                    :loading="this.$store.state.faq.trainingInProgress"
                    :disabled="dataset.length === 0 || totalVariations === 0"
                    @click="train"
                  >
                    Train (~
                    {{ getLowerBoundTime(totalVariations / 60).toFixed(0) }}mins)
                  </el-button>
                </el-tooltip>
              </td>
            </tr>

            <tr v-if="groupByTopicIsEnabled">
              <td>
                <el-tooltip content="Train and create a new FAQ model by topic" placement="left">
                  <el-button
                    type="primary"
                    style="width: 100%"
                    :loading="this.$store.state.faq.trainingInProgress"
                    :disabled="dataset.length === 0 || totalVariations === 0"
                    @click="toggleSelectTrainTopicsDialogVisible(true)"
                  >
                    Train Multiple Topics (~
                    {{ getLowerBoundTime(totalVariations / 60).toFixed(0) }}mins)
                  </el-button>
                </el-tooltip>
              </td>
            </tr>

            <tr v-if="groupByTopicIsEnabled">
              <td>
                <el-tooltip content="Train and publish overall topic model" placement="left">
                  <el-button
                    v-if="groupByTopicIsEnabled && rasaEnabled"
                    :loading="
                      $store.state.faq.trainingInProgress || $store.state.faq.publishingInProgress
                    "
                    type="primary"
                    style="width: 100%"
                    @click="trainAndPublishTopicAsIntentsModel()"
                    >Train and Publish Overall Model</el-button
                  >
                </el-tooltip>
              </td>
            </tr>

            <tr>
              <td>
                <el-tooltip
                  content="Process all intents for visualization, click to update visualization after any change in dataset"
                  placement="left"
                >
                  <el-button
                    type="primary"
                    style="width: 100%"
                    plain
                    :loading="this.$store.state.processingVisualization"
                    @click="processVisualizationData"
                  >
                    Visualize (~
                    {{ getUpperBoundTime(totalVariations / 10).toFixed(0) }}seconds)
                  </el-button>
                </el-tooltip>
              </td>
            </tr>

            <tr v-if="!groupByTopicIsEnabled">
              <td>
                <el-tooltip content="Train and find all possible conflicts" placement="left">
                  <el-button
                    type="primary"
                    style="width: 100%"
                    plain
                    :loading="this.$store.state.faq.trainingInProgress"
                    :disabled="
                      dataset.length === 0 || totalVariations === 0 || totalCheckableIntents === 0
                    "
                    @click="trainAndDeconflict"
                  >
                    Check conflicts (~
                    {{ getUpperBoundTime(totalVariations / 60).toFixed(0) }}mins)
                    <p style="margin: 10px 0 0 0; font-size: 12px" v-if="pendingCheckConflict">
                      {{ currentIntentCheck }}/{{ totalCheckableIntents }} Intents
                    </p>
                  </el-button>
                </el-tooltip>
              </td>
            </tr>
          </table>
        </el-col>
      </el-row>
    </el-popover>
    <el-button
      type="success"
      size="small"
      :loading="saving"
      :disabled="dataset.length === 0"
      plain
      icon="el-icon-check"
      style="margin-left: 5px"
      @click="save"
    >
      Save changes
    </el-button>

    <el-progress
      v-if="training && initialCheckedProgress"
      :percentage="parseFloat(trainingProgress.toFixed(0))"
      color="rgb(64, 158, 255)"
    />
  </div>
</template>

<script>
import Vue from "vue";
import gql from "graphql-tag";
import moment from "moment";
import _ from "lodash";
import { mapGetters } from "vuex";
import { prepareDatasetToSave } from "@/helperMethods/faq/util";
import FaqModelList from "./FaqModelList";
import { VARIANTS_LIMIT } from "@/components/Faq/constant";

export default Vue.extend({
  props: [],
  data() {
    return {
      models: [],
      initialCheckedProgress: false,
      saving: false,
      progress: 0,
      training: false,
      latestModelId: "",
      currentIntentCheck: 0,
      pendingCheckConflict: false,
    };
  },
  computed: {
    ...mapGetters([
      "rasaEnabled",
      "elsaEnabled",
      "groupByTopicIsEnabled",
      "getPublishingInProgress",
    ]),
    dataset() {
      return this.$store.state.training.dataset;
    },
    currentModelTime() {
      let modelId;

      modelId = _.get(this.$store, "state.modules.faq.Rasa.modelId");
      if (modelId) {
        return moment(modelId + " +00:00", "YYYYMMDD-HHmmss Z").fromNow();
      } else {
        return "-";
      }
    },
    totalVariations() {
      const dataset = this.dataset;
      return _.chain(dataset)
        .filter((intent) => intent.enabled)
        .map((row) => row.variations)
        .flattenDeep()
        .value().length;
    },
    trainingIcon() {
      return this.$store.state.faq.trainingInProgress ? "el-icon-loading" : "el-icon-tickets";
    },
    getEstimatedTrainingTime() {
      const totalVariations = this.totalVariations;
      const timeInMinutes = totalVariations / 60; // every variatons = 1 sec, in minutues
      return this.formatTime(timeInMinutes);
    },
    totalCheckableIntents() {
      return this.filteredIntentsToCheckConflict?.length;
    },
    filteredIntentsToCheckConflict() {
      // Filter for intents that are enabled and have less than  variants limit number (2000)
      const data = _.get(this, "$store.state.training.dataset", [{ enabled: false }]).filter(
        (intent) => {
          const isIntentEnabled = intent.enabled; //Check if intent is enabled
          const variationsLengthWithinLimit = (intent.variations?.length || 0) <= VARIANTS_LIMIT; //Check if intent has less than 2000 variations
          return isIntentEnabled && variationsLengthWithinLimit;
        }
      );
      return data;
    },
  },
  components: {
    FaqModelList,
  },
  methods: {
    setTrainingInProgress(inProgress) {
      this.$store.commit("SET_TRAINING_IN_PROGRESS", inProgress);
    },

    setPublishingInProgress(inProgress) {
      this.$store.commit("SET_PUBLISHING_IN_PROGRESS", inProgress);
    },

    toggleSelectTrainTopicsDialogVisible(isVisible = true) {
      this.$store.commit("SET_TOPIC_DIALOG_VISIBLE", isVisible);
    },

    getLowerBoundTime(timeInMinutes) {
      return Math.max(timeInMinutes * 0.75, 1);
    },

    getUpperBoundTime(timeInMinutes) {
      return Math.max(timeInMinutes, 5);
    },

    formatTime(timeInMinutes) {
      // A rough range
      const lowerBound = this.getLowerBoundTime(timeInMinutes);

      // train + deconflicting can take a much longer time
      const upperBound = this.getUpperBoundTime(timeInMinutes);
      return `${lowerBound.toFixed(0)} ~ ${upperBound.toFixed(0)}`;
    },

    publishLatest() {
      const latestModel = _.first(this.models);
      const modelId = _.get(latestModel, "modelId");
      if (modelId) {
        this.publish(modelId);
      } else {
        this.$notify.error({
          title: "Publish Error",
          position: "bottom-right",
          message: "No trained model to publish",
        });
      }
    },

    async trainAndDeconflict() {
      this.$nextTick(() => {
        // Only test if training is okay.
        this.evaluateIntents();
      });
    },

    async evaluateIntents() {
      if (!this.pendingCheckConflict) this.pendingCheckConflict = true;

      const data = this.filteredIntentsToCheckConflict;
      this.setTrainingInProgress(true);
      let latestModelId = this.latestModelId;
      if (this.elsaEnabled) {
        const { publishedModelIds, modelNameToUUID } = _.get(this, "$store.state.modules.faq.Elsa");
        latestModelId = _.get(publishedModelIds, "general", "");
      }

      // Define the total number of variants limit
      // 1800 Variants take about 15mins to run so the limit here is 2000 for avoid timeout
      const totalVariantsLimit = VARIANTS_LIMIT;
      let intents = [];
      let totalIntentLength = 0;

      // Loop through the data array starting from the current intent check
      for (let i = this.currentIntentCheck; i < data.length; i++) {
        totalIntentLength += data[i].variations?.length;
        // Check if the total number of variants is less than the total variants limit
        if (totalIntentLength <= totalVariantsLimit) {
          intents.push(data[i]);
        } else {
          break;
        }
      }

      if (intents.length <= 0) return;

      this.$store
        .dispatch("FAQ_TEST_TRAINING_DATA", {
          intents: intents,
          latestModelId,
          isIgnoreFallbackOthersIntent: false,
        })
        .then((updatedDataset) => {
          if (!updatedDataset) return;

          this.currentIntentCheck += intents.length;
          this.$notify.success({
            title: "Evaluation Success",
            position: "bottom-right",
            message: `${this.currentIntentCheck}/${this.totalCheckableIntents} Intents successfully evaluated`,
          });
          updatedDataset = [];
          intents = [];
        })
        .catch((err) => {
          this.$notify.error({
            title: "Evaluation Failed",
            position: "bottom-right",
            message: `Encountered error testing faq`,
          });
        })
        .finally(() => {
          if (this.currentIntentCheck < data.length) return;

          this.setTrainingInProgress(false);
          this.currentIntentCheck = 0;
          this.pendingCheckConflict = false;
          return;
        });
    },

    async publish(modelId) {
      this.setPublishingInProgress(true);
      await this.$apollo.mutate({
        mutation: gql`
          mutation ($modelId: String!) {
            faqAPI {
              publish(modelId: $modelId)
            }
          }
        `,
        variables: {
          modelId,
        },
        update: (
          store,
          {
            data: {
              faqAPI: { publish },
            },
          }
        ) => {
          if (publish && publish.success) {
            this.$notify.success({
              title: "Success",
              message: "Model published",
              position: "bottom-right",
            });

            if (publish.Elsa) {
              const copyPublishedModelIds = _.cloneDeep(
                _.get(this, "$store.state.modules.faq.Elsa.publishedModelIds", {})
              );
              copyPublishedModelIds["general"] = modelId;

              this.$set(
                this.$store.state.modules.faq.Elsa,
                "publishedModelIds",
                copyPublishedModelIds
              );
            } else if (publish.Rasa) {
              this.$set(this.$store.state.modules.faq.Rasa, "modelId", modelId);
            }
          } else {
            this.$notify.error({
              title: "Error",
              message: "Failed to publish model",
              position: "bottom-right",
            });
          }
        },
      });
      this.setPublishingInProgress(false);
    },

    async save() {
      this.saving = true;
      const datasetToSave = prepareDatasetToSave(this.$store.state.training.dataset);
      if (_.isEmpty(datasetToSave)) {
        this.saving = false;
        this.$notify.warning({
          title: "Warning",
          message: "Failed to save FAQ dataset. Dataset is empty.",
          position: "bottom-right",
        });
        return;
      }
      const isSaved = await this.$apollo
        .mutate({
          mutation: gql`
            mutation ($dataset: JSON!) {
              faqAPI {
                saveDataset(dataset: $dataset)
              }
            }
          `,
          variables: {
            dataset: datasetToSave,
          },
        })
        .catch(() => {
          this.$notify.error({
            title: "Error",
            message: "Failed to save FAQ dataset.",
            position: "bottom-right",
          });
          return false;
        });
      const isSaveSuccess = isSaved?.data?.faqAPI?.saveDataset;
      if (!isSaveSuccess) {
        this.saving = false;
        this.$notify.warning({
          title: "Warning",
          message: "There is another save in action, please wait till it completed",
          position: "bottom-right",
        });
        return;
      }
      if (isSaved) {
        this.saving = false;
        this.$notify.success({
          title: "Success",
          message: "Saved FAQ Question",
          position: "bottom-right",
        });
      }
    },

    async train() {
      await this.save();
      this.setTrainingInProgress(true);
      await this.$apollo
        .mutate({
          mutation: gql`
            mutation {
              faqAPI {
                train
              }
            }
          `,
        })
        .then((returnResult) => {
          if (_.get(this, "$apollo.queries.models")) {
            this.$apollo.queries.models.refresh();
          }
          this.$notify.success({
            title: "Train Success",
            position: "bottom-right",
            message: `Model has been trained successfully`,
          });

          const modelId = _.get(returnResult, "data.faqAPI.train.Elsa", "");
          if (!modelId) return;

          this.setModelNameToUUID(modelId);
          this.setUUIDToModelName(modelId);
        })
        .catch((e) => {
          this.$notify.error({
            title: "Train Error",
            position: "bottom-right",
            message: "Training failed." + e.message,
          });
        })
        .finally(() => this.setTrainingInProgress(false));
    },

    async trainAllTopicsAsIntents() {
      this.setTrainingInProgress(true);
      await this.save();

      const result = await this.$store.dispatch("FAQ_TRAIN_ALL_TOPICS_AS_INTENTS").catch((e) => {
        this.$notify.error({
          title: "Train Error",
          position: "bottom-right",
          message: "Training failed.",
        });
      });

      if (_.get(this, "$apollo.queries.models")) {
        await this.$apollo.queries.models.refetch();
      }

      this.$notify.success({
        title: "Train Success",
        position: "bottom-right",
        message: `Model has been trained successfully`,
      });

      this.setTrainingInProgress(false);
    },

    async getLastTrainedTopicAsIntentsModel() {
      const topicAsIntentModels = _.filter(this.models, (model) => {
        const modelId = _.get(model, "modelId", "");
        return modelId.split("_")[0] === "model";
      });
      return _.get(_.first(topicAsIntentModels), "modelId", "");
    },

    async publishTopicAsIntentsModel(modelId) {
      this.setPublishingInProgress(true);
      await this.$apollo.mutate({
        mutation: gql`
          mutation ($modelId: String!) {
            faqAPI {
              publishTopicAsIntentsModel(modelId: $modelId)
            }
          }
        `,
        variables: {
          modelId,
        },
        update: (
          store,
          {
            data: {
              faqAPI: { publishTopicAsIntentsModel },
            },
          }
        ) => {
          if (publishTopicAsIntentsModel) {
            this.$notify.success({
              title: "Publish Success",
              position: "bottom-right",
              message: `Model published`,
            });

            const { Rasa } = _.get(this, "$store.state.modules.faq");
            this.$set(Rasa, "topicAsIntentsModelId", modelId);
          } else {
            this.$notify.error({
              title: "Publish Error",
              position: "bottom-right",
              message: `Model failed to publish`,
            });
          }
        },
      });
      this.setPublishingInProgress(false);
    },

    async trainAndPublishTopicAsIntentsModel() {
      await this.trainAllTopicsAsIntents();
      const modelId = await this.getLastTrainedTopicAsIntentsModel();
      await this.publishTopicAsIntentsModel(modelId);
    },

    setModelNameToUUID(modelId) {
      const copyModelNameToUUID = _.cloneDeep(
        _.get(this, "$store.state.modules.faq.Elsa.modelNameToUUID", {})
      );
      copyModelNameToUUID["general"] = modelId;

      this.$set(this.$store.state.modules.faq.Elsa, "modelNameToUUID", copyModelNameToUUID);
    },

    setUUIDToModelName(modelId) {
      const copyUUIDToModelName = _.cloneDeep(
        _.get(this, "$store.state.modules.faq.Elsa.UUIDToModelName", {})
      );
      copyUUIDToModelName[modelId] = "general";

      this.$set(this.$store.state.modules.faq.Elsa, "UUIDToModelName", copyUUIDToModelName);
    },

    async processVisualizationData() {
      await this.$store.dispatch("FETCH_VISUALIZATION_DATA");
    },
  },

  apollo: {
    trainingInProgress() {
      return {
        query: gql`
          query {
            faqAPI {
              trainingObj: getTrainingStatus
            }
          }
        `,
        update: (data) => {
          let training = _.get(data, "faqAPI.trainingObj.training", false);
          this.setTrainingInProgress(training);
          return training;
        },
        error(error) {
          this.$notify.error({
            title: "Train Error",
            position: "bottom-right",
            message:
              "An error has occurred while fetching training status, please refresh your page to try again!",
          });
        },
        subscribeToMore: {
          document: gql`
            subscription {
              trainingObj: classifierTrainingStatusChanged
            }
          `,
          updateQuery: (previousResult, { subscriptionData }) => {
            const trainingObj = _.get(subscriptionData, "data.trainingObj");
            const loggedInUserEmail = _.get(this, "$store.state.profile.email", "");
            const trainedBy = trainingObj.trainedBy;
            if (trainedBy && loggedInUserEmail !== trainedBy) {
              this.$notify.warning({
                title: "Info",
                message: `${trainedBy} has started a training session!`,
              });
            }
            return { faqAPI: { trainingObj } };
          },
        },
      };
    },

    models() {
      return {
        query: gql`
          query ($modelName: String) {
            faqAPI {
              models: getModels(modelName: $modelName)
            }
          }
        `,
        variables() {
          return {
            modelName: this.$store.state.faq.currentViewingTopic,
          };
        },
        fetchPolicy: "network-only",
        update: (data) => {
          let models = _.get(data, "faqAPI.models", []);
          return models;
        },
      };
    },
  },

  watch: {
    currentIntentCheck(value) {
      if (value != 0) {
        this.evaluateIntents();
      }
    },
  },
});
</script>

<style>
.el-notification__content > p {
  text-align: left !important;
}
</style>
