<template>
  <div>
    <input
      type="file"
      :id="`fileCert${name}`"
      style="display: none"
      accept="application/x-x509-ca-cert, .key, .pem"
      ref="fileCert"
      @change="uploadFileCert"
    />
    <el-input readonly v-model="value[name]" style="max-width: 720px; margin-bottom: 1em">
      <div v-if="mode !== 'form'" slot="prepend" style="min-width: 110px">
        {{ setting.label || name }}
        <Tooltip :label="setting.label || name" :content="setting.detail" />
      </div>
      <el-button
        @click="removeFile"
        type="medium"
        slot="append"
        icon="el-icon-error"
        :loading="uploading"
        v-if="value[name]"
      />
      <label :for="`fileCert${name}`" slot="append" v-else-if="!uploading">
        <i class="el-icon-upload2"></i>
      </label>
      <el-button :loading="true" slot="append" v-else></el-button>
    </el-input>
  </div>
</template>

<script>
import _ from "lodash";
import Tooltip from "../../Tooltip.vue";
export default {
  components: { Tooltip },
  props: ["value", "setting", "name", "mode", "modules"],
  data() {
    return {
      uploading: false,
    };
  },
  methods: {
    uploadFileCert(e) {
      const file = e.target.files[0];
      this.$confirm("Do you wanna upload this file?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "info",
      })
        .then(async () => {
          this.uploading = true;
          const form = new FormData();
          form.append("brain", this.$store.state.brain);
          form.append("name", "cert");
          form.append("certs", file);
          const result = await this.$rest("post", "miniapp_certs_upload", form);
          this.$set(this.value, this.name, file.name);
          this.$set(this.value, `${this.name}Data`, result[0]);
          this.uploading = false;
        })
        .catch(() => {
          this.uploading = false;
          this.$refs.fileCert.value = "";
          this.$message({
            type: "info",
            message: "Upload canceled",
          });
        });
    },
    removeFile() {
      this.$confirm("Do you wanna delete this file?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "info",
      })
        .then(async () => {
          this.uploading = true;
          const form = new FormData();
          form.append("filename", this.value[`${this.name}Data`]?.name);
          try {
            await this.$rest("post", "deleteFile", form);
            this.$set(this.value, this.name, "");
            this.$delete(this.value, `${this.name}Data`);
            if (this.$refs.fileCert?.value) {
              this.$refs.fileCert.value = "";
            }
            this.uploading = false;
          } catch (error) {
            this.uploading = false;
            this.$message({
              type: "error",
              message: "File is not exist, please update again!",
            });
            this.$set(this.value, this.name, "");
            this.$delete(this.value, `${this.name}Data`);
            if (this.$refs.fileCert?.value) {
              this.$refs.fileCert.value = "";
            }
          }
        })
        .catch(() => {
          this.uploading = false;
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
  },
  mounted() {},
  computed: {},
};
</script>
