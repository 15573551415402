import _ from "lodash";

export function validateFiles(
  files: { name: string; size: number }[]
): { isValid: boolean; errors: string[] } {
  let overallErrors: string[] = [];
  const validation: boolean[] = [];
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const { isValid, errors } = validateFile(file);
    if (errors.length > 0) {
      overallErrors = overallErrors.concat(errors);
    }
    validation.push(isValid);
  }

  const isValid = _.every(validation, (v) => !!v);

  return { isValid, errors: overallErrors };
}

export function validateFile(file: {
  name: string;
  size: number;
}): { isValid: boolean; errors: string[] } {
  const errors: string[] = [];
  const fileName = file.name;

  const whitelistedExtensionOnly = hasWhitelistedExtensions(fileName);

  if (!whitelistedExtensionOnly) {
    errors.push(
      `Invalid Action: '${fileName}' can’t be uploaded as the file type is not supported by the system.`
    );
  }

  const validFileSize = hasValidFileSize(file);
  if (!validFileSize) {
    errors.push(`Invalid Action: file size greater than 10mb`);
  }

  const isValid = whitelistedExtensionOnly && validFileSize;
  return { isValid, errors };
}

export function hasWhitelistedExtensions(fileName: string): boolean {
  const allowedFileExtensions = [
    ".xls",
    ".xlsx",
    ".doc",
    ".docx",
    ".dotx",
    ".jpeg",
    ".jpg",
    ".png",
    ".pdf",
    ".svg",
    ".gif",
    ".json",
    ".jfif",
  ];

  const fileExtRegex = new RegExp(
    "(" + allowedFileExtensions.join("|").replace(/\./g, "\\.") + ")$",
    "i"
  );
  const isAllowed = fileExtRegex.test(fileName);

  return isAllowed;
}

export function hasSingleExtension(fileName: string): boolean {
  const ext = fileName.split(".");
  const onlyOneExt = ext.length === 2;
  return onlyOneExt;
}

export function noSpecialCharacter(fileName: string): boolean {
  if (!fileName) {
    return true;
  }

  const regex = /[`~!@#$%^&*()|+=?;:'",<>\{\}\[\]\\\/]/gi;
  const haveSpecialCharacter = regex.test(fileName);

  return !haveSpecialCharacter;
}

export function hasValidFileSize(file: { size: number }, tenMbLimit = 10240): boolean {
  const fileSize = file.size;
  const fileSizeInKB = Math.round(fileSize / 1024);

  const fileLessThan10Mb = fileSizeInKB <= tenMbLimit;
  return fileLessThan10Mb;
}
