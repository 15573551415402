// @ts-nocheck
import _ from "lodash";
import ZendeskBaseObject from "../BaseObjects/Zendesk.json";

export default {
  computed: {
    zendeskFormMixin(): any {
      const zendeskObject = _.clone(ZendeskBaseObject);
      zendeskObject.chat.children.fallbackDepartment.options =
        (this.$store.state.modules.zendesk.departments &&
          this.$store.state.modules.zendesk.departments.map((department) => {
            return {
              label: department,
              value: department,
            };
          })) ||
        [];
      zendeskObject.chat.children.noAgentAvailableContent.suggestions = this.getContentNodeSuggestions;
      zendeskObject.chat.children.escalationContent.suggestions = this.getContentNodeSuggestions;
      zendeskObject.chat.children.highQueueVolumeEscalationContent.suggestions = this.getContentNodeSuggestions;
      zendeskObject.chat.children.accounts.children.account_one.children = this.createZendeskChatAccountSettingsObject(
        "account_one"
      );
      zendeskObject.chat.children.accounts.children.account_two.children = this.createZendeskChatAccountSettingsObject(
        "account_two"
      );
      zendeskObject.chat.children.accounts.children.account_three.children = this.createZendeskChatAccountSettingsObject(
        "account_three"
      );
      return zendeskObject;
    },
  },
  methods: {
    zendeskOauth(accountObj, accountNo) {
      console.log(accountObj);
      if (accountObj.redirectURI && accountObj.clientId && accountObj.subdomain) {
        window.open(
          `https://www.zopim.com/oauth2/authorizations/new?response_type=code&redirect_uri=${encodeURIComponent(
            accountObj.redirectURI
          )}&client_id=${
            accountObj.clientId
          }&scope=read%20write%20chat&state=${accountNo}&subdomain=${encodeURIComponent(
            accountObj.subdomain
          )}`,
          "_blank"
        );
      } else {
        this.$message({
          message: "Redirect URI, client ID, or subdomain not configured yet!",
          type: "error",
        });
      }
    },
    createZendeskChatAccountSettingsObject(accountNo: string): any {
      return {
        botEnabled: {
          default: true,
          type: "boolean",
          label: "Bot Enabled",
        },
        redirectURI: {
          type: "string",
          default: "",
          detail:
            "A non-relative URL used as an callback endpoint after authenticating the client (This same URL must appear on Zendesk Chat under API Clients, https://(subdomain).zendesk.com/chat/agent#account/api_&_sdks).",
        },
        clientId: {
          type: "string",
          detail: "https://(subdomain).zendesk.com/chat/agent#account/api_&_sdks > Add Client",
          default: "",
        },
        clientSecret: {
          type: "string",
          default: "true",
          detail: "https://(subdomain).zendesk.com/chat/agent#account/api_&_sdks > Add Client",
          required: true,
          protected: true,
        },
        subdomain: {
          type: "string",
          default: "d3v-keyreply",
          detail: "https://(subdomain).zendesk.com (Just the subdomain part)",
          required: true,
        },
        "Grant Type Authorization": {
          type: "button",
          buttonType: "danger",
          text: "Authorize Client",
          onClick: this.zendeskOauth.bind(
            this,
            _.get(this, `$store.state.modules.zendesk.chat.accounts.${accountNo}`),
            `${accountNo}`
          ),
          detail:
            "When you change the API Client settings in Zendesk Chat, reauthorization is needed to enable the bot again.",
        },
      };
    },
  },
};
