<template>
  <el-form :model="value.data">
    <el-form-item label="URL">
      <el-input
        v-model="value.data.url"
        style="width: 50%"
        placeholder="Please enter a URL to a csv file..."
      ></el-input>
      <el-tooltip
        class="item"
        v-if="!isFileURLValid"
        effect="dark"
        content="The URL you entered is not valid!"
        placement="right"
      >
        <i class="el-icon-warning has-text-primary" style="margin-left: 5px"></i>
      </el-tooltip>
    </el-form-item>
    <el-row>
      <el-form-item label="Condition Type">
        <el-radio-group v-model="value.data.conditionType">
          <el-radio-button label="and">AND</el-radio-button>
          <el-radio-button label="or">OR</el-radio-button>
        </el-radio-group>
        <el-tooltip
          class="item"
          v-if="!value.data.conditionType"
          effect="dark"
          content="Please choose one of the condition types"
          placement="right"
        >
          <i class="el-icon-warning has-text-primary" style="margin-left: 5px"></i>
        </el-tooltip>
      </el-form-item>
    </el-row>
    <el-row>
      <el-form-item class="search-conditions" label="Search Conditions">
        <ConditionEditor
          :conditions="value.data.searchConditions"
          :condition-property-options="[]"
        />
        <el-tooltip
          class="item"
          v-if="!value.data.searchConditions || value.data.searchConditions.length === 0"
          effect="dark"
          content="Please add one or more conditions"
          placement="right"
        >
          <i class="el-icon-warning has-text-primary" style="margin-left: 5px"></i>
        </el-tooltip>
      </el-form-item>
    </el-row>

    <el-form-item label="Fuzzy Search">
      <el-checkbox v-model="value.data.fuzzySearch"></el-checkbox>
    </el-form-item>
    <el-row v-if="value.data.fuzzySearch">
      <el-form-item label="Similarity Threshold">
        <el-input-number
          v-model="value.data.similarityThreshold"
          :min="0.01"
          :max="1"
          :step="0.05"
        ></el-input-number>
      </el-form-item>
    </el-row>
    <el-row>
      <el-form-item label="Enable Caching">
        <el-checkbox v-model="value.data.caching"></el-checkbox>
      </el-form-item>
      <el-form-item label="Cache Duration (minutes)" v-if="value.data.caching">
        <el-input-number
          v-model="value.data.cacheDuration"
          :precision="2"
          :step="1"
        ></el-input-number>
        <el-tooltip
          class="item"
          v-if="value.data.caching && !value.data.cacheDuration"
          effect="dark"
          content="Cache duration cannot be 0 or less"
          placement="right"
        >
          <i class="el-icon-warning has-text-primary" style="margin-left: 5px"></i>
        </el-tooltip>
      </el-form-item>
    </el-row>
    <el-row>
      <el-form-item class="search-conditions" label="Mappings">
        <div
          :key="index"
          v-for="(mapping, index) in value.data.mappings"
          class="mapping"
          style="display: flex"
        >
          <el-input v-model="mapping.column" placeholder="Column header"></el-input>
          <el-input
            v-model="mapping.stateKey"
            style="margin-left: 10px"
            placeholder="Identifier to store"
          ></el-input>
          <el-checkbox v-model="mapping.permanent" style="margin-left: 10px">Persist</el-checkbox>
          <el-button
            type="text"
            icon="el-icon-delete"
            size="mini"
            circle
            @click="value.data.mappings.splice(index, 1)"
          />
        </div>
        <el-button
          @click="addMapping"
          type="primary"
          style="margin-top: 10px"
          :disabled="value.data.mappings.length >= 10"
          >Add Mapping</el-button
        >
      </el-form-item>
    </el-row>
    <el-row>
      <el-form-item label="Result Type">
        <el-radio-group @change="add" v-model="value.data.resultType">
          <el-radio-button label="default">Default</el-radio-button>
          <el-radio-button label="gifimage">GIF/Image</el-radio-button>
          <el-radio-button label="card">Card</el-radio-button>
          <el-radio-button label="event">Event</el-radio-button>
        </el-radio-group>
        <el-tooltip
          class="item"
          v-if="!value.data.resultType"
          effect="dark"
          content="Please select a result type"
          placement="right"
        >
          <i class="el-icon-warning has-text-primary" style="margin-left: 5px"></i>
        </el-tooltip>
      </el-form-item>
    </el-row>
    <el-divider content-position="center">Result</el-divider>
    <el-row v-if="value.data.resultType === 'event'">
      <EventNodeSelector v-model="value.data.result" />
    </el-row>
    <el-row v-else>
      <el-row v-if="value.data.resultType === 'card'">
        <el-button type="primary" @click="add('card')">+ Add Card</el-button>
      </el-row>
      <el-row>
        <ContentNode :node="value.data.result"></ContentNode>
      </el-row>
    </el-row>
    <el-divider content-position="center">Fallback</el-divider>
    <el-row>
      <EventNodeSelector v-model="value.data.fallback" />
    </el-row>
  </el-form>
</template>
<script>
import ContentNode from "@/components/ContentNode";
import ConditionEditor from "@/components/ConditionEditor";
import { getDefaultPlaceholderImage } from "@/helperMethods/util";

export default {
  name: "GlossarySearch",
  components: {
    ContentNode,
    ConditionEditor,
    EventNodeSelector: () => import("@/components/EventNodeSelector/Index"),
  },
  data() {
    return {};
  },
  props: ["value"],
  computed: {
    isFileURLValid() {
      const isValidUrlPattern =
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(
          this.value.data.url
        );
      if (!isValidUrlPattern) return false;
      return true;
    },
  },
  methods: {
    add(command) {
      // console.log("Extensible " + Object.isExtensible(this.node));
      if (command === "default") {
        const textContent = {
          content: {
            text: "Text",
            buttons: [],
          },
          quickReplies: [],
        };
        this.$set(this.value.data, "result", textContent);
      } else if (command === "card") {
        this.value.data.result.type = "card";
        if (!this.value.data.result.content) {
          const defaultCard = [
            {
              image: getDefaultPlaceholderImage(),
              text: "",
              subtext: "",
              buttons: [],
            },
          ];
          this.$set(this.value.data.result, "content", defaultCard);
        } else if (Array.isArray(this.value.data.result.content)) {
          const cardEntry = {
            image: getDefaultPlaceholderImage(),
            text: "",
            subtext: "",
            buttons: [],
          };
          this.value.data.result.content.push(cardEntry);
        } else {
          if (Array.isArray(this.value.data.result.content.text)) {
            let replaceText = "";
            this.value.data.result.content.text.forEach((text, index) => {
              if (index === 0) {
                replaceText += text;
              } else {
                replaceText += `\n${text}`;
              }
            });
            this.value.data.result.content.text = replaceText;
          }
          const card = [
            {
              image: getDefaultPlaceholderImage(),
              text: "",
              subtext: "",
              buttons: [],
              ...this.value.data.result.content,
            },
            {
              image: getDefaultPlaceholderImage(),
              text: "",
              subtext: "",
              buttons: [],
            },
          ];
          this.value.data.result.content = card;
        }
      } else if (command === "gifimage") {
        const imageOrGif = {
          image: getDefaultPlaceholderImage(),
        };
        this.$set(this.value.data.result, "content", imageOrGif);
      } else if (command === "event") {
        const defaultEvent = {
          event: null,
          data: null,
        };
        this.$set(this.value.data, "result", defaultEvent);
      }
    },
    addMapping() {
      this.value.data.mappings.push({
        column: "",
        stateKey: "",
        permanent: false,
      });
    },
  },
};
</script>
<style scoped>
.mapping ~ .mapping {
  margin-top: 10px;
}
>>> .conditions-input-wrapper {
  max-width: 400px;
}

>>> div.el-form-item.search-conditions div.el-form-item__content {
  display: inline-block;
}

>>> div.conditions-input-wrapper > div > div {
  width: unset;
}
</style>
