<template>
  <el-form>
    <el-form-item style="margin-bottom: 20px">
      <span style="margin-right: 10px">Choose Product Type</span>
      <el-select @change="toComponent" v-model="node.content.options.msg_type" placeholder="Select">
        <el-option
          v-for="(item, index) in message_type"
          :key="index"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <WhatsAppLocationRequest v-if="selectedType === 'whatsapp_location_request'" :node="node" />
    <WhatsAppLocationShare v-if="selectedType === 'whatsapp_location_share'" :node="node" />
  </el-form>
</template>

<script>
import WhatsAppLocationRequest from "@/components/WhatsAppLocationRequest";
import WhatsAppLocationShare from "@/components/WhatsAppLocationShare";

export default {
  name: "WhatsAppLocationMessage",
  components: {
    WhatsAppLocationRequest,
    WhatsAppLocationShare,
  },
  props: ["node"],
  data() {
    return {
      message_type: [
        {
          label: "Location Request",
          value: "whatsapp_location_request",
        },
        {
          label: "Share Location",
          value: "whatsapp_location_share",
        },
      ],
    };
  },
  computed: {
    selectedType() {
      return this.node.content.options.msg_type;
    },
  },
  methods: {
    toComponent() {
      if (this.selectedType === "whatsapp_location_request") {
        this.node.content = {
          ...this.node.content,
          options: {
            msg_type: "whatsapp_location_request",
          },
        };
      } else if (this.selectedType === "whatsapp_location_share") {
        this.node.content = {
          ...this.node.content,
          options: {
            msg_type: "whatsapp_location_share",
            latitude: "",
            longitude: "",
            name: "",
            address: "",
          },
        };
      }
    },
  },
};
</script>

<style></style>
