<template>
  <el-row v-loading="isLoading">
    <div class="import-file-contacts">
      <div class="import-file-contacts-download">
        <el-button size="mini" type="text" @click="downloadTemplate" class="download-template">
          Download CSV Template
        </el-button>
      </div>
      <div class="import-file-contacts-upload">
        <el-row :gutter="10">
          <el-col>
            <el-upload
              drag
              class="upload-demo"
              action="#"
              :before-upload="beforeUpload"
              :on-success="handleSuccess"
              :on-remove="handleRemove"
              :on-change="handleChange"
              :file-list="fileList"
              :disabled="isDisabled"
            >
              <div class="el-upload__text file-upload-inner-text">
                <div v-if="fileList[0]" class="upload-file-contacts-result">
                  <div class="upload-file-contacts-result-container">
                    <img :src="UploadSuccessIcon" alt="" v-if="checkSuccessUploadFile()" />
                    <img :src="UploadErrorIcon" alt="" v-else />
                    <div class="upload-file-contacts-result-container-name">
                      <p>
                        {{ fileList[0].name }}
                      </p>
                      <el-button type="text" @click="handleRemove">
                        <i class="el-icon-close"></i>
                      </el-button>
                    </div>
                    <p v-if="!checkSuccessUploadFile()" style="color: red; margin: 0">Error</p>
                  </div>
                </div>
                <div v-else class="upload-file-contacts-drag">
                  <div class="upload-file-contacts-drag-upload">
                    <img :src="FileUploadIcon" alt="" />
                    <p style="color: black; margin: 0">Drag or select file</p>
                  </div>
                  <div class="upload-file-contacts-drag-button">
                    <button>Choose File</button>
                    <p>(.CSV)</p>
                  </div>
                </div>
              </div>
            </el-upload>
          </el-col>
        </el-row>
      </div>
      <div class="import-file-contacts-notes">
        <div class="button-import">
          <el-button
            size="small"
            @click="submitUpload"
            plain
            type="primary"
            v-show="checkSuccessUploadFile()"
            >Import</el-button
          >
        </div>
        <div class="import-file-contacts-notes-title" v-show="!checkSuccessUploadFile()">
          <img :src="InstructionsIcon" alt="" />
          <p>Instructions</p>
        </div>
        <div
          v-for="(note, i) in notes"
          :key="i"
          class="import-file-contacts-notes-note"
          v-show="!checkSuccessUploadFile()"
        >
          <img :src="DotIcon" alt="" />
          <p>{{ note }}</p>
        </div>
      </div>
    </div>
  </el-row>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { OUT_REACH_IMPORT_NOTES } from "@/helperMethods/outreach";
import { ATTRIBUTE_TYPE, OPTION_CHECKBOX } from "@/helperMethods/outreach/attribute";
import XLSX from "xlsx";
import UploadSuccessIcon from "../assets/upload_success_icon.svg";
import UploadErrorIcon from "../assets/upload_error_icon.svg";
import FileUploadIcon from "../assets/file_upload_icon.svg";
import InstructionsIcon from "../assets/instructions_icon.svg";
import DotIcon from "../assets/dot_icon.svg";
const CONTACTS_IMPORT = require("@/graphql/OutReachContactImport.graphql");

export default Vue.extend({
  props: {
    dialogTableVisible: Boolean,
  },
  data() {
    return {
      fileList: [],
      uploadedContactList: [],
      err: undefined,
      notes: OUT_REACH_IMPORT_NOTES,
      isDisabled: false,
      // icon
      UploadSuccessIcon,
      UploadErrorIcon,
      FileUploadIcon,
      InstructionsIcon,
      DotIcon,
    };
  },
  computed: {
    ...mapGetters(["contactAttributes", "contactsTags", "contactsStages"]),
    isLoading() {
      return this.$store.state.outreach.isLoading;
    },
    contactAttributeName() {
      return this.contactAttributes.map((attribute) => attribute.name);
    },
  },
  methods: {
    checkSuccessUploadFile() {
      return this.fileList[0] && this.fileList[0].status !== "error" && !this.err;
    },
    beforeUpload(file) {
      const isCSV = file.type === "text/csv" || file.name.endsWith(".csv");
      if (isCSV) {
        this.handleSuccess("", file);
      } else {
        this.$message.error("Uploaded file must be in CSV format!");
      }
      return isCSV;
    },
    handleError(err) {
      console.log(err);
      this.err = err;
      console.log("An error occurred while importing file!");
    },

    handleRemove() {
      this.fileList = [];
      this.uploadedContactList = [];
      this.isDisabled = true;
      setTimeout(() => {
        this.isDisabled = false;
      }, 1000);
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-1);
    },
    handleSuccess(res, file) {
      this.uploadedContactList = [];

      const reader = new FileReader();
      reader.onload = ((vuecomponent) => {
        return (e) => {
          const data = e.target.result;
          const wb = XLSX.read(data, {
            type: "binary",
            raw: true,
          });
          const firstSheetName = wb.SheetNames[0];
          const firstSheet = wb.Sheets[firstSheetName];
          const uploadedData = XLSX.utils.sheet_to_json(firstSheet);
          console.log(uploadedData);
          this.err = false;
          uploadedData.some((rawRow) => {
            const row = vuecomponent.transformObjectToLowerCase(rawRow, [
              "firstName",
              "lastName",
              "channel",
              "primaryContact",
              "tags",
              "stage",
              "country",
              ...this.contactAttributeName,
            ]);
            const {
              firstName = "",
              lastName = "",
              channel = "",
              primaryContact = "",
              tags = "",
              stage = "",
              country = "",
            } = row;
            if (!firstName || !lastName || !primaryContact) {
              this.$message.error("Please fill all First name, Last name and Primary contact");
              this.err = true;
              return false;
            }
            this.uploadedContactList.push({
              ...row,
              firstName,
              lastName,
              channel,
              primaryContact,
              tags,
              stage,
              country,
            });
          });
        };
      })(this);
      reader.readAsText(file);
      this.fileList = [...this.fileList, file];
    },
    downloadTemplate() {
      const csvHeader = [
        "First Name",
        "Last Name",
        "Channel",
        "Primary Contact",
        "Tags",
        "Stage",
        "Country",
        ...this.contactAttributeName,
      ];
      const csvBody = [
        "Joe",
        "Doe",
        "WhatsApp",
        "213738347",
        this.contactsTags.data[0]?.name || "",
        this.contactsStages.data[0]?.name || "",
        "Singapore",
        ...this.contactAttributes.map((attribute) => {
          if (attribute?.type === ATTRIBUTE_TYPE.DROPDOWN) {
            return attribute.value[0] || "";
          }
          if (attribute?.type === ATTRIBUTE_TYPE.CHECKBOX) {
            return OPTION_CHECKBOX.FALSE;
          }
          return attribute?.label || "";
        }),
      ];
      const csvData = [csvHeader, csvBody];
      const escapedCSVData = csvData.map((arr) => arr.map((e) => `"${e}"`));
      const escapedCsvString = escapedCSVData.map((e) => e.join(",")).join("\n");

      const UTF_8_BOM = "%EF%BB%BF";
      const data = `data:text/csv;charset=utf-8,${UTF_8_BOM}${encodeURIComponent(
        escapedCsvString
      )}`;
      const a = document.createElement("a");
      a.download = "contact_import_template.csv";
      a.href = data;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      // const link = document.createElement("a");
      // link.download = "contact_import_template.csv";
      // link.href = "/template/contact_import_template.csv";
      // link.click();
    },
    submitUpload() {
      this.$store.commit("SET_OUTREACH_LOADING", true);
      const self = this;
      const dataContacts = [...this.uploadedContactList];
      this.$apollo
        .mutate({
          mutation: CONTACTS_IMPORT,
          variables: {
            contacts: dataContacts,
          },
          update(store, { data: { outReachContactAPI } }) {
            self.$notify.success({
              title: "Success",
              position: "bottom-right",
              message: `Successfully imported the contacts.`,
            });
            self.$store.dispatch("FETCH_OUTREACH_SEGMENTS");
            self.$emit("close-dialog", true);
            self.$parent.$emit("refetch-data", true);
          },
        })
        .catch((err) => {
          self.fileList = [];
          self.uploadedContactList = [];
          self.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `Failed to import contact file!`,
          });
          self.$store.commit("SET_OUTREACH_LOADING", false);
        });
    },
    transformObjectToLowerCase(obj, exclude = []) {
      return _.transform(obj, (result, value = "", _key) => {
        const key = _key
          .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
          })
          .replace(/\s+/g, "");
        result[key] = exclude.includes(key) ? value : value.toLowerCase();
      });
    },
  },
  watch: {
    dialogTableVisible: {
      handler() {
        this.handleRemove();
      },
    },
  },
});
</script>

<style lang="scss">
$color-drag-file: #1d57d8;
$button-bg-hover: #e8eefb;

.import-file-contacts {
  button {
    cursor: pointer;
  }
  color: #409eff !important;

  &-download {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 20px;
    .download-template {
      border: none !important;
      &:hover {
        background: none !important;
      }
      > span {
        font-weight: 400 !important;
        font-size: 8px !important;
      }
    }
  }

  .file-upload-inner-text {
    height: 200px;
    .upload-file-contacts-drag {
      display: flex !important;
      flex-direction: column !important;
      justify-content: space-between !important;
      height: 100%;
      &-upload {
        > p {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 18px;
          color: #000000;
        }
      }
      &-button {
        > p {
          font-weight: 400;
          font-size: 10px;
          line-height: 11px;
          color: #000000;
          margin: 7 0 0 0;
        }
      }
    }
    color: $color-drag-file !important;
    button {
      background: none !important;
      border: 1px solid $color-drag-file;
      border-radius: 4px;
      padding: 7px 10px;
      color: $color-drag-file !important;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }

    .upload-file-contacts-result {
      &-container {
        margin: auto;
        > i {
          font-size: 30px;
        }
        &-name {
          display: flex;
          color: black;
          > p {
            font-size: 15px;
            font-weight: 500 !important;
          }
          i {
            color: black;
          }
          > button {
            border: none;
            width: fit-content;
            height: fit-content;
          }
        }
      }
    }
  }
  .import-file-contacts-upload {
    .el-upload-dragger {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 40px;
      background: #e3ebfa;
      width: 100%;
      border: 1px dashed $color-drag-file !important;
      margin: 20px 0;
    }
    .el-upload-list {
      display: none !important;
    }
  }
  .import-file-contacts-notes {
    .button-import {
      position: absolute;
      bottom: 0;
      right: 0;
      > button {
        border: 1px solid $color-drag-file;
        color: $color-drag-file;
        &:hover {
          background: $button-bg-hover !important;
          border-color: $color-drag-file !important;
        }
      }
    }
    height: 150px;
    p {
      margin: 0;
      font-style: normal;
      color: #000000;
    }
    > div {
      display: flex;
    }
    &-title {
      > img {
        margin-right: 9px;
      }
      > p {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
      }
      margin-bottom: 13px;
    }
    &-note {
      padding: 0 5px;
      > img {
        margin-right: 13px;
      }
      > p {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        /* or 164% */
      }
    }
  }
}
</style>
