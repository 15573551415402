import { ContentNodeType } from "@/store/types";
import _ from "lodash";

export default function (contentNode: ContentNodeType): any {
  const extract = (node: any) => {
    let text = "";
    if (node) {
      if (typeof node.text === "string") {
        text = node.text;
      } else if (Array.isArray(node.text)) {
        text = _.sample(node.text);
      } else if (Array.isArray(node)) {
        text = extract(_.sample(node));
      } else if (node.content) {
        text = extract(node.content);
      }
    }

    return text;
  };

  return extract(contentNode);
}
