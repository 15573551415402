import LocalStorageManager from "@/localStorageManager";
import _ from "lodash";

// copied from livechat action -> FETCH_ALL_QUEUE_CHATS
// @todo: remove unnecessary steps
export const parseSessions = ({
  data,
  selectedChatId,
  queueChatObject,
  transferRedactedIdInteractions,
  transferRedactedId,
}) => {
  const clonedSession = _.cloneDeep(data);
  const selectedChat = queueChatObject[selectedChatId];

  const updatedSessions = _.chain(clonedSession)
    .map((chat, _index) => {
      if (selectedChat && chat.RowKey === selectedChatId) {
        chat.interactions = [...selectedChat.interactions, ...chat.interactions];
      }

      if (
        transferRedactedIdInteractions &&
        transferRedactedIdInteractions.length &&
        transferRedactedId !== null &&
        transferRedactedId === chat.RowKey
      ) {
        chat.interactions = transferRedactedIdInteractions;
      }
      const unreadMessageList = LocalStorageManager.getUnreadMessageList();

      const targetChat = _.find(unreadMessageList, {
        chatId: chat.RowKey,
      });
      let unreadMessage = 0;
      if (targetChat) {
        unreadMessage = targetChat.unreadMessage;
      }

      // FIXME: additional values for chat
      // TODO: move them into local vuex store
      chat.reply = "";
      chat.typingIndicatorStatus = false;
      chat.unreadMessage = unreadMessage;
      return chat;
    })
    .sortBy(
      // TODO: REMOVE
      // custom sort for parkway queue
      (chat) => {
        return !chat.tags.join("").includes("appointment");
      }
    )
    .value();

  return updatedSessions;
};
