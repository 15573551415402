import { MutationTree } from "vuex/types";
import { DepartmentState } from "./types";
import Vue from "vue";
import _ from "lodash";

const mutations: MutationTree<DepartmentState> = {
  SET_DEPARTMENTS(state, payload) {
    state.departments = payload;
  },
  SET_CREATE_LOADING(state, isLoading) {
    Vue.set(state, "createLoading", isLoading);
  },
  DEPARTMENT_DELETE(state, payload) {
    const departments = _.cloneDeep(state.departments);
    const deleted = departments.findIndex((department) => department.id === payload);
    departments.splice(deleted, 1);
    Vue.set(state, "departments", departments);
  },
  DEPARTMENT_ADD(state, payload) {
    const departments = _.cloneDeep(state.departments);
    departments.push(payload);
    Vue.set(state, "departments", departments);
  },
  DEPARTMENT_UPDATE(state, payload) {
    const departments = _.cloneDeep(state.departments);
    const updatedIndex = departments.findIndex((item) => item.id === payload.id);
    if (updatedIndex > -1) {
      departments.splice(updatedIndex, 1, payload);
      Vue.set(state, "departments", departments);
    }
  },
};

export default mutations;
