import { MutationTree } from "vuex/types";
import Vue from "vue";
import { WhatsAppState } from "./types";

const mutations: MutationTree<WhatsAppState> = {
  SET_WHATSAPP_MESSAGE_TEMPLATES(state, payload) {
    state.templates = payload;
  },
  SET_WHATSAPP_APPLICATION_SETTINGS(state, payload) {
    state.application_settings = payload;
  },
  SET_WHATSAPP_PROFILE_SETTINGS(state, payload) {
    state.profile_settings = payload;
  },
  SET_WHATSAPP_SERVER_STATUS(state, payload) {
    state.server_status = payload;
  },
  SET_WHATSAPP_GROUPS(state, payload) {
    state.groups = payload;
  },
  SET_WHATSAPP_SERVERS(state, payload) {
    Vue.set(state, "servers", payload);
  },
};

export default mutations;
