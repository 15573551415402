<template>
  <el-card>
    <h3 slot="header">Entrypoint ID Mapping</h3>
    <div>
      <el-card style="margin-bottom: 20px" v-for="(mapping, index) in mappings" :key="index">
        <el-form :model="mapping">
          <el-form-item label="Webchat Url">
            <el-input
              type="text"
              size="mini"
              v-model="mapping.url"
              placeholder="Webchat Url"
            ></el-input>
          </el-form-item>
          <el-form-item label="Entrypoint Id">
            <el-input
              type="number"
              size="mini"
              v-model="mapping.entrypointId"
              placeholder="Entrypoint Id"
            ></el-input>
          </el-form-item>
          <el-form-item label="CSAT survey question">
            <el-input
              type="text"
              size="mini"
              v-model="mapping.csat"
              placeholder="CSAT survey question"
            ></el-input>
          </el-form-item>
          <el-form-item label="Match 'Webchat URL' with">
            <el-select v-model="mapping.type" placeholder="Source type">
              <el-option
                v-for="item in mappingTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="mini" @click="onDeleteMapping(index)"
              ><i class="el-icon-delete"></i
            ></el-button>
          </el-form-item>
        </el-form>
      </el-card>

      <el-card>
        <h4>New Entrypoint Id mapping</h4>
        <el-form :model="newMapping">
          <el-form-item label="Webchat Url">
            <el-input
              type="text"
              size="mini"
              v-model="newMapping.url"
              placeholder="Webchat Url"
            ></el-input>
          </el-form-item>
          <el-form-item label="Entrypoint Id">
            <el-input
              type="number"
              size="mini"
              v-model="newMapping.entrypointId"
              placeholder="Entrypoint Id"
            ></el-input>
          </el-form-item>
          <el-form-item label="CSAT survey question">
            <el-input
              type="text"
              size="mini"
              v-model="newMapping.csat"
              placeholder="CSAT survey question"
            ></el-input>
          </el-form-item>
          <el-form-item label="Match 'Webchat URL' with">
            <el-select v-model="newMapping.type" placeholder="Source type">
              <el-option
                v-for="item in mappingTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="mini" @click="onSubmitNewMapping">Add</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "CiscoEntrypointEditor",
  props: ["setting", "value", "modules"],
  data() {
    return {
      newMapping: {
        url: "",
        entrypointId: null,
        type: "referer",
      },
      mappingTypeOptions: [
        {
          value: "referer",
          label: "user referer (headers.referer)",
        },
        {
          value: "location",
          label: "user page url (location.href)",
        },
        {
          value: "hostname",
          label: "user page hostname (location.hostname)",
        },
      ],
    };
  },
  computed: {
    mappings() {
      return this.value.ENTRYPOINT_MAPPING || [];
    },
  },
  methods: {
    onSubmitNewMapping() {
      this.value.ENTRYPOINT_MAPPING.push(this.newMapping);
      this.newMapping = {
        url: "",
        entrypointId: null,
        type: "referer",
      };
    },
    onDeleteMapping(index) {
      this.value.ENTRYPOINT_MAPPING.splice(index, 1);
    },
  },
  beforeMount() {
    if (!this.value.ENTRYPOINT_MAPPING || !Array.isArray(this.value.ENTRYPOINT_MAPPING)) {
      this.$set(this.value, "ENTRYPOINT_MAPPING", []);
    }
  },
};
</script>
