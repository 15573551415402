<template>
  <el-table
    :data="batchTestResult"
    :row-class-name="correctIntent"
    size="mini"
    style="width: 100%; min-height: 300px"
  >
    <el-table-column prop="department" label="Department" min-width="130" />
    <el-table-column prop="appSource" label="App Source" min-width="100" />
    <el-table-column prop="role" label="Role" min-width="90" />
    <el-table-column prop="utterance" label="Utterance" min-width="130" />
    <el-table-column prop="expectedIntent" label="Expected Intent" min-width="170" />
    <el-table-column label="Intent 1" min-width="170">
      <template slot-scope="scope">{{ getFirstIntent(scope.row) }}</template>
    </el-table-column>
    <el-table-column label="Intent 2" min-width="170">
      <template slot-scope="scope">{{ getSecondIntent(scope.row) }}</template>
    </el-table-column>
    <el-table-column prop="formattedEntities" label="Entities" min-width="170" />
    <el-table-column
      v-if="groupByTopicIsEnabled"
      prop="expectedTopic"
      label="Expected Topic"
      min-width="130"
    />
  </el-table>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  props: ["batchTestResult"],
  computed: {
    ...mapGetters(["groupByTopicIsEnabled"]),
  },
  methods: {
    getFirstIntent(row) {
      return _.get(row, "intents[0].intent") || _.get(row, "intents[0].name", "");
    },
    getSecondIntent(row) {
      return _.get(row, "intents[0].intent") || _.get(row, "intents[1].name", "");
    },
    correctIntent({ row, column, rowIndex, columnIndex }) {
      const actualIntent = _.get(row, "intent1.intent") || _.get(row, "intents[0].name", "");
      if (row.expectedIntent.toLowerCase().trim() === actualIntent.toLowerCase().trim()) {
        return "success-row";
      }
      return "warning-row";
    },
  },
};
</script>
<style scoped>
.el-table .warning-row {
  background: #fef0f0;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
