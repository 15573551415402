<template>
  <table class="result">
    <tbody>
      <tr v-for="(value, key) of result" :key="key">
        <td>{{ key }}</td>
        <td><form-result-value-field :value="value" /></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import FormResultValueField from "./FormResultValueField.vue";

export default {
  name: "FormResultItem",
  components: {
    FormResultValueField,
  },
  props: {
    result: Object,
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .result {
    td {
      padding: 2px !important;
    }
  }
}
</style>
