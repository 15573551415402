export default function (interim: any, complete: any) {
  const recognition = new (window.SpeechRecognition ||
    window.webkitSpeechRecognition ||
    window.mozSpeechRecognition ||
    window.msSpeechRecognition)();
  recognition.lang = "en-US";
  recognition.interimResults = true;
  recognition.maxAlternatives = 0;

  let final_transcript = "";
  recognition.onresult = function (event: any) {
    let interim_transcript = "";
    if (typeof event.results == "undefined") {
      recognition.onend = null;
      recognition.stop();
      return;
    }

    for (let i = event.resultIndex; i < event.results.length; ++i) {
      if (event.results[i].isFinal) {
        final_transcript += event.results[i][0].transcript;
      } else {
        interim_transcript += event.results[i][0].transcript;
      }
    }

    interim(interim_transcript);

    if (final_transcript) {
      complete(final_transcript);
      final_transcript = "";
      interim_transcript = "";
    }
  };

  return recognition;
}
