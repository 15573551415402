<template>
  <el-form :model="activeFlow" label-width="200px">
    <!-- Before flow start actions -->
    <el-divider>Before Flow Start Actions</el-divider>
    <el-form-item>
      <label slot="label" style="text-align: left">Event Selector</label>
      <EventNodeSelector :value="beforeStartAction" :node-type="'flow'"/>
    </el-form-item>
    <el-form-item>
      <label slot="label" style="text-align: left">
        Auto Escalation
        <el-tooltip
          effect="dark"
          content="User will be escalated to Livechat once entering workflow. Will escalate via email outside of office hours."
          placement="right"
        >
          <i class="has-text-grey el-icon-question" style="margin-left: 0.5em" />
        </el-tooltip>
      </label>
      <el-checkbox size="mini" v-model="activeFlow.autoEscalation" label="Enable" border />
    </el-form-item>

    <br />
    <br />

    <el-divider>General Flow Settings</el-divider>
    <el-form-item>
      <label slot="label" style="text-align: left">
        Allow link to other flows
        <el-tooltip
          effect="dark"
          content="Flow builders will be allowed to access another flow from this current flow. Not recommended unless necessary."
          placement="right"
        >
          <i class="has-text-grey el-icon-question" style="margin-left: 0.5em" />
        </el-tooltip>
      </label>
      <el-checkbox size="mini" v-model="activeFlow.isLinkingToOtherFlows" label="Enable" border />
    </el-form-item>

    <el-form-item>
      <label slot="label" style="text-align: left">
        Cancel keywords
        <el-tooltip
          effect="dark"
          content="Keywords to cancel workflow; Can be configured in Flow Maker Settings."
          placement="right"
        >
          <i class="has-text-grey el-icon-question" style="margin-left: 0.5em" />
        </el-tooltip>
      </label>
      <el-select
        style="width: 100%"
        v-model="activeFlow.cancelKeywords"
        multiple
        filterable
        size="mini"
        placeholder="Keywords to cancel"
      >
        <el-option
          v-for="(value, index) in cancelOptions"
          :key="index"
          :label="value"
          :value="value"
        />
      </el-select>
    </el-form-item>

    <el-form-item>
      <label slot="label" style="text-align: left">
        Cancel Flow Content
        <el-tooltip
          effect="dark"
          content="Content to show user when flow is cancelled. Either existing content node OR a text"
          placement="right"
        >
          <i class="has-text-grey el-icon-question" style="margin-left: 0.5em" />
        </el-tooltip>
      </label>

      <el-input
        v-model="activeFlow.cancelContent.textOrContentNodeID"
        type="textarea"
        size="mini"
        :autosize="{ minRows: 3, maxRows: 6 }"
        placeholder="Cancel flow text"
      />
    </el-form-item>

    <el-form-item>
      <label slot="label" style="text-align: left">
        Reset Destination
        <el-tooltip effect="dark" :content="resetDescription" placement="right">
          <i class="has-text-grey el-icon-question" style="margin-left: 0.5em" />
        </el-tooltip>
      </label>
      <el-select size="mini" v-model="activeFlow.resetTo" placeholder="Where to reset to?">
        <el-option
          v-for="(option, index) in resetOptions"
          :key="index"
          :label="option.label"
          :value="option.value"
        />
      </el-select>
    </el-form-item>
    <el-form-item>
      <label slot="label" style="text-align: left">
        Fallback keywords
        <el-tooltip
          effect="dark"
          content="Keywords you wish to add, separate it with comma. Eg. 'Help, pain, I need assistance'"
          placement="right"
        >
          <i class="has-text-grey el-icon-question" style="margin-left: 0.5em" />
        </el-tooltip>
      </label>
      <FallbackKeywords :active-flow="activeFlow" />
    </el-form-item>
  </el-form>
</template>

<script>
import EventNodeSelector from "@/components/EventNodeSelector/Index";
import FallbackKeywords from "./FallbackKeywords";

export default {
  props: ["activeFlow", "allFlowsNameArray", "cancelOptions", "resetDescription", "resetOptions"],
  components: {
    EventNodeSelector,
    FallbackKeywords,
  },
  computed: {
    beforeStartAction: {
      get() {
        if (!this.activeFlow.beforeStartAction) {
          this.$set(this.activeFlow, "beforeStartAction", {
            event: "",
            data: {},
          });
        }
        return this.activeFlow.beforeStartAction;
      },
      set(value) {
        this.activeFlow.beforeStartAction = value;
      },
    },
  },
};
</script>
