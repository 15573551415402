import { graph } from "@/store/api";
import { RootState } from "@/store/types";
import { ActionTree } from "vuex/types";
import { MakerCheckerState } from "./types";
import { Notification } from "element-ui";
import gql from "graphql-tag";
import _ from "lodash";
import moment from "moment-timezone";

const actions: ActionTree<MakerCheckerState, RootState> = {
  FETCH_CHANGES: ({ commit }, { status, offset }) => {
    return graph
      .query({
        query: gql`
          query($status: String!, $offset: Int) {
            makerCheckerAPI {
              fetchChanges(status: $status, offset: $offset)
            }
          }
        `,
        variables: { status, offset },
        fetchPolicy: "network-only",
      })
      .then((res) => {
        const changes = _.chain(res)
          .get("data.makerCheckerAPI.fetchChanges")
          .cloneDeep()
          // @ts-ignore
          .map(
            (change: {
              status: string;
              date_created: string;
              last_modified: string;
              department: [string];
            }) => {
              change.status = change.status.includes("_") ? change.status.split("_")[1] : ""; // add, modify, delete
              change.date_created = moment(change.date_created).format("YYYY-MM-DD HH:mm:ss");
              change.last_modified = moment(change.last_modified).format("YYYY-MM-DD HH:mm:ss");
              change.department = [""];
              return change;
            }
          )
          .map((change: any) => {
            // Get a display formatted status name
            const changeStatus = _.get(change, "status");
            let displayStatus = "";
            switch (changeStatus) {
              case "add":
                displayStatus = "Added";
                break;
              case "modify":
                displayStatus = "Modified";
                break;
              case "delete":
                displayStatus = "Deleted";
                break;
            }

            return { ...change, displayStatus };
          })
          .map((change: { type: string; last_modified: string }) => {
            // Helper Function naming
            if (change.type.includes("editor")) {
              return {
                ...change,
                last_modified: change.last_modified,
                mainType: change.type.split("_")[0],
              };
            } else {
              return {
                ...change,
                last_modified: change.last_modified,
                mainType: change.type.split("_")[1],
              };
            }
          })
          .map((change: { mainType: string }) => {
            let mainType = change.mainType;
            switch (mainType) {
              case "intent":
              case "example":
                mainType = "intent";
                break;

              case "entity":
              case "value":
              case "pattern":
              case "synonym":
                mainType = "entity";
                break;
              case "dialog":
                mainType = "dialog";
                break;
              case "editor":
                mainType = "editor";
                break;
            }
            return { ...change, mainType };
          })
          .map((change: { mainType: string }) => {
            // Mapping by department
            // Move to save changes. Do it once.
            // Change department => permission
            const old_values = JSON.parse(_.get(change, "old_values"));
            const new_values = JSON.parse(_.get(change, "new_values"));

            let department: any[] | string = [] || "";
            switch (change.mainType) {
              case "editor":
                department.push(
                  _.get(old_values, "node.department") || _.get(new_values, "node.department")
                );
                break;
              case "intent":
              case "entity":
              case "dialog":
                department.push(
                  _.get(old_values, "description") || _.get(new_values, "description")
                );
                break;
              default:
                department = [];
                break;
            }

            if (department && department[0] && department[0].includes(",")) {
              department = department[0].split(",");
            } else if (typeof department === "string") {
              department = [department];
            }
            department = _.chain(department)
              .compact()
              .flattenDeep()
              .map((v: string) => v && v.toLowerCase())
              .value();

            return { ...change, department };
          })
          .map((change: { old_values: string; new_values: string }) => {
            _.assign(change, {
              old_values: JSON.parse(change.old_values),
              new_values: JSON.parse(change.new_values),
            });
            return change;
          })
          .value();
        if (status === "completed" && offset !== 0) {
          commit("UPDATE_COMPLETED_CHANGES", changes);
        } else if (status === "pending") {
          commit("SET_PENDING_CHANGES", changes);
        } else if (status === "completed") {
          commit("SET_COMPLETED_CHANGES", changes);
        }
        return changes;
      })
      .catch((err) => {
        throw err;
      });
  },

  FETCH_PUBLISH: ({ commit }, { status, offset }) => {
    return graph
      .query({
        query: gql`
          query($status: String!, $offset: Int) {
            publishAPI {
              fetchPublish(status: $status, offset: $offset)
            }
          }
        `,
        variables: { status, offset },
        fetchPolicy: "network-only",
      })
      .then((res) => {
        const fetchPublish = _.cloneDeep(_.get(res, "data.publishAPI.fetchPublish"));
        return _.map(
          fetchPublish,
          (publish: {
            date_created: string;
            publish_date: string;
            modified_by: string;
            approved_by: string;
            payload: string;
            permission: any;
            count_down: any;
          }) => {
            const {
              date_created,
              publish_date,
              permission: stringPermission,
              modified_by,
              approved_by,
              payload,
            } = publish;
            publish.date_created = moment(date_created).format("DD-MM-YYYY HH:mm:ss");
            publish.publish_date = moment(publish_date).format("DD-MM-YYYY HH:mm:ss");
            publish.count_down = moment(publish_date).diff(moment(), "hour");
            publish.modified_by = _.capitalize(modified_by);
            publish.approved_by = _.capitalize(approved_by);
            publish.payload = JSON.parse(payload);
            publish.permission = stringPermission.split(",");
            return { ...publish };
          }
        );
      })
      .catch((err) => {
        throw err;
      });
  },
  CREATE_PUBLISH: (
    { commit },
    { publish_date, publishName, listOfChanges, types, departments }
  ) => {
    return graph
      .mutate({
        mutation: gql`
          mutation(
            $publish_date: String!
            $publishName: String
            $listOfChanges: [JSON]
            $types: [String!]
            $departments: [String!]
          ) {
            publishAPI {
              createPublications(
                publish_date: $publish_date
                publishName: $publishName
                listOfChanges: $listOfChanges
                types: $types
                departments: $departments
              )
            }
          }
        `,
        variables: {
          publish_date,
          publishName,
          listOfChanges,
          types,
          departments,
        },
      })
      .then((res) => {
        return _.get(res, "data.publishAPI.createPublications");
      })
      .catch((err) => {
        throw err;
      });
  },
  CREATE_AND_COMPLETE_PUBLISH: (
    { commit },
    { publish_date, publishName, listOfChanges, types, departments }
  ) => {
    const emptyListOfChanges = _.isEmpty(listOfChanges);
    if (emptyListOfChanges) {
      // Additional error message for user to take note
      Notification.error({
        type: "Empty Changes Error",
        message: "The list of changes detected is empty. Please refresh or select a change.",
        position: "bottom-right",
      });
      return;
    }

    return graph
      .mutate({
        mutation: gql`
          mutation(
            $publish_date: String!
            $publishName: String
            $listOfChanges: [JSON]
            $types: [String!]
            $departments: [String!]
          ) {
            publishAPI {
              createAndCompletePublications(
                publish_date: $publish_date
                publishName: $publishName
                listOfChanges: $listOfChanges
                types: $types
                departments: $departments
              )
            }
          }
        `,
        variables: {
          publish_date,
          publishName,
          listOfChanges,
          types,
          departments,
        },
      })
      .then((res) => {
        return _.get(res, "data.publishAPI.createAndCompletePublications");
      })
      .catch((err) => {
        throw err;
      });
  },
  UPDATE_PUBLISH: ({ commit }, { publish_id, publishName, publish_date }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation($publish_id: String!, $publishName: String, $publish_date: String!) {
            publishAPI {
              updatePublications(
                publish_id: $publish_id
                publishName: $publishName
                publish_date: $publish_date
              )
            }
          }
        `,
        variables: { publish_id, publishName, publish_date },
      })
      .then((res) => {
        return _.get(res, "data.publishAPI.updatePublications");
      })
      .catch((err) => {
        throw err;
      });
  },
  DELETE_PUBLICATION: ({ commit }, { publish_id }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation($publish_id: String!) {
            publishAPI {
              deletePublication(publish_id: $publish_id)
            }
          }
        `,
        variables: { publish_id },
      })
      .then((res) => {
        return _.get(res, "data.publishAPI.deletePublication");
      })
      .catch((err) => {
        throw err;
      });
  },
  COMPLETE_ALL_PUBLICATIONS: ({ commit }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation {
            publishAPI {
              approveAllPublications
            }
          }
        `,
      })
      .then((res) => {
        return _.get(res, "data.publishAPI.approveAllPublications");
      })
      .catch((err) => {
        throw err;
      });
  },
};

export default actions;
