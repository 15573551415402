<template>
  <div>
    <el-date-picker
      v-model="date"
      unlink-panels
      type="daterange"
      align="right"
      range-separator="To"
      start-placeholder="Start date"
      end-placeholder="End date"
      :default-time="['00:00:00', '23:59:59']"
      :picker-options="pickerOptions"
    ></el-date-picker>
    <el-button type="primary" v-on:click="changeDate(report, index)">Apply</el-button>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["report", "index"],
  data() {
    return {
      date: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "Today",
            onClick(picker) {
              const now = moment();
              const start = now.startOf("day").toDate();
              const end = new Date();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 3 days",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last week",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last month",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 3 months",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    changeDate({ url }, index) {
      const fromDate = moment(this.date[0]);
      const fromDateIsAfterCurrentTime = !fromDate.isBefore();

      let selectedToDate = moment(this.date[1]);
      const toDateIsAfterCurrentTime =
        !selectedToDate.isBefore() && !selectedToDate.isSame(moment(), "day");

      if (fromDateIsAfterCurrentTime || toDateIsAfterCurrentTime) {
        this.$notify.error({
          title: "Error",
          message: "Chosen date must be before today's date!",
        });
        return;
      }

      if (!selectedToDate.isBefore()) {
        selectedToDate = moment();
      }

      let updatedUrl;
      const chartType = this.$store.state.modules.analytics.urlEmbedCharts.chartType;
      switch (chartType) {
        case "kibana":
          updatedUrl = this.getKibanaURLWithNewFormattedDates(url, fromDate, selectedToDate);
          break;
        case "grafana":
          updatedUrl = this.getGrafanaURLWithNewFormattedDates(url, fromDate, selectedToDate);
          break;
        default:
          updatedUrl = url;
      }

      this.$store.commit("SET_ANALYTICS_CHART_URL", {
        urlWithUpdatedDate: updatedUrl,
        index,
      });
    },
    getKibanaURLWithNewFormattedDates(url, fromDate, toDate) {
      const formattedFromDate = `from%3A'${fromDate.toISOString()}'%2C`;
      const formattedToDate = `to%3A'${toDate.toISOString()}'))`;

      let updatedUrl = url;
      updatedUrl = updatedUrl.replace(/from%3A.*%2C/, formattedFromDate);
      updatedUrl = updatedUrl.replace(/to%3A.*\)/, formattedToDate);
      return updatedUrl;
    },
    getGrafanaURLWithNewFormattedDates(url, fromDate, toDate) {
      const formattedFromDate = `from=${moment(fromDate).unix()}000`;
      const formattedToDate = `to=${moment(toDate).unix()}000`;

      let updatedUrl = url;
      updatedUrl = updatedUrl.replace(/from=.*?(?=&)/, formattedFromDate);
      updatedUrl = updatedUrl.replace(/to=.*?(?=&)/, formattedToDate);
      return updatedUrl;
    },
  },
};
</script>
