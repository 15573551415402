<template>
  <!-- API -->
  <div>
    <el-input v-model="value.data.URL" style="margin-top: 15px" placeholder="Enter URL here">
      <template slot="prepend">URL</template>
    </el-input>
    <el-radio-group
      v-model="value.data.methodType"
      style="margin-top: 15px; margin-right: 150px"
      size="mini"
    >
      <el-radio label="GET" style="width: 48px">GET</el-radio>
      <el-radio label="POST" style="width: 48px">POST</el-radio>
    </el-radio-group>

    <el-row>
      <el-button
        icon="el-icon-plus"
        style="margin-top: 15px"
        type="primary"
        @click="addRequestHeader"
      >
        Add Request Header
      </el-button>
      <ul>
        <li
          v-for="(kv, index) in value.data.requestHeaders"
          :key="index"
          style="list-style-type: none; margin-left: -45px; width: 400px"
        >
          <el-input
            v-model="kv.key"
            style="float: left; width: 40%; padding: 5px"
            type="text"
            placeholder="Key"
          />
          <el-input
            v-model="kv.value"
            style="float: left; width: 40%; padding: 5px"
            type="text"
            placeholder="Value"
          />
          <el-button
            class="el-icon-delete"
            style="float: left; width: 10%; padding: 5px; margin-top: 12px"
            type="primary"
            @click="removeRequestHeader(index)"
          />
        </li>
      </ul>
    </el-row>
    <el-row style="margin-top: 0px; padding-top: 0px">
      <ul>
        <li style="list-style-type: none; margin-left: -40px; width: 100%">
          <el-button
            icon="el-icon-plus"
            style="float: left; margin-top: 15px; margin-right: 100%"
            type="primary"
            @click="addParameter"
          >
            Add Parameter
          </el-button>
        </li>
        <li
          v-for="(kv, index) in value.data.parameters"
          :key="index"
          style="list-style-type: none; margin-left: -45px; width: 400px"
        >
          <el-input
            v-model="kv.key"
            style="float: left; width: 40%; padding: 5px"
            type="text"
            placeholder="Key"
          />
          <el-input
            v-model="kv.value"
            style="float: left; width: 40%; padding: 5px"
            type="text"
            placeholder="Value"
          />
          <el-button
            class="el-icon-delete"
            style="float: left; width: 10%; padding: 5px; margin-top: 12px"
            type="primary"
            @click="removeParameter(index)"
          />
        </li>
      </ul>
    </el-row>
    <!-- <el-button style="float: left; margin-top: 15px; margin-right: 100%;" @click="submitAPICall()" type="primary">Submit</el-button> -->
  </div>
</template>

<script>
export default {
  name: "CustomEventAPI",
  props: {
    value: {
      event: "",
      data: {},
    },
  },
  methods: {
    addRequestHeader() {
      this.value.data.requestHeaders.push({
        key: "",
        value: "",
      });
    },

    removeRequestHeader(index) {
      this.value.data.requestHeaders.splice(index, 1);
    },

    addParameter() {
      this.value.data.parameters.push({
        key: "",
        value: "",
      });
    },

    removeParameter(index) {
      this.value.data.parameters.splice(index, 1);
    },
  },
};
</script>
