<template>
  <div>
    <span
      v-if="mode !== 'form'"
      style="margin-left: 1.5em; margin-right: 3.5em; fontsize: 14px"
      class="has-text-grey"
    >
      {{ setting.label || name }}
    </span>
    <el-select
      :name="setting.label || name"
      :clearable="setting.clearable"
      allow-create
      filterable
      multiple
      v-model="value[name]"
      placeholder="Create list"
      style="margin-bottom: 1em; width: 360px"
    >
      <el-option
        v-for="(item, index) in setting.default"
        :key="index"
        :label="item"
        :value="item"
      />
    </el-select>

    <Tooltip :label="setting.label || name" :content="setting.detail" />
  </div>
</template>

<script>
import Tooltip from "../Tooltip.vue";
export default {
  components: { Tooltip },
  props: ["value", "setting", "name", "mode"],
  mounted() {
    if (this.value[this.name] == null) {
      if (this.setting.default != null) {
        this.$set(this.value, this.name, this.setting.default);
      } else {
        this.$set(this.value, this.name, null);
      }
    }
  },
};
</script>
