<template>
  <el-row>
    <el-col>
      <el-form :model="node.content.options">
        <el-form-item>
          <el-input
            type="textarea"
            placeholder="Add body text"
            maxlength="1024"
            show-word-limit
            v-model="node.content.text"
            rows="5"
          />
        </el-form-item>
        <el-form-item>
          <el-input
            type="text"
            placeholder="Optional"
            maxlength="60"
            v-model="node.content.options.footer"
            show-word-limit
          >
            <template slot="prepend">Footer</template>
          </el-input>
        </el-form-item>
        <el-form-item
          prop="catalog_id"
          :rules="[{ required: true, trigger: 'blur', message: 'Please input Catalog ID' }]"
        >
          <el-input
            type="text"
            maxlength="60"
            v-model="node.content.options.catalog_id"
            show-word-limit
            placeholder="Require"
          >
            <template slot="prepend">
              <span> Catalogue ID </span>
              <Tooltip label="Catalogue ID" :content="content.catalogID" />
            </template>
          </el-input>
        </el-form-item>
        <el-form-item
          prop="product_retailer_id"
          :rules="[{ required: true, trigger: 'blur', message: 'Please input product ID' }]"
        >
          <el-input
            type="text"
            maxlength="60"
            v-model="node.content.options.product_retailer_id"
            show-word-limit
            placeholder="Require"
          >
            <template slot="prepend">
              <span> Product Retailer ID </span>
              <Tooltip label="Product Retailer ID" :content="content.retailerID" />
            </template>
          </el-input>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
import Tooltip from "@/components/ModuleEditor/Tooltip.vue";

export default {
  name: "WhatsAppSingleProduct",
  props: ["node"],
  components: {
    Tooltip,
  },
  data() {
    return {
      content: {
        catalogID: `Goto Business Catalogue Manager \> Select Catalogue \> Go to "Settings" \> Go to "Catalogue" \> Catalogue ID will be seen. Preview link: https://business.facebook.com/commerce/catalogs/\(CATALOG_ID\)/settings/products?business_id=xxx`,
        retailerID: `Goto Business Catalogue Manager \> Select Catalogue \> Go to "Items" \> Product ID will be under Item Name (Content ID). Preview link: https://business.facebook.com/commerce/catalogs/\(CATALOG_ID\)/products?business_id=xxx`,
      },
    };
  },
};
</script>

<style></style>
