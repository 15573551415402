<template>
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    style="background: none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.5 10.25L5.5 8" stroke="#222222" stroke-linecap="round" />
    <path d="M8.5 10.25L8.5 8" stroke="#222222" stroke-linecap="round" />
    <path
      d="M0.25 4.25H13.75V4.25C13.5178 4.25 13.4017 4.25 13.304 4.25963C12.3544 4.35315 11.6032 5.10441 11.5096 6.05397C11.5 6.15175 11.5 6.26783 11.5 6.5V10C11.5 11.8856 11.5 12.8284 10.9142 13.4142C10.3284 14 9.38562 14 7.5 14H6.5C4.61438 14 3.67157 14 3.08579 13.4142C2.5 12.8284 2.5 11.8856 2.5 10V6.5C2.5 6.26783 2.5 6.15175 2.49037 6.05397C2.39685 5.10441 1.64559 4.35315 0.696034 4.25963C0.598254 4.25 0.482169 4.25 0.25 4.25V4.25Z"
      stroke="#222222"
      stroke-linecap="round"
    />
    <path
      d="M5.55111 1.52794C5.63657 1.44821 5.82489 1.37775 6.08686 1.32749C6.34882 1.27724 6.6698 1.25 7 1.25C7.3302 1.25 7.65118 1.27724 7.91314 1.32749C8.17511 1.37775 8.36343 1.44821 8.44889 1.52794"
      stroke="#222222"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
