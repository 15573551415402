import Vue from "vue";
import _ from "lodash";
import Viewer from "v-viewer";
import ElementUI from "element-ui";
import VueTimeago from "vue-timeago";
import VueCodeMirror from "vue-codemirror";
import Vuelidate from "vuelidate";
import ReadMore from "vue-read-more";
import VueMasonry from "vue-masonry-css";
import VueDND from "awe-dnd";
import locale from "element-ui/lib/locale/lang/en";
import VueHtml5Editor from "vue-html5-editor";
import ElSearchTablePagination from "el-search-table-pagination";

import { AuthPlugin } from "./auth";
// Non package import
import KeyReplyUtility from "./plugins/index";
import store from "@/store";
import filters from "@/filters"; // Custom filters
import directives from "@/directives"; // Custom directives
// Javascript
import "core-js/stable"; // Polyfills
import "regenerator-runtime/runtime"; // Polyfills
import "push.js";
import "codemirror/mode/htmlmixed/htmlmixed.js";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/mode/yaml/yaml.js";
import "codemirror/addon/selection/active-line.js";
import "codemirror/addon/lint/lint.js";
import "codemirror/addon/lint/json-lint.js";
import "codemirror/keymap/sublime.js";
import "codemirror/addon/merge/merge.js";

// Css
import "codemirror/lib/codemirror.css";
import "codemirror/theme/monokai.css";
import "codemirror/addon/lint/lint.css";
import "codemirror/addon/merge/merge.css";
import "./assets/scss/element-variables.scss";
import "./assets/scss/global.scss"; // Custom
import VueClipboard from "vue-clipboard2";
import { initSentry } from "@/sentry";
import { initStoreWatcher } from "@/helpers/storeWatcher";
import Worker from "worker-loader!@/workers/index.ts";

const worker = new Worker();

// Escape from iframe
if (self == top) {
  document.documentElement!.style.display = "block";
}

Vue.config.productionTip = process.env.NODE_ENV !== "production";

// Register releaseId to window
window.DASHBOARD_RELEASE_ID = `${process.env.NAME}@${process.env.VERSION}`;

const initAuth = () => {
  const clientId = "a7ZRp2UutRceg2gUA23g7nvx9llXRNir";
  const domain = "keyreply.auth0.com";
  const audience = "https://login.keyreply.com";
  const useRefreshTokens = true;

  Vue.use(AuthPlugin, {
    domain,
    clientId,
    audience,
    useRefreshTokens,
    store,
  });
};

const initVuePlugins = () => {
  Vue.use(VueHtml5Editor, {
    icons: {
      text: "fas fa-pencil-alt",
      color: "fa fa-paint-brush",
      font: "fa fa-font",
      align: "fa fa-align-justify",
      list: "fa fa-list",
      link: "fas fa-link",
      unlink: "fas fa-unlink",
      tabulation: "fa fa-table",
      image: "fas fa-images",
      hr: "fa fa-minus",
      eraser: "fa fa-eraser",
      undo: "fa-undo fa",
      "full-screen": "fa fa-arrows-alt",
      info: "fa fa-info",
    },
    modules: [
      {
        name: "createid",
        icon: "fas fa-hashtag",
        i18n: "createid",
        show: true,
        dashboard: {
          data() {
            return {
              input: null,
            };
          },
          template: `
          <div style="display:flex;">
            <div style="max-width: 300px;" class="el-input el-input--mini">
              <input class="el-input__inner" placeholder="Insert id for the highlighted element." v-model="input"  />
            </div>    
            <button @click.prevent="setId">Update</button>
          </div>
          `,
          methods: {
            setId() {
              const vm = this as any;
              if (vm.input) {
                vm.$parent.execCommand("setId", vm.input);
                vm.input = null;
              }
            },
          },
        },
      },
    ],
  });
  Vue.use(ElementUI, { locale });
  Vue.use(ElSearchTablePagination);
  Vue.use(VueCodeMirror);
  Vue.use(Vuelidate);
  Vue.use(ReadMore);
  Vue.use(Viewer);
  Vue.use(VueMasonry);
  Vue.use(KeyReplyUtility);
  Vue.use(VueDND);
  Vue.use(VueTimeago, {
    name: "Timeago",
    locale: "en",
  });
  Vue.use(VueClipboard);

  // Register global utility filters.
  _.forEach(filters, (definition, id) => Vue.filter(id, definition));

  // Register global utility directives.
  _.forEach(directives, (definition, id) => Vue.directive(id, definition));
};

initAuth();
initVuePlugins();
initSentry(); // Init sentry
initStoreWatcher(store); // init store watcher

export { Vue, worker };
