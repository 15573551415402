<template>
  <!-- ARRAY VALUES -->
  <el-row v-if="this.isArray">
    <el-col :span="6" v-for="item in value" v-bind:key="item">
      <div class="grid-content">
        <form-result-value-display :value="item" />
      </div>
    </el-col>
  </el-row>

  <!-- NON-ARRAY VALUES -->
  <form-result-value-display v-else :value="value" />
</template>

<script>
import FormResultValueDisplay from "./FormResultValueDisplay.vue";

export default {
  name: "FormResultValueField",
  components: {
    FormResultValueDisplay,
  },
  props: {
    value: [String, Array],
  },
  computed: {
    isArray() {
      return _.isArray(this.value);
    },
  },
};
</script>

<style>
.grid-content {
  min-width: 110px;
  min-height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
