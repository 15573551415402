<template>
  <div>
    <el-row
      v-for="(condition, index) in conditions"
      :key="index"
      type="flex"
      style="align-items: center; flex-direction: row; margin-bottom: 0"
    >
      <el-select
        v-if="conditionPropertyOptionsDynamicModified.length > 0"
        v-model="condition.property"
        filterable
        allow-create
        default-first-option
        size="mini"
        :placeholder="propertyPlaceHolder"
        class="property-field"
      >
        <!-- this options list from contentNode.ts // contentNodeOptionsMixin -->
        <el-option-group
          v-for="(conditionPropertyOption, index) in conditionPropertyOptionsDynamicModified"
          :key="`condition-property-group-option-${index}`"
          :label="conditionPropertyOption.name"
        >
          <el-option
            v-for="(property, propertyIndex) in conditionPropertyOption.options"
            :key="`condition-property-option-${propertyIndex}`"
            :label="property.name"
            :value="property.value"
          ></el-option>
        </el-option-group>
      </el-select>
      <el-input
        v-model="condition.property"
        size="mini"
        :placeholder="propertyPlaceHolder"
        class="property-field"
        v-else
      ></el-input>
      <el-select
        filterable
        v-model="condition.operator"
        size="mini"
        style="width: 120px; margin-right: 5px"
      >
        <el-option
          :key="`condition-operator-option-${index}`"
          v-for="(conditionOperatorOption, index) in conditionOperatorOptions"
          :value="conditionOperatorOption.value"
          :label="conditionOperatorOption.name"
        />
      </el-select>
      <el-select
        v-if="showEntitySelector(condition)"
        size="mini"
        filterable
        :multiple="['INCLUDED IN', 'NOT INCLUDED IN'].includes(condition.operator)"
        v-model="condition.value"
        placeholder="Select Entity"
      >
        <el-option
          v-for="item in entityValues(condition)"
          :key="item.value"
          :label="item.value"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-autocomplete
        style="max-width: 35%; margin-right: 5px"
        v-else-if="!['EXISTS', 'NOT EXISTS'].includes(condition.operator)"
        v-model="condition.value"
        size="mini"
        :fetch-suggestions="querySearch(condition)"
        :placeholder="valuePlaceHolder"
      />
      <el-button
        type="text"
        icon="el-icon-delete"
        size="mini"
        circle
        @click="conditions.splice(index, 1)"
      />
    </el-row>

    <el-button @click.prevent="onAddMoreCondition" size="mini" type="primary" plain>
      <i class="el-icon-plus"></i>
      {{ actionAddBtnText }}
    </el-button>
  </div>
</template>

<script>
import {
  contentNodeConditionMethodMixin,
  contentNodeConditionOptionsPropsMixin,
  contentNodeConditionOptionsComputedMixin,
} from "@/mixins/contentNode";

export default {
  name: "ConditionEditor",
  mixins: [
    contentNodeConditionMethodMixin,
    contentNodeConditionOptionsPropsMixin,
    contentNodeConditionOptionsComputedMixin,
  ],
  props: {
    propertyPlaceHolder: {
      type: String,
      default: "Choose a property",
    },
    valuePlaceHolder: {
      type: String,
      default: "Specify a value",
    },
    conditions: {
      type: Array,
    },
    actionAddBtnText: {
      type: String,
      default: "Condition",
    },
  },
  computed: {
    conditionPropertyOptionsDynamicModified() {
      const propOpts = this.conditionPropertyOptions || [];
      if (propOpts.length) {
        return propOpts;
      }
      return this.conditionPropertyOptionsDynamic;
    },
  },
  data() {
    return {};
  },
  methods: {
    onAddMoreCondition() {
      this.conditions.push({
        property: null,
        operator: null,
        value: null,
      });
    },
  },
};
</script>
<style scoped>
>>> .conditions-input-wrapper > div {
  display: flex;
  align-items: center;
}

>>> .conditions-input-wrapper .el-input-group__prepend {
  width: 50%;
}

>>> .conditions-input-wrapper .el-input-group__prepend .property-selector {
  margin-right: 0;
  width: 70%;
}

>>> .conditions-input-wrapper .el-input-group__prepend .operator-selector {
  margin-left: 0;
  border-left: 1px solid #d8dce5;
}

.property-field {
  max-width: 35%;
  margin-right: 5px;
}
</style>
