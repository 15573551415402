import { InteractionType, LivechatSessionType } from "@/store/modules/livechat/types";
import store from "@/store";
import _ from "lodash";
import moment from "moment";
import { v4 as uuid } from "uuid";
import { LivechatType } from "@/store/modules/livechat/mutations";

export function getChatClass(
  index: number,
  {
    PartitionKey,
    RowKey,
    agents,
    resolved,
    unreadMessage,
    user_id,
  }: {
    PartitionKey: string;
    RowKey: string;
    agents: string[];
    resolved: boolean;
    unreadMessage: number;
    user_id?: string;
  },
  selectedChatId: string,
  partitionKey?: string
) {
  let className;
  let isActiveChat;
  const agentEmail = _.get(store, "state.profile.email");
  const isInChat = agents && agents.indexOf(agentEmail) !== -1;
  const hasAgentChat = agents && agents.length > 0;
  const isResolved = resolved === true;
  const hasUnreadMessages = unreadMessage > 0;
  if (partitionKey) {
    isActiveChat = user_id === selectedChatId && PartitionKey === partitionKey;
  } else {
    isActiveChat = RowKey === selectedChatId;
  }
  if (isActiveChat) {
    className = "active-chat list-item";
  } else if (isInChat) {
    className = "joined-chat list-item";
  } else if (hasAgentChat) {
    className = "has-agent-chat list-item";
  } else if (isResolved) {
    className = "resolved-chat list-item";
  } else {
    className = "list-item";
  }

  if (hasUnreadMessages) {
    className += " notification-flash";
  }

  return className;
}

interface DisplayIdPayload {
  fullName: string;
  alias: string;
  name: string;
  mobileNo: string;
  email: string;
  chatId: string;
  channel: string;
  source: string;
  user_id: string;
  source_wa_id?: string;
}

export function getChannelSpecificDisplayId(payload: DisplayIdPayload): string {
  const { channel, source, user_id, source_wa_id } = payload;
  const finalChannelOrSource = channel || source;
  if (finalChannelOrSource) {
    if (finalChannelOrSource === "whatsapp") {
      if (source_wa_id) {
        return `${user_id} @ ${source_wa_id}`;
      }
      return user_id;
    }
  }
  return "";
}

export function getDisplayId(payload: DisplayIdPayload): string {
  const { fullName, name, mobileNo, email, user_id, chatId } = payload;
  const vistorId = uuid();
  if (fullName) {
    return fullName;
  }
  if (name) {
    return name;
  }
  if (mobileNo) {
    return mobileNo;
  }
  if (email) {
    return email;
  }
  if (user_id) {
    return user_id;
  }
  if (chatId) {
    return chatId;
  }
  return vistorId;
}

export function getAvatar(chatId: string, profilePicture?: string) {
  if (profilePicture) {
    return profilePicture;
  }

  // TODO: remove once the old avatar is not needed
  // if (chatId) {
  //   // let pattern = GeoPattern.generate(chatId);
  //   // const result = pattern.toDataUrl();
  //   return chatId;
  // }
  return "";
}

// TODO: remove once the old avatar is not needed
// export function getAvatar(chatId: string, profilePicture?: string) {
//   if (profilePicture) {
//     return `url(${profilePicture})`;
//   }
//   if (chatId) {
//     let pattern = GeoPattern.generate(chatId);
//     const result = pattern.toDataUrl();
//     return result;
//   }
//   return "";
// }

export function getPreview(chatInteractions: InteractionType[]) {
  if (!chatInteractions || chatInteractions.length === 0) {
    return "";
  }

  const userMessages = _.filter(chatInteractions, (interaction) => {
    const isUserMessage = isMessageByUser(interaction.type);
    return isUserMessage;
  });
  const latestUserMessage = _.first(userMessages);

  if (latestUserMessage && latestUserMessage.data && !Array.isArray(latestUserMessage.data)) {
    const messageTextFromContentObject = _.get(
      latestUserMessage,
      "data.content.text",
      "-"
    ) as string;
    const messageText = _.get(
      latestUserMessage,
      "data.content[0].text",
      messageTextFromContentObject
    ) as string;

    return messageText.trim();
  } else {
    return "-";
  }
}

export function isMessageByUser(messageType: string): boolean {
  const isMessageByUser = messageType === "message" || messageType === "postback";
  return isMessageByUser;
}

export function filterUniqueTags(chatList: LivechatSessionType[]): string[] {
  const alltags = chatList.map((chat) => {
    const tags: string[] = _.get(chat, "tags", []);
    return tags;
  });
  const compactedTag = _.compact(_.flattenDeep(alltags)) as string[];

  const filteredTags = compactedTag.filter(
    (tag) =>
      !tag.includes("entities") &&
      !tag.includes("RowKey") &&
      !tag.includes("PartitionKey") &&
      !tag.includes("channel")
  );

  const finalTags = _.uniq(filteredTags);
  return finalTags;
}

export function printLocaleTimeFromUtc(utc) {
  const momentUtc = moment.utc(utc);
  const localDate = moment(momentUtc).local();
  return moment(localDate).format("DD/MM/YYYY hh:mm:ss A");
}

export function printTimestamp(timestamp: string): string {
  return timestamp != null ? moment(timestamp).format("DD/MM/YYYY hh:mm:ss A") : "NA*";
}
export function printDuration(
  startTimestamp: string,
  endTimestamp: string,
  isSuffixNotIncluded: boolean = false
): string {
  const isInvalidTimestamps = startTimestamp === "null" || endTimestamp === "null";
  if (isInvalidTimestamps) {
    return "-";
  }

  const start = moment(startTimestamp);
  const end = moment(endTimestamp);
  return start.from(end, isSuffixNotIncluded);
  // let duration = moment.duration(end.diff(start));
  // const finalDisplayDuration =
  //   Math.floor(duration.asMinutes()) + "m " + duration.seconds() + "s";
  // return finalDisplayDuration;
}

export function getSelectedChatArrays(type: LivechatType): LivechatSessionType[] {
  const monitorChats = store.state.livechat.monitorChatsArray;
  const resolvedChats = store.state.livechat.resolvedChatsArray;
  const abandonedChats = store.state.livechat.abandonedChatsArray;
  let selectedChats = monitorChats;

  switch (type) {
    case "queue":
      {
        const queueChats = Object.values(store.state.livechat.queueChatObject);
        selectedChats = queueChats;
      }
      break;
    case "resolved":
      {
        selectedChats = resolvedChats;
      }
      break;
    case "abandoned":
      {
        selectedChats = abandonedChats;
      }
      break;
    case "monitor":
      selectedChats = monitorChats;
      break;
  }

  return selectedChats;
}

export function getSelectedChatObject(type: LivechatType) {
  let selectedChats;

  switch (type) {
    case "queue":
      {
        const queueChats = store.state.livechat.queueChatObject;
        selectedChats = queueChats;
      }
      break;
    case "resolved":
      {
        const resolvedChats = store.state.livechat.resolvedChatsArray;
        selectedChats = resolvedChats;
      }
      break;
    case "abandoned":
      {
        const abandonedChats = store.state.livechat.abandonedChatsArray;
        selectedChats = abandonedChats;
      }
      break;
    case "monitor":
    default:
      {
        const monitorChats = store.state.livechat.monitorChatsArray;
        selectedChats = monitorChats;
      }
      break;
  }

  return selectedChats;
}
export function getLastSentMessageTimeStamp(
  selectedChat: any,
  interactions: InteractionType[]
): string {
  const orderedInteractions = _.orderBy(
    interactions,
    [
      (interaction) => {
        return moment(interaction.last_modified).valueOf();
      },
    ],
    ["desc"]
  );
  const latestUserInteraction = _.first(orderedInteractions);

  const dateCreatedTimeString = _.get(latestUserInteraction, "date_created");
  const lastModifiedTimeString = _.get(
    latestUserInteraction,
    "last_modified",
    dateCreatedTimeString
  );
  let lastModifiedTime = moment(lastModifiedTimeString);
  const chatLastModifiedTime = moment(selectedChat.last_modified);

  if (chatLastModifiedTime.isAfter(lastModifiedTime)) {
    lastModifiedTime = chatLastModifiedTime;
  }
  return lastModifiedTime.toISOString();
}

export function getLastSentMessageTime(interactions: InteractionType[]): string {
  const userMessages = _.filter(interactions, (interaction) => interaction.type !== "reply");
  const orderedInteractions = _.orderBy(
    userMessages,
    [
      (interaction) => {
        return moment(interaction.date_created).valueOf();
      },
    ],
    ["desc"]
  );
  const latestUserInteraction = _.first(orderedInteractions);

  const timestamp = _.get(latestUserInteraction, "date_created");
  const timeMessage = moment(timestamp).fromNow();

  return timeMessage;
}

export function isIEOrEdge() {
  if (!navigator) return false;
  let _ieOrEdge = false;
  if (/MSIE 10/i.test(navigator.userAgent)) {
    // This is internet explorer 10
    _ieOrEdge = true;
  }
  if (/MSIE 9/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent)) {
    // This is internet explorer 9 or 11
    _ieOrEdge = true;
  }
  if (/Edge\/\d./i.test(navigator.userAgent)) {
    // This is Microsoft Edge
    _ieOrEdge = true;
  }
  return _ieOrEdge;
}

/**
 * @description Is PII Details need to transfer
 * @return {boolean}
 */
export function isTransferRedactedText(): boolean {
  const enabled = _.get(store, "state.modules.pii.enabled", false);
  const transferRedacted = !_.get(store, "state.modules.pii.dontTransferRedacted", false);
  const result = enabled && transferRedacted;
  return result;
}
