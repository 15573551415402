import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import state from "./state";
import { Module } from "vuex/types";
import { BroadcastState } from "./types";
import { RootState } from "@/store/types";

const editor: Module<BroadcastState, RootState> = {
  // namespaced: true,
  getters,
  state,
  actions,
  mutations,
};

export default editor;
