import { RootState } from "./types";
import _ from "lodash";
import { OrderUpdate, TrendingType } from "@/helperMethods/trendingTopics/type";
// only AUTH0
export function getAgentSOEId(state: RootState): string {
  const agentSoeId = _.get(state, "profile.app_metadata.SOE_ID", "");
  return agentSoeId;
}
// only AUTH0
export function getAgentName(state: RootState): string {
  const agentName = _.get(state, "profile.name", "");
  return agentName;
}

export function isShowAdvanced(state: RootState): boolean {
  return _.get(state, "showAdvanced", false);
}

export function isCyngusAccount(state: RootState): boolean {
  return state.account.includes("CYNGUS");
}

export function contentNodeNames(state: RootState): string[] {
  return (state.nodes.content && Object.keys(state.nodes.content)) || [];
}

export function changes(state: RootState): string[] {
  return state.nodes?.changes || {};
}

export function releaseId(state: RootState): { dashboard: string; server: string } {
  return state.releaseId;
}

export function trendingTopics(state: RootState): TrendingType[] {
  return state.trendingTopics;
}
export function currentlyPublished(state: RootState): TrendingType[] {
  return state.currentlyTopics;
}
export function previousPublished(state: RootState): TrendingType[] {
  return state.previousTopics;
}
export function filterDate(state: RootState): Date[] {
  return state.filterDate;
}
export function orderCurrently(state: RootState): OrderUpdate[] {
  return state.orderCurrently;
}

export function isUiBetaEnabled(state: RootState): Boolean {
  return state.modules.webchat.ui_beta;
}

export function isRtlModeEnabled(state: RootState): Boolean {
  return state.modules.core.rtlMode;
}

export function intentTestingCompleted(state: RootState): Boolean {
  return state.training.intentTestingCompleted;
}
