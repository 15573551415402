<template>
  <ul class="wizard-step">
    <li v-for="step in steps" :key="step" :class="{ active: step === active }">
      {{ step }}
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    steps: {
      type: Number,
      required: true,
    },
    active: {
      type: Number,
      required: true,
    },
  },
  methods: {},
};
</script>

<style lang="scss">
@import "../../assets/scss/colors.scss";

.wizard-step {
  li {
    cursor: pointer;
    background: $color-primary;
    color: #fff;
    border-radius: 1em;
    width: 2em;
    height: 2em;
    text-align: center;
    margin-bottom: 5em;
    list-style: none;
    font-weight: bold;
    line-height: 1.9;
    position: relative;
    z-index: 0;
    &:after {
      content: " ";
      position: absolute;
      z-index: -1;
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      top: -7px;
      left: -7px;
      background: none;
      border-radius: 2em;
      transition: 0.3s;
    }
    &:not(:first-child) {
      &:before {
        content: "";
        position: absolute;
        bottom: 2em;
        left: 0.9em;
        width: 0.2em;
        height: 5em;
        background: $color-primary;
        z-index: -1;
      }
    }
    &:hover {
      &:after {
        border: 2px solid #dfdfdf;
      }
    }
    &.active {
      &:after {
        content: " ";
        position: absolute;
        z-index: -1;
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        top: -6px;
        left: -6px;
        border: 1px solid $color-primary;
        background: none;
        border-radius: 2em;
      }
      ~ li {
        background: #dfdfdf;
        &:before {
          background: #dfdfdf;
        }
      }
      + li {
        &:before {
          bottom: 1.5em;
        }
      }
      &:before {
        bottom: 2.4em;
      }
    }
  }
}
</style>
