export const trendingFilterShortcuts = [
  {
    text: "Today",
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      picker.$emit("pick", [start, end]);
    },
  },
  {
    text: "Yesterday",
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setDate(start.getDate() - 1);
      end.setDate(end.getDate() - 1);
      picker.$emit("pick", [start, end]);
    },
  },
  {
    text: "Last week",
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      picker.$emit("pick", [start, end]);
    },
  },
  {
    text: "Last month",
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      picker.$emit("pick", [start, end]);
    },
  },
  {
    text: "Last 3 months",
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      picker.$emit("pick", [start, end]);
    },
  },
  {
    text: "Last 6 months",
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30 * 6);
      picker.$emit("pick", [start, end]);
    },
  },
  {
    text: "Last year",
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30 * 12);
      picker.$emit("pick", [start, end]);
    },
  },
];
