import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import state from "./state";
import { Module } from "vuex/types";
import { WhatsAppState } from "./types";
import { RootState } from "@/store/types";

const whatsapp: Module<WhatsAppState, RootState> = {
  // namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default whatsapp;
