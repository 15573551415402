import { rest } from "@/store/api";
import { RootState } from "@/store/types";
import { ActionTree } from "vuex/types";
import { ZoomState } from "./types";

const actions: ActionTree<ZoomState, RootState> = {
  ZOOM_MEETING_START: async ({ state, commit, dispatch }, { chat, meeting_id }) => {
    const page_id = chat.PartitionKey;
    const user_id = chat.user_id;
    const session_id = chat.sessionId;

    const { id, start_url, join_url } = await rest("post", "zoom_meeting_create", { page_id, user_id, session_id, meeting_id });

    const payload = {
      user_id,
      meeting_id: id,
      start_url,
      join_url,
    }
    commit("SET_ZOOM_MEETING", payload);

    return { id, start_url, join_url };
  }
};

export default actions;
