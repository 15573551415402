<template>
  <div>
    <el-autocomplete
      v-model="value[name]"
      :fetch-suggestions="suggest"
      style="margin-bottom: 1em; max-width: 640px; min-width: 500px"
    >
      <div slot="prepend" style="min-width: 140px">
        {{ setting.label || name }}
        <Tooltip :label="setting.label || name" :content="setting.detail" />
      </div>
    </el-autocomplete>
  </div>
</template>

<script>
import Tooltip from "../Tooltip.vue";
export default {
  components: { Tooltip },
  props: ["value", "setting", "name"],
  methods: {
    suggest(query, cb) {
      let result = _.filter(this.setting.suggestions, (item) => {
        return item.toLowerCase().indexOf(query.toLowerCase()) > -1;
      });

      cb(result);
    },
  },
  mounted() {
    if (this.value[this.name] == null) {
      if (this.setting.default != null) {
        this.$set(this.value, this.name, this.setting.default);
      } else {
        this.$set(this.value, this.name, null);
      }
    }
  },
};
</script>
