import { BroadcastState } from "./types";
import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { graph } from "@/store/api";
import gql from "graphql-tag";
import _ from "lodash";

const actions: ActionTree<BroadcastState, RootState> = {
  FETCH_BROADCASTS: ({ commit }) => {
    return graph
      .query({
        query: gql`
          query {
            Bot {
              broadcasts
            }
          }
        `,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        const broadcastArray = _.get(response, "data.Bot");
        // console.log("broadcastArray", broadcastArray);
        commit("SET_BROADCASTS", broadcastArray);
        return response;
      })
      .catch((err) => {
        throw err;
      });
  },
  UPDATE_BROADCAST: (store, { oldTimestamp, broadcast }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation($broadcast: JSON) {
            updateBroadcast(broadcast: $broadcast)
          }
        `,
        variables: {
          broadcast,
        },
      })
      .then((result) => {
        const isUpdated = _.get(result, "data.updateBroadcast");
        store.commit("UNSET_BROADCAST", oldTimestamp);
        store.commit("SET_BROADCAST", {
          broadcast: broadcast,
        });

        return isUpdated;
      })
      .catch((err) => {
        throw err;
      });
  },
  DELETE_BROADCAST: (store, { timestamp }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation($timestamp: String) {
            deleteBroadcast(timestamp: $timestamp)
          }
        `,
        variables: {
          timestamp,
        },
      })
      .then((data) => {
        store.commit("DELETE_BROADCAST", timestamp);
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  CREATE_BROADCAST: (store, { timestamp, broadcast }) => {
    graph
      .mutate({
        mutation: gql`
          mutation($timestamp: String, $broadcast: JSON) {
            setBroadcast(timestamp: $timestamp, broadcast: $broadcast)
          }
        `,
        variables: {
          timestamp,
          broadcast,
        },
      })
      .then((result) => {
        const broadcast = _.get(result, "data.setBroadcast");
        store.commit("CREATE_BROADCAST", {
          broadcast,
          timestamp,
        });
        store.commit("SET_SELECTED_BROADCAST", timestamp);
        return broadcast;
      })
      .catch((err) => {
        throw err;
      });
  },
  EDIT_BROADCAST: (store, { previous, timestamp, broadcast }) => {
    if (previous !== timestamp) {
      store
        .dispatch("DELETE_BROADCAST", {
          timestamp: previous,
        })
        .catch((err) => {
          throw err;
        });
    }

    return store
      .dispatch("CREATE_BROADCAST", {
        timestamp,
        broadcast,
      })
      .catch((err) => {
        throw err;
      });
  },
  TEST_BROADCAST: (store, { timestamp, broadcast, testUserId }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation($timestamp: String, $testUserId: String, $broadcast: JSON) {
            testBroadcast(timestamp: $timestamp, testUserId: $testUserId, broadcast: $broadcast)
          }
        `,
        variables: {
          timestamp,
          broadcast,
          testUserId,
        },
      })
      .then((result) => {
        const isSuccess = _.get(result, "data.testBroadcast");
        return isSuccess;
      })
      .catch((err) => {
        throw err;
      });
  },
  GET_ESTIMATED_REACH: (store, { broadcast }) => {
    return graph
      .mutate({
        mutation: gql`
          mutation($broadcast: JSON) {
            getBroadcastEstimatedReach(broadcast: $broadcast)
          }
        `,
        variables: {
          broadcast,
        },
      })
      .then((result) => {
        const estimatedReach = _.get(result, "data.getBroadcastEstimatedReach");
        return estimatedReach;
      })
      .catch((err) => {
        throw err;
      });
  },
};

export default actions;
