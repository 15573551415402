<template>
  <el-card
    :class="{ 'is-launched': isLauncherOpened, 'is-left': isLeft }"
    :body-style="{ padding: 0, height: '100%' }"
    style="border-radius: 10px 10px 0 10px"
    id="widget-preview"
    v-show="isShowWebWidget"
    class="box-card"
  >
    <div v-if="isLauncherOpened" id="widget-navbar">
      <i
        class="el-icon-sort"
        @click="isLeft = !isLeft"
        style="
          margin: 5px;
          font-size: 12px;
          font-weight: bold;
          transform: rotate(90deg);
        "
      ></i>
      <!-- <i
        class="el-icon-close"
        style="margin: 5px; font-size: 12px; font-weight: bold"
        @click="
          $eventBus.$emit('TOGGLE_SHOW_WEB_WIDGET', {
            enabled: false,
          })
        "
      ></i> -->
    </div>
    <iframe ref="frame"></iframe>
  </el-card>
</template>

<script>
import get from "lodash/get";
import LocalStorageManager from "@/localStorageManager";

export default {
  data() {
    return {
      isLauncherOpened: false,
      isShowWebWidget: false,
      isLeft: false,
    };
  },
  methods: {
    /**
     * Render web chat widget
     *
     * @return {void}
     */
    renderWidget(config) {
      LocalStorageManager.setItem("keyreply-lastOpenStatus", true);
      this.isShowWebWidget = false;
      this.$refs.frame.contentWindow.document.open();
      this.$refs.frame.contentWindow.document.write(`
        <html>
          <style>${config.style || ""}</style>
          <body>
              <style>

              </style>
              ${config.code || ""}
          </body>
        </html>`);

      this.isShowWebWidget = !!config.enabled;

      window.onmessage = (e) => {
        const isLauncherOpened = get(e, "data") === "widgetopened";
        this.isLauncherOpened = isLauncherOpened;
      };
    },
  },
  beforeDestroy() {
    window.onmessage = () => {};
  },
  mounted() {
    this.$eventBus.$on("TOGGLE_SHOW_WEB_WIDGET", (payload) => {
      if (this.$refs.frame) {
        this.renderWidget(payload);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
#widget-navbar {
  position: absolute;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
#widget-preview {
  height: 75px;
  z-index: 9999;
  position: fixed;
  bottom: 0;
  right: 0;
  background: transparent;
  &:not(.is-launched) {
    border: 0px solid transparent;
    box-shadow: none;
  }
  &.is-left {
    right: auto;
    left: 0;
  }

  iframe {
    height: 100%;
    width: 75px;
    border: 0;
    border-radius: 0 10px 10px 0;
  }

  .widget-nav {
    display: flex;
    flex-direction: column;
    button {
      margin-left: 0;
    }
  }

  &.is-launched {
    height: 400px;
    iframe {
      width: 300px;
    }
  }
}
</style>