<template>
  <el-main>
    <el-row>
      <el-col style="width: 88%">
        <el-autocomplete
          class="entity-autocomplete"
          v-model="newEntity"
          :fetch-suggestions="getEntities"
          @select="
            addEntity();
            newEntity = '';
          "
          placeholder="Search an entity..."
        >
          <div slot="prepend">Entity</div>
        </el-autocomplete>
        <div class="fieldWarning" v-if="newEntity.length > 0 && invalidEntity">
          <i class="el-icon-warning"></i>
          Entity {{ newEntity }} not found.
        </div>
        <div class="fieldWarning" v-if="newEntity.length > 0 && notListEntity">
          <i class="el-icon-warning"></i>
          Only synonym-list entity allowed.
        </div>
      </el-col>
      <el-col :span="2" style="margin-left: 5px; float: right">
        <el-tooltip content="Add a new entity" placement="top">
          <el-button
            type="primary"
            :disabled="notValidEntity"
            @click="
              addEntity();
              newEntity = '';
            "
            icon="el-icon-plus"
          ></el-button>
        </el-tooltip>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-table
          size="mini"
          :data="intentEntities"
          highlight-current-row
          @cell-click="handleCellClick"
          :row-style="{ cursor: 'pointer' }"
          empty-text="No entities selected"
        >
          <el-table-column label="name">
            <!-- Custom column header for entity name-->
            <template slot="header">
              Selected Entity{{ " " }}
              <el-tooltip
                content="Selected entities will be used for training and FAQ detection"
                placement="right"
              >
                <i class="el-icon-info" />
              </el-tooltip>
            </template>

            <template slot-scope="scope">{{ scope.row }}</template>
          </el-table-column>
          <el-table-column width="60" label align="center">
            <template slot-scope="scope">
              <el-button
                icon="el-icon-delete"
                size="mini"
                circle
                type="danger"
                @click="deleteEntity(scope.row)"
              />
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <JSONEditor v-if="$store.state.showAdvanced" :value="intentEntities" />
      </el-col>
    </el-row>
    <FAQEntityDialog
      v-if="showDialog"
      :entity-form="selectedEntity"
      :show-dialog="showDialog"
      @toggleDialogVisible="toggleDialogVisible"
    />
  </el-main>
</template>
<script>
import Vue from "vue";
import _ from "lodash";
import JSONEditor from "@/components/JSONEditor";
import FAQEntityDialog from "@/components/Faq/FAQEntityDialog.vue";

export default Vue.extend({
  components: { JSONEditor, FAQEntityDialog },
  props: ["selectedIntent"],
  data() {
    return {
      newEntity: "",
      invalidEntity: false,
      notListEntity: false,
      selectedEntity: {},
      showDialog: false,
    };
  },

  computed: {
    dataset() {
      return _.get(this.$store.state, "training.dataset", []);
    },
    notValidEntity() {
      const newEntityIsEmpty = this.newEntity.length === 0;
      const notAcceptedEntity =
        this.newEntity.length > 0 && (this.invalidEntity || this.notListEntity);
      return newEntityIsEmpty || notAcceptedEntity;
    },
    intentEntities: {
      get() {
        // Default to empty array
        const storeEntities = _.get(this, "$store.state.nodes.entity", {});
        const storeEntityNames = Object.keys(storeEntities);

        const intentEntities = _.get(this, "selectedIntent.entities", []);
        _.forEach(intentEntities, (entity) => {
          const localEntityNotInStore = !storeEntityNames.includes(entity);
          if (localEntityNotInStore) {
            this.$notify.error({
              title: "Entity not found",
              message: `${entity} might have been renamed or removed`,
              position: "bottom-right",
            });
          }
        });

        return this.selectedIntent.entities || [];
      },
      set(value) {
        const intentEntity = _.flattenDeep(this.selectedIntent.entities);
        let temp = [];
        if (intentEntity && Array.isArray(temp)) {
          temp = value;
        } else {
          temp = [value];
        }
        this.selectedIntent.entities = temp;
      },
    },
    entities() {
      // Return names of entities
      return _.get(this, "$store.state.nodes.entity", {});
    },
  },
  methods: {
    toggleDialogVisible(isVisible = true, selectedEntity = "") {
      this.selectedEntity = this.entities[selectedEntity];
      this.showDialog = isVisible;
    },
    handleCellClick(row, column, cell, event) {
      if (column.label === "name") {
        // Do not open the dialog if the delete button was clicked
        this.selectedEntity = this.entities[row];
        this.showDialog = true;
      }
    },
    getEntities(value, cb) {
      if (value) {
        cb(this.$store.state.search.entity.search(value));
      }
    },
    addEntity() {
      const newEntity = this.newEntity;
      // Need to check before trim()
      if (typeof newEntity === "string") {
        const trimmedKeysEntities = _.transform(this.entities, (result, val, key) => {
          result[key.trim()] = val;
        });
        const selectedEntity = trimmedKeysEntities[newEntity.trim()];
        if (selectedEntity) {
          // This will update the local state's dataset
          const intentEntities = this.intentEntities;
          intentEntities.push(selectedEntity.name); // storing only entity's name
          this.intentEntities = intentEntities;
        }
      }
    },
    deleteEntity(entityName) {
      const entityIndex = _.findIndex(this.intentEntities, (row) => {
        return row === entityName;
      });

      if (entityIndex !== -1) {
        const intentEntities = this.intentEntities;
        intentEntities.splice(entityIndex, 1);
        this.intentEntities = _.uniq(intentEntities);
      }
    },
  },
  watch: {
    newEntity(value) {
      if (typeof value === "string") {
        const selectedEntity = this.entities[value.trim()];
        if (!selectedEntity) {
          this.invalidEntity = true;
        } else {
          // Valid Entity
          this.invalidEntity = false;

          if (selectedEntity.type !== "list") {
            this.notListEntity = true;
          } else {
            this.notListEntity = false;
          }
        }
      }
    },
  },
  // apollo: {
  //   dataset() {
  //     return {
  //       query: gql`
  //         query {
  //           faqAPI {
  //             dataset: getDataset
  //           }
  //         }
  //       `,
  //       update: data => {
  //         const dataset = get(data, "faqAPI.dataset");
  //         this.$set(this.$store.state.training, "dataset", dataset);
  //         const trainingDataset = this.$store.state.training.dataset;

  //         const result = _.map(trainingDataset, pair => {
  //           pair.tagInputVisible = false;
  //           pair.tagInputValue = "";
  //           pair.enabled = !!pair.enabled; // Cast to boolean type
  //           pair.tags = pair.tags || [];

  //           return pair;
  //         });

  //         return result;
  //       }
  //     };
  //   }
  // }
});
</script>

<style scoped lang="scss">
@import "../../../../../assets/scss/colors.scss";

.entity-autocomplete {
  width: 100%;
}
.keyreply-hover-table {
  border-collapse: collapse;
}
.keyreply-hover-table.keyreply-hover-color tbody tr:hover {
  background-color: lighten($color-primary, 45);
  cursor: pointer;
}
</style>
