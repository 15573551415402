<template>
  <el-drawer
    :visible.sync="isDrawerOpened"
    :title="`Edit Question: ${question ? question.name : ''}`"
    direction="rtl"
    id="question-edit-drawer"
    size="50%"
    @close="$emit('close')"
  >
    <div style="padding: 0 15px" v-if="question">
      <el-form :model="question" label-width="100px">
        <el-form-item style="margin-bottom: 0">
          <span slot="label" style="text-align: left">Name</span>
          <el-input
            size="mini"
            ref="questionName"
            v-model="question.name"
            :autofocus="true"
            id="question-edit-name"
            @change="updateVariableName"
          >
            <div slot="append" style="cursor: pointer">
              <i v-copy="{ text: question.variableName }" class="el-icon-document-copy"></i>
            </div>
          </el-input>
        </el-form-item>

        <el-form-item style="margin-bottom: 0">
          <span slot="label" style="text-align: left">Type</span>
          <el-select
            @change="onTypeChanged"
            v-model="question.type"
            filterable
            label="Type"
            size="mini"
            style="width: 100%"
          >
            <el-option label="Short Answer" value="string" />
            <el-option label="Email" value="email" />
            <el-option label="Phone number" value="phone" />
            <el-option label="Multiple Choice" value="multipleChoice" />
          </el-select>
        </el-form-item>

        <el-form-item style="margin-bottom: 0; margin-top: 7px">
          <span slot="label" style="text-align: left">Question</span>
          <el-input
            :autosize="{ minRows: 2 }"
            type="textarea"
            size="mini"
            v-model="question.question"
          ></el-input>
        </el-form-item>

        <el-form-item style="margin-bottom: 0">
          <span slot="label" style="text-align: left"></span>
          <el-checkbox v-model="question.required">Is Required</el-checkbox>
        </el-form-item>
      </el-form>

      <br />
      <br />

      <el-timeline style="padding-left: 5px">
        <el-timeline-item :timestamp="question.type | toTypeName" placement="top">
          <component
            v-if="getComponent(question.type)"
            :is="getComponent(question.type)"
            :success-options="successOptions"
            :question="question"
            :active-flow="activeFlow"
            @navigate="$emit('navigate', $event)"
          ></component>
        </el-timeline-item>
        <el-timeline-item timestamp="End with" placement="top">
          <EndWith :question="question" />
        </el-timeline-item>
      </el-timeline>

      <br />
      <JSONEditor v-if="$store.getters.isShowAdvanced" :value="question" />
    </div>
  </el-drawer>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

import EndWith from "./EndWith/Index";
import MultipleChoices from "./MultipleChoices/Index";
import TextOptions from "./TextOptions/Index";
import PhoneOptions from "./PhoneOptions/Index";
import EmailOptions from "./EmailOptions/Index";
import JSONEditor from "@/components/JSONEditor";

export default {
  props: ["question", "activeFlow"],
  components: {
    EndWith,
    MultipleChoices,
    TextOptions,
    PhoneOptions,
    EmailOptions,
    JSONEditor,
  },
  data() {
    return {
      isDrawerOpened: false,
    };
  },
  filters: {
    toTypeName: (text) => {
      const types = {
        string: "Short Answer",
        email: "Email",
        phone: "Phone Number",
        multipleChoice: "Multiple Choice",
        multiple_options: "Checkboxes",
      };
      const type = _.get(types, text, "");
      return `Suggested ${type}`;
    },
  },
  methods: {
    onTypeChanged() {
      this.question.suggestedAnswers = [];
    },
    getComponent(type) {
      let component = null;
      switch (type) {
        case "multipleChoice":
          component = "MultipleChoices";
          break;
        case "phone":
          component = "PhoneOptions";
          break;
        case "email":
          component = "EmailOptions";
          break;
        case "string":
          component = "TextOptions";
          break;
        default:
        //
      }

      return component;
    },
    updateVariableName() {
      const lowerCaseName = _.lowerCase(this.question.name);
      const replacedSpaceName = lowerCaseName.replace(/\s/gm, "_");
      const formattedVariableName = replacedSpaceName;
      this.question.variableName = formattedVariableName;
    },
  },
  watch: {
    question: {
      handler(updatedQuestion) {
        if (!_.isEmpty(updatedQuestion)) {
          this.isDrawerOpened = true;
          setTimeout(() => {
            this.$refs.questionName.$refs.input.focus();
          }, 100);
        }
      },
    },
  },
  computed: {
    ...mapGetters(["flowSectionsByFirstQuestionId"]),
    successOptions() {
      const defaultSuccessOptions = _.map(
        [
          {
            id: "submit",
            label: "Submit Flow",
            value: "submit",
          },
        ],
        (option) => {
          if (option.id === "submit" && this.activeFlow.endContentNode.id) {
            return {
              ...option,
              label: `Submit Flow (${this.activeFlow.endContentNode.id})`,
              value: this.activeFlow.endContentNode.id,
            };
          } else {
            return option;
          }
        }
      );

      const possibleQuestionNames = _.chain(this.activeFlow.sections)
        .map((section) => section.questions)
        .flatten()
        .filter((question) => this.question.questionId !== question.questionId)
        .map((question) => {
          if (question.questionId && question.questionId.includes("_1")) {
            return { label: question.name, value: `${question.sectionId}_1` };
          }
          return { label: question.name, value: question.questionId };
        })
        .value();

      const currentFlowQuestions = _.concat(defaultSuccessOptions, possibleQuestionNames);

      const finalOption = [{ label: "Current flow questions", options: currentFlowQuestions }];

      if (this.activeFlow.isLinkingToOtherFlows) {
        finalOption.push({
          label: "Other flow sections",
          options: this.flowSectionsByFirstQuestionId,
        });
      }

      return finalOption;
    },
  },
};
</script>

<style lang="css" scoped>
#question-edit-drawer {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
}

>>> .el-form-item__content .el-input-group {
  vertical-align: middle;
}

>>> .el-drawer.rtl {
  overflow: auto;
}
</style>
