import _ from "lodash";

interface Change {
  RowKey: string;
  PartitionKey: string;
  date_created: Date;
  last_modified: Date;
  modified_by: string;
  approved_by: string | null;
  status: string;
  type: string;
  mainType: string;
  department: string[];
  old_values: { [key: string]: string | boolean } | any;
  new_values: { [key: string]: string | boolean } | any;

  displayStatus?: "Deleted" | "Added" | "Modified";
}

export function getDepartmentVerifiedChanges(
  changesList: Change[],
  departments: string[]
): Change[] {
  if (!Array.isArray(changesList)) {
    return []; // not valid
  }

  const validAndDepartmentVerifiedChanges = changesList.filter((change) => {
    const isValidate = validateWatsonChangeMainType(change);
    if (isValidate) {
      const changeDepartment: string | string[] = change.department;
      const changeDepartmentExists = _.get(change, "department.length", 0) > 0;
      const deptExist = departments && changeDepartmentExists;

      if (deptExist) {
        const changeBelongsToUserDept = checkChangeBelongsToUserDepartment(
          changeDepartment,
          departments
        );
        return changeBelongsToUserDept;
      } else {
        return true;
      }
    } else {
      return false;
    }
  });

  return validAndDepartmentVerifiedChanges;
}

function validateWatsonChangeMainType(change: Change): boolean {
  return ["entity", "intent", "dialog", "editor"].includes(change.mainType); // valid for Watson
}

export function validateChangeStatusList(changeList: Change[], selectedStatus: string): Change[] {
  return _.filter(changeList, (change) => {
    return validateChangeStatus(change, selectedStatus);
  });
}

function validateChangeStatus(change: Change, selectedStatus: string): boolean {
  return selectedStatus === "pending"
    ? ["add", "modify", "delete"].includes(change.status)
    : ["approve", "reject"].includes(change.status);
}

export function getUniqueChangeStatusList(changeList: Change[], selectedStatus: string): Change[] {
  return _.uniqBy(changeList, (change) => {
    return selectedStatus === "pending"
      ? change.mainType
      : [change.mainType, change.last_modified].join();
  });
}

function checkChangeBelongsToUserDepartment(
  changeDepartment: string | string[],
  departments: string[]
): boolean {
  const lowerCasedChangeDept = parseChangeDepartment(changeDepartment);

  const belongsToUser = _.intersection(departments, lowerCasedChangeDept).length > 0;
  const isGeneral = lowerCasedChangeDept.includes("general");

  return belongsToUser || isGeneral;
}

function parseChangeDepartment(changeDepartment: string | string[]): string[] {
  const changeDepartmentIsStringWithComma = isFirstElementStringifiedWithComma(changeDepartment);
  let changeDepartmentArr: string[] = [];
  if (changeDepartmentIsStringWithComma) changeDepartmentArr = changeDepartment[0].split(",");
  else if (typeof changeDepartment === "string") {
    changeDepartmentArr = [changeDepartment];
  } else {
    changeDepartmentArr = changeDepartment;
  }

  return _.chain(changeDepartmentArr)
    .flattenDeep()
    .compact()
    .map((v) => v && v.toLowerCase())
    .value();
}

function isFirstElementStringifiedWithComma(array: string[] | any): boolean {
  const firstElementExist = Array.isArray(array) && array[0];
  const firstElementIsString =
    typeof firstElementExist === "string" && firstElementExist.includes(",");
  return firstElementIsString;
}
