import { doesEventExist, doesLinkExist, doesApiMappingExist } from "../editor";
import { CaptureData, GotoData, ApiMappingData } from "./type";
import store from "@/store";
import _ from "lodash";

export function isLinkFound(next: string | CaptureData | GotoData | ApiMappingData): boolean {
  if (typeof next === "string") {
    const linkNodeFound = doesLinkExist(next);
    return linkNodeFound;
  }

  const nextEvent = _.get(next, "event");
  const nextData = _.get(next, "data");
  const nextIsEvent = doesEventExist(nextEvent);
  if (!nextIsEvent) {
    return false;
  }

  if (nextEvent === "handover_router" || nextEvent === "handover_livechat_start") {
    return true;
  }

  if (nextEvent === "callApi") {
    const mappingExists =
      typeof nextData === "object" && "mappingName" in nextData
        ? doesApiMappingExist(nextData.mappingName)
        : false;
    return mappingExists;
  }

  let finalNextEventToValidate = nextEvent;
  let finalNextDataToValidate = nextData as string;

  if (nextEvent === "capture" || nextEvent === "set") {
    const captureEventNext = _.get(next, "data.next.event");
    const captureDataNext = _.get(next, "data.next");

    finalNextEventToValidate = captureEventNext as string;
    finalNextDataToValidate = captureDataNext;
  }

  const isValidEvent = isLinkFound(finalNextEventToValidate);
  let isValidData = isLinkFound(finalNextDataToValidate);

  if (nextEvent === "startflow") {
    const flowId = _.get(next, "data.id");
    const flowIds = Object.keys(store.state.floweditor.contents).join("");
    isValidData = !flowIds.length ? true : flowIds.includes(flowId);
  }

  const isValidLink = isValidEvent && isValidData;
  return isValidLink;
}
