<template>
  <div>
    <el-row>
      <el-col :span="4">
        <el-tooltip content="Add a new intent" placement="right">
          <el-button type="primary" @click="showNewIntentDialog">Add new intent</el-button>
        </el-tooltip>
      </el-col>
    </el-row>
    <el-dialog :visible.sync="newIntentDialogVisible">
      <el-row :gutter="10" type="flex" id="faq-new-intent">
        <el-col :span="isFaqGroupByTopicEnabled ? 15 : 24">
          <el-input
            v-model="defaultIntent.intentName"
            placeholder="Enter a new intent name..."
            @keyup.enter.native="onSaveIntent"
            @change="$emit('isDirty')"
            style="margin-bottom: 15px"
          />

          <small
            style="font-size: 10px; margin-left: 3px"
            class="has-text-danger"
            v-if="errorMessage"
            v-html="errorMessage"
          ></small>
        </el-col>
        <el-col v-if="isFaqGroupByTopicEnabled">
          <el-select
            multiple
            filterable
            allow-create
            default-first-option
            v-model="defaultIntent.topics"
            placeholder="Choose topics for your intent"
          >
            <el-option
              v-for="(item, index) in topics"
              :key="`intent-topic-option-${index}`"
              class="text-capitalize"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-tooltip content="Add a new intent" placement="right">
            <el-button
              type="primary"
              :disabled="!defaultIntent.intentName"
              icon="el-icon-plus"
              @click="onAddIntent"
            />
          </el-tooltip>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { minLength, required } from "vuelidate/lib/validators";
import _ from "lodash";

export default {
  props: {
    topics: {
      default: Array,
    },
  },
  computed: {
    /**
     * @description Get validation error message
     * @return {string | undefined}
     */
    errorMessage() {
      if (!this.$v.$error) {
        return undefined;
      }

      return !this.$v.defaultIntent.intentName.isUnique
        ? "Intent name has been taken."
        : "Intent name is required and has to be atleast 3 characters.";
    },

    /**
     * @description Get group by topic setting enable or not
     * @return {boolean}
     */
    isFaqGroupByTopicEnabled() {
      return this.$store.state.modules.faq.groupByTopic;
    },

    /**
     * @description Get all intent names into lowercase
     * @return {string[]}
     */
    datasetIntentNames() {
      const dataset = _.chain(this.$store.state)
        .get("training.dataset", [])
        .map((intent) => intent.intentName.toUpperCase())
        .value();
      return dataset;
    },
  },
  methods: {
    /**
     * @description Touch input.
     * @description If validated, handle save.
     * @return {void}
     */
    onSaveIntent() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit("handleBlur", this.defaultIntent, true);
        this.$nextTick(() => (this.defaultIntent.intentName = ""));
        this.$v.$reset();
      }
    },

    /**
     * @description Check if new intent's topic name is valid
     * @return {void}
     */
    checkIntentTopics() {
      let isValidTopicName = false;
      this.defaultIntent.topics = _.map(this.defaultIntent.topics, (topic) => {
        return topic.toLowerCase();
      });

      // Check to prevent '_' in topic name
      const topicWithUnderscoreIndex = this.defaultIntent.topics.findIndex((topic) =>
        topic.includes("_")
      );
      const topicsWithInvalidName = topicWithUnderscoreIndex > -1;
      if (topicsWithInvalidName) {
        this.$notify.error({
          title: "Topic name error",
          position: "bottom-right",
          message: `Please remove '_' character in ${this.defaultIntent.topics[topicWithUnderscoreIndex]}`,
        });
        return isValidTopicName;
      }

      // Check to prevent '_' in topic name
      const topicNamedModelIndex = this.defaultIntent.topics.findIndex(
        (topic) => topic === "model"
      );
      const topicNamedModel = topicNamedModelIndex > -1;
      if (topicNamedModel) {
        this.$notify.error({
          title: "Topic name error",
          position: "bottom-right",
          message: `Please rename ${this.defaultIntent.topics[topicNamedModelIndex]} topic`,
        });
        return isValidTopicName;
      }

      isValidTopicName = true;
      return isValidTopicName;
    },

    /**
     * @description On add new intent handler
     * @return {void}
     */
    onAddIntent() {
      const passedCheck = this.checkIntentTopics();
      if (passedCheck) {
        this.$emit("handleBlur", this.defaultIntent, true);
        this.$nextTick(() => {
          this.defaultIntent.intentName = "";
          this.defaultIntent.topics = [];
        });
      }
      this.newIntentDialogVisible = false;
    },

    showNewIntentDialog() {
      this.newIntentDialogVisible = true;
    },
  },
  data() {
    return {
      // Only intentName is needed for new intent creation
      defaultIntent: {
        intentName: "",
        topics: [],
      },
      newIntentDialogVisible: false,
    };
  },
  validations() {
    return {
      defaultIntent: {
        intentName: {
          required,
          minLength: minLength(3),
          isUnique: (value) => {
            if (!value) {
              return true;
            }

            const upperCasedValue = value.toUpperCase();
            const isUnique = !_.includes(this.datasetIntentNames, upperCasedValue);
            return isUnique;
          },
        },
      },
    };
  },
};
</script>

<style scoped>
#faq-new-intent .el-select {
  width: 100%;
}
</style>
