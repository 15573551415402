export default function Konami(callback: string | Location | Function) {
  let konami: any = {
    addEvent: function (obj: any, type: any, fn: any, ref_obj?: any) {
      if (obj.addEventListener) obj.addEventListener(type, fn, false);
      else if (obj.attachEvent) {
        // IE
        obj["e" + type + fn] = fn;
        obj[type + fn] = function () {
          obj["e" + type + fn](window.event, ref_obj);
        };
        obj.attachEvent("on" + type, obj[type + fn]);
      }
    },
    input: "",
    pattern: "38384040373937396665",
    load: function (link?: Location | string) {
      this.addEvent(
        document,
        "keydown",
        function (e: any, ref_obj: any) {
          if (ref_obj) konami = ref_obj; // IE
          konami.input += e ? e.keyCode : (event as any).keyCode;
          if (konami.input.length > konami.pattern.length)
            konami.input = konami.input.substr(konami.input.length - konami.pattern.length);
          if (konami.input == konami.pattern) {
            konami.code(link);
            konami.input = "";
            e.preventDefault();
            return false;
          }
        },
        this
      );
      this.iphone.load(link);
    },
    code: function (link: Location) {
      window.location = link;
    },
    iphone: {
      start_x: 0,
      start_y: 0,
      stop_x: 0,
      stop_y: 0,
      tap: false,
      capture: false,
      orig_keys: [""],
      keys: ["UP", "UP", "DOWN", "DOWN", "LEFT", "RIGHT", "LEFT", "RIGHT", "TAP", "TAP"],
      code: function (link: Location) {
        konami.code(link);
      },
      load: function (link: Location) {
        this.orig_keys = this.keys;
        konami.addEvent(document, "touchmove", function (e: any) {
          if (e.touches.length == 1 && konami.iphone.capture == true) {
            const touch = e.touches[0];
            konami.iphone.stop_x = touch.pageX;
            konami.iphone.stop_y = touch.pageY;
            konami.iphone.tap = false;
            konami.iphone.capture = false;
            // konami.iphone.check_direction();
            konami.iphone.check_direction(link); // passing link as parameter
          }
        });
        konami.addEvent(
          document,
          "touchend",
          // function(evt) { - Event was passed but not used, commented - 2/8/18
          function () {
            if (konami.iphone.tap == true) konami.iphone.check_direction(link);
          },
          false
        );
        konami.addEvent(document, "touchstart", function (evt: any) {
          konami.iphone.start_x = evt.changedTouches[0].pageX;
          konami.iphone.start_y = evt.changedTouches[0].pageY;
          konami.iphone.tap = true;
          konami.iphone.capture = true;
        });
      },
      check_direction: function (link: Location) {
        const x_magnitude = Math.abs(this.start_x - this.stop_x);
        const y_magnitude = Math.abs(this.start_y - this.stop_y);
        const x = this.start_x - this.stop_x < 0 ? "RIGHT" : "LEFT";
        const y = this.start_y - this.stop_y < 0 ? "DOWN" : "UP";
        let result = x_magnitude > y_magnitude ? x : y;
        result = this.tap == true ? "TAP" : result;

        if (result == this.keys[0]) {
          this.keys = this.keys.slice(1, this.keys.length);
        } else {
          this.keys = this.orig_keys;
        }
        if (this.keys.length == 0) {
          this.keys = this.orig_keys;
          this.code(link);
        }
      },
    },
  };

  typeof callback === "string" && konami.load(callback);
  if (typeof callback === "function") {
    konami.code = callback;
    konami.load();
  }

  return;
}
