<template>
  <el-card id="feedback-rating" style="min-height: 378px">
    <el-row>
      <el-col :sm="8">
        <h2>Bot Rating</h2>
      </el-col>
      <el-col :sm="16" style="text-align: right">
        <el-button
          class="button"
          size="mini"
          :disabled="Object.keys(ratingData).length === 0"
          @click="downloadRatings()"
          style="margin-top: 0px; font-size: 12px; margin-right: 5px"
        >
          Download (CSV)
        </el-button>
        <router-link
          v-if="showModule('analytics') && checkRoles(getPermittedRoles('analytics'))"
          to="/analytics"
        >
          <el-button size="mini" class="button" style="margin-top: 0px; font-size: 12px">
            See All
          </el-button>
        </router-link>
      </el-col>
    </el-row>

    <!-- rating -->
    <el-row style="margin-top: 32px; margin-bottom: 16px">
      <el-col :sm="12">
        <el-select
          v-model="selectedChannel"
          placeholder="Select a channel"
          @change="handleChannelChange"
          class="dashboard-dropdown"
          filterable
        >
          <el-option :key="0" :label="'All channels'" :value="''" />
          <el-option
            v-for="(obj, index) in ratingChannels"
            :key="index + 1"
            :label="obj.channel"
            :value="obj.channel"
          />
        </el-select>
      </el-col>
      <el-col :sm="12">
        <el-select
          v-model="selectedAppSource"
          placeholder="Select an App Source"
          @change="handleAppSourceChange"
          class="dashboard-dropdown"
          filterable
        >
          <el-option :key="0" :label="'All App Sources'" :value="''" />
          <el-option
            v-for="(obj, index) in appSources"
            :key="index + 1"
            :label="obj"
            :value="obj"
          />
        </el-select>
      </el-col>
    </el-row>
    <div style="margin-bottom: 16px; display: flex; justify-content: space-between">
      <el-date-picker
        v-model="botRatingDatePicked"
        type="daterange"
        range-separator="To"
        start-placeholder="Start date"
        end-placeholder="End date"
        format="dd/MM/yyyy"
        :picker-options="botRatingPickerOptions"
        :default-time="['00:00:00', '23:59:59']"
        class="dashboard-date"
      />
      <el-button
        :disabled="ratingLoading"
        type="primary"
        plain
        size="mini"
        icon="el-icon-search"
        class="dashboard-date-filter-button"
        @click="getRatingsByDateFilter"
        >Filter</el-button
      >
    </div>

    <RatingChart :width="100" :height="50" :grouped-data="filteredGroupedRatings" />&nbsp;
  </el-card>
</template>
<script>
import XLSX from "xlsx";
import _ from "lodash";
import RatingChart from "./Chart";
import moment from "moment";
import { checkRoles, getPermittedRoles, showModule } from "@/helperMethods/auth";

export default {
  components: {
    RatingChart,
  },
  computed: {
    timezone() {
      return this.$store.state.modules.rating.timezone ?? 8;
    },
    groupedData() {
      return this.$store.state.ratings || [];
    },
    ratingChannels() {
      return this.$store.state.ratingChannels || [];
    },
    appSources() {
      const sources = _.chain(this.groupedData)
        .uniqBy("appSource")
        .map((obj) => obj.appSource)
        .value();
      return sources;
    },
    filteredGroupedRatings() {
      const ratings = _.chain(this.ratingData)
        .filter((ratingObj) => ratingObj.rating)
        .map((ratingObj) => ratingObj.rating)
        .countBy(Math.floor)
        .value();
      return ratings;
    },
  },
  data() {
    return {
      botRatingDatePicked: [moment().subtract(6, "month"), moment()],
      botRatingPickerOptions: {
        shortcuts: [
          {
            text: "Today",
            onClick(picker) {
              var start = new Date();
              start.setHours(0, 0, 0, 0);

              var end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Yesterday",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last week",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last month",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 3 months",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 6 months",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last Year",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "All-Time",
            onClick(picker) {
              const end = new Date();
              const start = new Date(0);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      selectedChannel: "",
      selectedAppSource: "",
      ratingData: [],
      ratingLoading: false,
    };
  },

  methods: {
    checkRoles,
    getPermittedRoles,
    showModule,
    formatDateTime(date) {
      return moment(date).utcOffset(this.timezone).format("DD-MM-YYYY HH:mm:ss Z");
    },
    handleChannelChange() {
      this.getRatingsByDateFilter();
    },
    handleAppSourceChange(val) {
      this.filterByAppSource(val);
    },
    downloadRatings() {
      const wb = XLSX.utils.book_new();
      const mappedData = this.ratingData.map((item) => ({
        ...item,
        date_created: this.formatDateTime(item.date_created),
      }));
      const ws = XLSX.utils.json_to_sheet(mappedData);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      const from = this.botRatingDatePicked[0];
      const to = this.botRatingDatePicked[1];

      const filename = this.$exportFilename(`ratings (${from}-${to})`, "xlsx");
      XLSX.writeFile(wb, filename, {});
      this.isDownloading = false;
    },
    getRatingsByDateFilter() {
      this.ratingLoading = true;
      const startDate = this.botRatingDatePicked[0];
      const endDate = this.botRatingDatePicked[1];
      this.$store
        .dispatch("FETCH_RATINGS", {
          data: { channel: this.selectedChannel, startDate, endDate },
        })
        .then((ratings) => {
          this.ratingLoading = false;
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: "Encountered error. Please refresh and try again.",
          });
        })
        .finally(() => {
          this.ratingData = this.groupedData;
          this.filterByAppSource(this.selectedAppSource);
        });
    },

    getRatingChannels() {
      const startDate = this.botRatingDatePicked[0];
      const endDate = this.botRatingDatePicked[1];
      this.$store
        .dispatch("FETCH_RATING_CHANNELS", {
          data: { startDate, endDate },
        })
        .then((ratings) => {})
        .catch((err) => {
          this.$message({
            type: "error",
            message: "Encountered error. Please refresh and try again.",
          });
        });
    },
    filterByAppSource(val) {
      if (val) {
        const filteredByAppSource = _.chain(this.groupedData)
          .groupBy("appSource")
          .get(val, [])
          .value();
        this.ratingData = filteredByAppSource;
      } else {
        this.ratingData = this.groupedData;
      }
    },
  },
  mounted() {
    this.getRatingsByDateFilter();
    this.getRatingChannels();
  },
  watch: {},
};
</script>

<style lang="scss">
@import "../../../../assets/scss/colors.scss";

#feedback-rating {
  padding-bottom: 20px;
}

#feedback-rating .el-card__body h1 {
  margin-bottom: 0;
}

#feedback-rating .button {
  margin-top: 1.5em;
}

.feedback-rating-icon {
  height: 50px;
  margin-right: 0.5em;
}

.feedback-rating-percent.up {
  font-size: 50px;
  color: $color-success;
}

.feedback-rating-percent.down {
  font-size: 50px;
  color: $color-danger;
}

.dashboard-dropdown {
  .el-input--suffix {
    .el-input__inner {
      height: 30px;
      font-size: 12px;
    }
  }
}

.dashboard-date {
  &.el-input__inner {
    width: auto;
    height: 30px;
    margin-left: 10px;
  }
  .el-range__icon {
    line-height: 20px !important;
    height: 20px;
    max-height: 20px;
    width: 25px;
  }
  .el-range-input {
    height: 20px;
    font-size: 12px;
    width: 60%;
    line-height: 20px !important;
    margin-left: 5px;
  }
  .el-range-separator {
    height: 20px;
    padding: 0;
    line-height: 20px !important;
    font-size: 12px;
    width: 15%;
  }
  .el-range__close-icon {
    height: 20px;
    line-height: 20px !important;
  }
}

.dashboard-date-filter-button {
  height: 30px;
  margin-left: 10px;
}
</style>
