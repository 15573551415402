<template>
  <div>
    <span
      v-if="mode !== 'form'"
      class="has-text-grey"
      style="margin-left: 1.5em; margin-right: 3.5em; fontsize: 14px"
    >
      {{ setting.label || name }}
    </span>
    <el-select
      filterable
      v-model="value[name]"
      placeholder="Select"
      :name="setting.label || name"
      :clearable="setting.clearable"
      style="margin-bottom: 1em; width: 360px"
    >
      <el-option
        v-for="item in setting.options"
        :key="item.value"
        :label="item.label"
        :value="item.value ? item.value : item.label"
      >
        <span :style="{ float: 'left' }">{{ item.label }}</span>
        <span
          v-if="setting.displayValue"
          class="has-text-grey"
          :style="{
            float: 'right',
            color: item.color ? item.color : '',
            fontSize: 13 + 'px',
            marginLeft: '1.5em',
          }"
        >
          {{ item.value ? item.value : item.label }}
        </span>
      </el-option>
    </el-select>

    <Tooltip :label="setting.label || name" :content="setting.detail" />
  </div>
</template>

<script>
import Tooltip from "../Tooltip.vue";
export default {
  components: { Tooltip },
  props: ["value", "setting", "name", "mode"],
  mounted() {
    if (this.value[this.name] == null) {
      if (this.setting.default != null) {
        this.$set(this.value, this.name, this.setting.default);
      } else {
        this.$set(this.value, this.name, null);
      }
    }
  },
};
</script>
