// Currently only use in translate -> languages
<template>
  <div class="flex-container">
    <span v-if="mode !== 'form'" class="label-text has-text-grey">
      {{ setting.label || name }}
    </span>
    <el-select v-model="value[name]" multiple filterable class="select-field">
      <el-option
        v-for="item in setting.options"
        :key="getItemKey(item)"
        :label="getItemLabel(item)"
        :value="getItemValue(item)"
      >
        <span :style="{ float: 'left' }">{{ getItemLabel(item) }}</span>
        <span
          v-if="setting.displayValue"
          class="has-text-grey"
          :style="{
            float: 'right',
            fontSize: 13 + 'px',
            marginLeft: '1.5em',
          }"
          >{{ item.code ? item.code : item.name }}</span
        >
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: ["value", "setting", "name", "mode"],
  mounted() {
    if (this.value[this.name] == null) {
      if (this.setting.default != null) {
        this.$set(this.value, this.name, this.setting.default);
      } else {
        this.$set(this.value, this.name, null);
      }
    }
  },
  methods: {
    getItemLabel(item) {
      return item.name || item.label;
    },
    getItemKey(item) {
      return item.code || item.value;
    },
    getItemValue(item) {
      return item.code || item.value ? this.getItemKey(item) : this.getItemLabel(item);
    },
  },
};
</script>

<style scoped>
.flex-container {
  display: flex;
  align-items: flex-start;
}

.label-text {
  margin-left: 0.5em;
  margin-right: 3.5em;
  font-size: 14px;
}

.select-field {
  width: 100%;
}
</style>
