<template>
  <el-input
    v-model="searchTerm"
    style="margin-right: 10px; margin-bottom: 10px; max-width: 385px"
    placeholder="Input search term..."
    size="mini"
    clearable
    @keyup.native="onSearchTermChanged"
    @clear="onSearchTermChanged"
  >
    <template slot="append">
      <el-popover placement="bottom" title="Filter" trigger="click" width="300">
        <div>
          <el-select
            v-model="searchChatSources"
            placeholder="Filter by chat source"
            multiple
            style="width: 100%; margin-bottom: 10px"
            size="mini"
            filterable
            @change="onChatSourcesChanged"
          >
            <el-option
              v-for="source in chatSources"
              :key="source.value"
              :label="source.displayName"
              :value="source.value"
            ></el-option>
          </el-select>
        </div>
        <el-button
          :disabled="isLoading"
          slot="reference"
          size="mini"
          icon="el-icon-circle-plus-outline"
        >
          Filter
        </el-button>
      </el-popover>
    </template>
  </el-input>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "SearchChatFilters",
  props: {
    tab: String,
  },
  data() {
    return {
      searchTerm: "",
      searchChatSources: [],
    };
  },
  computed: {
    ...mapGetters(["fetchQueueChatLoading", "selectedChat", "selectedChatId", "isQueueTab"]),
    chatSources() {
      return [
        {
          displayName: "Facebook",
          value: "facebook",
        },
        {
          displayName: "Line",
          value: "line",
        },
        {
          displayName: "Telegram",
          value: "telegram",
        },
        {
          displayName: "Webchat",
          value: "webchat",
        },
        {
          displayName: "WeChat",
          value: "wechat",
        },
        {
          displayName: "WhatsApp",
          value: "whatsapp",
        },
        {
          displayName: "Workplace",
          value: "workplace",
        },
      ];
    },

    isLoading() {
      return this.fetchQueueChatLoading;
    },
  },
  methods: {
    onSearchTermChanged: _.debounce(function ($event) {
      this.$store.commit("SET_SELECTED_CHAT_ID", null);
      this.$store.commit("UPDATE_QUEUE_LIVECHAT_FILTERS", {
        chatSources: this.searchChatSources,
        searchTerm: this.searchTerm,
      });
    }, 500),
    onChatSourcesChanged() {
      this.$store.commit("SET_SELECTED_CHAT_ID", null);
      this.$store.commit("UPDATE_QUEUE_LIVECHAT_FILTERS", {
        chatSources: this.searchChatSources,
        searchTerm: this.searchTerm,
      });
    },
  },
};
</script>
