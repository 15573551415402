import { FlowEditorState } from "./types";

const state: FlowEditorState = {
  flows: {},
  fetchingFlows: false,
  contents: {},
  triggers: {},
};

export default state;
