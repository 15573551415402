<template>
  <div>
    <el-form-item style="margin-bottom: 0">
      <el-col style="margin-left: 10px; float: right">
        Match Mode:
        <el-switch v-model="isRegexMode" active-text="Advanced" inactive-text="Simple"></el-switch>
      </el-col>
    </el-form-item>
    <el-form-item prop="pattern">
      <template slot="label">
        {{ isRegexMode ? "Regex" : "Keywords" }}
        <el-tooltip content="Regular Expression" placement="top">
          <i class="el-icon-question has-text-grey" />
        </el-tooltip>
      </template>
      <el-input
        v-if="isRegexMode"
        v-model.trim="node.pattern"
        spellcheck="false"
        class="text-pattern"
        style="min-width: 250px; width: 75%"
        @blur="fixRegex"
        @input="render"
      />
      <el-row v-if="!isRegexMode">
        <el-tag
          :key="tag"
          v-for="tag in patternTags"
          closable
          :disable-transitions="false"
          style="margin-right: 10px"
          @close="handleCloseTag(tag)"
        >
          {{ tag }}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-if="inputVisible"
          v-model="inputValue"
          ref="saveTagInput"
          size="mini"
          style="min-width: 250px; width: 75%"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm"
        ></el-input>
        <el-button v-else class="button-new-tag" size="small" @click="showInput">
          + New Tag
        </el-button>
        <el-form-item label>
          <el-tooltip content="The matched word has to start with this" placement="left">
            <el-checkbox
              @change="updateRegex()"
              style="margin-left: 0; margin-right: 30px"
              v-model="startwith"
            >
              Start With
            </el-checkbox>
          </el-tooltip>
          <el-tooltip content="The matched word has to end with this" placement="right">
            <el-checkbox @change="updateRegex()" style="margin-left: 0" v-model="endWith">
              End With
            </el-checkbox>
          </el-tooltip>
          <el-checkbox style="margin-left: 0" @change="updateRegex()" v-model="caseInsensitive">
            Not Case Sensitive
          </el-checkbox>
        </el-form-item>
      </el-row>

      <el-alert v-if="error" show-icon :title="error" type="error" effect="dark"> </el-alert>

      <ul ref="warnings"></ul>
      <div ref="render">
        <div class="svg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:cc="http://creativecommons.org/ns#"
            xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
            version="1.1"
          ></svg>
        </div>
        <div class="progress">
          <div style="width: 0"></div>
        </div>
      </div>
    </el-form-item>
  </div>
</template>

<script>
import Parser from "./parser/javascript.js";
import util from "./util.js";

export default {
  props: ["node"],
  data() {
    return {
      caseInsensitive: true,
      startwith: true,
      endWith: true,

      isRegexMode: true,
      inputVisible: true,
      inputValue: "",

      parser: null,
      error: "",
      patternTags: [],
    };
  },
  computed: {},
  methods: {
    handleCloseTag(tag) {
      this.patternTags.splice(this.patternTags.indexOf(tag), 1);
      this.updateRegex();
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.patternTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
      this.updateRegex();
    },
    updateRegex() {
      let regex = "/";
      if (this.startwith) {
        regex += "^";
      } else {
        regex += "\\b";
      }

      if (this.patternTags.length > 0) {
        regex += "(";
        regex += this.patternTags.join("|");
        regex += ")";
      }

      if (this.endWith) {
        regex += "$";
      } else {
        regex += "\\b";
      }

      regex += "/";
      if (this.caseInsensitive) {
        regex += "i";
      }

      this.node.pattern = regex;
      this.render();
    },
    fixRegex() {
      if (!/^\//.test(this.node.pattern)) {
        this.node.pattern = "/" + this.node.pattern;
      }

      if (/^\/(:?\w|\W)+[^/|i]$/gim.test(this.node.pattern)) {
        this.node.pattern = this.node.pattern + "/i";
      }
    },
    render() {
      this.parser
        .parse(this.node.pattern)
        .then((parser) => {
          parser.render();
          this.error = "";
        })
        .catch((error) => {
          this.error = error;
        });
    },
  },
  mounted() {
    const element = this.$refs["render"];
    this.parser = new Parser(element, { keepContent: false });
    this.render();
  },
  watch: {
    node: function () {
      this.render();
    },
    trigger: function () {
      this.isRegexMode = true;
    },
  },
};
</script>

<style type="text/css">
svg {
  background-color: #fff;
}

.root text,
.root tspan {
  font: 12px Arial;
}

.root path {
  fill-opacity: 0;
  stroke-width: 2px;
  stroke: #000;
}

.root circle {
  fill: #6b6659;
  stroke-width: 2px;
  stroke: #000;
}

.anchor text,
.any-character text {
  fill: #fff;
}

.anchor rect,
.any-character rect {
  fill: #6b6659;
}

.escape text,
.charset-escape text,
.literal text {
  fill: #000;
}

.escape rect,
.charset-escape rect {
  fill: #bada55;
}

.literal rect {
  fill: #dae9e5;
}

.charset .charset-box {
  fill: #cbcbba;
}

.subexp .subexp-label tspan,
.charset .charset-label tspan,
.match-fragment .repeat-label tspan {
  font-size: 10px;
}

.repeat-label {
  cursor: help;
}

.subexp .subexp-label tspan,
.charset .charset-label tspan {
  dominant-baseline: text-after-edge;
}

.subexp .subexp-box {
  stroke: #908c83;
  stroke-dasharray: 6, 2;
  stroke-width: 2px;
  fill-opacity: 0;
}

.quote {
  fill: #908c83;
}
</style>
