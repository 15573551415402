<template>
  <div>
    <span
      style="margin-left: 1.5em; margin-right: 3em; fontsize: 14px; margin-bottom: 1em"
      class="has-text-grey"
      >{{ setting.label || name }}
    </span>

    <el-cascader
      v-model="value[name]"
      :options="setting.options"
      :props="setting.props"
      :collapse-tags="setting['collapse-tags']"
      :clearable="setting.clearable"
    >
      <template slot-scope="{ node, data }">
        <span>{{ data.label }}</span>
        <span
          class="has-text-grey"
          :style="{
            float: 'right',
            fontSize: 13 + 'px',
            marginLeft: '1.5em',
          }"
        >
          {{ data.value ? data.value : data.label }}
        </span>
      </template>
    </el-cascader>

    <Tooltip :label="setting.label || name" :content="setting.detail" />s
  </div>
</template>

<script>
import Tooltip from "../Tooltip.vue";
export default {
  components: { Tooltip },
  props: ["value", "setting", "name"],
  mounted() {
    if (this.value[this.name] == null) {
      if (this.setting.default != null) {
        this.$set(this.value, this.name, this.setting.default);
      } else {
        this.$set(this.value, this.name, null);
      }
    }
  },
};
</script>
