import { WhatsAppState } from "./types";

const state: WhatsAppState = {
  templates: [],
  application_settings: {},
  profile_settings: {},
  server_status: false,
  groups: [],
  servers: [],
};

export default state;
