import _ from "lodash";
import XLSX from "xlsx";
import moment from "moment";

const visitorRowsBuilder = (fields: any, visitors: { RowKey: string; Timestamp: any }[]) => {
  const ws_data: string[][] = [];
  ws_data.push(["ChatBot Visitors"]);
  ws_data.push([]);
  ws_data.push(["RowKey", "Last Seen", ..._.map(fields, (a) => a.displayValue)]);
  _.forEach(visitors, (visitor) => {
    const ws_head_row: string[] = [];
    ws_head_row.push(visitor.RowKey);
    ws_head_row.push(moment(visitor.Timestamp).format("DD/MM/YYYY:HH:mm:ss"));
    _.forEach(_.keys(fields), (field) => {
      const rowData = _.get(visitor, `${field}`, "");
      if (typeof rowData === "object") {
        ws_head_row.push(JSON.stringify(rowData));
      } else if (fields[field].dataType === "datetime" && rowData !== "") {
        ws_head_row.push(moment(rowData).format("DD/MM/YYYY"));
      } else {
        ws_head_row.push(rowData);
      }
    });
    ws_data.push(ws_head_row);
  });
  ws_data.push([]);

  return ws_data;
};

export function exportUsersToXLS(
  fields: any,
  visitors: { RowKey: string; Timestamp: any }[]
): XLSX.WorkBook {
  const wb = XLSX.utils.book_new();
  const ws_data = visitorRowsBuilder(fields, visitors);
  const ws = XLSX.utils.aoa_to_sheet(ws_data);
  XLSX.utils.book_append_sheet(wb, ws, "ChatBot Visitors");
  return wb;
}

export function exportInteractionsToXLS(fields: any, result: any, rowData: any): XLSX.WorkBook {
  const wb = XLSX.utils.book_new();
  _.forEach(_.keys(result), (userId: any) => {
    const visitor: { RowKey: string; Timestamp: any } = rowData[userId][0];
    const ws_data = visitorRowsBuilder(fields, [visitor]);

    ws_data.push(["Time", "Action", "Content"]);
    _.forEach(result[userId], (row: any) => {
      const ws_row: string[] = [];
      ws_row.push(moment(row.Timestamp).format("DD/MM/YYYY:HH:mm:ss"));
      ws_row.push(row.event);
      ws_row.push(row.data);
      ws_data.push(ws_row);
    });
    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    XLSX.utils.book_append_sheet(wb, ws, userId.slice(0, 31));
  });

  return wb;
}
