<template>
  <el-card
    v-loading="isDownloading"
    element-loading-text="Downloading..."
    style="max-height: 200px; height: 200px; min-height: 148px; text-align: left"
  >
    <el-row>
      <el-col>
        <h3 style="text-align: left; font-size: 16px; font-weight: 500; line-height: 26px">
          Training and Trigger Data Download
        </h3>
      </el-col>
    </el-row>
    <div style="margin-top: 16px">
      <p style="text-align: left; font-size: 14px; margin-bottom: 8px">Choose Date Range</p>
      <el-date-picker
        v-model="trainingData.date"
        type="daterange"
        range-separator="To"
        start-placeholder="Start date"
        end-placeholder="End date"
        format="dd/MM/yyyy"
        class="dashboard-date"
        style="margin-left: 0px"
      />
    </div>
    <el-row justify="start" style="margin-top: 16px">
      <el-button
        size="mini"
        class="button"
        icon="el-icon-download"
        @click="downloadTrainingData()"
        style="margin-top: 0px; font-size: 12px; float: left"
      >
        Training Data
      </el-button>
      <el-button
        size="mini"
        class="button"
        icon="el-icon-download"
        @click="downloadTriggerData()"
        style="margin-top: 0px; font-size: 12px; float: left"
      >
        Trigger Data
      </el-button>
    </el-row>
  </el-card>
</template>

<script>
import moment from "moment";
import XLSX from "xlsx";
import { mapGetters } from "vuex";
import { downloadArrayAsCSVFile } from "@/helpers/csvHelper";

export default {
  props: [],
  name: "DailyTrainingData",
  data() {
    return {
      isDownloading: false,
      trainingData: {
        date: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      },
    };
  },
  computed: {
    ...mapGetters(["getTrainingDownloadFileType"]),
  },
  methods: {
    downloadTrainingData() {
      this.isDownloading = true;
      const [startDate, endDate] = this.trainingData.date;
      this.$store
        .dispatch("GET_TRAINING_DATA", { startDate, endDate })
        .then((data) => {
          const wb = XLSX.utils.book_new();
          const ws_data = [];
          // const content = this.$store.state.nodes.content;
          ws_data.push([
            "RowKey",
            "PartitionKey",
            "archived",
            "prediction",
            "question",
            "user_selected_option",
            "confidence_level",
            "user_id",
            "prediction2",
            "confidence_level2",
            "prediction_answer",
            "appSource",
            "role",
            "date",
            "query_type",
            "userQueryTopic",
            "user_rating",
            "user_input",
            "custom_id",
          ]);
          _.map(data, (entry) => {
            const row = [];
            const omittedKeys = _.omit(entry, [
              "last_modified",
              "updated",
              "createdDate",
              "ref",
              "userQueryTopic",
              "userRating",
              "queryType",
            ]);
            if (!omittedKeys.role) {
              omittedKeys.role = "UND";
            }
            _.each(omittedKeys, (v, k) => {
              row.push(v);
            });
            try {
              row.push(moment(entry.last_modified).format());
              // row.push(private_variables.appSource);
              // row.push(private_variables.role);
              row.push(entry.queryType || "");
              row.push(entry.userQueryTopic || "");
              row.push(entry.userRating || "");
              row.push(entry.userlabel || "");
              row.push(entry.userid || "");
            } catch (e) {
              console.error("private_variables parsing failed");
            }
            // if (entry.prediction){
            //   const answer_content = content[entry.prediction];
            //   if (answer_content){
            //     row.push(JSON.stringify(_.get(answer_content, "content.text")));
            //   }
            // }
            ws_data.push(row);
          });
          const fileType = this.getTrainingDownloadFileType;
          const filename = this.$exportFilename(
            `training_data (${startDate}-${endDate})`,
            fileType
          );
          if (fileType === "XLSX") {
            const ws = XLSX.utils.aoa_to_sheet(ws_data);
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            XLSX.writeFile(wb, filename, {});
          } else if (fileType === "CSV") {
            downloadArrayAsCSVFile(ws_data, filename);
          }
        })
        .finally(() => {
          this.isDownloading = false;
        });
    },
    downloadTriggerData() {
      this.isDownloading = true;
      const [startDate, endDate] = this.trainingData.date;
      this.$store
        .dispatch("GET_TRIGGER_DATA", { startDate, endDate })
        .then((data) => {
          const wb = XLSX.utils.book_new();
          const ws_data = [];

          ws_data.push([
            "RowKey",
            "PartitionKey",
            "text",
            "trigger",
            "source",
            "user_id",
            "context",
            "appSource",
            "role",
            "date",
          ]);
          _.forEach(data, (entry) => {
            const row = [];

            _.each(entry, (v, k) => {
              if (k === "date") {
                row.push(moment(v).format());
              } else {
                row.push(v);
              }
            });

            ws_data.push(row);
          });

          const ws = XLSX.utils.aoa_to_sheet(ws_data);
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

          const filename = this.$exportFilename(`trigger_data (${startDate}-${endDate})`, "xlsx");
          XLSX.writeFile(wb, filename, {});

          this.isDownloading = false;
        })
        .finally(() => {
          this.isDownloading = false;
        });
    },
  },
};
</script>
