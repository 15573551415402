<template>
  <el-row>
    <el-col :span="16">
      <el-form :model="node.content">
        <transition-group name="section-list">
          <div v-for="(contact, index) in node.content.options.contacts" :key="index + 1">
            <WhatsAppContactItem @deleteContact="deleteContact" :contact="contact" :index="index" />
          </div>
        </transition-group>

        <div style="margin: 10px 0">
          <el-button type="primary" @click="addContact">Add Contact</el-button>
        </div>
      </el-form>
    </el-col>
  </el-row>
</template>
<script>
import WhatsAppContactItem from "@/components/WhatsAppContactItem";

export default {
  name: "WhatsAppContactMessage",
  components: {
    WhatsAppContactItem,
  },
  props: ["node", "editable"],
  data() {
    return {};
  },
  computed: {},
  methods: {
    addContact() {
      this.node.content.options.contacts.push({
        addresses: [],
        birthday: "",
        emails: [],
        name: {
          formatted_name: "",
        },
        org: {},
        phones: [],
        urls: [],
      });
    },
    deleteContact(index) {
      this.$confirm("Are you sure you want to delete this contact? ", "Confirmation", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        cancelButtonClass: "section-delete-btn-cancel",
        confirmButtonClass: "section-delete-btn-confirm",
        type: "warning",
      }).then(() => {
        this.node.content.options.contacts.splice(index, 1);
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "../assets/scss/colors.scss";

.interactive-message-section {
  border: 1px solid $color-light;
  padding: 0px 25px;
  padding-top: 25px;
  padding-bottom: 15px;
  margin: 10px 0;
  border-radius: 5px;
  position: relative;
}

.section-delete-button {
  position: absolute;
  right: 6px;
  top: 6px;
  border: 0;
  padding: 0;
  background: #fff;
  font-size: 16px;
  color: #333;
}
.section-delete-button:hover {
  color: $color-danger;
}

.section-list-enter-active,
.section-list-leave-active {
  transition: all 0.5s ease;
}
.section-list-enter-from,
.section-list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
