<template>
  <div>
    <el-steps :space="200" :active="active" simple style="cursor: pointer">
      <el-step
        icon="el-icon-tickets"
        @click.native="active = 1"
        title="Raw Data"
        description="Data returned from the external API."
      >
      </el-step>
      <el-step
        icon="el-icon-search"
        @click.native="active = 2"
        title="Data Transformation"
      ></el-step>
      <el-step
        icon="el-icon-document-checked"
        @click.native="active = 3"
        title="Final Result"
      ></el-step>
    </el-steps>
    <JSONEditor v-if="active === 1" ref="jsonEditor" :value="previewData.rawData" />
    <JSONEditor
      v-else-if="active === 2"
      ref="jsonEditor"
      :value="previewData.searchExpressedData"
    />
    <JSONEditor v-else-if="active === 3" ref="jsonEditor" :value="previewData.finalData" />
  </div>
</template>

<script>
import JSONEditor from "@/components/JSONEditor";

export default {
  components: {
    JSONEditor,
  },
  props: ["previewData"],
  data() {
    return {
      active: 1,
    };
  },
};
</script>

<style scoped>
>>> .CodeMirror {
  height: 60vh;
  font-size: 13px;
}
>>> .el-step.is-simple .el-step__icon {
  margin-top: 10px;
}
</style>
