<template>
  <el-main>
    <el-row>
      <el-date-picker
        v-model="datePicked"
        type="daterange"
        range-separator="To"
        start-placeholder="Start date"
        end-placeholder="End date"
        format="dd/MM/yyyy"
        :picker-options="datePickerOptions"
        :default-time="['00:00:00', '00:00:00']"
      />
      <el-select
              v-model="selectedTemplateIds"
              placeholder="Select templates"
              @visible-change="handleViewTemplateChange"
              @change="isSelectedTemplateIdsChanged = true"
              @remove-tag="handleItemRemoved"
              filterable
              collapse-tags
              collapse-tags-tooltip
              :multiple-limit="10"
              style="width: 300px; margin-left: 15px;"
              multiple
            >
              <el-option
                v-for="item in uniqueMessageTemplates"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
    </el-row>
    <div v-loading="loadingCharts">
      <LineChart :labels="messagesLabels" :datasets="messagesDataset" :height="150" :colors="['#71cdf7', '#06abf1']" />
      <LineChart :labels="amountSpentLabels" :datasets="amountSpentDataset" :height="150" :colors="['#85c212']"/>
    </div>
    <div v-loading="loadingTemplateTable">
      <el-table :data="templateMessageAnalytics" stripe style="width: 100%">
        <el-table-column prop="template_id" label="Template ID" width="180" />
        <el-table-column prop="name" label="Template name" width="280" />
        <el-table-column prop="sent" label="Sent" width="100"/>
        <el-table-column prop="delivered" label="Delivered" width="100"/>
        <el-table-column prop="read" label="Read" width="100"/>
        <el-table-column label="Clicked" >
          <template slot-scope="scope">
            <div v-if="scope.row.clicked !== undefined">
              <div v-for="item in scope.row.clicked" style="margin-bottom: 10px;">
                Type: {{ item.type }}<br />
                Button content: {{ item.button_content }}<br />
                Count: {{ item.count }}
              </div>
            </div>
            <div v-else>
              No Clicked data
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-main>
</template>
<script>
import moment from "moment";
import LineChart from "@/components/LineChart";

export default {
  components: {
    LineChart,
  },
  name: "AnalyticsTab",
  props: {
    wa_id: String,
    wa_endpoint: String,
    messageTemplates: Array,
  },
  data() {
    return {
      loadingCharts: false,
      loadingTemplateTable: false,
      amountSpentLabels: [],
      amountSpentDataset: [],
      messagesLabels: [],
      messagesDataset: [],
      datePicked: [moment().utc().subtract(1, "month"), moment().utc()],
      selectedTemplateIds: [],
      isSelectedTemplateIdsChanged: false,
      templateMessageAnalytics: [],
      datePickerOptions: {
        shortcuts: [
          {
            text: "Today",
            onClick(picker) {
              const start = new Date();
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Yesterday",
            onClick(picker) {
              const start = new Date();
              start.setHours(-24);
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last week",
            onClick(picker) {
              const start = new Date();
              start.setDate(start.getDate() - 7);
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last month",
            onClick(picker) {
              const start = new Date();
              start.setMonth(start.getMonth() - 1);
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 3 months",
            onClick(picker) {
              const start = new Date();
              start.setMonth(start.getMonth() - 3);
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 6 months",
            onClick(picker) {
              const start = new Date();
              start.setMonth(start.getMonth() - 6);
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last Year",
            onClick(picker) {
              const start = new Date();
              start.setMonth(start.getMonth() - 12);
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "All-Time",
            onClick(picker) {
              const start = new Date();
              start.setFullYear(1990, 1, 1);
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              end.setHours(23, 59, 59, 999);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  computed: {
    uniqueMessageTemplates() {
      return _.sortedUniqBy(this.messageTemplates, (o) => o.name) || [];
    },
  },
  methods: {
    updateAnalyticsData(start, end) {
      this.loadingCharts = true;
      this.$store
        .dispatch("GET_ACCOUNT_ANALYTICS", {
          data: {
            start,
            end,
            phone_number: this.wa_id,
            wa_endpoint: this.wa_endpoint,
          },
        })
        .then((data) => {
          this.loadingCharts = false;
          this.messagesLabels = data.map((obj) => obj.start * 1000);
          this.amountSpentLabels = data.map((obj) => obj.start * 1000);
          const messagesSent = {
            label: "Messages Sent",
            backgroundColor: "#06f5fb",
            data: _.map(data, (obj) => obj.sent),
          };
          const messagesDelivered = {
            label: "Messages Delivered",
            backgroundColor: "#1f6ff6",
            data: _.map(data, (obj) => obj.delivered),
          };

          const amountSpent = {
            label: "Amount Spent",
            backgroundColor: "#ffa64d",
            data: _.map(data, (obj) => (obj.sent * 0.05).toFixed(2)),
          };
          this.messagesDataset = [messagesSent, messagesDelivered];
          this.amountSpentDataset = [amountSpent];
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    updateMessageTemplateAnalyticsData(start, end, templateIds) {
      if (this.selectedTemplateIds.length == 0) {
        this.templateMessageAnalytics = [];
        return;
      };

      this.loadingTemplateTable = true;
      this.isSelectedTemplateIdsChanged = false;
      this.$store.dispatch("GET_MESSAGE_TEMPLATE_ANALYTICS", {
        data: {
          start,
          end,
          template_ids: templateIds,
        },
      }).then((data) => {
        this.loadingTemplateTable = false;
        this.templateMessageAnalytics = data.map(item => {
          return {
            ...item,
            name: _.find(this.messageTemplates, (template) => template.id === item.template_id).name,
          }
        });
      }).catch((err) => {
        console.log(err.message);
      });
    },
    getStartAndEndFromDatePicked(datePicked) {
      const timezone = this.$store.state.modules.whatsapp.timezone
        ? this.$store.state.modules.whatsapp.timezone
        : 8;

      const cloneStart = new Date(_.cloneDeep(datePicked[0]));
      const cloneEnd = new Date(_.cloneDeep(datePicked[1]));
      cloneStart.setMinutes(
        -cloneStart.getTimezoneOffset() - cloneStart.getMinutes() - timezone * 60
      );

      cloneEnd.setDate(cloneEnd.getDate() + 1);
      cloneEnd.setHours(0, 0, 0, 0);
      cloneEnd.setMinutes(-cloneEnd.getTimezoneOffset() - cloneEnd.getMinutes() - timezone * 60);
      const start = Math.floor(cloneStart.getTime() / 1000);
      const end = Math.floor(cloneEnd.getTime() / 1000);

      return { start, end };
    },
    handleViewTemplateChange(val) {
      if (val) return;

      const {start, end} = this.getStartAndEndFromDatePicked(this.datePicked);

      this.isSelectedTemplateIdsChanged && this.updateMessageTemplateAnalyticsData(start, end, this.selectedTemplateIds);
    },
    handleItemRemoved(val) {
      this.templateMessageAnalytics = this.templateMessageAnalytics.filter(item => item.template_id !== val);
    }
  },
  mounted() {
    const timezone = this.$store.state.modules.whatsapp.timezone
      ? this.$store.state.modules.whatsapp.timezone
      : 8;
    const start = this.datePicked[0].startOf("day").subtract(timezone, "hours").unix();
    const end = this.datePicked[1].add(1, "day").startOf("day").subtract(timezone, "hours").unix();
    this.updateAnalyticsData(start, end, this.filterParams);
  },
  watch: {
    datePicked(newDateArr) {
      const {start, end} = this.getStartAndEndFromDatePicked(newDateArr);
      this.updateAnalyticsData(start, end, this.filterParams);
      this.updateMessageTemplateAnalyticsData(start, end, this.selectedTemplateIds);
    },
  },
};
</script>
