import m from "moment";
import _ from "lodash";
import { getChannelSpecificDisplayId, getDisplayId } from "@/helperMethods/livechat/util";
import store from "@/store/index";
import string2template from "./string2template";
import { convertRegexStringToRegex } from "@/helperMethods/import_export/transformations";

/**
 * @description Get chat name for chats in dashboard
 * @param {any} chat
 * @return {string}
 */

export function getChatName(chat: any) {
  const settingsAssignedChatTitle: string = _.get(store, "state.modules.handover.chatTitle", null);

  if (settingsAssignedChatTitle) {
    const chatTitle = string2template(settingsAssignedChatTitle, chat.stateVariables);
    const isValidChatTitle =
      chatTitle.indexOf("undefined") === -1 && chatTitle.indexOf("null") === -1;

    if (isValidChatTitle) {
      return chatTitle;
    }
  }

  const channelSpecificId = getChannelSpecificDisplayId({
    ...chat,
    ...chat.stateVariables,
  });

  const { alias, name } = chat.userDetails ?? { alias: false, name: false };

  if (alias && channelSpecificId) {
    return `(${alias}) ${channelSpecificId}`;
  }

  if (channelSpecificId) {
    return channelSpecificId;
  }

  if (alias) {
    return alias;
  }

  if (name) {
    return name;
  }

  return getDisplayId({
    ...chat.stateVariables,
    chatId: chat.RowKey,
    user_id: chat.user_id,
  });
}

export function getTagName(variables) {
  const settingsAssignedChatTag = _.get(store, "state.modules.handover.chatTag", null);
  const result = string2template(settingsAssignedChatTag, variables);
  const showUnknownValueTag = _.get(store, "state.modules.handover.showUnknownValueTag", false);
  if (!showUnknownValueTag) {
    return;
  }
  if (!result) {
    return "N/A";
  } else if (result.includes("undefined") || result.includes("null")) {
    const transformed = result.replace(/undefined/g, "N/A").replace(/null/g, "N/A");
    return transformed;
  }
  return result;
}

export function tagType(tagName: string): string | undefined {
  const chatTagCustoms = _.get(store, "state.modules.handover.chatTagCustoms", []);
  const matched = chatTagCustoms.find((pattern) =>
    (convertRegexStringToRegex(pattern.regex) || /^$/).test(tagName)
  );

  if (!matched) {
    return;
  }
  if (matched.color === "green") {
    return "success";
  } else if (matched.color === "red") {
    return "danger";
  } else if (matched.color === "yellow") {
    return "warning";
  }
  return;
}

export function toCamelCase(text: string) {
  return text
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word: string, index: number) {
      return index == 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}

export function deCamelCase(text: string) {
  return text
    .replace(/([a-z\d])([A-Z])/g, "$1" + " " + "$2")
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, "$1" + " " + "$2")
    .toLowerCase();
}

export function toSlug(text: string) {
  text = text.replace(/^\s+|\s+$/g, ""); // trim
  text = text.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  const to = "aaaaeeeeiiiioooouuuunc------";
  for (let i = 0, l = from.length; i < l; i++) {
    text = text.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  text = text
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return text;
}

export function host(url: string) {
  const host = url.replace(/^https?:\/\//, "").replace(/\/.*$/, "");
  const parts = host.split(".").slice(-3);
  if (parts[0] === "www") parts.shift();
  return parts.join(".");
}

export function moment(timestamp: string, format: string): string {
  if (Number(timestamp)) {
    return m.unix(Number(timestamp) / 1000).format(format);
  } else {
    return m(timestamp).format(format);
  }
}

export function fromHour(timestamp: string, hour: number = 0): string {
  if (Number(timestamp)) {
    return m.unix(Number(timestamp) / 1000).fromNow();
  } else {
    return m(timestamp).hour(hour).fromNow();
  }
}

export function fromNow(timestamp: string): string {
  if (Number(timestamp)) {
    return m.unix(Number(timestamp) / 1000).fromNow();
  } else {
    return m(timestamp).fromNow();
  }
}

export function trim(text: string, length: number = 80): string {
  if (text) {
    if (text.length > length) {
      return _.truncate(text, {
        length: length,
        separator: " ",
      });
    }
  }

  return text.trim();
}

export function capitalize(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function prettyJSON(json: object): string {
  if (json) {
    let jsonString: string = JSON.stringify(json, undefined, 4);
    jsonString = jsonString.replace(/&/g, "&").replace(/</g, "<").replace(/>/g, ">");
    const expression: RegExp =
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g;

    return jsonString.replace(expression, (match: string) => {
      let cls: string = "number";
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = "key";
        } else {
          cls = "string";
        }
      } else if (/true|false/.test(match)) {
        cls = "boolean";
      } else if (/null/.test(match)) {
        cls = "null";
      }

      return `<span class="${cls}">${match}</span>`;
    });
  }

  return "";
}

export function wrapURL(text) {
  const urlPattern =
    /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\z`!()\[\]{};:'".,<>?«»“”‘’]))/gi;

  // Check string for any url patterns and wrap them in anchor tags
  const result = text.replace(urlPattern, function (url) {
    return `<a style="color: #1D57D8"  target="blank" href="${url.trim()}">${url.trim()}</a>`;
  });

  return result;
}

export default {
  host,
  moment,
  wrapURL,
  fromHour,
  prettyJSON,
  capitalize,
  trim,
  fromNow,
  getChatName,
};
