<template>
  <el-popover
    v-if="hasAnyLivechatStatusMonitorSettingEnabled"
    v-model="popoverIsOpen"
    :disabled="isConnecting"
    trigger="click"
    placement="bottom"
    popperClass="livechat-monitor-popover"
    :width="isShowAdvanced ? '800' : '550'"
  >
    <el-tag
      style="margin-right: 8px"
      :class="{
        'cursor-pointer': !hasNoAgentConnected,
        'cursor-not-allowed': hasNoAgentConnected,
      }"
      :type="hasNoAgentConnected || isBusy ? 'info' : 'success'"
      slot="reference"
      effect="plain"
    >
      <template v-if="isBusy">Connecting...</template>
      <template v-else>
        <strong>{{
          `${agentsConnection.filter((a) => a.status === "ONLINE").length} / ${
            agentsConnection.length === 0 ? 1 : agentsConnection.length
          }`
        }}</strong>
        {{ $device.Mobile ? "" : "Agents" }} Online
      </template>
    </el-tag>

    <el-tabs type="border-card">
      <el-tab-pane v-if="isShowAgentsStatusSettingsEnabled">
        <span slot="label"> <i class="el-icon-user"></i> Agents Status </span>
        <AgentsStatusTable :popover-is-open="popoverIsOpen" :agents-connection="agentsConnection" />
      </el-tab-pane>
      <el-tab-pane v-if="hasAnyChatStatusSettingsEnabled">
        <span slot="label"> <i class="el-icon-data-analysis"></i> Chat Status (today) </span>
        <ChatsStatusTable
          :popover-is-open="popoverIsOpen"
          :has-any-chat-status-settings-enabled="hasAnyChatStatusSettingsEnabled"
          :queued-chats="queuedChats"
          :agents-connection="agentsConnection"
        />
      </el-tab-pane>
    </el-tabs>
  </el-popover>
</template>

<script>
import _ from "lodash";

// Components
import AgentsStatusTable from "./AgentsStatusTable";
import ChatsStatusTable from "./ChatsStatusTable";

export default {
  components: {
    AgentsStatusTable,
    ChatsStatusTable,
  },
  name: "AgentsConnectionStatus",
  data() {
    return {
      agentsConnection: [],
      popoverIsOpen: false,
    };
  },
  props: {
    queuedChats: {
      default: Array,
    },
    isConnecting: {},
  },
  mounted() {
    this.fetchUsers();
    this.fetchDepartments();
  },
  methods: {
    /**
     * @description Fetch all departments
     * @return {void}
     */
    fetchDepartments() {
      this.$store.dispatch("FETCH_DEPARTMENTS");
    },

    /**
     * @description Fetch all users with their departments
     * @return {void}
     */
    fetchUsers() {
      // Fetch connected agents from sockets
      this.$store.dispatch("FETCH_AGENTS");

      // Fetch auth0 agents list
      if (this.isHandoverRoutingByDepartmentEnabled && _.isEmpty(this.allAgents)) {
        this.$store.dispatch("FETCH_AUTH_USERS", { fromLivechat: true }).then(() => {
          this.applyStatus();
        });
      }
    },

    /**
     * @description Apply agents connection status
     * @return {void}
     */
    applyStatus: _.throttle(
      function () {
        const agentList = this.connectedAgents.filter((agent) => {
          // If no department assigned, can see all
          const isProfileNoDepartment = _.isEmpty(this.profileDepartments);
          const isProfileDepartmentOnlyGeneral =
            this.profileDepartments?.length === 1 && this.profileDepartments?.includes("general");

          if (
            !this.isHandoverRoutingByDepartmentEnabled ||
            isProfileNoDepartment ||
            isProfileDepartmentOnlyGeneral
          ) {
            return true;
          }

          const departmentsOverlap = _.intersection(agent.departments, this.profileDepartments);
          const agentCanHandleDepartment = departmentsOverlap.length > 0;

          return agentCanHandleDepartment;
        });

        // const updatedAgentList = _.concat(, unResolvedChats);
        const orderedList = _.orderBy(agentList, ["departments"], ["asc"]);

        this.agentsConnection = orderedList;
      },
      1500,
      { leading: true }
    ),
  },
  computed: {
    /**
     * @description Get current logged in agent's departments
     * @return {string[]}
     */
    profileDepartments() {
      return _.get(this.$store, "state.profile.app_metadata.departments", []);
    },

    /**
     * @description Is handover by department enabled
     * @return {boolean}
     */
    isHandoverRoutingByDepartmentEnabled() {
      return _.get(this.$store.state, "modules.handover.handoverRouting.byDepartment", false);
    },

    /**
     * @description get all users
     */
    allAgents() {
      return _.get(this.$store, "getters.users", []);
    },

    /**
     * @description isShowAdvanced getters from vuex state
     * @return {boolean}
     */
    isShowAdvanced() {
      return _.get(this.$store, "getters.isShowAdvanced", false);
    },

    /**
     * @description Connecting / Recovering ws connection
     * @return {boolean}
     */
    isBusy() {
      return this.isConnecting;
    },

    /**
     * @description Has show agents status settings enabled
     * @return {boolean}
     */
    isShowAgentsStatusSettingsEnabled() {
      return _.get(
        this.$store.state,
        "modules.handover.livechatStatusMonitor.showAgentsStatus",
        false
      );
    },

    /**
     * @description Has any chat status settings enabled
     * @return {boolean}
     */
    hasAnyChatStatusSettingsEnabled() {
      const isShowAbandonedChatsCount = _.get(
        this.$store.state,
        "modules.handover.livechatStatusMonitor.showAbandonedChatsCount",
        false
      );

      const isShowAttendingChatsCount = _.get(
        this.$store.state,
        "modules.handover.livechatStatusMonitor.showAttendingChatsCount",
        false
      );

      const isShowQueuedChatsCount = _.get(
        this.$store.state,
        "modules.livechatStatusMonitor.showQueuedChatsCount",
        false
      );

      return isShowQueuedChatsCount || isShowAttendingChatsCount || isShowAbandonedChatsCount;
    },

    /**
     * @description Has any livechat status monitor settings enabled
     * @return {boolean}
     */
    hasAnyLivechatStatusMonitorSettingEnabled() {
      return this.hasAnyChatStatusSettingsEnabled || this.isShowAgentsStatusSettingsEnabled;
    },

    /**
     * @description Check if no agent connected or logged in to the dashboard
     * @return {boolean}
     */
    hasNoAgentConnected() {
      return _.isEmpty(this.connectedAgents);
    },

    /**
     * @description Connected agents includes all status
     * @return {any[]}
     */
    connectedAgents() {
      const result = _.get(this.$store.getters, "getConnectedAgents", []);
      return result;
    },

    /**
     * @description Get email array of connected agents ONLINE/OFFLINE
     * @return {string[]}
     */
    connectedAgentsEmail() {
      return this.connectedAgents.map((agent) => agent.email);
    },
  },
  watch: {
    connectedAgents: {
      deep: true,
      handler(updatedConnectedAgents, oldConnectedAgents) {
        const isConnectedAgentsUpdated = updatedConnectedAgents !== oldConnectedAgents;
        if (isConnectedAgentsUpdated) {
          this.applyStatus();
        }
      },
    },
    queuedChats: {
      deep: true,
      handler(updatedQueuedChats, oldQueuedChats) {
        this.applyStatus();
      },
    },
  },
};
</script>

<style>
.livechat-monitor-popover {
  padding: 0 !important;
  border: 0 !important;
}
</style>
