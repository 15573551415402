import _ from "lodash";
import moment from "moment";
import LocalStorageManager from "@/localStorageManager";
import store from "@/store";
import { logout } from "@/helperMethods/auth";

export function resetAutoSessionLocalStorage() {
  LocalStorageManager.removeItem("startAutoSession");
  LocalStorageManager.removeItem("autoSessionTimeout");
  LocalStorageManager.removeItem("sessionTimeoutDuration");
  LocalStorageManager.removeItem("loginTime");
  LocalStorageManager.removeItem("numberOfTabs");
  LocalStorageManager.removeItem("idleTabs");
}

export function setAutoSessionLocalStorage() {
  setStartAutoSession();
  setAutoSessionConfig();
}

export function toMinutes(value, unit) {
  switch (unit) {
    case "days":
      return value * 24 * 60;
    case "hours":
      return value * 60;
    default:
      return value;
  }
}

export function setAutoSessionConfig() {
  const sessionTimeoutEnabled = _.get(
    store,
    "state.modules.system.sessionTimeoutConfig.enabled",
    false
  );
  LocalStorageManager.setItem("autoSessionTimeout", sessionTimeoutEnabled);

  const sessionTimeoutDuration = _.get(
    store,
    "state.modules.system.sessionTimeoutConfig.duration.time",
    15
  );
  const sessionTimeoutDurationUnit = _.get(
    store,
    "state.modules.system.sessionTimeoutConfig.duration.unit",
    "minutes"
  );
  LocalStorageManager.setItem(
    "sessionTimeoutDuration",
    toMinutes(sessionTimeoutDuration, sessionTimeoutDurationUnit)
  );

  const sessionTimeoutType = _.get(
    store,
    "state.modules.system.sessionTimeoutConfig.type",
    "lastActivity"
  );
  LocalStorageManager.setItem("sessionTimeoutType", sessionTimeoutType);
}

export function setStartAutoSession() {
  const startAutoSession = Date.now();
  LocalStorageManager.setItem("startAutoSession", startAutoSession);
}

export function setLoginTime() {
  const loginTime = Date.now();
  LocalStorageManager.setItem("loginTime", loginTime);
}

export function checkAutoSessionLocalStorage() {
  const sessionTimeoutEnabled = LocalStorageManager.getItem("autoSessionTimeout");
  if (sessionTimeoutEnabled !== "true") {
    return;
  }

  const sessionTimeoutType = LocalStorageManager.getItem("sessionTimeoutType");
  let startAutoSession;
  if (sessionTimeoutType === "lastActivity") {
    startAutoSession = parseInt(LocalStorageManager.getItem("startAutoSession") as string);
  } else if (sessionTimeoutType === "loginTime") {
    startAutoSession = parseInt(LocalStorageManager.getItem("loginTime") as string);
  } else {
    return;
  }

  // checking startAutoSession exists and a number type to continue
  if (isNaN(startAutoSession)) {
    return;
  }
  const start = moment(startAutoSession);

  let sessionTimeoutDuration = parseInt(
    LocalStorageManager.getItem("sessionTimeoutDuration") as string
  );
  if (isNaN(sessionTimeoutDuration)) {
    sessionTimeoutDuration = 15;
  }
  const expired = start.add(sessionTimeoutDuration, "minutes");
  const now = moment(Date.now());
  const sessionIsExpired = now.isAfter(expired);

  return sessionIsExpired;
}

export function onInactiveSession() {
  resetAutoSessionLocalStorage();
  logout();
}

export function addTab(name) {
  let noOfTabs = parseInt(LocalStorageManager.getItem(name) as string);
  if (isNaN(noOfTabs)) {
    noOfTabs = 1;
  } else {
    noOfTabs++;
  }
  LocalStorageManager.setItem(name, noOfTabs);
}

export function removeTab(name) {
  let noOfTabs = parseInt(LocalStorageManager.getItem(name) as string);
  if (isNaN(noOfTabs)) {
    noOfTabs = 0;
  } else {
    noOfTabs--;
    if (noOfTabs < 0) {
      noOfTabs = 0;
    }
  }
  LocalStorageManager.setItem(name, noOfTabs);
}
