export const COUNTRY_LIST = [
  { link: "ab", value: "Abkhaz", nativeName: "аҧсуа" },
  { link: "aa", value: "Afar", nativeName: "Afaraf" },
  { link: "af", value: "Afrikaans", nativeName: "Afrikaans" },
  { link: "ak", value: "Akan", nativeName: "Akan" },
  { link: "sq", value: "Albanian", nativeName: "Shqip" },
  { link: "am", value: "Amharic", nativeName: "አማርኛ" },
  { link: "ar", value: "Arabic", nativeName: "العربية" },
  { link: "an", value: "Aragonese", nativeName: "Aragonés" },
  { link: "hy", value: "Armenian", nativeName: "Հայերեն" },
  { link: "as", value: "Assamese", nativeName: "অসমীয়া" },
  { link: "av", value: "Avaric", nativeName: "авар мацӀ, магӀарул мацӀ" },
  { link: "ae", value: "Avestan", nativeName: "avesta" },
  { link: "ay", value: "Aymara", nativeName: "aymar aru" },
  { link: "az", value: "Azerbaijani", nativeName: "azərbaycan dili" },
  { link: "bm", value: "Bambara", nativeName: "bamanankan" },
  { link: "ba", value: "Bashkir", nativeName: "башҡорт теле" },
  { link: "eu", value: "Basque", nativeName: "euskara, euskera" },
  { link: "be", value: "Belarusian", nativeName: "Беларуская" },
  { link: "bn", value: "Bengali", nativeName: "বাংলা" },
  { link: "bh", value: "Bihari", nativeName: "भोजपुरी" },
  { link: "bi", value: "Bislama", nativeName: "Bislama" },
  { link: "bs", value: "Bosnian", nativeName: "bosanski jezik" },
  { link: "br", value: "Breton", nativeName: "brezhoneg" },
  { link: "bg", value: "Bulgarian", nativeName: "български език" },
  { link: "my", value: "Burmese", nativeName: "ဗမာစာ" },
  { link: "ca", value: "Catalan", nativeName: "Català" },
  { link: "ch", value: "Chamorro", nativeName: "Chamoru" },
  { link: "ce", value: "Chechen", nativeName: "нохчийн мотт" },
  { link: "ny", value: "Chichewa", nativeName: "chiCheŵa, chinyanja" },
  { link: "zh", value: "Chinese", nativeName: "中文 (Zhōngwén), 汉语, 漢語" },
  { link: "cv", value: "Chuvash", nativeName: "чӑваш чӗлхи" },
  { link: "kw", value: "Cornish", nativeName: "Kernewek" },
  { link: "co", value: "Corsican", nativeName: "corsu, lingua corsa" },
  { link: "cr", value: "Cree", nativeName: "ᓀᐦᐃᔭᐍᐏᐣ" },
  { link: "hr", value: "Croatian", nativeName: "hrvatski" },
  { link: "cs", value: "Czech", nativeName: "česky, čeština" },
  { link: "da", value: "Danish", nativeName: "dansk" },
  { link: "dv", value: "Divehi", nativeName: "ދިވެހި" },
  { link: "nl", value: "Dutch", nativeName: "Nederlands, Vlaams" },
  { link: "en", value: "English", nativeName: "English" },
  { link: "eo", value: "Esperanto", nativeName: "Esperanto" },
  { link: "et", value: "Estonian", nativeName: "eesti, eesti keel" },
  { link: "ee", value: "Ewe", nativeName: "Eʋegbe" },
  { link: "fo", value: "Faroese", nativeName: "føroyskt" },
  { link: "fj", value: "Fijian", nativeName: "vosa Vakaviti" },
  { link: "fi", value: "Finnish", nativeName: "suomi, suomen kieli" },
  { link: "fr", value: "French", nativeName: "français, langue française" },
  { link: "ff", value: "Fula", nativeName: "Fulfulde, Pulaar, Pular" },
  { link: "gl", value: "Galician", nativeName: "Galego" },
  { link: "ka", value: "Georgian", nativeName: "ქართული" },
  { link: "de", value: "German", nativeName: "Deutsch" },
  { link: "el", value: "Greek", nativeName: "Ελληνικά" },
  { link: "gn", value: "Guaraní", nativeName: "Avañeẽ" },
  { link: "gu", value: "Gujarati", nativeName: "ગુજરાતી" },
  { link: "ht", value: "Haitian", nativeName: "Kreyòl ayisyen" },
  { link: "ha", value: "Hausa", nativeName: "Hausa, هَوُسَ" },
  { link: "he", value: "Hebrew (modern)", nativeName: "עברית" },
  { link: "hz", value: "Herero", nativeName: "Otjiherero" },
  { link: "hi", value: "Hindi", nativeName: "हिन्दी, हिंदी" },
  { link: "ho", value: "Hiri Motu", nativeName: "Hiri Motu" },
  { link: "hu", value: "Hungarian", nativeName: "Magyar" },
  { link: "ia", value: "Interlingua", nativeName: "Interlingua" },
  { link: "id", value: "Indonesian", nativeName: "Bahasa Indonesia" },
  {
    link: "ie",
    value: "Interlingue",
    nativeName: "Originally called Occidental; then Interlingue after WWII",
  },
  { link: "ga", value: "Irish", nativeName: "Gaeilge" },
  { link: "ig", value: "Igbo", nativeName: "Asụsụ Igbo" },
  { link: "ik", value: "Inupiaq", nativeName: "Iñupiaq, Iñupiatun" },
  { link: "io", value: "Ido", nativeName: "Ido" },
  { link: "is", value: "Icelandic", nativeName: "Íslenska" },
  { link: "it", value: "Italian", nativeName: "Italiano" },
  { link: "iu", value: "Inuktitut", nativeName: "ᐃᓄᒃᑎᑐᑦ" },
  { link: "ja", value: "Japanese", nativeName: "日本語 (にほんご／にっぽんご)" },
  { link: "jv", value: "Javanese", nativeName: "basa Jawa" },
  { link: "kl", value: "Kalaallisut", nativeName: "kalaallisut, kalaallit oqaasii" },
  { link: "kn", value: "Kannada", nativeName: "ಕನ್ನಡ" },
  { link: "kr", value: "Kanuri", nativeName: "Kanuri" },
  { link: "ks", value: "Kashmiri", nativeName: "कश्मीरी, كشميري‎" },
  { link: "kk", value: "Kazakh", nativeName: "Қазақ тілі" },
  { link: "km", value: "Khmer", nativeName: "ភាសាខ្មែរ" },
  { link: "ki", value: "Kikuyu, Gikuyu", nativeName: "Gĩkũyũ" },
  { link: "rw", value: "Kinyarwanda", nativeName: "Ikinyarwanda" },
  { link: "ky", value: "Kirghiz, Kyrgyz", nativeName: "кыргыз тили" },
  { link: "kv", value: "Komi", nativeName: "коми кыв" },
  { link: "kg", value: "Kongo", nativeName: "KiKongo" },
  { link: "ko", value: "Korean", nativeName: "한국어 (韓國語), 조선말 (朝鮮語)" },
  { link: "ku", value: "Kurdish", nativeName: "Kurdî, كوردی‎" },
  { link: "kj", value: "Kwanyama", nativeName: "Kuanyama" },
  { link: "la", value: "Latin", nativeName: "latine, lingua latina" },
  { link: "lb", value: "Luxembourgish", nativeName: "Lëtzebuergesch" },
  { link: "lg", value: "Luganda", nativeName: "Luganda" },
  { link: "li", value: "Limburgish", nativeName: "Limburgs" },
  { link: "ln", value: "Lingala", nativeName: "Lingála" },
  { link: "lo", value: "Lao", nativeName: "ພາສາລາວ" },
  { link: "lt", value: "Lithuanian", nativeName: "lietuvių kalba" },
  { link: "lu", value: "Luba-Katanga", nativeName: "" },
  { link: "lv", value: "Latvian", nativeName: "latviešu valoda" },
  { link: "gv", value: "Manx", nativeName: "Gaelg, Gailck" },
  { link: "mk", value: "Macedonian", nativeName: "македонски јазик" },
  { link: "mg", value: "Malagasy", nativeName: "Malagasy fiteny" },
  { link: "ms", value: "Malay", nativeName: "bahasa Melayu, بهاس ملايو‎" },
  { link: "ml", value: "Malayalam", nativeName: "മലയാളം" },
  { link: "mt", value: "Maltese", nativeName: "Malti" },
  { link: "mi", value: "Māori", nativeName: "te reo Māori" },
  { link: "mr", value: "Marathi (Marāṭhī)", nativeName: "मराठी" },
  { link: "mh", value: "Marshallese", nativeName: "Kajin M̧ajeļ" },
  { link: "mn", value: "Mongolian", nativeName: "монгол" },
  { link: "na", value: "Nauru", nativeName: "Ekakairũ Naoero" },
  { link: "nv", value: "Navajo, Navaho", nativeName: "Diné bizaad, Dinékʼehǰí" },
  { link: "nb", value: "Norwegian Bokmål", nativeName: "Norsk bokmål" },
  { link: "nd", value: "North Ndebele", nativeName: "isiNdebele" },
  { link: "ne", value: "Nepali", nativeName: "नेपाली" },
  { link: "ng", value: "Ndonga", nativeName: "Owambo" },
  { link: "nn", value: "Norwegian Nynorsk", nativeName: "Norsk nynorsk" },
  { link: "no", value: "Norwegian", nativeName: "Norsk" },
  { link: "ii", value: "Nuosu", nativeName: "ꆈꌠ꒿ Nuosuhxop" },
  { link: "nr", value: "South Ndebele", nativeName: "isiNdebele" },
  { link: "oc", value: "Occitan", nativeName: "Occitan" },
  { link: "oj", value: "Ojibwe, Ojibwa", nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ" },
  {
    link: "cu",
    value: "Old Church Slavonic",
    nativeName: "ѩзыкъ словѣньскъ",
  },
  { link: "om", value: "Oromo", nativeName: "Afaan Oromoo" },
  { link: "or", value: "Oriya", nativeName: "ଓଡ଼ିଆ" },
  { link: "os", value: "Ossetian", nativeName: "ирон æвзаг" },
  { link: "pa", value: "Panjabi", nativeName: "ਪੰਜਾਬੀ, پنجابی‎" },
  { link: "pi", value: "Pāli", nativeName: "पाऴि" },
  { link: "fa", value: "Persian", nativeName: "فارسی" },
  { link: "pl", value: "Polish", nativeName: "polski" },
  { link: "ps", value: "Pashto, Pushto", nativeName: "پښتو" },
  { link: "pt", value: "Portuguese", nativeName: "Português" },
  { link: "qu", value: "Quechua", nativeName: "Runa Simi, Kichwa" },
  { link: "rm", value: "Romansh", nativeName: "rumantsch grischun" },
  { link: "rn", value: "Kirundi", nativeName: "kiRundi" },
  { link: "ro", value: "Romanian", nativeName: "română" },
  { link: "ru", value: "Russian", nativeName: "русский язык" },
  { link: "sa", value: "Sanskrit (Saṁskṛta)", nativeName: "संस्कृतम्" },
  { link: "sc", value: "Sardinian", nativeName: "sardu" },
  { link: "sd", value: "Sindhi", nativeName: "सिन्धी, سنڌي، سندھی‎" },
  { link: "se", value: "Northern Sami", nativeName: "Davvisámegiella" },
  { link: "sm", value: "Samoan", nativeName: "gagana faa Samoa" },
  { link: "sg", value: "Sango", nativeName: "yângâ tî sängö" },
  { link: "sr", value: "Serbian", nativeName: "српски језик" },
  { link: "gd", value: "Scottish Gaelic", nativeName: "Gàidhlig" },
  { link: "sn", value: "Shona", nativeName: "chiShona" },
  { link: "si", value: "Sinhala", nativeName: "සිංහල" },
  { link: "sk", value: "Slovak", nativeName: "slovenčina" },
  { link: "sl", value: "Slovene", nativeName: "slovenščina" },
  { link: "so", value: "Somali", nativeName: "Soomaaliga, af Soomaali" },
  { link: "st", value: "Southern Sotho", nativeName: "Sesotho" },
  { link: "es", value: "Spanish", nativeName: "español, castellano" },
  { link: "su", value: "Sundanese", nativeName: "Basa Sunda" },
  { link: "sw", value: "Swahili", nativeName: "Kiswahili" },
  { link: "ss", value: "Swati", nativeName: "SiSwati" },
  { link: "sv", value: "Swedish", nativeName: "svenska" },
  { link: "ta", value: "Tamil", nativeName: "தமிழ்" },
  { link: "te", value: "Telugu", nativeName: "తెలుగు" },
  { link: "tg", value: "Tajik", nativeName: "тоҷикӣ, toğikī, تاجیکی‎" },
  { link: "th", value: "Thai", nativeName: "ไทย" },
  { link: "ti", value: "Tigrinya", nativeName: "ትግርኛ" },
  { link: "bo", value: "Tibetan Standard", nativeName: "བོད་ཡིག" },
  { link: "tk", value: "Turkmen", nativeName: "Türkmen, Түркмен" },
  { link: "tl", value: "Tagalog", nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔" },
  { link: "tn", value: "Tswana", nativeName: "Setswana" },
  { link: "to", value: "Tonga (Tonga Islands)", nativeName: "faka Tonga" },
  { link: "tr", value: "Turkish", nativeName: "Türkçe" },
  { link: "ts", value: "Tsonga", nativeName: "Xitsonga" },
  { link: "tt", value: "Tatar", nativeName: "татарча, tatarça, تاتارچا‎" },
  { link: "tw", value: "Twi", nativeName: "Twi" },
  { link: "ty", value: "Tahitian", nativeName: "Reo Tahiti" },
  { link: "ug", value: "Uighur", nativeName: "Uyƣurqə, ئۇيغۇرچە‎" },
  { link: "uk", value: "Ukrainian", nativeName: "українська" },
  { link: "ur", value: "Urdu", nativeName: "اردو" },
  { link: "uz", value: "Uzbek", nativeName: "zbek, Ўзбек, أۇزبېك‎" },
  { link: "ve", value: "Venda", nativeName: "Tshivenḓa" },
  { link: "vi", value: "Vietnamese", nativeName: "Tiếng Việt" },
  { link: "vo", value: "Volapük", nativeName: "Volapük" },
  { link: "wa", value: "Walloon", nativeName: "Walon" },
  { link: "cy", value: "Welsh", nativeName: "Cymraeg" },
  { link: "wo", value: "Wolof", nativeName: "Wollof" },
  { link: "fy", value: "Western Frisian", nativeName: "Frysk" },
  { link: "xh", value: "Xhosa", nativeName: "isiXhosa" },
  { link: "yi", value: "Yiddish", nativeName: "ייִדיש" },
  { link: "yo", value: "Yoruba", nativeName: "Yorùbá" },
  { link: "za", value: "Zhuang", nativeName: "Saɯ cueŋƅ, Saw cuengh" },
];

export const DEFAULT_LANGUAGE_TRENDING_TOPIC = [
  { link: "en", value: "English" },
  { link: "ms", value: "Malay" },
];
