// Detect existence of control characters and unicode
// Limit filename length to 256 characters
// Limit file size to 25MB

export const validateFileUpload = (file) => {
  const { name, size, type } = file;

  const nonAsciiCharacters = name.match(/[^\x20-\x7F]/g);
  if (nonAsciiCharacters) {
    return {
      validated: false,
      errorMessage: `Please remove the following characters from the file name (${nonAsciiCharacters.join(
        ", "
      )}).`,
    };
  }

  const filenameTooLong = name.length > 256;
  if (filenameTooLong)
    return {
      validated: false,
      errorMessage: `File name should not exceed 256 characters `,
    };

  const filesizeTooBig = size > 26_214_400; // 25MB
  if (filesizeTooBig) return { validated: false, errorMessage: "File size should not exceed 25MB" };

  return { validated: true };
};
