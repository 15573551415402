<template>
  <el-card :body-style="{ minHeight: '600px' }">
    <div style="display: flex; align-items: center; justify-content: space-between" slot="header">
      <span>
        <i style="margin-right: 10px" class="el-icon-folder-opened"></i>
        File Storage {{ storageType ? `(${storageType})` : "" }}
      </span>
      <Header
        :selected="selected"
        :opened="opened"
        :opened-folder="openedFolder"
        :folders="folders"
        @onUpload="$refs['uploader'].isOpen = true"
        @setSelected="selected = $event"
        @refresh="
          () => {
            fetchAllFolders();
          }
        "
      />
    </div>

    <div v-if="isBusy" v-loading="true" style="height: 80px"></div>

    <p style="text-align: center" v-if="!hasStorageConfigured">No storage provider configured...</p>

    <template v-if="opened">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <a @click="onOpenFolder(null)">All</a>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <a>{{ opened }}</a>
        </el-breadcrumb-item>
      </el-breadcrumb>

      <br />

      <Content :opened-folder="openedFolder" />
    </template>

    <template v-else>
      <el-row type="flex" style="flex-wrap: wrap">
        <Folder
          @dblclick.native="onOpenFolder(key)"
          @click.native="onSelectFolder(key)"
          v-for="(folder, key, index) in folders"
          class="folder"
          :class="{ 'is-selected': selected[key] }"
          :key="`folder-${index}`"
          :name="key"
        />
        <Item
          class="item"
          :key="index"
          :file="item"
          v-for="(item, index) in rootDir"
          @after-delete="rootDir.splice(index, 1)"
        />
      </el-row>
    </template>
  </el-card>
</template>

<script>
import _ from "lodash";
import Header from "./Header/Index";
import Folder from "./Folder";
import Content from "./Content/Index";
import Item from "./Content/Item";

import { mapGetters } from "vuex";

export default {
  name: "FileStorage",
  props: [],
  components: {
    Header,
    Folder,
    Content,
    Item,
  },
  data() {
    return {
      isBusy: false,
      folders: {},
      selected: {},
      opened: null,
      rootDir: [],
    };
  },
  mounted() {
    this.fetchStorageProvider();
  },
  computed: {
    ...mapGetters(["storageType", "isAzureStorage", "isCephStorage"]),

    /**
     * Has any storage configured
     *
     * @return {boolean}
     */
    hasStorageConfigured() {
      return this.storageType && (this.isAzureStorage || this.isCephStorage);
    },

    /**
     * Selected folder
     *
     * @return {object}
     */
    openedFolder() {
      if (!this.opened) {
        return;
      }
      return this.folders[this.opened];
    },
  },
  methods: {
    /**
     * On open folder
     *
     * @param {string} folderName
     * @return {void}
     */
    onOpenFolder(folderName) {
      this.selected = {};
      this.opened = folderName;
    },

    /**
     * On select folder
     *
     * @param {string} folderName
     * @return {void}
     */
    onSelectFolder(folderName) {
      if (_.has(this.selected, folderName)) {
        this.$delete(this.selected, folderName);
      } else {
        this.$set(this.selected, folderName, this.folders[folderName]);
      }
    },

    /**
     * Fetch all folders
     *
     * @return {void}
     */
    async fetchAllFolders() {
      this.isBusy = true;
      try {
        const folders = await this.$store.dispatch("GET_ALL_IMAGES");

        this.folders = _.groupBy(folders, "folder");
        // this is for handling file in rootDir, detected in undefined property, if folder property undefined
        this.rootDir = _.get(this, "folders.undefined", []);

        delete this.folders.undefined;
      } catch (error) {
        console.warn(error);
        this.$notify.error({
          title: "Error",
          message: "Encountered error fetching storage items.",
          position: "bottom-right",
        });
      } finally {
        this.isBusy = false;
      }
    },

    /**
     * Fetch storage provider
     *
     * @return {void}
     */
    async fetchStorageProvider() {
      if (this.isBusy) {
        return;
      }

      this.isBusy = true;
      try {
        const provider = await this.$store.dispatch("GET_STORAGE_TYPE");
        if (!provider) {
          throw "Not storage driver configured.";
        }
        this.fetchAllFolders();
      } catch (error) {
        console.warn(error);
        this.$notify.error({
          title: "Error",
          message: "Encountered error fetching storage provider.",
          position: "bottom-right",
        });
        this.isBusy = false;
      }
    },
  },
};
</script>

<style scoped>
.folder {
  padding: 10px;
  border: 2px solid transparent;
}

.folder.is-selected {
  border: 2px solid #e0e0e0;
  border-radius: 15px;
}

.item {
  width: 150px;
  margin: 10px;
}
</style>
