<template>
  <div>
    <b>Start queue</b>
    at {{ printLocaleTimeFromUtc(chat.startQueueTime) }}
    <br />
    <b>In queue</b>
    for {{ printDuration(chat.startQueueTime, chat.startResolveTime, true) }}
    <br />
    <b>Start live chat</b>
    at {{ printLocaleTimeFromUtc(chat.startResolveTime) }}
    <br />
    <b>In live chat</b>
    for {{ printDuration(chat.startResolveTime, chat.endResolveTime, true) }}
    <br />
    <b>End live chat</b>
    at {{ printLocaleTimeFromUtc(chat.endResolveTime) }}
    <span v-if="chat.startResolveTime === 'null' || chat.endResolveTime === 'null'">
      <br />
      <br />*User closed session before resolve
    </span>
  </div>
</template>

<script>
import { printDuration, printLocaleTimeFromUtc } from "@/helperMethods/livechat/util";

export default {
  props: {
    chat: Object,
  },
  methods: {
    printLocaleTimeFromUtc,
    printDuration,
  },
};
</script>
<style></style>
