<template>
  <el-card>
    <div style="max-width: 30vw">
      <b>{{ tooltipData.Question }}</b>

      <div>
        <svg height="12" width="12" style="display: inline-block">
          <circle
            v-if="tooltipData['Is expected?'] == 'Yes'"
            cx="6"
            cy="6"
            r="6"
            stroke-width="0"
            fill="green"
          />
          <circle
            v-if="tooltipData['Is expected?'] == 'No'"
            cx="6"
            cy="6"
            r="6"
            stroke-width="0"
            fill="red"
          />
        </svg>
        <h5 style="display: inline-block; margin: 10px">
          {{ (tooltipData.Confidence * 100).toFixed(2) }}%
        </h5>
      </div>
      <p style="font-size: 14px">
        Matched as
        <b>{{ tooltipData["Actual intent"] }}</b>
      </p>

      <el-tooltip :content="`Move this example to ${tooltipData['Actual intent']}`">
        <el-button type="plain" size="small" @click="moveExample"> Move </el-button>
      </el-tooltip>
    </div>
  </el-card>
</template>

<script>
import Vue from "vue";
import { faqDatasetMixin } from "@/mixins/faq";

export default Vue.extend({
  mixins: [faqDatasetMixin],
  name: "Tooltip",
  props: {
    tooltipData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    moveExample() {
      const { "Actual intent": targetIntent } = this.tooltipData;
      const { "Expected intent": intentName } = this.tooltipData;
      if (!_.isEmpty(targetIntent)) {
        // save to the intent
        const { pair } = this.getIntentByIdentity(intentName);
        let tooltipDataQuestion = this.tooltipData.Question;
        if (
          tooltipDataQuestion[0] == '"' &&
          tooltipDataQuestion[tooltipDataQuestion.length - 1] == '"'
        ) {
          tooltipDataQuestion = tooltipDataQuestion.slice(1, this.tooltipData.Question.length - 1);
        }
        const variationObject = _.find(
          pair.variations,
          (entity) => entity.text == tooltipDataQuestion
        );
        this.deleteVariation({ id: intentName }, this.tooltipData.Question, pair.id);
        this.saveVariation({ id: targetIntent }, variationObject);
        return;
      }
    },
    deleteVariation({ id = "" }, text, selectedIntent = null) {
      this.$confirm("This will delete the Variation. Continue?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "info",
      }).then(() => {
        const { index: pairIndex, pair } = this.getIntentByIdentity(id.toUpperCase());
        if (pair) {
          const { index: variationIndex } = this.getIntentVariation({
            pair,
            text,
          });
          pair.variations.splice(variationIndex, 1);
          this.$store.state.training.dataset[pairIndex] = pair;

          // Update the variable with latest data
        }
        this.$set(this.$store.state.pages.faq, "selectedIntentId", selectedIntent);
      });
    },

    saveVariation({ id }, variationObject) {
      const { id: variationObjectId, text: variationObjectText } = variationObject;
      const { pair } = this.getIntentByIdentity(id);
      const { index: variationIndex } = this.getIntentVariation({
        pair,
        id: variationObjectId,
        text: variationObjectText,
      });
      if (variationIndex !== -1) {
        // replace the variation
        pair.variations[variationIndex] = variationObject;
      } else {
        // Just add in if cannot find in existing intent
        pair.variations.unshift(variationObject);
      }
    },
  },
});
</script>
