// @ts-ignore
import { required } from "vuelidate/lib/validators";

/**
 * @description Blueprint for a webpage
 */
export const WebPageSkeleton = {
  name: null,
  content: null,
  type: null,
  head: null,
  is_published: false,
};

/**
 * @description Used for code mirror package
 * @description Additional configuration for html tag editor
 */
export const HtmlEditorOptions = {
  lineNumbers: true,
  line: true,
  styleActiveLine: true,
  keyMap: "sublime",
  mode: "htmlmixed",
  theme: "monokai",
  tags: {
    style: [
      ["type", /^text\/(x-)?scss$/, "text/x-scss"],
      [null, null, "css"],
    ],
  },
  // theme: ''
};

/**
 * @description Validation rules for WebPage
 * @description Used vuelidate, https://vuelidate.js.org/
 */
export const Validations = {
  validations() {
    return {
      selectedWebPage: {
        name: {
          required,
        },
        content: {
          required,
        },
      },
    };
  },
};
