export interface ExampleTrending {
  example: string;
  published: boolean;
}

export interface ExampleRecord {
  [country: string]: ExampleWithAnswer;
}

export interface ExampleWithAnswer {
  examples: ExampleTrending[];
  answer: string;
}

export enum TRENDING_TOPIC_STATUS {
  NONE = "NONE",
  CURRENTLY = "CURRENTLY",
  PREVIOUS = "PREVIOUS",
}
export interface TrendingIntentType {
  id: number;
  intentId: string;
  examples: string;
  status: TRENDING_TOPIC_STATUS;
  createdByAdmin: Boolean;
  order?: number;
  publishedAt?: Date;
  createdAt?: Date;
}
export interface TrendingType extends TrendingIntentType {
  count: number;
}

export interface OrderUpdate {
  id: number;
  order: number;
}
