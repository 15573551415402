import { BroadcastState } from "./types";

const state: BroadcastState = {
  broadcastObjects: {},
  broadcastArray: [],
  selectedBroadcastId: "",
  selectedBroadcast: null,
};

export default state;
