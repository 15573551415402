<template>
  <section>
    <div v-loading="isFetchingTodayLivechatSessions"></div>

    <p class="text-center">
      <strong>
        TOTAL {{ todayChatsFiltered.length }} CHATS FOR {{ today.format("YYYY-MM-DD") }}
      </strong>
    </p>

    <el-input
      readonly
      size="small"
      v-if="isShowQueuedChatsCountSettingsEnabled"
      :value="queuedChatsCount | preventBoolean"
      style="margin-bottom: 20px"
      class="has-background-info"
    >
      <template slot="prepend">QUEUED</template>
    </el-input>

    <el-input
      readonly
      size="small"
      v-if="isShowAttendingChatsCountSettingsEnabled"
      :value="attendingChatsCount | preventBoolean"
      style="margin-bottom: 20px"
      class="has-background-warning"
    >
      <template slot="prepend">ATTENDING</template>
    </el-input>

    <el-input
      readonly
      size="small"
      v-if="isShowAbandonedChatsCountSettingsEnabled"
      :value="abandonedChatsCount | preventBoolean"
      style="margin-bottom: 20px"
      class="has-background-danger"
    >
      <template slot="prepend">ABANDONED</template>
    </el-input>

    <!-- 
    <el-input placeholder="Please input" v-model="input1">
      <template slot="prepend">Http://</template>
    </el-input>

    <el-input placeholder="Please input" v-model="input1">
      <template slot="prepend">Http://</template>
    </el-input>-->

    <!-- <el-row type="flex" justify="space-around" align="middle">
      <div class="text-center" v-if="isShowQueuedChatsCountSettingsEnabled">
        <el-progress
          :color="[ 
            {color: '$color-warning', percentage: 0}
          ]"
          type="dashboard"
          :percentage="queuedChatsCount | toPercent(todayChats.length)"
        ></el-progress>
        <p style="font-size: 11px;" class="is-marginless is-paddingless">
          <strong>{{ queuedChatsCount }} QUEUED</strong>
        </p>
      </div>
      <div class="text-center" v-if="isShowAttendingChatsCountSettingsEnabled">
        <el-progress
          :percentage="attendingChatsCount | toPercent(todayChats.length)"
          type="dashboard"
        ></el-progress>
        <p style="font-size: 11px;" class="is-marginless is-paddingless">
          <strong>{{ attendingChatsCount }} ATTENDING</strong>
        </p>
      </div>
      <div class="text-center" v-if="isShowAbandonedChatsCountSettingsEnabled">
        <el-progress
          :color="[ 
            {color: '#fa5555', percentage: 0}
          ]"
          :percentage="abandonedChatsCount | toPercent(todayChats.length)"
          type="dashboard"
        ></el-progress>
        <p style="font-size: 11px;" class="is-marginless is-paddingless">
          <strong>{{ abandonedChatsCount === true ? 1 : abandonedChatsCount }} ABANDONED</strong>
        </p>
      </div>
    </el-row>-->

    <JSONEditor ref="jsonEditor" :value="queuedChats" v-if="isShowAdvanced" />
  </section>
</template>

<script>
import moment from "moment";
import { graph } from "@/store/api";
import gql from "graphql-tag";
import _ from "lodash";

import JSONEditor from "@/components/JSONEditor";

export default {
  components: {
    JSONEditor,
  },
  props: ["popoverIsOpen", "agentsConnection", "queuedChats", "hasAnyChatStatusSettingsEnabled"],
  data() {
    return {
      today: moment(),
      todayChats: [], // FIXME: used to store all list not by dept
      isFetchingTodayLivechatSessions: false,
    };
  },
  watch: {
    popoverIsOpen: {
      deep: true,
      handler(updatedPopoverIsOpen) {
        if (updatedPopoverIsOpen === true) {
          this.fetchTodayLivechatSessions();
        }
      },
    },
  },
  filters: {
    /**
     * @description Parse value to percentage
     * @return {number}
     */
    toPercent: (value, todayChatsLength) => {
      const percent = Math.round((value / todayChatsLength) * 100);
      return percent > 0 ? percent : 0;
    },

    /**
     * @description Prevent boolean show on the input
     * @return {number}
     */
    preventBoolean: (value) => {
      if (value === true) return 1;
      if (value === false) return 0;
      return value;
    },
  },
  methods: {
    /**
     * @description Fetch all webpages
     * @return {void}
     */
    fetchTodayLivechatSessions() {
      if (!this.isFetchingTodayLivechatSessions) {
        this.isFetchingTodayLivechatSessions = true;
        graph
          .query({
            query: gql`
              query {
                livechatAPI {
                  fetchTodayLivechatSessions
                }
              }
            `,
            fetchPolicy: "network-only",
          })
          .then((response) => {
            this.todayChats = _.chain(response)
              .get("data.livechatAPI.fetchTodayLivechatSessions")
              .map((chat) => {
                chat.agents = JSON.parse(chat.agents);
                return chat;
              })
              .value();
          })
          .finally(() => (this.isFetchingTodayLivechatSessions = false));
      }
    },

    /**
     * @description filter chats by department
     * @return {Array<object>}
     */
    filterChatByDepartment(chats) {
      const filteredChats = chats.filter((chat) => {
        if (!this.isHandoverByDepartmentEnabled) {
          return true;
        }

        const userNoDept = _.isEmpty(this.userProfileDepartmentIds);
        const chatNonDepartment = chat.department === "00000000-0000-0000-0000-000000000000";
        const userBelongToDept = _.includes(this.userProfileDepartmentIds, chat.department);

        return userNoDept || chatNonDepartment || userBelongToDept;
      });

      return filteredChats;
    },
  },
  computed: {
    /**
     * @description check handover routing by dept or not
     * @return {boolean}
     */
    isHandoverByDepartmentEnabled() {
      return _.get(this.$store, "state.modules.handover.handoverRouting.byDepartment", false);
    },

    /**
     * @description return list of dept registered
     * @return {Array<object>}
     */
    departments() {
      const depts = _.get(this.$store, "state.department.departments", []);
      return depts;
    },

    /**
     * @description return list of dept id of user
     * @return {Array<string>}
     */
    userProfileDepartmentIds() {
      const userProfileDepartments = _.get(
        this.$store,
        "state.profile.app_metadata.departments",
        []
      );
      const depts = _.chain(this.departments)
        .intersectionWith(userProfileDepartments, (department, userProfileDepartment) => {
          return department.name === userProfileDepartment;
        })
        .map("id")
        .value();

      return depts;
    },

    /**
     * @description return total chat in status
     * @return {number}
     */
    todayChatsFiltered() {
      const todayChatsFiltered = this.todayChats.filter((chat) => {
        if (!this.isHandoverByDepartmentEnabled) {
          return true;
        }

        const userNoDept = _.isEmpty(this.userProfileDepartmentIds);
        const chatNonDepartment = chat.department === "00000000-0000-0000-0000-000000000000";
        const userBelongToDept = _.includes(this.userProfileDepartmentIds, chat.department);

        return userNoDept || chatNonDepartment || userBelongToDept;
      });

      return this.filterChatByDepartment(this.todayChats);
    },

    /**
     * @description If handover routing mode is broadcast
     * @return {boolean}
     */
    isRoutingModeBroadcast() {
      return _.get(this.$store, "getters.isRoutingBroadcastMode", false);
    },

    /**
     * @description isShowAdvanced getters from vuex state
     * @return {boolean}
     */
    isShowAdvanced() {
      return _.get(this.$store, "getters.isShowAdvanced", false);
    },

    /**
     * @description Has show total abandonded chats status settings enabled
     * @return {boolean}
     */
    isShowAbandonedChatsCountSettingsEnabled() {
      return _.get(
        this.$store.state,
        "modules.handover.livechatStatusMonitor.showAbandonedChatsCount",
        false
      );
    },

    /**
     * @description Has show total queued chats count settings enabled
     * @return {boolean}
     */
    isShowQueuedChatsCountSettingsEnabled() {
      return _.get(
        this.$store.state,
        "modules.handover.livechatStatusMonitor.showQueuedChatsCount",
        false
      );
    },

    /**
     * @description Has show total attending chats count settings enabled
     * @return {boolean}
     */
    isShowAttendingChatsCountSettingsEnabled() {
      return _.get(
        this.$store.state,
        "modules.handover.livechatStatusMonitor.showAttendingChatsCount",
        false
      );
    },

    /**
     * @description Get current queue chats count
     * @return {number}
     */
    queuedChatsCount() {
      if (!this.isShowQueuedChatsCountSettingsEnabled) {
        return 0;
      }
      return this.queuedChats.filter((chat) => {
        const todayDate = this.today.format("YYYY-MM-DD");
        const chatCreatedDate = moment(chat.date_created).format("YYYY-MM-DD");
        const isToday = todayDate === chatCreatedDate;

        if (this.isRoutingModeBroadcast) {
          const hasNoAgent = !_.has(chat, "agents") || chat.agents.length < 1;
          return hasNoAgent && isToday;
        } else {
          return chat.waiting_for_assignment && isToday;
        }
      }).length;
    },

    /**
     * @description Get current attended queue chats count
     * @return {number}
     */
    attendingChatsCount() {
      if (!this.isShowAttendingChatsCountSettingsEnabled) {
        return 0;
      }

      return _.sumBy(this.agentsConnection, "attending");

      // return this.queuedChats.filter(chat => {
      //   const isToday = this.today.diff(moment(chat.date_created)) < 0;
      //   return true;
      // }).length;
    },

    /**
     * @description Get total abandonded chats count
     * @return {number}
     */
    abandonedChatsCount() {
      if (!this.isShowAbandonedChatsCountSettingsEnabled) {
        return 0;
      }

      const filteredTodayChats = this.filterChatByDepartment(this.todayChats);
      const totalAbandonedCount = _.sumBy(
        filteredTodayChats,
        (chat) => chat.resolved && _.isEmpty(chat.agents)
      );

      return totalAbandonedCount;
    },
  },
  apollo: {
    $subscribe: {
      livechatSessionsChanged: {
        query: gql`
          subscription {
            livechatSessionsChanged
          }
        `,
        result({ data }) {
          // Only update if popover is currently open
          // Dont always update if user not watching at the popover, to prevent performance issue
          if (!this.isFetchingTodayLivechatSessions && this.popoverIsOpen) {
            this.todayChats = _.chain(data)
              .get("livechatSessionsChanged.todayChats")
              .map((chat) => {
                chat.agents = JSON.parse(chat.agents);
                return chat;
              })
              .value();
          }
        },
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/colors.scss";

>>> .has-background-danger .el-input-group__prepend {
  background-color: $color-danger;
  color: white;
  font-weight: bold;
  border: 1px solid $color-danger;
}

>>> .has-background-danger input {
  border-color: $color-danger;
}

>>> .has-background-warning .el-input-group__prepend {
  background-color: $color-warning;
  color: white;
  font-weight: bold;
  border: 1px solid $color-warning;
}

>>> .has-background-warning input {
  border-color: $color-warning;
}

>>> .has-background-info .el-input-group__prepend {
  background-color: $color-info;
  color: white;
  font-weight: bold;
  border: 1px solid $color-info;
}

>>> .has-background-info input {
  border-color: $color-info;
}
</style>
