<template>
  <el-main>
    <template v-if="selectedTopic">
      <el-row>
        <el-col>
          <FaqTopicItems
            @selectTopic="selectTopic($event)"
            @deleteQuestion="deleteQuestion"
            @handleCellClick="handleCellClick($event)"
            @handleBlur="handleBlur"
            @isDirty="isDirty"
            :selectedTopic="selectedTopic"
            :topics="topics"
            :selectedTopicDatasets="selectedTopicDatasets"
            :selectedLanguages="selectedLanguages"
            :badgeForQustions="badgeForQustions"
            @onSortChange="$emit('onSortChange', $event)"
          />
        </el-col>
      </el-row>
    </template>

    <template v-else>
      <FaqNewIntent :topics="topics" @handleBlur="handleBlur" @isDirty="isDirty" />

      <br />

      <masonry :cols="{ default: 3, 700: 2, 600: 1 }" :gutter="10">
        <FaqTopic
          :key="`faq-topic-${index}-${topic}`"
          :topic="topic"
          v-for="(topic, index) in topics"
          @selectTopic="selectTopic($event)"
        />

        <el-card shadow="hover" style="height: 70px">
          <el-row type="flex" align="middle" justify="space-between">
            <el-input
              class="text-capitalize"
              placeholder="New Topic"
              size="small"
              v-model="newTopic"
              clearable
            ></el-input>
            <el-button
              class="add-topic-button"
              type="primary"
              :disabled="!newTopic"
              size="small"
              style="margin-left: 5px"
              icon="el-icon-plus"
              plain
              @click="onAddTopic"
            ></el-button>
          </el-row>
        </el-card>
      </masonry>
    </template>
  </el-main>
</template>
<script>
import _ from "lodash";
import FaqNewIntent from "@/components/Faq/FaqNewIntent";
import { validateIntentVariations, validateIntentAction } from "@/helperMethods/faq/index";
import FaqTopic from "./Topic.vue";
import FaqTopicItems from "./TopicFaqs.vue";

export default {
  components: { FaqNewIntent, FaqTopic, FaqTopicItems },
  name: "FaqTrainingDatasetListByTopic",
  props: [
    "visibleDataset",
    "expandedRows",
    "badgeForQustions",
    "selectedLanguages",
    "handleBlur",
    "isDirty",
  ],
  data() {
    return {
      // Topic
      selectedTopic: null,
      newTopic: "",
    };
  },
  computed: {
    noOfItems() {
      return this.datasets.length;
    },
    showAdvanced() {
      return this.$store.state.showAdvanced;
    },
    selectedIntentIsEmpty() {
      return _.isEmpty(this.selectedIntent);
    },
    topics() {
      return this.$store.getters.getFaqTopics;
    },
    selectedTopicDatasets() {
      const isSelectedTopicEmpty = _.isEmpty(this.selectedTopic);
      if (isSelectedTopicEmpty) {
        return [];
      }
      const selectedTopicDatasets = this.datasets.filter(
        (dataset) => dataset.topics.indexOf(this.selectedTopic) > -1
      );
      return selectedTopicDatasets;
    },
    datasets() {
      _.forEach(this.visibleDataset, (intent, index) => {
        const isInvalid = validateIntentAction(intent.action);
        _.assign(intent, {
          invalid: isInvalid,
        });

        const isValidVariations = validateIntentVariations(intent.variations);
        _.assign(intent, {
          invalidVariations: isValidVariations,
        });
      });
      return this.visibleDataset;
    },
  },
  methods: {
    selectTopic(topic) {
      this.selectedTopic = topic;
    },
    togglePage(pageNo) {
      this.currentPage = pageNo;
    },
    handleSizeChange(size) {
      this.pageSize = size;
    },
    customRowStyle(row, rowIndex) {
      const style = { cursor: "pointer" };
      const currentRowId = _.get(row, "row.intentName");
      if (this.$store.state.pages.faq.selectedIntentId === currentRowId) {
        style.background = "#f3f6f9";
      }
      const isInvalid = _.get(row, "row.invalid");
      if (isInvalid) {
        style.background = "#fee";
      }
      return style;
    },
    isAuthorisedForChange(intentDepartment = ["general"]) {
      const userDepartment = this.$store.getters.userDepartment;

      const userIsAuthorised = _.intersection(userDepartment, intentDepartment).length > 0;
      const intentIsGeneral = intentDepartment.includes("general");

      const isAuthorised = userIsAuthorised || intentIsGeneral;
      return isAuthorised;
    },
    containsUntestedVariations(intent) {
      const variations = intent.variations;
      let untestedVariationExist = false;
      if (variations) {
        variations.map((variation) => {
          const intent_ranking = variation.intent_ranking;
          if (intent_ranking && intent_ranking.length === 0) {
            untestedVariationExist = true;
          }
          if (!intent_ranking) {
            untestedVariationExist = true;
          }
        });
      }
      return untestedVariationExist;
    },
    handleCellClick({ row, column, cell, event }) {
      if (column.label === "Intent Name" || column.label === "Examples") {
        this.$emit("handleIntentEdit", row);
      }
    },
    variationsByLanguage(row) {
      const variations = row.variations;
      if (variations) {
        const variationsCount = variations.filter((variation) => {
          const selectedLanguages = this.selectedLanguages;
          if (selectedLanguages.length > 0) {
            return selectedLanguages.includes(variation.language);
          } else {
            return true;
          }
        }).length;
        return variationsCount;
      }
    },
    deleteQuestion(row) {
      this.$store.commit("DELETE_QUESTION", row.id);
      this.$emit("clearIntentView");
      this.$forceUpdate();
    },
    lowVariationStyle({ row, columnIndex }) {
      if (columnIndex === 1 && row && row.variations && row.variations.length < 2) {
        return { color: "red", fontWeight: "bold" };
      }
    },
    /**
     * @description Check to prevent '_' in topic name
     * @return {boolean}
     */
    checkTopicNamesForUnderscore(topics) {
      const topicWithUnderscoreIndex = topics.findIndex((topic) => topic.includes("_"));
      const topicNameInvalid = topicWithUnderscoreIndex > -1;
      if (topicNameInvalid) {
        this.$notify.error({
          title: "New Topic Name Error",
          position: "bottom-right",
          message: `Please remove '_' character in '${topics[topicWithUnderscoreIndex]}'`,
        });
      }
      return topicNameInvalid;
    },

    /**
     * @description Check to prevent topic name as 'model'
     * @return {boolean}
     */
    checkTopicNamesIsModel(topics) {
      const topicNamedModelIndex = topics.findIndex((topic) => topic === "model");
      const topicNameInvalid = topicNamedModelIndex > -1;
      if (topicNameInvalid) {
        this.$notify.error({
          title: "New Topic Name Error",
          position: "bottom-right",
          message: `Please rename '${topics[topicNamedModelIndex]}' topic`,
        });
      }
      return topicNameInvalid;
    },

    /**
     * @description Add a new empty topic group
     * @return {void}
     */
    onAddTopic() {
      const formattedTopicName = this.newTopic.toLowerCase();
      const nameHasUnderscore = this.checkTopicNamesForUnderscore([formattedTopicName]);
      const nameIsModel = this.checkTopicNamesIsModel([formattedTopicName]);
      const invalidTopicName = nameHasUnderscore || nameIsModel;

      if (invalidTopicName) {
        return;
      }

      const updatedTopics = this.topics;
      updatedTopics.push(formattedTopicName);
      this.$store.commit("SET_TOPICS", updatedTopics);

      this.newTopic = "";
    },
  },
};
</script>

<style scoped>
.badge_item {
  margin-top: 13px;
  margin-right: 50px;
}
.list-wording {
  vertical-align: middle;
}
.list-icon {
  font-size: 18px;
  vertical-align: middle;
}
</style>
