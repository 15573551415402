<template>
  <el-popover
    v-if="$store.getters.isShowAdvanced"
    v-model="showNewBotPopover"
    placement="bottom"
    width="240"
    style="margin-right: 10px"
  >
    <el-tooltip
      slot="reference"
      class="item"
      effect="dark"
      content="Create new bot"
      placement="bottom"
    >
      <el-button circle :loading="creating" icon="el-icon-plus" />
    </el-tooltip>
    <el-input
      v-model="newBotName"
      size="small"
      @keyup.enter.native="createBot"
      @keyup.esc.native="showNewBotPopover = false"
    >
      <div slot="prepend">Bot name</div>
    </el-input>
  </el-popover>
</template>

<script>
export default {
  props: ["setStatus", "creating"],
  data() {
    return {
      newBotName: "",
      showNewBotPopover: false,
    };
  },
  methods: {
    createBot() {
      // Prevent invalid characters in the Bot name.
      if (/_/.test(this.newBotName)) {
        this.$message({
          message: "Bot name cannot contain invalid characters (_)",
          type: "error",
        });
      } else {
        this.setStatus("creating", true);

        this.$store
          .dispatch("CREATE_BOT", this.newBotName)
          .then(() => {
            this.$message({
              type: "success",
              message: "Created " + this.newBotName,
            });

            this.setStatus("creating", false);
            this.showNewBotPopover = false;
          })
          .catch(() => {
            this.$notify.error({
              title: "Error",
              position: "bottom-right",
              message: `Failed to create bot.`,
            });
          });
      }
    },
  },
};
</script>
