export default {
  props: ["value"],
  mounted() {
    const component = this as any;
    component.$refs[component.ref].codemirror.setSize(640, 480);
  },
  computed: {
    settings() {
      const component = this as any;
      return {
        mode: component.mode || "text/javascript",
        value: "",
        origRight: "",
        lineNumbers: true,
        collapseIdentical: false,
        highlightDifferences: true,
        allowEditingOriginals: true,
        foldGutter: true,
        styleSelectedText: true,
        matchBrackets: true,
        showCursorWhenSelecting: true,
        theme: "monokai",
        styleActiveLine: true,
        line: true,
        tabSize: 2,
        indentWithTabs: true,
      };
    },
    codemirror() {
      const component = this as any;
      return component.$refs[component.ref].jsonEditor.codemirror;
    },
  },
};
