import Vue from "vue";
import "unfetch/polyfill/index.js";
import {
  GRAPHQL_WEBSOCKET_STATE,
  initGraphQL,
  isGraphQLWebsocketConnected,
  isGraphQLWebsocketConnecting,
  listenGraphQLWebsocket,
} from "@/store/graphql";
import { config } from "@/helpers/apiConfig";
import { initREST } from "@/store/rest";

Object.assign(Vue.config, config);

const rest: any = initREST();

let graph: any = null;

const loadGraphQL = () => {
  graph = initGraphQL();
  return graph;
};

export {
  loadGraphQL,
  graph,
  rest,
  config,
  initGraphQL,
  GRAPHQL_WEBSOCKET_STATE,
  listenGraphQLWebsocket,
  isGraphQLWebsocketConnected,
  isGraphQLWebsocketConnecting,
};
