<template>
  <div>
    <template v-if="flowType === 'standard'">
      <el-row ref="previewWindow" style="overflow-x: hidden" class="preview-editor-panel">
        <el-row v-for="(message, index) in messages" :key="index">
          <!-- CHAT MESSAGE -->
          <div
            style="width: 75%"
            :style="{
              float: message.type === 'message' || message.type === 'postback' ? 'left' : 'right',
            }"
          >
            <message
              :key="message.id"
              :message="message"
              :is-preview="true"
              @sendPostback="$emit('sendPostback', $event)"
            />
          </div>
        </el-row>
      </el-row>

      <br />

      <el-row style="position: relative">
        <el-col>
          <el-autocomplete
            v-model="message"
            style="width: 100%"
            placeholder="message"
            placement="top"
            :fetch-suggestions="querySearch"
            @keyup.enter.native="reply"
          >
            <template slot="append">
              <el-button @click="reply">Send</el-button>
            </template>
          </el-autocomplete>
        </el-col>
      </el-row>
    </template>
  </div>
</template>

<script>
import Message from "@/components/ChatInteractions/Message/Index";

export default {
  components: {
    Message,
  },
  props: ["flowType", "messages"],
  data() {
    return {
      message: "",
    };
  },
  updated() {
    this.$nextTick(this.scrollToBottom);
  },
  methods: {
    querySearch(queryString, callback) {
      callback([]);
    },
    scrollToBottom() {
      if (this.$refs.previewWindow) {
        const container = this.$refs.previewWindow.$el;
        container.scrollTop = container.scrollHeight;
      }
    },
    reply() {
      this.$emit("reply", this.message);
      this.message = "";
    },
  },
};
</script>
<style>
@media screen and (max-height: 900px) {
  .preview-editor-panel {
    height: 350px;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

@media screen and (min-height: 900px) {
  .preview-editor-panel {
    height: 75vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
</style>
