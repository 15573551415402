import _ from "lodash";

/**
 * @description Copy text to clipboard
 * @param el
 * @param binding
 * @return {void}
 */
export function copy(el: any, binding: any) {
  el.addEventListener("click", function () {
    const textToCopy = _.get(binding, "value.text", "");
    const dummy = document.createElement("input");
    document.body.appendChild(dummy);
    dummy.setAttribute("value", textToCopy);
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  });
}

export default {
  copy,
};
