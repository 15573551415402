<template>
  <el-dialog :visible="visible" :before-close="handleClose" class="dialog-form-tag" width="450px">
    <div class="dialog-form-tag-container">
      <div class="dialog-form-tag-container-header">
        <p>{{ formTitle }}</p>
      </div>
      <div class="dialog-form-tag-container-body">
        <div class="dialog-form-tag-container-body-input">
          <p>
            {{ label }}
          </p>
          <div class="dialog-input">
            <el-input v-model="formModel.name" placeholder="" />
            <div class="dialog-input-length" v-if="formModel?.type === OUT_REACH_TAG_TYPE.TAG">
              <p class="dialog-input-length-count" :class="getLengthName() > maxLength && 'red'">
                {{ getLengthName() }}
              </p>
              <p>/</p>
              <p class="dialog-input-length-max">{{ maxLength }}</p>
            </div>
          </div>
        </div>
        <div class="dialog-form-tag-container-body-button">
          <el-button
            type="primary"
            size="mini"
            @click="submitForm('tag')"
            :disabled="
              (getLengthName() > maxLength && type === OUT_REACH_TAG_TYPE.TAG) ||
              getLengthName() === 0
            "
            >Save</el-button
          >
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { OUT_REACH_TAG_TYPE } from "@/store/modules/outreach/types";
import _, { isEmpty } from "lodash";
const defaultModel = {
  name: "",
};

export default {
  props: {
    visible: {},
    propsModel: {},
    tags: {},
    type: OUT_REACH_TAG_TYPE,
    label: String,
  },
  data() {
    return {
      formModel: {},
      OUT_REACH_TAG_TYPE,
      maxLength: 20,
    };
  },
  computed: {
    formTitle() {
      return this.modelExists ? `Edit ${this.label}` : `Create ${this.label}`;
    },
    modelExists() {
      return !isEmpty(this.propsModel);
    },
  },
  watch: {
    visible(visible) {
      if (visible) {
        if (this.modelExists) {
          this.formModel = Object.assign({}, this.propsModel);
        } else {
          this.formModel = Object.assign({}, { ...defaultModel, type: this.type });
        }
      }
    },
  },
  methods: {
    submitForm(formName) {
      if (this.formModel.type === OUT_REACH_TAG_TYPE.TAG) {
        this.formModel.name = this.formModel.name.toLowerCase();
      }
      this.$emit("submit:tag", this.formModel);
      this.$emit("update:visible", false);
      // this.$v.$touch();
      // if (!this.$v.$invalid) {
      //   this.formModel.name = this.formModel.name.toLowerCase();

      // }
      // const tag = this.$refs[formName];
      // tag.validate((valid) => {
      //   if (valid) {
      //     // this.$emit("submit:tag", this.formModel);
      //     // this.$emit("update:visible", false);
      //   }
      // });
    },
    handleClose() {
      this.$emit("update:visible", false);
      this.$emit("refresh-props-model");
    },
    getLengthName() {
      return this.formModel?.name?.length || 0;
    },
  },
};
</script>

<style lang="scss">
$base-date-picker-color: #1d57d8;
$button-bg-hover: #e8eefb;
$button-border-hover: #bbcdf3;
.dialog-form-tag {
  .el-dialog__header {
    display: none !important;
  }
  .el-dialog__body {
    padding: 20px 32px 27px 23px !important;
  }
  &-container {
    &-header {
      > p {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
          Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 16px;

        color: #000000;
      }
    }
    &-body {
      &-input {
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
        > p {
          font-weight: normal;
          margin-right: 6px !important;
          font-size: 14px;
          margin-bottom: 10px !important;
        }
        input {
          width: 100%;
        }
      }
      &-button {
        text-align: end;
        > button {
          border-color: $base-date-picker-color !important;
          background: none !important;
          color: $base-date-picker-color !important;
          &:hover {
            background: $button-bg-hover !important;
            border-color: $button-border-hover !important;
            border-color: $base-date-picker-color !important;
          }
        }
      }
    }
    .dialog-input {
      font-size: 14px;
      color: #909399;
      position: relative;
      &-length {
        position: absolute;
        right: 5px;
        bottom: 2px;
        display: flex;
        font-size: 12px;
        &-count {
          &.red {
            color: red !important;
          }
        }
      }
    }
  }
}
</style>
