import Facebook from "@/components/BotCreationWizard/BaseObjects/Facebook.json";
import Line from "@/components/BotCreationWizard/BaseObjects/Line.json";
import Telegram from "@/components/BotCreationWizard/BaseObjects/Telegram.json";
import Webchat from "@/components/BotCreationWizard/BaseObjects/Webchat.json";
import Whatsapp from "@/components/BotCreationWizard/BaseObjects/Whatsapp.json";

export default {
  Facebook,
  Line,
  Telegram,
  Webchat,
  Whatsapp,
};
