import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import state from "./state";
import { Module } from "vuex/types";
import { AccessControlState } from "./types";
import { RootState } from "@/store/types";

const editor: Module<AccessControlState, RootState> = {
  namespaced: false,
  getters,
  state,
  actions,
  mutations,
};

export default editor;
